import * as React from 'react';

import { ErrorHandlerProps } from './Catch';

import { BriefError } from './BriefError';

export const InlineError: React.FC<
  ErrorHandlerProps & { requiredPackages?: string[]; className?: string; showPackageError?: boolean }
> = ({ error, onRetry, requiredPackages, className = '', showPackageError }) => {
  return (
    <BriefError
      error={error}
      onRetry={onRetry}
      requiredPackages={requiredPackages}
      className={className}
      showPackageError={showPackageError}
    />
  );
};
