import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { constibutorsStr } from 'util/contributorsStr';
import { formatDate } from 'util/dateHelpers';
import { GqlPublishedPage } from 'util/hooks/usePublishedTemplatesQuery';
import { COLOR_TOKENS, FONT_FAMILIES } from 'theme/config/defaultTheme';
import DocumentIcon from 'icons/DocumentIcon';
import Download2Icon from 'icons/Download2Icon';
import URI from 'urijs';
import { ResourceMetadata } from 'containers/StatisticsLogger/types';
import FavoriteButton from './FavoriteButton';
import PromotedContentHeading from './PromotedContent/PromotedContentHeading';
import { LinkCss, StyledExternalLink } from './StyledLink/StyledLink';
import Lock from './icons/Lock';

export const getTemplateRelativeUrl = (slug: string): string => {
  return URI('/').segment(['maler', 'dokument', slug]).toString();
};

const StyledTemplateCss = styled.div`
  display: flex;
  align-items: flex-start;

  div > svg {
    margin-top: 0.125rem;
    path {
      fill: ${(props) => props.theme.colorTokens.grey[600]};
    }
  }

  p {
    margin: 0;
    letter-spacing: 0.0169rem;
    color: ${(props) => props.theme.colorTokens.grey[800]};
  }
`;

const HeadingWrapperCss = styled.div`
  margin: 0 1.5rem 0 0.5rem;
  flex: 1;

  h4 {
    display: inline-block;

    div {
      color: #3b4146;
      cursor: not-allowed;
    }
  }

  p {
    margin: 0;
    letter-spacing: 0.0169rem;
    color: ${(props) => props.theme.colorTokens.grey[800]};
  }
`;

const ActionButtonsWrapperCss = styled.div`
  margin-left: auto;
  width: 4.6875rem;

  ${LinkCss}:last-child {
    margin-left: 1.5rem;
  }
`;

const ActionButtonsWrapperAlternativeCss = styled.div`
  margin: 1rem 3rem 1rem 1.7rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold !important;
`;

const dateStr = (date: string) => {
  if (date) return `- Sist oppdatert ${formatDate(date)}.`;
};

const Template: React.FC<{
  template: GqlPublishedPage;
  hideDetailedInformation?: boolean;
  showButtonsOnSeparateRow?: boolean;
  showButtonText?: boolean;
  downloadTemplateCallback?: (data: ResourceMetadata) => void;
}> = ({ template, hideDetailedInformation, showButtonsOnSeparateRow, showButtonText, downloadTemplateCallback }) => {
  const link = template.documentAttachments?.[0]?.url;

  const { id, contributions, lastPublishedAt, userMetadata } = template;
  const authors = contributions.edges.map((edge) => edge.node);

  const href = getTemplateRelativeUrl(template.slug);
  const accessible = template.documentAttachments;

  const onClick = () => {
    if (downloadTemplateCallback)
      downloadTemplateCallback({
        authorsFullName: authors.map((author) => author.names.join(' ')),
        isRoyaltyResource: true,
        tags: template.tags.map((tag) => tag.id),
        originallyPublishedAt: lastPublishedAt,
        templateEdition: {
          title: template.title,
          slug: template.slug,
          firstPublishedAt: template.firstPublishedAt,
          lastPublishedAt: template.lastPublishedAt,
        },
      });
  };

  const headings = (
    <>
      <Link to={href}>
        <div>
          <DocumentIcon />
        </div>
      </Link>
      <HeadingWrapperCss>
        <Link to={href}>
          {!accessible && <Lock title="Dette innholdet er ikke i ditt abonnement" mr2 />}
          <PromotedContentHeading
            hTag="h4"
            size="sm"
            margin={{ top: 0, bottom: 0.187 }}
            fontConfig={{ family: FONT_FAMILIES.secondary, weight: 600, letterSpacing: 0.0187 }}
          >
            {template.title}
          </PromotedContentHeading>
        </Link>

        {!hideDetailedInformation && (
          <p>
            {constibutorsStr(authors)} {dateStr(lastPublishedAt)}
          </p>
        )}
      </HeadingWrapperCss>
    </>
  );

  const buttons = (
    <>
      <FavoriteButton
        isFavorite={!!userMetadata?.favoriteCreatedAt}
        collection="FavoritePublishedPage"
        mutationVariables={{
          id,
        }}
        graphqlResource={template}
      >
        {showButtonText ? 'LEGG TIL FAVORITT' : ''}
      </FavoriteButton>
      {link && (
        <StyledExternalLink
          onClick={onClick}
          href={link}
          $hasTextDecorationOnHover
          $color={COLOR_TOKENS.grey[800]}
          leftIcon={Download2Icon}
          download
          title="Last ned"
        >
          {showButtonText ? 'LAST NED' : ''}
        </StyledExternalLink>
      )}
    </>
  );

  return showButtonsOnSeparateRow ? (
    <>
      {' '}
      <StyledTemplateCss>{headings}</StyledTemplateCss>
      <ActionButtonsWrapperAlternativeCss>{buttons}</ActionButtonsWrapperAlternativeCss>{' '}
    </>
  ) : (
    <StyledTemplateCss>
      {headings}
      <ActionButtonsWrapperCss>{buttons}</ActionButtonsWrapperCss>
    </StyledTemplateCss>
  );
};
export default Template;
