/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { HOST, useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { createLogger } from 'commonUtils/log';
import { AuthenticationStatus } from 'state/login/types';
import { useSelector } from 'util/hooks/useSelector';
import { sha256 } from 'util/cryptoUtils';
import { useCustomerInfoQuery } from 'util/hooks/useCustomerInfoQuery';
import * as gqlResult from 'util/graphql/GqlResult';
import { usePackageQuery } from 'util/hooks/usePackageQuery';
import { determineRoyaltyProducts, determineBusinessPackage, getTimedSessionId } from 'util/statistics/royaltyUtils';
import { rsSendPageEventUser, rsSendPageEventRoyalty } from 'util/statistics/rudderStackUtils';
import { createPendoLocation, setPendoLocation } from 'util/statistics/pendoUtils';
import { CustomerMetadata, EventMetadata, EventProps, UserMetadata } from './types';

const log = createLogger('statisticsLogger');

const StatisticsLogger: React.FC<EventProps> = (props) => {
  const { title, hostname, page, children, resourceMetadata, setMetadataCallback } = props;
  // Control variables
  const authStatus = useSelector((state) => state.login.authStatus);
  const idTokenPayload = useSelector((state) => state.session.idTokenPayload);
  const [currentPath, setCurrentPath] = useState<string | undefined>();
  const [rsLastPagePath, setRsLastPagePath] = useState<string | undefined>();
  // Metadata for requests
  const juridikaConfig = useJuridikaConfig();
  const email = useSelector((state) => state.currentUser.email);
  const customerInfo = useCustomerInfoQuery(authStatus);
  const [customerNumber, setCustomerNumber] = useState<string | undefined>();
  const [eventMetadata, setEventMetadata] = useState<EventMetadata | undefined>();

  const skipPackageQuery =
    !resourceMetadata.tags || resourceMetadata.tags.length === 0 || authStatus !== AuthenticationStatus.AUTHENTICATED;

  const resourcePackagesOrSkipped = usePackageQuery(
    resourceMetadata.tags ? { tagPrefixes: resourceMetadata.tags } : {},
    skipPackageQuery
  );

  const setDefinedCustomerNumber = (customerNumber: string | undefined) => {
    if (customerNumber) {
      setCustomerNumber(customerNumber);
    }
  };

  useEffect(() => {
    if (authStatus === AuthenticationStatus.UNKNOWN) return;

    const extractedCustomerNumber =
      customerNumber || (idTokenPayload ? idTokenPayload['http://juridika/claims/customerId'] : undefined);
    setDefinedCustomerNumber(extractedCustomerNumber);
  }, [authStatus, customerNumber, idTokenPayload]);

  React.useEffect(() => {
    const isInternalUser = (): boolean =>
      authStatus === AuthenticationStatus.AUTHENTICATED && !!customerNumber && ['uf-1000', 'test-666'].includes(customerNumber);
    if (
      rsLastPagePath !== page &&
      authStatus === AuthenticationStatus.AUTHENTICATED &&
      customerInfo &&
      customerInfo.value.type === gqlResult.Type.Ok &&
      resourcePackagesOrSkipped.called &&
      !resourcePackagesOrSkipped.loading
    ) {
      const userActiveProducts = customerInfo?.value?.data?.products || [];
      const resourcePackages = resourcePackagesOrSkipped?.data?.packages.edges.map((e) => e.node) || [];
      const royaltyProducts = determineRoyaltyProducts(resourcePackages, userActiveProducts, resourceMetadata.tags);
      const royaltyBusinessPackages = determineBusinessPackage(
        userActiveProducts?.map((p) => p.key),
        resourcePackages,
        resourceMetadata.isFrontlist || false
      );
      const userMeta: UserMetadata = {
        products: userActiveProducts,
        marketCategory: customerInfo.value.data.marketCategoryName || 'ukjent',
        isInternal: isInternalUser(),
        sessionId: getTimedSessionId(),
      };
      const resourceMeta = {
        ...resourceMetadata,
        packages: resourcePackages,
        isRoyaltyResource: resourceMetadata.isRoyaltyResource || false,
      };
      const royaltyMeta = royaltyProducts && { activeProducts: royaltyProducts, businessPackages: royaltyBusinessPackages };
      const customerMeta: CustomerMetadata = {
        customerNumber: customerInfo.value.data.customerNumber,
        customerName: customerInfo.value.data.customerName,
        marketCategory: customerInfo.value.data.marketCategoryName,
        potentialUsers: customerInfo.value.data.potentialUsers.toString(),
        subscriptionPackage: customerInfo.value.data.subscriptionPackageName,
        subscriptionStatus: customerInfo.value.data.subscriptionStatusName,
      };
      setEventMetadata({
        eventType: 'pageviewEvent',
        pageDetails: { title, page, hostname },
        userMeta,
        resourceMeta,
        royaltyMeta,
        customerMeta,
      });

      rsSendPageEventUser({ title, page, hostname }, userMeta, resourceMeta, royaltyMeta, customerMeta);
      rsSendPageEventRoyalty({ title, page, hostname }, userMeta, resourceMeta, royaltyMeta);

      setRsLastPagePath(page);
    } else if (rsLastPagePath !== page && authStatus === AuthenticationStatus.NOT_AUTHENTICATED) {
      rsSendPageEventUser(
        { title, page, hostname },
        { isInternal: false, marketCategory: 'ukjent', products: [], sessionId: getTimedSessionId() }
      );
      setRsLastPagePath(page);
    }
  }, [
    authStatus,
    customerInfo,
    customerNumber,
    hostname,
    page,
    resourceMetadata,
    resourcePackagesOrSkipped.called,
    resourcePackagesOrSkipped.data,
    resourcePackagesOrSkipped.loading,
    rsLastPagePath,
    title,
  ]);

  React.useEffect(() => {
    if (authStatus === AuthenticationStatus.UNKNOWN) return;

    if (currentPath !== page) {
      setCurrentPath(page);
    }
  }, [
    authStatus,
    customerInfo,
    resourcePackagesOrSkipped,
    resourceMetadata,
    customerNumber,
    currentPath,
    juridikaConfig,
    title,
    page,
    hostname,
  ]);

  // Initialize Pendo
  useEffect(() => {
    const createHashedEmail = (email: string): string => {
      const prefix = juridikaConfig.get(HOST) === 'juridika.no' ? 'production' : 'staging';
      const hash = sha256(prefix + email);
      return hash;
    };

    const shouldInitializePendo = (): boolean => {
      return (
        authStatus === AuthenticationStatus.AUTHENTICATED &&
        customerNumber &&
        juridikaConfig.isClient &&
        // @ts-ignore
        pendo &&
        // @ts-ignore
        !(pendo.isReady && pendo.isReady())
      );
    };

    if (!shouldInitializePendo()) return;

    const val = customerInfo && customerInfo.value.type === gqlResult.Type.Ok ? customerInfo.value.data : null;

    if (!val || !email) return;

    const location = createPendoLocation(resourceMetadata);

    // @ts-ignore
    pendo.initialize({
      visitor: {
        id: createHashedEmail(email),
      },
      account: {
        id: customerNumber,
        name: val.customerName,
        marketCategory: val.marketCategoryName,
        potentialUsers: val.potentialUsers,
        subscriptionPackage: val.subscriptionPackageName,
        subscriptionStatus: val.subscriptionStatusName,
      },
      ...((location && {
        location: {
          setUrl: location,
        },
      }) ||
        {}),
    });

    setPendoLocation(resourceMetadata);
  }, [authStatus, customerInfo, customerNumber, email, juridikaConfig, resourceMetadata, page]);

  useEffect(() => {
    setPendoLocation(resourceMetadata);
  }, [resourceMetadata]);

  useEffect(() => {
    if (eventMetadata) {
      if (setMetadataCallback) setMetadataCallback(eventMetadata);
    }
  }, [eventMetadata, setMetadataCallback]);

  return <div>{children}</div>;
};

export default StatisticsLogger;
