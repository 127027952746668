import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import ArrowRight from 'icons/ArrowRight';
import styled, { css } from 'styled-components';
import { StyledInternalLink } from './StyledLink/StyledLink';

const StyledInternalLinkCss = styled(StyledInternalLink)<{ $redIconOnHover?: boolean }>`
  font-weight: 600;

  ${({ $redIconOnHover, theme }) =>
    $redIconOnHover &&
    css`
      svg * {
        fill: ${theme.colorTokens.default.black};
      }

      :hover {
        svg * {
          fill: ${theme.colorTokens.red[600]};
        }
      }
    `}
`;

const ReadMoreButton: React.FC<{
  path: string;
  txt: string;
  redIconOnHover?: boolean;
}> = ({ path, txt, redIconOnHover }) => (
  <StyledInternalLinkCss
    to={path}
    rightIcon={<ArrowRight width={8} height={12} />}
    $color={COLOR_TOKENS.default.black}
    $redIconOnHover={!!redIconOnHover}
  >
    {txt}
  </StyledInternalLinkCss>
);

export default ReadMoreButton;
