import gql from 'graphql-tag';

import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { generateNikDocumentLink } from 'pages/SearchPage/helpers';
import {
  SearchHistorySuggestionElement,
  Suggestion,
  SuggestionElement,
  TextWithModifiers,
} from 'components/Search/SearchSuggestionDropdown';
import {
  GqlSearchResult,
  GqlSearchSuggestion,
  GqlSearchSuggestionContributor,
  GqlSearchSuggestionLiteraturePathElements,
  GqlSearchSuggestionMetadata,
} from 'models/graphql/SearchSuggestions';
import { getTemplateRelativeUrl } from 'components/Template';
import { concatArrays } from './arrayUtils';
import { formatDate } from './dateHelpers';
import { convertFragmentIdToUrlPath, countryCodeToFragmentProfile } from './fragmentUrlPathConversion';

const SUGGESTION_HISTORY_COUNT = 7;
const HISTORY_STORAGE_KEY = 'searchHistory';

export const SUGGESTIONS_CATEGORIES = {
  ACT_FRAGMENT: 'Act Fragment',
  ACT: 'Act',
  JOURNAL: 'Journal',
  JOURNAL_ARTICLE: 'Journal Article',
  TEXTBOOK: 'Textbook',
  CONTRIBUTORS: 'Contributors',
  NEWS_ARTICLE: 'News Article',
  CONTRACT_TEMPLATE: 'Contract',
  NIK_DOCUMENT: 'NikDocument',
};

const HIDDEN_CATEGORIES = ['Persons', 'Tags', 'Locations', 'Organizations', 'Terms'];

export const SEARCH_SUGGESTIONS_QUERY = gql`
  query SearchSuggestions($query: String!, $limit: Int) {
    searchSuggestions(query: $query, limit: $limit) {
      results {
        header
        list {
          literaturePathElements {
            key
            editionPath
            slug
          }
          score
          type
          displayValue
          metadata {
            documentPath
            issueNumber
            journalTitle
            firstEditionYear
            lastEditionYear
            publicationDate
            contributors {
              name
            }
            accessible
            availability
            hasComment
          }
        }
      }
      error {
        type
      }
    }
  }
`;

export const SUGGESTION_HISTORY_TYPES = {
  SEARCH_QUERY: 'search_query',
  SEARCH_HIT_SELECTED: 'SEARCH_HIT_SELECTED',
  SUGGESTION_SELECTED: 'SUGGESTION_SELECTED',
};

export const getDocumentUrl = (
  type: string,
  accessible: boolean,
  documentId: string | null,
  literaturePathElements: GqlSearchSuggestionLiteraturePathElements | null
): string => {
  // Must format documentId to correct juridika url scheme for comment
  if (type === SUGGESTIONS_CATEGORIES.ACT) {
    return `/${documentId}`;
  }
  if (type === SUGGESTIONS_CATEGORIES.ACT_FRAGMENT && documentId) {
    const idElements = documentId.split('/');
    if (idElements.length === 4) {
      const countryCode = idElements[0];
      const type = idElements[1];
      const dateSeq = idElements[2];
      const fragment = convertFragmentIdToUrlPath(idElements[3], countryCodeToFragmentProfile(countryCode));
      return `/${countryCode}/${type}/${dateSeq}${fragment}/kommentar`;
    }
  }
  if (type === SUGGESTIONS_CATEGORIES.TEXTBOOK) {
    return accessible ? `/fagbøker/${documentId}/dokument` : `/fagbøker/${documentId}`;
  }
  if (type === SUGGESTIONS_CATEGORIES.JOURNAL) {
    return `/tidsskrifter/${documentId}`;
  }
  if (type === SUGGESTIONS_CATEGORIES.JOURNAL_ARTICLE) {
    if (!accessible && literaturePathElements) {
      return `/tidsskrifter/${literaturePathElements.slug}/${literaturePathElements.editionPath[0]}/${literaturePathElements.editionPath[1]}`;
    }
    return `/tidsskrifter/${documentId}`;
  }
  if (type === SUGGESTIONS_CATEGORIES.CONTRIBUTORS) {
    return `/forfattere/${documentId}`;
  }
  if (type === SUGGESTIONS_CATEGORIES.NEWS_ARTICLE) {
    return `/innsikt/${documentId}`;
  }
  if (type === SUGGESTIONS_CATEGORIES.CONTRACT_TEMPLATE && documentId) {
    return getTemplateRelativeUrl(documentId);
  }
  if (type === SUGGESTIONS_CATEGORIES.NIK_DOCUMENT && documentId) {
    return generateNikDocumentLink(documentId);
  }
  return `/${documentId}`;
};

function getPublicationYear(date: string) {
  if (!date) {
    return '';
  }
  return new Date(date).getFullYear();
}

function getContributorNames(contributors: GqlSearchSuggestionContributor[]) {
  if (!contributors || contributors.length === 0) {
    return '';
  }

  if (contributors.length > 1) {
    const names = contributors.map((contributor) => contributor.name);
    return `av ${names.slice(0, -1).join(', ')} og ${names.slice(-1)}`;
  }
  return `av ${contributors[0].name}`;
}

export function processGraphqlSuggestions(suggestions: GqlSearchResult[], includedCategories?: string[]): Suggestion[] {
  const getMetatext = (type: string, meta: GqlSearchSuggestionMetadata): TextWithModifiers[] => {
    switch (type) {
      case SUGGESTIONS_CATEGORIES.TEXTBOOK: {
        const { publicationDate, contributors, availability } = meta;
        if (publicationDate && contributors) {
          const publicationYearOrCustomMessage =
            availability === 'COMPLETE'
              ? { text: getPublicationYear(publicationDate).toString() }
              : { text: 'Kommende utgivelse', color: COLOR_TOKENS.red[700] };
          return [publicationYearOrCustomMessage, { text: getContributorNames(contributors) }];
        }
        return [];
      }

      case SUGGESTIONS_CATEGORIES.JOURNAL: {
        const { firstEditionYear, lastEditionYear } = meta;

        if (lastEditionYear && firstEditionYear) {
          if (lastEditionYear === new Date().getFullYear().toString()) {
            return [{ text: `${getPublicationYear(firstEditionYear)}-d.å.` }];
          }
          if (lastEditionYear === firstEditionYear) {
            return [{ text: `${getPublicationYear(firstEditionYear)}` }];
          }
          return [
            {
              text: `${getPublicationYear(firstEditionYear)}-${getPublicationYear(lastEditionYear)}`,
            },
          ];
        }

        return [{ text: 'Tidsskrift' }];
      }

      case SUGGESTIONS_CATEGORIES.JOURNAL_ARTICLE: {
        const { journalTitle, publicationDate, contributors, issueNumber } = meta;
        return publicationDate && contributors
          ? [
              {
                text: `${journalTitle}, ${getPublicationYear(publicationDate)}/${issueNumber}, ${getContributorNames(
                  contributors
                )}`,
              },
            ]
          : [];
      }
      case SUGGESTIONS_CATEGORIES.NEWS_ARTICLE: {
        const { contributors, publicationDate } = meta;
        if (publicationDate && contributors) {
          return [{ text: `${formatDate(publicationDate)}, ${getContributorNames(contributors)}` }];
        }
        if (contributors) {
          return [{ text: `${getContributorNames(contributors)}` }];
        }
        return [];
      }
      case SUGGESTIONS_CATEGORIES.NIK_DOCUMENT:
      case SUGGESTIONS_CATEGORIES.CONTRACT_TEMPLATE: {
        const { contributors } = meta;
        if (contributors) {
          return [{ text: `${getContributorNames(contributors)}` }];
        }
        return [];
      }
      case SUGGESTIONS_CATEGORIES.ACT:
      case SUGGESTIONS_CATEGORIES.ACT_FRAGMENT: {
        const { hasComment } = meta;
        if (hasComment) {
          return [{ text: 'med Universitetsforlagets blå lovkommentar' }];
        }
        return [];
      }

      default:
        return [];
    }
  };

  const filteredSuggestions = !includedCategories
    ? suggestions.filter((hit) => !HIDDEN_CATEGORIES.includes(hit.header))
    : suggestions
        .filter((hit) => includedCategories.includes(hit.header))
        .filter((hit) => !HIDDEN_CATEGORIES.includes(hit.header));

  return filteredSuggestions.map((suggestion: GqlSearchResult) => {
    const type = suggestion.header;
    return {
      category: type,
      list: suggestion.list.map((hit: GqlSearchSuggestion) => ({
        type,
        metaText: hit.metadata ? getMetatext(type, hit.metadata) : [],
        documentUrl: getDocumentUrl(type, hit.metadata.accessible, hit.metadata.documentPath, hit.literaturePathElements),
        displayText: hit.displayValue,
        score: hit.score,
        accessible: hit.metadata.accessible,
      })),
    };
  });
}

export function storeSuggestion(
  suggestion: SuggestionElement,
  type = SUGGESTION_HISTORY_TYPES.SUGGESTION_SELECTED
): SearchHistorySuggestionElement[] {
  let searchHistory = [];

  const historyString = localStorage.getItem(HISTORY_STORAGE_KEY);
  const suggestionToStore = { ...suggestion, type: suggestion.type || type };
  if (historyString) {
    // Make sure no duplicate
    searchHistory = JSON.parse(historyString).filter(
      (storedSuggestion: SearchHistorySuggestionElement) => storedSuggestion.displayText !== suggestion.displayText
    );
    // Same as .shift(suggestion) but faster
    searchHistory = [suggestionToStore].concat(searchHistory).slice(0, SUGGESTION_HISTORY_COUNT);
  } else {
    searchHistory = [suggestionToStore];
  }

  localStorage.setItem(HISTORY_STORAGE_KEY, JSON.stringify(searchHistory));
  return searchHistory;
}

export function storeSearchQuery(query: string) {
  storeSuggestion(
    {
      documentUrl: `/sok?q=${query}`,
      metaText: [],
      displayText: query,
      accessible: true,
    },
    SUGGESTION_HISTORY_TYPES.SEARCH_QUERY
  );
}

export function fetchSuggestionHistory(): SearchHistorySuggestionElement[] {
  const searchHistory = localStorage.getItem(HISTORY_STORAGE_KEY);
  return searchHistory ? JSON.parse(searchHistory) : [];
}

export function clearSuggestionHistory() {
  localStorage.removeItem(HISTORY_STORAGE_KEY);
}

export const parseSearchHistoryMetaText = (metaText: string | TextWithModifiers[] | null): TextWithModifiers[] => {
  if (metaText) {
    if (Array.isArray(metaText)) return metaText;
    return [{ text: metaText }];
  }
  return [];
};

/**
 * Combines suggestions into one single entity, sorted descending by score.
 *
 * @param {*Object} suggestions Suggestions such as [{ category: 'Act', list: [{term: 'Strl.' ...}] }]
 * @param {*Array} combinations List of combinations such as [['Act Fragment', 'Act'], ['Textbook']]
 */
export function combineSuggestions(suggestions: Suggestion[], combinations: string[][], limit = 5) {
  const nextSuggestions: Suggestion[] = [];
  const processed: string[] = [];

  suggestions.forEach((suggestion) => {
    if (!processed.includes(suggestion.category)) {
      const categories = combinations.find((combination) => !!combination.find((category) => category === suggestion.category));
      if (categories) {
        // Sorted descending
        const list = categories
          .map((category) => {
            const categoryEl = suggestions.find((s) => s.category === category);
            if (categoryEl) {
              processed.push(categoryEl.category);
              return categoryEl.list;
            }

            return [];
          })
          .reduce(concatArrays, [])
          .sort((a, b) => (typeof a.score === 'number' && typeof b.score === 'number' ? b.score - a.score : 0))
          .splice(0, limit);

        nextSuggestions.push({
          list,
          category: suggestion.category,
        });
      }
    }
  });
  return nextSuggestions;
}
