import { gql, QueryResult, useQuery } from '@apollo/client';
import { GqlConnection } from '../graphql/GqlConnection';

export const PACKAGE_QUERY = gql`
  query Node($first: Int, $offset: Int, $keys: [String!], $tagPrefixes: [String!], $permissions: [String!]) {
    packages(first: $first, offset: $offset, keys: $keys, tagPrefixes: $tagPrefixes, permissions: $permissions) {
      edges {
        node {
          name
          key
          permission
          tags {
            id
            path
            name
          }
        }
        cursor
      }
    }
  }
`;

export interface GqlTag {
  name: string;
  path: string[];
}

export interface GqlPackage {
  name: string;
  key: string;
  permission: string;
  tags: GqlTag[];
}

export interface GqlPackageVariables {
  first?: number;
  offset?: number;
  keys?: string[];
  tagPrefixes?: string[];
  permissions?: string[];
}

export interface GqlPackageConnection {
  packages: GqlConnection<{ node: GqlPackage }>;
}

export const usePackageQuery = (
  variables?: GqlPackageVariables,
  skip?: boolean
): QueryResult<GqlPackageConnection, GqlPackageVariables> => {
  return useQuery(PACKAGE_QUERY, {
    variables,
    skip,
  });
};
