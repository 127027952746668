import React, { FC } from 'react';
import DownloadIcon3 from 'components/icons/DownloadIcon3';
import styled from 'styled-components';
import { buttonWithoutStyle } from 'styleMixins/listMixins';

export const DownloadButtonCss = styled.button`
  ${buttonWithoutStyle}
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTokens.purple[700]};
  text-decoration: underline;

  :focus-visible {
    border: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colorTokens.default.black};
  }

  :hover {
    opacity: 1;
    text-decoration: none;
  }
`;

const DownloadPDFButton: FC<{ onClick: () => void; text: string }> = ({ onClick, text }) => {
  return (
    <DownloadButtonCss onClick={onClick}>
      <DownloadIcon3 mr2 />
      <span>{text}</span>
    </DownloadButtonCss>
  );
};

export default DownloadPDFButton;
