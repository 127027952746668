import React, { FC, useRef } from 'react';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import Cross2 from 'components/icons/Cross2';
import useFocusTrap from 'util/hooks/useFocusTrap';
import { AcademyIconButton3Css, ButtonCss, ContentCss, HeaderCss, InputCss, ModalContentCss, ModalCss, TitleCss } from './Styles';

export interface ShareCourseModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selected: boolean;
  setSelected: (selected: boolean) => void;
  onExit: () => void;
}

const ShareCourseModal: FC<ShareCourseModalProps> = ({ open, setOpen, selected, setSelected, onExit }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { isClient } = useJuridikaConfig();

  const modalRef = useRef<HTMLDivElement>(null);
  useFocusTrap(modalRef);

  const copyText = () => {
    const input = inputRef?.current?.value || '';
    navigator.clipboard.writeText(input);
    setSelected(true);
  };

  const getCurrentUrl = (): string => (isClient ? window.location.href : '');

  const onClose = () => {
    onExit();
    setSelected(false);
    setOpen(false);
  };

  return (
    <ModalCss ariaLabelledbyId="share-course-module" isOpen={open}>
      <ModalContentCss ref={modalRef} tabIndex={-1}>
        <HeaderCss align="center" justify="space-between">
          <TitleCss>Del</TitleCss>
          <AcademyIconButton3Css icon={<Cross2 />} onClick={onClose} />
        </HeaderCss>
        <ContentCss>
          <InputCss type="text" defaultValue={getCurrentUrl()} $selected={selected} ref={inputRef} />
          <ButtonCss label="Kopier" onClick={copyText} />
        </ContentCss>
      </ModalContentCss>
    </ModalCss>
  );
};

export default ShareCourseModal;
