import * as React from 'react';
import styled, { css } from 'styled-components';

import createMediaQuery from 'util/createMediaQuery';

interface ToolbarItemsProps {
  children: React.ReactNode | React.ReactNode[];
}

export const ToolbarCss = styled.div`
  margin: 0.625rem;

  ${createMediaQuery(
    'sm',
    css`
      display: flex;
    `
  )}
`;

const StartCss = styled.div`
  align-items: center;
  display: flex;
  padding: 0;
`;

const EndCss = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  padding: 0;
`;

const ItemCss = styled.div`
  margin: 0.325rem;
`;

export const StartToolbarItems: React.FC<ToolbarItemsProps> = ({ children }) => {
  if (!children) return null;

  return (
    <StartCss>
      <ItemCss>{children}</ItemCss>
    </StartCss>
  );
};

export const EndToolbarItems: React.FC<ToolbarItemsProps> = ({ children }) => {
  if (!children) return null;

  return (
    <EndCss>
      <ItemCss>{children}</ItemCss>
    </EndCss>
  );
};
