import React, { FC } from 'react';
import styled from 'styled-components';
import * as ReactRouter from 'react-router-dom';

interface BreadCrumbProps {}

const AcademyBreadCrumbs: FC<BreadCrumbProps> = () => {
  return (
    <BreadCrumbCss>
      <BreadCrumbLinkCss to="/">Juridika</BreadCrumbLinkCss>

      <div>/</div>

      <BreadCrumbLinkCss to="/akademi" $disabled>
        Akademi
      </BreadCrumbLinkCss>
    </BreadCrumbCss>
  );
};

export default AcademyBreadCrumbs;

const BreadCrumbCss = styled.div`
  padding: 10px 20px;

  display: flex;
  gap: 5px;
  align-items: baseline;

  line-height: normal;
`;

const BreadCrumbLinkCss = styled(ReactRouter.Link)<{ $disabled?: boolean }>`
  color: ${({ theme }) => theme.colorTokens.default.white};
  font-size: 0.875rem;

  :focus-visible {
    border: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.default.white};
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    pointer-events: none;
    text-decoration: none;
  `}
`;
