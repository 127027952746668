import { CustomerMetadata, EventMetadata, PageDetails, ResourceMetadata, UserMetadata } from 'containers/StatisticsLogger/types';
import { GqlPackage } from '../hooks/usePackageQuery';
import { getTimedSessionId, RoyaltyMetadata } from './royaltyUtils';

const RUDDERSTACK_TRACK_API = 'v1/track';
const RUDDERSTACK_PAGE_API = 'v1/page';

const VERSION = '4.0.5';

interface EventVersion {
  version: string;
}

export interface Track {
  anonymousId?: string;
  userId?: string; // Should not use this for anonymous tracking, use anonymousId instead
  context?: EventMetadata;
  event: string;
  properties?: EventVersion;
  integrations?: Record<string, unknown>;
  timestamp: string;
}

export interface Page {
  anonymousId?: string;
  userId?: string; // Should not use this for anonymous tracking, use anonymousId instead
  context?: EventMetadata;
  name: string;
  properties?: EventVersion;
  integrations?: Record<string, unknown>;
  timestamp: string;
}

export enum TrackEventType {
  JOURNAL_ARTICLE_DOWNLOAD = 'Journal article download',
  COMMENTARY_DOWNLOAD = 'Commentary download',
  CONTRACT_TEMPLATE_DOWNLOAD = 'Contract template download',
  TEXTBOOK_DOWNLOAD = 'Textbook download',
}

const createPageEventBody = (pageDetails: PageDetails, context: EventMetadata): Page => {
  return {
    anonymousId: '',
    context,
    name: pageDetails.page,
    integrations: {
      All: true,
    },
    properties: {
      version: VERSION,
    },
    timestamp: new Date().toISOString(),
  };
};

const createTrackEventBody = (event: TrackEventType, context: EventMetadata, properties?: Record<string, unknown>): Track => {
  return {
    anonymousId: '',
    event,
    properties: { ...properties, version: VERSION },
    context,
    timestamp: new Date().toISOString(),
  };
};

const createContext = (
  pageDetails: PageDetails,
  userMeta: UserMetadata,
  resourceMeta?: ResourceMetadata & { packages?: GqlPackage[] },
  royaltyMeta?: RoyaltyMetadata,
  customerMeta?: CustomerMetadata
): EventMetadata => {
  return {
    pageDetails: {
      ...pageDetails,
      referrer: document.referrer,
      search: document.location.search,
    },
    resourceMeta,
    userMeta: {
      ...userMeta,
      sessionId: getTimedSessionId(),
    },
    royaltyMeta,
    customerMeta,
    eventType: 'pageviewEvent',
  };
};

export const rsSendTrackEvent = async (
  event: TrackEventType,
  eventMetadata: EventMetadata,
  properties?: Record<string, unknown>
): Promise<void> => {
  rsApiPost('/api/rudderstack/userstatistics', {
    body: createTrackEventBody(event, eventMetadata, properties),
    api: RUDDERSTACK_TRACK_API,
  });

  // Remove customerMeta from contextRoyalty
  const contextRoyalty = createContext(
    eventMetadata.pageDetails,
    eventMetadata.userMeta,
    eventMetadata.resourceMeta,
    eventMetadata.royaltyMeta
  );
  rsApiPost('/api/rudderstack/royaltystatistics', {
    body: createTrackEventBody(event, contextRoyalty, properties),
    api: RUDDERSTACK_TRACK_API,
  });
};

export const rsSendPageEventUser = async (
  pageDetails: PageDetails,
  userMeta: UserMetadata,
  resourceMeta?: ResourceMetadata & { packages?: GqlPackage[] },
  royaltyMeta?: RoyaltyMetadata,
  customerMeta?: CustomerMetadata
): Promise<void> => {
  const contextUserAndCustomer = createContext(pageDetails, userMeta, resourceMeta, royaltyMeta, customerMeta);
  rsApiPost('/api/rudderstack/userstatistics', {
    body: createPageEventBody(pageDetails, contextUserAndCustomer),
    api: RUDDERSTACK_PAGE_API,
  });
};

export const rsSendPageEventRoyalty = async (
  pageDetails: PageDetails,
  userMeta: UserMetadata,
  resourceMeta?: ResourceMetadata & { packages?: GqlPackage[] },
  royaltyMeta?: RoyaltyMetadata
): Promise<void> => {
  const contextRoyalty = createContext(pageDetails, userMeta, resourceMeta, royaltyMeta);
  rsApiPost('/api/rudderstack/royaltystatistics', {
    body: createPageEventBody(pageDetails, contextRoyalty),
    api: RUDDERSTACK_PAGE_API,
  });
};

const rsApiPost = async (route: string, body: { body: Track | Page; api: string }) => {
  const response = await fetch(route, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.status !== 200) console.error(response.status);
  console.log('Følgende anonymisert data sendes på bruk på denne siden:', body.body);
};
