import { ResourceMetadata } from 'containers/StatisticsLogger/types';
import URI from 'urijs';
/* eslint-disable @typescript-eslint/ban-ts-comment */

// Creates a location including meta data which is used by Pendo
export const createPendoLocation = (resourceMetadata: ResourceMetadata): string | void => {
  if (resourceMetadata && window) {
    const url = new URI(window.location.href).segment('meta').addQuery('isbn', resourceMetadata.isbn || 'empty');
    const areas = resourceMetadata.tags;
    let index = 1;
    if (areas) {
      areas.forEach((element: string) => {
        url.addQuery(`tag_${(index += 1)}`, element);
      });
    }
    return url.href();
  }
};

export const extractSubdomain = (email: string): string => {
  const emailParts = email.split('@');
  if (emailParts.length === 2) {
    return emailParts[1].split('.')[0];
  }
  return '';
};

export const setPendoLocation = (resourceMetadata: ResourceMetadata): void => {
  // @ts-ignore
  if (!pendo || !pendo.location) return;
  const pendoLocation = createPendoLocation(resourceMetadata);
  if (pendoLocation) {
    // @ts-ignore
    pendo.location.setUrl(pendoLocation);
  } else {
    // @ts-ignore
    pendo.location.useBrowserUrl();
  }
};
