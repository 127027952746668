import * as React from 'react';

import { formatError, errorClassification, ErrorClassification } from 'util/juridikaErrorUtils';
import WarningStatusCircle from 'icons/WarningStatusCircle';
import { COLORS } from 'theme/config/defaultTheme';
import styled from 'styled-components';
import { DeniedPackageAccess, WorkHasNotBeenTagged } from 'components/AccessDenied/AccessDenied';
import { OldButton } from '../OldButton';
import { ErrorHandlerProps } from './Catch';
import BriefLoginPrompt from './BriefLoginPrompt';
import BriefNoAccess from './BriefNoAccess';

interface DefaultBriefErrorProps {
  children?: React.ReactNode | React.ReactNode[];
  errorMsg: string;
}

const DefaultBriefErrorContentsCss = styled.div`
  padding: 1.25rem;
`;

const MessageCss = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colorTokens.red[600]};

  svg {
    margin-right: 0.625rem;
  }
`;

const DefaultBriefErrorContents: React.FC<DefaultBriefErrorProps> = ({ errorMsg, children }) => (
  <DefaultBriefErrorContentsCss>
    <MessageCss>
      <WarningStatusCircle color={COLORS.danger} />
      {errorMsg}.
    </MessageCss>
    {children}
  </DefaultBriefErrorContentsCss>
);

const BriefErrorContents: React.FC<
  ErrorHandlerProps & { requiredPackages?: string[]; className?: string; showPackageError?: boolean }
> = ({ error, onRetry, requiredPackages = [], className, showPackageError }) => {
  switch (errorClassification(error)) {
    case ErrorClassification.USER_UNAUTHENTICATED:
      return <BriefLoginPrompt />;

    case ErrorClassification.USER_UNAUTHORIZED:
      return showPackageError ? (
        requiredPackages.length ? (
          <DeniedPackageAccess packages={requiredPackages} className={className} />
        ) : (
          <WorkHasNotBeenTagged className={className} />
        )
      ) : (
        <BriefNoAccess />
      );

    case ErrorClassification.BAD_REQUEST:
    case ErrorClassification.RESOURCE_NOT_FOUND:
      return <DefaultBriefErrorContents errorMsg={formatError(error)} />;

    case ErrorClassification.API_NOT_WORKING:
      return (
        <DefaultBriefErrorContents errorMsg={formatError(error)}>
          <OldButton isPrimary isBig onClick={onRetry} type="reset">
            Prøv igjen
          </OldButton>
        </DefaultBriefErrorContents>
      );
  }
};

export const BriefError: React.FC<
  ErrorHandlerProps & { requiredPackages?: string[]; className?: string; showPackageError?: boolean }
> = (props) => <BriefErrorContents {...props} />;
