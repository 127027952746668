import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { setValue } from 'state/ui/uiActions';

const useEffectOpenCloseSidebarBasedOnViewportSize = (): void => {
  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');

  const dispatch = useDispatch();

  useEffect(() => {
    if (viewportSize === 'large') dispatch(setValue(true, ['akademi', 'sidebarIsOpen']));
    else dispatch(setValue(false, ['akademi', 'sidebarIsOpen']));
  }, [dispatch, viewportSize]);
};

export default useEffectOpenCloseSidebarBasedOnViewportSize;
