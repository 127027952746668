import { RootState } from 'state/types';
import { AuthenticationStatus } from './types';

/**
 * is user authenticated?
 *
 * The use of this selector is discouraged, because there are three possible answers to that question.
 *
 * The selector mainly exists as a convenience for non-typescript files that don't want to deal with ts enums.
 */
export const authenticatedSelector = (state: RootState): boolean => state.login.authStatus === AuthenticationStatus.AUTHENTICATED;

export const createPermissionSelector =
  (permission: string) =>
  (state: RootState): boolean =>
    state.login.permissions.indexOf(permission) >= 0;

export const commentReadPermissionSelector = createPermissionSelector('read:comment');
export const frontlistReadPermissionSelector = createPermissionSelector('read:literature_class:frontlist');
export const backlistReadPermissionSelector = createPermissionSelector('read:literature_class:backlist');
export const excerptFullActionPermissionSelector = createPermissionSelector('action:excerpt');
export const excerptTrialActionPermissionSelector = createPermissionSelector('action:excerpt:trial');
export const excerptPreviewActionPermissionSelector = createPermissionSelector('action:excerpt:preview');
