/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react';

/*
 *script for enabling GTM through Rudderstack:
 * https://app.rudderstack.com/sources/2eohdrXsq3krfnSFVO7kIMMQgp6?tab=Setup
 */

/* eslint-disable */

export const runRudderstackScript = () => (
  <script type="text/javascript" id="rs-gtm-script-loader">
    {(function () {
      'use strict';
      window.RudderSnippetVersion = '3.0.3';
      var sdkBaseUrl = 'https://cdn.rudderlabs.com/v3';
      var sdkName = 'rsa.min.js';
      var asyncScript = true;
      window.rudderAnalyticsBuildType = 'legacy';
      window.rudderanalytics = [];
      var methods = [
        'setDefaultInstanceKey',
        'load',
        'ready',
        'page',
        'track',
        'identify',
        'alias',
        'group',
        'reset',
        'setAnonymousId',
        'startSession',
        'endSession',
        'consent',
      ];
      for (var i = 0; i < methods.length; i++) {
        var method = methods[i];
        window.rudderanalytics[method] = (function (methodName) {
          return function () {
            window.rudderanalytics.push([methodName].concat(Array.prototype.slice.call(arguments)));
          };
        })(method);
      }
      try {
        new Function('return import("")');
        window.rudderAnalyticsBuildType = 'modern';
      } catch (e) {}
      window.rudderAnalyticsMount = function () {
        if (typeof globalThis === 'undefined') {
          Object.defineProperty(Object.prototype, '__globalThis_magic__', {
            get: function get() {
              return this;
            },
            configurable: true,
          });
          __globalThis_magic__.globalThis = __globalThis_magic__;
          delete Object.prototype.__globalThis_magic__;
        }
        var rudderAnalyticsScript = document.createElement('script');
        rudderAnalyticsScript.src = ''.concat(sdkBaseUrl, '/').concat(window.rudderAnalyticsBuildType, '/').concat(sdkName);
        rudderAnalyticsScript.async = asyncScript;
        if (document.head) {
          document.head.appendChild(rudderAnalyticsScript);
        } else {
          document.body.appendChild(rudderAnalyticsScript);
        }
      };
      if (typeof Promise === 'undefined' || typeof globalThis === 'undefined') {
        var rudderAnalyticsPromisesScript = document.createElement('script');
        rudderAnalyticsPromisesScript.src =
          'https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount';
        rudderAnalyticsPromisesScript.async = asyncScript;
        if (document.head) {
          document.head.appendChild(rudderAnalyticsPromisesScript);
        } else {
          document.body.appendChild(rudderAnalyticsPromisesScript);
        }
      } else {
        window.rudderAnalyticsMount();
      }
      var loadOptions = {};
      window.rudderanalytics.load('2eohdvXNIZHZsDjEcLhhosbh6E7', 'https://aschehougokk.dataplane.rudderstack.com', loadOptions);
      window.rudderanalytics.page();
    })()}
  </script>
);
/* eslint-enable */
