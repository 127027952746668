import React from 'react';

const Envelope: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13">
    <path
      fill="#FFF"
      d="M1.6 0C.718 0 0 .729 0 1.625v9.75C0 12.271.718 13 1.6 13h12.8c.882 0 1.6-.729 1.6-1.625v-9.75C16 .729 15.282 0 14.4 0H1.6zm0 1.625h12.8v.005L8 5.688l-6.4-4.06v-.003zm0 1.628L8 7.313l6.4-4.058.002 8.12H1.6V3.253z"
    />
  </svg>
);

export default Envelope;
