import * as React from 'react';
import styled from 'styled-components';

import { FONT_FAMILIES } from 'theme/config/defaultTheme';
import { ColorTokenValues } from 'theme/config/types';
import { ButtonAndLinkContentWrapper } from '../Button/ButtonAndLinkCss';
import { StyledInternalLink, StyledInternalLinkProps, LinkCss } from '../StyledLink/StyledLink';
import { fontSize } from './utils/fontSize';

interface PromotedContentLinkProps extends StyledInternalLinkProps {
  $arrowIconColor?: ColorTokenValues;
}

export const PromotedContentLinkWrapperCss = styled.span<PromotedContentLinkProps>`
  ${LinkCss} {
    display: inline;

    svg {
      width: ${(props) => props.$size === 'lg' && `0.8125rem`};
      height: ${(props) => props.$size === 'lg' && `1.1875rem`};

      path {
        fill: ${(props) => props.$arrowIconColor ?? props.theme.colorTokens.red[600]};
      }
    }
  }

  ${ButtonAndLinkContentWrapper} {
    display: inline;
  }
`;

export const PromotedContentLink: React.FC<PromotedContentLinkProps> = ({ $size, ...linkProps }) => {
  return (
    <PromotedContentLinkWrapperCss $size={$size} {...linkProps}>
      <StyledInternalLink
        $fontConfig={{
          family: FONT_FAMILIES.primary,
          size: fontSize($size),
          weight: 500,
          letterSpacing: 0.0125,
        }}
        $hasTextDecorationOnHover
        {...linkProps}
      />
    </PromotedContentLinkWrapperCss>
  );
};

export default PromotedContentLink;
