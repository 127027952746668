/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { Link } from 'react-router-dom';
import * as H from 'history';

import LoadingSpinner from './LoadingSpinner';

const classes = new BEMHelper({ name: 'btn', prefix: 'c-' });

export interface OldCommonButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  iconLeft?: React.ComponentType | null;
  iconRight?: React.ComponentType | null;
  id?: string;
  isBig?: boolean;
  isHuge?: boolean;
  isLoading?: boolean;
  isOceanBlueInverted?: boolean;
  isPrimary?: boolean;
  isPrimaryInverted?: boolean;
  isSecondary?: boolean;
  isSecondaryInverted?: boolean;
  isSmall?: boolean;
  role?: string;
  type?: 'submit' | 'reset' | 'button';
  tabIndex?: number;
}

export interface OldButtonProps extends OldCommonButtonProps {
  onClick?: (event: React.MouseEvent) => void;
  isUnderlined?: boolean;
  isInverted?: boolean;
  style?: {
    [key: string]: string;
  };
}

export interface OldLinkButtonProps extends OldCommonButtonProps {
  to: H.LocationDescriptor<H.LocationState>;
}

const OldButtonContent: React.FC<OldButtonProps> = ({
  disabled = false,
  iconLeft = null,
  iconRight = null,
  isOceanBlueInverted = false,
  isPrimary = false,
  isPrimaryInverted = false,
  isSecondary = false,
  isSecondaryInverted = false,
  isUnderlined = false,
  isInverted = false,
  isLoading = false,
  isSmall = false,
  isHuge = false,
  isBig = false,
  className = '',
  children = null,
}) => {
  const options = {
    element: undefined,
    modifiers: {
      isOceanBlueInverted,
      isPrimary,
      isPrimaryInverted,
      isSmall,
      isBig,
      isHuge,
      isDisabled: disabled,
      isSecondary,
      isSecondaryInverted,
      isUnderlined,
      isInverted,
    },
    extra: className,
  };

  return (
    <>
      {isLoading ? (
        <span {...classes('leftIcon', { ...options.modifiers })}>
          <LoadingSpinner />
        </span>
      ) : (
        iconLeft && <span {...classes('leftIcon', { ...options.modifiers })}>{React.createElement(iconLeft, {})}</span>
      )}
      {children && <span {...classes('text', { hasIconLeft: !!iconLeft, hasIconRight: !!iconRight })}>{children}</span>}
      {iconRight && <span {...classes('rightIcon', { ...options.modifiers })}>{React.createElement(iconRight, {})}</span>}
    </>
  );
};

export const OldExternalLinkButton: React.FC<OldButtonProps & { to: string }> = (props) => {
  const {
    to,
    isOceanBlueInverted = false,
    isPrimary = false,
    isPrimaryInverted = false,
    isSecondary = false,
    isSecondaryInverted = false,
    isSmall = false,
    isHuge = false,
    isBig = false,
    className = '',
    disabled = false,
    onClick,
  } = props;
  const options = {
    element: undefined,
    modifiers: {
      isOceanBlueInverted,
      isPrimary,
      isPrimaryInverted,
      isSmall,
      isBig,
      isHuge,
      isDisabled: disabled,
      isSecondary,
      isSecondaryInverted,
    },
    extra: className,
  };

  return (
    <a {...classes(options)} href={to} onClick={onClick}>
      <OldButtonContent {...props} />
    </a>
  );
};

export const OldLinkButton: React.FC<OldLinkButtonProps> = (props) => {
  const {
    to,
    isOceanBlueInverted = false,
    isPrimary = false,
    isPrimaryInverted = false,
    isSecondary = false,
    isSecondaryInverted = false,
    isSmall = false,
    isHuge = false,
    isBig = false,
    className = '',
    disabled = false,
  } = props;
  const options = {
    element: undefined,
    modifiers: {
      isOceanBlueInverted,
      isPrimary,
      isPrimaryInverted,
      isSmall,
      isBig,
      isHuge,
      isDisabled: disabled,
      isSecondary,
      isSecondaryInverted,
    },
    extra: className,
  };

  return (
    <Link {...classes(options)} to={to}>
      <OldButtonContent {...props} />
    </Link>
  );
};

export const OldButton: React.FC<OldButtonProps> = (props) => {
  const {
    disabled = false,
    isOceanBlueInverted = false,
    isPrimary = false,
    isPrimaryInverted = false,
    isSecondary = false,
    isSecondaryInverted = false,
    isUnderlined = false,
    isInverted = false,
    isSmall = false,
    isHuge = false,
    isBig = false,
    className = '',
    ariaLabel,
    iconLeft,
    iconRight,
    isLoading,
    ...rest
  } = props;

  const options = {
    element: undefined,
    modifiers: {
      isOceanBlueInverted,
      isPrimary,
      isPrimaryInverted,
      isSmall,
      isBig,
      isHuge,
      isDisabled: disabled,
      isSecondary,
      isSecondaryInverted,
      isUnderlined,
      isInverted,
    },
    extra: className,
  };

  return (
    <button {...classes(options)} disabled={disabled} aria-label={ariaLabel} {...rest}>
      <OldButtonContent {...props} />
    </button>
  );
};
