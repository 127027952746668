import React, { FC, useEffect, useRef } from 'react';
import { RootState } from 'state/types';
import { useSelector } from 'util/hooks/useSelector';
import { ResourceStickerData } from '../../hooks/useResourceStickers';
import { ResourceSticker } from './ResourceSticker';
import { ResourceStickersViewCss } from './styles';

interface Props {
  resourceStickers: ResourceStickerData[];
  scrollToTopEffectCounter: number;
  onCloseSticker: (stickerId: string) => void;
}

export const ResourceStickersView: FC<Props> = ({
  resourceStickers,
  scrollToTopEffectCounter,
  onCloseSticker,
}) => {
  useEffect(() => {
    scrollToTop();
  }, [scrollToTopEffectCounter]);

  const ref = useRef<HTMLDivElement>(null);

  function scrollToTop() {
    ref.current?.scrollTo({ top: 0 });
  }

  const topElementsApproxHeight = useSelector(
    (state: RootState) => state.ui.topElementsApproxHeight
  );

  return (
    <ResourceStickersViewCss
      ref={ref}
      topElementsApproxHeight={topElementsApproxHeight}
    >
      <div className="stickers-container">
        {resourceStickers.map((sticker) => (
          <ResourceSticker
            key={sticker.id}
            stickerData={sticker}
            onClose={() => onCloseSticker(sticker.id)}
          />
        ))}
      </div>
    </ResourceStickersViewCss>
  );
};
