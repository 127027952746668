import React, { FC, useEffect, useState } from 'react';
import ArrowLeft from 'components/icons/ArrowLeft';
import Cross2 from 'components/icons/Cross2';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { useHistory, useLocation } from 'react-router';
import { ButtonCss, WrapperCss } from './Styles';
import { AcademyIconButton2 } from '../IconButtons/IconButtons';

const BackToCourseBanner: FC = () => {
  const [state, setState] = useState<{ show: boolean; path: string }>({ show: false, path: '' });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const returnToCourseBanner = localStorage.getItem('returnToCourseBanner');

    if (returnToCourseBanner) {
      setState(JSON.parse(returnToCourseBanner));
    }
  }, []);

  const close = () => {
    setState({ show: false, path: '' });
    localStorage.setItem('returnToCourseBanner', JSON.stringify({ show: false, path: '' }));
  };

  useEffect(() => {
    // banner should not be displayed on the page it links to
    if (state.path === location.pathname) {
      close();
    }
  }, [location.pathname, state.path]);

  const handleBackToCourse = () => {
    history.push(state.path);
    close();
  };

  if (state.show) {
    return (
      <WrapperCss justify="center" align="center" $show={state.show}>
        <ButtonCss
          label="Tilbake til kurset"
          iconLeft={<ArrowLeft color={COLOR_TOKENS.default.white} width={10} height={16} mr2 />}
          onClick={handleBackToCourse}
        />
        <AcademyIconButton2 icon={<Cross2 />} onClick={close} aria-label="lukk tilbake-til-kurset-banner" />
      </WrapperCss>
    );
  }
  return null;
};

export default BackToCourseBanner;
