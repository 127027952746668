import phoneReg from './phoneReg';

export type FieldValidator = 'required' | 'email' | 'phone' | ['regExp', RegExp] | ['minLength', number];

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /^((([a-z]|\d|[!#$%&'*+-=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const testRegExp = (regExp: RegExp, value: string): boolean => {
  return regExp.test(value);
};

export const regExp = (regExp: RegExp, name: string, value: string): string | undefined => {
  if (!testRegExp(regExp, value)) {
    return `Ugyldig ${name.toLocaleLowerCase()}`;
  }
};

export const required = (name: string, value: string): string | undefined => {
  if (value.trim().length === 0) {
    return `${name} mangler`;
  }
};

export const email = (value: string): string | undefined => {
  if (!testRegExp(EMAIL_REGEX, value)) {
    return 'Ugyldig e-post';
  }
};

export const phoneNumber = (value: string): string | undefined => {
  if (!testRegExp(phoneReg, value)) {
    return 'Ugyldig telefonnummer';
  }
};

export const minLength = (name: string, value: string, length: number): string | undefined => {
  if (value.trim().length < length) {
    return `${name} må være minst ${length} bokstaver`;
  }
};
