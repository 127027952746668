import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { login as loginWithAuth0 } from 'state/session/sessionThunkActions';
import { useSelector } from 'util/hooks/useSelector';
import { useAuth0 } from 'util/auth0';
import { AuthenticationStatus } from 'state/login/types';
import { NavLink } from 'react-router-dom';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import Login from '../components/icons/Login';
import Exit from '../components/icons/Exit';

const commonStyles = css`
  ${buttonWithoutStyle}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colorTokens.default.white};
  font-weight: 600;
`;

const LoginButtonCss = styled.button`
  ${commonStyles}
`;

const LogoutButtonLinkCss = styled(NavLink)`
  ${commonStyles}
  text-decoration: none;
`;

interface LogInOrOutButtonsProps {
  className?: string;
}

const LogInOrOutButtons: FC<LogInOrOutButtonsProps> = ({ className }) => {
  const juridikaConfig = useJuridikaConfig();
  const auth0 = useAuth0();
  const dispatch = useDispatch();
  const handleLoginWithAuth0Click = () => {
    dispatch(loginWithAuth0(juridikaConfig, auth0));
  };
  const { authStatus } = useSelector((state) => state.login);
  const isAuthenticated = authStatus === AuthenticationStatus.AUTHENTICATED;

  if (isAuthenticated) {
    return (
      <LogoutButtonLinkCss to="/logout" className={className}>
        <Exit color={COLOR_TOKENS.default.white} width={16} height={16} mr2 />
        <span>Logg ut</span>
      </LogoutButtonLinkCss>
    );
  }

  return (
    <LoginButtonCss onClick={handleLoginWithAuth0Click} className={className}>
      <Login color={COLOR_TOKENS.default.white} width={16} height={16} mr2 />
      <span>Logg inn</span>
    </LoginButtonCss>
  );
};

export default LogInOrOutButtons;
