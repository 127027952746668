import * as React from 'react';

import { JuridikaError } from 'commonUtils/models/JuridikaError';

export interface ServerErrorState {
  // Each "thrown" error is persisted in an array for logging purposes later.
  [catchId: string]: Array<JuridikaError>;
}

export interface ErrorContextValue {
  readonly errorLogger: (catchId: string, error: JuridikaError) => void;
  readonly serverErrorState: ServerErrorState;
  catchRegistry: {
    [catchId: string]: null;
  };
}

/**
 * Global context for error handling on the server side (SSR).
 */
export const ErrorContext = React.createContext<ErrorContextValue>({
  serverErrorState: {},
  errorLogger: () => {},
  catchRegistry: {},
});
