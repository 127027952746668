import * as React from 'react';
import styled, { css } from 'styled-components';

import createMediaQuery from 'util/createMediaQuery';
import { listWithoutStyle } from '../../styleMixins/listMixins';
import { GridConfig, GridMargin } from './gridConfig';

export interface GridProps {
  config: GridConfig;
  children: React.ReactNode | React.ReactNode[];
}

const gridMarginCss = ({ topBottom, sides }: GridMargin) => css`
  margin: 0 ${sides * -1}rem ${topBottom * -1}rem;
`;

const GridCss = styled.ul<GridProps>`
  display: flex;
  flex-wrap: wrap;

  ${({ config }) => config.isListGrid && listWithoutStyle}

  ${({ config }) => gridMarginCss(config.margin)};

  ${({ config: { mediaQueries = [] } }) =>
    mediaQueries.map((query) => query.margin && createMediaQuery(query.breakpoint, gridMarginCss(query.margin)))};
`;

const GridContainerCss = styled.div`
  overflow: hidden;
`;

const Grid: React.FC<GridProps> = ({ config, children }) => (
  <GridContainerCss>
    <GridCss as={!config.isListGrid ? 'div' : 'ul'} config={config}>
      {children}
    </GridCss>
  </GridContainerCss>
);

export default Grid;
