import * as React from 'react';
import { FC } from 'react';
import { TranscriptCss, FlexCss, TimestampCss, TextCss } from './Styles';

export interface TranscriptEntry {
  id: number;
  timestamp: {
    from: string;
    to: string;
  };
  text: string;
}

export type Transcript = TranscriptEntry[];

const Transcript: FC<{ transcript: Transcript }> = ({ transcript }) => {
  return (
    <TranscriptCss forceVisible="y" autoHide={false}>
      {transcript.map((line) => (
        <FlexCss key={line.id}>
          <TimestampCss>{line.timestamp.from}</TimestampCss>
          <TextCss>{line.text}</TextCss>
        </FlexCss>
      ))}
    </TranscriptCss>
  );
};
export default Transcript;
