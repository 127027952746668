import * as Redux from 'redux';
import { ApolloClient, ApolloLink, ApolloCache, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

import uuidv4 from 'uuid/v4';
import * as ApolloLinkHttp from '@apollo/client/link/http';

import { GRAPHQL_GATEWAY } from 'commonUtils/serviceConstants';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';

import { AuthenticationStatus } from 'state/login/types';

import { possibleTypes } from './graphql/possibleTypes';
import { getIsomorphicApiUrl } from './apiUrls';
import { isTimestampExpired } from './jwt/isTimestampExpired';

interface ReduxState {
  readonly login: {
    authStatus: AuthenticationStatus;
    accessTokenExpiryTimestamp: number | undefined;
  };
  readonly session: {
    accessToken?: string;
  };
}

export const CTX_INTERACTION = 'INTERACTION';

const hydrateState = () => {
  const apolloState = (window as any).APOLLO_STATE;
  delete (window as any).APOLLO_STATE;

  return apolloState || {};
};

// Graphql schema only includes a few unions so we write the possible types manually
// https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
const createApolloCache = (juridikaConfig: JuridikaConfig): ApolloCache<NormalizedCacheObject> => {
  const cache = new InMemoryCache({
    possibleTypes,
  });

  if (juridikaConfig.isClient) {
    return cache.restore(hydrateState());
  }

  return cache;
};

export const createHttpLink = (juridikaConfig: JuridikaConfig): ApolloLink =>
  ApolloLinkHttp.createHttpLink({
    uri: getIsomorphicApiUrl(juridikaConfig, GRAPHQL_GATEWAY).toString(),
  });

export const createApolloClient = <Store extends Redux.Store<ReduxState, Redux.AnyAction>>(
  juridikaConfig: JuridikaConfig,
  reduxStore: Store
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    ssrMode: juridikaConfig.isServer,
    link: createContextMiddleware(juridikaConfig, reduxStore).concat(createHttpLink(juridikaConfig)),
    cache: createApolloCache(juridikaConfig),
  });
};

const createContextMiddleware = <Store extends Redux.Store<ReduxState, Redux.AnyAction>>(
  juridikaConfig: JuridikaConfig,
  reduxStore: Store
): ApolloLink => {
  const correlationIdGenerator = createCorrelationIdGenerator(juridikaConfig);

  return new ApolloLink((operation, forward) => {
    if (!forward) return null;

    const context = operation.getContext();
    const interactionObj = context[CTX_INTERACTION];

    const correlationId = correlationIdGenerator();

    operation.setContext({
      ...context,
      headers: {
        'X-Juridika-Correlation-Id': correlationId,
        ...getAuthorizationHeaders(reduxStore),
      },
    });

    if (interactionObj) {
      const interaction = interactionObj as ApolloInteraction;

      interaction.enterInFlightState();

      return forward(operation).map((result) => {
        interaction.enterReadyState();

        return result;
      });
    }

    return forward(operation).map((result) => {
      return result;
    });
  });
};

const createCorrelationIdGenerator = (juridikaConfig: JuridikaConfig) => {
  if (juridikaConfig.isServer) {
    // The same correlation id for all outgoings (for the same page request)
    const correlationId = uuidv4();
    return () => correlationId;
  }

  return () => uuidv4();
};

const getAuthorizationHeaders = <Store extends Redux.Store<ReduxState, Redux.AnyAction>>(reduxStore: Store) => {
  const {
    login: { authStatus, accessTokenExpiryTimestamp },
    session: { accessToken },
  } = reduxStore.getState();

  const authenticated =
    authStatus === AuthenticationStatus.AUTHENTICATED && accessToken && !isTimestampExpired(accessTokenExpiryTimestamp);

  return {
    ...(authenticated && { authorization: `Bearer ${accessToken}` }),
  };
};

export enum ApolloInteractionState {
  Queued,
  InFlight,
  Ready,
}

/**
 * "Interaction" object to optionally track the state of a request.
 * Add this object to the context:
 * ```
 * context {
 *   [CTX_INTERACTION]: new ApolloInteraction(),
 * }
 * ```
 *
 * and that object instance will track the actual state of the request.
 */
export class ApolloInteraction {
  private state: ApolloInteractionState;

  constructor() {
    this.state = ApolloInteractionState.Queued;
  }

  getState(): ApolloInteractionState {
    return this.state;
  }

  enterQueuedState(): void {
    this.state = ApolloInteractionState.Queued;
  }

  enterInFlightState(): void {
    this.state = ApolloInteractionState.InFlight;
  }

  enterReadyState(): void {
    this.state = ApolloInteractionState.Ready;
  }
}
