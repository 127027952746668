import * as React from 'react';

import WarningStatusCircle from 'icons/WarningStatusCircle';

import { COLORS } from 'theme/config/defaultTheme';

import {
  FlexParentWrapper,
  FlexChildLabelWrapper,
  RequiredSpan,
  FlexChildInputWrapper,
  InputIconButton,
  StyledInput,
  ErrorMessage,
} from './ComponentsCss';
import { InputType } from './types';

export const InputErrorMessage: React.FC<{ label?: string; inputErrorMessage: string }> = ({ label, inputErrorMessage }) => {
  const errorMessageId = `${label}Error`;
  return (
    <ErrorMessage id={errorMessageId}>
      <WarningStatusCircle color={COLORS.danger} />
      <span>{inputErrorMessage}</span>
    </ErrorMessage>
  );
};

export const Input = React.forwardRef<HTMLInputElement, InputType>((props, ref) => {
  const {
    type = 'text',
    label,
    requiredDecorator,
    leftIcon,
    rightIcon,
    disableInputFieldIconButton,
    darkBackground,
    iconButtonHandler,
    iconButtonSvgConfig,
    paddingConfig = { topBottom: 0.5, leftRight: 0.5 },
    borderColorVariant = 'primary',
    hasBorderRadius = true,
    inputErrorMessage,
    isLargeInputField,
    disabled,
    ...htmlProps
  } = props;

  return (
    <FlexParentWrapper>
      {!!label && (
        <FlexChildLabelWrapper>
          <label htmlFor={label}>
            {label} {requiredDecorator && htmlProps.required && <RequiredSpan> *</RequiredSpan>}
          </label>
        </FlexChildLabelWrapper>
      )}
      <FlexChildInputWrapper hasIcon={!!leftIcon || !!rightIcon}>
        {!!leftIcon && (
          <InputIconButton
            as={disableInputFieldIconButton ? 'span' : 'button'}
            darkBackground={darkBackground}
            iconButtonSvgConfig={iconButtonSvgConfig}
            paddingConfig={paddingConfig}
            leftIcon={!!leftIcon}
            onClick={iconButtonHandler}
            borderColorVariant={borderColorVariant}
            hasBorderRadius={hasBorderRadius}
            aria-label={label}
          >
            {React.createElement(leftIcon, {})}
          </InputIconButton>
        )}
        <StyledInput
          id={label}
          ref={ref}
          type={type || 'text'}
          {...htmlProps}
          hasError={!!inputErrorMessage}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          darkBackground={darkBackground}
          paddingConfig={paddingConfig}
          borderColorVariant={borderColorVariant}
          hasBorderRadius={hasBorderRadius}
          isLargeInputField={isLargeInputField}
          aria-describedby={label}
          disabled={disabled}
        />
        {!!rightIcon && (
          <InputIconButton
            as={disableInputFieldIconButton ? 'span' : 'button'}
            darkBackground={darkBackground}
            iconButtonSvgConfig={iconButtonSvgConfig}
            paddingConfig={paddingConfig}
            rightIcon={!!rightIcon}
            onClick={iconButtonHandler}
            borderColorVariant={borderColorVariant}
            hasBorderRadius={hasBorderRadius}
            aria-label={label}
          >
            {React.createElement(rightIcon, {})}
          </InputIconButton>
        )}
        {!!inputErrorMessage && <InputErrorMessage label={label} inputErrorMessage={inputErrorMessage} />}
      </FlexChildInputWrapper>
    </FlexParentWrapper>
  );
});

export default Input;
