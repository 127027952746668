import * as React from 'react';
import Svg from 'components/ui/Svg';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Cross2: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 6L2 0L0 2L6 8L0 14L2 16L8 10L14 16L16 14L10 8L16 2L14 0L8 6Z" fill={color} />
  </Svg>
);

Cross2.defaultProps = {
  size: 16,
  color: COLOR_TOKENS.purple[700],
};
export default Cross2;
