import * as hast from '@universitetsforlaget/hast';
import * as apolloClient from '@apollo/client';

import * as GqlResult from 'util/graphql/GqlResult';
import { GqlError } from 'util/graphql/GqlError';
import { ColorTokenValues } from 'theme/config/types';
import { FavoriteButtonProps } from '../../components/FavoriteButton';

export enum SearchResultTypes {
  ACT = 'ActExpressionSearchResult',
  ACT_FRAGMENT = 'ActFragmentSearchResult',
  TEXTBOOK = 'TextbookSearchResult',
  TEXTBOOK_FRAGMENTS = 'TextbookFragmentSearchResult',
  JOURNAL_ARTICLE = 'JournalArticleSearchResult',
  JOURNAL = 'JournalSearchResult',
  PUBLISHED_PAGE = 'PublishedPageSearchResult',
  PUBLISHED_CONTRACT = 'PublishedContractSearchResult',
  CONTRIBUTORS = 'ContributorSearchResult',
  NIK_DOCUMENT = 'NikDocumentSearchResult',
}

export type SortField = 'score' | 'newdates' | 'olddates';

export interface GqlExprId {
  workId: GqlWorkId;
  inForceAt: string;
}

export interface GqlWorkId {
  countryCode: string;
  type: string;
  date: string;
  seq: number;
}

export interface DocumentContributionEdge {
  role: string;
  node: {
    names: string[];
  };
}

interface GqlDocumentEdge {
  node: GqlDocument | null;
  error: GqlError | null;
}

interface GqlPublicationEdge {
  node: GqlPublication | null;
  error: GqlError | null;
}

interface GqlEditionEdge {
  node: GqlEdition | null;
  error: GqlError | null;
}

export interface GqlDocument extends apolloClient.Reference {
  id: string;
  key: string;
  edition: GqlEdition;
  accessible: boolean;
  contributions: {
    edges: DocumentContributionEdge[];
  };
  userMetadata: UserMetadata | null;
}

export interface GqlEdition {
  id: string;
  path: string[];
  publication: GqlPublication;
  originallyPublishedAt: string;
  availability: 'ANNOUNCED' | 'PREVIEW' | 'COMPLETE';
  hasCover: boolean;
}

export interface GqlPublication {
  slug: string;
  titles: string[];
  publicationIsAccessible: boolean;
  editions: {
    edges: GqlEditionEdge[];
  };
}

interface GqlHyperText {
  hast: hast.HastElementNode;
}

interface PublishedPageContributor {
  names: string[];
}

export interface SearchPublishedPageContributionEdge {
  role: string;
  node: PublishedPageContributor | null;
}

interface SearchPublishedPage extends apolloClient.Reference {
  id: string;
  userMetadata: UserMetadata | null;
  firstPublishedAt: string;
  title: string;
  contributions: {
    edges: Array<SearchPublishedPageContributionEdge>;
  };
}

interface SearchContributor {
  id: string;
  slug: string;
  names: string[];
  hasPortrait: boolean;
  degree: {
    plainText: string;
  } | null;
  occupation: {
    plainText: string;
  } | null;
}

interface UserMetadata {
  favoriteCreatedAt: string | null;
}

export interface SearchPublishedPageEdge {
  error: GqlError | null;
  node: SearchPublishedPage | null;
}

export interface SearchContributorEdge {
  error: GqlError | null;
  node: SearchContributor | null;
}

export interface SearchActWorkEdge {
  error: GqlError | null;
  node: {
    id: string;
    userMetadata: UserMetadata | null;
  } | null;
}

export interface SearchActWork extends apolloClient.Reference {
  id: string;
  userMetadata: UserMetadata | null;
}

export interface ActExpressionSearchResult {
  node: {
    __typename: SearchResultTypes.ACT;
    highlightedContent: GqlHyperText[];
    title: string[];
    actFullTitle: string[];
    exprId: GqlExprId;
    documentPath: string;
    accessible: boolean;
    hasComment: boolean;
    favoriteLegalDocument: {
      id: string;
      userMetadata: UserMetadata;
    };
  };
}

export interface ActFragmentSearchResult {
  node: {
    __typename: SearchResultTypes.ACT_FRAGMENT;
    highlightedContent: GqlHyperText[];
    title: string[];
    parentTitle: string;
    exprId: GqlExprId;
    authors: string[] | null;
    fragmentId: string;
    actTitle: string[];
    actFullTitle: string[];
    documentPath: string;
    accessible: boolean;
  };
}

export interface TextbookSearchResult {
  node: {
    __typename: SearchResultTypes.TEXTBOOK;
    highlightedContent: GqlHyperText[];
    title: string[];
    document: GqlDocumentEdge;
    documentPath: string;
  };
}

export interface JournalSearchResult {
  node: {
    __typename: SearchResultTypes.JOURNAL;
    highlightedContent: GqlHyperText[];
    title: string[];
    publication: GqlPublicationEdge;
    documentPath: string;
  };
}

export interface JournalArticleSearchResult {
  node: {
    __typename: SearchResultTypes.JOURNAL_ARTICLE;
    highlightedContent: GqlHyperText[];
    title: string[];
    document: GqlDocumentEdge;
    documentPath: string;
  };
}

export interface TextbookFragmentSearchResult {
  node: {
    __typename: SearchResultTypes.TEXTBOOK_FRAGMENTS;
    docType: string;
    textbookTitle: string[];
    title: string[];
    highlightedContent: GqlHyperText[];
    documentPath: string;
    document: GqlDocumentEdge;
    fragmentId: string | null;
  };
}

export interface PublishedPageSearchResult {
  node: {
    __typename: SearchResultTypes.PUBLISHED_PAGE;
    highlightedContent: GqlHyperText[];
    documentPath: string;
    title: string[];
    publishedPage: SearchPublishedPageEdge;
  };
}

export interface PublishedContractSearchResult {
  node: {
    __typename: SearchResultTypes.PUBLISHED_CONTRACT;
    highlightedContent: GqlHyperText[];
    documentPath: string;
    title: string[];
    authors: string[];
    accessible: boolean;
  };
}

export interface NikDocumentSearchResult {
  node: {
    __typename: SearchResultTypes.NIK_DOCUMENT;
    highlightedContent: GqlHyperText[];
    documentPath: string;
    title: string[];
    authors: string[];
    accessible: boolean;
  };
}

export interface ContributorSearchResult {
  node: {
    __typename: SearchResultTypes.CONTRIBUTORS;
    highlightedContent: GqlHyperText[];
    documentPath: string;
    title: string[];
    contributor: SearchContributorEdge;
  };
}

export type SearchResultEdge =
  | TextbookSearchResult
  | ActFragmentSearchResult
  | ActExpressionSearchResult
  | JournalSearchResult
  | JournalArticleSearchResult
  | TextbookFragmentSearchResult
  | PublishedPageSearchResult
  | PublishedContractSearchResult
  | NikDocumentSearchResult
  | ContributorSearchResult;

export interface GqlSearchResult {
  pageInfo: {
    hasNextPage: boolean;
    endCursor: string;
    totalSearchResults: number;
    docTypesResultCount: AggregationsResultCount[];
    contributorsResultCount: AggregationsResultCount[];
    actShortTitleResultCount: AggregationsResultCount[];
    journalTitleResultCount: AggregationsResultCount[];
    textbookEditionTitleResultCount: AggregationsResultCount[];
    contentCategoryNameResultCount: AggregationsResultCount[];
  };
  error: GqlError | null;
  edges: SearchResultEdge[];
}

export interface AggregationsResultCount {
  count: number;
  type: string;
}

export interface GqlSearchResponse {
  search: GqlSearchResult;
}

export interface TextWithModifiers {
  text: string;
  color?: ColorTokenValues;
}

export interface SearchHit {
  highlights: GqlHyperText[];
  title: string;
  id: string;
  typeDisplayName: string;
  subtitle: TextWithModifiers[];
  pathTitle: string | null;
  linkPath: string | null;
  coverUrl: string | null;
  accessible: boolean;
  favoriteButtonProps: FavoriteButtonProps | null;
}

export const placeholderForSearch = (future: GqlResult.Future<GqlSearchResult> | null): GqlSearchResult => {
  if (future && future.type === GqlResult.Type.Ok) {
    return future.data;
  }

  return {
    pageInfo: {
      hasNextPage: false,
      endCursor: '',
      totalSearchResults: 0,
      docTypesResultCount: [],
      contributorsResultCount: [],
      actShortTitleResultCount: [],
      journalTitleResultCount: [],
      textbookEditionTitleResultCount: [],
      contentCategoryNameResultCount: [],
    },
    error: null,
    edges: [],
  };
};
