import Flex from 'components/ui/Flex';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';

export const buttonDarkPurpleStyles = css`
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
  color: ${({ theme }) => theme.colorTokens.default.white};
  opacity: 1;

  svg * {
    fill: ${({ theme }) => theme.colorTokens.default.white};
  }
`;

export const buttonLightPurpleStyles = css`
  background-color: ${({ theme }) => theme.colorTokens.purple[300]};
  border: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
  color: ${({ theme }) => theme.colorTokens.purple[700]};
  opacity: 1;

  svg * {
    fill: ${({ theme }) => theme.colorTokens.purple[700]};
  }
`;

export const buttonLightPurpleFocusStyles = css`
  :focus-visible {
    ${buttonLightPurpleStyles}
  }
`;

export const buttonDarkPurpleFocusStyles = css`
  :focus-visible {
    ${buttonDarkPurpleStyles}
  }
`;

export const buttonHoverStyles = css`
  :hover {
    ${buttonLightPurpleStyles}
  }
`;

export const HeaderActionsCss = styled(Flex)`
  flex-grow: 0;

  padding: 1rem 1.25rem;
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[600]};
  position: relative;
`;

export const ButtonCss = styled.button<{ $isActive: boolean }>`
  ${buttonWithoutStyle}

  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0.375rem 0.625rem;
  margin-right: 1rem;

  border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
  color: ${({ theme }) => theme.colorTokens.purple[700]};

  ${({ $isActive }) => $isActive && buttonDarkPurpleStyles}

  ${buttonLightPurpleFocusStyles}

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purple[700]};
  }

  ${buttonHoverStyles}
`;
