import { JuridikaIdToken } from '../../models/jwt/JuridikaIdToken';

export enum TokenSource {
  USER = 'USER',
  FEIDE = 'FEIDE',
  IP = 'IP',
  SERVER = 'SERVER',
}

export interface SessionState {
  readonly accessToken?: string;
  readonly idToken?: string;
  readonly idTokenPayload?: JuridikaIdToken;
  readonly expiresIn?: number;
  readonly tokenType?: string;
  readonly tokenSource: TokenSource | undefined;
}
