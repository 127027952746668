import { css } from 'styled-components';

export const listWithoutStyle = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const buttonWithoutStyle = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  text-align: left;
`;

export const linkWithoutStyle = css`
  text-decoration: none;
  color: inherit;
`;

export const inputWithoutStyle = css`
  background: transparent;
  border: none;
  outline: none;
`;
