// Get contextual info before a a specific string index,
// e.g. for displaying as a search match.
export function getContextBefore(string: string, index: number, maxChars: number, maxWords: number) {
  let charCount = 0;
  let wordCount = -1;
  let wordStart = -1;
  let start = index;
  while (charCount < maxChars && wordCount < maxWords && start > 0) {
    const char = string[start - 1];
    if (char === ' ' && string[start] !== ' ') {
      wordCount += 1;
      wordStart = start;
    }
    start -= 1;
    charCount += 1;
  }
  if (wordStart > 0) {
    return `... ${string.slice(wordStart, index)}`;
  }
  return string.slice(Math.max(index - maxChars, 0), index);
}

// Get contextual info before a a specific string index,
// e.g. for displaying as a search match.
export function getContextAfter(string: string, index: number, maxChars: number, maxWords: number) {
  let charCount = 0;
  let wordCount = -1;
  let wordEnd = -1;
  let end = index;
  while (charCount < maxChars && wordCount < maxWords && end < string.length - 1) {
    const char = string[end + 1];
    if (char === ' ' && string[end] !== ' ') {
      wordCount += 1;
      wordEnd = end + 1;
    }
    end += 1;
    charCount += 1;
  }
  if (wordEnd > 0) {
    return `${string.slice(index, wordEnd)} ...`;
  }
  return `${string.slice(index, Math.min(index + maxChars, string.length))} ...`;
}

export const normalizeWhitespace = (str: string): string => {
  return str.replace(/\s+/g, ' ').trim();
};

// Sanitize the title used in <title/>.
// in Certain Browsers the soft hyphen is not hidden.
export const sanitizeTitle = (title: string) => title.replace(/\u00ad/g, '');

export const capitalizeFirstLetter = (str: string) => [str.charAt(0).toLocaleUpperCase(), str.slice(1)].join('');

export const capitalize = (str: string) =>
  str
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
