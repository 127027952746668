import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { getReloadUrlPath } from './urlHelpers';

/**
 * Previously we used the version from package.json
 * where we used semantic versioning, but now we use
 * the GIT_SHA as a temporary solution. The reason
 * for this is that the step for updating the
 * package.json version was lost in in the
 * infrastructure migration. See the old jenkisfile
 * for reference.
 */
const currentFrontendVersion = process.env.GIT_SHA;

export function reloadJuridika(): void {
  window.location.replace(getReloadUrlPath());
}

/**
 * Reloads page when bundle does not exist.
 * (by default after 1 hour if interval is not specified).
 * This will force-reload old tab.
 */

export function reloadPageWhenBundleChanges(juridikaConfig: JuridikaConfig, interval = 3600000): void {
  if (juridikaConfig.isServer || juridikaConfig.isDebug) {
    return;
  }

  setInterval(async () => {
    try {
      const actualFrontendVersion = await (await fetch('/version')).text();
      if (currentFrontendVersion !== actualFrontendVersion) {
        reloadJuridika();
      }
    } catch (error) {
      console.warn(
        `Failed while trying to fetch version from remote server, current version is '${currentFrontendVersion}'`,
        error
      );
    }
  }, interval);
}
