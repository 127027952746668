import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'util/hooks/useSelector';

interface Breakpoints {
  [size: string]: number;
}

export const useViewportSize = <B extends Breakpoints>(breakpoints: B, defaultViewportSize: keyof B): keyof B => {
  const hasBeenHydrated = useSelector((state) => state.viewport.hasBeenHydrated);
  const [viewportSize, setViewportSize] = useState<keyof B>(defaultViewportSize);

  const computeViewportSize = useCallback((): keyof B => {
    if (!hasBeenHydrated) return defaultViewportSize;

    const entries = Object.entries(breakpoints).sort((a, b) => a[1] - b[1]);
    const { innerWidth } = window;

    for (let i = entries.length - 1; i >= 0; i -= 1) {
      if (innerWidth >= entries[i][1]) {
        return entries[i][0];
      }
    }

    return defaultViewportSize;
  }, [breakpoints, defaultViewportSize, hasBeenHydrated]);

  useEffect(() => {
    if (hasBeenHydrated) {
      setViewportSize(computeViewportSize());
    }
    const updateViewportSize = () => setViewportSize(computeViewportSize());
    window.addEventListener('resize', updateViewportSize);
    return () => window.removeEventListener('resize', updateViewportSize);
  }, [computeViewportSize, hasBeenHydrated]);

  if (useJuridikaConfig().isServer) {
    return defaultViewportSize;
  }

  return viewportSize;
};
