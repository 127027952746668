import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { TYPE, ELEMENT, CHILD, VALUE, CHILDREN } from 'models/dom/domNode';
import { ResourceStickerType } from 'pages/NIKPage/hooks/useResourceStickers';
import { ResourceLink } from 'pages/NIKPage/types';
import React, {
  Attributes,
  ReactElement,
  createElement,
  Fragment,
} from 'react';
import {
  HypertextNode,
  TAGNAME,
  HTML_ATTRIBUTES,
} from 'util/hypertextAllocator';
import {
  renderText,
  renderChildrenOf,
  RenderContext,
} from 'util/hypertextRendering';
import BoxedContent from './BoxedContent';
import OpenResourceStickerButton from '../ResourceStickers/OpenResourceStickerButton';
import InlineFootnote from './InlineFootnote';
import { NIKQuoteSectionCss, TableWrapperCss } from './styles';

export interface ButtonOverrideAttributes extends Attributes {
  onClickResourceLink?: (resourceLink: ResourceLink) => void;
  onHoverResourceLink?: (legalUri: string, fragmentId: string) => void;
  [key: string]: any; // For additional properties that are not fixed
}

export const renderNode = (
  node: HypertextNode,
  props: Attributes | null = null,
  overrides: (node: HypertextNode) => ButtonOverrideAttributes | null = () =>
    null,
  renderContext: RenderContext = {
    level: 1,
    currentPartId: '',
    paragraphId: '',
  }
): ReactElement | null => {
  switch (node[TYPE]) {
    case ELEMENT: {
      switch (node[TAGNAME]) {
        // skip element from rendering in DOM and render children instead
        case 'dynamic-document':
        case 'body':
        case 'paragraph': {
          return createElement(
            Fragment,
            {},
            ...renderChildrenOf(node, renderNode, overrides, {
              level: renderContext.level,
              currentPartId: renderContext.currentPartId,
              paragraphId: node[HTML_ATTRIBUTES]?.id || '',
            })
          );
        }
        case 'part': {
          const { [HTML_ATTRIBUTES]: htmlAttrs } = node;
          return createElement(
            Fragment,
            {},
            ...renderChildrenOf(node, renderNode, overrides, {
              level: renderContext.level + 1,
              currentPartId: htmlAttrs?.id,
            })
          );
        }
        case 'list': {
          const { [HTML_ATTRIBUTES]: htmlAttrs } = node;

          const getListElement = () => {
            switch (htmlAttrs.type) {
              case 'number':
              case 'roman':
                return 'ol';
              case 'bullet':
                return 'ul';
              default:
                return 'ul';
            }
          };

          const type = htmlAttrs.type === 'roman' ? 'I' : htmlAttrs.type;
          const listElement = getListElement();

          return createElement(
            listElement,
            {
              ...node[HTML_ATTRIBUTES],
              type,
              ...props,
              ...overrides(node),
            },
            ...renderChildrenOf(node, renderNode, overrides, renderContext)
          );
        }
        case 'list-item': {
          return createElement(
            'li',
            {
              ...node[HTML_ATTRIBUTES],
              ...props,
              ...overrides(node),
            },
            ...renderChildrenOf(node, renderNode, overrides, renderContext)
          );
        }
        case 'quote': {
          return (
            <q>
              {renderChildrenOf(node, renderNode, overrides, renderContext)}
            </q>
          );
        }
        // stop element and all its children from being rendered in the DOM
        case 'back':
        case 'num': {
          return null;
        }
        case 'p': {
          return createElement(
            'p',
            {
              id: renderContext.paragraphId || '',
            },
            ...renderChildrenOf(node, renderNode, overrides, renderContext)
          );
        }
        case 'title': {
          const HTagLevel = `h${renderContext.level}`;
          return createElement(
            HTagLevel,
            {
              id: renderContext.currentPartId || '',
            },
            ...renderChildrenOf(node, renderNode, overrides, renderContext)
          );
        }
        case 'block-quote': {
          const { [HTML_ATTRIBUTES]: htmlAttrs } = node;
          const cite = htmlAttrs?.cite;
          return (
            <NIKQuoteSectionCss cite={cite}>
              {renderChildrenOf(node, renderNode, overrides, renderContext)}
            </NIKQuoteSectionCss>
          );
        }
        case 'a': {
          const child = node[CHILD];

          if (child) {
            const label = child[VALUE] || '';
            const { [HTML_ATTRIBUTES]: htmlAttrs } = node;

            const { href } = htmlAttrs;
            const hrefParts = href.split(/[:/]+/);
            const isResourceLink = hrefParts[0] === 'internal';
            if (isResourceLink) {
              const legalUri = hrefParts.slice(1, -1).join('/');

              const fragmentId = hrefParts[hrefParts.length - 1];

              const typeOfResource = () => {
                switch (hrefParts[3]) {
                  case 'act': {
                    switch (hrefParts[4]) {
                      case 'lov':
                        return ResourceStickerType.LawFragment;
                      case 'forskrift':
                        return ResourceStickerType.Regulation;
                      case 'direktiv':
                        return ResourceStickerType.Directive;
                      default:
                        return ResourceStickerType.Regulation;
                    }
                  }
                  case 'judgment':
                    return ResourceStickerType.Judgment;
                  default:
                    return ResourceStickerType.Regulation;
                }
              };

              const resourceLink: ResourceLink = {
                type: typeOfResource(),
                id: `${legalUri}#${fragmentId}`,
                legalUri,
                fragmentId,
              };

              const overrideProps = { ...overrides(node) };
              const { onClickResourceLink, onHoverResourceLink } =
                overrideProps;

              if (onClickResourceLink && onHoverResourceLink) {
                return (
                  <OpenResourceStickerButton
                    onClick={() => onClickResourceLink(resourceLink)}
                    onMouseOver={() =>
                      onHoverResourceLink(
                        resourceLink.legalUri,
                        resourceLink.fragmentId
                      )
                    }
                    label={label}
                  />
                );
              }
            }
            // render normal links
            else {
              return createElement(
                'a',
                {
                  ...node[HTML_ATTRIBUTES],
                  ...props,
                  ...overrides(node),
                },
                ...renderChildrenOf(node, renderNode, overrides, renderContext)
              );
            }
          }
        }
        case 'table': {
          return (
            <TableWrapperCss>
              {createElement(
                node[TAGNAME],
                {
                  ...node[HTML_ATTRIBUTES],
                  ...props,
                  ...overrides(node),
                },
                ...renderChildrenOf(node, renderNode, overrides, renderContext)
              )}
            </TableWrapperCss>
          );
        }
        case 'note': {
          return (
            <InlineFootnote type="information">
              {renderChildrenOf(node, renderNode, overrides, renderContext)}
            </InlineFootnote>
          );
        }
        case 'reference': {
          return (
            <InlineFootnote type="reference">
              {renderChildrenOf(node, renderNode, overrides, renderContext)}
            </InlineFootnote>
          );
        }
        case 'boxed-content': {
          const label =
            node[CHILDREN]?.find((child) => child[TAGNAME] === 'label')?.[
              CHILD
            ]?.[VALUE] || '';

          const content = {
            ...node,
            [CHILDREN]: node[CHILDREN]?.filter(
              (child) => child[TAGNAME] !== 'label'
            ),
          };
          return (
            <BoxedContent label={label}>
              {renderChildrenOf(content, renderNode, overrides, renderContext)}
            </BoxedContent>
          );
        }
        case 'math': {
          return (
            <MathJaxContext
              version={3}
              config={{
                loader: { load: ['input/mml', 'output/chtml'] },
                mml: {},
              }}
            >
              <MathJax inline hideUntilTypeset="first">
                {createElement(
                  node[TAGNAME],
                  {},
                  ...renderChildrenOf(
                    node,
                    renderNode,
                    overrides,
                    renderContext
                  )
                )}
              </MathJax>
            </MathJaxContext>
          );
        }
        default: {
          return createElement(
            node[TAGNAME],
            {
              ...node[HTML_ATTRIBUTES],
              ...props,
              ...overrides(node),
            },
            ...renderChildrenOf(node, renderNode, overrides, renderContext)
          );
        }
      }
    }
    default: {
      return renderText(node, props);
    }
  }
};
