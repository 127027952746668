import Svg from 'components/ui/Svg';
import * as React from 'react';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const VideoPlay: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.4465 12.0668C16.0617 11.7141 16.8246 11.6566 17.4316 12.0996L29.2441 19.3184C29.8266 19.6793 30.1875 20.3109 30.1875 21C30.1875 21.6891 29.8266 22.3207 29.2441 22.6816L17.4316 29.9004C16.8246 30.2695 16.0617 30.2859 15.4465 29.9332C14.823 29.5887 14.4375 28.9324 14.4375 28.2188V13.7074C14.4375 13.0676 14.823 12.4113 15.4465 12.0668ZM42 21C42 32.5992 32.5992 42 21 42C9.40078 42 0 32.5992 0 21C0 9.40078 9.40078 0 21 0C32.5992 0 42 9.40078 42 21ZM21 3.9375C11.5746 3.9375 3.9375 11.5746 3.9375 21C3.9375 30.4254 11.5746 38.0625 21 38.0625C30.4254 38.0625 38.0625 30.4254 38.0625 21C38.0625 11.5746 30.4254 3.9375 21 3.9375Z"
      fill={color}
    />
  </Svg>
);

VideoPlay.defaultProps = {
  color: COLOR_TOKENS.purple[400],
};
export default VideoPlay;
