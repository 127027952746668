import * as React from 'react';
import styled from 'styled-components';

import { CLOUDFRONT_LITERATURE_FILES_PUBLIC_URL, JuridikaConfig, useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { closestWidth, LiteratureEditionAvailability } from 'util/literatureUtils';
import { COLOR_TOKENS, COLORS } from 'theme/config/defaultTheme';

import Text from 'components/ui/Text';
import Flex, { FlexPropTypes } from 'components/ui/Flex';

import missingBookCover from '../../images/missing_book_cover.svg';

export const getEditionCoverUrl = (
  juridikaConfig: JuridikaConfig,
  { id, hasCover }: { id: string; hasCover: boolean },
  width: number
): string | null => {
  if (hasCover) {
    const cloudFrontUrl = juridikaConfig.getString(CLOUDFRONT_LITERATURE_FILES_PUBLIC_URL);
    return `${cloudFrontUrl}/editions/${id}/cover-${closestWidth(width)}px`;
  }

  return null;
};

interface TextBannerPropTypes {
  bannerColor: string;
}

export const getEditionCoverUrlWithFallback = (
  juridikaConfig: JuridikaConfig,
  props: { id: string; hasCover: boolean },
  width: number
): string => getEditionCoverUrl(juridikaConfig, props, width) ?? missingBookCover;

const MediumCoverImageCss = styled.img`
  display: block;
  max-width: 100%;
  position: relative;
  border: solid 0.0625rem ${({ theme }) => theme.colorTokens.grey[400]};
`;

const ExtraExtraLargeCoverImageCss = styled.img`
  display: block;
  width: 8.9375rem;
  position: relative;
  border: solid 0.0625rem ${({ theme }) => theme.colorTokens.grey[400]};
`;

export const TextBannerWrapperCss = styled.div`
  position: relative;
`;

export const TextBannerContainerCss = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 170px;
  height: 170px;
  overflow: hidden;
`;

export const TextBannerCss = styled(Flex)<FlexPropTypes & TextBannerPropTypes>`
  transform: rotate(-45deg);
  margin-bottom: -85px;
  margin-right: -85px;
  width: 170px;
  background-color: ${({ bannerColor }) => bannerColor};
`;

export const EditionCover: React.FC<{
  id: string;
  availability: LiteratureEditionAvailability;
  hasCover: boolean;
  coverWidth: number;
  alt: string;
  isExtraExtraLarge?: boolean;
  isPartiallyPublished?: boolean;
}> = (props) => {
  const juridikaConfig = useJuridikaConfig();

  const { isPartiallyPublished, availability, coverWidth: width, alt, isExtraExtraLarge } = props;

  const CoverImage = isExtraExtraLarge ? ExtraExtraLargeCoverImageCss : MediumCoverImageCss;

  const banner = {
    label: '',
    color: 'none',
  };

  if (availability === 'ANNOUNCED') {
    banner.label = 'Kommer';
    const {
      red: { 600: color },
    } = COLOR_TOKENS;
    banner.color = color;
  } else if (availability === 'PREVIEW') {
    banner.label = 'Sniktitt';
    const {
      purple: { 500: color },
    } = COLOR_TOKENS;
    banner.color = color;
  } else if (isPartiallyPublished) {
    banner.label = 'Delpublisert';
    const {
      purple: { 500: color },
    } = COLOR_TOKENS;
    banner.color = color;
  }

  if (banner.label && banner.color) {
    return (
      <TextBannerWrapperCss>
        <CoverImage
          src={getEditionCoverUrlWithFallback(juridikaConfig, props, width)}
          alt={alt}
          loading="lazy"
          role="presentation"
        />{' '}
        <TextBannerContainerCss>
          <TextBannerCss py1 justify="center" bannerColor={banner.color}>
            <Text small uppercase weight={600} color={COLORS.white}>
              {banner.label}
            </Text>
          </TextBannerCss>
        </TextBannerContainerCss>
      </TextBannerWrapperCss>
    );
  }

  return (
    <CoverImage src={getEditionCoverUrlWithFallback(juridikaConfig, props, width)} alt={alt} loading="lazy" role="presentation" />
  );
};
