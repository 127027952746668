import React from 'react';

const Diamond: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21">
    <path
      fill="#e00e37"
      fillRule="nonzero"
      d="M2.692 8.076l7.909 8.392-3.809-8.392h-4.1zM13 17.82l4.43-9.743H8.57L13 17.82zM9.42 1.615H6.094l1.663 3.142L9.42 1.615zM5.14 2.978L2.437 6.461H6.83L5.14 2.978zm10.26 13.49l7.908-8.392h-4.1l-3.809 8.392zM8.67 6.461h8.658l-2.59-4.846h-3.478L8.67 6.461zm11.061 0h3.83l-2.587-3.627-1.243 3.627zm-1.277-1.284l1.45-3.562h-3.43l1.98 3.562zM20.96.328l4.875 6.461a.77.77 0 0 1 .165.524.768.768 0 0 1-.216.511L13.597 20.746A.767.767 0 0 1 13 21a.767.767 0 0 1-.597-.253L.217 7.824A.768.768 0 0 1 0 7.313a.77.77 0 0 1 .165-.524L5.04.33A.743.743 0 0 1 5.688 0h14.624c.28 0 .495.11.647.328z"
    />
  </svg>
);

export default Diamond;
