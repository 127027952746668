import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from './IconPropTypes';

const Checked: React.FC<IconPropTypes & { width?: number; height?: number; y?: number; x?: number }> = ({
  color,
  width,
  height,
  size,
  ...props
}) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width || size} height={height || size} viewBox="0 0 21 16" {...props}>
    <path fill={color} fillRule="evenodd" d="M7.174 12.03L2.433 7.288.818 8.89l6.356 6.357L20.818 1.603 19.215 0 7.175 12.03" />
  </Svg>
);

Checked.defaultProps = {
  color: '#00C5BD',
  width: 21,
  height: 16,
  size: 16,
};

export default Checked;
