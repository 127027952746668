import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import PageviewStatisticsLogger from 'containers/StatisticsLogger/PageviewStatisticsLogger';
import { useSelector } from 'util/hooks/useSelector';
import { RootState } from 'state/types';
import { authenticatedSelector } from 'state/login/loginSelectors';
import { Throw } from 'components/error/Throw';
import { JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import CurrentCourseView from './CurrentCourseView';
import useEffectOpenCloseSidebarBasedOnViewportSize from '../../hooks/useEffectOpenCloseSidebarBasedOnViewportSize';

const CourseModulePage: FC = () => {
  const isAuthenticated = useSelector((state: RootState) => authenticatedSelector(state));

  useEffectOpenCloseSidebarBasedOnViewportSize();

  return (
    <>
      <Helmet titleTemplate="%s | Akademi" />

      <PageviewStatisticsLogger title="Juridika Akademi" />

      <CurrentCourseView />

      {/* TODO: Do this on layout level */}
      {!isAuthenticated && <Throw error={{ type: JuridikaErrorType.GRAPHQL_UNAUTHENTICATED }} />}
    </>
  );
};

export default CourseModulePage;
