import * as React from 'react';
import { DocumentNode } from 'graphql';
import * as apolloClient from '@apollo/client';
import { WatchQueryFetchPolicy, NetworkStatus } from '@apollo/client';

import { JuridikaConfig, useJuridikaConfig } from 'commonUtils/juridikaConfig';

import { AuthenticationStatus } from 'state/login/types';

import { ApolloInteractionState, ApolloInteraction, CTX_INTERACTION } from '../apolloClient';

import { useSelector } from './useSelector';

/**
 * Extra data we send along with the apollo query,
 * information which apollo doesn't give away easily.
 */
export interface RequestMetadata {
  /**
   * The authStatus that was current when the last request was sent
   * Why do we need this?
   * When authStatus changes, we'll try to refetch visible queries.
   * We have a need to see these two things (the query result and the authStatus)
   * in a synchronized way:
   *
   * time:            1---------------2------------3-------------4-------------->
   * authStatus:      UNKNOWN--------------------->AUTHENTICATED---------------->
   * query in flight: [query w/o token]            [query w/token]
   * query result:                    result1------------------->result2-------->
   *
   * The problem is at point 3, when we are authenticated and refetching the query,
   * the components will still see the data of the previous query.
   * Only at point 4, when the second query is finished, we'll observe synchronized states.
   * This isn't possible to do using using useSelector for authStatus alone.
   */
  authStatus: AuthenticationStatus;
}

const mapFetchPolicy = (fetchPolicy: WatchQueryFetchPolicy | undefined, config: JuridikaConfig): WatchQueryFetchPolicy => {
  // If on the server, we should always use cache-first.
  // This means check the cache and fetch if not in the cache.
  // This is because there are several rendering passes, and we
  // only want to fetch each resource once.
  if (!fetchPolicy || config.isServer) return 'cache-first';

  return fetchPolicy;
};

/**
 * MachineState
 *
 * States for the finite state machine that is useApolloQuery.
 */
enum MachineState {
  INITIAL = 0,

  // *_READY: States when the machine is "at rest". Queries have been refetched
  // and all is in sync with the system's authStatus:

  // The frontend is initializing: It has no authentication (yet) but is working on
  // acquiring one, but anyway we currently have cached data that reflects not being
  // authenticated:
  UNKNOWN_RESPONSE_READY = 1,
  // The frontend definitely has no authentication and query cache reflects it:
  UNAUTHENTICATED_RESPONSE_READY = 2,
  // The frontend has an active authentication, and a query has been successfully
  // executed with the authentication token:
  AUTHENTICATED_RESPONSE_READY = 3,

  // *_READY_FOR_REFETCH means that cached query data is outdated, we want to refetch,
  // and there is _currently_ nothing blocking apollo from refetching:
  UNKNOWN_READY_FOR_REFETCH = 4,
  FIRST_AUTHENTICATED_READY_FOR_REFETCH = 5,
  AUTHENTICATED_READY_FOR_REFETCH = 6,
  UNAUTHENTICATED_READY_FOR_REFETCH = 7,

  // *_IN_FLIGHT means that a request, for any reason, is currently ongoing, and
  // that request is assumed to be a request that will lead directly to our "at rest"
  // state when it finishes.
  UNKNOWN_REQUEST_IN_FLIGHT = 8,
  FIRST_AUTHENTICATED_REQUEST_IN_FLIGHT = 9,
  AUTHENTICATED_REQUEST_IN_FLIGHT = 10,
  UNAUTHENTICATED_REQUEST_IN_FLIGHT = 11,

  // *_QUEUED: cached query data is outdated, but _right now_ we are prevented from
  // refetching the query. Therefore the refetch is put "in queue" and scheduled for later.
  // It might be because of one or both of the following reasons:
  // 1. we are explicitly prevented from refetching, because of skip=true or lazy query.
  // 2. there is another request already in flight that does not lead to our "at rest" state.
  UNKNOWN_REFETCH_QUEUED = 12,
  UNAUTHENTICATED_REFETCH_QUEUED = 13,
  FIRST_AUTHENTICATED_REFETCH_QUEUED = 14,
  AUTHENTICATED_REFETCH_QUEUED = 15,
}

interface StateMachineInput {
  prevAuthStatus: AuthenticationStatus;
  authStatus: AuthenticationStatus;
  interactionState: ApolloInteractionState;
  skipped: boolean;
}

const transition = (state: MachineState, input: StateMachineInput): MachineState => {
  const { prevAuthStatus, authStatus, interactionState, skipped } = input;
  const inFlight = interactionState === ApolloInteractionState.InFlight;

  const authenticated = authStatus === AuthenticationStatus.AUTHENTICATED;

  const deriveUnknownFromInput = (): MachineState => {
    if (inFlight) {
      return MachineState.UNKNOWN_REQUEST_IN_FLIGHT;
    }
    return MachineState.UNKNOWN_RESPONSE_READY;
  };

  const deriveAuthenticatedFromInput = (): MachineState => {
    if (inFlight && prevAuthStatus === AuthenticationStatus.UNKNOWN) {
      return MachineState.FIRST_AUTHENTICATED_REQUEST_IN_FLIGHT;
    }
    if (inFlight) {
      return MachineState.AUTHENTICATED_REQUEST_IN_FLIGHT;
    }
    return MachineState.AUTHENTICATED_RESPONSE_READY;
  };

  const deriveUnauthenticatedFromInput = (): MachineState => {
    if (inFlight) {
      return MachineState.UNAUTHENTICATED_REQUEST_IN_FLIGHT;
    }
    return MachineState.UNAUTHENTICATED_RESPONSE_READY;
  };

  const deriveFromInput = (): MachineState => {
    switch (authStatus) {
      case AuthenticationStatus.UNKNOWN:
        return deriveUnknownFromInput();
      case AuthenticationStatus.NOT_AUTHENTICATED:
        return deriveUnauthenticatedFromInput();
      case AuthenticationStatus.AUTHENTICATED:
        return deriveAuthenticatedFromInput();
    }
  };

  switch (state) {
    case MachineState.INITIAL:
      return deriveFromInput();

    case MachineState.UNKNOWN_READY_FOR_REFETCH:
      switch (interactionState) {
        case ApolloInteractionState.Queued:
          return state;
        case ApolloInteractionState.InFlight:
          return MachineState.FIRST_AUTHENTICATED_REQUEST_IN_FLIGHT;
        case ApolloInteractionState.Ready:
          return deriveFromInput();
      }

    case MachineState.AUTHENTICATED_READY_FOR_REFETCH:
      switch (interactionState) {
        case ApolloInteractionState.Queued:
          return state;
        case ApolloInteractionState.InFlight:
          return MachineState.UNAUTHENTICATED_REQUEST_IN_FLIGHT;
        case ApolloInteractionState.Ready:
          return deriveFromInput();
      }

    case MachineState.FIRST_AUTHENTICATED_READY_FOR_REFETCH:
      switch (interactionState) {
        case ApolloInteractionState.Queued:
          return state;
        case ApolloInteractionState.InFlight:
          return MachineState.FIRST_AUTHENTICATED_REQUEST_IN_FLIGHT;
        case ApolloInteractionState.Ready:
          return deriveFromInput();
      }

    case MachineState.UNAUTHENTICATED_READY_FOR_REFETCH:
      switch (interactionState) {
        case ApolloInteractionState.Queued:
          return state;
        case ApolloInteractionState.InFlight:
          return MachineState.AUTHENTICATED_REQUEST_IN_FLIGHT;
        case ApolloInteractionState.Ready:
          return deriveFromInput();
      }

    case MachineState.UNKNOWN_REQUEST_IN_FLIGHT:
      if (authenticated) {
        return MachineState.FIRST_AUTHENTICATED_REFETCH_QUEUED;
      }
      return deriveFromInput();

    case MachineState.UNAUTHENTICATED_REQUEST_IN_FLIGHT:
      if (authenticated) {
        return MachineState.AUTHENTICATED_REFETCH_QUEUED;
      }
      return deriveFromInput();

    case MachineState.FIRST_AUTHENTICATED_REQUEST_IN_FLIGHT:
      switch (interactionState) {
        case ApolloInteractionState.InFlight:
          return state;
        default:
          return deriveFromInput();
      }

    case MachineState.AUTHENTICATED_REQUEST_IN_FLIGHT:
      if (!authenticated) {
        return MachineState.UNAUTHENTICATED_REFETCH_QUEUED;
      }
      return deriveFromInput();

    case MachineState.UNKNOWN_RESPONSE_READY:
      if (authenticated) {
        if (skipped) return MachineState.FIRST_AUTHENTICATED_REFETCH_QUEUED;
        return MachineState.FIRST_AUTHENTICATED_READY_FOR_REFETCH;
      }
      if (authStatus === AuthenticationStatus.NOT_AUTHENTICATED) {
        return MachineState.UNAUTHENTICATED_RESPONSE_READY;
      }
      return state;

    case MachineState.UNAUTHENTICATED_RESPONSE_READY:
      if (authenticated) {
        if (skipped) return MachineState.AUTHENTICATED_REFETCH_QUEUED;
        return MachineState.AUTHENTICATED_READY_FOR_REFETCH;
      }
      return state;

    case MachineState.AUTHENTICATED_RESPONSE_READY:
      if (!authenticated) {
        if (skipped) return MachineState.UNAUTHENTICATED_REFETCH_QUEUED;
        return MachineState.UNAUTHENTICATED_READY_FOR_REFETCH;
      }
      return state;

    case MachineState.UNKNOWN_REFETCH_QUEUED:
      if (authenticated) {
        if (inFlight || skipped) return MachineState.FIRST_AUTHENTICATED_REFETCH_QUEUED;
        return MachineState.AUTHENTICATED_READY_FOR_REFETCH;
      }
      if (inFlight || skipped) {
        return state;
      }
      return deriveFromInput();

    case MachineState.UNAUTHENTICATED_REFETCH_QUEUED: {
      if (authenticated) {
        // Quick flicker/glitching between NO_AUTH/AUTH?
        // just try to find an "in rest" state again:
        if (inFlight || skipped) return deriveFromInput();
        return MachineState.AUTHENTICATED_READY_FOR_REFETCH;
      }
      if (inFlight || skipped) return state;
      return deriveUnauthenticatedFromInput();
    }

    case MachineState.FIRST_AUTHENTICATED_REFETCH_QUEUED:
      if (!authenticated) {
        if (inFlight || skipped) {
          // LOL, we've lost authentication while an _authenticated refetch_
          // is QUEUED. Does this mean we're suddenly back to an "in rest" state?
          // Not sure. We have to tweak a bit over time to find the right logic, I guess
          return deriveFromInput();
        }
        return MachineState.FIRST_AUTHENTICATED_READY_FOR_REFETCH;
      }
      if (inFlight || skipped) return state;
      return MachineState.FIRST_AUTHENTICATED_READY_FOR_REFETCH;

    case MachineState.AUTHENTICATED_REFETCH_QUEUED:
      if (!authenticated) {
        if (inFlight || skipped) return deriveFromInput();
        // trigger
        return MachineState.UNAUTHENTICATED_READY_FOR_REFETCH;
      }
      if (inFlight || skipped) return state;
      return MachineState.AUTHENTICATED_READY_FOR_REFETCH;
  }
};

/**
 * The current machine state should be enough to fully describe
 * the authStatus of the previous successful fetch
 */
const getLastResponseAuthStatus = (state: MachineState): AuthenticationStatus => {
  switch (state) {
    case MachineState.INITIAL:
    case MachineState.UNKNOWN_RESPONSE_READY:
    case MachineState.UNKNOWN_READY_FOR_REFETCH:
    case MachineState.UNKNOWN_REFETCH_QUEUED:
    case MachineState.UNKNOWN_REQUEST_IN_FLIGHT:
    case MachineState.FIRST_AUTHENTICATED_REFETCH_QUEUED:
    case MachineState.FIRST_AUTHENTICATED_READY_FOR_REFETCH:
    case MachineState.FIRST_AUTHENTICATED_REQUEST_IN_FLIGHT:
      return AuthenticationStatus.UNKNOWN;

    case MachineState.AUTHENTICATED_RESPONSE_READY:
    case MachineState.UNAUTHENTICATED_REFETCH_QUEUED:
    case MachineState.UNAUTHENTICATED_READY_FOR_REFETCH:
    case MachineState.UNAUTHENTICATED_REQUEST_IN_FLIGHT:
      return AuthenticationStatus.AUTHENTICATED;

    case MachineState.UNAUTHENTICATED_RESPONSE_READY:
    case MachineState.AUTHENTICATED_READY_FOR_REFETCH:
    case MachineState.AUTHENTICATED_REQUEST_IN_FLIGHT:
    case MachineState.AUTHENTICATED_REFETCH_QUEUED:
      return AuthenticationStatus.NOT_AUTHENTICATED;
  }
};

const useRefetchOnAuthChange = <Result extends { refetch?: () => void; networkStatus: NetworkStatus }>(
  result: Result,
  interaction: ApolloInteraction,
  shouldRefetchNow: () => boolean,
  config: JuridikaConfig
): RequestMetadata => {
  // Storage for the state machine:
  const machineStateRef = React.useRef(MachineState.INITIAL);
  // Just a counter so we may force re-renders:
  const [_, setNextFakeRefetch] = React.useState(0);

  const machineInput: StateMachineInput = {
    prevAuthStatus: useSelector((state) => state.login.prevAuthStatus),
    authStatus: useSelector((state) => state.login.authStatus),
    interactionState: interaction.getState(),
    skipped: !shouldRefetchNow() || !result.refetch,
  };

  const previousMachineState = machineStateRef.current;
  const currentMachineState = transition(previousMachineState, machineInput);
  machineStateRef.current = currentMachineState;

  /*
  console.log(
    'transition ',
    previousMachineState,
    '=>',
    currentMachineState,
    ' input: ',
    machineInput
  );
  */

  if (config.isClient) {
    React.useEffect(() => {
      if (currentMachineState === previousMachineState) return;

      if (previousMachineState === MachineState.INITIAL) {
        // The first transition can have no side-effects.
        return;
      }

      switch (currentMachineState) {
        case MachineState.UNKNOWN_READY_FOR_REFETCH:
        case MachineState.FIRST_AUTHENTICATED_READY_FOR_REFETCH:
        case MachineState.AUTHENTICATED_READY_FOR_REFETCH:
        case MachineState.UNAUTHENTICATED_READY_FOR_REFETCH:
          // refetch should _REALLY_ be defined now, because state is
          // not *_QUEUED
          if (!result.refetch) {
            throw Error('expected refetch to be defined');
          }

          // So that we may track whether the refetch has actually started:
          interaction.enterQueuedState();

          result.refetch();
          break;
        case MachineState.UNAUTHENTICATED_RESPONSE_READY:
          if (previousMachineState === MachineState.UNKNOWN_RESPONSE_READY) {
            setNextFakeRefetch((n) => n + 1);
          }
        default:
          break;
      }

      // DEPS: interactionRef is a useRef, so it doesn't need to be a dependency
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMachineState, previousMachineState, result]);
  }

  const authStatus = getLastResponseAuthStatus(currentMachineState);

  /**
   * Return memoized version in so the object doesn't change at every render
   */
  return React.useMemo(() => ({ authStatus }), [authStatus]);
};

/**
 * apollo query custom hook
 * Ensures that the query is re-executed when authStatus changes.
 * Also includes an extra property `authStatus` which is the actual authentication status
 * used while fetching the most recently performed query.
 */
export const useApolloQuery = <Data, Variables>(
  query: DocumentNode,
  options?: apolloClient.QueryHookOptions<Data, Variables>
): [apolloClient.QueryResult<Data, Variables>, RequestMetadata] => {
  const config = useJuridikaConfig();
  const interaction = useApolloInteraction();

  const result = apolloClient.useQuery(query, {
    ...options,
    fetchPolicy: mapFetchPolicy((options || {}).fetchPolicy, config),
    context: {
      ...options?.context,
      [CTX_INTERACTION]: interaction,
    },
  });

  const { skip } = options || {};
  const shouldRefetchNow = React.useCallback(() => skip !== true, [skip]);

  return [result, useRefetchOnAuthChange(result, interaction, shouldRefetchNow, config)];
};

/**
 * apollo lazy query custom hook
 * Ensures that the query is re-executed when authStatus changes.
 */
export const useLazyApolloQuery = <Data, Variables>(
  query: DocumentNode,
  options?: apolloClient.QueryHookOptions<Data, Variables>
): [
  (options?: apolloClient.QueryLazyOptions<Variables>) => void,
  apolloClient.LazyQueryResult<Data, Variables>,
  RequestMetadata
] => {
  const config = useJuridikaConfig();
  const interaction = useApolloInteraction();

  const lazyQueryResult = apolloClient.useLazyQuery(query, {
    ...options,
    fetchPolicy: mapFetchPolicy((options || {}).fetchPolicy, config),
    context: {
      ...options?.context,
      [CTX_INTERACTION]: interaction,
    },
  });
  const [execute, result] = lazyQueryResult;
  const shouldRefetchNow = React.useCallback(() => result.called === true, [result]);

  return [execute, result, useRefetchOnAuthChange(result, interaction, shouldRefetchNow, config)];
};

// Create a ref with an ApolloInteraction
const useApolloInteraction = (): ApolloInteraction => {
  const ref = React.useRef<ApolloInteraction | null>(null);
  if (ref.current === null) {
    const interaction = new ApolloInteraction();
    ref.current = interaction;
    return interaction;
  }

  return ref.current;
};
