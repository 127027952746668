import pino from 'pino';

export const createLogger = (name: string): pino.Logger =>
  pino({
    name,
    safe: true,
    level: 'info',
    timestamp: pino.stdTimeFunctions.isoTime,
    enabled: process.env.NO_PINO_LOGS !== 'true',
    formatters: {
      level: (label: string) => ({ level: label }),
    },
  });
