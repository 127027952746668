import * as React from 'react';
import styled from 'styled-components';

interface ModalOverlayProps {
  children: React.ReactNode | React.ReactNode[];
  ariaLabelledbyId: string;
  isOpen: boolean;
  className?: string;
}

const ModalOverlayCss = styled.div`
  position: fixed;
  padding: 0.5rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(16, 22, 26, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay: React.FC<ModalOverlayProps> = ({ ariaLabelledbyId, isOpen, className = '', children }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlayCss role="dialog" aria-labelledby={ariaLabelledbyId} aria-modal aria-hidden={!isOpen} className={className}>
      {children}
    </ModalOverlayCss>
  );
};

export default ModalOverlay;
