import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import useRouteMatchUrlWithoutSlash from 'pages/AcademyPage/hooks/useRouteMatchUrlWithoutSlash';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { Layout } from 'containers/Layouts/Layout';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';
import CourseModulePage from './CourseModulePage';

const CoursePage: React.FC = () => {
  const { path } = useRouteMatch();
  const url = useRouteMatchUrlWithoutSlash();
  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');
  const sidebarIsOpen = useSelector((state: RootState) => state.ui.akademi.sidebarIsOpen);

  const showFooter = viewportSize === 'large' || !sidebarIsOpen;

  return (
    <Layout hasToolbar={false} hasFooter={showFooter}>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${url}/intro`} />
        </Route>

        <Route path={`${path}/intro`}>
          <CourseModulePage />
        </Route>

        <Route path={`${path}/:chapterSlug/:moduleSlug`}>
          <CourseModulePage />
        </Route>
      </Switch>
    </Layout>
  );
};

export default CoursePage;
