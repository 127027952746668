import React from 'react';
import { HastNode, HastFragmentNode, isElement, hasClass, createFragment } from '@universitetsforlaget/hast';

import { HastFragment } from '../components/Hast';

import { HastConfig } from './hastConfig';

// NodeRule: match a single hast node
interface TagNameNodeRule {
  type: 'tagName';
  tagName: string;
}
interface ClassNameNodeRule {
  type: 'className';
  className: string;
}
interface PositionNodeRule {
  type: 'position';
  position: number;
}
interface BeforeNodeRule {
  type: 'before';
  ruleName: string;
}
interface AllOfNodeRule {
  type: 'allOf';
  rules: NodeRule[];
}
interface AnyOfNodeRule {
  type: 'anyOf';
  rules: NodeRule[];
}

type NodeRule = TagNameNodeRule | ClassNameNodeRule | PositionNodeRule | BeforeNodeRule | AllOfNodeRule | AnyOfNodeRule;

// FilterRule: filter an array of hast nodes
interface BaseFilterRule {
  nodeRule: NodeRule;
}
interface FirstFilterRule extends BaseFilterRule {
  type: 'first';
  max: number;
}
interface WhileFilterRule extends BaseFilterRule {
  type: 'while';
}
interface WhileReversedFilterRule extends BaseFilterRule {
  type: 'whileReversed';
}

type FilterRule = FirstFilterRule | WhileFilterRule | WhileReversedFilterRule;

/** Match an element tagName */
export const matchTagName = (tagName: string): TagNameNodeRule => ({ type: 'tagName', tagName });
/** Match an element className */
export const matchClassName = (className: string): ClassNameNodeRule => ({
  type: 'className',
  className,
});
/** Match a position in the array (negative counts from the end of the array) */
export const matchPosition = (position: number): PositionNodeRule => ({
  type: 'position',
  position,
});
/** Match at the position before another matching rule */
export const matchBefore = (ruleName: string): BeforeNodeRule => ({ type: 'before', ruleName });
/** Match all of the given rules (AND) */
export const matchAllOf = (...rules: NodeRule[]): AllOfNodeRule => ({ type: 'allOf', rules });
/** Match any of the given rules (OR) */
export const matchAnyOf = (...rules: NodeRule[]): AnyOfNodeRule => ({ type: 'anyOf', rules });

/** Take the first n node that matches the given rules, searching from the start of the array */
export const takeFirst = (n: number, nodeRule: NodeRule): FirstFilterRule => ({
  type: 'first',
  max: n,
  nodeRule,
});
/** Continue to take nodes as long as passed rule matches, searching from the start of the array */
export const takeWhile = (nodeRule: NodeRule): WhileFilterRule => ({ type: 'while', nodeRule });
/** Continue to take nodes as long as passed rule matches, searching from the end of the array */
export const takeWhileReversed = (nodeRule: NodeRule): WhileReversedFilterRule => ({
  type: 'whileReversed',
  nodeRule,
});

interface FilterRules {
  [ruleName: string]: FilterRule;
}

type NodeMatcher = (
  node: HastNode,
  index: number,
  array: Array<HastNode>,
  nodeMatchers: { [ruleName: string]: NodeMatcher }
) => boolean;

type Filter = (array: Array<HastNode>, nodeMatchers: { [ruleName: string]: NodeMatcher }) => Array<HastNode>;

const compileNodeMatcher = (rule: NodeRule): NodeMatcher => {
  switch (rule.type) {
    case 'allOf': {
      const matchers = rule.rules.map(compileNodeMatcher);
      return (...args) => matchers.every((matcher) => matcher(...args));
    }
    case 'anyOf': {
      const matchers = rule.rules.map(compileNodeMatcher);
      return (...args) => matchers.some((matcher) => matcher(...args));
    }
    case 'tagName': {
      const { tagName } = rule;
      return (node) => isElement(node) && node.tagName === tagName;
    }
    case 'className': {
      const { className } = rule;
      return (node) => isElement(node) && hasClass(node, className);
    }
    case 'position': {
      const { position } = rule;
      if (position < 0) {
        return (node, index, array) => index - array.length === position;
      }
      return (node, index) => index === position;
    }
    case 'before': {
      const { ruleName } = rule;
      return (node, index, array, rootMatchers) => {
        if (index >= array.length - 1) return false;
        const matcher = rootMatchers[ruleName];
        if (!rule) return false;
        return matcher(array[index + 1], index + 1, array, rootMatchers);
      };
    }
  }
};

const compileFilter = (rule: FilterRule, nodeMatcher: NodeMatcher): Filter => {
  switch (rule.type) {
    case 'first': {
      const { max } = rule;
      return (array, nodeMatchers) => {
        const result: HastNode[] = [];

        for (let i = 0; i < array.length; i += 1) {
          const node = array[i];
          if (nodeMatcher(node, i, array, nodeMatchers)) {
            result.push(node);
            if (result.length === max) {
              break;
            }
          }
        }
        return result;
      };
    }
    case 'while': {
      return (array, nodeMatchers) => {
        const result: HastNode[] = [];
        for (let i = 0; i < array.length; i += 1) {
          const node = array[i];
          if (!nodeMatcher(node, i, array, nodeMatchers)) {
            break;
          }
          result.push(node);
        }
        return result;
      };
    }
    case 'whileReversed': {
      return (array, nodeMatchers) => {
        const result: HastNode[] = [];
        for (let i = array.length - 1; i >= 0; i -= 1) {
          const node = array[i];
          if (!nodeMatcher(node, i, array, nodeMatchers)) {
            break;
          }
          result.push(node);
        }
        return result.reverse();
      };
    }
  }
};

export class HastSelector<R extends FilterRules> {
  private readonly nodeMatchers: { [RuleName in keyof R]: NodeMatcher };

  private readonly filters: { [RuleName in keyof R]: Filter };

  constructor(rules: R) {
    this.nodeMatchers = Object.keys(rules).reduce(
      (agg, ruleName) => ({
        ...agg,
        [ruleName]: compileNodeMatcher(rules[ruleName].nodeRule),
      }),
      {} as { [RuleName in keyof R]: NodeMatcher }
    );
    this.filters = Object.keys(rules).reduce(
      (agg, ruleName) => ({
        ...agg,
        [ruleName]: compileFilter(rules[ruleName], this.nodeMatchers[ruleName]),
      }),
      {} as { [RuleName in keyof R]: Filter }
    );
  }

  private allSelections(array: HastNode[]): Set<HastNode> {
    const set = new Set<HastNode>();
    const { filters, nodeMatchers } = this;

    for (const ruleName in filters) {
      for (const node of filters[ruleName](array, nodeMatchers)) {
        set.add(node);
      }
    }

    return set;
  }

  private selectByRule(rule: keyof R, nodes: Array<HastNode>): Array<HastNode> {
    return this.filters[rule](nodes, this.nodeMatchers);
  }

  private selectComplement(nodes: Array<HastNode>): Array<HastNode> {
    const allSelections = this.allSelections(nodes);
    return nodes.filter((node) => !allSelections.has(node));
  }

  /** Select with rule, return new hast fragment */
  select(rule: keyof R, fragment: HastFragmentNode): HastFragmentNode {
    return fragment.children ? createFragment(...this.selectByRule(rule, fragment.children)) : fragment;
  }

  /** Select all elements that don't match any rule, and return a new hast fragment */
  complement(fragment: HastFragmentNode): HastFragmentNode {
    return fragment.children ? createFragment(...this.selectComplement(fragment.children)) : fragment;
  }

  /** Select by rule, directly in react */
  Select: React.FC<{ rule: keyof R; fragment: HastFragmentNode; config: HastConfig }> = ({ rule, fragment, config }) => {
    if (!fragment.children) return null;

    return React.createElement(HastFragment, {
      nodes: this.selectByRule(rule, fragment.children),
      config,
    });
  };

  /** Select all elements that don't match any rule, directly in react  */
  Complement: React.FC<{ fragment: HastFragmentNode; config: HastConfig }> = ({ fragment, config }) => {
    if (!fragment.children) return null;

    return React.createElement(HastFragment, {
      nodes: this.selectComplement(fragment.children),
      config,
    });
  };
}
