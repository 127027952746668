import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

import * as sessionThunkActions from 'state/session/sessionThunkActions';
import { useSelector } from 'util/hooks/useSelector';

interface CheckIPLoginProps {
  children: React.ReactNode | React.ReactNode[];
}

const CheckIpLogin: React.FC<CheckIPLoginProps> = ({ children }) => {
  const haveCheckedIp = useSelector((state) => state.login.haveCheckedIp);
  const isCheckingIp = useSelector((state) => state.login.isCheckingIp);

  const juridikaConfig = useJuridikaConfig();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!haveCheckedIp && !isCheckingIp) {
      dispatch(sessionThunkActions.checkIp(juridikaConfig));
    }
  }, [dispatch, juridikaConfig, isCheckingIp, haveCheckedIp]);

  return <>{children}</>;
};

export default CheckIpLogin;
