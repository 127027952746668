import React from 'react';

const JuridikaByline: React.FC<{}> = () => (
  <svg
    className="juridikaByline"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 264 86"
    preserveAspectRatio="xMidYMid meet"
  >
    <g>
      <path
        className="st0"
        d="M13.8,9.9c0.9,0.4,1.8,0.7,2.9,1c1.1,0.3,2,0.4,2.9,0.6v29.9c0,1.8-0.3,3.2-1,4c-0.7,0.9-2.2,1.3-4.1,1.3   c-3.5,0-5.8-0.9-6.7-1.3l-0.7-6.6l-4.1,0.4c-0.4,1.7-0.8,3.5-1.1,5.5c-0.3,1.9-0.6,3.9-0.7,5.7c1.3,0.6,5.9,2.1,10.6,2.1   c5.4,0,10.2-1.8,12.4-4.2c2.4-2.7,3.1-5.7,3.1-11.3V11.4c0.9-0.1,1.8-0.3,2.7-0.6c0.9-0.2,1.7-0.6,2.5-1V6.2H13.8V9.9z"
      />
      <path
        className="st0"
        d="M67.7,44.6c-0.2-0.6-0.3-1.4-0.3-2.6V18.8h-1.8l-10.8,2.6v2.5c0.3,0.3,0.6,0.5,1,0.8c0.4,0.2,0.9,0.4,1.3,0.6   c0.5,0.2,1,0.4,1.4,0.5c0.5,0.2,0.9,0.3,1.4,0.4v18.3c-0.8,0.4-1.8,0.8-2.9,1.2c-1.1,0.4-2.3,0.6-3.6,0.6c-1.3,0-2.5-0.2-3.4-0.5   c-1-0.3-1.8-0.8-2.4-1.5c-0.6-0.7-1.1-1.5-1.4-2.6c-0.3-1-0.5-2.2-0.5-3.5V18.8h-1.8l-10.4,2.5v2.6c0.5,0.5,1.2,1,2.1,1.4   c0.9,0.4,1.8,0.7,2.6,0.9v14c0,4,0.9,7,2.6,9.1c1.8,2.1,4.3,3.1,7.7,3.1c1.4,0,2.6-0.1,3.7-0.4c1.1-0.3,2.2-0.6,3.1-1.1   c1-0.4,1.8-0.9,2.6-1.4c0.8-0.5,1.5-1,2.2-1.4c0.3,1.5,0.8,2.6,1.6,3.3c0.8,0.6,1.9,1,3.1,1c0.7,0,1.4-0.1,2.1-0.3   c0.7-0.2,1.4-0.5,2.1-0.8c0.7-0.3,1.3-0.7,1.9-1.1c0.6-0.4,1.1-0.9,1.5-1.3V46h-4C68.2,45.6,67.9,45.2,67.7,44.6z"
      />
      <path
        className="st0"
        d="M114,47V18.8h-1.7l-10.5,2.6v2.5c0.5,0.5,1.3,1,2.2,1.4c0.9,0.4,1.8,0.7,2.6,0.9V47c-0.8,0.1-1.6,0.2-2.4,0.5   c-0.8,0.2-1.6,0.5-2.4,0.9v3.3H119v-3.3c-0.7-0.4-1.5-0.6-2.3-0.9C115.8,47.3,114.9,47.1,114,47z"
      />
      <path
        className="st0"
        d="M89.5,47V18.8h-1.7l-10.5,2.6v2.5c0.5,0.5,1.3,1,2.2,1.4c0.9,0.4,1.8,0.7,2.6,0.9V47c-0.8,0.1-1.6,0.2-2.4,0.5   c-0.8,0.2-1.6,0.5-2.4,0.9v3.3h17.1v-3.3c-0.7-0.4-1.5-0.6-2.3-0.9C91.3,47.3,90.4,47.1,89.5,47z"
      />
      <path
        className="st0"
        d="M155.5,44.9c-0.3-0.6-0.4-1.5-0.4-2.6v-40h-1.6l-11.4,2.1L141.2,7c0.2,0.3,0.5,0.5,1.1,0.8   c0.6,0.3,1.2,0.5,1.8,0.7c0.7,0.2,1.3,0.4,2,0.5c0.7,0.2,1.2,0.3,1.7,0.3V20c-0.8-0.4-1.8-0.8-2.9-1c-1.2-0.2-2.2-0.3-3.3-0.3   c-2.4,0-4.7,0.4-6.8,1.3c-2.1,0.9-3.9,2.2-5.4,3.8c-1.5,1.6-2.7,3.6-3.6,5.9c-0.9,2.3-1.3,4.9-1.3,7.8c0,2.5,0.3,4.6,0.9,6.4   c0.6,1.8,1.5,3.4,2.6,4.7c1.1,1.3,2.4,2.2,3.9,2.9c1.5,0.6,3.2,1,5,1c2.3,0,4.4-0.4,6.4-1.2c1.9-0.8,3.5-1.7,4.9-2.7   c0.3,1.3,0.7,2.3,1.5,2.9c0.7,0.6,1.7,0.9,2.8,0.9c0.7,0,1.5-0.1,2.2-0.3c0.7-0.2,1.5-0.4,2.2-0.7c0.7-0.3,1.4-0.6,2-1   c0.6-0.4,1.2-0.8,1.6-1.3v-2.9h-4C156.1,46,155.8,45.5,155.5,44.9z M147.7,45c-0.3,0.2-0.7,0.4-1.2,0.6c-0.5,0.2-1,0.4-1.5,0.6   c-0.5,0.2-1.1,0.3-1.6,0.4c-0.6,0.1-1.1,0.1-1.6,0.1c-3.4,0-5.9-1.1-7.7-3.3c-1.8-2.2-2.6-5.3-2.6-9.4c0-1.8,0.2-3.2,0.6-4.5   c0.4-1.2,1-2.2,1.8-3c0.8-0.8,1.7-1.3,2.8-1.7c1.1-0.4,2.4-0.5,3.8-0.5c1.5,0,2.8,0.2,4,0.5c1.2,0.3,2.3,0.8,3.3,1.4V45z"
      />
      <path
        className="st0"
        d="M178.4,47V18.8h-1.7l-10.5,2.6v2.5c0.5,0.5,1.3,1,2.2,1.4c0.9,0.4,1.8,0.7,2.6,0.9V47   c-0.8,0.1-1.6,0.2-2.4,0.5c-0.8,0.2-1.6,0.5-2.4,0.9v3.3h17.1v-3.3c-0.7-0.4-1.5-0.6-2.3-0.9C180.2,47.3,179.3,47.1,178.4,47z"
      />
      <path
        className="st0"
        d="M219.3,24.4c0.4,0,0.9-0.1,1.3-0.2c0.5-0.1,0.9-0.2,1.3-0.4c0.4-0.1,0.8-0.3,1.2-0.5c0.4-0.2,0.7-0.4,1-0.6   v-3.3h-15.5v3.2c0.2,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.7,0.3,1.1,0.4c0.4,0.1,0.8,0.2,1.1,0.3c0.4,0.1,0.7,0.2,1,0.2l-11.9,10.4V2.3   H199c-1.8,0.4-3.5,0.8-5.2,1.3c-1.7,0.4-3.5,0.8-5.2,1.3v2.5c0.3,0.3,0.6,0.5,1,0.8c0.4,0.3,0.8,0.5,1.2,0.7   c0.4,0.2,0.9,0.4,1.3,0.5c0.5,0.1,0.9,0.3,1.3,0.4V47c-0.8,0.1-1.6,0.3-2.5,0.5c-0.9,0.2-1.6,0.5-2.4,0.9v3.3h17.1v-3.3   c-0.7-0.3-1.5-0.6-2.4-0.8c-0.9-0.2-1.7-0.4-2.5-0.5V35.6l10.4,10.3c0.6,0.6,1.3,1.2,2.1,1.9c0.8,0.6,1.6,1.2,2.4,1.8   c0.8,0.6,1.7,1.1,2.5,1.6c0.9,0.5,1.7,0.9,2.5,1.2c0.3,0,0.6,0,1-0.1c0.4-0.1,0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.2,1-0.3   c0.3-0.1,0.6-0.2,0.8-0.3v-2.9l-16.4-15.3L219.3,24.4z"
      />
      <path
        className="st0"
        d="M258.7,46.3c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.4-1.4-0.4-2.4V29.3c0-1.9-0.2-3.5-0.7-4.8   c-0.4-1.3-1.1-2.4-2-3.3c-0.9-0.9-2-1.5-3.3-1.9c-1.3-0.4-2.8-0.6-4.6-0.6c-1.8,0-3.7,0.2-5.6,0.6c-1.9,0.4-3.7,1.1-5.2,2   c-1.6,0.9-2.9,2-3.9,3.4c-1,1.4-1.5,3-1.5,4.9v1.5l7.6-1.6l0.7-4.9c0.7-0.3,1.5-0.5,2.2-0.7c0.8-0.2,1.5-0.3,2.2-0.3   c1.2,0,2.2,0.1,3,0.4c0.9,0.2,1.6,0.6,2.1,1.1c0.5,0.5,1,1.2,1.2,1.9c0.3,0.8,0.4,1.8,0.4,2.9v3.7l-4.7,0.3   c-5.5,0.4-9.5,1.5-11.9,3.3c-2.4,1.7-3.6,4.1-3.6,7c0,2.6,0.8,4.6,2.5,5.9c1.7,1.4,3.9,2.1,6.6,2.1c1.4,0,2.6-0.2,3.8-0.5   c1.2-0.3,2.2-0.7,3.1-1.2c0.9-0.5,1.8-1,2.5-1.6c0.7-0.6,1.4-1.1,2-1.6c0.2,1.6,0.6,2.8,1.2,3.6c0.6,0.8,1.6,1.2,3,1.2   c0.7,0,1.4-0.1,2.2-0.3c0.8-0.2,1.5-0.4,2.3-0.7c0.7-0.3,1.4-0.6,2.1-1c0.6-0.4,1.2-0.8,1.6-1.3v-2.9H258.7z M250.1,44.1   c-0.5,0.4-1.1,0.8-1.7,1.1c-0.6,0.4-1.3,0.7-2,1c-0.7,0.3-1.5,0.5-2.2,0.7c-0.8,0.2-1.5,0.3-2.2,0.3c-0.8,0-1.5-0.1-2.1-0.3   c-0.6-0.2-1.2-0.4-1.6-0.7c-0.5-0.3-0.8-0.7-1-1.2c-0.2-0.5-0.3-1.1-0.3-1.8c0-0.8,0.2-1.4,0.6-2c0.4-0.6,1-1.1,1.9-1.5   c0.8-0.4,1.9-0.8,3.1-1c1.3-0.3,2.7-0.5,4.4-0.6l3.2-0.3V44.1z"
      />
      <path
        className="st0"
        d="M113.7,2.4c-0.8-0.9-2.3-1.3-3.8-1.3c-0.1,0-0.2,0-0.3,0c-2.5,0.1-5.3,0.7-8,2.8c0,0,0,0,0,0c0,0,0,0,0,0   c-0.1,0-0.1,0.1-0.2,0.1c-4.4,3.4-6.6,9.2-7.6,13l2.3,0.8l0,0l0.4,0.2c1.3-4,2.9-7.2,5-9c1-0.9,2.6-1.9,3.7-2.1c0.1,0,0.1,0,0.2,0   c0.2,0.8,0.5,1.6,1.1,2.1c0.8,0.8,2,1.3,3.5,1.3c1.6,0,2.8-0.4,3.6-1.3c0.8-0.8,1.2-2,1.2-3.4C114.9,4.3,114.5,3.2,113.7,2.4z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M20.7,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2c-0.3,0.3-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2s-0.5-0.3-0.7-0.5s-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2c0.2-0.6,0.6-1.2,1-1.6   c0.4-0.5,0.9-0.8,1.4-1.1S20.1,74,20.7,74z M20,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6s-0.4,0.6-0.5,0.9   c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6C20.6,75.1,20.3,75,20,75z"
      />
      <path
        className="st1"
        d="M23.4,75.3v-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2   c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.4c-0.1,0.2-0.1,0.5-0.2,0.8   c-0.1,0.3-0.2,0.7-0.3,1s-0.2,0.7-0.3,1.1h0.2c0.3-0.7,0.6-1.3,0.9-1.8c0.3-0.5,0.6-0.9,0.8-1.2c0.3-0.3,0.6-0.5,0.8-0.7   c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.6   c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3-0.1,0.5l-0.6,3.9l0.1,0.1H31V81c-0.1,0.1-0.3,0.2-0.5,0.3s-0.4,0.2-0.6,0.2s-0.4,0.1-0.6,0.2   c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3   c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2l0.7-4.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.2-0.7-0.5-0.7c-0.2,0-0.4,0.1-0.7,0.4   s-0.6,0.7-0.9,1.2c-0.3,0.5-0.6,1.2-1,2c-0.3,0.8-0.6,1.7-0.9,2.8h-1.2l1-6.2l-0.1-0.1H23.4z"
      />
      <path
        className="st1"
        d="M40.6,80.8c-0.2,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2   c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.2-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5   c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2s0-0.2,0-0.3c0-0.1,0-0.2,0-0.2l0.7-4.3h-1.2v-0.6c0.1-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1   l0.8-2.1h0.9L39,74.3h1.9v0.3l-0.3,0.6h-1.8l-0.6,3.9c0,0.1,0,0.2-0.1,0.4s0,0.3,0,0.4c0,0.2,0.1,0.4,0.2,0.5s0.3,0.1,0.5,0.1   c0.1,0,0.2,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2L40.6,80.8z"
      />
      <path
        className="st1"
        d="M40,83.8c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4-0.1s0.2-0.1,0.3-0.3   c0.1-0.1,0.2-0.3,0.3-0.5s0.1-0.5,0.2-0.9l1.1-6.8l-0.1-0.1h-1.2v-0.5c0.1-0.1,0.3-0.2,0.5-0.2s0.4-0.2,0.7-0.2   c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.1,0.4-0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2   l-1.1,6.8c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.2-1.2,0.2c-0.3,0-0.5,0-0.8-0.1   s-0.5-0.2-0.7-0.3c0-0.2,0-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.5s0.1-0.3,0.1-0.5H40V83.8z M45.4,71.5   c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.1-0.6,0.3-0.8   c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.5,0.2C45.4,71.1,45.4,71.3,45.4,71.5z"
      />
      <path
        className="st1"
        d="M50.7,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2c-0.3,0.3-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2s-0.5-0.3-0.7-0.5s-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2c0.2-0.6,0.6-1.2,1-1.6   c0.4-0.5,0.9-0.8,1.4-1.1S50.1,74,50.7,74z M50,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6s-0.4,0.6-0.5,0.9   c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6C50.6,75.1,50.3,75,50,75z"
      />
      <path
        className="st1"
        d="M53.4,75.3v-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2   c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.4c-0.1,0.2-0.1,0.5-0.2,0.8   c-0.1,0.3-0.2,0.7-0.3,1s-0.2,0.7-0.3,1.1h0.2c0.3-0.7,0.6-1.3,0.9-1.8c0.3-0.5,0.6-0.9,0.8-1.2c0.3-0.3,0.6-0.5,0.8-0.7   c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5s0.1,0.4,0.1,0.6c0,0.1,0,0.3,0,0.5   c0,0.2,0,0.3-0.1,0.5l-0.6,3.9l0.1,0.1H61V81c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.2s-0.4,0.1-0.6,0.2   c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2   s0-0.1,0-0.2l0.7-4.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.2-0.7-0.5-0.7c-0.2,0-0.4,0.1-0.7,0.4c-0.3,0.3-0.6,0.7-0.9,1.2   c-0.3,0.5-0.6,1.2-1,2c-0.3,0.8-0.6,1.7-0.9,2.8h-1.2l1-6.2l-0.1-0.1H53.4z"
      />
      <path
        className="st1"
        d="M66.8,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2   c0.2-0.6,0.6-1.2,1-1.6s0.9-0.8,1.4-1.1C65.7,74.2,66.2,74,66.8,74z M66.1,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6   s-0.4,0.6-0.5,0.9c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6S66.4,75,66.1,75z"
      />
      <path
        className="st1"
        d="M70.1,76.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.2-0.2   c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.3-0.1,0.6-0.2,1s-0.2,0.7-0.3,1h-0.6   v-1.1c-0.1,0-0.2-0.1-0.3-0.1s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.4,0-0.7,0.1-1,0.2   c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.4,1,0.7c0.4,0.2,0.7,0.4,0.9,0.6c0.3,0.2,0.5,0.4,0.6,0.5   c0.1,0.2,0.3,0.3,0.3,0.5s0.1,0.3,0.1,0.5c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.4,0.5-0.7,0.8s-0.6,0.4-1,0.5s-0.8,0.2-1.2,0.2   c-0.4,0-0.7,0-1.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.3l0.3-2.1h0.6c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.4,0.1,0.6c0.1,0,0.2,0.1,0.3,0.1   c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.4-0.4,0.4-0.6c0-0.1,0-0.2-0.1-0.3   c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.4c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5   c-0.1-0.2-0.3-0.4-0.3-0.5C70.2,76.7,70.1,76.5,70.1,76.3z"
      />
      <path
        className="st1"
        d="M80.5,80.8c-0.2,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2   c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.2-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5   c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2s0-0.2,0-0.3c0-0.1,0-0.2,0-0.2l0.7-4.3h-1.2v-0.6c0.1-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1   l0.8-2.1h0.9l-0.3,2.1h1.9v0.3l-0.3,0.6h-1.8l-0.6,3.9c0,0.1,0,0.2-0.1,0.4c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.4,0.2,0.5   c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2L80.5,80.8z"
      />
      <path
        className="st1"
        d="M86,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2   c0.2-0.6,0.6-1.2,1-1.6s0.9-0.8,1.4-1.1C84.9,74.2,85.4,74,86,74z M85.3,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6   s-0.4,0.6-0.5,0.9c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6S85.6,75,85.3,75z"
      />
      <path
        className="st1"
        d="M93.2,84.8h-0.7l1.5-9.5h-1.1v-0.7l1.3-0.2l0.2-1.2c0.1-0.5,0.2-0.9,0.4-1.3s0.5-0.7,0.8-1   c0.3-0.3,0.7-0.5,1-0.6s0.7-0.2,1.1-0.2c0.2,0,0.4,0,0.5,0s0.3,0.1,0.5,0.1c0.1,0.1,0.3,0.1,0.4,0.2s0.2,0.1,0.3,0.2   c-0.1,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.2,0.7-0.3,1h-0.6v-1.2c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3,0-0.5,0.1   c-0.1,0.1-0.3,0.2-0.4,0.3s-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.7l-0.3,1.7h2v0.3l-0.3,0.6h-1.9l-1.1,6.7   c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.3-0.2,0.5-0.3,0.8C93.4,84.3,93.3,84.6,93.2,84.8z"
      />
      <path
        className="st1"
        d="M104.1,74.3c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.3-0.2,0.4   c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0-0.2,0-0.3-0.1s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1s-0.2,0.2-0.4,0.3s-0.3,0.4-0.4,0.6   c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.4-0.4,0.9-0.6,1.5c-0.2,0.6-0.5,1.3-0.7,2.1h-1.1l1-6.2l-0.1-0.1h-1.2v-0.5   c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.3,0,0.3,0.1   s0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.5-0.2,1-0.4,1.6h0.2   c0.3-0.6,0.5-1.1,0.7-1.6c0.2-0.5,0.5-0.9,0.7-1.2s0.4-0.6,0.6-0.7s0.4-0.3,0.6-0.3c0.2,0,0.4,0,0.5,0.1   C103.9,74.2,104,74.3,104.1,74.3z"
      />
      <path
        className="st1"
        d="M111.4,80.5V81c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.6,0.2s-0.3,0.1-0.5,0.1   c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.3s0-0.3,0.1-0.4s0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6   c0.1-0.4,0.2-0.9,0.3-1.4h-0.1c-0.3,0.6-0.6,1.2-0.8,1.7s-0.5,0.9-0.8,1.2c-0.3,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.5,0.2-0.8,0.2   c-0.2,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5c-0.1-0.2-0.3-0.5-0.3-0.8s-0.1-0.6-0.1-1c0-0.7,0.1-1.3,0.4-2c0.2-0.6,0.6-1.2,0.9-1.7   s0.9-0.9,1.4-1.2c0.5-0.3,1.1-0.4,1.6-0.4c0.2,0,0.4,0,0.7,0.1c0.2,0,0.4,0.1,0.5,0.2c0.1-0.1,0.1-0.2,0.2-0.3   c0.1-0.1,0.2-0.2,0.2-0.2l0.8,0.2l-1,6.4l0.1,0.1H111.4z M106.6,80.6c0.1,0,0.3-0.1,0.5-0.4c0.2-0.3,0.5-0.6,0.8-1.1   c0.3-0.5,0.6-1,0.9-1.7c0.3-0.6,0.6-1.3,0.8-2.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1   c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0.1-0.9,0.3s-0.5,0.5-0.7,0.9s-0.4,0.8-0.5,1.2c-0.1,0.5-0.2,0.9-0.2,1.4c0,0.6,0.1,1,0.2,1.4   C106.1,80.4,106.3,80.6,106.6,80.6z"
      />
      <path
        className="st1"
        d="M121.3,71.8c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0.1-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0.1l-0.8,5.3   c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0,0.6c0,0.6,0.2,1.1,0.6,1.4s1,0.6,1.8,0.6c0.8,0,1.5-0.3,2-0.8s0.9-1.4,1-2.5l0.8-5.2   c-0.2,0-0.4-0.1-0.7-0.1c-0.3-0.1-0.5-0.1-0.6-0.2l0.1-0.8h3.4l-0.1,0.8c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.1l-0.8,5   c-0.1,0.8-0.3,1.4-0.6,2c-0.2,0.6-0.6,1-0.9,1.4c-0.4,0.4-0.8,0.7-1.4,0.8c-0.5,0.2-1.1,0.3-1.8,0.3c-0.6,0-1.1-0.1-1.5-0.2   s-0.8-0.3-1-0.6c-0.3-0.2-0.4-0.6-0.6-0.9c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3   c0-0.1,0-0.2,0.1-0.3l0.9-5.5c-0.1,0-0.2,0-0.3-0.1s-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1l0.1-0.8h3.8   L121.3,71.8z"
      />
      <path
        className="st1"
        d="M127.6,75.3v-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2   c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.4c-0.1,0.2-0.1,0.5-0.2,0.8   c-0.1,0.3-0.2,0.7-0.3,1s-0.2,0.7-0.3,1.1h0.2c0.3-0.7,0.6-1.3,0.9-1.8c0.3-0.5,0.6-0.9,0.8-1.2c0.3-0.3,0.6-0.5,0.8-0.7   c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.6   c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3-0.1,0.5l-0.6,3.9l0.1,0.1h1.2V81c-0.1,0.1-0.3,0.2-0.5,0.3s-0.4,0.2-0.6,0.2s-0.4,0.1-0.6,0.2   c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3   c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2l0.7-4.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.5-0.2-0.7-0.5-0.7c-0.2,0-0.4,0.1-0.7,0.4   s-0.6,0.7-0.9,1.2c-0.3,0.5-0.6,1.2-1,2c-0.3,0.8-0.6,1.7-0.9,2.8H128l1-6.2l-0.1-0.1H127.6z"
      />
      <path
        className="st1"
        d="M136.5,74.8c0.1-0.1,0.3-0.2,0.5-0.2s0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.1,0.5-0.1   c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2l-0.8,5.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0   c0,0,0,0,0.1,0.1h1.2V81c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.1-0.5,0.1   c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2l0.8-5.2l-0.1-0.1h-1.2V74.8z M140,71.5   c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.1-0.6,0.3-0.8   c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.5,0.2C139.9,71.1,140,71.3,140,71.5z"
      />
      <path
        className="st1"
        d="M146.1,74.6C146.1,74.6,146.1,74.6,146.1,74.6c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1   c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.3-0.2,0.6-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.2,0.4,0.2,0.5   c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.4-0.4,0.9-0.8,1.3s-0.7,0.9-1.1,1.3c-0.4,0.4-0.8,0.8-1.2,1.1   c-0.4,0.3-0.8,0.6-1.1,0.7c-0.3,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.2-0.6   s0-0.5,0-0.7l0.4-4.4l-0.1-0.1h-1.1v-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.1,0.6-0.2   c0.2-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.4l-0.5,4.6   c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0.1,0.3s0.1,0.2,0.2,0.2s0.2,0.1,0.3,0.1c0.3-0.2,0.7-0.5,1-0.9c0.4-0.4,0.7-0.7,1-1.1   s0.5-0.8,0.8-1.2c0.2-0.4,0.3-0.8,0.4-1.2L146.1,74.6z"
      />
      <path
        className="st1"
        d="M153.9,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2   c0.2-0.6,0.6-1.2,1-1.6s0.9-0.8,1.4-1.1C152.8,74.2,153.3,74,153.9,74z M153.2,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6   s-0.4,0.6-0.5,0.9c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6S153.5,75,153.2,75z"
      />
      <path
        className="st1"
        d="M162.4,74.3c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.3-0.2,0.4   c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0-0.2,0-0.3-0.1s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1s-0.2,0.2-0.4,0.3s-0.3,0.4-0.4,0.6   c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.4-0.4,0.9-0.6,1.5c-0.2,0.6-0.5,1.3-0.7,2.1H157l1-6.2l-0.1-0.1h-1.2v-0.5c0.1-0.1,0.3-0.2,0.5-0.2   c0.2-0.1,0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.3,0,0.3,0.1s0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2   c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.5-0.2,1-0.4,1.6h0.2c0.3-0.6,0.5-1.1,0.7-1.6   c0.2-0.5,0.5-0.9,0.7-1.2s0.4-0.6,0.6-0.7s0.4-0.3,0.6-0.3c0.2,0,0.4,0,0.5,0.1C162.1,74.2,162.3,74.3,162.4,74.3z"
      />
      <path
        className="st1"
        d="M163.5,76.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.2-0.2   c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.3-0.1,0.6-0.2,1s-0.2,0.7-0.3,1h-0.6   v-1.1c-0.1,0-0.2-0.1-0.3-0.1s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.4,0-0.7,0.1-1,0.2   c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.4,1,0.7c0.4,0.2,0.7,0.4,0.9,0.6c0.3,0.2,0.5,0.4,0.6,0.5   c0.1,0.2,0.3,0.3,0.3,0.5s0.1,0.3,0.1,0.5c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.4,0.5-0.7,0.8s-0.6,0.4-1,0.5s-0.8,0.2-1.2,0.2   c-0.4,0-0.7,0-1.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.3l0.3-2.1h0.6c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.4,0.1,0.6c0.1,0,0.2,0.1,0.3,0.1   c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.4-0.4,0.4-0.6c0-0.1,0-0.2-0.1-0.3   c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.4c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5   c-0.1-0.2-0.3-0.4-0.3-0.5C163.5,76.7,163.5,76.5,163.5,76.3z"
      />
      <path
        className="st1"
        d="M169.4,74.8c0.1-0.1,0.3-0.2,0.5-0.2s0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.1,0.5-0.1   c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2l-0.8,5.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0   c0,0,0,0,0.1,0.1h1.2V81c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.1-0.5,0.1   c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2l0.8-5.2l-0.1-0.1h-1.2V74.8z M172.9,71.5   c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.1-0.6,0.3-0.8   c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.5,0.2C172.9,71.1,172.9,71.3,172.9,71.5z"
      />
      <path
        className="st1"
        d="M178.5,80.8c-0.2,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2   c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.2-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5   c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2s0-0.2,0-0.3c0-0.1,0-0.2,0-0.2l0.7-4.3h-1.2v-0.6c0.1-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1   l0.8-2.1h0.9l-0.3,2.1h1.9v0.3l-0.3,0.6h-1.8l-0.6,3.9c0,0.1,0,0.2-0.1,0.4c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.4,0.2,0.5   c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2L178.5,80.8z"
      />
      <path
        className="st1"
        d="M184,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2   c0.2-0.6,0.6-1.2,1-1.6s0.9-0.8,1.4-1.1C182.8,74.2,183.4,74,184,74z M183.3,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6   s-0.4,0.6-0.5,0.9c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6S183.6,75,183.3,75z"
      />
      <path
        className="st1"
        d="M191.2,80.8c-0.2,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2   c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.2-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5   c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2s0-0.2,0-0.3c0-0.1,0-0.2,0-0.2l0.7-4.3h-1.2v-0.6c0.1-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1   l0.8-2.1h0.9l-0.3,2.1h1.9v0.3l-0.3,0.6h-1.8l-0.6,3.9c0,0.1,0,0.2-0.1,0.4c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.4,0.2,0.5   c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2L191.2,80.8z"
      />
      <path
        className="st1"
        d="M192.9,76.3c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.2-0.2   c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.3-0.1,0.6-0.2,1s-0.2,0.7-0.3,1h-0.6   v-1.1c-0.1,0-0.2-0.1-0.3-0.1s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.4,0-0.7,0.1-1,0.2   c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.4,1,0.7c0.4,0.2,0.7,0.4,0.9,0.6c0.3,0.2,0.5,0.4,0.6,0.5   c0.1,0.2,0.3,0.3,0.3,0.5s0.1,0.3,0.1,0.5c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.4,0.5-0.7,0.8s-0.6,0.4-1,0.5s-0.8,0.2-1.2,0.2   c-0.4,0-0.7,0-1.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.3l0.3-2.1h0.6c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.4,0.1,0.6c0.1,0,0.2,0.1,0.3,0.1   c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.4-0.4,0.4-0.6c0-0.1,0-0.2-0.1-0.3   c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.4c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5   c-0.1-0.2-0.3-0.4-0.3-0.5C192.9,76.7,192.9,76.5,192.9,76.3z"
      />
      <path
        className="st1"
        d="M199.4,84.8h-0.7l1.5-9.5h-1.1v-0.7l1.3-0.2l0.2-1.2c0.1-0.5,0.2-0.9,0.4-1.3s0.5-0.7,0.8-1   c0.3-0.3,0.7-0.5,1-0.6s0.7-0.2,1.1-0.2c0.2,0,0.4,0,0.5,0s0.3,0.1,0.5,0.1c0.1,0.1,0.3,0.1,0.4,0.2s0.2,0.1,0.3,0.2   c-0.1,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.2,0.7-0.3,1h-0.6v-1.2c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3,0-0.5,0.1   c-0.1,0.1-0.3,0.2-0.4,0.3s-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.7l-0.3,1.7h2v0.3l-0.3,0.6h-1.9l-1.1,6.7   c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.3-0.2,0.5-0.3,0.8C199.7,84.3,199.6,84.6,199.4,84.8z"
      />
      <path
        className="st1"
        d="M203.9,79.1c0-0.7,0.1-1.4,0.4-2c0.3-0.6,0.6-1.2,1-1.6s0.9-0.8,1.4-1.1c0.5-0.3,1-0.4,1.6-0.4   c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.5,0.4,0.8s0.1,0.7,0.1,1.1c0,0.7-0.1,1.4-0.4,2   c-0.3,0.6-0.6,1.2-1,1.6c-0.4,0.5-0.9,0.8-1.4,1.1c-0.5,0.3-1,0.4-1.6,0.4c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.5-0.3-0.7-0.5   c-0.2-0.2-0.3-0.5-0.4-0.8C203.9,79.8,203.9,79.5,203.9,79.1z M205.3,78.6c0,0.7,0.1,1.2,0.3,1.6s0.6,0.5,1,0.5   c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.5-0.5,0.8-0.8c0.2-0.3,0.4-0.7,0.5-1.1c0.1-0.4,0.2-0.9,0.2-1.4c0-0.7-0.1-1.2-0.3-1.6   c-0.2-0.4-0.6-0.5-1-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.5,0.5-0.8,0.8c-0.2,0.3-0.4,0.7-0.5,1.2   C205.4,77.7,205.3,78.2,205.3,78.6z"
      />
      <path
        className="st1"
        d="M217.7,74.3c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.3-0.2,0.4   c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0-0.2,0-0.3-0.1s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1s-0.2,0.2-0.4,0.3s-0.3,0.4-0.4,0.6   c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.4-0.4,0.9-0.6,1.5c-0.2,0.6-0.5,1.3-0.7,2.1h-1.1l1-6.2l-0.1-0.1h-1.2v-0.5   c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.3,0,0.3,0.1   s0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.5-0.2,1-0.4,1.6h0.2   c0.3-0.6,0.5-1.1,0.7-1.6c0.2-0.5,0.5-0.9,0.7-1.2s0.4-0.6,0.6-0.7s0.4-0.3,0.6-0.3c0.2,0,0.4,0,0.5,0.1   C217.4,74.2,217.5,74.3,217.7,74.3z"
      />
      <path
        className="st1"
        d="M218.6,70.8c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2s0.3-0.1,0.5-0.1   c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l-1.5,9.3l0.1,0.1h1.2V81   c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3-0.1-0.4-0.2   s-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l1.5-9.3l-0.1-0.1h-1.2V70.8z"
      />
      <path
        className="st1"
        d="M229.7,80.5V81c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.6,0.2s-0.3,0.1-0.5,0.1   c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.3s0-0.3,0.1-0.4s0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6   c0.1-0.4,0.2-0.9,0.3-1.4h-0.1c-0.3,0.6-0.6,1.2-0.8,1.7s-0.5,0.9-0.8,1.2c-0.3,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.5,0.2-0.8,0.2   c-0.2,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5c-0.1-0.2-0.3-0.5-0.3-0.8s-0.1-0.6-0.1-1c0-0.7,0.1-1.3,0.4-2c0.2-0.6,0.6-1.2,0.9-1.7   s0.9-0.9,1.4-1.2c0.5-0.3,1.1-0.4,1.6-0.4c0.2,0,0.4,0,0.7,0.1c0.2,0,0.4,0.1,0.5,0.2c0.1-0.1,0.1-0.2,0.2-0.3   c0.1-0.1,0.2-0.2,0.2-0.2l0.8,0.2l-1,6.4l0.1,0.1H229.7z M224.9,80.6c0.1,0,0.3-0.1,0.5-0.4c0.2-0.3,0.5-0.6,0.8-1.1   c0.3-0.5,0.6-1,0.9-1.7c0.3-0.6,0.6-1.3,0.8-2.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1   c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0.1-0.9,0.3s-0.5,0.5-0.7,0.9s-0.4,0.8-0.5,1.2c-0.1,0.5-0.2,0.9-0.2,1.4c0,0.6,0.1,1,0.2,1.4   C224.4,80.4,224.6,80.6,224.9,80.6z"
      />
      <path
        className="st1"
        d="M231.9,81c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.4-0.5,0.6-0.8   c-0.2-0.1-0.3-0.3-0.5-0.6c-0.1-0.2-0.2-0.6-0.2-1c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.5,1.1-0.6   s0.8-0.2,1.2-0.2c0.3,0,0.5,0,0.7,0.1s0.4,0.1,0.5,0.2l0.8-1.5h1.4v0.5c-0.1,0.2-0.2,0.3-0.3,0.5s-0.3,0.2-0.4,0.4   c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.5,0.2,0.8c0,0.5-0.1,0.9-0.3,1.3   s-0.4,0.7-0.7,1s-0.7,0.5-1.1,0.6s-0.8,0.2-1.2,0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3-0.1-0.4-0.1c0,0.1-0.1,0.2-0.1,0.3   c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2l2.4,0.8c0.5,0.2,0.9,0.3,1.2,0.6c0.3,0.2,0.4,0.5,0.4,1   c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.6-0.9,0.8s-0.8,0.4-1.3,0.6c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-1-0.1-1.4-0.2s-0.7-0.2-0.9-0.4   s-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.5,0.2-0.7s0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.4   C231.5,81.2,231.7,81.1,231.9,81z M232.5,81.2c-0.3,0.2-0.6,0.4-0.8,0.7s-0.3,0.5-0.3,0.8c0,0.2,0.1,0.4,0.2,0.6s0.2,0.3,0.4,0.4   s0.4,0.2,0.7,0.3c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.5-0.4   c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2-0.1-0.3-0.3-0.5c-0.2-0.1-0.5-0.3-0.8-0.4L232.5,81.2z M235.9,76.3c0-0.5-0.1-0.8-0.4-1   c-0.3-0.2-0.6-0.3-1.1-0.3c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.2-0.2,0.3-0.3,0.6s-0.1,0.5-0.1,0.8   c0,0.5,0.1,0.8,0.4,1s0.7,0.3,1.1,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.2,0.2-0.3,0.3-0.6   C235.8,76.9,235.9,76.6,235.9,76.3z"
      />
      <path
        className="st1"
        d="M243.2,74c0.6,0,1.1,0.1,1.3,0.4c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1,0.9   c-0.4,0.2-0.9,0.4-1.5,0.6c-0.6,0.2-1.2,0.3-1.8,0.4c0,0.7,0.1,1.2,0.4,1.6c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.7-0.1,1.1-0.2   s0.7-0.4,1-0.6l0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.1-0.9,0.1   c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.5-0.8s-0.2-0.7-0.2-1.1c0-0.7,0.1-1.3,0.4-2   c0.2-0.6,0.6-1.2,1-1.6s0.9-0.8,1.4-1.1C242.1,74.2,242.6,74,243.2,74z M242.6,75c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.7,0.6   s-0.4,0.6-0.5,0.9c-0.2,0.4-0.3,0.7-0.3,1.2c1-0.1,1.9-0.4,2.4-0.8s0.9-0.8,0.9-1.3c0-0.3-0.1-0.5-0.3-0.6S242.9,75,242.6,75z"
      />
      <path
        className="st1"
        d="M250.4,80.8c-0.2,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2   c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.2-0.2-0.4c0-0.2-0.1-0.3-0.1-0.5   c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2s0-0.2,0-0.3c0-0.1,0-0.2,0-0.2l0.7-4.3h-1.2v-0.6c0.1-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1   l0.8-2.1h0.9l-0.3,2.1h1.9v0.3l-0.3,0.6h-1.8l-0.6,3.9c0,0.1,0,0.2-0.1,0.4c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.4,0.2,0.5   c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2L250.4,80.8z"
      />
    </g>
  </svg>
);

export default JuridikaByline;
