import * as React from 'react';
import styled from 'styled-components';

const LabelCss = styled.label`
  margin-left: 10px;
`;

const Checkbox: React.FC<{ label?: string | JSX.Element } & React.InputHTMLAttributes<HTMLInputElement>> = ({
  label,
  ...inputAttrs
}) => {
  return (
    <>
      <input type="checkbox" {...inputAttrs} />
      {label && <LabelCss htmlFor={inputAttrs.id}>{label}</LabelCss>}
    </>
  );
};

export default Checkbox;
