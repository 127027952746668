import { JuridikaError, JuridikaErrorType } from 'commonUtils/models/JuridikaError';

export type GqlErrorType = 'BAD_REQUEST' | 'NOT_FOUND' | 'UNAUTHENTICATED' | 'UNAUTHORIZED' | 'SERVER';

/**
 * Errors returned from graphql-gateway
 */
export interface GqlError {
  type: GqlErrorType;
}

/**
 * Some 'edge' with a `node` property that was successfully fetched
 */
export interface GqlEdgeWithNode<T> {
  node: T;
}

/**
 * Some 'edge' with an `error` property that is the result of a failed fetch operation
 */
export interface GqlEdgeWithError {
  error: GqlError;
}

/**
 * An edge that has either succeeded or failed
 */
export type GqlFallible<T> = (T & { error: null }) | GqlEdgeWithError;

export const gqlErrorToJuridikaError = (gqlError: GqlError): JuridikaError => {
  switch (gqlError.type) {
    case 'BAD_REQUEST':
      return { type: JuridikaErrorType.GRAPHQL_BAD_REQUEST };
    case 'NOT_FOUND':
      return { type: JuridikaErrorType.GRAPHQL_NOT_FOUND };
    case 'UNAUTHENTICATED':
      return { type: JuridikaErrorType.GRAPHQL_UNAUTHENTICATED };
    case 'UNAUTHORIZED':
      return { type: JuridikaErrorType.GRAPHQL_UNAUTHORIZED };
    case 'SERVER':
      return { type: JuridikaErrorType.GRAPHQL_SERVER };
  }
};

export const isAuthError = (gqlError: GqlError): boolean => {
  const { type } = gqlError;
  return type === 'UNAUTHENTICATED' || type === 'UNAUTHORIZED';
};
