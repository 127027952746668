import * as React from 'react';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

export const useDismissLocalStorage = (storageKey?: string): { isDismissed: boolean; dismissHandler: () => void } => {
  const { isClient } = useJuridikaConfig();
  const [isDismissed, setIsDismissed] = React.useState(true);

  React.useEffect(() => {
    if (isClient) setIsDismissed(storageKey ? !!localStorage.getItem(storageKey) || false : false);
  }, [isClient, storageKey]);

  const dismissHandler = () => {
    setIsDismissed(true);
    if (storageKey) {
      localStorage.setItem(storageKey, 'true');
    }
  };

  return { isDismissed, dismissHandler };
};
