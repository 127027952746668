const isElementVisibleInContainer = (element: HTMLElement, container: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return (
    rect.top >= containerRect.top &&
    rect.left >= containerRect.left &&
    rect.bottom <= containerRect.bottom &&
    rect.right <= containerRect.right
  );
};

export default isElementVisibleInContainer;
