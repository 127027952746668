import React from 'react';
import { match } from 'react-router';
import { Route, Redirect, Switch, matchPath, RouteComponentProps, RedirectProps } from 'react-router-dom';
import loadable from '@loadable/component';
import * as urlHelpers from 'util/urlHelpers';
import { ensureIsArray } from 'util/arrayUtils';
import { JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import { RouteConfig } from '../models/route/RouteTypes';
import { Layout } from '../containers/Layouts/Layout';
import CheckIpLogin from '../pages/Session/CheckIpLogin';
import AuthCallback from '../containers/AuthCallback/AuthCallback';
import { LoadableWrapper } from '../components/LoadableWrapper';
import { Throw } from '../components/error/Throw';
import { LEGAL_DOCUMENT_PATHS } from './paths';
import { AKADEMI_PATH } from '../pages/AcademyPage/index';
import { NEW_CONTENT_CONCEPT_PATH } from '../pages/NIKPage';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NEW_CONTENT_CONCEPT_COMPONENTS_PATH } from '../pages/NIKPage/pages/NIKComponents';
import { PATH_NAME_TRY_AKADEMI } from '../pages/ContactUsFormPage/ContactUsFormPage';

type NoProps = Record<string, never>;

const AuthorPage = loadable<NoProps>(() => import('../pages/AuthorPage/AuthorPage'));
const AuthorsPage = loadable<NoProps>(() => import('../pages/AuthorsPage/AuthorsPage'));
const TextbookEditionPage = loadable<NoProps>(() => import('../pages/TextbookEditionPage/TextbookEditionPage'));
const HomePage = loadable<NoProps>(() => import('../pages/HomePage/HomePage'));
const StartPage = loadable<NoProps>(() => import('../pages/StartPage/StartPage'));
const JournalsPage = loadable<NoProps>(() => import('../pages/JournalsPage/JournalsPage'));
const JuridikaInsightPage = loadable<NoProps>(() => import('../pages/JuridikaInsightPage/JuridikaInsightPage'));
const LegalDocumentPage = loadable<NoProps>(() => import('../pages/LegalDocumentPage/LegalDocumentPage'));
const TextbookReaderPage = loadable<NoProps>(() => import('../pages/TextbookReaderPage/TextbookReaderPage'));
const JournalPage = loadable<NoProps>(() => import('../pages/JournalPage/JournalPage'));
const JournalArticlePage = loadable<NoProps>(() => import('../pages/JournalArticlePage/JournalArticlePage'));
const TextbooksPage = loadable<NoProps>(() => import('../pages/TextbooksPage/TextbooksPage'));
const CommentariesPage = loadable<NoProps>(() => import('../pages/CommentariesPage/CommentariesPage'));
const SessionReset = loadable<NoProps>(() => import('../pages/Session/SessionReset'));
const CompanySignupPage = loadable<NoProps>(() => import('../pages/CompanySignupPage/CompanySignupPage'));
const CompleteSignupPage = loadable<NoProps>(() => import('../pages/CompleteSignupPage/CompleteSignupPage'));
const EmailValidationPage = loadable<NoProps>(() => import('../pages/EmailValidationPage/EmailValidationPage'));
const AcceptTermsPage = loadable<NoProps>(() => import('../pages/AcceptTermsPage/AcceptTemsPage'));
const SearchPage = loadable<NoProps>(() => import('../pages/SearchPage/SearchPage'));
const StaticContentPage = loadable<NoProps>(() => import('../pages/StaticContentPage/StaticContentPage'));
const CookiesPage = loadable<NoProps>(() => import('../staticPages/CookiesPage'));
const PrivacyPage = loadable<NoProps>(() => import('../staticPages/PrivacyPage'));
const UserTermsPage = loadable<NoProps>(() => import('../staticPages/UserTermsPage'));
const SubscriptionTermsPage = loadable<NoProps>(() => import('../staticPages/SubscriptionTermsPage'));
const TemplatesPage = loadable<NoProps>(() => import('../pages/TemplatesPage/TemplatesPage'));
const AcademyPage = loadable<NoProps>(() => import('../pages/AcademyPage'));
const NIKHomePage = loadable<NoProps>(() => import('../pages/NIKPage'));
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NIKComponentsPage = loadable<NoProps>(() => import('../pages/NIKPage/pages/NIKComponents'));
const TemplatePage = loadable<NoProps>(() => import('../pages/TemplatePage/TemplatePage'));
const PricingPage = loadable<NoProps>(() => import('../pages/PricingPage/PricingPage'));
const FamilyLawPage = loadable<NoProps>(() => import('../pages/FamilyLawPage/FamilyLawPage'));
const EuLawPage = loadable<NoProps>(() => import('../pages/EuLawPage/EuLawPage'));
const FavoritesPage = loadable<NoProps>(() => import('../pages/FavoritesPage/FavoritesPage'));
const ContactUsFormPage = loadable<NoProps>(() => import('../pages/ContactUsFormPage/ContactUsFormPage'));

const routeConfig: Array<RouteConfig> = [
  // HOMEPAGE

  {
    path: '/om-juridika',
    redirectTo: '/',
  },
  {
    path: '/',
    component: HomePage,
    name: 'Hjem',
  },
  {
    path: '/start',
    component: StartPage,
    name: 'Start',
  },
  // PRICING AND PACKAGING
  {
    path: '/priser',
    component: PricingPage,
    name: 'Pakkevelger',
  },
  {
    path: '/om-juridika#packagingSection',
    redirectTo: '/priser',
  },
  {
    path: '/pakkevelger',
    redirectTo: '/priser',
  },
  // AUTHORS
  {
    path: '/faglitteratur/forfattere',
    redirectTo: '/forfattere',
  },
  {
    path: '/forfattere',
    component: AuthorsPage,
    name: 'Forfattere',
  },
  {
    path: '/faglitteratur/forfattere/:slug',
    redirectTo: '/forfattere/:slug',
  },
  {
    path: '/forfattere/:slug',
    component: AuthorPage,
  },
  // STATIC COMMENTARIES
  {
    path: ['/faglitteratur/kommentarutgaver', '/:type(lov|forskrift)', '/kommentarer'],
    redirectTo: '/lovkommentarer',
  },
  {
    path: '/lovkommentarer',
    component: CommentariesPage,
  },
  {
    path: '/:type(direktiv|forordning|traktat)',
    redirectTo: '/eøs-rett',
  },
  // JOURNALS
  {
    path: '/faglitteratur/tidsskrifter',
    redirectTo: '/tidsskrifter',
  },
  {
    path: '/tidsskrifter',
    component: JournalsPage,
    name: 'Tidsskrifter',
  },
  {
    path: ['/faglitteratur/tidsskrifter/nye-artikler', '/tidsskrifter/nye-artikler'],
    redirectTo: { pathname: '/tidsskrifter', hash: 'nye-artikler' },
  },
  {
    path: '/faglitteratur/tidsskrifter/:publicationSlug',
    redirectTo: '/tidsskrifter/:publicationSlug',
  },
  {
    path: '/faglitteratur/tidsskrifter/:publicationSlug/:year',
    redirectTo: '/tidsskrifter/:publicationSlug/:year',
  },
  {
    path: '/faglitteratur/tidsskrifter/:publicationSlug/:year/:issue',
    redirectTo: '/tidsskrifter/:publicationSlug/:year/:issue',
  },
  {
    path: '/tidsskrifter/:publicationSlug/:year?/:issue?',
    component: JournalPage,
  },
  {
    path: '/faglitteratur/tidsskrifter/:publicationSlug/:year/:issue/artikkel/:documentKey',
    redirectTo: '/tidsskrifter/:publicationSlug/:year/:issue/artikkel/:documentKey',
  },
  {
    path: '/tidsskrifter/:publicationSlug/:year/:issue/artikkel/:documentKey',
    component: JournalArticlePage,
  },
  // TEXT BOOKS
  {
    path: ['/faglitteratur', '/fagbok', '/fagbøker'],
    redirectTo: '/rettsområder',
  },
  {
    path: '/rettsområder',
    component: TextbooksPage,
    name: 'Rettsområder',
  },
  {
    path: ['/faglitteratur/:publicationSlug', '/faglitteratur/kommentarutgaver/:publicationSlug', '/fagbøker/:publicationSlug'],
    redirectTo: '/fagbok/:publicationSlug',
  },
  {
    path: '/fagbok/:publicationSlug',
    component: JournalPage,
  },
  {
    path: [
      '/faglitteratur/:publicationSlug/:sequence',
      '/faglitteratur/kommentarutgaver/:publicationSlug/:sequence',
      '/fagbøker/:publicationSlug/:sequence',
    ],
    redirectTo: '/fagbok/:publicationSlug/:sequence',
  },
  {
    path: '/fagbok/:publicationSlug/:sequence',
    component: TextbookEditionPage,
  },
  {
    path: [
      '/faglitteratur/:publicationSlug/:sequence/dokument',
      '/faglitteratur/kommentarutgaver/:publicationSlug/:sequence/dokument',
      '/fagbøker/:publicationSlug/:sequence/dokument',
    ],
    redirectTo: '/fagbok/:publicationSlug/:sequence/dokument',
  },
  {
    path: [
      '/faglitteratur/:publicationSlug/:sequence/dokument/:docPath+',
      '/fagbøker/:publicationSlug/:sequence/dokument/:docPath+',
    ],
    redirectTo: '/fagbok/:publicationSlug/:sequence/dokument/:docPath',
  },
  {
    path: '/fagbok/:publicationSlug/:sequence/:docPath+',
    component: TextbookReaderPage,
  },
  // LEGAL DOCUMENT PAGE
  {
    path: LEGAL_DOCUMENT_PATHS,
    component: LegalDocumentPage,
    key: 'key:legaldocument',
  },
  // legacy paths
  {
    path: '/documents/:base/:type/:docId',
    redirectTo: '/:type/:docId',
  },
  {
    path: '/documents/:base/:type/:docId/:inForceAt',
    redirectTo: '/:type/:docId/:inForceAt',
  },
  // SIGNUP
  {
    path: '/signup',
    component: CompanySignupPage,
    name: 'Registrering',
    excludeFromSitemap: true,
  },
  // INFORMATION PAGES
  {
    path: '/brukeravtale',
    component: UserTermsPage,
    name: 'Brukeravtale',
  },
  {
    path: '/abonnementsbetingelser',
    component: SubscriptionTermsPage,
    name: 'Abonnementsbetingelser',
  },
  {
    path: '/personvern',
    component: PrivacyPage,
    name: 'Personvern',
  },
  {
    path: '/bruk-av-informasjonskapsler',
    component: CookiesPage,
    name: 'Bruk av informasjonskapsler',
  },
  // SEARCH
  {
    path: '/sok',
    component: SearchPage,
    name: 'Søk',
    excludeFromSitemap: true,
  },
  // JURIDIKA INSIGHT
  {
    path: '/innsikt',
    component: JuridikaInsightPage,
    name: 'Innsikt',
  },
  {
    path: '/innsikt/ekspertkommentarer',
    component: JuridikaInsightPage,
    name: 'Ekspertkommentarer',
  },
  {
    path: '/innsikt/podkaster',
    component: JuridikaInsightPage,
    name: 'Podkaster',
  },
  {
    path: '/innsikt/podkaster/:slug',
    component: JuridikaInsightPage,
    name: 'Podkaster',
  },
  {
    path: '/innsikt/webinarer',
    component: JuridikaInsightPage,
    name: 'Webinarer',
  },
  {
    path: '/innsikt/serier/:slug',
    component: JuridikaInsightPage,
  },
  {
    path: '/innsikt/:slug',
    component: StaticContentPage,
  },
  {
    path: '/nyheter/:slug',
    redirectTo: '/innsikt/:slug',
  },
  // Email validation
  {
    path: '/validateEmail',
    component: EmailValidationPage,
    excludeFromSitemap: true,
  },
  // Accepting terms
  {
    path: '/aksepterVilkaar',
    component: AcceptTermsPage,
  },
  // AUTH HANDLERS
  {
    path: urlHelpers.AUTH_CALLBACK_PATHNAME,
    component: AuthCallback,
    excludeFromSitemap: true,
  },
  {
    path: urlHelpers.IP_CALLBACK_PATHNAME,
    component: AuthCallback,
    excludeFromSitemap: true,
  },
  // TEMPLATES
  {
    path: ['/maler', '/maler/dokument'],
    component: TemplatesPage,
    name: 'Maler',
  },
  {
    path: ['/maler/dokument/:slug'],
    component: TemplatePage,
    name: 'Mal',
  },
  {
    path: [
      '/maler/søk',
      '/maler/:fieldLevel1',
      '/maler/:fieldLevel1/:fieldLevel2',
      '/maler/:fieldLevel1/:fieldLevel2/:fieldLevel3',
    ],
    component: TemplatesPage,
    name: 'Maler',
    key: 'key:template',
  },
  // ACADEMY
  {
    path: [
      `/${AKADEMI_PATH}`,
      `/${AKADEMI_PATH}/kurs/:course`,
      `/${AKADEMI_PATH}/kurs/:course/:chapter`,
      `/${AKADEMI_PATH}/kurs/:course/:chapter/:module`,
    ],
    component: AcademyPage,
    name: 'Akademi',
  },
  {
    path: `/${NEW_CONTENT_CONCEPT_PATH}/:document`,
    component: NIKHomePage,
    name: 'Nytt innholdskonsept',
  },
  {
    path: `/${NEW_CONTENT_CONCEPT_PATH}/draft/:document`,
    component: NIKHomePage,
    name: 'Nytt innholdskonsept',
  },
  // {
  //   path: NEW_CONTENT_CONCEPT_COMPONENTS_PATH,
  //   component: NIKComponentsPage,
  //   name: 'Nytt innholdskonsept - komponenter',
  // },
  // Field-of-law specific pages
  {
    path: '/familierett',
    component: FamilyLawPage,
    name: 'Familierett',
  },
  {
    path: '/eøs-rett',
    component: EuLawPage,
    name: 'EU/EØS-rett',
  },
  {
    path: '/eu-rett',
    redirectTo: '/eøs-rett',
  },
  {
    path: '/registrering',
    redirectTo: 'prøv-juridika',
  },
  {
    path: '/prøv-juridika',
    component: ContactUsFormPage,
    name: 'Kampanje Prøv juridika',
  },
  {
    path: PATH_NAME_TRY_AKADEMI,
    component: ContactUsFormPage,
    name: 'Prøv Akademi',
  },
  // FAVORITES
  {
    path: '/favoritter',
    component: FavoritesPage,
    name: 'Favoritter',
  },
  {
    path: '/favoritter/:type',
    component: FavoritesPage,
    name: 'Favoritter',
  },
];

export const getRouteConfig = (): RouteConfig[] => routeConfig;

export const matchRoute = (pathname: string): { route: RouteConfig; match: match<Record<string, string>> } | null => {
  for (let i = 0; i < routeConfig.length; i += 1) {
    const route = routeConfig[i];
    const match = matchPath(pathname, { path: route.path, exact: true });
    if (match) return { route, match };
  }

  return null;
};

const PermanentRedirectWithParams: React.FC<RedirectProps & RouteComponentProps<NoProps>> = (props) => {
  const { from, to, match, location, staticContext } = props;
  const { search } = location;
  const paramKeys = Object.keys(match.params);
  if (staticContext) {
    // A Moved Permanently redirect!
    staticContext.statusCode = 301;
  }

  const paramInliner = (url: string, paramKey: string): string => url.replace(`:${paramKey}`, match.params[paramKey]);

  if (typeof to === 'string') {
    const toWithReplacedParamsKeys: string = paramKeys.reduce(paramInliner, to);

    return <Redirect from={from} to={{ pathname: toWithReplacedParamsKeys, search }} />;
  }
  const toWithReplacedParamsKeys: string = paramKeys.reduce(paramInliner, to.pathname || '/');

  return <Redirect from={from} to={{ ...to, pathname: toWithReplacedParamsKeys, search }} />;
};

export const Routes: React.FC = () => (
  <Switch>
    {routeConfig.reduce<React.ReactElement<RouteComponentProps<NoProps>>[]>(
      (acc, config: RouteConfig): React.ReactElement<RouteComponentProps<NoProps>>[] => {
        const { path, key, redirectTo, component: Component } = config;

        return [
          ...acc,
          ...ensureIsArray(path).map((path) => (
            <Route
              key={key || path}
              exact
              path={path}
              render={(props) => {
                if (redirectTo) {
                  return (
                    <PermanentRedirectWithParams
                      from={path}
                      to={redirectTo}
                      history={props.history}
                      location={props.location}
                      match={props.match as match<Record<string, never>>}
                    />
                  );
                }

                return (
                  <LoadableWrapper>
                    <CheckIpLogin>{Component && <Component />}</CheckIpLogin>
                  </LoadableWrapper>
                );
              }}
            />
          )),
        ];
      },
      []
    )}
    <Route path={urlHelpers.LOGOUT_PATHNAME} component={SessionReset} />
    <Route path={urlHelpers.COMPLETE_SIGNUP_PATHNAME} component={CompleteSignupPage} />
    <Route
      render={({ location }) => (
        <Layout>
          <Throw
            error={{
              type: JuridikaErrorType.ROUTE_NOT_FOUND,
              pathname: location.pathname,
            }}
          />
        </Layout>
      )}
    />
  </Switch>
);
