import * as Redux from 'redux';
import * as H from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer, createTransform, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Auth0 } from 'util/auth0';
import { jwtMiddleware } from 'middleware/jwt';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { RootState, Store } from './types';
import { createRootReducer } from './reducers';
import { SessionState, TokenSource } from './session/types';
import { CurrentUserState } from './currentUser/types';

export const createClientReduxStore = (
  juridikaConfig: JuridikaConfig,
  auth0: Auth0,
  history: H.History<any>,
  preloadedState: Partial<RootState>
): Store => {
  const middleware = [routerMiddleware(history), thunkMiddleware, jwtMiddleware(juridikaConfig, auth0)];

  /**
   * Prevent crash if user has not installed redux devtools.
   */
  let reduxDevtools = <T>(noOp: T) => noOp;

  if (juridikaConfig.isDebug) {
    if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
      reduxDevtools = (window as any).__REDUX_DEVTOOLS_EXTENSION__();
    }
  }

  /**
   * If we did not have to support IP-login, there would be no need to persist state with server side authentication enabled
   * Unless the persisted state is coming from IP-login, use the preloaded state generated server side.
   */
  const transformSessionState = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: SessionState | undefined, key): SessionState | undefined => {
      /**
       * If tokensource is server, it means we are getting session state through preloaded state, so there is no need to persist it.
       */
      if (key === 'session' && inboundState?.tokenSource === TokenSource.SERVER) {
        return undefined;
      }
      return inboundState;
    },
    // transform state being rehydrated
    (outboundState: SessionState | undefined, key): SessionState | undefined => {
      if (key === 'session' && outboundState) {
        if (outboundState.tokenSource === TokenSource.IP) {
          return outboundState;
        }
        if (preloadedState?.session) {
          return {
            ...preloadedState.session,
          };
        }
      }
      return outboundState;
    },
    // define which reducers this transform gets called for.
    { whitelist: ['session'] }
  );

  const transformUserState = createTransform(
    (inboundState: CurrentUserState | undefined, _key): CurrentUserState | undefined => inboundState,
    (outBoundState: CurrentUserState | undefined, key): CurrentUserState | undefined => {
      // If we are authed server side preloaded currentUser state will have name and email
      if (key === 'currentUser' && preloadedState?.currentUser?.name) {
        return { ...preloadedState?.currentUser };
      }
      return outBoundState;
    }
  );

  // Hydrate store from local-storage, this supports migrations from older versions as-well (see documentation)
  const persistedReducer = persistReducer(
    {
      storage,
      version: 2,
      key: 'juridika',
      whitelist: ['referrer', 'session', 'currentUser'],
      transforms: [transformSessionState, transformUserState],
    },
    createRootReducer(juridikaConfig, history)
  );

  return Redux.createStore(persistedReducer, preloadedState, Redux.compose(Redux.applyMiddleware(...middleware), reduxDevtools));
};

export const runPersistStore = (store: Store): Persistor => persistStore(store);
