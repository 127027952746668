import React, { FC } from 'react';

import { COLORS } from 'theme/config/defaultTheme';
import IconPropTypes from 'components/icons/IconPropTypes';
import Svg from 'components/ui/Svg';

const SuccessStatusCircle: FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM8 15.414l-4.707-4.707 1.414-1.414L8 12.586l7.293-7.293 1.414 1.414L8 15.414z"
    />
  </Svg>
);

SuccessStatusCircle.defaultProps = {
  size: 20,
  color: COLORS.iconDefault,
};

export default SuccessStatusCircle;
