import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import * as clipboard from 'clipboard-polyfill';
import * as DetectBrowser from 'detect-browser';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import Quote from 'icons/Quote';
import styled from 'styled-components';

const MarkedTextTooltipCss = styled.div`
  width: 200px;
  height: 50px;
  float: left;
  color: white;
  background-color: #291547;
  position: absolute;
  display: inline-block;
  text-align: center;
  border-radius: 5px;

  :after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #291547 transparent transparent transparent;
  }
`;

const CopySectionCss = styled.div`
  width: 100%;
  height: 100%;
  :hover {
    cursor: pointer;
    border-radius: 5px;
    background-color: #674f8a;
    border-color: #291547 transparent transparent transparent;
  }
`;

type DetectResult = DetectBrowser.BrowserInfo | DetectBrowser.BotInfo | DetectBrowser.NodeInfo | null;

export interface Citation {
  htmlText: string | null;
  plainText: string | null;
}

export interface SelectedText {
  text: string | null;
  html: HTMLElement | null;
  parentElement?: HTMLElement | null;
  citation: Citation | null;
}

export interface Props {
  markedTextTooltipDocument: string[];
  posX: number;
  posY: number;
  visible: boolean;
  selectedText: SelectedText;
  hideToolTip: () => void;
  juridikaConfig: JuridikaConfig;
}

interface State {
  browser: DetectResult;
  browserVersion: number;
}

class MarkedTextTooltip extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const browser = DetectBrowser.detect();
    // Edge versions < 17 requires special case
    const browserVersion = browser ? parseInt((browser.version || '').split('.')[0], 10) : 0;
    this.state = {
      browser,
      browserVersion,
    };
  }

  clearSelection = () => {
    const { visible, hideToolTip } = this.props;
    // Doing a click on already selected text does not remove it from getSelection, so we have to clear the selection whenever a click happens.
    if (window.getSelection) {
      // Alias for removeAllRanges, Safari/Chrome/Opera/Firefox/Edge
      const selection = window.getSelection();

      if (selection && selection.empty) {
        selection.empty();
        // IE
      } else if (selection && selection.removeAllRanges) {
        selection.removeAllRanges();
      }
      // Just in case, old IE versions?
    } else if ((document as any).selection) {
      if ((document as any).selection.empty) {
        (document as any).selection.empty();
      }
    }
    if (visible) {
      hideToolTip();
    }
  };

  formatHtml = (html: HTMLElement, footnotes: HTMLElement) => {
    html.childNodes.forEach((node: any) => {
      if (node.className === 'akn__paragraph') {
        node.style.textIndent = '18px';
      } else if (node.className === 'akn__list') {
        node.style.listStyle = 'none';
      } else if (node.className === 'akn__point') {
        node.style.listStyleType = 'none';
        node.style.position = 'relative';
      } else if (node.className === 'footnote') {
        const footnote = document.createElement('div');
        footnote.innerHTML = node.outerHTML;
        footnotes.appendChild(footnote);
        node.innerHTML = '';
      }
      if (node.childNodes.length > 0) {
        this.formatHtml(node, footnotes);
      }
    });
  };

  formatTextAndCopy = ({ text, html, citation }: SelectedText) => {
    const { browser, browserVersion } = this.state;
    const dt = new clipboard.DT();

    const footnotes = document.createElement('div');
    footnotes.style.fontStyle = 'italic';
    footnotes.style.fontSize = '12px';

    if (html) {
      this.formatHtml(html, footnotes); // Apply some important css to laws and fetch footnotes in articles
      html.appendChild(footnotes);
    }

    const citationPlainText = (citation && citation.plainText) || '';
    const citationHtmlText = (citation && citation.htmlText) || '';

    const plainText =
      text && `${text.replace(/[\r\n]\s*[\r\n]/g, '\n').trim()} ${citationPlainText.replace(/\\s+/g, ' ')}`.trim(); // Removing excessive whitespaces and newlines
    const htmlText = html && `${html.outerHTML.toString()} ${citationHtmlText}`.replace(/\\s+/g, ' ').trim();

    if (browser && browser.name === 'edge' && browserVersion < 17) {
      dt.setData('text/plain', plainText || '');
    } else if (browser && browser.name === 'edge') {
      dt.setData('text/html', htmlText || '');
    } else {
      dt.setData('text/plain', plainText || '');
      dt.setData('text/html', htmlText || '');
    }

    clipboard.write(dt).then(() => {
      this.clearSelection();
    });
  };

  handleClickOutside = (evt: React.MouseEvent) => {
    if (evt.button !== 2) {
      // If right clicking text we dont want to remove selected text
      this.clearSelection();
    }
  };

  render() {
    const { browser, browserVersion } = this.state;

    const { posX, posY, visible, selectedText } = this.props;

    // Unavailable for browsers not allowing copying of text/html to clipboard for now
    let isVisible;
    if (browser && (browser.name === 'ie' || (browser.name === 'edge' && browserVersion < 17))) {
      isVisible = false;
    } else {
      isVisible = visible;
    }

    return (
      <MarkedTextTooltipCss
        style={{ top: posY, left: posX, visibility: isVisible ? 'visible' : 'hidden' }}
        id="selectedTextToolTip"
        onClick={() => this.formatTextAndCopy(selectedText)}
      >
        <CopySectionCss>
          <span style={{ position: 'relative', top: '25%' }}>
            <Quote />
          </span>
          <span style={{ position: 'relative', top: '10%', marginLeft: '5px' }}>Kopier med kilde</span>
        </CopySectionCss>
      </MarkedTextTooltipCss>
    );
  }
}

const OnClickOutsideMarkedTextTooltip = onClickOutside(MarkedTextTooltip);

export default OnClickOutsideMarkedTextTooltip;
