export const rejectAfter = <T>(error: any, timeout: number): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(error), timeout);
  });
};

export const withTimeout = <T>(promise: Promise<T>, milliseconds: number): Promise<T> => {
  if (milliseconds === 0) return promise;

  return Promise.race([promise, rejectAfter<T>(new Error('timeout'), milliseconds)]);
};
