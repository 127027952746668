/**
 * http://www.jsonml.org/syntax/
 */

export type JsonMLAttributeValue = string | number | boolean | null;
export type JsonMLAttributes = Record<string, JsonMLAttributeValue>;

/**
 * JsonML is actually mistyped, because it has a type that TypeScript cannot
 * represent:
 * type JsonMLElement = [
 *   string,
 *   ...[JsonMLAttributes] | [],
 *   ...JsonMLNode[]
 * ]
 *
 * For this reason, attributes is not part of the type, but nonetheless attributes
 * may exist optionally at index 1.
 */
export type JsonMLElement = [string, ...JsonMLNode[]];
export type JsonMLText = string;

export type JsonMLNode = JsonMLElement | JsonMLText;

export interface UnpackedElement<T> {
  tagName: string;
  attributes: JsonMLAttributes | null;
  children: T[];
}

export const getAttributes = (element: JsonMLElement): JsonMLAttributes | null => {
  const elementOne = element[1];
  if (Array.isArray(elementOne) || typeof elementOne === 'string') {
    return null;
  }
  return elementOne as unknown as JsonMLAttributes;
};

export const childrenLength = (element: JsonMLElement): number => {
  return getAttributes(element) === null ? element.length - 1 : element.length - 2;
};

export const getChild = (element: JsonMLElement, index: number): JsonMLNode => {
  return getAttributes(element) === null ? element[index + 1] : element[index + 2];
};

export const mapChildren = <T>(element: JsonMLElement, mapFn: (node: JsonMLNode, index: number) => T): T[] => {
  const output: T[] = [];
  const startIndex = getAttributes(element) === null ? 1 : 2;
  let externalIndex = 0;

  for (let index = startIndex; index < element.length; index += 1) {
    output.push(mapFn(element[index], externalIndex));
    externalIndex += 1;
  }

  return output;
};

export const unpackElement = <T>(
  element: JsonMLElement,
  childMapFn: (node: JsonMLNode, index: number) => T
): UnpackedElement<T> => {
  const children: T[] = [];
  const attributes = getAttributes(element);
  const localChildIndex = attributes === null ? 1 : 2;
  let childIndex = 0;

  for (let index = localChildIndex; index < element.length; index += 1) {
    children.push(childMapFn(element[index], childIndex));
    childIndex += 1;
  }

  return {
    tagName: element[0],
    attributes,
    children,
  };
};
