import * as React from 'react';
import styled from 'styled-components';

import { ResponsiveFontType } from 'theme/config/types';
import createFontStyles from 'util/createFontStyles';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingTagProps {
  margin?: { top?: number; bottom?: number };
  fontColor?: string;
  fontConfig?: ResponsiveFontType;
}

export interface HeadingProps extends HeadingTagProps, React.HTMLAttributes<HTMLHeadingElement> {
  hTag: HeadingTag;
}

const H1Css = styled.h1<HeadingTagProps>`
  margin: ${({ margin }) => (margin?.top ? `${margin?.top}rem` : 0)} 0
    ${({ margin }) => (margin?.bottom ? `${margin?.bottom}rem` : 0)};

  ${(props) =>
    createFontStyles({
      family: props.fontConfig?.family ?? props.theme.fontFamilies.primary,
      size: props.fontConfig?.size,
      weight: props.fontConfig?.weight,
      lineHeight: props.fontConfig?.lineHeight,
      letterSpacing: props.fontConfig?.letterSpacing,
      mediaQueries: props.fontConfig?.mediaQueries,
    })};

  color: ${(props) => props.fontColor ?? props.theme.colorTokens.grey[900]};
`;

const Heading: React.FC<HeadingProps> = ({ hTag, children, ...headingAttrs }) => {
  return (
    <H1Css as={hTag} {...headingAttrs}>
      {children}
    </H1Css>
  );
};

export default Heading;
