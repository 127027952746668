import * as React from 'react';

import { createLogger } from 'commonUtils/log';

import { ErrorContext, ErrorContextValue, ServerErrorState } from './ErrorContext';

interface ErrorContextHydratedProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const log = createLogger('juridikaError');

/**
 * Load ServerErrorState generated by SSR
 */
const hydrateServerErrorContext = (): ErrorContextValue => {
  const errorState: ServerErrorState = (window as any).ERROR_STATE;
  delete (window as any).ERROR_STATE;

  return {
    errorLogger: (catchId, error) => {
      // Not logging at error level, because it floods TrackJS with quite useless messages
      log.warn({ catchId, error, msg: 'juridikaError' });
    },
    serverErrorState: errorState,
    catchRegistry: {},
  };
};

/**
 * A provider of ServerErrorContext for the Web/Client side.
 * The initial hydration of the application should use the same errors as the server side did.
 * After the initial hydration, those errors should be copied into local component state in Catch.
 */
export const ErrorContextHydratedProvider: React.FC<ErrorContextHydratedProviderProps> = ({ children }) => {
  const [contextValue, setContextValue] = React.useState(hydrateServerErrorContext);

  // After the first run, clear the state and thus the context.
  React.useEffect(() => {
    setContextValue({
      ...contextValue,
      serverErrorState: {},
      catchRegistry: {},
    });
  }, []);

  return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>;
};
