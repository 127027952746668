import URI from 'urijs';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { StaticContentCategorySemanticKey } from '../models/staticContent/ContentCategory';

import { HastSelector, takeWhile, matchAnyOf, matchTagName } from './HastSelector';

const staticPageSegments = (base: URI, slug: string): URI => base.segment('innsikt').segment(slug);

export const staticPageRelativeUrl = (slug: string): URI => staticPageSegments(URI('/'), slug);

export const staticPageAbsoluteUrl = (slug: string, juridikaConfig: JuridikaConfig): URI =>
  staticPageSegments(juridikaConfig.juridikaAbsoluteBaseUrl(), slug);

export const isExpertComment = (contentCategoryKey: StaticContentCategorySemanticKey): boolean =>
  contentCategoryKey === 'ekspertkommentar';

export const noticeHeadersSelector = new HastSelector({
  allHeaders: takeWhile(matchAnyOf(matchTagName('img'), matchTagName('h3'), matchTagName('h4'), matchTagName('iframe'))),
});
