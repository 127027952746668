import { SpinningIcon } from 'pages/StartPage/Styles';
import React, { FC, ReactNode, Ref, forwardRef } from 'react';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';

interface AcademyIconButtonProps {
  icon: ReactNode;
  onClick: () => void;
  className?: string;
  ref?: Ref<HTMLButtonElement>;
}

const commonStyles = css`
  ${buttonWithoutStyle}
  display: flex;
  align-items: center;

  :hover {
    opacity: 1;
  }

  :focus-visible {
    border: none;
    outline: none;
  }
`;

const AcademyIconButton1Css = styled.button`
  ${commonStyles}

  border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
  border-radius: 50%;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  background-color: ${({ theme }) => theme.colorTokens.default.white};

  :hover,
  :focus-visible {
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

const AcademyIconButton2Css = styled.button`
  ${commonStyles}

  padding: 0.8125rem;
  border-radius: 4px;
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purple[700]};
  background-color: ${({ theme }) => theme.colorTokens.default.white};

  :hover {
    background-color: ${({ theme }) => theme.colorTokens.purple[300]};
    color: ${({ theme }) => theme.colorTokens.purple[700]};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purple[300]};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.purple[700]};
    }
  }

  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purple[700]};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }

  ${SpinningIcon}
`;

const AcademyIconButton3Css = styled.button`
  ${commonStyles}

  border: 1px solid black;
  border-radius: 50%;
  padding: 0.625rem;

  :hover {
    background-color: ${({ theme }) => theme.colorTokens.purple[300]};
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
    color: ${({ theme }) => theme.colorTokens.purple[700]};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.purple[700]};
    }
  }

  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

const AcademyIconButton4Css = styled.button`
  ${commonStyles}

  background-color: ${({ theme }) => theme.colorTokens.default.white};
  padding: 0.8125rem;
  box-shadow: 4px 0 0 0 rgb(255 255 255), -3px 2px 5px 0 #00000040;
  border: 1px solid ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 4px 0 0 4px;

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

const AcademyIconButton5Css = styled.button`
  ${commonStyles}

  background-color: ${({ theme }) => theme.colorTokens.default.white};
  padding: 0.8125rem;
  border: 1px solid ${({ theme }) => theme.colorTokens.default.black};

  border-radius: 4px;

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

export const AcademyIconButton1: FC<AcademyIconButtonProps> = ({ onClick, className = '', icon }) => (
  <AcademyIconButton1Css onClick={onClick} className={className}>
    {icon}
  </AcademyIconButton1Css>
);

export const AcademyIconButton2: FC<AcademyIconButtonProps> = ({ onClick, className = '', icon }) => (
  <AcademyIconButton2Css onClick={onClick} className={className}>
    {icon}
  </AcademyIconButton2Css>
);

export const AcademyIconButton3: FC<AcademyIconButtonProps> = ({ onClick, className = '', icon }) => (
  <AcademyIconButton3Css onClick={onClick} className={className}>
    {icon}
  </AcademyIconButton3Css>
);

export const AcademyIconButton4: FC<AcademyIconButtonProps> = ({ onClick, className = '', icon }) => (
  <AcademyIconButton4Css onClick={onClick} className={className}>
    {icon}
  </AcademyIconButton4Css>
);

export const AcademyIconButton5: FC<AcademyIconButtonProps> = forwardRef(({ onClick, className = '', icon }, ref) => (
  <AcademyIconButton5Css onClick={onClick} className={className} ref={ref}>
    {icon}
  </AcademyIconButton5Css>
));
