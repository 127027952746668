import * as React from 'react';

import { AuthenticationStatus } from 'state/login/types';
import * as GqlResult from 'util/graphql/GqlResult';
import {
  GqlFavoritesConnectionKey,
  useFavoriteQuery,
  GqlFavoriteConnection,
  GqlAnyFavoritesEdge,
} from 'util/hooks/useFavoritesQuery';

import { ButtonAndLinkProps } from '../Button/ButtonAndLinkCss';
import ReadMoreButton from '../ReadMoreButton';

import { Throw } from '../error/Throw';
import ReadMoreAndCloseButtons from '../ReadMoreAndCloseButtons';

import FavoritesList from './FavoritesList';
import { ColumnSectionFooter, ColumnSectionFooterLeft, ColumnSectionFooterRight } from '../ColumnSection/ColumnSection';

export type FavoriteCategory = 'alle' | 'lovkommentarer' | 'fagbøker' | 'artikler' | 'innsiktsartikler' | 'maler';

export const FAVORITE_CATEGORIES: FavoriteCategory[] = [
  'alle',
  'lovkommentarer',
  'fagbøker',
  'artikler',
  'innsiktsartikler',
  'maler',
];

export interface FavoritesFeedProps {
  favoriteCategory: string;
  initialLoadCount: number;
  displayMoreInterval?: number;
  buttonConfig?: ButtonAndLinkProps;
  displayGoToFavoritesBtn?: boolean;
  displayLoadMoreButtons?: boolean;
  onTruncate?(): void;
  redFooterIconsOnHover?: boolean;
  iconLeft?: boolean;
}

export const mapFavoriteTabToQuery = (type: string): GqlFavoritesConnectionKey => {
  switch (type) {
    case 'fagbøker':
      return 'favoriteTextbookDocuments';
    case 'innsiktsartikler': {
      return 'favoritePublishedPages';
    }
    case 'maler': {
      return 'favoritePublishedContracts';
    }
    case 'artikler': {
      return 'favoriteJournalArticleDocuments';
    }
    case 'lovkommentarer': {
      return 'legalDocuments';
    }
    default: {
      return 'allFavorites';
    }
  }
};

const FavoritesFeed: React.FC<FavoritesFeedProps> = ({
  favoriteCategory,
  buttonConfig,
  initialLoadCount,
  displayMoreInterval = 0,
  displayGoToFavoritesBtn,
  onTruncate,
  displayLoadMoreButtons = true,
  redFooterIconsOnHover,
  iconLeft,
}) => {
  const [displayCount, setDisplayCount] = React.useState(initialLoadCount);

  const favoriteQuery = mapFavoriteTabToQuery(favoriteCategory);
  const { result, authStatus, fetchMore } = useFavoriteQuery(favoriteQuery, initialLoadCount, displayMoreInterval);

  const handleTruncate = () => {
    setDisplayCount(initialLoadCount);

    if (onTruncate) onTruncate();
  };

  React.useEffect(() => {
    // Set initial display count when favorite category changes
    setDisplayCount(initialLoadCount);
  }, [favoriteCategory, initialLoadCount]);

  return GqlResult.of(result)
    .flatMapOk<GqlFavoriteConnection<GqlAnyFavoritesEdge>>((data) => {
      const favoriteData = data[favoriteQuery];

      if (!favoriteData) {
        return GqlResult.unknownError();
      }

      if (favoriteData.error) {
        switch (favoriteData.error.type) {
          case 'UNAUTHORIZED':
          case 'UNAUTHENTICATED':
            if (authStatus === AuthenticationStatus.UNKNOWN) {
              // Waiting for the app to aquire a real authentication status, just
              // keep showing the loading UI in the meantime
              return GqlResult.loading();
            }
        }

        return GqlResult.gqlError(favoriteData.error);
      }

      return GqlResult.ok(favoriteData);
    })
    .map((favoriteData): React.ReactElement | null => {
      const { edges, pageInfo: { hasNextPage = false, endCursor = undefined } = {} } = favoriteData;
      const noticesInCache = edges?.length || 0;
      const canDisplayMore = displayCount < noticesInCache || hasNextPage;

      const handleLoadMore = () => {
        const desiredDisplayedCount = displayCount + displayMoreInterval;

        if (desiredDisplayedCount >= noticesInCache && endCursor) {
          fetchMore(endCursor);
        }

        setDisplayCount(desiredDisplayedCount);
      };

      return (
        <div>
          {edges?.length === 0 && (
            <div>Du har ingen {favoriteCategory === 'alle' ? 'favorittmarkeringer' : `favorittmarkerte ${favoriteCategory}`}</div>
          )}
          <FavoritesList edges={edges?.slice(0, displayCount)} buttonConfig={buttonConfig} iconLeft={!!iconLeft} />
          <ColumnSectionFooter>
            {displayLoadMoreButtons && (
              <ColumnSectionFooterLeft>
                <ReadMoreAndCloseButtons
                  onLoadMore={handleLoadMore}
                  onClose={handleTruncate}
                  displayLoadMoreAction={canDisplayMore}
                  displayCloseAction={displayCount > initialLoadCount}
                  redIconsOnHover={!!redFooterIconsOnHover}
                />
              </ColumnSectionFooterLeft>
            )}
            {displayGoToFavoritesBtn && (
              <ColumnSectionFooterRight>
                <ReadMoreButton path="/favoritter" txt="Alle favoritter" redIconOnHover={!!redFooterIconsOnHover} />
              </ColumnSectionFooterRight>
            )}
          </ColumnSectionFooter>
        </div>
      );
    })
    .mapLoading((): React.ReactElement | null => null)
    .getOrElseGet((notOk) => <Throw error={GqlResult.notOkToJuridikaError(notOk)} />);
};

export default FavoritesFeed;
