import * as React from 'react';
import styled from 'styled-components';
import { SocialMediaLink } from './SocialMediaLink';
import { LinkCss, StyledExternalLink } from '../StyledLink/StyledLink';
import Grid from '../Grid/Grid';
import GridItem from '../Grid/GridItem';
import { GridConfig } from '../Grid/gridConfig';

const GridWrapperCss = styled.div`
  padding: 0.625rem 0 3rem 0;
  background-color: ${(props) => props.theme.colorTokens.grey[900]};
  display: flex;
  justify-content: left;
  flex-wrap: wrap;

  li {
    ${LinkCss} {
      color: ${(props) => props.theme.colorTokens.purple[100]};
      svg {
        width: 1.5rem;
        height: 1.5rem;

        path {
          fill: ${(props) => props.theme.colorTokens.purple[100]};
        }
      }
    }
  }
`;

const GRID_MARGIN = { topBottom: 1, sides: 1 };
const GRID_LIST_CONFIG: GridConfig = {
  isListGrid: true,
  margin: GRID_MARGIN,
  numberOfColumns: 2,
  mediaQueries: [
    {
      breakpoint: 'sm',
      numberOfColumns: 4,
      margin: GRID_MARGIN,
    },
    {
      breakpoint: 1920,
      numberOfColumns: 1,
      margin: GRID_MARGIN,
    },
  ],
};

export const FooterSoMeList: React.FC<{ linkList: SocialMediaLink[] }> = ({ linkList }) => {
  return (
    <GridWrapperCss>
      <Grid config={GRID_LIST_CONFIG}>
        {linkList.map((item) => (
          <GridItem key={item.link} config={GRID_LIST_CONFIG}>
            <StyledExternalLink href={item.link} leftIcon={item.icon}>
              {item.displayText}
            </StyledExternalLink>
          </GridItem>
        ))}
      </Grid>
    </GridWrapperCss>
  );
};

export default FooterSoMeList;
