import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import { FavoriteMutationHookOptions, FavoriteMutationTuple } from '../../models/GqlFavorite';
import { capitalizeFirstLetter } from '../textUtils';

export type FavoriteCollection = 'FavoritePublishedPage' | 'FavoriteActWork' | 'FavoriteLiteratureDocument';

export type FavoriteMutation =
  | 'addFavoritePublishedPage'
  | 'removeFavoritePublishedPage'
  | 'addFavoriteActWork'
  | 'removeFavoriteActWork'
  | 'addFavoriteLiteratureDocument'
  | 'removeFavoriteLiteratureDocument';

const mutationQuery = (mutation: FavoriteMutation) => gql`
  mutation ${capitalizeFirstLetter(mutation)}($id: ID!) {
    ${mutation}(id: $id) {
      success
      error {
        type
      }
    }
  }
`;

export const useFavoriteMutation = (mutation: FavoriteMutation, opts?: FavoriteMutationHookOptions): FavoriteMutationTuple =>
  useMutation(mutationQuery(mutation), opts);
