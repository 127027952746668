import { RefObject, useEffect, useRef } from 'react';

// The timeout delay is a somewhat hacky way to wait for DOM updates so that the scrollIntoView works.
// Without the timeout, the scrollIntoView effect seems to only move the scroll position a little bit
// before being stopped by something else going on in the DOM. There might be a cleaner way to do this
// without an arbitrary timeout delay. The current delay might too short for some users depending on
// the speed of the user's device.

const SET_TIMEOUT_DELAY = 500;

export function useScrollActiveModuleIntoViewRef(isActive: boolean): RefObject<HTMLDivElement> {
  const rootDivForScrollingIntoViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      setTimeout(
        () => rootDivForScrollingIntoViewRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }),
        SET_TIMEOUT_DELAY
      );
    }
  }, [isActive]);

  return rootDivForScrollingIntoViewRef;
}
