import * as React from 'react';
import DoubleArrowsRight from 'components/icons/DoubleArrowsRight';
import DoubleArrowsLeft from 'components/icons/DoubleArrowsLeft';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { Chapter as ChapterType, Course, Sources as SourcesType } from 'pages/AcademyPage/types';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { RootState } from 'state/types';
import { useSelector } from 'util/hooks/useSelector';
import { useDispatch } from 'react-redux';
import { setValue } from 'state/ui/uiActions';
import { useDimensions } from 'util/hooks/useDimensions';
import HeaderActions from './HeaderActions/HeaderActions';
import Chapter, { CourseHeader } from './Chapter/Chapter';
import Sources from './Sources/Sources';
import { SidebarCss, DockButtonCss, SidebarContentCss, CourseCss, SidebarModalCss } from './Styles';

export enum Display {
  CHAPTERS,
  SOURCES,
}

interface CourseSourcesType {
  title: string;
  sources: SourcesType[];
}

interface SidebarContentProps {
  data: ChapterType[];
  className?: string;
  courseSlug?: string;
}

const SidebarContent: FC<SidebarContentProps> = memo(({ data, courseSlug, className }) => {
  const [display, setDisplay] = useState(Display.CHAPTERS);
  const [courseSourcesData, setCourseSourcesData] = useState<CourseSourcesType | null>(null);
  const [course, setCourse] = useState<Course | null>(null);
  const dispatch = useDispatch();

  const { observe, width } = useDimensions({
    onResize: ({ observe }) => {
      observe();
    },
  });

  const currentChapterId = useSelector((state: RootState) => state.ui.akademi.currentChapterId);

  const close = () => dispatch(setValue(false, ['akademi', 'sidebarIsOpen']));

  useEffect(() => {
    if (!currentChapterId) return;
    const currentChapter = data.find((chapter) => chapter.id === currentChapterId);

    if (currentChapter) {
      fetch(`/api/academy/chaptersources/${currentChapter.id}`)
        .then((res) => res.json())
        .then((res) => setCourseSourcesData({ title: currentChapter.title || '', sources: res }));
    }

    return () => {
      setCourseSourcesData(null);
    };
  }, [currentChapterId, data]);

  useEffect(() => {
    if (courseSlug) {
      fetch(`/api/academy/course/${courseSlug}`)
        .then((res) => res.json())
        .then((res) => setCourse(res));
    }

    return () => {
      setCourse(null);
    };
  }, [courseSlug]);

  const checkIfIntroIsSelected = data.find((chapter) => chapter.id === currentChapterId)?.slug === 'intro';

  return (
    <SidebarContentCss ref={observe} className={className || ''}>
      <HeaderActions
        handleViewCourses={() => setDisplay(Display.CHAPTERS)}
        handleViewSources={() => setDisplay(Display.SOURCES)}
        onClose={() => close()}
        display={display}
        showSources={!!courseSourcesData?.sources || false}
      />
      {(() => {
        switch (display) {
          case Display.CHAPTERS:
            return (
              <CourseCss>
                <CourseHeader isSelected={checkIfIntroIsSelected} courseMetadata={course} />
                {data.map(
                  (chapter) =>
                    chapter.slug !== 'intro' && (
                      <Chapter
                        key={chapter.id}
                        chapterData={chapter}
                        onModuleClick={() => close()}
                        sidebarWidth={width || 0}
                        courseSlug={courseSlug}
                      />
                    )
                )}
              </CourseCss>
            );

          case Display.SOURCES:
            return (
              courseSourcesData && (
                <Sources
                  data={courseSourcesData.sources}
                  chapterTitle={courseSourcesData.title}
                  courseTitle={course?.title || ''}
                />
              )
            );
        }
      })()}
    </SidebarContentCss>
  );
});

export const SidebarModal: FC<SidebarContentProps> = memo(({ data, courseSlug }) => {
  const ref = useRef<HTMLDivElement>(null);

  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');
  const sidebarIsOpen = useSelector((state: RootState) => state.ui.akademi.sidebarIsOpen);

  useEffect(() => {
    if (viewportSize !== 'large')
      if (ref.current) {
        // Set focus to the new component when it appears
        ref.current.focus();
      }
  }, [viewportSize]);

  const topElementsApproxHeight = useSelector((state: RootState) => state.ui.topElementsApproxHeight);

  if (!sidebarIsOpen) return null;

  return (
    sidebarIsOpen && (
      <SidebarModalCss tabIndex={0} ref={ref} $topElementsApproxHeight={topElementsApproxHeight}>
        <SidebarContent data={data} courseSlug={courseSlug} />
      </SidebarModalCss>
    )
  );
});

export const Sidebar: FC<SidebarContentProps> = memo(({ data, courseSlug }) => {
  const toggle = useSelector((state: RootState) => state.ui.akademi.sidebarIsOpen);
  const dispatch = useDispatch();
  const handleToggle = () => dispatch(setValue(!toggle, ['akademi', 'sidebarIsOpen']));

  const topElementsApproxHeight = useSelector((state: RootState) => state.ui.topElementsApproxHeight);

  return (
    <SidebarCss $show={toggle} $topElementsApproxHeight={topElementsApproxHeight}>
      <DockButtonCss
        $open={toggle}
        $topElementsApproxHeight={topElementsApproxHeight}
        onClick={handleToggle}
        icon={toggle ? <DoubleArrowsRight /> : <DoubleArrowsLeft />}
        aria-label={toggle ? 'lukk sidepanel' : 'åpne sidepanel'}
      />
      <SidebarContent data={data} className="sidebar-content-sticky" courseSlug={courseSlug} />
    </SidebarCss>
  );
});
