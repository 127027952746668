class CondVar {
  private promise: Promise<void>;

  private resolve: () => void;

  private reject: (error: any) => void;

  constructor() {
    this.resolve = () => {};
    this.reject = () => {};
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  wait(): Promise<void> {
    return this.promise;
  }

  signal() {
    this.resolve();
  }
}

export class AsyncFifoQueue<T> {
  private items: Array<T>;

  private condVar: CondVar;

  constructor() {
    this.items = [];
    this.condVar = new CondVar();
  }

  send(item: T): void {
    this.items.push(item);
    this.condVar.signal();
  }

  async recv(): Promise<T> {
    if (this.items.length > 0) {
      const item = this.items.shift();
      if (this.items.length === 0) {
        // queue is now empty and now need to potentially wait for it to
        // become non-empty again.
        this.condVar = new CondVar();
      }
      return item as T;
    }
    await this.condVar.wait();
    return this.recv();
  }
}
