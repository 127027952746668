import * as React from 'react';
import styled from 'styled-components';
import { mailToLink, MailToOptions } from 'util/mailToLink';
import ChatBubble from 'icons/ChatBubble';
import Envelope from 'icons/Envelope';
import { useDispatch } from 'react-redux';
import { setValue } from 'state/ui/uiActions';
import { Button } from './Button/Button';
import { StyledExternalLink } from './StyledLink/StyledLink';

const ContactUsButtonsCss = styled.div`
  margin-left: -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ButtonWrapperCss = styled.div`
  margin-left: 1rem;
  flex-grow: 1;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ContactUsButtons: React.FC<MailToOptions> = ({ subject, body }) => {
  const dispatch = useDispatch();

  const openChat = () => {
    dispatch(setValue('maximized', ['chat', 'visibility']));
  };

  const mailTo = mailToLink({
    subject,
    body,
  });

  return (
    <ContactUsButtonsCss>
      <ButtonWrapperCss>
        <Button leftIcon={ChatBubble} onClick={() => openChat()} $variant="secondary" $size="sm" $rounded $fullWidth>
          Chat med oss
        </Button>
      </ButtonWrapperCss>
      <ButtonWrapperCss>
        <StyledExternalLink leftIcon={Envelope} href={mailTo} $variant="secondary" $size="sm" $rounded $fullWidth>
          Send oss e-post
        </StyledExternalLink>
      </ButtonWrapperCss>
    </ContactUsButtonsCss>
  );
};

export default ContactUsButtons;
