import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import PageviewStatisticsLogger from 'containers/StatisticsLogger/PageviewStatisticsLogger';
import AcademyFrontPage from './pages/FrontPage/AcademyFrontPage';
import CoursePage from './pages/CoursePage/CoursePage';

export const AKADEMI_PATH = 'akademi';

const AcademyPage: FC = () => {
  return (
    <Switch>
      <Route path={`/${AKADEMI_PATH}`} exact>
        <PageviewStatisticsLogger title="Akademi forside" />
        <AcademyFrontPage />
      </Route>
      <Route path={`/${AKADEMI_PATH}/kurs/:courseSlug`}>
        <CoursePage />
      </Route>
    </Switch>
  );
};

export default AcademyPage;
