import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Sidebar, SidebarModal } from 'pages/AcademyPage/components/Sidebar/Sidebar';
import { Chapter, Course, Module } from 'pages/AcademyPage/types';
import { setValue } from 'state/ui/uiActions';
import { useDispatch, useSelector, useStore } from 'react-redux';
import ShareCourseModal from 'pages/AcademyPage/components/ShareCourseModal/ShareCourseModal';
import {
  ACADEMY_VIEWPORT_SIZE,
  calculateTotalProgressInAllChapters,
  getModulesProgress,
  useUserHasAkademiBeta,
} from 'pages/AcademyPage/utils';
import { RootState } from 'state/types';
import { useViewportSize } from 'util/hooks/useViewportSize';
import FinishedCourseModal from 'pages/AcademyPage/components/FinishedCourseModal/FinishedCourseModal';
import { CourseContentContext, CourseContent } from 'pages/AcademyPage/utils/academyCourseContentContext';
import { useLocation } from 'react-router';
import { isCourseModulesBeforeFinalTestCompletedSelector } from 'state/selectors';
import { Helmet } from 'react-helmet-async';
import { JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import { Throw } from 'components/error/Throw';
import { CurrentCourseViewCss } from './Styles';
import CurrentCourseContent from './CurrentCourseContent';

const CurrentCourseView: FC = () => {
  const isFinalTestCompleted = useSelector((state: RootState) => state.ui.akademi.isFinalTestCompleted);

  const store = useStore();
  const hasAkademiBeta = useUserHasAkademiBeta();

  // TODO: AKADEMI look into reducer
  const [openShareCourseModal, setOpenShareCourseModal] = useState<boolean>(false);
  const [copyTextSelected, setCopyTextSelected] = useState<boolean>(false);
  const [currentChapter, setCurrentChapter] = useState<Chapter | undefined>();
  const [currentCourse, setCurrentCourse] = useState<Course | undefined>();
  const [openFinishedCourseModal, setOpenFinishedCourseModal] = useState<boolean>(false);

  // All chapters in the course
  const [courseChapters, setCourseChapters] = useState<Chapter[] | undefined>();
  const [allModules, setAllModules] = useState<Module[]>();

  // TODO: courseChapters over blir satt i denne komponenten, mens allModules blir satt i child componenten CurrentCourseContent.
  // De to er kan begge sies å være kursinnholddata og burde nok bli satt på samme sted (selv om første er mer struktur og andre mer innhold).
  // Videre, så blir modulesProgress lastet inn i denne komponenten.
  // Det som har å gjøre med kursinnholdsdata og det som har med progresjsonsdata kunne ideelt sett skilles ut bedre
  // i separate custom hooks.
  // Og er usikker, men kanskje burde all denne staten ideelt sett bli samlet i redux, selv om kursinnhold ikke er state som endrer seg.
  // For det virker kanskje ikke så lett å få tak i kursinnhold fra context fra redux-selectorer og actions, det må vel sendes inn.
  // Eller man kunne samlet all staten i en eller to contexts og droppet å bruke redux for akademi
  // (kanskje gjør redux bare ting unødvendig komplisert?).

  const courseContent: CourseContent = useMemo(
    () => ({ chapters: courseChapters || [], modules: allModules || [] }),
    [allModules, courseChapters]
  );

  const isCourseModulesBeforeFinalTestCompleted = useSelector((state: RootState) =>
    isCourseModulesBeforeFinalTestCompletedSelector(state, courseContent)
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');
  const exitFocusRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const finishedCourseModalIsDismissed = localStorage.getItem('finishedCourseModalIsDismissed') === 'true';
    const showFinishedCourseModal =
      isCourseModulesBeforeFinalTestCompleted && !finishedCourseModalIsDismissed && !isFinalTestCompleted;

    if (showFinishedCourseModal) {
      setOpenFinishedCourseModal(true);
    }
  }, [isCourseModulesBeforeFinalTestCompleted, isFinalTestCompleted]);

  useEffect(() => {
    const allModuleIds = allModules?.map((module) => module.id);
    if (allModuleIds) {
      const { accessToken } = store.getState().session;
      getModulesProgress(accessToken, allModuleIds).then((modulesProgress) => {
        dispatch(setValue(modulesProgress, ['akademi', 'moduleProgress']));
        dispatch(setValue(calculateTotalProgressInAllChapters(modulesProgress, courseContent), ['akademi', 'chapterProgress']));
      });
    }
  }, [allModules, courseContent, dispatch, store]);

  // Set all chapters
  useEffect(() => {
    if (currentCourse) {
      fetch(`/api/academy/courseChapters/${currentCourse.id}`).then((res) => res.json().then((res) => setCourseChapters(res)));
    }
  }, [currentCourse]);

  const handleModalExit = () => {
    setTimeout(() => {
      if (exitFocusRef.current) {
        exitFocusRef.current.focus();
      }
    }, 0);
  };

  const isIntroPage = location.pathname.split('/').at(-1).toLowerCase() === 'intro';

  const getCourseSlug = useCallback((): string => {
    const lastPathnameParam = location.pathname.split('/').at(-1).toLowerCase();
    const secondPathnameParam = location.pathname.split('/').at(-2).toLowerCase();
    const thirdPathnameParam = location.pathname.split('/').at(-3).toLowerCase();

    switch (lastPathnameParam) {
      case 'intro':
        return secondPathnameParam;
      default:
        return thirdPathnameParam;
    }
  }, [location.pathname]);

  // Set current course
  useEffect(() => {
    const courseSlug = getCourseSlug();

    fetch(`/api/academy/course/${courseSlug}`).then((res) =>
      res.json().then((res) => {
        setCurrentCourse(res);
      })
    );
  }, [getCourseSlug]);

  return (
    <>
      {openFinishedCourseModal && (
        <FinishedCourseModal
          course={{ title: currentCourse?.title || '', slug: currentCourse?.slug || '' }}
          open={openFinishedCourseModal}
          setOpen={(open: boolean) => setOpenFinishedCourseModal(open)}
        />
      )}

      {openShareCourseModal && (
        <ShareCourseModal
          open={openShareCourseModal}
          setOpen={(open: boolean) => setOpenShareCourseModal(open)}
          selected={copyTextSelected}
          setSelected={setCopyTextSelected}
          onExit={handleModalExit}
        />
      )}

      <Helmet>
        <title>{currentCourse?.title}</title>
      </Helmet>

      <CurrentCourseViewCss $large={viewportSize === 'large'}>
        <CourseContentContext.Provider value={courseContent}>
          {viewportSize !== 'large' && <SidebarModal data={courseChapters || []} courseSlug={currentCourse?.slug} />}
          <CurrentCourseContent
            exitFocusRef={exitFocusRef}
            setCurrentChapter={setCurrentChapter}
            setAllModules={setAllModules}
            currentChapter={currentChapter}
            currentCourse={currentCourse}
            setOpenShareCourseModal={setOpenShareCourseModal}
          />
          {viewportSize === 'large' && <Sidebar data={courseChapters || []} courseSlug={currentCourse?.slug} />}
        </CourseContentContext.Provider>

        {/* Kast 403-error hvis brukeren ikke har AkademiBeta-tilgang eller er på forsiden (/intro). */}
        {!hasAkademiBeta && !isIntroPage && <Throw error={{ type: JuridikaErrorType.GRAPHQL_UNAUTHORIZED }} />}
      </CurrentCourseViewCss>
    </>
  );
};

export default CurrentCourseView;
