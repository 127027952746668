import React, { FC } from 'react';
import Svg from 'components/ui/Svg';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const ThreeDots: FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={4} height={18} viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="2" cy="2" r="2" fill={color} />
    <circle cx="2" cy="9" r="2" fill={color} />
    <circle cx="2" cy="16" r="2" fill={color} />
  </Svg>
);

ThreeDots.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default ThreeDots;
