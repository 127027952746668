import React from 'react';
import styled from 'styled-components';

import { capitalizeFirstLetter } from 'util/textUtils';
import createFontStyles from 'util/createFontStyles';
import LogInOrOutButtons from '../../containers/LogInOrOutButtons';
import { OldLinkButton } from '../OldButton';
import ContentList from '../ContentList';

const WrapperCss = styled.div<{ bgColor?: string }>`
  margin: 0 auto;
  padding: 1.25rem;
  max-width: 37rem;
  color: ${({ theme }) => theme.colorTokens.grey[900]};

  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}

  .c-btn--isOceanBlueInverted {
    padding: 0;
    font-weight: 400;
  }
`;

const SectionCss = styled.div`
  text-align: center;
  margin: 4rem 0;

  :last-child {
    margin-top: 5rem;
  }

  h2 {
    ${({ theme }) =>
      createFontStyles({
        family: theme.fontFamilies.primary,
        size: 2.375,
        weight: 500,
      })}
  }

  h3 {
    ${({ theme }) =>
      createFontStyles({
        family: theme.fontFamilies.primary,
        size: 1.75,
        weight: 500,
      })}
  }
`;

const ActionButtonWrapperCss = styled.div`
  margin: 1.25rem 0;
  .c-btn {
    padding: 0.625rem 1.25rem;
    display: inline-flex;
  }
`;

const ContentListWrapperCss = styled.div`
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
`;

const LogInOrOutButtonsCss = styled(LogInOrOutButtons)`
  padding: 0.625rem 1.25rem;
  background-color: ${({ theme }) => theme.colorTokens.red[600]};
`;

const BriefLoginPrompt: React.FC<{
  isResponsive?: boolean;
  subscriptionType?: string;
  backgroundColor?: string;
}> = ({ isResponsive, subscriptionType, backgroundColor }) => (
  <WrapperCss bgColor={backgroundColor}>
    <SectionCss>
      <h2>Du er ikke innlogget</h2>
      <p>
        For å få tilgang til dette innholdet må du ha abonnement på Juridika{' '}
        {subscriptionType && capitalizeFirstLetter(subscriptionType)} via din arbeidsgiver eller ditt studiested. Du kan også
        kjøpe personlig abonnement.
      </p>
      <ActionButtonWrapperCss>
        <LogInOrOutButtonsCss />
      </ActionButtonWrapperCss>
    </SectionCss>
    <SectionCss>
      <h3>Kjøp Juridika til deg og ditt team</h3>
      <ContentListWrapperCss>
        <ContentList isResponsive={!!isResponsive} />
      </ContentListWrapperCss>
      <ActionButtonWrapperCss>
        <OldLinkButton to="/prøv-juridika" isSecondary isBig>
          Få prøvetilgang
        </OldLinkButton>
      </ActionButtonWrapperCss>
    </SectionCss>
  </WrapperCss>
);

export default BriefLoginPrompt;
