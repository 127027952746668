import React, { FC } from 'react';
import {
  stripTimeFromISO8601Date,
  convertISO8601DateToDDMMYYYY,
} from 'util/dateHelpers';
import { DateLastUpdatedCss } from './styles';

interface Props {
  ISO8601Date: string; // YYYY-MM-DDThh:mm:ss
}

const DateLastUpdated: FC<Props> = ({ ISO8601Date }) => {
  const formattedDate = stripTimeFromISO8601Date(ISO8601Date);
  const humanReadableDate = convertISO8601DateToDDMMYYYY(formattedDate);

  return (
    <DateLastUpdatedCss>
      Sist oppdatert <time dateTime={formattedDate}>{humanReadableDate}</time>
    </DateLastUpdatedCss>
  );
};

export default DateLastUpdated;
