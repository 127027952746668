import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const Plus: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 12 12" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M5.14285714,5.14285714 L0,5.14285714 L0,6.85714286 L5.14285714,6.85714286 L5.14285714,12 L6.85714286,12 L6.85714286,6.85714286 L12,6.85714286 L12,5.14285714 L6.85714286,5.14285714 L6.85714286,0 L5.14285714,1.04969726e-16 L5.14285714,5.14285714 Z"
    />
  </Svg>
);

Plus.defaultProps = {
  color: COLOR_TOKENS.default.white,
  size: 12,
};

export default Plus;
