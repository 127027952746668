import * as React from 'react';
import { InputCss, SwitchCss } from 'components/ToggleSwitch/Styles';
import { ButtonOverrideCss, SwitchBaseOverrideCss, ThumbOverrideCss, TrackOverrideCss, TitleOverrideCss } from './Styles';

export const AcademyToggleOnAndOffSwitch: React.FC<{
  isChecked: boolean;
  onToggleSwitch: () => void;
  labelLeft?: string;
  labelRight?: string;
  className?: string;
}> = ({ isChecked, onToggleSwitch, labelLeft, labelRight, className }) => (
  <ButtonOverrideCss className={className} onClick={onToggleSwitch} aria-expanded={isChecked}>
    {labelLeft && <TitleOverrideCss>{labelLeft}</TitleOverrideCss>}
    <SwitchCss>
      <SwitchBaseOverrideCss $isChecked={isChecked}>
        <InputCss tabIndex={-1} />
        <ThumbOverrideCss $isChecked={isChecked} />
      </SwitchBaseOverrideCss>
      <TrackOverrideCss $isChecked={isChecked} />
    </SwitchCss>
    {labelRight && <TitleOverrideCss>{labelRight}</TitleOverrideCss>}
  </ButtonOverrideCss>
);
