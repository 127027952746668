import * as React from 'react';
import styled, { css } from 'styled-components';

import Refresh from 'icons/Refresh';
import PenTool from 'icons/PenTool';
import NewsPaper from 'icons/NewsPaper';
import Diamond from 'icons/Diamond';
import createMediaQuery from 'util/createMediaQuery';

const ContentWrapperCss = styled.div<{ isResponsive: boolean }>`
  ${({ isResponsive }) =>
    isResponsive &&
    createMediaQuery(
      1080,
      css`
        display: flex;
        justify-content: space-around;

        li:first-child {
          margin-right: 3.75rem;
          margin-bottom: 0;
        }
      `
    )}

  ul {
    padding: 0;

    li {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.01rem;
      color: ${({ theme }) => theme.colorTokens.default.black};

      :not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    li svg {
      margin-right: 0.875rem;
    }
  }
`;

export interface ListItem {
  text: string;
  icon: React.FC;
}

const listItems: ListItem[] = [
  { text: 'Ajourførte kommentarer', icon: Refresh },
  { text: 'Tankevekkende artikler', icon: PenTool },
  { text: 'Faglig relevante nyheter', icon: NewsPaper },
  { text: 'Eldre bokskatter fra vår katalog', icon: Diamond },
];

export const SubList: React.FC<{
  list: ListItem[];
}> = ({ list }) => (
  <ul>
    {list.map(({ text, icon: Icon }) => (
      <li key={Icon.name}>
        <Icon />
        <span>{text}</span>
      </li>
    ))}
  </ul>
);

const ContentList: React.FC<{ isResponsive: boolean }> = ({ isResponsive }) => (
  <ContentWrapperCss isResponsive={isResponsive}>
    <SubList list={listItems.slice(0, 2)} />
    <SubList list={listItems.slice(2)} />
  </ContentWrapperCss>
);

export default ContentList;
