import React, { FC } from 'react';
import TextBlockStylesheet from './Styles';

interface TextBlockProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const TextBlock: FC<TextBlockProps> = ({ children, className = '' }) => (
  <TextBlockStylesheet className={className}>{children}</TextBlockStylesheet>
);

export default TextBlock;
