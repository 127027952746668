import URI from 'urijs';

import { SortField } from '../pages/SearchPage/interfaces';

import { sanitizeSearchTerm } from './queryParams';

export interface BasicSearchQuery {
  term: string;
}

export interface SearchQueryMetadata {
  page: number;
  pageSize: number;
  typeScopes: string[];
  contributorScopes: string[];
  actShortTitleScopes: string[];
  journalTitleScopes: string[];
  textbookEditionTitleScopes: string[];
  contentCategoryNameScopes: string[];
  sortField: SortField;
  sortOrder: string;
  fromDate: string | undefined;
  toDate: string | undefined;
}

export type PartialSearchQuery = BasicSearchQuery & Partial<SearchQueryMetadata>;
export type FullSearchQuery = BasicSearchQuery & SearchQueryMetadata;

const isArray = (obj: any | any[] | undefined) => {
  if (Array.isArray(obj)) return obj;
  if (!obj) return [];
  return [obj];
};

const verifyDate = (date: string | undefined) => {
  if (date && Date.parse(date)) {
    return date;
  }
  return undefined;
};

const parseNumber = (num: string | undefined, defaultValue: number) => {
  if (num) return parseInt(num, 10);
  return defaultValue;
};

export const deserializeQueryString = (search: string): FullSearchQuery => {
  const query: { [key: string]: any } = URI.parseQuery(search);
  return {
    term: query.q || '',
    typeScopes: isArray(query.typeScopes),
    contributorScopes: isArray(query.contributorScopes),
    actShortTitleScopes: isArray(query.actShortTitleScopes),
    journalTitleScopes: isArray(query.journalTitleScopes),
    textbookEditionTitleScopes: isArray(query.textbookEditionTitleScopes),
    contentCategoryNameScopes: isArray(query.contentCategoryNameScopes),
    page: parseNumber(query.page, 1),
    pageSize: parseNumber(query.size, 10),
    sortField: query.sortField || 'score',
    sortOrder: query.sortOrder || 'desc',
    fromDate: verifyDate(query.fromDate),
    toDate: verifyDate(query.toDate),
  };
};
export const toSearchURL = (query: PartialSearchQuery): string => {
  const {
    page,
    pageSize,
    typeScopes,
    contributorScopes,
    actShortTitleScopes,
    journalTitleScopes,
    textbookEditionTitleScopes,
    contentCategoryNameScopes,
    term,
    sortField,
    sortOrder,
    fromDate,
    toDate,
  } = query;
  const searchObj = {
    q: sanitizeSearchTerm(term),
    ...(typeScopes && { typeScopes }),
    ...(contributorScopes && { contributorScopes }),
    ...(actShortTitleScopes && { actShortTitleScopes }),
    ...(journalTitleScopes && { journalTitleScopes }),
    ...(textbookEditionTitleScopes && { textbookEditionTitleScopes }),
    ...(contentCategoryNameScopes && { contentCategoryNameScopes }),
    ...(page && { page }),
    ...(pageSize && { size: pageSize }),
    ...(sortField && { sortField }),
    ...(sortOrder && { sortOrder }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
  };
  return URI('/').segment('sok').setQuery(searchObj).toString();
};
