import * as React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const ArrowDown: React.FC<IconPropTypes> = ({ size, height, width, color, ...props }) => (
  <Svg width={width || size} height={height || size} className="c-icon c-icon-arrowDown" viewBox="0 0 16 9" {...props}>
    <polygon
      fill={color}
      fillRule="evenodd"
      points="61 8.688 53 16 45 8.688 46.714 7 53 12.906 59.286 7"
      transform="translate(-45 -7)"
    />
  </Svg>
);

ArrowDown.defaultProps = {
  color: COLOR_TOKENS.grey[800],
  size: 16,
  height: 9,
  width: 13,
};

export default ArrowDown;
