import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import styled from 'styled-components';

import { ButtonAndLinkContent } from '../Button/ButtonAndLinkContent';

import { buttonAndLinkCss, ButtonAndLinkProps, TransientButtonAndLinkStyleProps } from '../Button/ButtonAndLinkCss';

export const LinkCss = styled(ReactRouter.Link)<TransientButtonAndLinkStyleProps>`
  ${buttonAndLinkCss}
`;

export type StyledInternalLinkProps = ButtonAndLinkProps & ReactRouter.LinkProps;
export type StyledExternalLinkProps = ButtonAndLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const StyledInternalLink: React.FC<StyledInternalLinkProps> = ({ children, leftIcon, rightIcon, ...linkProps }) => (
  <LinkCss {...linkProps}>
    <ButtonAndLinkContent leftIcon={leftIcon} rightIcon={rightIcon} $color={linkProps.$color} $variant={linkProps.$variant}>
      {children}
    </ButtonAndLinkContent>
  </LinkCss>
);

export const StyledExternalLink: React.FC<StyledExternalLinkProps> = ({ children, leftIcon, rightIcon, ...linkProps }) => {
  return (
    <LinkCss as="a" {...linkProps}>
      <ButtonAndLinkContent leftIcon={leftIcon} rightIcon={rightIcon} $color={linkProps.$color} $variant={linkProps.$variant}>
        {children}
      </ButtonAndLinkContent>
    </LinkCss>
  );
};
