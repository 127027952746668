import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from '../IconPropTypes';

export const Facebook: React.FC = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.014467 17.065322 19.313017 13.21875 19.898438 L 13.21875 14.384766 L 15.546875 14.384766 L 15.912109 12.019531 L 13.21875 12.019531 L 13.21875 10.726562 C 13.21875 9.7435625 13.538984 8.8710938 14.458984 8.8710938 L 15.935547 8.8710938 L 15.935547 6.8066406 C 15.675547 6.7716406 15.126844 6.6953125 14.089844 6.6953125 C 11.923844 6.6953125 10.654297 7.8393125 10.654297 10.445312 L 10.654297 12.019531 L 8.4277344 12.019531 L 8.4277344 14.384766 L 10.654297 14.384766 L 10.654297 19.878906 C 6.8702905 19.240845 4 15.970237 4 12 C 4 7.5698774 7.5698774 4 12 4 z" />
  </svg>
);

export const Twitter: React.FC = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <path d="M22,3.999c-0.78,0.463-2.345,1.094-3.265,1.276c-0.027,0.007-0.049,0.016-0.075,0.023c-0.813-0.802-1.927-1.299-3.16-1.299 c-2.485,0-4.5,2.015-4.5,4.5c0,0.131-0.011,0.372,0,0.5c-3.353,0-5.905-1.756-7.735-4c-0.199,0.5-0.286,1.29-0.286,2.032 c0,1.401,1.095,2.777,2.8,3.63c-0.314,0.081-0.66,0.139-1.02,0.139c-0.581,0-1.196-0.153-1.759-0.617c0,0.017,0,0.033,0,0.051 c0,1.958,2.078,3.291,3.926,3.662c-0.375,0.221-1.131,0.243-1.5,0.243c-0.26,0-1.18-0.119-1.426-0.165 c0.514,1.605,2.368,2.507,4.135,2.539c-1.382,1.084-2.341,1.486-5.171,1.486H2C3.788,19.145,6.065,20,8.347,20 C15.777,20,20,14.337,20,8.999c0-0.086-0.002-0.266-0.005-0.447C19.995,8.534,20,8.517,20,8.499c0-0.027-0.008-0.053-0.008-0.08 c-0.003-0.136-0.006-0.263-0.009-0.329c0.79-0.57,1.475-1.281,2.017-2.091c-0.725,0.322-1.503,0.538-2.32,0.636 C20.514,6.135,21.699,4.943,22,3.999z M18,8.999c0,4.08-2.957,8.399-8.466,8.943C10.28,17.413,11,16.662,11,16.662 S8,14,7.775,10.522C8.81,10.838,9.888,10.999,11,10.999h2v-2.5c0-0.001,0-0.001,0-0.001C13.002,7.118,14.12,6,15.5,6 C16.881,6,18,7.119,18,8.5C18,8.5,18,8.92,18,8.999z" />
  </svg>
);

export const LinkedIn: React.FC = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 19 21 C 20.105 21 21 20.105 21 19 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 7.7792969 6.3164062 C 6.9222969 6.3164062 6.4082031 6.8315781 6.4082031 7.5175781 C 6.4082031 8.2035781 6.9223594 8.7167969 7.6933594 8.7167969 C 8.5503594 8.7167969 9.0644531 8.2035781 9.0644531 7.5175781 C 9.0644531 6.8315781 8.5502969 6.3164062 7.7792969 6.3164062 z M 6.4765625 10 L 6.4765625 17 L 9 17 L 9 10 L 6.4765625 10 z M 11.082031 10 L 11.082031 17 L 13.605469 17 L 13.605469 13.173828 C 13.605469 12.034828 14.418109 11.871094 14.662109 11.871094 C 14.906109 11.871094 15.558594 12.115828 15.558594 13.173828 L 15.558594 17 L 18 17 L 18 13.173828 C 18 10.976828 17.023734 10 15.802734 10 C 14.581734 10 13.930469 10.406562 13.605469 10.976562 L 13.605469 10 L 11.082031 10 z" />
  </svg>
);

export const Instagram: React.FC = () => (
  <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
  </svg>
);

export const FacebookFill: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <path
      fill="#29282E"
      fillRule="evenodd"
      d="M22 20.786c0 .67-.544 1.214-1.214 1.214H15.18v-8.52h2.86l.428-3.32H15.18V8.04c0-.96.267-1.616 1.645-1.616l1.759-.001v-2.97c-.304-.04-1.348-.13-2.562-.13-2.535 0-4.27 1.547-4.27 4.388v2.45H8.883v3.32h2.867V22H1.214C.544 22 0 21.456 0 20.786V1.214C0 .544.544 0 1.214 0h19.572C21.456 0 22 .544 22 1.214v19.572"
    />
  </svg>
);

export const TwitterFill: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19">
    <path
      fill="#29282E"
      fillRule="evenodd"
      d="M21.393.351a8.693 8.693 0 0 1-2.868 1.162C17.704.582 16.53 0 15.23 0c-2.49 0-4.512 2.148-4.512 4.796 0 .376.039.741.116 1.093-3.75-.2-7.076-2.108-9.304-5.014a5.021 5.021 0 0 0-.61 2.414c0 1.664.796 3.132 2.008 3.992a4.309 4.309 0 0 1-2.046-.598v.059c0 2.325 1.556 4.264 3.622 4.703a4.161 4.161 0 0 1-1.19.169c-.29 0-.574-.029-.848-.085.574 1.905 2.24 3.292 4.215 3.329-1.544 1.288-3.491 2.055-5.605 2.055-.364 0-.725-.022-1.077-.066C1.997 18.206 4.37 19 6.919 19c8.303 0 12.842-7.308 12.842-13.647 0-.208-.004-.417-.011-.621A9.476 9.476 0 0 0 22 2.249a8.603 8.603 0 0 1-2.592.755A4.768 4.768 0 0 0 21.393.351"
    />
  </svg>
);

export const LinkedInFill: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
    <path
      fill="#29282E"
      fillRule="evenodd"
      d="M.555 6.413h4.288V20H.555V6.413zm16.003-.13c3.048 0 5.442 1.954 5.442 6.163V20h-4.862v-7.03c0-1.841-.76-3.098-2.433-3.098-1.28 0-1.991.848-2.322 1.665-.124.294-.105.702-.105 1.11V20H7.461s.062-12.454 0-13.587h4.817v2.133c.284-.933 1.824-2.263 4.28-2.263zM2.62 0c1.582 0 2.555 1.014 2.586 2.355 0 1.316-1.004 2.359-2.616 2.359h-.03C1.006 4.714 0 3.675 0 2.359 0 1.017 1.036 0 2.62 0z"
    />
  </svg>
);

export const Email: React.FC<IconPropTypes> = ({ color, width, height, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 17" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M.664.51L13 10 25.337.51C25.744.875 26 1.406 26 2v13a2 2 0 0 1-1.997 2H1.997A1.995 1.995 0 0 1 0 15V2C0 1.41.257.877.664.51zM2 0h22L13 8 2 0z"
    />
  </Svg>
);

Email.defaultProps = {
  color: '#29282E',
  width: 26,
  height: 17,
};
