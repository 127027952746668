import React, { FC } from 'react';

import { COLORS } from 'theme/config/defaultTheme';
import IconPropTypes from 'components/icons/IconPropTypes';
import Svg from 'components/ui/Svg';

const WarningStatusCircle: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M9.923 0C4.443 0 0 4.443 0 9.923c0 5.481 4.443 9.924 9.923 9.924 5.481 0 9.924-4.443 9.924-9.924C19.847 4.443 15.404 0 9.923 0zm-.037 15.018c-.553 0-1.002-.395-1.002-.882s.448-.882 1.002-.882c.553 0 1.002.395 1.002.882s-.448.882-1.002.882zm.751-2.616H9.034l-.5-8.093h2.803l-.7 8.093z"
    />
  </Svg>
);

WarningStatusCircle.defaultProps = {
  size: 20,
  color: COLORS.iconDefault,
};

export default WarningStatusCircle;
