import React, { FC, ElementType, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import createSpacing from 'util/createSpacing';
import createFontSize from 'util/createFontSize';
import SpacingPropTypes from 'models/SpacingPropTypes';
import FontSizePropTypes from 'models/FontSizePropTypes';

import { FONT_FAMILIES } from 'theme/config/defaultTheme';

export interface TextPropTypes extends SpacingPropTypes, FontSizePropTypes {
  as?: ElementType;
  color?: string;

  variant?: string;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  quaternary?: boolean;

  small?: boolean;
  medium?: boolean;
  large?: boolean;

  size?: string;
  bold?: boolean;
  center?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  muted?: boolean;
  block?: boolean;
  lineHeight?: string;
  weight?: string | number;
  children?: ReactNode;
}

const TextCss = styled.span<TextPropTypes>`
  margin: 0;
  ${(props) => createSpacing(props)}
  ${(props) => createFontSize(props)}

  font-size: ${({ size }) => size || 'inherit'};

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
    `}

  font-weight: ${({ weight }) => weight || 'inherit'};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `}
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}
  ${({ variant }) =>
    variant &&
    css`
      font-family: ${FONT_FAMILIES[variant]};
    `}
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ block }) =>
    block &&
    css`
      display: block;
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ muted }) =>
    muted &&
    css`
      opacity: 0.7;
    `}
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

const Text: FC<TextPropTypes> = ({ variant, primary, secondary, tertiary, quaternary, small, large, ...props }) => {
  let pVariant = '__default__';

  if (primary) {
    pVariant = 'primary';
  } else if (secondary) {
    pVariant = 'secondary';
  } else if (tertiary) {
    pVariant = 'tertiary';
  } else if (quaternary) {
    pVariant = 'quaternary';
  } else if (variant) {
    pVariant = variant;
  }

  let pSize = 1;

  if (small) {
    pSize = 0;
  } else if (large) {
    pSize = 2;
  }

  return <TextCss f={pSize} variant={pVariant} {...props} />;
};

export default Text;
