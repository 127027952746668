import React, { FC, useEffect, useState } from 'react';
import Flex from 'components/ui/Flex';
import { contributorPath, getPortraitUrl } from 'util/literatureUtils';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { capitalizeFirstLetter } from 'util/textUtils';
import Notice from 'components/ui/Notice';
import {
  BylineWrapperCss,
  ContributorAvatarImageButtonCss,
  BylineContentCss,
  ContributorLink,
} from './styles';

interface Contributor {
  name: string;
  title: string;
  shortBio: string;
  slug: string;
  id: string;
  hasPortrait: boolean;
}

interface OrderedContributor extends Contributor {
  zIndex: number;
}

interface BylineProps {
  contributors: Array<Contributor>;
}

const useOrderedContributors = (
  contributors: Contributor[],
  selectedAuthorIndex: number,
  selectedAuthorZIndex: number
) => {
  const [orderedContributors, setOrderedContributors] = useState<
    OrderedContributor[]
  >([]);

  useEffect(() => {
    const getZIndex = (index: number) => {
      if (selectedAuthorIndex === index) {
        return selectedAuthorZIndex;
      }
      if (selectedAuthorIndex < index) {
        return contributors.length - index;
      }
      return index;
    };

    const updatedOrderedContributors = contributors.map((author, index) => ({
      ...author,
      zIndex: getZIndex(index),
    }));

    setOrderedContributors(updatedOrderedContributors);
  }, [contributors, selectedAuthorIndex, selectedAuthorZIndex]);

  return orderedContributors;
};

const Byline: FC<BylineProps> = ({ contributors }) => {
  const [selectedAuthorIndex, setSelectedAuthor] = useState(0);

  const juridikaConfig = useJuridikaConfig();

  const selectedAuthorZIndex = contributors.length + 1;
  const authorIsJuridika = contributors.find(
    (author) => author.slug === 'juridika'
  );

  const orderedContributors = useOrderedContributors(
    contributors,
    selectedAuthorIndex,
    selectedAuthorZIndex
  );

  const useFlexLayout = contributors.length === 1;
  const hasMultipleAuthors = contributors.length > 1;

  if (contributors.length === 0)
    return <Notice danger content="Finner ikke forfatter" />;

  return (
    <BylineWrapperCss useFlexLayout={useFlexLayout}>
      {!authorIsJuridika && (
        <Flex>
          {orderedContributors.map((author, index) => (
            <ContributorAvatarImageButtonCss
              key={author.id}
              aria-label={`Velg ${author.name}`}
              tabIndex={0}
              imageUrl={getPortraitUrl(
                juridikaConfig,
                {
                  ...author,
                  hasPortrait: author.hasPortrait,
                },
                48
              )}
              selected={index === selectedAuthorIndex}
              selectedZIndex={selectedAuthorZIndex}
              hasMultipleAuthors={hasMultipleAuthors}
              zIndex={author.zIndex}
              onClick={() => setSelectedAuthor(index)}
            />
          ))}
        </Flex>
      )}
      <BylineContentCss>
        <h3>
          {authorIsJuridika ? (
            contributors[selectedAuthorIndex].name
          ) : (
            <ContributorLink
              aria-label={`Gå til forfatterprofil for ${contributors[selectedAuthorIndex].name}`}
              itemProp="Author"
              to={contributorPath(contributors[selectedAuthorIndex])}
            >
              {capitalizeFirstLetter(contributors[selectedAuthorIndex].title)}{' '}
              {contributors[selectedAuthorIndex].name}
            </ContributorLink>
          )}
        </h3>
        {!authorIsJuridika && (
          <p>{contributors[selectedAuthorIndex].shortBio}</p>
        )}
      </BylineContentCss>
    </BylineWrapperCss>
  );
};

export default Byline;
