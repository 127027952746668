import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

import { errorClassification, ErrorClassification } from 'util/juridikaErrorUtils';
import { useIsomorphicEffect } from 'util/hooks/useIsomorphicEffect';
import * as sessionThunkActions from 'state/session/sessionThunkActions';
import { useSelector } from 'util/hooks/useSelector';
import { useAuth0 } from 'util/auth0';
import { AuthenticationStatus } from 'state/login/types';

import { LoadableErrorPage } from '../../pages/Error/LoadableErrorPage';
import { CustomErrorMessages } from '../../models/CustomErrorMessages';

import { ErrorHandlerProps } from './Catch';

export const RootError: React.FC<ErrorHandlerProps & { customErrorMessages?: CustomErrorMessages }> = ({
  error,
  customErrorMessages,
}) => {
  const juridikaConfig = useJuridikaConfig();
  const auth0 = useAuth0();
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.login.authStatus);
  const classification = errorClassification(error);

  const shouldRedirectToLogin =
    authStatus === AuthenticationStatus.NOT_AUTHENTICATED && classification === ErrorClassification.USER_UNAUTHENTICATED;

  useIsomorphicEffect(() => {
    if (shouldRedirectToLogin) {
      dispatch(sessionThunkActions.login(juridikaConfig, auth0));
    }
  }, [shouldRedirectToLogin]);

  if (shouldRedirectToLogin) {
    // Cannot display the loadable error page now, because that will interrupt
    // with the login flow - chunk loading will fail because we have already started to navigate
    // away to Lock, it will understand that before actually being done navigating away and then
    // redirect to /redirect?{...} (which is the error handling for failed js chunks). It's a
    // race condition whether we reach the Lock or the redirect page first. So.... just avoid that
    return <>Ingen tilgang. Du blir nå videreført til innlogging.</>;
  }

  return <LoadableErrorPage error={error} customErrorMessages={customErrorMessages} />;
};
