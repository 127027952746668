import * as React from 'react';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

import { JuridikaError } from 'commonUtils/models/JuridikaError';

import { CatchContext } from './CatchContext';

/**
 * React component for notifying that something is an error: "Throw" the error.
 * The closest <Catch /> component up the tree will catch the error and display it.
 */
export const Throw: React.FC<{ error: JuridikaError }> = ({ error }) => {
  const catchContext = React.useContext(CatchContext);
  if (useJuridikaConfig().isClient) {
    React.useEffect(() => {
      catchContext.catchError(error);
    }, []);
  } else {
    catchContext.catchError(error);
  }
  return null;
};
