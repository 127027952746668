import React from 'react';
import * as hast from '@universitetsforlaget/hast';

import { HastConfig } from 'util/hastConfig';

const reactNodes = (nodes: Array<hast.HastNode>, config: HastConfig): React.ReactNode =>
  nodes.map((node, index) => React.createElement(Hast, { key: index, node, config }));

export const reactChildren = (children: Array<hast.HastNode> | undefined, config: HastConfig): React.ReactNode =>
  children ? reactNodes(children, config) : null;

export const Hast: React.FC<{
  node: hast.HastNode;
  config: HastConfig;
}> = ({ node, config }) => {
  if (hast.isText(node)) {
    return React.createElement(React.Fragment, {}, node.value);
  }

  const overrides = config.overridesByTagName[node.tagName];
  if (overrides) {
    const override = overrides.find((override) => !override.match || override.match(node));
    if (override) {
      return override.render(node, config);
    }
  }

  return React.createElement(
    node.tagName,
    hast.hastPropertiesToJs(hast.htmlSpace, node.properties),
    reactChildren(node.children, config)
  );
};

export const HastFragment: React.FC<{
  nodes?: Array<hast.HastNode>;
  config: HastConfig;
}> = ({ nodes, config }) => {
  if (!nodes) return null;

  return React.createElement(React.Fragment, {}, reactNodes(nodes, config));
};
