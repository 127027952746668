import { DATADOG_APP_ID, DATADOG_CLIENT_TOKEN, HOST, useJuridikaConfig } from 'commonUtils/juridikaConfig';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { accessTokenSelector } from 'state/selectors';
import { Status } from 'util/hooks/useScriptStatus';
import { useSelector } from 'util/hooks/useSelector';
import { datadogRum } from '@datadog/browser-rum';
import { FC, ReactNode, useEffect } from 'react';
import { runHubspotScript } from './HubspotScript';
import { runRudderstackScript } from './RudderstackScript';

interface ScriptWrapperProps {
  children: ReactNode | ReactNode[];
}

export interface StatusList {
  [key: string]: Status;
}

const runCookieYesScript = () => {
  const juridikaConfig = useJuridikaConfig();
  const host = juridikaConfig.getString('HOST');

  const showCookieYesBanner = host === 'juridika.no' || host === 'stage.juridika.no';

  if (showCookieYesBanner) {
    const cookieYesKey = host === 'juridika.no' ? '5d815dd90393dea8491a765e' : 'b48dc8a615728a4e2d79e4f7';
    return (
      <script id="cookieyes" type="text/javascript" src={`https://cdn-cookieyes.com/client_data/${cookieYesKey}/script.js`} />
    );
  }
  return null;
};

export const ScriptWrapper: FC<ScriptWrapperProps> = ({ children }) => {
  const juridikaConfig = useJuridikaConfig();
  const isAuthenticated = useSelector(accessTokenSelector);

  useEffect(() => {
    /**
     * The init of Datadog client logger creates a cookie '_dd_s'. They have an
     * open issue on '[GDPR] Offer option for non-cookie based session/client
     * ID identifiers https://github.com/DataDog/browser-sdk/issues/323
     * */

    const backendURLS = Object.keys(juridikaConfig.getAllVariables())
      .filter((key) => key.endsWith('_BASE_URL'))
      .map((key) => juridikaConfig.getString(key));

    const { GIT_SHA } = process.env;
    const isLocalhost = juridikaConfig.getString(HOST) === 'localhost';
    const isProduction = juridikaConfig.getString(HOST) === 'juridika.no';

    datadogRum.init({
      applicationId: juridikaConfig.getString(DATADOG_APP_ID),
      clientToken: juridikaConfig.getString(DATADOG_CLIENT_TOKEN),
      env: isProduction ? 'prod' : isLocalhost ? 'local' : 'test',
      service: 'juridika-frontend-browser',
      site: 'datadoghq.eu',
      version: GIT_SHA,
      sessionSampleRate: isProduction ? 10 : isLocalhost ? 0 : 100,
      sessionReplaySampleRate: isProduction ? 0 : 100,
      trackUserInteractions: !isProduction,
      trackResources: !isProduction,
      trackLongTasks: !isProduction,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: backendURLS, // Need to contain all our backend urls we use to enable datadog tracing
      startSessionReplayRecordingManually: isProduction,
    });
  }, [juridikaConfig]);

  return (
    <>
      <Helmet>
        {runHubspotScript()}
        {!isAuthenticated && runRudderstackScript()}
        {runCookieYesScript()}
      </Helmet>
      {children}
    </>
  );
};
