import { Auth0DecodedHash } from 'auth0-js';
import { AccessToken } from 'express-openid-connect';
import * as React from 'react';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';

export class Auth0 {
  private juridikaConfig: JuridikaConfig;

  constructor(juridikaConfig: JuridikaConfig) {
    this.juridikaConfig = juridikaConfig;
  }

  static fake(juridikaConfig: JuridikaConfig): Auth0 {
    return new Auth0(juridikaConfig);
  }

  static real(juridikaConfig: JuridikaConfig): Auth0 {
    return new Auth0(juridikaConfig);
  }

  changeLocationToAuth0Lock(options: { loginHint?: string; returnToHomepage?: boolean }): void {
    if (this.juridikaConfig.isClient) {
      const { loginHint, returnToHomepage } = options;
      const loginUrl = this.juridikaConfig.juridikaAbsoluteBaseUrl().segment('login');
      loginUrl.addQuery({
        ...(loginHint && { loginHint }),
        ...(returnToHomepage !== undefined && { returnToHomepage }),
      });
      window.location.replace(loginUrl.toString());
    }
  }

  async refreshAccessToken(): Promise<Auth0DecodedHash> {
    const response = await fetch(this.juridikaConfig.juridikaAbsoluteBaseUrl().segment('refreshToken').toString());
    const authResult: {
      accessToken: AccessToken | undefined;
      idToken: string | undefined;
    } = await response.json();
    if (authResult && authResult.accessToken && authResult.idToken) {
      const auth0DecodedHash = {
        accessToken: authResult.accessToken.access_token,
        idToken: authResult.idToken,
        expiresIn: authResult.accessToken.expires_in,
        tokenType: authResult.accessToken.token_type,
      };
      return Promise.resolve(auth0DecodedHash);
    }
    throw new Error('failed refreshing access token');
  }
}

export const Auth0Context = React.createContext(Auth0.fake(JuridikaConfig.testServerDebug({})));

export const useAuth0 = (): Auth0 => {
  return React.useContext(Auth0Context);
};
