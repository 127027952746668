import styled, { css } from 'styled-components';

export const OverlayCss = styled.div<{ $large: boolean }>`
  width: 100%;
  margin: 0 auto;

  ${({ $large }) =>
    $large &&
    css`
      margin: 0 0.94rem;
      width: auto;
    `}
`;

export const WrapperCss = styled.div<{ $show: boolean }>`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 97;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;

  display: ${({ $show }) => ($show ? 'block' : 'none')};
`;

export const LinkCss = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colorTokens.blue[600]};
  cursor: pointer;

  :hover,
  :focus-visible {
    opacity: 1;
    color: ${({ theme }) => theme.colorTokens.default.black};
  }
`;
