import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { listWithoutStyle } from '../../../../../../styleMixins/listMixins';
import LogInOrOutButtons from '../../../../../LogInOrOutButtons';
import { NAVBAR_VIEWPORT_SIZE } from '../../../../utils';

const OptionsListCss = styled.ul`
  ${listWithoutStyle}
`;

const OptionItemCss = styled.li`
  a,
  button {
    padding: 0.625rem;
    border-bottom: 1px solid transparent;

    :hover {
      opacity: 1;
      border-bottom: 1px solid ${({ theme }) => theme.colorTokens.default.white};
    }

    :focus-visible {
      box-shadow: 0 0 1px 1px ${({ theme }) => theme.colorTokens.default.white};
      border: none;
      border-bottom: 1px solid transparent;
    }

    ${createMediaQuery(
      NAVBAR_VIEWPORT_SIZE.medium,
      css`
        padding: 0;
        border-bottom: none;
        border-bottom: 2px solid transparent;

        :hover {
          span {
            color: ${({ theme }) => theme.colorTokens.default.black};
          }

          svg {
            fill: ${({ theme }) => theme.colorTokens.default.black};
          }
        }

        :focus-visible {
          box-shadow: none;
          border: none;
          border-bottom: 2px solid ${({ theme }) => theme.colorTokens.default.black};

          span {
            color: ${({ theme }) => theme.colorTokens.default.black};
          }

          svg {
            fill: ${({ theme }) => theme.colorTokens.default.black};
          }
        }
      `
    )}
  }
`;

const CurrentUserDropdownOptions: FC = () => {
  return (
    <OptionsListCss>
      <OptionItemCss>
        <LogInOrOutButtons />
      </OptionItemCss>
    </OptionsListCss>
  );
};

export default CurrentUserDropdownOptions;
