import * as React from 'react';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { useSelector } from 'util/hooks/useSelector';

export const useViewportInnerHeight = (): number => {
  if (useJuridikaConfig().isServer) return 0;

  const hasBeenHydrated = useSelector((state) => state.viewport.hasBeenHydrated);

  const [innerHeight, setInnerHeight] = React.useState<number>(window.innerHeight);

  React.useEffect(() => {
    if (!hasBeenHydrated) return;

    const updateViewportSize = () => setInnerHeight(window.innerHeight);
    window.addEventListener('resize', updateViewportSize);

    return () => window.removeEventListener('resize', updateViewportSize);
  }, [hasBeenHydrated]);

  return innerHeight;
};
