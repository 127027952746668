import styled, { css } from 'styled-components';
import Checkbox from 'components/Input/Checkbox';
import Cross from '../../../images/icon-cross.svg';
import Checked from '../../../images/icon-checked.svg';

export const TitleCss = styled.p`
  margin: 0;
  font-weight: 600;
`;

export const SwitchCss = styled.span`
  display: inline-flex;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  z-index: 0;
  vertical-align: middle;
  width: 3rem;
  height: 1.5625rem;
  padding: 0;
  margin: 0.5rem;
`;

export const SwitchBaseCss = styled.span<{ $isChecked: boolean }>`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: rgb(224, 224, 224);
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 1px;
  padding: 0;
  transform: translateX(0);

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      transform: translateX(23px);
    `}
`;

export const TrackCss = styled.span<{ $isChecked: boolean }>`
  height: 100%;
  width: 100%;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  border-radius: 1.5625rem;
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  border: 1px solid #bfafa6;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: ${({ theme }) => theme.colorTokens.brown[900]};
    `}
`;

export const ThumbCss = styled.span<{ $isChecked: boolean }>`
  background-color: currentColor;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  width: 1.4375rem;
  height: 1.4375rem;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${Cross});

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        background-image: url(${Checked});
      `}
  }
`;

export const InputCss = styled(Checkbox)`
  left: -100%;
  width: 300%;
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
`;
