import React, { FC } from 'react';
import { JsonMLElement } from 'models/JsonML';
import {
  HTML_ATTRIBUTES,
  HypertextAllocator,
  HypertextNode,
  TAGNAME,
} from 'util/hypertextAllocator';
import { ResourceLink } from '../../types';
import { ButtonOverrideAttributes, renderNode } from './RenderNode';
import { BodyCss } from './styles';

interface Props {
  jsonmlDocument: JsonMLElement | null;
  onClickResourceLink: (resourceLink: ResourceLink) => void;
  onHoverResourceLink: (legalUri: string, fragmentId: string) => void;
}

const Body: FC<Props> = ({
  jsonmlDocument,
  onHoverResourceLink,
  onClickResourceLink,
}) => {
  const hypertextAllocator = new HypertextAllocator();
  const hypertextNode = hypertextAllocator.jsonmlFragmentToNode(jsonmlDocument);

  const buttonOverrides = (
    node: HypertextNode
  ): ButtonOverrideAttributes | null => {
    switch (node[TAGNAME]) {
      case 'a': {
        const isResourceLink =
          node[HTML_ATTRIBUTES].href.split(/[:/]+/)[0] === 'internal';
        if (isResourceLink) {
          return {
            onClickResourceLink: (resourceLink) =>
              onClickResourceLink(resourceLink),
            onHoverResourceLink: (legalUri: string, fragmentId: string) =>
              onHoverResourceLink(legalUri, fragmentId),
          };
        }
        return null;
      }
      default:
        return null;
    }
  };

  return <BodyCss>{renderNode(hypertextNode, {}, buttonOverrides)}</BodyCss>;
};

export default Body;
