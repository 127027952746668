import Flex from 'components/ui/Flex';
import styled from 'styled-components';
import { PrimaryButtonPurple } from '../../../../components/AcademyAndNIK/Buttons/ButtonsPurple';

export const WrapperCss = styled(Flex)<{ $show: boolean }>`
  position: fixed;
  bottom: 2%;
  z-index: 200;
  width: 100%;
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
`;

export const ButtonCss = styled(PrimaryButtonPurple)`
  margin-right: 0.625rem;
`;
