import * as React from 'react';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

/**
 * A version of useEffect that does not complain on the server side.
 * Note that it is invoked synchronously on the server side.
 */
export const useIsomorphicEffect = (func: React.EffectCallback, deps?: React.DependencyList): void => {
  if (useJuridikaConfig().isServer) {
    func();
  } else {
    React.useEffect(func, deps);
  }
};
