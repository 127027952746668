export const possibleFragmentSegmentTypes =
  '§|%C2%A7|artikkel|kapittel|underkapittel|del|underdel|inndeling|tittel|innledning|konklusjon|hcontainer';

/**
 * Configuration for determining which fragment types can be used to uniquely identify its location in the document,
 * allowing for simplification of the URL pointing to the fragment
 */
export enum UniqueFragmentsProfile {
  NORWEGIAN_LAW = 'chapter|section|article',
  EU_LAW = 'section|article',
}

export function countryCodeToFragmentProfile(countryCode?: string): UniqueFragmentsProfile {
  return countryCode === 'eu' ? UniqueFragmentsProfile.EU_LAW : UniqueFragmentsProfile.NORWEGIAN_LAW;
}

const FRAGMENT_ELEMENT_TO_SEGMENT: Record<string, string> = {
  section: '§',
  article: 'artikkel',
  chapter: 'kapittel',
  subchapter: 'underkapittel',
  part: 'del',
  subpart: 'underdel',
  title: 'tittel',
  division: 'inndeling',
  preamble: 'innledning',
  body: '',
  conclusions: 'konklusjon',
  hcontainer: 'hcontainer',
};

// noinspection NonAsciiCharacters
const FRAGMENT_SEGMENT_TO_ELEMENT: Record<string, string> = {
  '§': 'section',
  artikkel: 'article',
  kapittel: 'chapter',
  underkapittel: 'subchapter',
  del: 'part',
  underdel: 'subpart',
  tittel: 'title',
  inndeling: 'division',
  innledning: 'preamble',
  konklusjon: 'conclusions',
  hcontainer: 'hcontainer',
};

function fragmentUrlSegmentsToFragmentId(segments: Array<{ type: string; num: string | undefined }>): string | undefined {
  if (segments.length === 0) return undefined;

  return segments.map((v) => (FRAGMENT_SEGMENT_TO_ELEMENT[v.type] || v.type) + (v.num ? `_${v.num}` : '')).join('__');
}

export function findFragmentIdFromUrlPath(
  segments: Array<{ type: string; num: string | undefined }>,
  allFragmentIds: string[]
): string | undefined {
  const constructedFragmentId = fragmentUrlSegmentsToFragmentId(segments);

  if (!constructedFragmentId) return undefined;

  // check that the fragmentId actually exists (with some leniency)
  const regexMatch = new RegExp(`(^|_)${constructedFragmentId}$`);

  for (const id of allFragmentIds) {
    if (regexMatch.test(id)) {
      return id;
    }
  }

  return undefined;
}

function convertFragmentIdPartToSegment(fragmentIdPart: string): string | undefined {
  const split = fragmentIdPart.split('_');

  const type = split[0];
  const convertedType = FRAGMENT_ELEMENT_TO_SEGMENT[type];

  if (split.length !== 2) return convertedType;

  if (!convertedType) return undefined;

  const num = split[1];
  const separator = type === 'section' ? '' : '_';
  return convertedType + separator + num;
}

export function convertFragmentIdToUrlPath(fragmentId: string, profile: UniqueFragmentsProfile): string | undefined {
  const topLevelFragment = FRAGMENT_ELEMENT_TO_SEGMENT[fragmentId];
  if (topLevelFragment !== undefined) {
    return `/${topLevelFragment}`;
  }

  // First, look for the last 'unique' fragment type, if present,
  // which number is expected to be unique among all numbers of the same type
  // (meaning we can ignore anything that appears before it)
  // These types are determined by the input UniqueFragmentsProfile.
  const uniquelyIdentifiedSubstringPattern = new RegExp(`.*(?:^|_)((?:${profile}).*)`);
  const uniquelyIdentifiedSubstring = uniquelyIdentifiedSubstringPattern.exec(fragmentId)?.[1];

  const simplifiedFragmentId = uniquelyIdentifiedSubstring || fragmentId;

  const segments = simplifiedFragmentId
    .split('__')
    .map((segment) => convertFragmentIdPartToSegment(segment))
    .filter((segment) => segment);

  if (!segments.length) return undefined;

  return `/${segments.join('/')}`;
}
