import React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const Minus: React.FC<IconPropTypes> = ({ width, height, color, ...props }) => (
  <Svg width={width} height={height} viewBox="0 0 12 12" {...props}>
    <rect width="12" height="2" y="5" fill={color} fillRule="evenodd" />
  </Svg>
);

Minus.defaultProps = {
  color: '#9289A1',
  width: 12,
  height: 12,
};

export default Minus;
