import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Helmet } from 'react-helmet-async';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { generatePageTitle } from 'util/pageTitleUtils';
import { FONT_FAMILIES } from 'theme/config/defaultTheme';
import createFontStyles from 'util/createFontStyles';
import createMediaQuery from 'util/createMediaQuery';
import PageviewStatisticsLogger from '../../containers/StatisticsLogger/PageviewStatisticsLogger';
import SocialMetaTags from '../../components/SocialMetaTags';
import ContactUsForm from './components/ContactUsForm';
import { Layout } from '../../containers/Layouts/Layout';
import ListOfProductsWithSalesPitch from './components/ListOfProductsWithSalesPitch';

const LAYOUT_CONFIG = {
  hasBeigeBackground: true,
  hasToolbar: false,
};

export const PATH_NAME_TRY_AKADEMI = '/prøv-akademi';

const PAGE_TITLE_TRY_JURIDIKA = 'Prøv Juridika';
const PAGE_TITLE_TRY_AKADEMI = 'Prøv Akademi';

const WrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 3rem 0 0 0;
  padding: 0;

  ${createMediaQuery(
    'lg',
    css`
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    `
  )}
`;

const TitleCss = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.colorTokens.default.black};

  ${createFontStyles({
    family: `${FONT_FAMILIES.primary}`,
    size: 2,
  })}

  ${createMediaQuery(
    'xs',
    css`
      font-size: 2.5rem;
    `
  )}

  strong {
    color: ${(props) => props.theme.colorTokens.red[600]};

    ${createFontStyles({
      letterSpacing: 0.0625,
    })}
  }
`;

const PitchSectionCss = styled.div`
  padding: 0 1.5rem;
  max-width: 37.5rem;

  h2.this-is-juridika-heading {
    margin: 0;
  }
`;

const FormSectionCss = styled.div`
  margin: 0 0 6rem 0;
`;

const RedParagraphTextCss = styled.p`
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 0;
  color: ${(props) => props.theme.colorTokens.red[600]};

  ${createFontStyles({
    family: `${FONT_FAMILIES.primary}`,
    size: 1,
  })}
`;

const AkademiPitchTextCss = styled.div`
  > h2 {
    margin-top: 1.5rem;
    font-weight: 700;
  }

  > p {
    font-size: 1.125rem;
  }
`;

const ContactUsFormPage: FC = () => {
  const location = useLocation<{ formCommentaryText: string }>();
  const juridikaConfig = useJuridikaConfig();
  let formCommentaryText = '';
  if (location.state?.formCommentaryText) {
    formCommentaryText = location.state.formCommentaryText;
  }

  const isTryAkademiPath = location.pathname === PATH_NAME_TRY_AKADEMI;
  const pageTitle = isTryAkademiPath ? PAGE_TITLE_TRY_AKADEMI : PAGE_TITLE_TRY_JURIDIKA;

  return (
    <Layout {...LAYOUT_CONFIG}>
      <Helmet title={generatePageTitle(pageTitle)} />
      <SocialMetaTags
        contentType="website"
        type="LANDING_PAGE"
        title={pageTitle}
        description={ReactDOMServer.renderToString(<p>{pageTitle}</p>).replace(/<(?!\/?>)[^>]*>/g, '')}
        shareImageUrl={null}
        publishedAt={null}
        url={juridikaConfig.juridikaAbsoluteBaseUrl().segment(location.pathname).toString()}
        articleTag={null}
        twitterHandleContributor={null}
      />
      <PageviewStatisticsLogger title={pageTitle} />

      <WrapperCss>
        <PitchSectionCss>
          {isTryAkademiPath ? (
            <AkademiPitchTextCss>
              <h2>Ta kontakt med oss for pris og tilgang!</h2>
              <p>
                Dette er et helt nytt tilleggsprodukt fra Juridika. Fyll ut kontaktskjemaet eller ring oss dersom du har spørsmål.
              </p>
              <p>
                Telefon Håvard Bjerkeng: <b>917 95 858</b>
              </p>
            </AkademiPitchTextCss>
          ) : (
            <>
              <TitleCss>
                <strong>Fyll ut skjema og vi kontakter deg</strong>
              </TitleCss>
              <RedParagraphTextCss>eller ring tlf. 24 14 75 00 (hverdager 9-15)</RedParagraphTextCss>
              <h2 className="this-is-juridika-heading">Dette er Juridika:</h2>
              <ListOfProductsWithSalesPitch />
              <RedParagraphTextCss>
                Studenter har tilgang til våre lovkommentarer gjennom Feide-innlogging på juridika.no.
              </RedParagraphTextCss>
            </>
          )}
        </PitchSectionCss>
        <FormSectionCss>
          <ContactUsForm formCommentaryText={formCommentaryText} />
        </FormSectionCss>
      </WrapperCss>
    </Layout>
  );
};

export default ContactUsFormPage;
