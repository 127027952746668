import styled, { css } from 'styled-components';

import { FONT_FAMILIES } from 'theme/config/defaultTheme';
import { ColorTokenValues } from 'theme/config/types';
import createFontStyles from 'util/createFontStyles';
import createMediaQuery from 'util/createMediaQuery';
import { createInputFieldBorder, createInputFieldBorderRadius } from './inputStylesHelper';
import { InputType, TextareaType } from './types';

export const FlexParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexChildLabelWrapper = styled.div`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colorTokens.default.black};

  ${createFontStyles({
    family: `${FONT_FAMILIES.secondary}`,
    size: 1,
    weight: 600,
    lineHeight: 1.5,
  })}

  ${createMediaQuery(
    'lg',
    css`
      margin-bottom: 0.3125rem;
    `
  )}
`;

export const FlexChildInputWrapper = styled.div<{
  hasIcon?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.hasIcon ? 'row' : 'column')};
  position: relative;
`;

export const RequiredSpan = styled.span`
  color: ${(props) => props.theme.colorTokens.red[600]};
`;

export const StyledInput = styled.input<Partial<InputType & { hasError?: boolean }>>`
  background-color: ${(props) =>
    props.darkBackground ? props.theme.colorTokens.purple[600] : props.theme.colorTokens.default.white};
  margin: 0;
  color: ${(props) => (props.darkBackground ? props.theme.colorTokens.default.white : 'inherit')};
  padding: ${(props) => (props.paddingConfig?.topBottom ? props.paddingConfig.topBottom : 0.5)}rem
    ${(props) => (props.paddingConfig?.leftRight ? props.paddingConfig.leftRight : 0.5)}rem;
  font-size: ${(props) => (props.isLargeInputField ? 1.25 : 1)}rem;
  width: ${(props) => (props.customWidth ? `${props.customWidth}rem` : '100%')};

  ${(props) => createInputFieldBorder(!!props.rightIcon, !!props.leftIcon, !!props.hasError, props.borderColorVariant)};

  ${(props) => props.hasBorderRadius && createInputFieldBorderRadius(!!props.rightIcon, !!props.leftIcon)};

  &::placeholder {
    color: ${(props) => (props.darkBackground ? props.theme.colorTokens.purpleVibrant[300] : props.theme.colorTokens.grey[700])};
  }
  &:active,
  &:focus {
    outline: none;
  }
`;

export const StyledTextArea = styled.textarea<Partial<TextareaType & { hasError?: boolean }>>`
  &:active,
  &:focus {
    outline: none;
  }

  ${(props) => props.hasBorderRadius && createInputFieldBorderRadius(!!props.rightIcon, !!props.leftIcon)};
  ${(props) => createInputFieldBorder(!!props.rightIcon, !!props.leftIcon, !!props.hasError, props.borderColorVariant)};
  padding: ${(props) => (props.paddingConfig?.topBottom ? props.paddingConfig.topBottom : 0.5)}rem
    ${(props) => (props.paddingConfig?.leftRight ? props.paddingConfig.leftRight : 0.5)}rem;

  resize: ${(props) => (props.isResizable ? 'initial' : 'none')};
  height: ${(props) => props.maxHeight}rem;
`;

export const ErrorMessage = styled.div`
  margin-top: 0.3125rem;
  color: ${(props) => props.theme.colorTokens.red[600]};
  display: flex;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  span {
    margin-left: 0.3125rem;
    ${createFontStyles({
      family: `${FONT_FAMILIES.secondary}`,
      size: 0.875,
      weight: 600,
    })}
  }
`;

export const InputIconButton = styled.button<{
  darkBackground?: boolean;
  iconButtonSvgConfig?: {
    width?: number;
    height?: number;
    fillColor?: ColorTokenValues;
  };
  paddingConfig?: {
    topBottom?: number;
    leftRight?: number;
  };
  hasError?: boolean;
  rightIcon?: boolean;
  leftIcon?: boolean;
  hasBorderRadius?: boolean;
  borderColorVariant?: 'primary' | 'secondary' | 'tertiary';
}>`
  background-color: ${(props) =>
    props.darkBackground ? props.theme.colorTokens.purple[600] : props.theme.colorTokens.default.white};
  outline: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.paddingConfig?.topBottom ? props.paddingConfig.topBottom : 0.5)}rem
    ${(props) => (props.paddingConfig?.leftRight ? props.paddingConfig.leftRight : 0.5)}rem;

  ${(props) => props.hasBorderRadius && createInputFieldBorderRadius(!!props.leftIcon, !!props.rightIcon)};

  ${(props) =>
    props.iconButtonSvgConfig &&
    css`
      svg {
        width: ${props.iconButtonSvgConfig.width}rem;
        height: ${props.iconButtonSvgConfig.width || 1}rem;
        fill: ${props.iconButtonSvgConfig.fillColor};
      }
    `};

  &,
  &:active {
    ${(props) => createInputFieldBorder(!!props.leftIcon, !!props.rightIcon, !!props.hasError, props.borderColorVariant)};
  }
`;
