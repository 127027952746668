import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as ReferrerActions from './referrerActions';
import { ReferrerState } from './types';

type ReferrerAction = ActionType<typeof ReferrerActions>;

export const referrerInitialState: ReferrerState = { path: '/' };

export const referrerReducers: Reducer<ReferrerState, ReferrerAction> = (state = referrerInitialState, action) => {
  switch (action.type) {
    case getType(ReferrerActions.setReferrer):
      return {
        ...state,
        path: action.payload,
      };
    default:
      return state;
  }
};
