import URI from 'urijs';

export interface MailToOptions {
  email?: string;
  subject?: string;
  body?: string;
}

export const SUPPORT_EMAIL = 'post@juridika.no';

export const mailToLink = ({ email = SUPPORT_EMAIL, subject, body }: MailToOptions = {}): string =>
  URI(`mailto:${email}`).escapeQuerySpace(false).setQuery({ subject, body }).toString();
