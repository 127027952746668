/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import URI from 'urijs';
import { contributorPath, getLiteratureLinkURI, getPortraitUrl } from 'util/literatureUtils';
import { legalDocumentLinkURI } from 'util/urlHelpers';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import actCover from '../../../images/search_commentary.png';
import {
  ContributorSearchResult,
  DocumentContributionEdge,
  GqlExprId,
  GqlSearchResponse,
  JournalArticleSearchResult,
  PublishedPageSearchResult,
  SearchHit,
  SearchPublishedPageContributionEdge,
  SearchResultEdge,
  SearchResultTypes,
  TextbookFragmentSearchResult,
  TextbookSearchResult,
  TextWithModifiers,
} from './interfaces';
import { FavoriteButtonProps } from '../../components/FavoriteButton';
import { getEditionCoverUrl } from '../../components/EditionCover';
import { getTemplateRelativeUrl } from '../../components/Template';

export type TYPE_FILTER = 'Type';
export type CONTRIBUTOR_FILTER = 'Forfatter';
export type DATE_FROM_FILTER = 'Dato fra';
export type DATE_TO_FILTER = 'Dato til';
export type FilterName = TYPE_FILTER | CONTRIBUTOR_FILTER | DATE_FROM_FILTER | DATE_TO_FILTER;
export const LAW_COMMENTARY_SCOPE = 'Lovkommentar';
export const TEXTBOOK_SCOPE = 'Fagbok';
export const JOURNAL_SCOPE = 'Tidsskriftartikkel';
export const NEWS_SCOPE = 'Innsikt';
export const CONTRACT_TEMPLATE_SCOPE = 'Mal';
export const CONTRIBUTOR_SCOPE = 'Forfatter';
export const NIK_DOCUMENT_SCOPE = 'Problemstilling';
export type TypeScopeName =
  | typeof JOURNAL_SCOPE
  | typeof TEXTBOOK_SCOPE
  | typeof LAW_COMMENTARY_SCOPE
  | typeof NEWS_SCOPE
  | typeof CONTRACT_TEMPLATE_SCOPE
  | typeof CONTRIBUTOR_SCOPE
  | typeof NIK_DOCUMENT_SCOPE;
export const typeFilters: TypeScopeName[] = [
  LAW_COMMENTARY_SCOPE,
  TEXTBOOK_SCOPE,
  NIK_DOCUMENT_SCOPE,
  JOURNAL_SCOPE,
  NEWS_SCOPE,
  CONTRACT_TEMPLATE_SCOPE,
  CONTRIBUTOR_SCOPE,
];

// We have been using the display names as scopes in the url. Helper function to keep old URLS functioning while changing the display names.
export const mapScopeNameToDisplayName = (scopeName: TypeScopeName): string => {
  switch (scopeName) {
    case JOURNAL_SCOPE:
      return 'Tidsskrift';
    case LAW_COMMENTARY_SCOPE:
      return 'Lov';
    default:
      return scopeName;
  }
};

export const mapTypenameToDisplayname = (typeName: string): string => {
  switch (typeName) {
    case SearchResultTypes.ACT_FRAGMENT:
    case SearchResultTypes.ACT:
      return 'Lov';
    case SearchResultTypes.TEXTBOOK:
    case SearchResultTypes.TEXTBOOK_FRAGMENTS:
      return TEXTBOOK_SCOPE;
    case SearchResultTypes.JOURNAL_ARTICLE:
      return JOURNAL_SCOPE;
    case SearchResultTypes.JOURNAL:
      return 'Tidsskrift';
    case SearchResultTypes.PUBLISHED_PAGE:
      return NEWS_SCOPE;
    case SearchResultTypes.PUBLISHED_CONTRACT:
      return CONTRACT_TEMPLATE_SCOPE;
    case SearchResultTypes.NIK_DOCUMENT:
      return NIK_DOCUMENT_SCOPE;
    case SearchResultTypes.CONTRIBUTORS:
      return CONTRIBUTOR_SCOPE;
    default:
      return '';
  }
};

export const getTypeFilterValuesFromScopeName = (scopeName: string): SearchResultTypes[] => {
  switch (scopeName) {
    case TEXTBOOK_SCOPE:
      return [SearchResultTypes.TEXTBOOK_FRAGMENTS, SearchResultTypes.TEXTBOOK];
    case LAW_COMMENTARY_SCOPE:
      return [SearchResultTypes.ACT_FRAGMENT, SearchResultTypes.ACT];
    case JOURNAL_SCOPE:
      return [SearchResultTypes.JOURNAL_ARTICLE, SearchResultTypes.JOURNAL];
    case NEWS_SCOPE:
      return [SearchResultTypes.PUBLISHED_PAGE];
    case CONTRACT_TEMPLATE_SCOPE:
      return [SearchResultTypes.PUBLISHED_CONTRACT];
    case CONTRIBUTOR_SCOPE:
      return [SearchResultTypes.CONTRIBUTORS];
    case NIK_DOCUMENT_SCOPE:
      return [SearchResultTypes.NIK_DOCUMENT];
    default:
      return [];
  }
};

export const defaultFilters = [
  SearchResultTypes.ACT_FRAGMENT,
  SearchResultTypes.ACT,
  SearchResultTypes.TEXTBOOK_FRAGMENTS,
  SearchResultTypes.TEXTBOOK,
  SearchResultTypes.JOURNAL_ARTICLE,
  SearchResultTypes.JOURNAL,
  SearchResultTypes.PUBLISHED_CONTRACT,
  SearchResultTypes.PUBLISHED_PAGE,
  SearchResultTypes.CONTRIBUTORS,
  SearchResultTypes.NIK_DOCUMENT,
];

const makeLawLinkFromHit = (exprId: GqlExprId, fragmentId: string | undefined, term: string) =>
  legalDocumentLinkURI(
    {
      countryCode: exprId.workId.countryCode,
      subtype: exprId.workId.type,
      date: exprId.workId.date,
      seq: exprId.workId.seq,
      fragmentId,
      shouldDisplayComment: true,
    },
    { q: term }
  ).toString();

const makeLiteratureDocLinkFromGqlDocument = (
  edge: TextbookSearchResult | TextbookFragmentSearchResult | JournalArticleSearchResult,
  term: string
) => {
  const isAccessible = isEdgeAccessible(edge);
  const getPublicationCategory = () => {
    switch (edge.node.__typename) {
      case SearchResultTypes.JOURNAL_ARTICLE:
        return ['tidsskrift'];
      default:
        return ['fagbok', 'juss'];
    }
  };
  const getDocumentCategory = () => {
    switch (edge.node.__typename) {
      case SearchResultTypes.JOURNAL_ARTICLE:
        return ['artikkel', 'juss'];
      default:
        return getPublicationCategory();
    }
  };

  const uri = getLiteratureLinkURI({
    ...(edge.node.__typename === SearchResultTypes.TEXTBOOK_FRAGMENTS && { term }),
    publication: {
      slug: edge.node.document.node!.edition.publication.slug,
      category: getPublicationCategory(),
    },
    edition: {
      path: edge.node.document.node!.edition.path,
    },
    document: {
      key: edge.node.document.node!.key,
      category: getDocumentCategory(),
    },
    accessible: isAccessible,
  });

  if (edge.node.__typename === SearchResultTypes.TEXTBOOK_FRAGMENTS && isAccessible && edge.node.fragmentId) {
    uri.hash(edge.node.fragmentId);
  }
  return uri.toString();
};

const generateJournalLink = (journalPath: string) => URI('/').segment('tidsskrifter').segment(journalPath).toString();

const generateInnsiktLink = (innsiktPath: string) => URI('/').segment('innsikt').segment(innsiktPath).toString();

export const generateNikDocumentLink = (documentPath: string) =>
  URI('/').segment('problemstilling').segment(documentPath).toString();

const generateContributorsString = (contributors: DocumentContributionEdge[] | SearchPublishedPageContributionEdge[]) => {
  const authorsFormatted: string = contributors
    .filter((edge) => edge.node !== null)
    .map((contributor, i) => {
      let name = contributor.node!.names.join(' ');
      if (contributor.role === 'editor') name += ' (red.)';
      if (i === contributors.length - 1) {
        return `${name}`;
      }
      if (i === contributors.length - 2) {
        return `${name} og `;
      }
      return `${name}, `;
    })
    .join('');
  return authorsFormatted.length === 0 ? '' : `Av ${authorsFormatted}`;
};

const combineAuthorStrings = (authors: string[] | null): string => {
  if (!authors) return '';
  return authors
    .map((name, i) => {
      if (i === authors.length - 1) {
        return `${name}`;
      }
      if (i === authors.length - 2) {
        return `${name} og `;
      }
      return `${name}, `;
    })
    .join('');
};

const generateAuthorsActCommentString = (authors: string[] | null): string => {
  const combined = combineAuthorStrings(authors);
  return combined.length === 0 ? '' : `Kommentar av ${combined}`;
};

const makeLiteratureDocumentSubtitle = (
  edge: TextbookSearchResult | TextbookFragmentSearchResult | JournalArticleSearchResult
): TextWithModifiers[] => {
  const { node } = edge.node.document;
  if (edge.node.__typename === SearchResultTypes.JOURNAL_ARTICLE) {
    return [
      { text: node!.edition.publication.titles.join(', ') },
      { text: node!.edition.path.join('/') },
      { text: generateContributorsString(node!.contributions.edges) },
    ];
  }
  if (edge.node.__typename === SearchResultTypes.TEXTBOOK_FRAGMENTS) {
    return [
      { text: node!.edition.originallyPublishedAt.substring(0, 4) },
      { text: generateContributorsString(node!.contributions.edges) },
      { text: edge.node.title[0] },
    ];
  }
  if (edge.node.__typename === SearchResultTypes.TEXTBOOK) {
    const isCompletedTextbook = node?.edition.availability === 'COMPLETE';
    return [
      {
        text: isCompletedTextbook ? node!.edition.originallyPublishedAt.substring(0, 4) : 'Kommende utgivelse',
        ...(!isCompletedTextbook && { color: COLOR_TOKENS.red[700] }),
      },
      { text: generateContributorsString(node!.contributions.edges) },
    ];
  }
  return [];
};

const generatePublishedPageSubtitle = (edge: PublishedPageSearchResult): TextWithModifiers[] => {
  const { node } = edge.node.publishedPage;
  if (node) {
    return [{ text: node.firstPublishedAt.substring(0, 10) }, { text: generateContributorsString(node.contributions.edges) }];
  }
  return [];
};

const generateContributorsSubtitle = (edge: ContributorSearchResult): TextWithModifiers[] => {
  const { node } = edge.node.contributor;
  if (node) {
    const occupation = node.occupation ? node.occupation.plainText : '';
    const degree = node.degree ? node.degree.plainText : '';
    return [{ text: `${occupation} ${degree}` }];
  }
  return [];
};

const makeSubtitleFromSearchResultEdge = (edge: SearchResultEdge): TextWithModifiers[] => {
  switch (edge.node.__typename) {
    case SearchResultTypes.ACT_FRAGMENT:
      return [{ text: edge.node.actFullTitle[0] }, { text: generateAuthorsActCommentString(edge.node.authors) }];
    case SearchResultTypes.ACT:
      return [{ text: edge.node.actFullTitle[0] }];
    case SearchResultTypes.JOURNAL_ARTICLE:
    case SearchResultTypes.TEXTBOOK_FRAGMENTS:
    case SearchResultTypes.TEXTBOOK:
      return makeLiteratureDocumentSubtitle(
        edge as TextbookSearchResult | TextbookFragmentSearchResult | JournalArticleSearchResult
      );
    case SearchResultTypes.PUBLISHED_PAGE:
      return generatePublishedPageSubtitle(edge as PublishedPageSearchResult);
    case SearchResultTypes.PUBLISHED_CONTRACT:
    case SearchResultTypes.NIK_DOCUMENT:
      return [{ text: combineAuthorStrings(edge.node.authors) }];
    case SearchResultTypes.CONTRIBUTORS:
      return generateContributorsSubtitle(edge as ContributorSearchResult);
    default:
      return [];
  }
};

const makeTitleFromSearchResultEdge = (edge: SearchResultEdge): string => {
  switch (edge.node.__typename) {
    case SearchResultTypes.TEXTBOOK_FRAGMENTS:
      return edge.node.textbookTitle.join(' : ');
    case SearchResultTypes.ACT_FRAGMENT:
      return `${edge.node.actTitle} / ${edge.node.title}`;
    case SearchResultTypes.JOURNAL_ARTICLE:
      return edge.node.title.join(' – ').replace('– –', '–');
    default:
      return edge.node.title.join(' : ');
  }
};

const makePathTitleFromSearchResultEdge = (edge: SearchResultEdge): string | null => {
  switch (edge.node.__typename) {
    case SearchResultTypes.ACT_FRAGMENT:
      return edge.node.parentTitle ? `${edge.node.parentTitle} / ${edge.node.title[0]}` : edge.node.title[0];
    default:
      return null;
  }
};

const forwardHighlightsForFragments = (edge: SearchResultEdge) => {
  switch (edge.node.__typename) {
    case SearchResultTypes.TEXTBOOK:
    case SearchResultTypes.ACT:
    case SearchResultTypes.JOURNAL:
      return [];
    default:
      return edge.node.highlightedContent;
  }
};

const makeDocLinkFromSearchResultEdge = (edge: SearchResultEdge, term: string) => {
  switch (edge.node.__typename) {
    case SearchResultTypes.ACT_FRAGMENT:
      return makeLawLinkFromHit(edge.node.exprId, edge.node.fragmentId, term);
    case SearchResultTypes.ACT:
      return makeLawLinkFromHit(edge.node.exprId, undefined, term);
    case SearchResultTypes.JOURNAL_ARTICLE:
    case SearchResultTypes.TEXTBOOK:
    case SearchResultTypes.TEXTBOOK_FRAGMENTS:
      return makeLiteratureDocLinkFromGqlDocument(
        edge as TextbookSearchResult | TextbookFragmentSearchResult | JournalArticleSearchResult,
        term
      );
    case SearchResultTypes.JOURNAL:
      return generateJournalLink(edge.node.documentPath);
    case SearchResultTypes.PUBLISHED_PAGE:
      return generateInnsiktLink(edge.node.documentPath);
    case SearchResultTypes.PUBLISHED_CONTRACT:
      return getTemplateRelativeUrl(edge.node.documentPath);
    case SearchResultTypes.CONTRIBUTORS: {
      const contributorNode = edge.node.contributor.node;
      if (contributorNode) {
        return contributorPath({ slug: contributorNode.slug });
      }
      return null;
    }
    case SearchResultTypes.NIK_DOCUMENT: {
      return generateNikDocumentLink(edge.node.documentPath);
    }
    default:
      return null;
  }
};

const isEdgeAccessible = (edge: SearchResultEdge) => {
  switch (edge.node.__typename) {
    case SearchResultTypes.ACT_FRAGMENT:
    case SearchResultTypes.ACT:
      return edge.node.accessible;
    case SearchResultTypes.JOURNAL_ARTICLE:
    case SearchResultTypes.TEXTBOOK:
    case SearchResultTypes.TEXTBOOK_FRAGMENTS:
      return edge.node.document.node!.accessible;
    case SearchResultTypes.JOURNAL:
      return edge.node.publication.node!.publicationIsAccessible;
    case SearchResultTypes.PUBLISHED_CONTRACT:
    case SearchResultTypes.NIK_DOCUMENT:
      return edge.node.accessible;
    default:
      return true;
  }
};

const assertNodesDefined = (edge: SearchResultEdge) => {
  switch (edge.node.__typename) {
    case SearchResultTypes.JOURNAL_ARTICLE:
    case SearchResultTypes.TEXTBOOK:
    case SearchResultTypes.TEXTBOOK_FRAGMENTS:
      return edge.node.document.node !== null;
    case SearchResultTypes.JOURNAL:
      return edge.node.publication.node !== null;
    case SearchResultTypes.PUBLISHED_PAGE:
      return edge.node.publishedPage.node !== null;
    default:
      return true;
  }
};

const getDocumentImageUrl = (edge: SearchResultEdge, juridikaConfig: JuridikaConfig): string | null => {
  switch (edge.node.__typename) {
    case SearchResultTypes.TEXTBOOK: {
      const documentNode = edge.node.document.node;
      if (documentNode?.edition.hasCover) {
        return getEditionCoverUrl(juridikaConfig, documentNode.edition, 100);
      }
      return null;
    }
    case SearchResultTypes.JOURNAL: {
      const publicationNode = edge.node.publication.node;
      if (publicationNode && publicationNode.editions.edges.length > 0) {
        const latestEditionNode = publicationNode.editions.edges[0].node;
        if (latestEditionNode) {
          return getEditionCoverUrl(juridikaConfig, latestEditionNode, 100);
        }
      }
      return null;
    }
    case SearchResultTypes.CONTRIBUTORS: {
      const contributorNode = edge.node.contributor.node;
      if (contributorNode) {
        return getPortraitUrl(
          juridikaConfig,
          {
            id: contributorNode.id,
            hasPortrait: contributorNode.hasPortrait,
          },
          200
        );
      }
      return null;
    }
    case SearchResultTypes.ACT: {
      if (edge.node.hasComment) {
        return actCover;
      }
    }
    default:
      return null;
  }
};

const constructFavoriteButtonProps = (edge: SearchResultEdge): FavoriteButtonProps | null => {
  switch (edge.node.__typename) {
    case SearchResultTypes.ACT: {
      const favorite = edge.node.favoriteLegalDocument;
      if (favorite?.userMetadata) {
        return {
          isFavorite: !!favorite.userMetadata.favoriteCreatedAt,
          mutationVariables: { id: favorite.id },
          graphqlResource: favorite,
          collection: 'FavoriteActWork',
        };
      }
      return null;
    }
    case SearchResultTypes.TEXTBOOK:
    case SearchResultTypes.JOURNAL_ARTICLE: {
      const documentNode = edge.node.document.node;
      if (documentNode?.userMetadata) {
        return {
          isFavorite: !!documentNode.userMetadata.favoriteCreatedAt,
          mutationVariables: { id: documentNode.id },
          graphqlResource: documentNode,
          collection: 'FavoriteLiteratureDocument',
        };
      }
      return null;
    }
    case SearchResultTypes.PUBLISHED_PAGE: {
      const publishedPageNode = edge.node.publishedPage.node;
      if (publishedPageNode?.userMetadata) {
        return {
          isFavorite: !!publishedPageNode.userMetadata.favoriteCreatedAt,
          mutationVariables: { id: publishedPageNode.id },
          graphqlResource: publishedPageNode,
          collection: 'FavoritePublishedPage',
        };
      }
      return null;
    }
    default:
      return null;
  }
};

export const mapGqlResultToSearchHits = (data: GqlSearchResponse, term: string, juridikaConfig: JuridikaConfig): SearchHit[] => {
  const { edges } = data.search;
  return edges
    .filter((edge) => assertNodesDefined(edge) && Object.values(SearchResultTypes).includes(edge.node.__typename))
    .map((edge: SearchResultEdge) => ({
      title: makeTitleFromSearchResultEdge(edge).trim(),
      subtitle: makeSubtitleFromSearchResultEdge(edge).filter((part) => part.text !== ''),
      highlights: forwardHighlightsForFragments(edge),
      typeDisplayName: mapTypenameToDisplayname(edge.node.__typename),
      id: edge.node.documentPath,
      linkPath: makeDocLinkFromSearchResultEdge(edge, term),
      accessible: isEdgeAccessible(edge),
      pathTitle: makePathTitleFromSearchResultEdge(edge), // "Sub subtitle" Better name?
      favoriteButtonProps: constructFavoriteButtonProps(edge),
      coverUrl: getDocumentImageUrl(edge, juridikaConfig),
    }));
};
