import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

export interface SocialMetaTagsProps {
  articleTag: string | null;
  contentType: 'article' | 'website';
  type: 'PUBLISHED_PAGE' | 'JOURNAL_ARTICLE' | 'AUTHOR' | 'TEXTBOOK' | 'LANDING_PAGE';
  title: string;
  description: string;
  shareImageUrl: string | null;
  url: string;
  publishedAt: Date | null;
  twitterHandleContributor: string | null;
}

const SocialMetaTags: React.FC<SocialMetaTagsProps> = ({
  articleTag,
  contentType,
  title,
  type,
  description,
  shareImageUrl,
  url,
  publishedAt,
  twitterHandleContributor,
}) => {
  const juridikaConfig = useJuridikaConfig();

  const isNewsArticle = type === 'PUBLISHED_PAGE';
  const isJournalArticle = type === 'JOURNAL_ARTICLE';

  return (
    <Helmet>
      <meta name="description" content={description} />

      {/* Google+ */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />

      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@JuridikaUF" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {twitterHandleContributor && <meta name="twitter:creator" content={twitterHandleContributor} />}

      {/* Facebook */}
      <meta property="fb:app_id" content={juridikaConfig.FACEBOOK_APP_ID} />

      {/* Open Graph data */}
      <meta property="og:locale" content="no_NO" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={contentType} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Juridika" />
      {(isNewsArticle || isJournalArticle) && publishedAt && (
        <meta property="article:published_time" content={publishedAt.toISOString()} />
      )}
      {isNewsArticle && <meta property="article:section" content="Nyheter" />}
      {(isNewsArticle || isJournalArticle) && articleTag && <meta property="article:tag" content={articleTag} />}

      {/* Image url tags */}
      {shareImageUrl && [
        // Google+
        <meta key="googlePlusImageUrl" itemProp="image" content={shareImageUrl} />,
        // Twitter
        <meta key="twitterImageUrl" name="twitter:image" content={shareImageUrl} />,
        // Open Graph/Facebook
        <meta key="openGraphImageUrl" property="og:image" content={shareImageUrl} />,
      ]}
    </Helmet>
  );
};

export default SocialMetaTags;
