import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useViewportSize } from 'util/hooks/useViewportSize';
import MagnifyingGlass from 'components/icons/MagnifyingGlass';
import { useSelector } from 'util/hooks/useSelector';
import { AuthenticationStatus } from 'state/login/types';
import Menu from 'components/icons/Menu';
import Close from 'components/icons/Close';
import { capitalizeFirstLetter } from 'util/textUtils';
import NavbarLogo from './components/NavbarLogo/NavbarLogo';
import SmallViewportMenu from './components/SmallViewportMenu/SmallViewportMenu';
import FavoritesDropdown from './components/NavbarDropdown/FavoritesDropdown';
import CurrentUserDropdown from './components/NavbarDropdown/CurrentUserDropdown';
import CategoryPageNavigation from './components/CategoryPageNavigation/CategoryPageNavigation';
import { CategoryPage, categoryPages } from './components/CategoryPageNavigation/categoryPages';
import { NAVBAR_VIEWPORT_SIZE, NavigationType, isNavLinkActive } from '../../utils';
import { NavLinkListItemCss, NavLinkCss } from './components/CategoryPageNavigation/Styles';
import {
  NavbarCss,
  ActionsListCss,
  ActionItemCss,
  SearchButtonItemCss,
  MenyButtonItemCss,
  LogInOrOutButtonsCss,
  SecondNavbarCss,
  CloseButtonCss,
} from './Styles';
import { useClickOutsideButtonAndElementHook } from './useClickOutsideButtonAndElementHook';

interface NavbarProps {
  setDisplayGlobalSearch: Dispatch<SetStateAction<boolean>>;
  hasBeigeBackground: boolean;
  displayGlobalSearch: boolean;
}

const Navbar: FC<NavbarProps> = ({ setDisplayGlobalSearch, hasBeigeBackground, displayGlobalSearch }) => {
  const isIpLogin = useSelector((state) => state.session.tokenSource) === 'IP';
  const { authStatus } = useSelector((state) => state.login);
  const [displaySmallViewportMenu, setDisplaySmallViewportMenu] = useState(false);
  const [showSecondNavbar, setShowSecondNavbar] = useState(false);
  const [listOfHiddenMenuItems, setListOfHiddenMenuItems] = useState<CategoryPage[]>([]);
  const [menuItems, setMenuItems] = useState<CategoryPage[]>([]);

  const viewportSize = useViewportSize(NAVBAR_VIEWPORT_SIZE, 'small');
  const isAuthenticated = authStatus === AuthenticationStatus.AUTHENTICATED;
  const isMediumViewport = viewportSize !== 'small';
  const isLargeViewport = viewportSize === 'large' || viewportSize === 'xlarge';
  const handleDisplayGlobalSearch = () => setDisplayGlobalSearch((prev) => !prev);
  const handleCloseSecondNavbar = () => setShowSecondNavbar(false);

  const secondNavbarRef = useRef<HTMLElement>(null);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const namesOfMenuItems = useMemo(() => listOfHiddenMenuItems.map((item) => item.name.toLowerCase()), [listOfHiddenMenuItems]);

  // Lukk 'SecondNavbar' om brukeren trykker (mouse click/enter) utenfor navigasjonsmenyen eller 'mer'-knappen
  useClickOutsideButtonAndElementHook(secondNavbarRef, moreButtonRef, setShowSecondNavbar);

  useEffect(() => {
    const accessibleMenuItems = categoryPages.filter((a, _) => {
      if (authStatus === AuthenticationStatus.AUTHENTICATED) {
        return !a.excludeIfAuthenticated;
      }
      return !a.excludeIfUnauthenticated;
    });

    if (viewportSize === 'small') {
      setMenuItems(accessibleMenuItems);
    } else {
      // Vi kutter ut menypunktet "Forsiden" (index 0) på større skjermer
      const menuItemsWithoutFirstIndex = accessibleMenuItems.slice(1);
      setMenuItems(menuItemsWithoutFirstIndex);
    }
  }, [authStatus, viewportSize]);

  return (
    <>
      <NavbarCss>
        <NavbarLogo />
        {isMediumViewport && (
          <CategoryPageNavigation
            listOfHiddenMenuItems={listOfHiddenMenuItems}
            setListOfHiddenMenuItems={setListOfHiddenMenuItems}
            navType={NavigationType.desktopNavbar}
            showSecondNavbar={showSecondNavbar}
            toggleSecondNavbar={setShowSecondNavbar}
            moreButtonRef={moreButtonRef}
            menuItems={menuItems}
          />
        )}
        <ActionsListCss>
          {isAuthenticated && !isIpLogin && (
            <ActionItemCss>
              <FavoritesDropdown hasBeigeBackground={hasBeigeBackground} globalSearchSectionIsOpen={displayGlobalSearch} />
            </ActionItemCss>
          )}
          <ActionItemCss>
            <SearchButtonItemCss onClick={handleDisplayGlobalSearch} aria-label="Søk">
              <MagnifyingGlass color={COLOR_TOKENS.red[600]} />
              {isLargeViewport && <span>Søk</span>}
            </SearchButtonItemCss>
          </ActionItemCss>
          {!isMediumViewport && (
            <ActionItemCss>
              <MenyButtonItemCss onClick={() => setDisplaySmallViewportMenu((prev) => !prev)}>
                <Menu color={COLOR_TOKENS.red[600]} />
                <span>MENY</span>
              </MenyButtonItemCss>
            </ActionItemCss>
          )}
          {isMediumViewport && (
            <ActionItemCss>
              {isAuthenticated ? <CurrentUserDropdown hasBeigeBackground={hasBeigeBackground} /> : <LogInOrOutButtonsCss />}
            </ActionItemCss>
          )}
        </ActionsListCss>
        {!isMediumViewport && displaySmallViewportMenu && (
          <SmallViewportMenu
            setOpen={() => setDisplaySmallViewportMenu((prev) => !prev)}
            open={displaySmallViewportMenu}
            menuItems={menuItems}
          />
        )}
      </NavbarCss>
      {isMediumViewport && showSecondNavbar && listOfHiddenMenuItems.length > 0 && (
        <SecondNavbarCss $hasBeigeBackground={!hasBeigeBackground} ref={secondNavbarRef}>
          <ul>
            {listOfHiddenMenuItems.map(({ name, path }: CategoryPage) => (
              <NavLinkListItemCss key={name}>
                <NavLinkCss
                  $navType={NavigationType.desktopNavbar}
                  activeClassName="isActive"
                  to={path}
                  isActive={(a, b) => isNavLinkActive(a, b, namesOfMenuItems)}
                >
                  {capitalizeFirstLetter(name)}
                </NavLinkCss>
              </NavLinkListItemCss>
            ))}
          </ul>
          <CloseButtonCss onClick={handleCloseSecondNavbar} aria-label="Lukk ytterligere navigasjon">
            <Close size={20} color={COLOR_TOKENS.grey[900]} />
          </CloseButtonCss>
        </SecondNavbarCss>
      )}
    </>
  );
};

export default Navbar;
