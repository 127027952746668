import * as React from 'react';

import { capitalizeFirstLetter } from 'util/textUtils';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import FavoritesFeed, { FavoriteCategory, FAVORITE_CATEGORIES } from '../../../components/Favorites/FavoritesFeed';
import Tag from '../../../components/Tag/Tag';
import { FilterListCss, FilterListItemCss } from './ComponentCss';

const FavoritesSection: React.FC<{
  initialLoadCount: number;
  displayMoreInterval?: number;
  onTruncate?(): void;
  displayLoadMoreButtons?: boolean;
  redFooterIconsOnHover?: boolean;
  iconLeft?: boolean;
}> = (props) => {
  const [selectedCategory, setSelectedCategory] = React.useState<FavoriteCategory>(FAVORITE_CATEGORIES[0]);

  return (
    <>
      <FilterListCss>
        {FAVORITE_CATEGORIES.map((category) => (
          <FilterListItemCss key={category}>
            <Tag
              backgroundColor={COLOR_TOKENS.brown[200]}
              isSelected={category === selectedCategory}
              onClick={() => setSelectedCategory(category)}
            >
              {capitalizeFirstLetter(category)}
            </Tag>
          </FilterListItemCss>
        ))}
      </FilterListCss>
      <FavoritesFeed
        favoriteCategory={selectedCategory}
        displayGoToFavoritesBtn
        buttonConfig={{
          $color: COLOR_TOKENS.red[600],
        }}
        {...props}
      />
    </>
  );
};

export default FavoritesSection;
