import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from './IconPropTypes';

const Filter: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.668 3.83203H12.332"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.332 3.83203H2.33203"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33203 12.168H9.66796"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.668 12.168H14.668"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33203 8H4.66797"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66797 8H14.668"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.332 5.5C10.7812 5.5 10.332 5.05078 10.332 4.5V3.16797C10.332 2.61328 10.7812 2.16797 11.332 2.16797C11.8867 2.16797 12.332 2.61328 12.332 3.16797V4.5C12.332 5.05078 11.8867 5.5 11.332 5.5Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.668 13.832C10.1133 13.832 9.66797 13.3867 9.66797 12.832V11.5C9.66797 10.9492 10.1133 10.5 10.668 10.5C11.2187 10.5 11.668 10.9492 11.668 11.5V12.832C11.668 13.3867 11.2187 13.832 10.668 13.832Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66797 9.66797C5.11328 9.66797 4.66797 9.21875 4.66797 8.66797V7.33203C4.66797 6.78125 5.11328 6.33203 5.66797 6.33203C6.21875 6.33203 6.66797 6.78125 6.66797 7.33203V8.66797C6.66797 9.21875 6.21875 9.66797 5.66797 9.66797Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

Filter.defaultProps = {
  color: '#171718',
};

export default Filter;
