import Flex from 'components/ui/Flex';
import styled, { css } from 'styled-components';
import createFontStyles from 'util/createFontStyles';

export const ColumnSection = styled.div<{ hasTopBorder?: boolean; juridikaBeta?: boolean }>`
  margin: 0 0 2.5rem 0;
  width: 100%;
  border-top: ${({ hasTopBorder, theme }) => hasTopBorder && `0.125rem solid ${theme.colorTokens.grey[900]}`};

  ${({ juridikaBeta }) => juridikaBeta && `margin-bottom: 0;`}
`;

export const ColumnSectionHeading = styled.h2<{ juridikaBeta?: boolean }>`
  margin: 0;
  padding: 8px 0;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colorTokens.grey[900]};
  text-transform: uppercase;

  ${({ theme }) =>
    createFontStyles({
      family: theme.fontFamilies.primary,
      size: 1,
      weight: 500,
      letterSpacing: 0.08,
    })}

  ${({ juridikaBeta }) =>
    juridikaBeta &&
    css`
      padding-top: 0;
      border-bottom: none;
    `}
`;

export const PersonalizedColumnSectionHeadingCss = styled(Flex)`
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colorTokens.brown[800]};
`;

export const ColumnSectionSpacingWrapper = styled.div<{ hasBottomBorder?: boolean }>`
  padding: 1.5rem 0;

  border-bottom: ${({ hasBottomBorder, theme }) => hasBottomBorder && `0.0625rem solid ${theme.colorTokens.brown[400]}`};
`;

export const ColumnSectionFooter = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
`;

export const ColumnSectionFooterLeft = styled.div`
  margin-right: auto;
`;

export const ColumnSectionFooterRight = styled.div`
  margin-left: auto;
`;

export const ColumnSectionFooterCenter = styled.div`
  margin: auto;
`;
