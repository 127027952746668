import React from 'react';
import { Chapter, Module } from '../types';

export interface CourseContent {
  modules: Module[];
  chapters: Chapter[];
}

export const CourseContentContext = React.createContext<CourseContent>({
  chapters: [],
  modules: [],
});

export const getModulesInChapter = (context: CourseContent, chapterId: string): Module[] => {
  return context.modules.filter((module) => module.inChapter === chapterId);
};
