import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { AcademyFrontPageTestimonial } from 'pages/AcademyPage/types';
import createMediaQuery from 'util/createMediaQuery';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { AcademyFrontPageIconButtonCss } from './AcademyFrontPageContentStyles';
import {
  academyFrontPageTestimonialsNextButtonArrowLeftIconSvg,
  academyFrontPageTestimonialsPreviousButtonArrowRightIconSvg,
  academyFrontPageQuoteIconSvg,
} from './FrontPageSvgs';

interface Props {
  testimonials: AcademyFrontPageTestimonial[];
}

const AcademyTestimonials: FC<Props> = ({ testimonials }) => {
  const [currentTestimonialIndex, setCurrentTestimonialsIndex] = useState(0);

  const handleShowPreviousTestimonial = () => {
    if (currentTestimonialIndex > 0) {
      setCurrentTestimonialsIndex((currentIndex) => currentIndex - 1);
    }
  };

  const handleShowNextTestimonial = () => {
    if (currentTestimonialIndex < testimonials.length - 1) {
      setCurrentTestimonialsIndex((currentIndex) => currentIndex + 1);
    }
  };

  const isMoreThanOneTestimonial = testimonials.length > 1;

  return (
    <TestimonialsContainerCss>
      {isMoreThanOneTestimonial ? (
        <AcademyFrontPageIconButtonCss onClick={handleShowPreviousTestimonial} aria-label="Vis forrige sitat">
          {academyFrontPageTestimonialsNextButtonArrowLeftIconSvg}
        </AcademyFrontPageIconButtonCss>
      ) : (
        <div />
      )}

      <div className="content-between-arrows-container">
        <div className="quote-icon-container">{academyFrontPageQuoteIconSvg}</div>

        <p className="testimonial-text">{testimonials[currentTestimonialIndex].text}</p>
        <p className="testimonial-signature">{testimonials[currentTestimonialIndex].signature}</p>

        {isMoreThanOneTestimonial && (
          <div className="selection-buttons-container">
            {testimonials.map((_, index) => (
              <SmallRoundTestimonialSelectionButtonCss
                key={index}
                onClick={() => setCurrentTestimonialsIndex(index)}
                aria-label={`Vis sitat nummer ${index + 1}`}
                isCurrentTestimonial={index === currentTestimonialIndex}
              />
            ))}
          </div>
        )}
      </div>

      {isMoreThanOneTestimonial ? (
        <AcademyFrontPageIconButtonCss onClick={handleShowNextTestimonial} aria-label="Vis neste sitat">
          {academyFrontPageTestimonialsPreviousButtonArrowRightIconSvg}
        </AcademyFrontPageIconButtonCss>
      ) : (
        <div />
      )}
    </TestimonialsContainerCss>
  );
};

export default AcademyTestimonials;

const TestimonialsContainerCss = styled.div`
  width: 100%;
  max-width: 680px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.medium,
    css`
      gap: 16px;
    `
  )}

  > .content-between-arrows-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .quote-icon-container {
      color: ${({ theme }) => theme.academy.frontPage.colors.primary};
      margin: 8px 0;
    }

    > p.testimonial-text {
      margin: 0 0 10px;
      font-family: Tuna;
      font-size: 1.375rem;
      font-style: italic;
      font-weight: 300;
      text-align: center;
      line-height: 30px;
    }

    > p.testimonial-signature {
      margin: 0;
      color: ${({ theme }) => theme.academy.frontPage.colors.primary};
      font-size: 1.125rem;
      text-align: center;
      line-height: normal;
    }

    > .selection-buttons-container {
      display: flex;
      gap: 16px;
    }
  }
`;

const SmallRoundTestimonialSelectionButtonCss = styled.button<{ isCurrentTestimonial: boolean }>`
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ theme, isCurrentTestimonial }) =>
    isCurrentTestimonial ? theme.academy.frontPage.colors.primary : theme.academy.frontPage.colors.secondary};

  :hover {
    background-color: ${({ theme }) => theme.academy.frontPage.colors.primary};
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.academy.frontPage.colors.primary};
    border: none;
  }
`;
