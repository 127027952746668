import URI from 'urijs';

import { JuridikaConfig, PORT } from 'commonUtils/juridikaConfig';
import * as serviceConstants from 'commonUtils/serviceConstants';

type Service = keyof typeof serviceConstants;

const unknownHost = (service: Service): string => {
  throw new Error(`Unknown host for ${service}`);
};

const getBaseUrl = (config: JuridikaConfig, service: Service): string | undefined => config.get(`${service}_BASE_URL`);

const getBaseUrlForServer = (config: JuridikaConfig, service: Service): string | undefined =>
  config.get(`${service}_BASE_URL_SERVER`);

export const getPublicApiUrl = (config: JuridikaConfig, service: Service): URI => {
  return URI(getBaseUrl(config, service) || unknownHost(service));
};

export const getIsomorphicApiUrl = (config: JuridikaConfig, service: Service): URI => {
  if (service === serviceConstants.JURIDIKA_FRONTEND) return getFrontendApiUrl(config);

  return URI((config.isServer && getBaseUrlForServer(config, service)) || getBaseUrl(config, service) || unknownHost(service));
};

export const getFrontendApiUrl = (config: JuridikaConfig): URI =>
  URI(config.isServer ? `http://localhost:${config.get(PORT)}/` : '/');
