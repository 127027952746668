import { LiveChatWidget } from '@livechat/widget-react';
import React, { FC } from 'react';
import { RootState } from 'state/types';
import { useSelector } from 'util/hooks/useSelector';

const JuridikaLivechatWidget: FC = () => {
  const visibility = useSelector((state: RootState) => state.ui.chat.visibility);

  return <LiveChatWidget license="12261696" group="3" visibility={visibility} />;
};

export default JuridikaLivechatWidget;
