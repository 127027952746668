import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { BasicSearchQuery, deserializeQueryString, toSearchURL } from 'util/searchQuery';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { useSelector } from 'util/hooks/useSelector';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import SearchBar from '../../../../components/Search/SearchBar';
import Close from '../../../../components/icons/Close';
import { Button, ButtonCss } from '../../../../components/Button/Button';

const SearchAreaCss = styled.div`
  position: relative;
  display: flex;
  background: ${({ theme }) => theme.colorTokens.purple[800]};
  max-height: 62.5rem;
  padding: 1.25rem;

  ${ButtonCss} {
    margin-left: 0.625rem;
  }
`;

const SearchAreaWrapperCss = styled.div`
  flex: 2;

  form {
    margin: 0 auto;
    max-width: 50rem;
  }
`;

const GlobalSearch: React.FC<{
  displayGlobalSearch: boolean;
  setDisplayGlobalSearch: Dispatch<SetStateAction<boolean>>;
}> = ({ displayGlobalSearch, setDisplayGlobalSearch }) => {
  const query = useSelector((state) => state.router.location.search);
  const dispatch = useDispatch();
  const handleDisplayGlobalSearch = () => setDisplayGlobalSearch((prev) => !prev);

  const handleSearch = (query: BasicSearchQuery) => {
    if (!query.term) {
      return null;
    }
    handleDisplayGlobalSearch();
    dispatch(push(toSearchURL(query)));
  };

  return (
    (displayGlobalSearch || null) && (
      <SearchAreaCss>
        <SearchAreaWrapperCss>
          <SearchBar
            autoFocus
            enableSuggestion
            onSubmit={handleSearch}
            onClose={handleDisplayGlobalSearch}
            query={deserializeQueryString(query)}
            iconButtonSvgConfig={{ width: 1, height: 1, fillColor: COLOR_TOKENS.purpleVibrant[300] }}
            paddingConfig={{ topBottom: 0.625, leftRight: 1 }}
            darkBackground
            borderColorVariant="tertiary"
          />
        </SearchAreaWrapperCss>
        <Button
          aria-label="lukk søk"
          onClick={handleDisplayGlobalSearch}
          leftIcon={<Close color={COLOR_TOKENS.purpleVibrant[300]} />}
        />
      </SearchAreaCss>
    )
  );
};

export default GlobalSearch;
