import React, { FC } from 'react';

import { COLORS } from 'theme/config/defaultTheme';
import IconPropTypes from 'components/icons/IconPropTypes';
import Svg from 'components/ui/Svg';

const Cross: FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 5.25L1.75 0 0 1.75 5.25 7 0 12.25 1.75 14 7 8.75 12.25 14 14 12.25 8.75 7 14 1.75 12.25 0 7 5.25z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);

Cross.defaultProps = {
  size: 20,
  color: COLORS.iconDefault,
};

export default Cross;
