import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { mailToLink, SUPPORT_EMAIL } from 'util/mailToLink';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { listWithoutStyle } from 'styleMixins/listMixins';
import { socialMediaLinkList } from 'components/SocialMediaLinks/SocialMediaLink';
import JuridikaByline from '../../../components/icons/Logos/JuridikaByline';
import NewsletterSignupForm from '../../../components/NewsletterSignupForm';
import FooterLinkToHelpPages from '../../../components/FooterLinkToHelpPages';
import FooterSoMeList from '../../../components/SocialMediaLinks/FooterSoMeList';

interface FooterBlockProps {
  block: string;
  children: ReactNode | ReactNode[];
}

const FooterCss = styled.footer`
  position: relative;
  padding: 3.75rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colorTokens.grey[900]};

  @media print {
    display: none;
  }
`;

const WrapperCss = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${createMediaQuery(
    'md',
    css`
      max-width: 53.0625rem;
      align-items: flex-start;
    `
  )}

  ${createMediaQuery(
    1280,
    css`
      max-width: 53.0625rem;
      max-width: 73.75rem;
      flex-direction: row;
    `
  )}

  ${createMediaQuery(
    1920,
    css`
      max-width: 53.0625rem;
      max-width: 86.25rem;
    `
  )}
`;

const LogoCss = styled.div`
  position: relative;
  padding-top: 4.09rem; /* (svg height / svg width) * width-value */
  width: 12.563rem;
  height: 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;

    .st0,
    .st1 {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

const ContainerCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${createMediaQuery(
    'md',
    css`
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    `
  )}

  ${createMediaQuery(
    1280,
    css`
      margin-left: 7.25rem;
      align-items: flex-start;
    `
  )}

  ${createMediaQuery(
    1920,
    css`
      justify-content: space-between;
    `
  )}
`;

const LinksCss = styled.div`
  ${createMediaQuery(
    'sm',
    css`
      display: flex;
      justify-content: center;
    `
  )}

  ${createMediaQuery(
    'md',
    css`
      margin-right: auto;
      justify-content: flex-start;
    `
  )}

${createMediaQuery(
    1920,
    css`
      margin-right: 0;
    `
  )}
`;

const HeadingCss = styled.div`
  margin-bottom: 0.3125rem;
  color: ${({ theme }) => theme.colorTokens.default.white};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 1.125rem;
  font-weight: 500;
`;

const ListHeadingCss = styled.span`
  color: ${({ theme }) => theme.colorTokens.grey[600]};
  margin-bottom: 0.3125rem;
  display: block;
  font-weight: 600;
`;

const ListCss = styled.ul`
  ${listWithoutStyle}

  li {
    margin: 0.9375rem 0;
    white-space: nowrap;
  }

  a,
  div {
    color: ${({ theme }) => theme.colorTokens.grey[600]};
  }
`;

const OpeningHoursCss = styled.div`
  margin-top: 0.5rem;
`;

const NewsletterSignupFormCss = styled.div`
  margin-top: 1.1875rem;
`;

const StackedFooterBlocksCss = styled.div`
  > div:first-of-type {
    margin-bottom: 1.75rem;
  }
`;

const getStyle = (block: string) => {
  switch (block) {
    case 'logoWrapper':
      return css`
        margin-bottom: 3.5rem;
      `;
    case 'contact':
      return css`
        text-align: center;

        ${createMediaQuery(
          'sm',
          css`
            margin-right: 6.25rem;
            text-align: left;
          `
        )}
        ${createMediaQuery(
          1280,
          css`
            margin-right: 6.875rem;
          `
        )}
      `;
    case 'info':
      return css`
        text-align: center;

        ${createMediaQuery(
          'sm',
          css`
            text-align: left;
          `
        )}
      `;

    case 'newsletter':
      return css`
        width: 100%;
        max-width: 19.25rem;
        color: ${({ theme }) => theme.colorTokens.grey[600]};
        font-size: 0.938rem;
        text-align: center;

        ${createMediaQuery(
          'sm',
          css`
            text-align: left;
          `
        )}
      `;

    case 'socialMedia':
      return css`
        margin-top: 0.625rem;
        width: 100%;
        max-width: 15.25rem;

        ${createMediaQuery(
          'sm',
          css`
            max-width: 100%;
          `
        )}

        ${createMediaQuery(
          1920,
          css`
            max-width: 6.25rem;
            margin-top: 0;
            width: auto;
          `
        )}
      `;
  }
};

const BlockCss = styled.div<{ block: string }>`
  margin-bottom: 3rem;

  ${({ block }) => block && getStyle(block)}
`;

const FooterBlock: FC<FooterBlockProps> = ({ block, children }) => <BlockCss block={block}>{children}</BlockCss>;

const Footer: FC = () => (
  <FooterCss>
    <WrapperCss>
      <FooterBlock block="logoWrapper">
        <LogoCss>
          <JuridikaByline />
        </LogoCss>
      </FooterBlock>
      <ContainerCss>
        <LinksCss>
          <FooterBlock block="contact">
            <HeadingCss>Kontakt</HeadingCss>
            <ListCss>
              <li>
                <ListHeadingCss> Epost </ListHeadingCss>
                <a href={mailToLink()}>{SUPPORT_EMAIL}</a>
              </li>
              <li>
                <ListHeadingCss>Telefon</ListHeadingCss>
                <a href="tel:+4724147500">24 14 75 00</a>
                <OpeningHoursCss>
                  <div>Åpningstider på telefon:</div>
                  <div>09:00-15:00 (man-fre)</div>
                </OpeningHoursCss>
              </li>
              <li>
                <ListHeadingCss>Postadresse</ListHeadingCss>
                <div>Postboks 508 Sentrum</div>
                <div>0105 Oslo</div>
              </li>
            </ListCss>
          </FooterBlock>
          <FooterBlock block="info">
            <HeadingCss>Informasjon</HeadingCss>
            <nav>
              <ListCss>
                <li>
                  <a href="https://www.universitetsforlaget.no/forfatter/bli-forfatter">Bli forfatter</a>
                </li>
                <li>
                  <Link to="/brukeravtale">Brukervilkår</Link>
                </li>
                <li>
                  <Link to="/personvern">Personvern</Link>
                </li>
                <li>
                  <Link to="/bruk-av-informasjonskapsler">Bruk av informasjonskapsler</Link>
                </li>
              </ListCss>
            </nav>
            <ListCss>
              <li>
                <ListHeadingCss> Firmanavn </ListHeadingCss>
                <div>H. Aschehoug & Co</div>
              </li>
              <li>
                <ListHeadingCss> Organisasjonsnummer </ListHeadingCss>
                <div>910 292 005</div>
              </li>
            </ListCss>
          </FooterBlock>
        </LinksCss>
        <StackedFooterBlocksCss>
          <FooterBlock block="newsletter">
            <HeadingCss>Meld deg på vårt nyhetsbrev</HeadingCss>
            <div>Få varsler om nytt innhold og ny funksjonalitet</div>
            <NewsletterSignupFormCss>
              <NewsletterSignupForm />
            </NewsletterSignupFormCss>
          </FooterBlock>
          <FooterBlock block="help">
            <HeadingCss>Spørsmål?</HeadingCss>
            <ListCss>
              <FooterLinkToHelpPages />
            </ListCss>
          </FooterBlock>
        </StackedFooterBlocksCss>
        <FooterBlock block="socialMedia">
          <FooterSoMeList linkList={socialMediaLinkList} />
        </FooterBlock>
      </ContainerCss>
    </WrapperCss>
  </FooterCss>
);

export default Footer;
