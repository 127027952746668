// Styling-related constants for the NIK page
export const NIK_HEADER_MARGIN_TOP = 32;
export const NIK_CONTENT_CONTAINER_MARGIN_TB = 25;
export const NIK_MAX_CONTRIBUTORS_Z_INDEX = 15;
export const NIK_TOOLBAR_HEIGHT = 60;
export const NIK_VIEWPORT_SIZE = {
  small: 576,
  medium: 767,
  large: 992,
};
