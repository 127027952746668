import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import AcademyBreadCrumbs from 'pages/AcademyPage/components/BreadCrumbs/AcademyBreadCrumbs';
import createMediaQuery from 'util/createMediaQuery';
import { AcademyFrontPageData } from 'pages/AcademyPage/types';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';

const HERO_SECTION_HEADING_MID_FONT_SIZE_BREAKPOINT = 500;
const HERO_SECTION_HEADING_BIG_FONT_SIZE_BREAKPOINT = 575;

interface Props {
  heroSectionData: AcademyFrontPageData['frontPage']['heroSection'];
}

const AcademyFrontPageHeroSection: FC<Props> = ({ heroSectionData }) => {
  return (
    <HeroSectionCss>
      <AcademyBreadCrumbs />

      <div className="hero-main-content">
        <h1 className="hero-heading">AKADEMI</h1>

        <p className="hero-subheading" dangerouslySetInnerHTML={{ __html: heroSectionData.tagLine }} />
      </div>
    </HeroSectionCss>
  );
};

export default AcademyFrontPageHeroSection;

const HeroSectionCss = styled.section`
  background-color: ${({ theme }) => theme.academy.frontPage.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-family: Source Sans Pro;

  .hero-main-content {
    padding: 11px 15px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1.hero-heading {
      margin: 0;
      font-weight: 700;

      font-size: 3.5625rem;
      letter-spacing: 0.5rem;

      ${createMediaQuery(
        HERO_SECTION_HEADING_MID_FONT_SIZE_BREAKPOINT,
        css`
          font-size: 4.2rem;
        `
      )}

      ${createMediaQuery(
        HERO_SECTION_HEADING_BIG_FONT_SIZE_BREAKPOINT,
        css`
          font-size: 5rem;
        `
      )}
    }

    p.hero-subheading {
      max-width: 640px;
      margin: 0;
      text-align: center;

      font-size: 1.125rem;
      line-height: 1.8rem;

      ${createMediaQuery(
        ACADEMY_VIEWPORT_SIZE.medium,
        css`
          font-size: 1.25rem;
        `
      )}
    }
  }
`;
