import { FieldValidator } from 'util/fieldValidators';
import { ContactInfoState, CompanyInfoState, CheckboxInfoState } from '../components/ContactUsForm';

export interface FieldInput extends React.InputHTMLAttributes<HTMLInputElement> {
  name: keyof ContactInfoState | keyof CompanyInfoState;
  type: 'text' | 'email' | 'tel' | 'number';
  displayName: string;
  validations: FieldValidator[];
}

export interface CheckboxInput {
  name: keyof CheckboxInfoState;
  displayName: string;
}

export const CONTACT_INFO_FIELDS: FieldInput[] = [
  {
    name: 'firstname',
    type: 'text',
    displayName: 'Fornavn',
    validations: ['required'],
    required: true,
  },
  {
    name: 'lastname',
    type: 'text',
    displayName: 'Etternavn',
    validations: ['required'],
    required: true,
  },
  {
    name: 'email',
    type: 'email',
    displayName: 'E-post',
    validations: ['email'],
    required: true,
  },
  {
    name: 'number',
    type: 'tel',
    displayName: 'Mobil',
    validations: ['phone'],
    required: true,
  },
];

export const COMPANY_INFO_FIELDS: FieldInput[] = [
  {
    name: 'companyName',
    type: 'text',
    displayName: 'Firma',
    validations: ['required'],
    required: true,
  },
];

export const CHECKBOXES: CheckboxInput[] = [
  {
    name: 'hasAcceptedTerms',
    displayName: 'Har akseptert brukervilkårene',
  },
  {
    name: 'shouldReceiveNewsletter',
    displayName: 'Ønsker å motta nyhetsbrev',
  },
];
