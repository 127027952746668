import LoadingSpinner from 'components/LoadingSpinner';
import { LoadingSpinnerWrapperCss } from 'pages/AcademyPage/pages/CoursePage/Styles';
import { ACADEMY_VIEWPORT_SIZE, getTotalInChapter, putModuleProgress } from 'pages/AcademyPage/utils';
import * as React from 'react';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { updateModuleProgressAction } from 'state/ui/uiActions';
import JWPlayer from '@jwplayer/jwplayer-react';
import { useSelector } from 'util/hooks/useSelector';
import { Module, ModuleProgress } from 'pages/AcademyPage/types';
import { CourseContentContext } from 'pages/AcademyPage/utils/academyCourseContentContext';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export const JWPlayerLibraryUrl = 'https://cdn.jwplayer.com/libraries/WRE4TQ4F.js';

declare const window: Window &
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jwplayer: any;
  };

interface OnTimeEvent {
  absolutePosition: unknown;
  currentTime: number;
  duration: number;
  metadata: { currentTime: number };
  position: number;
  seekRange: { start: number; end: number };
  type: 'time';
  viewable: number;
}

interface OnMetaEvent {
  duration: number;
  height: number;
  metadataType: string;
  seekRange: { start: number; end: number };
  type: string;
  width: number;
}

interface VideoProps {
  id: string;
  playlist: { file: string; image: string };
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  onReady: () => void;
  // For handling hotspot links. Module progress update is handled inside this component
  onTime: (currentTime: number) => void;
  moduleMetadata: Module;
}

const VideoCss = styled.div`
  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.medium,
    css`
      .jwplayer {
        border-radius: 0.5rem;
      }
    `
  )}
`;

const Video: FC<VideoProps> = ({ id, playlist, loading, setLoading, setDuration, onReady, duration, onTime, moduleMetadata }) => {
  const store = useStore();

  const progressValue: ModuleProgress[] = useSelector((state) => state.ui.akademi.moduleProgress);
  const progressRef = useRef(progressValue);

  const academyContext = React.useContext(CourseContentContext);
  const totalTimestampTokensInChapter = getTotalInChapter(academyContext, moduleMetadata.inChapter, 'timestampTokens');

  const [intervalId, setIntervalId] = useState<number | null>(null);

  useEffect(() => {
    progressRef.current = progressValue;
  }, [progressValue]);

  // This useeffect removes the interval for storing progress when the component unmounts
  useEffect(() => {
    const clearInterval = () => {
      if (intervalId) window.clearInterval(intervalId);
    };

    window.addEventListener('clearinterval', clearInterval);

    return () => {
      window.removeEventListener('clearinterval', clearInterval);
      clearInterval();
    };
  }, [intervalId]);

  const dispatch = useDispatch();

  const storeModuleProgress = useCallback(
    (timestampTokensSeen: number[]) => {
      const timestampCursor: number = window.jwplayer(id).getCurrentTime();
      dispatch(
        updateModuleProgressAction({
          timestampCursor,
          timestampTokensSeen,
          totalTimestampTokens: duration + 1,
          totalTimestampTokensInChapter,
        })
      );
    },
    [dispatch, duration, id, totalTimestampTokensInChapter]
  );

  const setModuleProgress = useCallback(
    (timestampTokensSeen: number[]) => {
      storeModuleProgress(timestampTokensSeen);
    },
    [storeModuleProgress]
  );

  const handleOnReady = async () => {
    setLoading(false);
    onReady();
  };

  const handleOnMeta = async (event: OnMetaEvent) => {
    const videoDuration = Math.floor(event.duration);
    setDuration(videoDuration);
  };

  const handleOnTime = (event: OnTimeEvent) => {
    const { currentTime } = event;

    // TODO: Debounce her før kall til setModuleProgress og oppover til onTime (men alt burde kanskje håndteres på et sted,
    // feks flytte setModuleProgress i parent komponenenten)
    setModuleProgress([currentTime]);

    onTime(currentTime);
  };

  const updateTime = () => {
    const saveProgressToDatabase = () => {
      const currentModuleProgress = progressRef.current?.find((item) => item.moduleId === moduleMetadata.id);

      if (currentModuleProgress) {
        const { accessToken } = store.getState().session;

        putModuleProgress(accessToken, currentModuleProgress, moduleMetadata.id);
      }
    };
    saveProgressToDatabase();
  };

  const handlePlay = (): void => {
    if (!intervalId) {
      const id = window.setInterval(updateTime, 5000);
      setIntervalId(id);
    }
  };

  const handlePause = (): void => {
    if (intervalId) {
      window.clearInterval(intervalId);
      setIntervalId(null);
      const currentModuleProgress = progressRef.current.find((item) => item.moduleId === moduleMetadata.id);
      if (!currentModuleProgress) {
        throw new Error('Could not find module progress');
      }

      const { accessToken } = store.getState().session;

      putModuleProgress(accessToken, currentModuleProgress, moduleMetadata.id);
    }
  };

  const handleOnError = (): void => {
    // TODO handle error, maybe reload page
    setLoading(false);
  };

  return (
    <VideoCss>
      {loading && (
        <LoadingSpinnerWrapperCss>
          <LoadingSpinner size="big" />
        </LoadingSpinnerWrapperCss>
      )}

      <JWPlayer
        id={id}
        library={JWPlayerLibraryUrl}
        playlist={playlist}
        key={playlist.file} // BUG WORKAROUND: we need this for the component to rerender --> https://github.com/jwplayer/jwplayer-react/issues/20
        onReady={handleOnReady}
        onTime={handleOnTime}
        onPlay={handlePlay}
        onPause={handlePause}
        onStop={handlePause}
        onComplete={handlePause}
        onMeta={handleOnMeta}
        onSetupError={handleOnError}
      />
    </VideoCss>
  );
};

export default Video;
