import styled, { css } from 'styled-components';
import { buttonWithoutStyle, listWithoutStyle } from 'styleMixins/listMixins';
import LogInOrOutButtons from 'containers/LogInOrOutButtons';
import { NAVBAR_HEIGHT } from '../../utils';

export const NavbarCss = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1.25rem;
  align-items: center;
  background: inherit;
  height: ${NAVBAR_HEIGHT}px;
`;

export const ActionsListCss = styled.ul`
  ${listWithoutStyle};
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ActionItemCss = styled.li`
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
`;

export const commonButtonStyles = css`
  ${buttonWithoutStyle}
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;

  span {
    color: ${({ theme }) => theme.colorTokens.red[600]};
    margin-left: 0.4rem;
    font-weight: 600;
  }

  :hover {
    opacity: 1;
    span {
      color: ${({ theme }) => theme.colorTokens.default.black};
    }
    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.black};
    }
  }

  :focus-visible {
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colorTokens.default.black};

    span {
      color: ${({ theme }) => theme.colorTokens.default.black};
    }
    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.black};
    }
  }
`;

export const MenyButtonItemCss = styled.button`
  ${commonButtonStyles}
`;

export const SearchButtonItemCss = styled.button`
  ${commonButtonStyles}
`;

export const LogInOrOutButtonsCss = styled(LogInOrOutButtons)`
  color: ${({ theme }) => theme.colorTokens.red[600]};

  svg * {
    fill: ${({ theme }) => theme.colorTokens.red[600]};
  }

  ${commonButtonStyles}

  span {
    margin-left: 0;
  }
`;

export const SecondNavbarCss = styled.nav<{ $hasBeigeBackground: boolean }>`
  padding: 1.125rem 1.25rem;
  display: flex;
  justify-content: space-between;
  background-color: ${({ $hasBeigeBackground, theme }) =>
    $hasBeigeBackground ? theme.colorTokens.peach[300] : theme.colorTokens.default.white};

  ul {
    ${listWithoutStyle}
    display: inline-flex;
    align-items: center;
  }
`;

export const CloseButtonCss = styled.button`
  ${buttonWithoutStyle}

  display: flex;
  align-items: center;

  transition: transform 1.25s ease;
  transform-origin: center;

  svg {
    transform: translate(0.6px, -0.6px);
  }

  :hover {
    color: ${({ theme }) => theme.colorTokens.red[600]};
    opacity: 1;
    transform: rotate(360deg);

    svg * {
      fill: ${({ theme }) => theme.colorTokens.red[600]};
    }
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.red[600]};
    transform: none;
    border: none;

    svg * {
      fill: ${({ theme }) => theme.colorTokens.red[600]};
    }
  }
`;
