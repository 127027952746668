import Svg from 'components/ui/Svg';
import * as React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const ChatBubble: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" {...props}>
    <path
      fill={color}
      d="M8 0C3.639 0 0 3.203 0 7.253c0 4.05 3.639 7.254 8 7.254.277 0 .532-.056.8-.082V17l1.233-.796c1.73-1.119 5.475-4.022 5.922-8.189.028-.248.045-.502.045-.762C16 3.203 12.361 0 8 0zm0 1.612c3.592 0 6.4 2.57 6.4 5.641 0 .194-.012.388-.034.583v.003l-.002.003c-.276 2.58-2.326 4.605-3.964 5.926V12.55l-.958.195c-.468.096-.95.15-1.442.15-3.592 0-6.4-2.571-6.4-5.642 0-3.07 2.808-5.641 6.4-5.641z"
    />
  </Svg>
);

ChatBubble.defaultProps = {
  color: COLOR_TOKENS.default.white,
};

export default ChatBubble;
