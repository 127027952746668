import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const ArrowLeft: React.FC<IconPropTypes> = ({ color, height, width, size, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width || size} height={height || size} viewBox="0 0 8 12" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M0 6c1.98637037-2 3.97214815-4 5.95851852-6L8 1.451 3.472 6.0115 8 10.5605 5.95851852 12C3.97688889 9.996 1.96740741 8.0135 0 6z"
    />
  </Svg>
);

ArrowLeft.defaultProps = {
  size: 16,
  color: COLOR_TOKENS.grey[900],
};
export default ArrowLeft;
