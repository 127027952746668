import * as React from 'react';
import styled from 'styled-components';

const LabelCss = styled.label<{ isDisabled: boolean }>`
  margin-left: 0.625rem;
  color: ${(props) => props.isDisabled && props.theme.colorTokens.grey[700]};

  :hover {
    cursor: pointer;
  }
`;

const CheckboxLabel: React.FC<{
  label?: string | JSX.Element;
  labelFor: string;
  isDisabled?: boolean;
  className?: string;
}> = ({ label, isDisabled = false, className = '', labelFor }) => {
  if (!label) return null;

  return typeof label === 'string' ? (
    <LabelCss className={className} isDisabled={isDisabled} htmlFor={labelFor}>
      {label}
    </LabelCss>
  ) : (
    <LabelCss className={className} isDisabled={isDisabled} htmlFor={labelFor}>
      {label}
    </LabelCss>
  );
};

export default CheckboxLabel;
