import { cookieHandlerClient } from 'components/Cookiebanner/cookieHandlerClient';
import { SetCookie } from 'components/Cookiebanner/model';
import { ProductInfo } from 'models/customerInfo/CustomerInfo';
import { GqlPackage } from 'util/hooks/usePackageQuery';
import { includesFreeAccessTag } from 'util/structuredData';
import uuidv4 from 'uuid/v4';

const PRODUCT_TYPE_HOVEDPRODUKT = 'Hovedprodukt';

export const HOVEDPRODUCT_BACKLIST = 'h_backlist';
export const HOVEDPRODUCT_FRONTLIST = 'h_frontlist';
export const HOVEDPRODUCT_LOVKOMMENTARER = 'h_lovkommentarer';
export const HOVEDPRODUCT_TIDSSKRIFTER = 'h_tidsskrifter';
export const HOVEDPRODUCT_MALER = 'h_contract';

export const NYE_FAGBOKER = 'Nye fagbøker';

export const PREMIUM = [HOVEDPRODUCT_BACKLIST, HOVEDPRODUCT_MALER, HOVEDPRODUCT_LOVKOMMENTARER, HOVEDPRODUCT_TIDSSKRIFTER];

export interface RoyaltyMetadata {
  activeProducts: ProductInfo[];
  businessPackages: string[];
}

const filterProducts = (resourcePackageKeys: string[], userProducts: ProductInfo[]): ProductInfo[] => {
  return userProducts.filter((productInfo) => resourcePackageKeys.includes(productInfo.key));
};

const filterPackages = (userProductsKeys: string[], resourcePackage: GqlPackage[]): GqlPackage[] => {
  return resourcePackage.filter((pack) => userProductsKeys.includes(pack.key));
};

export const determineBusinessPackage = (
  userProductsKeys: string[],
  resourcePackages: GqlPackage[],
  isFrontlist = false
): string[] => {
  if (userProductsKeys?.length === 0) return [''];
  const packages = filterPackages(userProductsKeys, resourcePackages);
  if (isFrontlist === false && packages.length !== 0) return packages.map((p) => p.name);
  if (isFrontlist === true && userProductsKeys.some((key) => key === HOVEDPRODUCT_FRONTLIST)) return [NYE_FAGBOKER];
  if (PREMIUM.every((key) => userProductsKeys.includes(key))) return ['Premium'];
  return [''];
};

export const fictiveFreebieProduct: ProductInfo = {
  key: 'freebie',
  name: 'Freebie',
  type: PRODUCT_TYPE_HOVEDPRODUKT,
};

// Determines the appropriate royalty product(s) based on the resource packages, the user products, and/or the resource tags.
export const determineRoyaltyProducts = (
  resourcePackages: GqlPackage[],
  userProducts: ProductInfo[] | undefined,
  resourceTags: string[] | undefined
): ProductInfo[] => {
  if (includesFreeAccessTag(resourceTags)) {
    return [fictiveFreebieProduct];
  }

  if (!userProducts || userProducts.length === 0) {
    return [];
  }

  // Use packages if available, since some users migrating from old system may have both packages and main products.
  const royaltyPackages = filterProducts(
    resourcePackages.map((p) => p.key),
    userProducts
  );

  if (royaltyPackages.length > 0) {
    return royaltyPackages;
  }

  const royaltyMainProducts = userProducts.filter((p) => p.type === PRODUCT_TYPE_HOVEDPRODUKT);
  if (royaltyMainProducts.length > 0) {
    return royaltyMainProducts;
  }

  throw new Error(
    `No active products found for authorized user. userProduct: ${userProducts}, resourcePackages: ${resourcePackages}`
  );
};

const packageIncludesTagPathPrefix = (tagPathPrefix: string, tag: string): boolean => {
  return tag.startsWith(tagPathPrefix);
};

export const findPackagesFromTag = (tagPrefixes: string[] | undefined, allPackages: GqlPackage[]): GqlPackage[] => {
  if (!tagPrefixes) {
    return [];
  }

  const packagesMappedToTagPath = allPackages.map((p) => {
    return {
      package: p,
      tagPaths: p.tags.map((t) => t.path.join('.')),
    };
  });

  const filteredPackages = packagesMappedToTagPath.filter((entry) => {
    return entry.tagPaths.some((tagPath) => tagPrefixes.some((tagPrefix) => packageIncludesTagPathPrefix(tagPrefix, tagPath)));
  });

  return filteredPackages.map((entry) => entry.package);
};

const SESSION_MINUTES = 30;
const SESSION_EXPIRES = (1 / (24 * 60)) * SESSION_MINUTES;

// Get a sessionId for rudderstack statistics. If cookie is not present or expired, a new one is created.
export const getTimedSessionId = (): string => {
  const cookieName = 'royaltySession';
  const cookieValue = cookieHandlerClient.getCookie({ name: cookieName, options: undefined });

  if (cookieValue) {
    cookieHandlerClient.setCookie({ name: cookieName, value: cookieValue, options: { expires: SESSION_EXPIRES } });
    return cookieValue;
  }

  const newCookie: SetCookie = {
    name: cookieName,
    value: uuidv4(),
    options: {
      expires: SESSION_EXPIRES,
      sameSite: 'Strict',
    },
  };

  cookieHandlerClient.setCookie(newCookie);
  return newCookie.value;
};
