import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { COLORS, COLOR_TOKENS } from 'theme/config/defaultTheme';
import Flex, { FlexPropTypes } from 'components/ui/Flex';
import Text from 'components/ui/Text';
import icons from 'components/icons';
import IconPropTypes from 'components/icons/IconPropTypes';
import { mailToLink } from 'util/mailToLink';

export interface NoticePropTypes extends FlexPropTypes {
  title?: string;
  content?: string;

  variant?: string;
  info?: boolean;
  danger?: boolean;
  success?: boolean;

  size?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;

  center?: boolean;
  rounded?: boolean;
  icon?: IconPropTypes & { name: string };

  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  fontWeight?: number | string;

  className?: string;
}

const defaultProps = {
  size: 'medium',
  info: false,
  danger: false,
  success: false,
  small: false,
  medium: false,
  large: false,
  center: false,
  rounded: false,
  backgroundColor: '__none__',
  borderColor: '__none__',
  textColor: '__default__',
  fontWeight: '__default__',
};

const WrapperCss = styled(Flex)<NoticePropTypes>`
  ${({ borderColor }) =>
    borderColor !== '__none__' &&
    css`
      border: 2px solid ${borderColor};
    `}
  ${({ textColor }) =>
    css`
      color: ${textColor === '__default__' ? COLOR_TOKENS.default.black : textColor};
    `}
  ${({ backgroundColor }) =>
    backgroundColor !== '__none__' &&
    css`
      background-color: ${backgroundColor};
    `}
    ${({ fontWeight }) =>
    css`
      font-weight: ${fontWeight === '__default__' ? 400 : fontWeight};
    `}
`;

const Notice: FC<NoticePropTypes> = ({
  textColor,
  variant,
  info,
  danger,
  success,
  small,
  large,
  size,
  title,
  icon,
  content,
  center,
  children,
  ...props
}) => {
  let pVariant = '__default__';

  if (info) {
    pVariant = 'info';
  } else if (danger) {
    pVariant = 'danger';
  } else if (success) {
    pVariant = 'success';
  } else if (variant) {
    pVariant = variant;
  }

  let pSize = 'medium';

  if (small) {
    pSize = 'small';
  } else if (large) {
    pSize = 'large';
  } else if (size) {
    pSize = size;
  }

  let pIcon = null;

  if (pVariant === 'info') {
    pIcon = {
      name: 'Info',
      size: pSize === 'small' ? 18 : pSize === 'large' ? 28 : 20,
      ...icon,
    };
  } else if (pVariant === 'danger') {
    pIcon = {
      name: 'WarningStatusCircle',
      size: pSize === 'small' ? 18 : pSize === 'large' ? 28 : 20,
      color: COLORS.danger,
      ...icon,
    };
  } else if (pVariant === 'success') {
    pIcon = {
      name: 'SuccessStatusCircle',
      size: pSize === 'small' ? 18 : pSize === 'large' ? 28 : 20,
      color: COLORS.success,
      ...icon,
    };
  } else if (icon) {
    pIcon = icon;
  }

  const Icon = !children && pIcon && pIcon.name && icons[pIcon.name] ? icons[pIcon.name] : null;

  const wrapperPadding = pSize === 'small' ? 1 : pSize === 'large' ? 4 : 2;
  const titleSize = pSize === 'small' ? 1 : pSize === 'large' ? 3 : 2;
  const contentSize = pSize === 'large' ? 2 : 1;
  const titleMarginBottom = pSize === 'large' ? 2 : 1;
  const iconMarginBottom = pSize === 'large' ? 2 : 1;
  const dangerDefaultText = (
    <>
      Noe gikk galt. Ta <a href={mailToLink()}>kontakt</a> hvis det ikke hjelper å prøve igjen.
    </>
  );
  const pContent: string | undefined | JSX.Element = danger && !content && !children ? dangerDefaultText : content;

  return center ? (
    <WrapperCss p={wrapperPadding} column align="center" {...props}>
      {Icon && pIcon && (
        <Flex mb={iconMarginBottom} justify="center">
          <Icon size={pIcon.size} color={pIcon.color} />
        </Flex>
      )}
      {title && (
        <Text mb={titleMarginBottom} f={titleSize} bold center={center} color={textColor}>
          {title}
        </Text>
      )}
      {pContent && (
        <Text f={contentSize} center={center} color={textColor}>
          {pContent}
        </Text>
      )}
      {children}
    </WrapperCss>
  ) : (
    <WrapperCss p={wrapperPadding} {...props}>
      {Icon && pIcon && (
        <Flex mr={pSize === 'small' ? 1 : 2}>
          <Icon size={pIcon.size} color={pIcon.color} />
        </Flex>
      )}
      {(title || pContent) && (
        <Flex column>
          {title && (
            <Text bold mb={titleMarginBottom} f={titleSize} color={textColor}>
              {title}
            </Text>
          )}
          <Text f={contentSize} color={textColor}>
            {pContent}
          </Text>
        </Flex>
      )}
      {children}
    </WrapperCss>
  );
};

Notice.defaultProps = defaultProps;

export default Notice;
