import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import JWPlayer from '@jwplayer/jwplayer-react';

import createMediaQuery from 'util/createMediaQuery';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { JWPlayerLibraryUrl } from 'pages/AcademyPage/components/Video/Video';
import { AcademyFrontPageData } from 'pages/AcademyPage/types';
import {
  AcademyFrontPageTextContentCss,
  AcademyFrontPageSectionHeadingCss,
  AcademyFrontPageVideoContainerCss,
  AcademyFrontPageLimitWidthContainerCss,
} from './components/AcademyFrontPageContentStyles';
import {
  AcademyFrontPageSectionCss,
  AkademiFrontPageSectionBackgroundColor,
  AcademyFrontPageSectionContentCss,
} from './components/AcademyFrontPageSectionStyles';
import GetAccessOrStartCourseButton from './components/GetAccessOrStartCourseButton';

interface Props {
  sneakPeakSectionData: AcademyFrontPageData['frontPage']['sneakPeakSection'];
}

const AcademyFrontPageSneakPeakSection: FC<Props> = ({ sneakPeakSectionData }) => {
  return (
    <AcademyFrontPageSectionCss backgroundColor={AkademiFrontPageSectionBackgroundColor.PURPLE_VIBRANT}>
      <AcademyFrontPageSectionContentCss>
        <SneakPeakLimitWidthContainerCss>
          <SneakPeakSectionHeadingCss dangerouslySetInnerHTML={{ __html: sneakPeakSectionData.title }} />

          <SneakPeakSectionBodyTextCss dangerouslySetInnerHTML={{ __html: sneakPeakSectionData.text }} />

          <div className="button-container">
            <GetAccessOrStartCourseButton />
          </div>
        </SneakPeakLimitWidthContainerCss>

        <SneakPeakSectionVideoContainerCss>
          <JWPlayer
            library={JWPlayerLibraryUrl}
            key={sneakPeakSectionData.video.videoFileUrl}
            playlist={{
              file: sneakPeakSectionData.video.videoFileUrl,
              image: sneakPeakSectionData.video.posterImageUrl,
              tracks: [
                {
                  file: sneakPeakSectionData.video.videoCaptions,
                  kind: 'captions',
                  label: 'Norwegian',
                },
              ],
            }}
          />
        </SneakPeakSectionVideoContainerCss>
      </AcademyFrontPageSectionContentCss>
    </AcademyFrontPageSectionCss>
  );
};

export default AcademyFrontPageSneakPeakSection;

const SneakPeakSectionHeadingCss = styled(AcademyFrontPageSectionHeadingCss)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.mediumSmall,
    css`
      text-align: center;
    `
  )}
`;

const SneakPeakSectionBodyTextCss = styled(AcademyFrontPageTextContentCss)`
  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.mediumSmall,
    css`
      text-align: center;
    `
  )}
`;

const SneakPeakLimitWidthContainerCss = styled(AcademyFrontPageLimitWidthContainerCss)`
  ${SneakPeakSectionBodyTextCss} {
    margin-bottom: 20px;
  }

  .button-container {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
`;

const SneakPeakSectionVideoContainerCss = styled(AcademyFrontPageVideoContainerCss)`
  max-width: 1024px;
`;
