import React from 'react';

const Quote: React.FC<{}> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M23.602 11.011c.002-.054.008-.108.008-.164l-.002-.054.002-.11-.008.006a4.593 4.593 0 1 0-5.238 4.701 4.463 4.463 0 0 1-4.273 3.171v1.64c5.149 0 9.336-4.083 9.511-9.19zM11.68 11.011c.001-.054.008-.108.008-.164l-.002-.054.002-.11-.009.006a4.593 4.593 0 1 0-5.238 4.701 4.463 4.463 0 0 1-4.273 3.171v1.64a9.513 9.513 0 0 0 9.511-9.19z" />
    </g>
  </svg>
);

export default Quote;
