import { useLayoutEffect, RefObject, useRef } from 'react';

function useFocusTrap(modalRef: RefObject<HTMLDivElement>): void {
  const previousFocusedElement = useRef<HTMLElement | null>(null);
  useLayoutEffect(() => {
    previousFocusedElement.current = document.activeElement as HTMLElement;

    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.focus();
      }
    }, 1);

    // Disable tabbing outside the modal
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const modalElements = modalRef.current?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        if (modalElements && modalElements.length > 0) {
          const firstElement = modalElements[0] as HTMLElement;
          const lastElement = modalElements[modalElements.length - 1] as HTMLElement;

          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();

            firstElement.focus();
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalRef]);
}

export default useFocusTrap;
