import { useRef, useEffect } from 'react';

type Timer = ReturnType<typeof setTimeout>;

type DebouncedFunction<F extends (...args: any[]) => any> = {
  (...args: Parameters<F>): ReturnType<F>;
  cancel: () => void;
};

/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 * https://medium.com/@philip.andrewweedewang/debounce-hook-in-react-typescript-in-20-lines-of-code-9cde26254d10
 */

export const useDebounce = <F extends (...args: any[]) => any>(func: F, delay: number): DebouncedFunction<F> => {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction = ((...args: Parameters<F>) => {
    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as DebouncedFunction<F>;

  debouncedFunction.cancel = () => {
    clearTimeout(timer.current);
  };

  return debouncedFunction;
};
