import { JWT } from '../../models/jwt/JWT';

export const getTokenExpirationDate = (decodedToken: JWT): Date | null => {
  if (!decodedToken.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(decodedToken.exp);

  return date;
};
