import Svg from 'components/ui/Svg';
import React, { FC } from 'react';
import IconPropTypes from 'icons/IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const AngleLeft: FC<IconPropTypes> = ({ color, size, ...props }) => (
  <Svg width={size || 10} height={size || 16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8L7.44815 0L10 1.93467L4.34 8.01533L10 14.0807L7.44815 16C4.97111 13.328 2.45926 10.6847 0 8Z"
      fill={color}
    />
  </Svg>
);

AngleLeft.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default AngleLeft;
