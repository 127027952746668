import Svg from 'components/ui/Svg';
import * as React from 'react';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const ArrowDown3: React.FC<IconPropTypes> = ({ width, height, color, ...props }) => (
  <Svg width={width} height={height} viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.5C6.64333 9.5495 3.34 6.5345 0 3.5615L2.4 0.5L9.98167 7.292L17.5817 0.5L20 3.5615L10 12.5Z"
      fill={color}
    />
  </Svg>
);

ArrowDown3.defaultProps = {
  color: COLOR_TOKENS.purple[700],
  width: 20,
  height: 13,
};

export default ArrowDown3;
