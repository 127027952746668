import * as React from 'react';
import styled from 'styled-components';
import { ButtonAndLinkContent } from './ButtonAndLinkContent';

import { buttonAndLinkCss, ButtonAndLinkProps, TransientButtonAndLinkStyleProps } from './ButtonAndLinkCss';

export type ButtonProps = ButtonAndLinkProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const defaultProps: ButtonProps = {
  $fullWidth: true,
  $rounded: false,
};

export const ButtonCss = styled.button<TransientButtonAndLinkStyleProps>`
  ${buttonAndLinkCss}
`;

export const Button = React.forwardRef((props: ButtonProps = defaultProps, ref: any) => {
  const { children, leftIcon, rightIcon, ...buttonProps } = props;

  return (
    <ButtonCss {...buttonProps} ref={ref}>
      <ButtonAndLinkContent leftIcon={leftIcon} rightIcon={rightIcon} $color={buttonProps.$color} $variant={buttonProps.$variant}>
        {children}
      </ButtonAndLinkContent>
    </ButtonCss>
  );
});
