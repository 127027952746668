import { UiState } from './types';

export const mergeStateValue = (xState: any, payload: any, meta: string | Array<string>): UiState => {
  const key = typeof meta === 'string' ? [meta] : meta;

  if (key.length > 3 || key.length < 1) {
    // ERROR not supporting triple state or no key
    return xState;
  }

  if (key.length > 2) {
    return {
      ...xState,
      [key[0]]: {
        ...xState[key[0]],
        [key[1]]: {
          ...xState[key[0]][key[1]],
          [key[2]]:
            typeof payload === 'object' && !Array.isArray(payload) && payload !== null
              ? { ...xState[key[0]][key[1]][key[2]], ...payload }
              : payload,
        },
      },
    };
  }

  if (key.length > 1) {
    return {
      ...xState,
      [key[0]]: {
        ...xState[key[0]],
        [key[1]]:
          typeof payload === 'object' && !Array.isArray(payload) && payload !== null
            ? { ...xState[key[0]][key[1]], ...payload }
            : payload,
      },
    };
  }

  return {
    ...xState,
    [key[0]]:
      typeof payload === 'object' && !Array.isArray(payload) && payload !== null ? { ...xState[key[0]], ...payload } : payload,
  };
};
