export enum ActiveLoginMethod {
  USER,
  IP,
  AUTO,
  SSO,
  FEIDE,
}

export enum AuthenticationStatus {
  NOT_AUTHENTICATED,
  UNKNOWN,
  AUTHENTICATED,
}

export interface TokenValidationResult {
  authStatus: AuthenticationStatus;
  permissions: Array<string>;
  accessTokenExpiryTimestamp: number | undefined;
}

export interface LoginState {
  readonly activeLoginMethod: ActiveLoginMethod | null;
  readonly isCheckingIp: boolean;
  readonly haveCheckedIp: boolean;
  readonly canDoIpLogin: boolean;

  // Are we currently _officially_ authenticated?
  readonly authStatus: AuthenticationStatus;
  readonly prevAuthStatus: AuthenticationStatus;

  // officially authenticated permissions
  readonly permissions: Array<string>;
  readonly accessTokenExpiryTimestamp: number | undefined;

  // These are considered private state fields, only to be used
  // by middlewares and reducers. React should never subscribe.
  readonly jwtMiddlewareInitialized: boolean;
  readonly jwtMiddlewareLastValidationResult: TokenValidationResult;
}
