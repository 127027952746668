import * as React from 'react';
import styled from 'styled-components';

const StyledImageCss = styled.img<{ $maxWidth?: string }>`
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
`;

export enum Loading {
  LAZY = 'lazy',
  EAGER = 'eager',
}
interface ImageProps {
  src: string;
  maxWidth?: string;
  loading?: Loading;
  alt?: string;
  width?: string;
  height?: string;
}

const Image: React.FC<ImageProps> = ({ src, maxWidth, loading, alt, ...props }) => {
  return <StyledImageCss src={src} $maxWidth={maxWidth} alt={alt} loading={loading || Loading.LAZY} {...props} />;
};

export default Image;
