import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'state/types';
import {
  courseProgressSelector,
  chapterProgressByIdSelector,
  moduleProgressByIdSelector,
  isModuleCompletedSelector,
  isChapterModulesCompletedSelector,
  isCourseModulesBeforeFinalTestCompletedSelector,
} from 'state/selectors';
import { CourseContentContext } from 'pages/AcademyPage/utils/academyCourseContentContext';
import ProgressbarWithCheckmark from './ProgressbarWithCheckmark';

const CourseProgressbarWithCheckmarkCss = styled(ProgressbarWithCheckmark)`
  > div {
    background-color: ${({ theme }) => theme.colorTokens.default.white};
    > div {
      background-color: ${({ theme }) => theme.colorTokens.purple[400]};

      span {
        background-color: ${({ theme }) => theme.colorTokens.default.white};
        color: ${({ theme }) => theme.colorTokens.purple[700]};
      }
    }
  }

  > svg {
    path:first-of-type {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
    path:last-of-type {
      fill: ${({ theme }) => theme.colorTokens.purple[700]};
    }
  }
`;

export const CourseProgressbar: FC = () => {
  const courseContent = useContext(CourseContentContext);

  const courseProgress = useSelector((state: RootState) => courseProgressSelector(state, courseContent));
  const isCourseCompleted = useSelector((state: RootState) =>
    isCourseModulesBeforeFinalTestCompletedSelector(state, courseContent)
  );

  return <CourseProgressbarWithCheckmarkCss progress={courseProgress} isCompleted={isCourseCompleted} />;
};

export const ChapterProgressbar: FC<{ chapterId: string }> = ({ chapterId }) => {
  const courseContent = useContext(CourseContentContext);

  const chapterProgress = useSelector((state: RootState) => chapterProgressByIdSelector(state, chapterId));
  const isChapterCompleted = useSelector((state: RootState) =>
    isChapterModulesCompletedSelector(state, chapterId, courseContent)
  );

  return <ProgressbarWithCheckmark progress={chapterProgress} isCompleted={isChapterCompleted} />;
};

export const ModuleProgressbar: FC<{ moduleId: string }> = ({ moduleId }) => {
  const moduleProgress = useSelector((state: RootState) => moduleProgressByIdSelector(state, moduleId));
  const isModuleCompleted = useSelector((state: RootState) => isModuleCompletedSelector(state, moduleId));

  return <ProgressbarWithCheckmark progress={moduleProgress} isCompleted={isModuleCompleted} />;
};
