import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';

export const useClickOutsideButtonAndElementHook = (
  elementRef: RefObject<HTMLElement>,
  buttonRef: RefObject<HTMLButtonElement>,
  setBooleanState: Dispatch<SetStateAction<boolean>>
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setBooleanState(false);
      }
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        elementRef.current &&
        !elementRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setBooleanState(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [buttonRef, elementRef, setBooleanState]);
};
