import { norwegianListify } from './languageUtils';

export const generatePageTitle = (...keywords: (string | undefined | null)[]): string => keywords.filter(Boolean).join(' | ');

export const generateTextbookEditionTitle = (editionTitle: string, contributorNames: string[][]): string => {
  const contributorFullNames = contributorNames.map((names) => names.join(' '));

  const contributorNamesStr = norwegianListify(contributorFullNames);

  return `${editionTitle}${contributorNamesStr ? ` av ${contributorNamesStr}` : ''} - Les boka`;
};
