import { intersection } from './collectionUtils';

export const isHtmlElement = (node: Node): node is HTMLElement =>
  /* Could technically also be other types of Element, like SVGElement,
   * but prior knowledge of the content assures us this will be HTML. */
  node.nodeType === Node.ELEMENT_NODE;

export const hasClassAnyOf = (element: HTMLElement, potentialClasses: string[]) =>
  intersection([...element.classList.values()], potentialClasses).length > 0;

export const ancestorsOf = (node: Node): Node[] => {
  const ancestors = [];
  let curr: Node = node;
  while (curr.parentNode) {
    curr = curr.parentNode;
    ancestors.push(curr);
  }
  return ancestors;
};

export const selfAndAncestorsOf = (node: Node): Node[] => [node, ...ancestorsOf(node)];

export const setAppContainerHeight = (height: string) => {
  if (document) {
    const appContainer = document.getElementById('app-container');

    if (appContainer) {
      appContainer.style.height = height;
    }
  }
};

export const firstFollowingNode = <N extends Node>(
  origin: Node,
  direction: 'next' | 'previous',
  includeIf: (n: Node) => n is N,
  terminateIf: (n: Node) => boolean
): N | undefined => {
  const closest: Generator<Node> = allFollowingNodes(origin, direction, includeIf, terminateIf);
  return closest.next().value;
};

// eslint-disable-next-line func-names
export const allFollowingNodes = function* (
  origin: Node,
  direction: 'next' | 'previous',
  includeIf: (n: Node) => boolean,
  terminateIf: (n: Node) => boolean
): Generator<Node> {
  const dontProcessChildrenOf: Node[] = ancestorsOf(origin);

  let currNode: Node | null = origin;

  // Fallback to avoid infinite iteration if loop is bugged
  let iterationNum = 0;
  // eslint-disable-next-line no-plusplus
  while (currNode && !terminateIf(currNode) && iterationNum++ < 1000) {
    const followingChild: Node | null = direction === 'next' ? currNode.firstChild : currNode.lastChild;
    const followingSibling: Node | null = direction === 'next' ? currNode.nextSibling : currNode.previousSibling;

    if (followingChild && !dontProcessChildrenOf.includes(currNode)) {
      dontProcessChildrenOf.unshift(currNode);
      currNode = followingChild;
    } else {
      currNode = followingSibling || currNode.parentNode;
    }

    if (currNode && includeIf(currNode)) {
      yield currNode;
    }
  }
};
