import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import Checked from '../../../../../images/icon-checked-purple.svg';

const TextBlockStylesheet = styled.div`
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.purple[700]};
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 700;
    margin: 0 0 0.625rem;
  }

  h1.quiz {
    margin-bottom: 1.875rem;
  }

  h2 {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.purple[700]};
    font-size: 1.875rem;
    line-height: 1.3;
    font-weight: 700;
    margin: 2.5rem 0 0.3125rem;
  }

  h2.first-child {
    margin: 0 0 0.625rem;
  }

  h3 {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.purple[700]};
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 700;
    margin: 0 0 0.3125rem;
  }

  h4 {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.purple[700]};
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 700;
    margin: 0 0 0.3125rem;
  }

  p {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    margin: 0 0 1.125rem;
  }

  p.preamble {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 1.125rem;
  }

  blockquote {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    font-style: italic;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 1.25rem 1.25rem 1.25rem;
  }

  figcaption {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 0.625rem 0 0.625rem;
  }

  footer.copyright {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.grey[800]};
    font-size: 0.75rem;
    line-height: 1.2;
    font-weight: 400;
    margin: 0.5rem 0 0.625rem;
  }

  ul,
  ol {
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 0 0 1.125rem;

    li {
      font-family: ${({ theme }) => theme.fontFamilies.secondary};
      margin: 0 0 0.25rem;
    }
  }

  ul li::marker {
    color: ${({ theme }) => theme.colorTokens.purple[700]};
  }

  ul.checklist {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding-left: 1.5em;
      position: relative;

      :before {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1rem;
        background-image: url(${Checked});
        background-repeat: no-repeat;
        background-size: contain;
        left: 0;
        top: 65%;
        transform: translateY(-50%);
      }
    }
  }

  div.course-content-box {
    margin-top: 2.5rem;
    padding: 0.9375rem 1.25rem 1.25rem;
    width: max-content;

    background-color: ${({ theme }) => theme.colorTokens.purple[100]};

    border: ${({ theme }) => `0.0625rem solid ${theme.colorTokens.purple[300]}`};
    border-radius: 8px;

    > h2.course-content-box__heading {
      margin-top: 0;
      margin-bottom: 0.75rem;

      font-family: ${({ theme }) => theme.fontFamilies.secondary};
      font-size: 1.25rem;
      font-weight: 600;
    }

    > ul.course-content-box__list {
      display: grid;
      row-gap: 0.75rem;
      column-gap: 1rem;

      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      ${createMediaQuery(
        // 470 is where 2 columns is too wide with current content in course content box
        470,
        css`
          grid-template-rows: repeat(3, 1fr);
          grid-auto-flow: column;
        `
      )}

      > li {
        display: flex;
        align-items: center;
        margin: 0;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .subheading {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    color: ${({ theme }) => theme.colorTokens.grey[900]};
    font-size: 1.125rem;
    line-height: 1.3;
    font-weight: 400;
    margin: 0;

    &.over-video {
      margin: 0.625rem 0 0;
    }
  }

  ${createMediaQuery(
    767,
    css`
      h1 {
        font-size: 2.375rem;
        line-height: 1.3;
      }

      p.preamble {
        font-size: 1.375rem;
      }

      p,
      blockquote,
      ul,
      ol {
        font-size: 1.125rem;
      }

      figcaption {
        font-size: 1rem;
      }

      footer.copyright {
        font-size: 0.875rem;
      }
    `
  )}
`;

export default TextBlockStylesheet;
