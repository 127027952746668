import styled from 'styled-components';
import { NAVBAR_HEIGHT } from 'containers/NavbarAndGlobalSearchAndErrorContainer/utils';
import {
  NIK_CONTENT_CONTAINER_MARGIN_TB,
  NIK_HEADER_MARGIN_TOP,
  NIK_TOOLBAR_HEIGHT,
  NIK_MAX_CONTRIBUTORS_Z_INDEX,
} from 'pages/NIKPage/constants';

const TOC_TOP_OFFSET =
  NAVBAR_HEIGHT +
  NIK_TOOLBAR_HEIGHT +
  NIK_HEADER_MARGIN_TOP +
  NIK_CONTENT_CONTAINER_MARGIN_TB;

export const TableOfContentsCss = styled.nav`
  display: flex;
  position: fixed;
  top: ${TOC_TOP_OFFSET}px;
  left: 0;
  max-width: 330px;
  z-index: ${NIK_MAX_CONTRIBUTORS_Z_INDEX};
`;

export const TOCVisibleOverlayCss = styled.div`
  transition: all 0.3s;
`;

export const TOCHeaderTitleCss = styled.header`
  width: 100%;
  background-color: ${(props) => props.theme.colorTokens.default.white};
  font-family: ${(props) => props.theme.fontFamilies.primary};
  border-top: 1px solid ${(props) => props.theme.colorTokens.purple[300]};
  border-right: 1px solid ${(props) => props.theme.colorTokens.purple[300]};
  font-weight: 500;
  line-height: 1.575rem;
  font-size: 1.125rem;
  padding: 1rem;
`;

export const TOCPartsCss = styled.div`
  border-top: 1px solid ${(props) => props.theme.colorTokens.purple[300]};
  background-color: ${(props) => props.theme.colorTokens.purple[100]};
  border-bottom: 1px solid ${(props) => props.theme.colorTokens.purple[300]};
  border-right: 1px solid ${(props) => props.theme.colorTokens.purple[300]};
  border-bottom-right-radius: 0.25rem;
  overflow-y: scroll;
  max-height: 60vh;
  font-size: 1.125rem;

  ol {
    list-style-type: none;
    box-sizing: border-box;
    padding-inline-start: 1rem;

    li {
      width: 100%;
      padding: 0;
    }
  }

  ol:first-child {
    padding: 0.5rem;
    width: 100%;
    margin: 0;
    margin-top: 0.25rem;
  }
`;

export const TOCAsideButtonCss = styled.button<{ expanded: boolean }>`
  border: none;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colorTokens.default.white};
  border: 1px solid ${(props) => props.theme.colorTokens.purple[300]};
  border-left: none;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  align-self: start;
  transform: translateX(-1px);

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colorTokens.purpleVibrant[600]};
    font-size: 1rem;
    svg {
      margin-left: ${(props) => (props.expanded ? '0' : '0.5rem')};
      path {
        fill: ${(props) => props.theme.colorTokens.purpleVibrant[600]};
      }
    }
  }

  :hover {
    background-color: ${(props) => props.theme.colorTokens.purpleVibrant[500]};
    opacity: 1;
    cursor: pointer;
    border: 1px solid transparent;
    border-left: none;
    span {
      color: ${(props) => props.theme.colorTokens.default.white};
      svg path {
        fill: ${(props) => props.theme.colorTokens.default.white};
      }
    }
  }

  :active {
    background-color: ${(props) => props.theme.colorTokens.purpleVibrant[700]};
  }

  :focus-visible {
    background-color: ${(props) => props.theme.colorTokens.purpleVibrant[500]};
    border-left: none;
    border: 1px solid ${(props) => props.theme.colorTokens.purpleVibrant[900]};

    span {
      color: ${(props) => props.theme.colorTokens.default.white};
      svg path {
        fill: ${(props) => props.theme.colorTokens.default.white};
      }
    }
  }
`;

export const TOCListItemButtonCss = styled.button<{ highlighted?: boolean }>`
  all: unset;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  border: ${(props) =>
    props.highlighted
      ? `1px solid ${props.theme.colorTokens.purpleVibrant[500]}}`
      : '1px solid transparent'};
  border-radius: 0.2rem;

  :hover,
  :focus-visible {
    background-color: ${(props) => props.theme.colorTokens.purpleVibrant[500]};
    color: ${(props) => props.theme.colorTokens.default.white};
    cursor: pointer;
  }

  :active {
    background-color: ${(props) => props.theme.colorTokens.purpleVibrant[700]};
    color: ${(props) => props.theme.colorTokens.default.white};
    cursor: pointer;
  }
`;
