import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const ArrowRight: React.FC<IconPropTypes> = ({ size, color, height, width, ...props }) => (
  <Svg
    className="c-icon"
    width={width || size}
    height={height || size}
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-740.000000, -1261.000000)" fill={color}>
        <g transform="translate(391.000000, 1154.000000)">
          <g transform="translate(1.000000, 95.000000)">
            <g transform="translate(221.000000, 0.000000)">
              <path d="M135,18 C133.01363,16 131.027852,14 129.041481,12 L127,13.451 L131.528,18.0115 L127,22.5605 L129.041481,24 C131.023111,21.996 133.032593,20.0135 135,18 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

ArrowRight.defaultProps = {
  size: 16,
  color: COLOR_TOKENS.grey[900],
};

export default ArrowRight;
