import * as React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Quiz2: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.8563 28.8562C18.8344 29.8781 17.1656 29.8781 16.1437 28.8562L10.1437 22.8562C9.11906 21.8344 9.11906 20.1656 10.1437 19.1437C11.1656 18.1219 12.8344 18.1219 13.8563 19.1437L18 23.2875L28.1438 13.1437C29.1656 12.1219 30.8344 12.1219 31.8562 13.1437C32.8781 14.1656 32.8781 15.8344 31.8562 16.8563L19.8563 28.8562ZM0 6C0 2.68594 2.68594 0 6 0H36C39.3094 0 42 2.68594 42 6V36C42 39.3094 39.3094 42 36 42H6C2.68594 42 0 39.3094 0 36V6ZM4.5 6V36C4.5 36.825 5.17125 37.5 6 37.5H36C36.825 37.5 37.5 36.825 37.5 36V6C37.5 5.17125 36.825 4.5 36 4.5H6C5.17125 4.5 4.5 5.17125 4.5 6Z"
      fill={color}
    />
  </Svg>
);

Quiz2.defaultProps = {
  color: COLOR_TOKENS.purple[400],
};

export default Quiz2;
