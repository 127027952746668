import styled, { css } from 'styled-components';
import Flex from 'components/ui/Flex';
import { AcademyToggleOnAndOffSwitch } from '../ToggleSwitch/ToggleSwitch';

export const SettingsCss = styled.div`
  position: relative;
`;

export const SettingsOptionsCss = styled(Flex)<{ $isActive: boolean; $top: boolean }>`
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};

  background-color: ${({ theme }) => theme.colorTokens.default.white};
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.25rem;
  font-size: 0.875rem;

  position: absolute;
  z-index: 98;
  left: 1.25rem;

  ${({ $top }) =>
    $top
      ? css`
          bottom: 3.25rem;
        `
      : css`
          top: 3.25rem;
        `}

  margin-left: -1.375rem;
  box-shadow: 0 1px 3px 0 #0000004d;
  white-space: nowrap;
`;

export const AcademyToggleSwitchCss = styled(AcademyToggleOnAndOffSwitch)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colorTokens.default.white};

  :focus-visible {
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
  }

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[200]};
  }

  > span {
    margin: 0.375rem;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
  }
`;
