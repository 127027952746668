import React, { FC, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowDown from 'icons/ArrowDown';
import ArrowUp from 'icons/ArrowUp';
import { ColorTokenValues } from 'theme/config/types';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import { ButtonAndLinkContentWrapper } from '../../../../../../components/Button/ButtonAndLinkCss';
import { useClickOutsideButtonAndElementHook } from '../../useClickOutsideButtonAndElementHook';

export interface DropdownContentConfig {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
  backgroundColor?: ColorTokenValues;
}

export interface NavbarDropdownProps {
  children: ReactNode | ReactNode[];
  id: string;
  contentConfig: DropdownContentConfig;
  buttonConfig: { iconLeft: ReactNode; label: string | undefined; ariaLabel: string };
  className?: string;
}

const WrapperCss = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

export const DropdownContentCss = styled.span<DropdownContentConfig>`
  position: absolute;
  background-color: ${(props) => props.backgroundColor && props.backgroundColor};
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  top: ${({ top }) => top && top};
  bottom: ${({ bottom }) => bottom && bottom};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
`;

const DropdownButtonCss = styled.button`
  ${buttonWithoutStyle}
  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colorTokens.red[600]};
  font-weight: 600;
  border-bottom: 2px solid transparent;
  border-radius: 0;

  > span {
    margin-left: 0.5rem;
  }

  svg * {
    stroke: ${(props) => props.theme.colorTokens.red[600]};
    fill: ${(props) => props.theme.colorTokens.red[600]};
  }

  :hover {
    opacity: 1;
  }

  :focus-visible {
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colorTokens.default.black};
  }

  :hover,
  :focus-visible {
    span {
      color: ${({ theme }) => theme.colorTokens.default.black};
    }
    svg * {
      stroke: ${({ theme }) => theme.colorTokens.default.black};
      fill: ${({ theme }) => theme.colorTokens.default.black};
    }
  }
`;

export const NavbarDropdown: FC<NavbarDropdownProps> = ({ children, id, contentConfig, buttonConfig, className }) => {
  const dropDownContentRef = useRef<HTMLDivElement>(null);
  const dropDownButtonRef = useRef<HTMLButtonElement>(null);
  const [expanded, setExpanded] = useState(false);

  const dropdownButtonId = `${id}NavbarDropdownButton`;
  const dropdownContentId = `${id}NavbarDropdownContent`;

  // Lukk 'NavbarDropdown' om brukeren trykker (mouse click/enter) utenfor dropdown content eller 'dropdown'-knappen
  useClickOutsideButtonAndElementHook(dropDownContentRef, dropDownButtonRef, setExpanded);

  return (
    <WrapperCss className={className}>
      <ButtonAndLinkContentWrapper>
        <DropdownButtonCss
          onClick={() => setExpanded(!expanded)}
          aria-label={buttonConfig.ariaLabel}
          id={dropdownButtonId}
          aria-expanded={expanded}
          aria-controls={dropdownContentId}
          tabIndex={0}
          ref={dropDownButtonRef}
        >
          {buttonConfig.iconLeft} {buttonConfig.label && <span>{buttonConfig.label}</span>}
          {expanded ? <ArrowUp width={12} height={8} ml2 /> : <ArrowDown width={12} height={8} ml2 />}
        </DropdownButtonCss>
      </ButtonAndLinkContentWrapper>

      {expanded && (
        <DropdownContentCss aria-labelledby={dropdownButtonId} id={dropdownContentId} {...contentConfig} ref={dropDownContentRef}>
          {children}
        </DropdownContentCss>
      )}
    </WrapperCss>
  );
};

export default NavbarDropdown;

NavbarDropdown.defaultProps = {
  className: '',
};
