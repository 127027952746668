import React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Content: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.71429 0.5C10.0286 0.5 10.2857 0.775385 10.2857 1.11538C10.2857 1.45538 10.0286 1.73077 9.71429 1.73077H0.571429C0.255857 1.73077 0 1.45538 0 1.11538C0 0.775385 0.255857 0.5 0.571429 0.5H9.71429ZM15.4286 5.42308C15.7429 5.42308 16 5.7 16 6.03846C16 6.37692 15.7429 6.65385 15.4286 6.65385H0.571429C0.255857 6.65385 0 6.37692 0 6.03846C0 5.7 0.255857 5.42308 0.571429 5.42308H15.4286ZM0 10.9615C0 10.6231 0.255857 10.3462 0.571429 10.3462H9.71429C10.0286 10.3462 10.2857 10.6231 10.2857 10.9615C10.2857 11.3 10.0286 11.5769 9.71429 11.5769H0.571429C0.255857 11.5769 0 11.3 0 10.9615ZM15.4286 15.2692C15.7429 15.2692 16 15.5462 16 15.8846C16 16.2231 15.7429 16.5 15.4286 16.5H0.571429C0.255857 16.5 0 16.2231 0 15.8846C0 15.5462 0.255857 15.2692 0.571429 15.2692H15.4286Z"
      fill={color}
    />
  </Svg>
);

Content.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default Content;
