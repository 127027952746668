import FieldValidator, { Field } from './FieldValidator';

export interface FormErrors {
  [key: string]: string[];
}

export const validateForm = (fields: Field[]): FormErrors => {
  const valErrors = fields.reduce((acc, field) => {
    const fieldValidator = new FieldValidator(field);

    field.validations.forEach((validation) => {
      if (typeof validation === 'string') fieldValidator[validation]();

      const [validationType, opt] = validation;

      switch (validationType) {
        case 'regExp': {
          return fieldValidator[validationType](opt as RegExp);
        }
        case 'minLength': {
          return fieldValidator[validationType](opt as number);
        }
      }
    });

    return {
      ...acc,
      [field.name]: fieldValidator.getErrors(),
    };
  }, {});

  return valErrors;
};

export const resetError = (field: string, errors: FormErrors): FormErrors => {
  return { ...errors, [field]: [] };
};

export const formHasErrors = (errors: FormErrors): boolean => !!Object.values(errors).find((error) => error.length > 0);

export const mapInputValueToText = (inputValue: string | boolean): string | number => {
  if (typeof inputValue === 'string') return inputValue;

  switch (typeof inputValue) {
    case 'boolean': {
      return inputValue ? 'Ja' : 'Nei';
    }
    default: {
      return inputValue;
    }
  }
};
