import * as React from 'react';
import CustomScroll from 'react-custom-scroll';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useDimensions } from 'util/hooks/useDimensions';
import { Catch } from 'error/Catch';
import { InlineError } from 'error/InlineError';
import { Throw } from 'error/Throw';
import { LocationStateProps, usePdfReader } from '../../TextbookReaderPage/pdf/hooks/usePDFReader';

const DocumentPreviewCss = styled.div`
  padding: 1rem 1rem 2rem;
  background: ${({ theme }) => theme.colorTokens.default.white};

  .pdfViewer {
    .page {
      margin: 0 auto;
      border: none;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      .canvasWrapper {
        border: 0.0625rem solid #d6d5d6;
      }
    }
  }
`;

const DocumentPreview: React.FC<{
  documentUrl: string;
  accessToken?: string;
  idToken?: string;
}> = ({ documentUrl, accessToken, idToken }) => {
  const location = useLocation<LocationStateProps>();

  const { observe, width: readerPagePanelWidth } = useDimensions({
    breakpoints: { lg: 1280 },
  });
  const pdfPreviewRef = React.useRef<HTMLDivElement | null>(null);
  const pdfCustomScrollRef = React.useRef<{ getScrolledElement(): Element } | null>(null);

  const headers: Record<string, string> = {};
  if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
  if (idToken) headers['id-token'] = idToken;

  const pdfReaderData = usePdfReader({
    pdfUrl: documentUrl,
    mountRef: pdfPreviewRef,
    headers,
    width: readerPagePanelWidth < 850 ? readerPagePanelWidth : 850,
    scale: 1,
    page: 1,
    location,
    searchQuery: '',
    searchResults: null,
    customScrollRef: pdfCustomScrollRef,
    onFeedback: () => {},
    onUpdateLocation: () => {},
  });

  return (
    <Catch catchId="documentPreview" renderError={(props) => <InlineError {...props} />}>
      <CustomScroll heightRelativeToParent="100%" ref={pdfCustomScrollRef} allowOuterScroll>
        <DocumentPreviewCss ref={observe}>
          {pdfReaderData.juridikaError && <Throw error={pdfReaderData.juridikaError} />}
          <div className="pdfViewer" ref={pdfPreviewRef} />
        </DocumentPreviewCss>
      </CustomScroll>
    </Catch>
  );
};

export default DocumentPreview;
