import * as TypesafeActions from 'typesafe-actions';
import { Auth0DecodedHash } from 'auth0-js';

import { Tokens } from '../jwt/jwtActions';

export const checkIp = TypesafeActions.createAsyncAction('CHECKIP_REQUEST', 'CHECKIP_SUCCESS', 'CHECKIP_FAILURE')<
  void,
  { canDoIpLogin: boolean },
  { error: any }
>();

export const login = TypesafeActions.createAsyncAction('LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE')<
  void,
  Auth0DecodedHash,
  string
>();

export const loginWithIp = TypesafeActions.createAsyncAction(
  'LOGIN_WITH_IP_REQUEST',
  'LOGIN_WITH_IP_SUCCESS',
  'LOGIN_WITH_IP_FAILURE'
)<void, Auth0DecodedHash, { error: any }>();

export const refreshIpToken = TypesafeActions.createAsyncAction(
  'REFRESH_IP_TOKEN_REQUEST',
  'REFRESH_IP_TOKEN_SUCCESS',
  'REFRESH_IP_TOKEN_FAILURE'
)<void, Auth0DecodedHash, { error: any }>();

export const loginWithFeide = TypesafeActions.createAsyncAction(
  'LOGIN_WITH_FEIDE_REQUEST',
  'LOGIN_WITH_FEIDE_SUCCESS',
  'LOGIN_WITH_FEIDE_FAILURE'
)<void, Auth0DecodedHash, { error: any }>();

export const ssoLogin = TypesafeActions.createAsyncAction('SSOLOGIN_REQUEST', 'SSOLOGIN_SUCCESS', 'SSOLOGIN_FAILURE')<
  void,
  Tokens,
  { error: any }
>();

export const resetSession = TypesafeActions.createAction('RESET_SESSION');
