import * as React from 'react';
import gql from 'graphql-tag';
import { AuthenticationStatus } from 'state/login/types';
import * as gqlResult from '../graphql/GqlResult';
import { CustomerInfo } from '../../models/customerInfo/CustomerInfo';
import { GqlError } from '../graphql/GqlError';
import { useLazyApolloQuery } from './useApolloQuery';

export const CUSTOMER_INFO_QUERY = gql`
  query CustomerInfo {
    customerInfo {
      node {
        customerId
        customerName
        customerNumber
        subscriptionStatusName
        subscriptionPackageName
        products {
          key
          name
          type
        }
        potentialUsers
        marketCategoryName
      }
      error {
        type
      }
    }
  }
`;

export interface GqlCustomerInfoResult {
  customerInfo: {
    node: CustomerInfo;
    error: GqlError | null;
  };
}

export const useCustomerInfoQuery = (authStatus: AuthenticationStatus): gqlResult.Result<CustomerInfo> | null => {
  const [execute, queryResult] = useLazyApolloQuery<GqlCustomerInfoResult, null>(CUSTOMER_INFO_QUERY);
  React.useEffect(() => {
    if (authStatus === AuthenticationStatus.AUTHENTICATED && !queryResult.called) {
      execute();
    }
  }, [authStatus, execute, queryResult]);
  const result = queryResult.called
    ? gqlResult.of(queryResult).flatMapOk<CustomerInfo>((data) => {
        if (data.customerInfo?.error) {
          return gqlResult.gqlError(data.customerInfo.error);
        }
        return gqlResult.ok(data.customerInfo.node);
      })
    : null;
  return result;
};
