import { ApolloError } from '@apollo/client';

export enum JuridikaErrorType {
  REST_UNAUTHENTICATED = 'REST_UNAUTHENTICATED',
  REST_UNAUTHORIZED = 'REST_UNAUTHORIZED',
  REST_NOT_FOUND = 'REST_NOT_FOUND',
  REST = 'REST',
  LOGIN = 'LOGIN',
  GRAPHQL_UNAUTHENTICATED = 'GRAPHQL_UNAUTHENTICATED',
  GRAPHQL_UNAUTHORIZED = 'GRAPHQL_UNAUTHORIZED',
  GRAPHQL_NOT_FOUND = 'GRAPHQL_NOT_FOUND',
  GRAPHQL_BAD_REQUEST = 'GRAPHQL_BAD_REQUEST',
  GRAPHQL_SERVER = 'GRAPHQL_SERVER',
  GRAPHQL_LOADING = 'GRAPHQL_LOADING',
  GRAPHQL_NOT_CALLED = 'GRAPHQL_NOT_CALLED',
  GRAPHQL_UNKNOWN = 'GRAPHQL_UNKNOWN',
  APOLLO = 'APOLLO',
  ROUTE_NOT_FOUND = 'ROUTE_NOT_FOUND',
  EMAIL_VALIDATION_FAILED = 'EMAIL_VALIDATION_FAILED',
  MISSING_PDF_EXCEPTION = 'MISSING_PDF_EXCEPTION',
}

interface JuridikaErrorWithoutParams {
  type:
    | JuridikaErrorType.GRAPHQL_UNAUTHENTICATED
    | JuridikaErrorType.GRAPHQL_UNAUTHORIZED
    | JuridikaErrorType.GRAPHQL_NOT_FOUND
    | JuridikaErrorType.GRAPHQL_BAD_REQUEST
    | JuridikaErrorType.GRAPHQL_UNKNOWN
    | JuridikaErrorType.GRAPHQL_SERVER
    | JuridikaErrorType.GRAPHQL_LOADING
    | JuridikaErrorType.GRAPHQL_NOT_CALLED
    | JuridikaErrorType.LOGIN
    | JuridikaErrorType.EMAIL_VALIDATION_FAILED;
}

export interface JuridikaRestError {
  type:
    | JuridikaErrorType.REST_UNAUTHENTICATED
    | JuridikaErrorType.REST_UNAUTHORIZED
    | JuridikaErrorType.REST_NOT_FOUND
    | JuridikaErrorType.REST
    | JuridikaErrorType.MISSING_PDF_EXCEPTION;
  url: string;
  statusCode: number;
  message: string;
}

interface JuridikaApolloError {
  type: JuridikaErrorType.APOLLO;
  apolloError: ApolloError;
}

interface JuridikaRouteNotFoundError {
  type: JuridikaErrorType.ROUTE_NOT_FOUND;
  pathname: string;
}

export type JuridikaError = JuridikaErrorWithoutParams | JuridikaRestError | JuridikaApolloError | JuridikaRouteNotFoundError;
