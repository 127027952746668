import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Lock: React.FC<IconPropTypes> = ({ width, height, color, title, ...props }) => (
  <Svg
    className="c-icon c-icon-lock"
    width={width}
    height={height}
    viewBox="0 0 14 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{title}</title>
    <defs />
    <g id="Sider" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Søkeresultat-desktop" transform="translate(-748.000000, -997.000000)" fillRule="nonzero" fill={color}>
        <g id="resultat/normal/kommentarutgave" transform="translate(486.000000, 969.000000)">
          <g id="locked">
            <g transform="translate(262.000000, 28.000000)">
              <g id="icon_locked">
                <path d="M9.48529412,7.37561642 L9.48529412,4.78064364 C9.48529412,3.02402182 8.14873241,1.59999847 6.5,1.59999847 C4.85126759,1.59999847 3.51470588,3.02402182 3.51470588,4.78064364 L3.51470588,7.39999847 L9.48529412,7.37561642 Z M2,7.45457405 L2,4.69390467 C2,2.21199007 4.01471863,0.200000763 6.5,0.200000763 C8.98528137,0.200000763 11,2.21199007 11,4.69390467 L11,7.42194119 L11.0041999,7.42194119 C12.3272638,7.42194119 13.399819,8.4944347 13.399819,9.82226647 L13.399819,15.5996747 C13.399819,16.9253378 12.3259066,18 11.0041999,18 L2.3956191,18 C1.0725552,18 0,16.9275065 0,15.5996747 L0,9.82226647 C0,8.63174674 0.866115328,7.64366034 2,7.45457405 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

Lock.defaultProps = {
  color: COLOR_TOKENS.grey[600],
  width: 14,
  height: 18,
  title: 'locked',
};

export default Lock;
