import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { RootState } from 'state/types';
import { useSelector } from 'util/hooks/useSelector';
import createMediaQuery from 'util/createMediaQuery';
import { NAVBAR_VIEWPORT_SIZE } from 'containers/NavbarAndGlobalSearchAndErrorContainer/utils';
import Avatar from '../../../../../../components/icons/Avatar';
import NavbarDropdown from './NavbarDropdown';
import CurrentUserDropdownOptions from './CurrentUserDropdownOptions';

const BUTTON_TEXT_MAX_WIDTH_UP_TO_XL_SCREEN = '12rem';
const BUTTON_TEXT_MAX_WIDTH_ABOVE_XL_SCREEN = '20rem';

const ContentWrapperCss = styled.div`
  padding: 1rem;
  min-width: 6.875rem;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  span {
    color: ${(props) => props.theme.colorTokens.red[600]};
  }
  svg {
    fill: ${(props) => props.theme.colorTokens.red[600]};
  }
`;

const NavbarDropdownCss = styled(NavbarDropdown)<{ $userButtonTextMaxWidthOtherwiseElipsis: string }>`
  ${({ $userButtonTextMaxWidthOtherwiseElipsis }) =>
    $userButtonTextMaxWidthOtherwiseElipsis &&
    css`
      ${createMediaQuery(
        'lg',
        css`
          > span > button {
            /* This targets the span that contains the "Hei, [name]" text inside the user dropdown button. */
            > span:first-of-type {
              display: block;

              max-width: ${$userButtonTextMaxWidthOtherwiseElipsis};
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            /* This targets the span that contains the down arrow icon inside the user dropdown button. */
            > span:last-of-type {
              display: block;
              margin-top: 0;
            }
          }
        `
      )}
    `}
`;

const CurrentUserDropdown: FC<{
  hasBeigeBackground: boolean;
}> = ({ hasBeigeBackground }) => {
  const viewportSize = useViewportSize(NAVBAR_VIEWPORT_SIZE, 'small');
  const isExtraLargeViewport = viewportSize === 'xlarge';
  const isLargeViewport = viewportSize !== 'small' && viewportSize !== 'medium';
  const currentUser = useSelector((state: RootState) => state.currentUser);

  return (
    <NavbarDropdownCss
      $userButtonTextMaxWidthOtherwiseElipsis={
        isExtraLargeViewport ? BUTTON_TEXT_MAX_WIDTH_ABOVE_XL_SCREEN : BUTTON_TEXT_MAX_WIDTH_UP_TO_XL_SCREEN
      }
      id="CurrentUser"
      buttonConfig={{
        iconLeft: <Avatar />,
        label: isLargeViewport ? `Hei, ${currentUser.name}` : undefined,
        ariaLabel: 'Åpne brukermeny',
      }}
      contentConfig={{
        top: '2.5rem',
        right: '0',
        backgroundColor: hasBeigeBackground ? COLOR_TOKENS.peach[300] : COLOR_TOKENS.default.white,
      }}
    >
      <ContentWrapperCss>
        <CurrentUserDropdownOptions />
      </ContentWrapperCss>
    </NavbarDropdownCss>
  );
};

export default CurrentUserDropdown;
