import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import WidthConstraints from 'components/WidthConstraints';
import QuoteSection from 'pages/HomePage/components/QuoteSection';
import { NIK_VIEWPORT_SIZE } from './Document';

export const NeverBeforePublishedTextCss = styled.span`
  background-color: ${({ theme }) => theme.colorTokens.red[100]};
`;

export const DraftTextCss = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.red[100]};
  color: ${({ theme }) => theme.colorTokens.red[900]};
  padding: 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const NIKContentContainerCss = styled(WidthConstraints)`
  display: flex;
  flex-direction: column;

  ${createMediaQuery(
    NIK_VIEWPORT_SIZE.large,
    css`
      display: grid;
      grid-template-columns: [toc-start] minmax(0, 400px) [content-start] 575px [resource-boxes-start] 416px;
      justify-content: center;
      gap: 4rem;
    `
  )}
`;

export const DocumentContainerCss = styled.article`
  max-width: 575px;
  margin: 0 auto;

  ${createMediaQuery(
    NIK_VIEWPORT_SIZE.large,
    css`
      margin: initial;
      grid-column-start: content-start;
    `
  )}
`;

export const BodyCss = styled.div`
  details {
    margin: 15px 0;
  }

  color: ${({ theme }) => theme.colors.text};

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-weight: 500;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 139%;
    letter-spacing: 0.01875rem;
  }
`;

export const BylineCss = styled.p`
  margin: 16px 0;
  display: flex;
  flex-direction: column;

  span {
    margin: 0;
    margin-bottom: 8px;
  }

  span:first-of-type {
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    color: ${({ theme }) => theme.colorTokens.red[600]};
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: normal;
    font-size: 1rem;
  }

  span:last-of-type {
    color: ${({ theme }) => theme.colorTokens.purple[900]};
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 130%;
  }
`;

export const TableWrapperCss = styled.div`
  max-width: 575px;
  overflow-x: scroll;
  margin-bottom: 1rem;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
    text-align: left;
    overflow: scroll;
  }

  table tbody {
    border-collapse: collapse;
  }

  table caption {
    font-weight: bold;
    margin: 10px 0;
  }

  table tr:first-child {
    border-top: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
  }

  table tbody tr td {
    padding: 7px 10px 10px 10px;
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
  }

  table tbody tr:hover {
    background-color: ${({ theme }) => theme.colorTokens.purple[100]};
  }

  table thead tr th {
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.414375rem;
    padding: 7px 10px 10px 10px;
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
  }

  table thead tr {
    background-color: ${({ theme }) => theme.colorTokens.purple[100]};
    :hover {
      background-color: ${({ theme }) => theme.colorTokens.purple[200]};
    }
  }
`;

export const FootNoteBoxContentCss = styled.span`
  summary {
    display: none !important;
  }
`;

export const LoaderWrapperCss = styled.div`
  max-width: 575px;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
`;

export const DateLastUpdatedCss = styled.p`
  background-color: ${({ theme }) => theme.colorTokens.purpleVibrant[100]};
  color: ${({ theme }) => theme.colorTokens.purple[900]};
  display: inline-block;
  padding: 8px 6px;
  border-radius: 4px;
  margin: 0;
  line-height: 139%;
  letter-spacing: 0.01875rem;
  font-size: 0.875rem;
`;

export const TitleCss = styled.h1`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: 500;
  margin-top: 0;
`;

export const HeaderCss = styled.header`
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const NIKQuoteSectionCss = styled(QuoteSection)`
  background-color: #eae0f5;

  blockquote {
    color: ${({ theme }) => theme.colorTokens.default.black};
  }

  svg path {
    fill: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  }
`;
