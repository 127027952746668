import { DomAllocator } from 'util/dom/domAllocator';
import { DomBaseProps, DomNode } from '../models/dom/domNode';
import * as jsonml from '../models/JsonML';

import { cssStringToObject } from './styling';

// Keys
export const ID = Symbol('id');
export const CLASSNAME = Symbol('className');
export const TAGNAME = Symbol('tagname');
export const HTML_ATTRIBUTES = Symbol('htmlAttributes');

interface HypertextProps extends DomBaseProps<HypertextProps> {
  [TAGNAME]: string;
  [HTML_ATTRIBUTES]: {
    id: string;
    eid: string;
    className: string;
    href: string;
    src: string;
    style: Record<string, string>;
    tag?: string;
    'data-type'?: string;
    type?: string;
    cite?: string;
    itemType?: string;
  };
}

export type HypertextNode = DomNode<HypertextProps>;

export class HypertextAllocator {
  private domAllocator: DomAllocator<HypertextProps, unknown>;

  constructor() {
    this.domAllocator = new DomAllocator({
      initialState: {} as unknown,
      enrichNode: (baseNode) => {
        return baseNode as unknown as HypertextNode;
      },
      createElement: (baseNode, tagName, attrs) => {
        const node = baseNode as unknown as HypertextNode;
        node[TAGNAME] = tagName;

        if (attrs) {
          const getAttr = (key: string): string => {
            const attr = attrs[key];
            if (typeof attr !== 'string') {
              return `${attr}`;
            }
            return attr;
          };

          for (const key of Object.keys(attrs)) {
            switch (key) {
              case 'eId': {
                node[HTML_ATTRIBUTES] = {
                  ...node[HTML_ATTRIBUTES],
                  eid: getAttr(key),
                };
                break;
              }
              case 'style': {
                const styleObject = cssStringToObject(getAttr(key));

                if (styleObject) {
                  node[HTML_ATTRIBUTES] = {
                    ...node[HTML_ATTRIBUTES],
                    style: styleObject,
                  };
                }
                break;
              }
              default: {
                node[HTML_ATTRIBUTES] = {
                  ...node[HTML_ATTRIBUTES],
                  [key]: getAttr(key),
                };
              }
            }
          }
        }
        return node;
      },
    });
  }

  jsonmlFragmentToNode = (input: jsonml.JsonMLNode | null): HypertextNode => {
    return this.domAllocator.jsonmlFragmentToNode(input);
  };

  emptyFragment = (): HypertextNode => {
    return this.domAllocator.emptyFragment();
  };
}
