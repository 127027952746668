import React, { FC, FunctionComponent, ReactNode } from 'react';
import Lock from 'icons/Lock';
import Flex from 'components/ui/Flex';
import { Email } from 'components/icons/SocialIcons/SocialMedia';
import Phone2 from 'components/icons/Phone2';
import { mailToLink, SUPPORT_EMAIL } from 'util/mailToLink';
import Button from 'components/ui/Button';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { useDimensions } from 'util/hooks/useDimensions';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setValue } from 'state/ui/uiActions';
import { LockedProps } from './types';
import {
  AccessDeniedCss,
  ChatButtonCss,
  FooterCss,
  FooterItemCss,
  LockedSimpleCss,
  MessageCss,
  MessageSimpleCss,
  TitleCss,
} from './styles';

interface LockedSimpleProps {
  children?: ReactNode | ReactNode[];
  className?: string;
}

export const LockedSimple: FunctionComponent<LockedSimpleProps> = ({ children, className = '' }) => (
  <LockedSimpleCss className={className}>
    <Lock mr2 color={COLOR_TOKENS.grey[600]} />
    <MessageSimpleCss>{children}</MessageSimpleCss>
  </LockedSimpleCss>
);

export const DeniedFrontlistAccessSimple: React.FC<{ className?: string; publicationYear: number }> = ({
  className = '',
  publicationYear,
}) => (
  <LockedSimple className={className}>
    Denne boken er ny og omfattes av fastprisbestemmelsen i Bokavtalen. Boken blir inkludert i abonnementet ditt fra og med 1. mai{' '}
    {publicationYear + 1}.
    <br />
    <br />
    For å få tilgang til nye utgivelser samme dag som de publiseres må organisasjonen din oppgradere sitt abonnement.
  </LockedSimple>
);

export const DeniedPackageAccessSimple: FC<{ className?: string }> = ({ className = '' }) => (
  <LockedSimple className={className}>Ikke tilgjengelig i ditt abonnement. Be ditt firmas administrator om tilgang.</LockedSimple>
);

export const DeniedAuthAccessSimple: FC<{ className?: string }> = ({ className = '' }) => (
  <LockedSimple className={className}>Logg inn for å få tilgang til ditt innhold.</LockedSimple>
);

export const DeniedAccessDefault: FC<{ className?: string }> = ({ className = '' }) => (
  <Locked title="Ikke inklu&shy;dert i abonne&shy;mentet" className={className}>
    <p>Du mangler de rette tilgangene for å vise dette innholdet</p>
    <p>Ditt firmas administrator kan kontakte oss for å bestille dette.</p>
  </Locked>
);

export const WorkHasNotBeenTagged: FC<{ className?: string }> = ({ className = '' }) => {
  // eslint-disable-next-line no-console
  console.warn('INTERN MELDING: VERKET MANGLER TAGS \n\n Ta kontakt med innholdsteamet for å legge riktig tag på verket');
  return <DeniedAccessDefault className={className} />;
};

export const DeniedBacklistAccess: FC<{ className?: string; children?: ReactNode }> = ({ className = '' }) => (
  <Locked className={className} title="Ikke inklu&shy;dert i abonne&shy;mentet">
    <p>For å få tilgang til dette innholdet må du ha tilgang på fagbøker.</p>
  </Locked>
);

export const DeniedFrontlistAccess: FC<{
  className?: string;
  originallyPublishedAt: string | undefined;
  packages: string[];
}> = ({ className = '', originallyPublishedAt = '2025-01-01', packages }) => {
  const publicationYear = new Date(originallyPublishedAt).getFullYear();
  const packagesString = (
    <>
      pakken{packages.length > 1 && 'e'} {renderListOfRequiredPackages(packages)}
    </>
  );

  return (
    <Locked title="Ikke inklu&shy;dert i abonne&shy;mentet" className={className}>
      <p>
        Denne utgaven er ny og omfattes derfor av fastprisbestemmelsen i bokavtalen. Utgaven vil bli inkludert i {packagesString}{' '}
        og <em>Juridika Premium</em> fra 1. mai {publicationYear + 1}.
      </p>
      <p>Eldre utgaver er tilgjengelig lenger ned på siden, forutsatt at du har abonnement på riktig pakke. </p>
      <p>
        For å få tilgang til nye utgivelser samme dag som de publiseres, må du ha abonnement på pakken <em>Nye fagbøker</em>. Ditt
        firmas administrator kan kontakte oss for å bestille dette.
      </p>
    </Locked>
  );
};

export const Locked: FC<LockedProps> = ({ title, children, className = '' }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const arrayOfLocation = location.pathname.split('/');
  const isJournalArticle = arrayOfLocation.includes('tidsskrifter') && arrayOfLocation.length > 3;

  const useWhiteBackgroundColor = (): boolean => isJournalArticle;

  const mailTo = mailToLink();

  const openChat = () => {
    dispatch(setValue('maximized', ['chat', 'visibility']));
  };

  const { observe, width } = useDimensions({
    onResize: ({ observe }) => {
      observe();
    },
  });

  return (
    <AccessDeniedCss className={className} ref={observe} $whiteBackgroundColor={useWhiteBackgroundColor()} $isSmall={width < 300}>
      <Flex align="flex-start" mb3>
        <Lock title="Dette innholdet er ikke i ditt abonnement" color={COLOR_TOKENS.red[500]} width={18} height={24} mr3 />
        <TitleCss>{title}</TitleCss>
      </Flex>
      <MessageCss>{children}</MessageCss>
      <FooterCss justify="space-between" column $isLarge={width > 500}>
        <FooterItemCss align="center">
          <Phone2 color={COLOR_TOKENS.purple[600]} height={24} mr3 /> <span>24 14 75 00</span>
        </FooterItemCss>
        <FooterItemCss align="center">
          <Email color={COLOR_TOKENS.purple[600]} height={24} mr3 /> <a href={mailTo}>{SUPPORT_EMAIL}</a>
        </FooterItemCss>
        <FooterItemCss align="center">
          <Button
            as={ChatButtonCss}
            iconLeft={{ name: 'Chat', color: COLOR_TOKENS.purple[600], size: 24 }}
            label="Chat med kundeservice"
            onClick={openChat}
          />
        </FooterItemCss>
      </FooterCss>
    </AccessDeniedCss>
  );
};

export const renderListOfRequiredPackages = (packages: string[]): ReactNode =>
  packages.map((packageName) => (
    <>
      <em key={packageName}>{packageName}</em>,{' '}
    </>
  ));

export const DeniedPackageAccess: FC<{
  className?: string;
  packages?: string[];
  customMessage?: string;
}> = ({ className = '', packages, customMessage }) => {
  const deniedMessage = packages?.length ? (
    <>
      For å få tilgang til dette innholdet må du ha abonnement på virksomhetsområde{packages.length > 1 ? 'ne' : 't'}{' '}
      {renderListOfRequiredPackages(packages)} eller <em>Juridika Premium</em>.
    </>
  ) : (
    customMessage
  );

  return (
    <Locked title="Ikke inklu&shy;dert i abonne&shy;mentet" className={className}>
      <p>{deniedMessage} </p>
      <p>Ditt firmas administrator kan kontakte oss for å bestille dette.</p>
    </Locked>
  );
};
