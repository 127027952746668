import * as React from 'react';

import CheckboxIntermediate from 'icons/CheckboxIntermediate';
import CheckboxOff from 'icons/CheckboxOff';
import CheckboxOn from 'icons/CheckboxOn';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const CheckboxSvg: React.FC<{ isChecked?: boolean; isIndeterminate?: boolean; color?: string }> = ({
  isChecked,
  isIndeterminate,
  color,
}) => {
  if (isChecked) {
    return <CheckboxOn size={20} color={color} />;
  }

  if (isIndeterminate) {
    return <CheckboxIntermediate />;
  }

  return <CheckboxOff size={20} />;
};

CheckboxSvg.defaultProps = {
  color: COLOR_TOKENS.grey[900],
};

export default CheckboxSvg;
