import * as React from 'react';

import { FlexParentWrapper, FlexChildLabelWrapper, FlexChildInputWrapper, StyledTextArea } from './ComponentsCss';
import { TextareaType } from './types';

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaType>((props) => {
  const {
    label,
    paddingConfig,
    hasBorderRadius,
    leftIcon,
    rightIcon,
    borderColorVariant = 'primary',
    inputErrorMessage,
    maxHeight,
    isResizable,
    ...inputAttrs
  } = props;
  return (
    <FlexParentWrapper>
      {!!label && (
        <FlexChildLabelWrapper>
          <label htmlFor={label}>{label}</label>
        </FlexChildLabelWrapper>
      )}
      <FlexChildInputWrapper>
        <StyledTextArea
          paddingConfig={paddingConfig}
          borderColorVariant={borderColorVariant}
          hasBorderRadius={hasBorderRadius}
          hasError={!!inputErrorMessage}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          maxHeight={maxHeight}
          isResizable={isResizable}
          {...inputAttrs}
        />
      </FlexChildInputWrapper>
    </FlexParentWrapper>
  );
});

export default Textarea;
