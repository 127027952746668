import React, { FC, ElementType } from 'react';
import styled from 'styled-components';

import createSpacing from 'util/createSpacing';
import SpacingPropTypes from 'models/SpacingPropTypes';

export interface SvgPropTypes extends SpacingPropTypes {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  as?: ElementType;
  size?: number;
  width?: number;
  height?: number;
  viewBox?: string;
  color?: string;
  xmlns?: string;
  fill?: string;
  y?: number;
  x?: number;
  ariaLabel?: string;
}

const SvgCss = styled.svg<SvgPropTypes>`
  ${(props) => createSpacing(props)}
`;

const Svg: FC<SvgPropTypes> = ({ className, ariaLabel, ...props }) => (
  <SvgCss className={className} aria-label={ariaLabel} {...props} />
);

export default Svg;
