import React from 'react';
import styled, { css } from 'styled-components';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import Button from './ui/Button';

export const ReadMoreButtonWrapperCss = styled.div<{ $redIconOnHover: boolean }>`
  display: flex;
  justify-content: center;

  button {
    font-weight: 600;
  }

  ${({ $redIconOnHover, theme }) =>
    $redIconOnHover &&
    css`
      button:hover {
        svg * {
          fill: ${theme.colorTokens.red[600]};
        }
      }
    `}
`;

const ButtonCss = styled(Button)<{ $transform?: boolean }>`
  svg {
    // looks better when transformed
    ${({ $transform }) => $transform && `transform: rotate(90deg);`}
    margin-left: 0.5rem;
  }
`;

const ReadMoreAndCloseButtons: React.FC<{
  displayCloseAction: boolean;
  displayLoadMoreAction: boolean;
  onLoadMore: () => void;
  onClose: () => void;
  redIconsOnHover?: boolean;
  labelColor?: string;
  className?: string;
}> = ({ displayCloseAction, displayLoadMoreAction, onLoadMore, onClose, redIconsOnHover, labelColor, className = '' }) => (
  <ReadMoreButtonWrapperCss $redIconOnHover={!!redIconsOnHover} className={className}>
    {displayLoadMoreAction && (
      <ButtonCss
        $transform
        p0
        mr3
        onClick={onLoadMore}
        label="Vis flere"
        labelColor={labelColor}
        // todo: create a new arrowDown component
        iconRight={{ name: 'ArrowRight', width: 8, height: 12, color: labelColor ?? COLOR_TOKENS.default.black }}
      />
    )}
    {displayCloseAction && (
      <ButtonCss onClick={onClose} label="Lukk" iconRight={{ name: 'Cross', size: 10, color: COLOR_TOKENS.default.black }} />
    )}
  </ReadMoreButtonWrapperCss>
);

export default ReadMoreAndCloseButtons;
