import React, { FC, useEffect, useState, KeyboardEvent, memo, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useViewportSize } from 'util/hooks/useViewportSize';
import VideoPlay from 'components/icons/VideoPlay';
import Flex from 'components/ui/Flex';
import Time from 'components/icons/Time';
import Headphones from 'components/icons/Headphones';
import Book2 from 'components/icons/Book2';
import Quiz2 from 'components/icons/Quiz2';
import { AKADEMI_PATH } from 'pages/AcademyPage';
import { ACADEMY_VIEWPORT_SIZE, secondsToMMSS, useUserHasAkademiBeta } from 'pages/AcademyPage/utils';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { CourseContentContext } from 'pages/AcademyPage/utils/academyCourseContentContext';
import { isCourseModulesBeforeFinalTestCompletedSelector } from 'state/selectors';
import { RootState } from 'state/types';
import Diploma from 'components/icons/Diploma';
import { DeniedPackageAccessSimple } from 'components/AccessDenied/AccessDenied';
import { Chapter, Format, SidebarModule } from '../../../types';
import { ModuleCss, DescriptionCss, DetailsCss, TitleCss, DescriptionContainerCss, ImageCss } from './Styles';
import { useScrollActiveModuleIntoViewRef } from './useScrollActiveModuleIntoViewRef';
import { SidebarLockCss } from '../Chapter/Styles';
import { ModuleProgressbar } from '../../AcademyProgressbar/AcademyProgressbar';

export interface ModuleProps {
  moduleData: SidebarModule & { course: string };
  close: () => void;
  isActive: boolean;
  sidebarWidth: number;
}

const VIEWPORT_SIZE = {
  small: 0,
  large: 375,
};

const Module: FC<ModuleProps> = memo(({ moduleData, close, isActive, sidebarWidth }) => {
  const academyViewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');
  const location = useLocation();
  const history = useHistory();
  const [chapter, setChapter] = useState<Chapter | undefined>();
  const courseContent = useContext(CourseContentContext);
  const hasAkademiBeta = useUserHasAkademiBeta();

  const isCourseModulesBeforeTestCompleted = useSelector((state: RootState) =>
    isCourseModulesBeforeFinalTestCompletedSelector(state, courseContent)
  );

  const isFinalTestCompleted = useSelector((state: RootState) => state.ui.akademi.isFinalTestCompleted);

  const isModuleLocked =
    (moduleData.format === Format.QUIZ && !isCourseModulesBeforeTestCompleted) ||
    (moduleData.format === Format.DIPLOMA && !isFinalTestCompleted);

  useEffect(() => {
    fetch(`/api/academy/chapter/${moduleData.inChapter}`).then((res) =>
      res.json().then((res) => {
        setChapter(res);
      })
    );
  }, [moduleData.inChapter]);

  const rootDivRef = useScrollActiveModuleIntoViewRef(isActive);

  const redirectToModule = () => {
    const module = moduleData.slug;

    const currentCourse = moduleData.course;
    const currentChapter = location.pathname.split('/').at(-2);
    const currentModule = location.pathname.split('/').at(-1);

    const oldSlug = `${currentChapter}/${currentModule}`;
    const newSlug = chapter?.slug === 'intro' ? module : `${chapter?.slug}/${module}`;

    if (oldSlug === newSlug && academyViewportSize !== 'large') {
      close();
    }

    history.push({
      pathname: `/${AKADEMI_PATH}/kurs/${currentCourse}/${newSlug}`,
      search: location.search,
    });
    if (academyViewportSize !== 'large') close();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      redirectToModule();
    }
  };

  const handleOnClick = (): void => {
    redirectToModule();
  };

  return (
    <ModuleCss
      $disabled={!hasAkademiBeta || isModuleLocked}
      ref={rootDivRef}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      $isActive={isActive}
    >
      <ModuleContent data={moduleData} sidebarWidth={sidebarWidth} isModuleLocked={!hasAkademiBeta || isModuleLocked} />
      {moduleData.format !== Format.DIPLOMA && moduleData.format !== Format.QUIZ && hasAkademiBeta && (
        <ModuleProgressbar moduleId={moduleData.id} />
      )}
    </ModuleCss>
  );
});

export default Module;

const ModuleContent: FC<{ data: SidebarModule; sidebarWidth: number; isModuleLocked: boolean }> = memo(
  ({ data, sidebarWidth, isModuleLocked }) => {
    const viewportSize = useViewportSize(VIEWPORT_SIZE, 'small');
    const hasAkademiBeta = useUserHasAkademiBeta();

    const description = () => {
      if (data.format === Format.QUIZ && isModuleLocked) {
        return 'Denne modulen er låst inntil du har fullført kursinnholdet.';
      }

      if (data.format === Format.DIPLOMA && isModuleLocked) {
        return 'Denne modulen er låst inntil du har fullført quizen.';
      }
      return data.desc;
    };

    return (
      <>
        <Flex align={data.format === Format.QUIZ || data.format === Format.DIPLOMA ? 'center' : 'stretch'}>
          {(() => {
            switch (data.format) {
              case Format.VIDEO:
                return viewportSize !== 'large' ? (
                  <VideoPlay aria-label="Videoinnhold" />
                ) : sidebarWidth < VIEWPORT_SIZE.large ? (
                  <VideoPlay aria-label="Videoinnhold" />
                ) : (
                  // TODO: Create separate type for video module where thumbnail is required
                  // data.thumbnail as string is a temporary fix
                  <ImageCss src={data.thumbnail as string} aria-label="Videoinnhold" />
                );
              case Format.AUDIO:
                return <Headphones aria-label="Audioinnhold" />;
              case Format.TEXT:
                return <Book2 aria-label="Tekstinnhold" />;
              case Format.QUIZ:
                return (
                  <>
                    <Quiz2 />
                    {isModuleLocked && <SidebarLockCss ml2 color={COLOR_TOKENS.grey[700]} />}
                  </>
                );

              case Format.DIPLOMA:
                return (
                  <>
                    <Diploma />
                    {isModuleLocked && <SidebarLockCss ml2 color={COLOR_TOKENS.grey[700]} />}
                  </>
                );
            }
          })()}

          <Flex column flex="1" ml2 justify="space-between">
            <TitleCss>{data.title}</TitleCss>
            {data.format !== Format.DIPLOMA && data.format !== Format.QUIZ && (
              <DetailsCss justify="space-between" mt1>
                <span>Modul {data.part}</span>
                <Flex align="center">
                  <Time m1 size={14} />
                  <span>{secondsToMMSS(data.durationInSeconds)}</span>
                </Flex>
              </DetailsCss>
            )}
          </Flex>
        </Flex>

        {viewportSize === 'large' && description() !== '' && (
          <DescriptionContainerCss>
            <DescriptionCss>{hasAkademiBeta ? description() : <DeniedPackageAccessSimple />}</DescriptionCss>
          </DescriptionContainerCss>
        )}
      </>
    );
  }
);
