export function concatArrays<T>(a: Array<T>, b: Array<T>): Array<T> {
  return a.concat(b);
}

export function ensureIsArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function arraysEqual<T>(a0: T[], a1: T[]): boolean {
  if (a0.length === a1.length) {
    for (let i = 0; i < a0.length; i += 1) {
      if (a0[i] !== a1[i]) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function removeAtIndex<T>(arr: Array<T>, index: number): Array<T> {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}
