import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Phone2: React.FC<IconPropTypes> = ({ size, color, height, width, ...props }) => (
  <Svg width={width} height={height} viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.45587 1.31406L7.31837 3.33906C7.69337 4.22499 7.48244 5.26093 6.80275 5.90781L5.19493 7.43124C5.01681 7.60468 4.92306 7.85312 4.96056 8.10156C5.11994 9.03906 5.46212 9.96718 5.99181 10.8812C6.52619 11.7953 7.16837 12.5734 7.93243 13.2156C8.134 13.3844 8.41525 13.4266 8.66368 13.3422L10.5387 12.7187C11.4012 12.4328 12.3387 12.7609 12.8684 13.5391L14.1012 15.3484C14.7152 16.2531 14.6074 17.5 13.8434 18.2641L13.0277 19.0844C12.2121 19.9047 11.0496 20.2 9.9715 19.8625C7.43556 19.0703 5.10118 16.7219 2.96837 12.8078C0.835559 8.89374 0.0855589 5.57031 0.713685 2.84218C0.976185 1.69374 1.79181 0.779681 2.86056 0.437494L3.93868 0.0953064C4.9465 -0.228132 6.02462 0.292181 6.45587 1.31406Z"
      fill={color}
    />
  </Svg>
);

Phone2.defaultProps = {
  color: COLOR_TOKENS.default.black,
  height: 20,
  width: 15,
};

export default Phone2;
