import { css, FlattenInterpolation, ThemeProps, DefaultTheme } from 'styled-components';
import { BreakpointsType, ThemeType } from 'theme/config/types';

export default function createMediaQuery(
  breakpoint: keyof BreakpointsType | number,
  styles: FlattenInterpolation<ThemeProps<DefaultTheme & ThemeType>>
): FlattenInterpolation<ThemeProps<DefaultTheme & ThemeType>> {
  return css`
    ${(props) => {
      const bp = typeof breakpoint === 'number' ? breakpoint : props.theme.breakpoints[breakpoint];

      return css`
        @media all and (min-width: ${bp}px) {
          ${styles};
        }
      `;
    }}
  `;
}
