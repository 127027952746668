/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Location } from 'history';
import { createLogger } from 'commonUtils/log';
import { JuridikaError, JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import { isPDFDocumentError } from 'models/CustomErrorMessages';
import { PdfDocument, PdfLinkService, PdfLocationParams, PdfViewer } from '../models/pdfTypes';
import { PDFJSViewer, ContinuousPdfViewer } from '../utils/PDFJSViewer';
import PDFCustomFindController from '../utils/PDFCustomFindController';
import { GqlLiteratureEditionSearchResult } from '../../literatureEditionSearchTypes';

export interface LocationStateProps {
  lazy: boolean;
}

const log = createLogger('pdf');

// for possible types see: https://github.com/mozilla/pdf.js/blob/master/src/display/api.js
declare const PDFJS: {
  getDocument: (src: unknown) => Promise<PdfDocument>;
};

export interface CustomScrollAPI {
  getScrolledElement(): Element;
}

export interface Args {
  pdfUrl: string;
  headers: Record<string, string>;
  width: number;
  scale: number;
  page: number;
  location: Location<LocationStateProps>;
  searchQuery: string;
  searchResults: GqlLiteratureEditionSearchResult | null;
  customScrollRef: React.RefObject<CustomScrollAPI | null>;
  mountRef: React.RefObject<Element | null>;
  onLoad?: (document: PdfDocument) => void;
  onFeedback: (feedback: { matchCount: number; matchIndex: number }) => void;
  onUpdateLocation: (locationParams: PdfLocationParams) => void;
}

interface ReaderInstance {
  pdfUrl: string | null;
  loadingDocument: PdfDocument | null;
  loadedDocument: PdfDocument | null;
  searchQuery: string;
  searchResults: GqlLiteratureEditionSearchResult | null;
  locationHash: string | null;
  width: number;
  pdfViewer: PdfViewer;
  pdfLinkService: PdfLinkService;
  pdfFindController: PDFCustomFindController;
}

interface DocumentMeta {
  url: string | null;
  pageLabels: Array<string> | null;
  totalPages: number | null;
}

export interface PDFReaderData {
  reader: ReaderInstance | null;
  juridikaError: JuridikaError | null;
  documentMeta: DocumentMeta;
}

interface Callbacks {
  onPageChange(): void;
  onUpdateViewArea(params: PdfLocationParams): void;
  onTextLayerRendered(params: { pageNumber: number }): void;
}

const EVENT_PAGE_CHANGE = 'pagechange';
const EVENT_UPDATE_VIEW_AREA = 'updateviewarea';
const EVENT_TEXT_LAYER_RENDERED = 'textlayerrendered';

export const usePdfReader = (args: Args): PDFReaderData => {
  // We need a re-render when the pdf document has been (partially) downloaded, thus useState:
  const [pdfDocument, setPdfDocument] = React.useState<PdfDocument | null>(null);

  const [juridikaError, setJuridikaError] = React.useState<JuridikaError | null>(null);

  // Ref to hold the last input arguments, to avoid having to bind directly
  // to the args variable inside child hooks
  const argsRef = React.useRef<Args>(args);
  argsRef.current = args;

  // Metadata about the document
  const documentMetaRef = React.useRef<DocumentMeta>({
    url: null,
    pageLabels: null,
    totalPages: null,
  });

  // Reader instance. It is created and mounted inside the main effect.
  const instanceRef = React.useRef<ReaderInstance | null>(null);

  const callbackRef = React.useRef<Callbacks>({
    onPageChange: () => {
      argsRef.current.onUpdateLocation({});
    },
    onUpdateViewArea: (params) => {
      argsRef.current.onUpdateLocation(params);
    },
    onTextLayerRendered: ({ pageNumber }) => {
      if (instanceRef.current) {
        instanceRef.current.pdfFindController.updatePageLazy(pageNumber - 1);
      }
    },
  });

  // This effect always runs. Dependency changed-checks are performed internally.
  // Therefore you must remember to copy relevant properties from argsRef into instanceRef.
  // The reason it's all inside one effect, is that we don't rely much on state and
  // re-renders. We just want one, simple "update function".
  React.useEffect(() => {
    const args = argsRef.current;

    if (!instanceRef.current && args.customScrollRef.current && args.mountRef.current) {
      initializePdfJs(instanceRef, args.customScrollRef.current, args.mountRef.current, callbackRef.current, args);
    }

    // Try to trigger a new document download:
    if (args.pdfUrl !== documentMetaRef.current.url) {
      documentMetaRef.current.url = args.pdfUrl;

      const download = async () => {
        try {
          const pdfDocument = await downloadDocument(args);

          // Don't await this, let it play out in the background:
          pdfDocument.getPageLabels().then((labels) => {
            documentMetaRef.current.pageLabels = labels;
            documentMetaRef.current.totalPages = pdfDocument.numPages;
          });

          // Will trigger re-render, and a re-run of this hook:
          setPdfDocument(pdfDocument);
        } catch (err) {
          if (isPDFDocumentError(err)) {
            const metadata = {
              statusCode: err.status || 0,
              message: err.message,
              url: args.pdfUrl,
            };
            if (err.status === 401) {
              setJuridikaError({
                type: JuridikaErrorType.REST_UNAUTHENTICATED,
                ...metadata,
              });
            } else if (err.status === 403) {
              setJuridikaError({
                type: JuridikaErrorType.REST_UNAUTHORIZED,
                ...metadata,
              });
            } else if (err.name === 'MissingPDFException') {
              setJuridikaError({
                type: JuridikaErrorType.MISSING_PDF_EXCEPTION,
                message: 'Finner ikke dokument',
                url: err.url || '',
                statusCode: 404,
              });
            } else {
              setJuridikaError({
                type: JuridikaErrorType.GRAPHQL_SERVER,
                ...metadata,
              });
            }
            log.error({ msg: 'getDocument: unhandled error type', error: err });
          }
          log.error({ msg: 'getDocument: error not recognized as a PDFDocumentError', error: err });
        }
      };
      download();
    }

    if (instanceRef.current) {
      if (pdfDocument && pdfDocument !== instanceRef.current.loadedDocument) {
        loadDocument(instanceRef, pdfDocument, argsRef);
      } else if (instanceRef.current.loadedDocument) {
        updateInstanceProperties(instanceRef, args);
      }
    }
  });

  // Unmount effect.
  React.useEffect(() => {
    const callbacks = callbackRef.current;
    return () => {
      if (instanceRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const { pdfViewer } = instanceRef.current;

        log.info('Cleaning up pdfViewer eventBus');
        pdfViewer.eventBus.off(EVENT_PAGE_CHANGE, callbacks.onPageChange);
        pdfViewer.eventBus.off(EVENT_UPDATE_VIEW_AREA, callbacks.onUpdateViewArea);
        pdfViewer.eventBus.off(EVENT_TEXT_LAYER_RENDERED, callbacks.onTextLayerRendered);
      }
    };
  }, []);

  return {
    juridikaError,
    reader: instanceRef.current,
    documentMeta: documentMetaRef.current,
  };
};

const initializePdfJs = (
  instanceRef: React.MutableRefObject<ReaderInstance | null>,
  customScroll: CustomScrollAPI,
  mount: Element,
  callbacks: Callbacks,
  args: Args
) => {
  // @ts-ignore
  const pdfLinkService = new PDFJSViewer.PDFLinkService();
  // @ts-ignore
  const pdfViewer = new ContinuousPdfViewer({
    container: customScroll.getScrolledElement(),
    viewer: mount,
    linkService: pdfLinkService,
  });
  // eslint-disable-next-line no-underscore-dangle
  pdfViewer._setWidth(args.width);
  const pdfFindController = new PDFCustomFindController(pdfViewer);

  pdfLinkService.setViewer(pdfViewer);
  pdfViewer.setFindController(pdfFindController);

  // The event bus is a global object, listener must be unregistered in componentWillUnmount.
  pdfViewer.eventBus.on(EVENT_PAGE_CHANGE, callbacks.onPageChange);
  pdfViewer.eventBus.on(EVENT_UPDATE_VIEW_AREA, callbacks.onUpdateViewArea);
  pdfViewer.eventBus.on(EVENT_TEXT_LAYER_RENDERED, callbacks.onTextLayerRendered);

  instanceRef.current = {
    pdfUrl: null,
    loadingDocument: null,
    loadedDocument: null,
    searchQuery: '',
    searchResults: null,
    locationHash: null,
    width: args.width,
    pdfViewer,
    pdfLinkService,
    pdfFindController,
  };
};

const downloadDocument = async (args: Args): Promise<PdfDocument> => {
  const src = {
    url: args.pdfUrl,
    httpHeaders: args.headers || {},
  };

  const pdfDocument = await PDFJS.getDocument(src);

  // DEBUG
  pdfDocument.getMetadata().then((metadata) => {
    log.info({ msg: 'metadata', metadata });
  });

  return pdfDocument;
};

// Register a downloaded document with the viewer instance
const loadDocument = (
  instanceRef: React.MutableRefObject<ReaderInstance | null>,
  pdfDocument: PdfDocument,
  argsRef: React.MutableRefObject<Args>
) => {
  if (!instanceRef.current) return;

  const instance = instanceRef.current;
  const { pdfViewer, pdfLinkService } = instance;
  const args = argsRef.current;

  instance.loadingDocument = pdfDocument;

  pdfViewer.currentScaleValue = args.scale;
  pdfViewer.currentPageNumber = args.page;

  // eslint-disable-next-line no-underscore-dangle
  pdfViewer._setWidth(args.width);
  instance.width = args.width;

  pdfDocument.getPage(1).then((firstPage) => {
    // Cancel if loading a new document
    if (pdfDocument !== instanceRef.current?.loadingDocument) {
      log.info('cancelling');
      return;
    }

    log.info({ msg: 'firstPage: ', firstPage });
    // eslint-disable-next-line no-underscore-dangle
    pdfViewer._currentScale = pdfViewer.computeScale(firstPage);
    // eslint-disable-next-line no-underscore-dangle
    log.info({ msg: 'pdfViewer _currentScale: ', currentScale: pdfViewer._currentScale });
    pdfViewer.setDocument(pdfDocument);
    pdfLinkService.setDocument(pdfDocument);

    pdfViewer.firstPagePromise.then(() => {
      log.debug({ msg: 'firstPagePromise', goto: args.location.hash });
      navigateToHash(instanceRef, args.location.hash);
    });

    instance.loadedDocument = pdfDocument;

    Promise.resolve().then(() => {
      if (argsRef.current.onLoad) {
        argsRef.current.onLoad(pdfDocument);
      }
    });
  });
};

const updateInstanceProperties = (instanceRef: React.MutableRefObject<ReaderInstance | null>, args: Args) => {
  if (!instanceRef.current) return;

  const { pdfViewer, pdfFindController } = instanceRef.current;

  if (args.location.hash !== instanceRef.current.locationHash) {
    if (!args.location.state || !args.location.state.lazy) {
      navigateToHash(instanceRef, args.location.hash);
    }
  }

  if (args.searchQuery !== instanceRef.current.searchQuery) {
    instanceRef.current.searchQuery = args.searchQuery;
    args.onFeedback({ matchCount: 0, matchIndex: 0 });
  }

  if (args.width !== instanceRef.current.width) {
    instanceRef.current.width = args.width;
    // eslint-disable-next-line no-underscore-dangle
    pdfViewer._setWidth(args.width);
  }

  if (args.searchResults !== instanceRef.current.searchResults || args.searchQuery !== instanceRef.current.searchQuery) {
    instanceRef.current.searchResults = args.searchResults;
    instanceRef.current.searchQuery = args.searchQuery;

    const controllerResults: GqlLiteratureEditionSearchResult = instanceRef.current.searchResults ?? {
      pageInfo: {
        hasNextPage: false,
        endCursor: '',
        totalSearchResults: 0,
      },
      error: null,
      edges: [],
    };

    pdfFindController.applySearchResults(controllerResults, instanceRef.current.searchQuery);
  }
};

const navigateToHash = (instanceRef: React.MutableRefObject<ReaderInstance | null>, hash: string) => {
  if (instanceRef.current) {
    instanceRef.current.locationHash = hash;
    const dest = hash.slice(1);
    if (dest.length > 0) {
      instanceRef.current.pdfLinkService.navigateTo(dest);
    }
  }
};
