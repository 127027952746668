import styled, { css } from 'styled-components';

export const AcademyFrontPageSectionHeadingCss = styled.h2<{ isTextAlignCenter?: boolean }>`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;

  ${({ isTextAlignCenter }) =>
    isTextAlignCenter &&
    css`
      text-align: center;
    `}
`;

export const AcademyFrontPageTextContentCss = styled.div<{ isTextAlignCenter?: boolean }>`
  ${({ isTextAlignCenter }) =>
    isTextAlignCenter &&
    css`
      text-align: center;
    `}

  > h3 {
    font-size: 1.375rem;
    margin: 15px 0 5px;
    font-weight: 700;
    line-height: normal;
    color: ${({ theme }) => theme.academy.frontPage.colors.primary};
  }

  > p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 160%;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    strong {
      font-weight: 600;
    }
  }
`;

export const AcademyFrontPageLimitWidthContainerCss = styled.div`
  max-width: 640px;

  .button-container {
    margin-top: 18px;
  }
`;

export const AcademyFrontPageVideoContainerCss = styled.div`
  width: 100%;

  .jwplayer {
    border-radius: 0.5rem;

    /* Decrease overlay darkening effect on poster image */
    &.jw-state-idle .jw-controls {
      background-color: rgba(0, 0, 0, 0.2);
    }

    /* Decrease overlay darkening effect on poster image */
    .jw-controls-backdrop {
      background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 77%, rgba(0, 0, 0, 0.2) 100%);
    }
  }
`;

export const AcademyFrontPageIconButtonCss = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  padding: 12px;
  border-radius: 4px;

  color: ${({ theme }) => theme.academy.frontPage.colors.primary};
  background-color: transparent;

  :hover {
    color: ${({ theme }) => theme.academy.frontPage.colors.secondary};
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.academy.frontPage.colors.primary};
    border: none;
  }
`;
