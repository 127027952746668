import React, { useState, useEffect, FC } from 'react';
import DoubleArrowsRight from 'components/icons/DoubleArrowsRight';
import DoubleArrowsLeft from 'components/icons/DoubleArrowsLeft';
import scrollToElement from 'util/scrollToElement';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { NIK_VIEWPORT_SIZE } from 'pages/NIKPage/constants';
import TOCFoldableSection from './TOCFoldableSection';
import {
  TableOfContentsCss,
  TOCAsideButtonCss,
  TOCVisibleOverlayCss,
} from './styles';

const SCROLL_OFFSET = 126;

interface TableOfContentsProps {
  publicationTitle: string;
  titles: TitleHierarchy[];
  ids: string[];
}

export interface TitleHierarchy {
  title: string;
  id: string;
  ids: string[];
  children: TitleHierarchy[];
}

const TableOfContents: FC<TableOfContentsProps> = ({
  publicationTitle,
  titles,
  ids,
}) => {
  const viewportSize = useViewportSize(NIK_VIEWPORT_SIZE, 'small');
  const [isExpanded, setIsExpanded] = useState(true);
  const [urlHash, setUrlHash] = useState('');

  const toggleExpanded = () =>
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);

  const handleScrollToPart = (id: string) => {
    scrollToElement(id, SCROLL_OFFSET);
    setUrlHash(`${id}`);
  };

  useEffect(() => {
    const getHashAndScroll = () => {
      if (window) {
        const hash = window.location.hash.substring(1);
        const element = document.getElementById(hash);
        if (element) {
          handleScrollToPart(hash);
        }
      }
    };
    if (window) {
      window.addEventListener('hashchange', () => {
        getHashAndScroll();
      });
      getHashAndScroll();
    }
  }, []);

  if (viewportSize === 'large')
    return (
      <TableOfContentsCss aria-expanded={isExpanded}>
        {isExpanded && (
          <TOCFoldableSection
            publicationTitle={publicationTitle}
            ids={ids}
            titles={titles}
            urlHash={urlHash}
            handleScrollToPart={handleScrollToPart}
          />
        )}
        <TOCVisibleOverlayCss>
          <TOCAsideButtonCss
            aria-label={
              isExpanded
                ? 'Skjul innholdsfortegnelse'
                : 'Vis innholdsfortegnelse'
            }
            onClick={toggleExpanded}
            expanded={isExpanded}
          >
            <span>
              {isExpanded ? (
                <DoubleArrowsLeft />
              ) : (
                <>
                  Vis
                  <DoubleArrowsRight />
                </>
              )}
            </span>
          </TOCAsideButtonCss>
          <div />
        </TOCVisibleOverlayCss>
      </TableOfContentsCss>
    );
  return null;
};

export default TableOfContents;
