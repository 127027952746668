import { TYPE, ELEMENT, CHILD, VALUE, CHILDREN } from 'models/dom/domNode';
import {
  HTML_ATTRIBUTES,
  HypertextNode,
  HypertextAllocator,
  TAGNAME,
} from 'util/hypertextAllocator';
import { JsonMLElement } from 'models/JsonML';
import { TitleHierarchy } from './TableOfContents';

const collectTitlesFromHypertextNode = (
  node: HypertextNode,
  level: number = 1,
  ids: string[] = []
): { titles: TitleHierarchy[]; ids: string[] } => {
  const titles: TitleHierarchy[] = [];
  switch (node[TYPE]) {
    case ELEMENT: {
      switch (node[TAGNAME]) {
        case 'part': {
          const id = node[HTML_ATTRIBUTES]?.id || '';
          const children = node[CHILDREN];
          const part: TitleHierarchy = {
            title: '',
            id,
            children: [],
            ids: [id],
          };

          if (children) {
            for (const child of children) {
              if (child[TAGNAME] === 'title') {
                part.title = child[CHILD]?.[VALUE] || '';
              } else if (child[TAGNAME] === 'part') {
                const { titles: childrenTitles, ids: childrenIds } =
                  collectTitlesFromHypertextNode(child, level + 1, part.ids);
                part.children.push(...childrenTitles);
                part.ids.push(...childrenIds);
              }
            }
          }
          titles.push(part);
          return { titles, ids: part.ids };
        }
        default: {
          const children = node[CHILDREN];
          if (children) {
            let childrenIds: string[] = [];
            for (const child of children) {
              const { titles: childTitles, ids: childIds } =
                collectTitlesFromHypertextNode(child, level, ids);
              titles.push(...childTitles);
              childrenIds = childrenIds.concat(childIds);
            }
            return { titles, ids: childrenIds };
          }
          return { titles, ids };
        }
      }
    }
    default: {
      return { titles, ids };
    }
  }
};

const collectTitles = (jsonMlDocument: JsonMLElement) => {
  const hypertextNode = new HypertextAllocator().jsonmlFragmentToNode(
    jsonMlDocument
  );
  const { titles, ids } = collectTitlesFromHypertextNode(hypertextNode);
  return { titles, ids };
};

export default collectTitles;
