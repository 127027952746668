import React, { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useViewportSize } from 'util/hooks/useViewportSize';
import Paragraph from 'components/icons/Paragraph';
import Gavel from 'components/icons/Gavel';
import ArrowDown from 'components/icons/ArrowDown';
import ArrowUp from 'components/icons/ArrowUp';
import ResourceStickerContent from './ResourceStickerContent';
import {
  ResourceStickerData,
  ResourceStickerType,
} from '../../hooks/useResourceStickers';
import { NIK_VIEWPORT_SIZE } from '../../constants';
import ResourceStickerContentLoader from './ResourceStickerContentLoader';
import { ResourceStickerCss, IconButtonCss } from './styles';

interface ResourceStickerProps {
  stickerData: ResourceStickerData;
  onClose: () => void;
}

const closeButtonIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 4.5L1.5 0L0 1.5L4.5 6L0 10.5L1.5 12L6 7.5L10.5 12L12 10.5L7.5 6L12 1.5L10.5 0L6 4.5Z"
      fill="#29282E"
    />
  </svg>
);

const ResourceStickerIcon: FC<{ stickerType: ResourceStickerType }> = ({
  stickerType,
}) => {
  const theme = useTheme();
  const iconFill = theme.colorTokens.grey[900];
  switch (stickerType) {
    case ResourceStickerType.LawFragment:
      return <Paragraph color={iconFill} />;
    case ResourceStickerType.Judgment:
      return <Gavel color={iconFill} />;
    default:
      return null;
  }
};

export const ResourceSticker: FC<ResourceStickerProps> = ({
  stickerData,
  onClose,
}) => {
  const { isLoading, type: stickerType, stickerDivRef } = stickerData;

  const [isExpanded, setIsExpanded] = useState(true);
  const viewportSize = useViewportSize(NIK_VIEWPORT_SIZE, 'small');

  useEffect(() => {
    setTimeout(() => {
      stickerDivRef.current?.classList.remove('appear-animation');
    }, 500);
  }, [stickerDivRef]);

  return (
    <ResourceStickerCss
      $stickerType={stickerType}
      $isLarge={viewportSize === 'large'}
      ref={stickerDivRef}
      className="appear-animation"
    >
      {/* This extra div is here because when scrolling the div into view and playing highlight animation
      (when user clicks a link for an existing sticker), the outer div is scrolled into view, and the top
      or bottom of the highlight outline would be cut off if the outline animation was applied to the outer div.
       */}
      <div className="resource-sticker-outline-box">
        <div className="sticker-top-controls-line">
          <ResourceStickerIcon stickerType={stickerType} />
          <div className="sticker-top-controls-actions">
            <IconButtonCss
              onClick={() => setIsExpanded(!isExpanded)}
              aria-expanded={isExpanded}
              aria-label="Vis/skjul tekstinnhold"
            >
              {isExpanded ? (
                <ArrowUp width={13} height={9} />
              ) : (
                <ArrowDown width={13} height={9} />
              )}
            </IconButtonCss>
            <IconButtonCss onClick={onClose} aria-label="Lukk ressursboks">
              {closeButtonIcon}
            </IconButtonCss>
          </div>
        </div>
        <div className="content">
          {isLoading ? (
            <ResourceStickerContentLoader showHeadingLine />
          ) : (
            <ResourceStickerContent
              stickerData={stickerData}
              isExpanded={isExpanded}
            />
          )}
        </div>
      </div>
    </ResourceStickerCss>
  );
};
