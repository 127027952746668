import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Gears: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.7956 9.92257L14.3601 9.12903C14.505 8.38064 14.505 7.6129 14.3601 6.86452L15.7956 6.07097C15.9608 5.98065 16.0349 5.79356 15.981 5.61936C15.6069 4.47098 14.9701 3.43228 14.1377 2.56776C14.0097 2.4355 13.8008 2.40325 13.639 2.49357L12.2035 3.28711C11.6004 2.79034 10.9062 2.40647 10.1548 2.15486V0.570996C10.1548 0.390351 10.0234 0.232287 9.83802 0.193578C8.60134 -0.0709372 7.33434 -0.0580341 6.15832 0.193578C5.97298 0.232287 5.84157 0.390351 5.84157 0.570996V2.15809C5.09349 2.41292 4.39934 2.79679 3.79279 3.29034L2.36068 2.49679C2.19556 2.40647 1.99001 2.4355 1.86196 2.57099C1.02965 3.43228 0.392775 4.47098 0.0187395 5.62259C-0.0385452 5.79678 0.0389577 5.98388 0.204073 6.0742L1.63956 6.86775C1.49466 7.61613 1.49466 8.38387 1.63956 9.13225L0.204073 9.9258C0.0389577 10.0161 -0.0351756 10.2032 0.0187395 10.3774C0.392775 11.5258 1.02965 12.5645 1.86196 13.429C1.99001 13.5613 2.19893 13.5935 2.36068 13.5032L3.79616 12.7097C4.39934 13.2064 5.0935 13.5903 5.84494 13.8419V15.429C5.84494 15.6096 5.97635 15.7677 6.16169 15.8064C7.39836 16.0709 8.66537 16.058 9.84139 15.8064C10.0267 15.7677 10.1581 15.6096 10.1581 15.429V13.8419C10.9062 13.5871 11.6004 13.2032 12.2069 12.7097L13.6424 13.5032C13.8075 13.5935 14.0131 13.5645 14.1411 13.429C14.9734 12.5677 15.6103 11.529 15.9843 10.3774C16.0349 10.2 15.9608 10.0129 15.7956 9.92257ZM7.99817 10.5774C6.51214 10.5774 5.30242 9.41935 5.30242 7.99677C5.30242 6.5742 6.51214 5.41614 7.99817 5.41614C9.4842 5.41614 10.6939 6.5742 10.6939 7.99677C10.6939 9.41935 9.4842 10.5774 7.99817 10.5774Z"
      fill={color}
    />
  </Svg>
);

Gears.defaultProps = {
  color: COLOR_TOKENS.default.white,
  size: 16,
};

export default Gears;
