import * as React from 'react';
import { COLOR_TOKENS, SPACING } from 'theme/config/defaultTheme';
import { ColorTokenValues } from 'theme/config/types';
import { Button } from '../Button/Button';

export interface TagProps {
  isSelected: boolean;
  backgroundColor?: ColorTokenValues;
  onClick(): void;
  tabIndex?: number;
  children: React.ReactNode | React.ReactNode[];
}

const Tag: React.FC<TagProps> = ({ backgroundColor, isSelected, onClick, tabIndex, children }) => (
  <Button
    tabIndex={tabIndex}
    $fontConfig={{ size: 0.875 }}
    $paddingConfig={{ topBottom: '0.25rem', sides: SPACING.xs }}
    $backgroundColor={isSelected ? COLOR_TOKENS.aqua[400] : backgroundColor || COLOR_TOKENS.grey[300]}
    $color={COLOR_TOKENS.default.black}
    $rounded
    onClick={onClick}
  >
    {children}
  </Button>
);

export default Tag;
