import Svg from 'components/ui/Svg';
import * as React from 'react';
import IconPropTypes from './IconPropTypes';

const ArrowUp2: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.25 13.668L8.25 3.08204"
      stroke="#171718"
      strokeWidth="1.77778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.58204 7.00001L8.25 2.33204L12.918 7.00001"
      stroke="#171718"
      strokeWidth="1.77778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

ArrowUp2.defaultProps = {
  color: '#171718',
};

export default ArrowUp2;
