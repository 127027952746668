import styled, { css } from 'styled-components';
import { ButtonLinkVibrantCss } from 'components/AcademyAndNIK/Buttons/ButtonsVibrantPurple';
import { ResourceStickerType } from '../../hooks/useResourceStickers';

// ResourceSticker.tsx
export const ResourceStickerCss = styled.div<{
  $stickerType: ResourceStickerType;
  $isLarge: boolean;
}>`
  position: relative; /* For relative positioning during appear animation */

  ${({ $isLarge }) =>
    $isLarge &&
    css`
      padding: 4px; /* To make room for outine during highlight animation */
    `}

  /* For mobile layout */
      margin-top: 16px;
  margin-bottom: 32px;

  ${({ $isLarge }) =>
    $isLarge &&
    css`
      margin-top: 0;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    `}

  .resource-sticker-outline-box {
    ${({ $stickerType, theme }) => {
      switch ($stickerType) {
        case ResourceStickerType.LawFragment:
          return css`
            background-color: ${theme.colorTokens.blue[100]};
          `;
        case ResourceStickerType.Judgment:
          return css`
            background-color: ${theme.colorTokens.purpleVibrant[100]};
          `;
        case ResourceStickerType.Regulation:
        case ResourceStickerType.Directive:
          return css`
            background-color: ${theme.colorTokens.grey[100]};
          `;
      }
    }}

    border-radius: 4px;
    overflow: hidden;
    font-size: 1.125rem;
    display: flex;
    flex-direction: column;

    .sticker-top-controls-line {
      padding: 0.75rem 1.5rem;
      display: flex;
      align-items: center;

      ${({ $stickerType, theme }) => {
        switch ($stickerType) {
          case ResourceStickerType.LawFragment:
            return css`
              background-color: ${theme.colorTokens.blue[200]};
            `;
          case ResourceStickerType.Judgment:
            return css`
              background-color: ${theme.colorTokens.purpleVibrant[200]};
            `;
          case ResourceStickerType.Regulation:
          case ResourceStickerType.Directive:
            return css`
              background-color: ${theme.colorTokens.grey[300]};
            `;
        }
      }}

      .sticker-top-controls-actions {
        display: flex;
        margin-left: auto;

        button {
          margin-left: 0.6rem;
        }
      }
    }

    .content {
      padding: 1rem 1.5rem;

      h2.sticker-heading {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 8px;
      }
    }

    @keyframes highlight {
      0%,
      50% {
        outline: 4px solid ${({ theme }) => theme.colorTokens.grey[700]};
      }
      100% {
        outline: 4px solid transparent;
      }
    }
  }

  &.appear-animation {
    animation: 0.5s ease-out appear;
  }

  ${({ $isLarge }) =>
    $isLarge &&
    css`
      &.highlight-animation > .resource-sticker-outline-box {
        animation: 1s highlight ease;
      }
    `}

  @keyframes appear {
    from {
      opacity: 0;
      top: 40px;
    }

    to {
      opacity: 100%;
      top: 0;
    }
  }

  @keyframes jump {
    0% {
      transform: scale(1);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    }
    100% {
      transform: scale(1.15);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const IconButtonCss = styled.button`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path,
    polygon {
      fill: ${({ theme }) => theme.colorTokens.grey[900]};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colorTokens.grey[900]};
    svg {
      path,
      polygon {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colorTokens.grey[900]};
  }

  :active {
    background-color: ${({ theme }) => theme.colorTokens.grey[900]};
  }
`;

// ResourceStickerContent.tsx
export const LegalFragmentStickerContentCss = styled.div<{
  isExpanded: boolean;
}>`
  .comming-into-effect-date-line {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-style: italic;
    font-size: 1rem;
  }

  .akn__paragraph:only-of-type {
    p {
      margin-bottom: 0.5rem !important;
    }
  }
`;

export const GoToResourceButtonLinkCss = styled(ButtonLinkVibrantCss)`
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const HTMLContentCss = styled.div<{ isExpanded: boolean }>`
  margin-top: 0;
  margin-bottom: ${({ isExpanded }) => (isExpanded ? `1.5rem` : `0`)};

  h2 {
    font-size: 1.375rem;
    margin-top: 0;
    margin-bottom: ${({ isExpanded }) => (isExpanded ? `1rem` : `0`)};
    font-weight: 600;
  }

  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .akn__paragraph:only-of-type {
    p {
      margin-bottom: 0;
    }
  }
`;

const MARGIN_TOP = 32;
const MARGIN_BOTTOM = 12;

export const ResourceStickersViewCss = styled.div<{
  topElementsApproxHeight: number;
}>`
  width: 416px;
  overflow-y: auto;
  margin-bottom: ${MARGIN_BOTTOM}px;

  position: sticky;

  ${({ topElementsApproxHeight }) =>
    css`
      top: calc(${topElementsApproxHeight}px + ${MARGIN_TOP}px);
      height: calc(
        100vh - ${topElementsApproxHeight}px - ${MARGIN_TOP}px -
          ${MARGIN_BOTTOM}px
      );
    `}

  .stickers-container {
    width: 404px;
  }

  /* Scrollbar for firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colorTokens.grey[600]} transparent;

  /* Scrollbar for webkit-based (chrome, safari, edge...) */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colorTokens.grey[600]};
    border-radius: 4px;
    border: transparent;
  }
`;

export const OpenResourceStickerButtonCss = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colorTokens.grey[200]};
  padding: 2px 4px;
  border-radius: 2px;
  text-decoration: underline dotted;

  display: inline-flex;
  align-items: baseline;
  gap: 4px;

  :focus-visible {
    border: none;
    outline: 2px solid ${({ theme }) => theme.colorTokens.blue[400]};
    position: relative; /* To make outline appear above other resource link buttons on line below */
  }
`;
