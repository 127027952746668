import { possibleFragmentSegmentTypes } from 'util/fragmentUrlPathConversion';

const countryAndType = '/:countryCode([a-z]{2})?/:type(lov|forskrift|direktiv|forordning|traktat|hr)';
const docIdSegmentOptions = [
  '/:docId([12][0-9][0-9][0-9]-[0-1]?[0-9]-[0-3]?[0-9]-[0-9]+)',
  '/:docId([12][0-9][0-9][0-9]-[0-1]?[0-9]-[0-3]?[0-9])',
];
const exprSegments = '/:languageCode([a-z]{3})?/:inForceAt([12][0-9][0-9][0-9]-[0-1]?[0-9]-[0-3]?[0-9])?';

const fragmentTypes = possibleFragmentSegmentTypes;

const generateFragmentSegments = () => {
  const paths = ['', `/:fragment1Type(${fragmentTypes})`, '/:fragment1Type(§|%C2%A7):fragment1Num'];

  let path = '';
  for (let i = 1; i <= 5; i += 1) {
    path += `/:fragment${i}Type(${fragmentTypes})_:fragment${i}Num`;
    paths.push(path);
  }
  return paths;
};

const commentSegments = '/:loadComment(kommentar)?/:commentParagraphParam(avsnitt_\\d+)?';

const generateLegalDocumentPaths = () => {
  const paths: string[] = [];

  docIdSegmentOptions.forEach((docId) => {
    generateFragmentSegments().forEach((fragmentSegment) => {
      paths.push(`${countryAndType}${docId}${exprSegments}${fragmentSegment}${commentSegments}`);
    });
  });

  return paths;
};

export const LEGAL_DOCUMENT_PATHS = generateLegalDocumentPaths();
