import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { AcademyFrontPageData } from 'pages/AcademyPage/types';
import { Layout } from 'containers/Layouts/Layout';
import AcademyFrontPageHeroSection from './AcademyFrontPageHeroSection';
import AcademyFrontPageTestimonialsSection from './AcademyFrontPageTestimonialsSection';
import AcademyFrontPageSneakPeakSection from './AcademyFrontPageSneakPeakSection';
import AcademyFrontPageTopVideoAndTextSection from './AcademyFrontPageTopVideoAndTextSection';

const AcademyFrontPage: React.FC = () => {
  const [frontPageData, setFrontPageData] = useState<AcademyFrontPageData | undefined>(undefined);

  useEffect(() => {
    fetch(`/api/academy/frontPage`).then((res) =>
      res.json().then((res) => {
        setFrontPageData(res);
      })
    );
  }, []);

  return (
    frontPageData && (
      <Layout hasToolbar={false} livechatVisibility="minimized">
        <AcademyFrontPageCss>
          <Helmet title="Juridika Akademi" titleTemplate="%s" />

          <AcademyFrontPageHeroSection heroSectionData={frontPageData.frontPage.heroSection} />

          <AcademyFrontPageTopVideoAndTextSection
            topSectionData={frontPageData.frontPage.topVideoAndTextSection}
            featureBoxesContent={frontPageData.frontPage.featureBoxes}
          />

          <AcademyFrontPageTestimonialsSection testimonialsSectionData={frontPageData.frontPage.testimonialsSection} />

          {/* <AcademyFrontPageAboutSection aboutAkademiSectionData={frontPageData.frontPage.aboutAkademiSection} /> */}

          <AcademyFrontPageSneakPeakSection sneakPeakSectionData={frontPageData.frontPage.sneakPeakSection} />
        </AcademyFrontPageCss>
      </Layout>
    )
  );
};

export default AcademyFrontPage;

const AcademyFrontPageCss = styled.section`
  *::selection {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.academy.frontPage.colors.textSelectionBackground};
  }
`;
