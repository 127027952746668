import { Facebook, Twitter, Instagram, LinkedIn } from 'icons/SocialIcons/SocialMedia';

export interface SocialMediaLink {
  link: string;
  displayText: string;
  icon: React.FC;
}

export const FacebookLink: SocialMediaLink = {
  link: 'https://www.facebook.com/JuridikaUF',
  displayText: 'Facebook',
  icon: Facebook,
};

export const TwitterLink: SocialMediaLink = {
  link: 'https://twitter.com/JuridikaUF/',
  displayText: 'Twitter',
  icon: Twitter,
};

export const InstagramLink: SocialMediaLink = {
  link: 'https://www.instagram.com/juridika_',
  displayText: 'Instagram',
  icon: Instagram,
};

export const LinkedInLink: SocialMediaLink = {
  link: 'https://www.linkedin.com/company/juridika/',
  displayText: 'LinkedIn',
  icon: LinkedIn,
};

export const socialMediaLinkList: SocialMediaLink[] = [FacebookLink, TwitterLink, InstagramLink, LinkedInLink];
