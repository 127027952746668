import React from 'react';

const PenTool: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 22, height = 22 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0 0 22 22">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#e00e37"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1 1)"
    >
      <path d="M10 17l7-7 3 3-7 7z" />
      <path d="M16 11l-1.5-7.5L0 0l3.5 14.5L11 16zM0 0l7.586 7.586" />
      <circle cx="9" cy="9" r="2" />
    </g>
  </svg>
);

export default PenTool;
