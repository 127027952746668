import React, { FC, ReactElement } from 'react';
import { gql } from 'graphql-tag';
import { useApolloQuery } from 'util/hooks/useApolloQuery';
import * as GqlResult from 'util/graphql/GqlResult';
import { Throw } from 'components/error/Throw';
import { useParams } from 'react-router';
import { JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import DocumentLoader from '../components/Document/DocumentLoader';
import Document from '../components/Document/Document';
import { NIKContentContainerCss } from '../components/Document/styles';
import DraftDocument from '../components/Document/DraftDocument';
import { GqlNIKDocumentById, GqlNIKDocumentBySlug } from '../types';
import { NIK_CONTENT_CONTAINER_MARGIN_TB } from '../constants';

const NIK_DOCUMENT_BY_SLUG_QUERY = gql`
  query NikDocument($slug: String) {
    nikDocument(slug: $slug) {
      node {
        publicationTitle
        publishedAt
        content {
          jsonMl
        }
        primaryTag {
          name
        }
        contributorDetails {
          title
          shortBio
          contributorConnection {
            node {
              id
              hasPortrait
              names
              slug
            }
          }
        }
      }
    }
  }
`;

const NIK_DOCUMENT_BY_ID_QUERY = gql`
  query NikDocumentById($id: String) {
    nikDocumentById(id: $id) {
      node {
        publicationTitle
        publishedAt
        content {
          jsonMl
        }
        primaryTag {
          name
        }
        contributorDetails {
          title
          shortBio
          contributorConnection {
            node {
              id
              hasPortrait
              names
              slug
            }
          }
        }
      }
    }
  }
`;

const NIKReadingPage: FC<{ draft?: boolean }> = ({ draft }) => {
  const params = useParams<{
    document: string;
  }>();

  const RouteNotFound = (
    <Throw
      error={{
        type: JuridikaErrorType.ROUTE_NOT_FOUND,
        pathname: params.document,
      }}
    />
  );

  const [result] = useApolloQuery<
    GqlNIKDocumentBySlug | GqlNIKDocumentById,
    { slug: string } | { id: string }
  >(draft ? NIK_DOCUMENT_BY_ID_QUERY : NIK_DOCUMENT_BY_SLUG_QUERY, {
    variables: draft ? { id: params.document } : { slug: params.document },
    fetchPolicy: 'cache-and-network',
  });

  return GqlResult.of(result)
    .map((data): ReactElement | null => {
      if ('nikDocument' in data) {
        if (!data.nikDocument.node) {
          return RouteNotFound;
        }
        return (
          <Document
            {...data.nikDocument.node}
            jsonmlDocument={data.nikDocument.node.content.jsonMl}
          />
        );
      }
      if ('nikDocumentById' in data) {
        if (!data.nikDocumentById.node) {
          return RouteNotFound;
        }
        return <DraftDocument data={data.nikDocumentById.node} />;
      }
      return RouteNotFound;
    })
    .mapLoading((): ReactElement | null => (
      <NIKContentContainerCss
        margin={`${NIK_CONTENT_CONTAINER_MARGIN_TB}px 15px`}
      >
        <DocumentLoader />
      </NIKContentContainerCss>
    ))
    .getOrElseGet((notOk) => (
      <Throw error={GqlResult.notOkToJuridikaError(notOk)} />
    ));
};

export default NIKReadingPage;
