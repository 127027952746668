/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint global-require: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint import/no-mutable-exports: 0 */

import { createLogger } from 'commonUtils/log';

const log = createLogger('pdf');

// Exports are null for the server side.
// PDFJS does not support SSR.
export let PDFJSViewer = null;
export let ContinuousPdfViewer = null;

// eslint-disable-next-line no-undef
if (typeof __CLIENT__ !== 'undefined' && !!__CLIENT__) {
  const lib = require('pdfjs-dist');

  // The following hack is used to be able to generate correct production bundle.
  // Somehow there's a require('../build/pdf') inside pdf_viewer.js that fails to
  // resolve in the resulting bundle. Setting this avoids that code path and reads
  // the library from this location instead.... :(
  window['pdfjs-dist/build/pdf'] = lib;

  PDFJSViewer = require('pdfjs-dist/web/pdf_viewer').PDFJS;

  const PDFJS_CSS_UNITS = 96.0 / 72.0;

  // continuous viewer: Scrolls vertically
  // eslint-disable-next-line no-shadow
  ContinuousPdfViewer = class ContinuousPdfViewer extends PDFJSViewer.PDFViewer {
    // Computes the 'raw' page scaling value given the desired viewer width.
    computeScale(pdfPage) {
      const { pageInfo } = pdfPage;
      const { view } = pageInfo;
      const scale = this.width / (view[2] - view[0]);
      log.info({ msg: 'computeScale', pdfPage, scale });

      // pdf.js multiplies the scale by CSS_UNITS.
      return scale / PDFJS_CSS_UNITS;
    }

    _setWidth(width) {
      this.width = width;
      this._setScale(1);
    }

    // Overridden method to force scaling value to be consistent with a fixed width.
    _setScale(...args) {
      const noScroll = arguments.length > 1 && args[1] !== undefined ? args[1] : false;

      let currentPage = this._pages[this._currentPageNumber - 1];
      if (!currentPage) {
        [currentPage] = this._pages;
      }
      if (!currentPage) {
        log.info('_setScale: no current page');
        return;
      }

      log.info({ msg: '_setScale', currentPage });

      const scale = this.computeScale(currentPage.pdfPage);
      this._setScaleUpdatePages(scale, scale, noScroll, true);
    }
  };

  // Set up web worker
  const Worker = require('pdfjs-dist/build/pdf.worker');
  PDFJSViewer.workerPort = new Worker();
}
