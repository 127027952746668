export interface CustomErrorMessages {
  notFound?: string;
}

interface ExcerptError extends Error {
  statusCode: number;
}

interface PDFError extends Error {
  status?: number;
  url?: string;
}

export function isExcerptError(error: ExcerptError | unknown): error is ExcerptError {
  return (error as ExcerptError).statusCode !== undefined;
}

export function isPDFDocumentError(error: PDFError | unknown): error is PDFError {
  return (error as PDFError).status !== undefined;
}
