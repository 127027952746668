export const takeWhile = <T>(list: T[], condition: (elem: T) => boolean): T[] => {
  const firstNonMatchingIndex: number = list.findIndex((elem) => !condition(elem));
  return firstNonMatchingIndex === -1 ? list : list.slice(0, firstNonMatchingIndex);
};

export const intersection = <T>(a: T[], b: T[]): T[] => a.filter((e) => b.includes(e));

/**
 * Split an array on every predicate yielding true for an item
 */
export const splitOn = <T>(list: T[], predicate: (elem: T, index: number) => boolean, limit?: number): T[][] => {
  const realLimit = limit === undefined ? list.length : limit;
  let group: T[] = [];
  const split: T[][] = [group];

  for (let i = 0; i < list.length; i += 1) {
    const elem = list[i];
    if (predicate(elem, i) && split.length < realLimit) {
      group = [];
      split.push(group);
    }
    group.push(elem);
  }

  return split;
};
