import Modal, { ModalContent, ModalFooter, ModalHeader } from 'components/ui/Modal/Modal';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export const BoldTextCss = styled.p<{ isEditing: boolean }>`
  margin-top: 0;
  font-weight: 600;

  ${({ isEditing }) => !isEditing && `margin-bottom: 0;`}
`;

export const ModalCss = styled(Modal)`
  > div {
    ${createMediaQuery(
      'md',
      css`
        max-height: 50rem;
        border-radius: 8px;
      `
    )}
    max-width: 37.5rem;
  }
`;

export const SpinningIcon = css`
  :hover,
  :focus {
    svg {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);

      -webkit-transition: all 0.7s ease;
      -moz-transition: all 0.7s ease;
      -ms-transition: all 0.7s ease;
      -o-transition: all 0.7s ease;
      transition: all 0.7s ease;
    }
  }
`;

export const ModalHeaderCss = styled(ModalHeader)`
  font-size: 0.875rem;
  padding: 1rem 1.25rem;
  background-color: ${({ theme }) => theme.colorTokens.grey[100]};

  button {
    padding-right: 0;
  }

  button {
    ${SpinningIcon}
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export const ModalContentCss = styled(ModalContent)`
  padding: 0 1.25rem;
`;

export const ModalFooterCss = styled(ModalFooter)`
  padding: 0.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colorTokens.grey[100]};

  button {
    width: 50%;
    justify-content: center;
    border-radius: 0.25rem;
  }
`;

export const HorizontalRuleCss = styled.hr`
  border: none;
  height: 0.4px;
  background-color: ${({ theme }) => theme.colorTokens.brown[200]};
`;
