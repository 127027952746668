import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import JWPlayer from '@jwplayer/jwplayer-react';

import createMediaQuery from 'util/createMediaQuery';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { AcademyFrontPageData } from 'pages/AcademyPage/types';
import { JWPlayerLibraryUrl } from 'pages/AcademyPage/components/Video/Video';
import {
  AcademyFrontPageTextContentCss,
  AcademyFrontPageLimitWidthContainerCss,
  AcademyFrontPageVideoContainerCss,
  AcademyFrontPageSectionHeadingCss,
} from './components/AcademyFrontPageContentStyles';
import {
  AcademyFrontPageSectionContentCss,
  AcademyFrontPageSectionContentTwoColumnsCss,
  AcademyFrontPageSectionCss,
} from './components/AcademyFrontPageSectionStyles';
import GetAccessOrStartCourseButton from './components/GetAccessOrStartCourseButton';
import AcademyFeatureBoxes from './components/AcadademyFeatureBoxes';

interface Props {
  topSectionData: AcademyFrontPageData['frontPage']['topVideoAndTextSection'];
  featureBoxesContent: AcademyFrontPageData['frontPage']['featureBoxes'];
}

const AcademyFrontPageTopVideoAndTextSection: FC<Props> = ({ topSectionData, featureBoxesContent }) => {
  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');

  return (
    <AcademyFrontPageSectionCss>
      <SectionContentTwoColumnsCss>
        <VideoContainerCss>
          <JWPlayer
            library={JWPlayerLibraryUrl}
            playlist={{
              file: topSectionData.video.videoFileUrl,
              image: topSectionData.video.posterImageUrl,
            }}
          />

          {viewportSize === 'large' && (
            <AcademyFrontPageTextContentCss dangerouslySetInnerHTML={{ __html: topSectionData.textsTwoColumns.textUnderVideo }} />
          )}
        </VideoContainerCss>

        <AcademyFrontPageLimitWidthContainerCss>
          <AcademyFrontPageSectionHeadingCss dangerouslySetInnerHTML={{ __html: topSectionData.title }} />

          <AcademyFrontPageTextContentCss
            dangerouslySetInnerHTML={{
              __html: viewportSize === 'large' ? topSectionData.textsTwoColumns.mainText : topSectionData.textOneColumn,
            }}
          />

          <div className="button-container">
            <GetAccessOrStartCourseButton isPrimary />
          </div>
        </AcademyFrontPageLimitWidthContainerCss>
      </SectionContentTwoColumnsCss>

      <SectionContentFeatureBoxesCss>
        <AcademyFeatureBoxes featureBoxesContent={featureBoxesContent} />
      </SectionContentFeatureBoxesCss>
    </AcademyFrontPageSectionCss>
  );
};

export default AcademyFrontPageTopVideoAndTextSection;

const SectionContentTwoColumnsCss = styled(AcademyFrontPageSectionContentTwoColumnsCss)`
  padding-bottom: 30px;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.large,
    css`
      flex-direction: row-reverse; /* To get video in the second column on large screens */
    `
  )}
`;

const SectionContentFeatureBoxesCss = styled(AcademyFrontPageSectionContentCss)`
  padding-top: 0;
`;

const VideoContainerCss = styled(AcademyFrontPageVideoContainerCss)`
  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.large,
    css`
      margin-top: 8px;
    `
  )}
`;
