import styled, { css } from 'styled-components';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import createMediaQuery from 'util/createMediaQuery';

export const MobileTranscriptToggleButtonContainerCss = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[500]};

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.medium,
    css`
      margin: 0 0.9375rem;
    `
  )}
`;

export const MobileTranscriptToggleButtonCss = styled.button`
  ${buttonWithoutStyle}

  width: 100%;
  text-align: center;
  padding: 0.75rem 0 0.4375rem;
  color: ${({ theme }) => theme.colorTokens.purple[700]};
  background-color: ${({ theme }) => theme.colorTokens.default.white};

  :hover {
    opacity: 1;
  }
`;

export const FlexCss = styled.div`
  padding-bottom: 0.1875rem;
  color: ${({ theme }) => theme.colorTokens.grey[900]};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colorTokens.default.white};

  :focus-visible {
    outline: 0;
  }

  :hover,
  :focus-visible {
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colorTokens.grey[900]};
  }
`;
