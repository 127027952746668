import React from 'react';

const History: React.FC<{
  className?: string;
}> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fill="#2A282D"
      fillRule="evenodd"
      d="M7.195 7.168l-3.46 1.365c-.414.164-.744-.067-.735-.512l.075-3.726c.008-.445.313-.602.676-.347l1.31.909a7.9 7.9 0 0 1 4.566-2.565 7.998 7.998 0 0 1 1.57-.133 7.982 7.982 0 0 1 7.505 5.818 7.526 7.526 0 0 1 .242 1.178v.057c.015.117.028.233.035.35v.014a7.998 7.998 0 0 1-6.098 8.363l-.045.011a8.052 8.052 0 0 1-1.821.208 7.896 7.896 0 0 1-4.533-1.434c-.012-.021-.026-.021-.041-.041a7.97 7.97 0 0 1-3.252-5.114.606.606 0 0 1 1.19-.215 6.77 6.77 0 0 0 7.148 5.563h.02c.104 0 .21-.02.317-.033h.025a6.774 6.774 0 0 0 5.64-4.882l.012-.046.057-.221.03-.122.03-.15c.012-.048.026-.132.036-.199 0-.024 0-.05.012-.075a6.838 6.838 0 0 0-.78-4.334l-.026-.047a3.953 3.953 0 0 0-.116-.194l-.067-.109-.085-.127a5.01 5.01 0 0 0-.114-.165l-.047-.064c-.055-.073-.107-.146-.164-.216a6.81 6.81 0 0 0-3.14-2.205h-.018a5.354 5.354 0 0 0-.248-.077l-.086-.038-.18-.047-.16-.038-.115-.023-.232-.044h-.043a6.767 6.767 0 0 0-6.017 2.101l1.212.847c.365.255.325.595-.085.759zm6.866 6.057a.545.545 0 0 0 0-.77l-2.515-2.522v-4.32c0-.727-1.087-.727-1.087 0v4.545c0 .145.057.283.16.386l2.673 2.681a.542.542 0 0 0 .769 0z"
    />
  </svg>
);

export default History;
