import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { LoaderWrapperCss } from './styles';

const DocumentLoader: FC = () => (
  <LoaderWrapperCss>
    <ContentLoader
      height={670}
      width={380}
      primaryColor={COLOR_TOKENS.grey[600]}
      secondaryColor="#CBCBCB"
      speed={1}
    >
      <rect x="0" y="40" rx="0" ry="0" width="400" height="40" />

      <rect x="0" y="90" rx="0" ry="0" width="150" height="16" />
      <rect x="0" y="110" rx="0" ry="0" width="220" height="10" />
      <rect x="0" y="130" rx="0" ry="0" width="100" height="20" />
      <rect x="0" y="180" rx="0" ry="0" width="400" height="30" />

      <rect x="0" y="220" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="230" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="240" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="240" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="250" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="270" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="280" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="290" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="300" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="310" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="330" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="340" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="350" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="360" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="370" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="390" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="400" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="410" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="420" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="430" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="450" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="460" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="470" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="480" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="490" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="510" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="520" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="530" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="540" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="550" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="570" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="580" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="590" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="600" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="610" rx="0" ry="0" width="350" height="8" />

      <rect x="0" y="630" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="640" rx="0" ry="0" width="400" height="8" />
      <rect x="0" y="650" rx="0" ry="0" width="350" height="8" />
    </ContentLoader>
  </LoaderWrapperCss>
);

export default DocumentLoader;
