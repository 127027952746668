import React, { FC, useEffect, useState, MouseEvent } from 'react';
import ArrowDown3 from 'components/icons/ArrowDown3';
import ArrowUp3 from 'components/icons/ArrowUp3';
import Flex from 'components/ui/Flex';
import { SourceItemType, Sources as SourcesType } from 'pages/AcademyPage/types';
import { useSelector } from 'util/hooks/useSelector';
import { RootState } from 'state/types';
import ExternalLink from 'components/icons/ExternalLink';
import { useLocation } from 'react-router';
import {
  SourceAccordionCss,
  CourseTitleCss,
  LegalSourcesCss,
  LinkCss,
  SourcesWrapperCss,
  TitleCss,
  ModuleTitleCss,
  SourceAccordionButtonCss,
} from './Styles';

interface SourceAccordionProps {
  data: SourcesType;
  isActive: boolean;
}

interface SourcesProps {
  courseTitle: string;
  chapterTitle: string;
  data: (SourcesType | undefined)[];
}

const SourceAccordion: FC<SourceAccordionProps> = ({ data, isActive }) => {
  const [expanded, setExpanded] = useState(isActive);
  const [title, setTitle] = useState();

  const location = useLocation();

  /*
   * We are still exploring the use of 'dypdykk'. In the course 'Krasjkurs i sakførsel
   * for advokatfullmektiger' we have three sources that are included on each module.
   * We have separated the sources into two arrays. The sources that are on every module
   * are always the last three items in the array. There is no need to engineer this
   * further before we have more user insight.
   */

  const newSources = data.items?.slice(0, -3);
  const defaultSources = data.items?.slice(-3);

  useEffect(() => {
    fetch(`/api/academy/module/${data.moduleId}`)
      .then((res) => res.json())
      .then((res) => setTitle(res.title));
  }, [data.moduleId]);

  const handlePersistStoreOnRedirect = (event: MouseEvent): void => {
    /*
     * store returnToCourseBanner in localStorage so
     * that the store persists through page refresh
     * caused by following anchor-tags
     */
    // Do not persist store if user is holding down ctrl, shift or meta key (opening link in new tab)
    if (event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)) {
      return;
    }

    localStorage.setItem('returnToCourseBanner', JSON.stringify({ show: true, path: location.pathname }));
  };

  return (
    <SourceAccordionCss $isActive={isActive}>
      <SourceAccordionButtonCss onClick={() => setExpanded((prev) => !prev)} aria-expanded={expanded} $expanded={expanded}>
        <Flex align="baseline" justify="space-between">
          <ModuleTitleCss>{title}</ModuleTitleCss>
          {expanded ? <ArrowUp3 height={10} width={16} ml1 /> : <ArrowDown3 height={10} width={16} ml1 />}
        </Flex>
      </SourceAccordionButtonCss>

      {expanded && (
        <LegalSourcesCss>
          {newSources && newSources.length > 0 && (
            <>
              {newSources.map((item) => (
                <LinkCss
                  key={item.label}
                  href={item.src}
                  target={item._type === SourceItemType.EXTERNAL ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  onClick={handlePersistStoreOnRedirect}
                >
                  <span>{item.label}</span>
                  {item._type === SourceItemType.EXTERNAL && <ExternalLink ml1 />}
                </LinkCss>
              ))}
              <hr />
            </>
          )}

          {defaultSources?.map((item) => (
            <LinkCss
              key={item.label}
              href={item.src}
              target={item._type === SourceItemType.EXTERNAL ? '_blank' : '_self'}
              rel="noopener noreferrer"
              onClick={handlePersistStoreOnRedirect}
            >
              <span>{item.label}</span>
              {item._type === SourceItemType.EXTERNAL && <ExternalLink ml1 />}
            </LinkCss>
          ))}
        </LegalSourcesCss>
      )}
    </SourceAccordionCss>
  );
};

const Sources: FC<SourcesProps> = ({ courseTitle, chapterTitle, data }) => {
  const currentModuleId = useSelector((state: RootState) => state.ui.akademi.currentModuleId);

  return (
    <SourcesWrapperCss>
      <CourseTitleCss>{courseTitle}</CourseTitleCss>
      <TitleCss>{chapterTitle}</TitleCss>
      {data?.map(
        (source) =>
          source && <SourceAccordion key={source.moduleId} data={source} isActive={source.moduleId === currentModuleId} />
      )}
    </SourcesWrapperCss>
  );
};

export default Sources;
