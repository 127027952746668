import React, { FC, ElementType, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import createSpacing from 'util/createSpacing';
import { RADIUS, COLORS, COLOR_TOKENS } from 'theme/config/defaultTheme';

import SpacingPropTypes from 'models/SpacingPropTypes';
import IconPropTypes from 'components/icons/IconPropTypes';

import Text from 'components/ui/Text';
import Icons from 'components/icons';

enum IconPosition {
  LEFT,
  RIGHT,
}

type ButtonIconPropTypes = IconPropTypes & { name?: string; color?: string };

export interface ButtonPropTypes extends SpacingPropTypes {
  as?: ElementType;
  label?: string;
  _target?: string;
  id?: string;

  className?: string;

  variant?: string;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  dark?: boolean;

  size?: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;

  block?: boolean;
  noLetterSpacing?: boolean;
  center?: boolean;
  rounded?: boolean;
  iconLeft?: ButtonIconPropTypes;
  iconRight?: ButtonIconPropTypes;
  showBothChildrenAndIcons?: boolean;

  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string;
  labelColor?: string;
  fontWeight?: number;

  ariaLabel?: string;
  tabIndex?: number;
  href?: string;
  type?: string;

  onClick?: ((event: React.MouseEvent) => void) | (() => void);
  children?: ReactNode;
  disabled?: boolean;
}

const ButtonCss = styled.button<{
  px?: number;
  $size?: string;
  $variant?: string;
  $rounded?: boolean;
  $fontWeight?: number;
  $backgroundColor?: string;
  $block?: boolean;
  $noLetterSpacing?: boolean;
  $borderColor?: string;
  $borderRadius?: string;
  $center?: boolean;
}>`
  border: none;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  background: none;
  cursor: pointer;
  text-align: left;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  margin: 0;
  flex: 0 0 auto;
  white-space: nowrap;
  position: relative;
  transition: all 0.2s ease 0s;

  ${({ $size }) =>
    $size !== '__none__' &&
    css`
      height: ${$size === 'large' ? '60px' : $size === 'medium' ? '40px' : '24px'};
    `}

  ${({ $variant }) =>
    $variant === 'primary' &&
    css`
      background-color: ${COLORS.primary};
      border-radius: ${RADIUS.all};
      color: ${COLORS.white};
      justify-content: center;
      font-weight: bold;
    `}

  ${({ $variant }) =>
    $variant === 'secondary' &&
    css`
      background-color: ${COLORS.secondary};
      border-radius: ${RADIUS.all};
      color: ${COLORS.white};
      justify-content: center;
      font-weight: bold;
      box-shadow: 0 2px 0 0 ${COLOR_TOKENS.aqua[500]};
    `}


    ${({ $variant, theme }) =>
    $variant === 'tertiary' &&
    css`
      border: 2px solid ${theme.colorTokens.grey[800]};
      border-radius: 4px;

      :hover,
      :focus {
        background-color: ${theme.colorTokens.grey[800]};
        span {
          color: ${theme.colorTokens.default.white} !important;
        }

        svg * {
          fill: ${theme.colorTokens.default.white} !important;
          stroke: ${theme.colorTokens.default.white} !important;
        }
      }
    `}

  ${({ $variant, theme }) =>
    ($variant === 'dark' &&
      css`
        background-color: ${COLORS.base};
        border-radius: ${RADIUS.all};
        color: ${COLORS.white};
        justify-content: center;
        font-weight: bold;
      `) ||
    ($variant === 'filter' &&
      css`
        border: 1px solid ${theme.colorTokens.grey[500]};
        border-radius: 4px;
      `)}

  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: ${RADIUS.all};
    `}

  ${({ $backgroundColor }) =>
    $backgroundColor !== '__none__' &&
    css`
      background-color: ${$backgroundColor};
    `}


  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: 1px solid ${$borderColor};
    `}

  ${({ $fontWeight }) =>
    $fontWeight &&
    css`
      font-weight: ${$fontWeight};
    `}

    ${({ $borderRadius }) =>
    $borderRadius &&
    css`
      border-radius: ${$borderRadius};
    `}

  display: ${({ $block }) => ($block ? 'flex' : 'inline-flex')};
  letter-spacing: ${({ $noLetterSpacing }) => ($noLetterSpacing ? '0' : ' 0.0313rem')};
  width: ${({ $block }) => ($block ? '100%' : 'auto')};
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  align-items: center;
  text-decoration: none;

  ${(props) => createSpacing(props)}

  &:disabled {
    opacity: 0.32;
    pointer-events: none;
  }
`;

const Button: FC<ButtonPropTypes> = ({
  variant,
  primary,
  secondary,
  tertiary,
  dark,
  size,
  small,
  medium,
  large,
  label,
  labelColor,
  iconLeft,
  iconRight,
  showBothChildrenAndIcons,
  children,
  disabled,
  ariaLabel = '',
  tabIndex,
  center,
  block,
  rounded,
  fontWeight,
  backgroundColor,
  noLetterSpacing,
  borderColor,
  borderRadius,
  ...props
}) => {
  let pVariant = '__default__';

  if (primary) {
    pVariant = 'primary';
  } else if (secondary) {
    pVariant = 'secondary';
  } else if (tertiary) {
    pVariant = 'tertiary';
  } else if (dark) {
    pVariant = 'dark';
  } else if (variant) {
    pVariant = variant;
  }

  let pSize = '__none__';

  if (small) {
    pSize = 'small';
  } else if (large) {
    pSize = 'large';
  } else if (medium || pVariant !== '__default__') {
    pSize = 'medium';
  } else if (size) {
    pSize = size;
  }

  let PFontWeight: string | number = '__default__';

  if (small) {
    PFontWeight = 400;
  } else if (large) {
    PFontWeight = 600;
  } else if (medium || pVariant !== '__none__') {
    PFontWeight = 600;
  }

  let pLabelColor = labelColor || COLORS.text;

  if (pVariant === 'primary') {
    pLabelColor = COLORS.white;
  } else if (pVariant === 'secondary') {
    pLabelColor = COLORS.text;
  } else if (pVariant === 'dark') {
    pLabelColor = COLORS.white;
  } else if (size) {
    pLabelColor = size;
  }

  const labelSize = pSize === 'small' ? 0 : pSize === 'large' ? 2 : 1;

  let pIcon: ButtonIconPropTypes | null | undefined = null;
  const icon = iconLeft || iconRight;

  if (pVariant === 'primary') {
    pIcon = {
      size: pSize === 'small' ? 16 : pSize === 'large' ? 22 : 20,
      color: COLORS.white,

      ...icon,
    };
  } else if (pVariant === 'secondary') {
    pIcon = {
      size: pSize === 'small' ? 16 : pSize === 'large' ? 22 : 20,
      color: COLORS.text,
      ...icon,
    };
  } else if (pVariant === 'tertiary') {
    pIcon = {
      size: pSize === 'small' ? 16 : pSize === 'large' ? 22 : 20,
      color: COLOR_TOKENS.grey[800],
      ...icon,
    };
  } else if (pVariant === 'success') {
    pIcon = {
      size: pSize === 'small' ? 16 : pSize === 'large' ? 22 : 20,
      color: COLORS.white,
      ...icon,
    };
  } else if (icon) {
    pIcon = icon;
  }

  if (pVariant === 'primary') {
    pLabelColor = COLORS.white;
  } else if (pVariant === 'secondary') {
    pLabelColor = COLORS.text;
  } else if (pVariant === 'dark') {
    pLabelColor = COLORS.white;
  } else if (size) {
    pLabelColor = size;
  }

  const Icon = (!children || showBothChildrenAndIcons) && pIcon && pIcon.name ? Icons[pIcon.name] : null;
  const iconGap = !label ? '0' : pSize === 'small' ? '5px' : pSize === 'large' ? '7px' : '5px';
  const paddingHorizontal = pSize === 'large' ? 5 : pSize === 'medium' ? 3 : 2;

  const renderIcon = (pos: IconPosition) => {
    const mr = pos === IconPosition.RIGHT ? '0' : iconGap;
    const ml = pos === IconPosition.RIGHT ? iconGap : '0';
    return (
      Icon && pIcon && <Icon mr={mr} ml={ml} size={pIcon.size} color={icon?.color || pIcon.color} {...pIcon} {...pIcon.style} />
    );
  };

  return (
    <ButtonCss
      px={paddingHorizontal}
      $variant={pVariant}
      $size={pSize}
      $fontWeight={PFontWeight || fontWeight}
      $center={center}
      $block={block}
      $rounded={rounded}
      $backgroundColor={backgroundColor}
      $noLetterSpacing={noLetterSpacing}
      $borderColor={borderColor}
      $borderRadius={borderRadius}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      disabled={disabled}
      {...props}
    >
      {!iconRight && renderIcon(IconPosition.LEFT)}
      {label && (
        <Text f={labelSize} color={labelColor || pLabelColor}>
          {label}
        </Text>
      )}
      {iconRight && renderIcon(IconPosition.RIGHT)}
      {children}
    </ButtonCss>
  );
};

export default Button;
