import React, { FC, useCallback, useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import styled from 'styled-components';
import { useViewportInnerHeight } from 'util/hooks/useViewportInnerHeight';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { useViewportInnerWidth } from 'util/hooks/useViewportInnerWidth';
import { useViewportSize } from 'util/hooks/useViewportSize';
import NavbarDropdown from 'containers/NavbarAndGlobalSearchAndErrorContainer/components/Navbar/components/NavbarDropdown/NavbarDropdown';
import { NAVBAR_VIEWPORT_SIZE } from 'containers/NavbarAndGlobalSearchAndErrorContainer/utils';
import { Catch } from '../../../../../../components/error/Catch';
import { InlineError } from '../../../../../../components/error/InlineError';
import Star from '../../../../../../components/icons/Star';
import FavoritesSection from '../../../../../../pages/StartPage/components/FavoritesSection';
import { ButtonCss } from '../../../../../../components/Button/Button';

const NavbarDropdownCss = styled(NavbarDropdown)<{ $position: string }>`
  position: ${(props) => props.$position};

  ${ButtonCss} {
    svg * {
      stroke: ${(props) => props.theme.colorTokens.red[600]};
    }
  }
`;

const ContentWrapperCss = styled.div`
  padding: ${(props) => props.theme.spacing.sm};
`;

const FavoritesDropdown: FC<{
  hasBeigeBackground: boolean;
  globalSearchSectionIsOpen: boolean;
}> = ({ hasBeigeBackground, globalSearchSectionIsOpen }) => {
  const innerHeight = useViewportInnerHeight();
  const [contentHeight, setContentHeight] = useState(0);
  const innerWidth = useViewportInnerWidth();

  const viewportSize = useViewportSize(NAVBAR_VIEWPORT_SIZE, 'small');
  const isLargeViewport = viewportSize === 'large' || viewportSize === 'xlarge';
  const maxDropdownContentHeight = innerHeight - 80;

  const contentRef = useCallback((node: HTMLDivElement | null) => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.borderBoxSize) {
          const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;
          setContentHeight(borderBoxSize.blockSize);
        } else {
          setContentHeight(entry.contentRect.height);
        }
      }
    });

    if (node) {
      resizeObserver.observe(node, { box: 'border-box' });
    }
  }, []);

  return (
    <NavbarDropdownCss
      $position={innerWidth > 700 ? 'relative' : 'initial'}
      id="favorites"
      contentConfig={{
        backgroundColor: hasBeigeBackground ? COLOR_TOKENS.peach[300] : COLOR_TOKENS.default.white,
        height: contentHeight > maxDropdownContentHeight ? `${maxDropdownContentHeight}px` : undefined,
        width: innerWidth > 700 ? '25rem' : 'auto',
        top: innerWidth > 700 ? '2.5rem' : globalSearchSectionIsOpen ? '8.9375rem' : '3.8125rem',
        right: '0',
        left: innerWidth > 700 ? 'auto' : '0',
      }}
      buttonConfig={{
        iconLeft: <Star />,
        label: isLargeViewport ? 'Favoritter' : undefined,
        ariaLabel: 'Åpne favoritter',
      }}
    >
      <CustomScroll heightRelativeToParent="100%">
        <ContentWrapperCss ref={contentRef}>
          <Catch catchId="favoritesFeedHeader" renderError={(props) => <InlineError {...props} />}>
            <FavoritesSection initialLoadCount={4} displayLoadMoreButtons={false} />
          </Catch>
        </ContentWrapperCss>
      </CustomScroll>
    </NavbarDropdownCss>
  );
};

export default FavoritesDropdown;
