import {
  CMS_LIVE_PREVIEW_BASE_URL,
  useJuridikaConfig,
} from 'commonUtils/juridikaConfig';
import React, { FC, useEffect, useState } from 'react';
import { GqlNIKDocument } from 'pages/NIKPage/types';
import Document from './Document';

const DraftDocument: FC<{
  data: GqlNIKDocument;
}> = ({ data }) => {
  const [livePreviewContent, setLivePreviewContent] = useState(null);
  const livePreviewOriginURL = useJuridikaConfig().getString(
    CMS_LIVE_PREVIEW_BASE_URL
  );

  // this is a hack to get the live preview in CMS to work
  useEffect(() => {
    window.addEventListener(
      'message',
      (message: { origin: string; data: string }) => {
        if (message.origin === (livePreviewOriginURL as string)) {
          const messageData = JSON.parse(message.data);
          setLivePreviewContent(messageData);
        }
      }
    );
  }, [livePreviewOriginURL]);

  return (
    <Document
      {...data}
      jsonmlDocument={livePreviewContent || data.content.jsonMl}
      isDraft
    />
  );
};

export default DraftDocument;
