import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const CheckboxOff: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#8F8D90" />
  </Svg>
);

CheckboxOff.defaultProps = {
  size: 18,
  color: COLOR_TOKENS.default.white,
};

export default CheckboxOff;
