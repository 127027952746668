import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import JuridikaLogo from 'icons/Logos/JuridikaLogo';
import JulidikaChristmasLogo from 'icons/Logos/JulidikaChristmasLogo';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

const NavLinkCss = styled(NavLink)`
  min-width: 6.25rem;
  margin-right: 1.25rem;

  ${createMediaQuery(
    425,
    css`
      width: 8.875rem;
    `
  )}
`;

const NavbarLogo: FC = () => {
  const now = new Date();
  const december = 11;
  return (
    <NavLinkCss to="/" aria-label="Gå til forsiden">
      {now.getMonth() === december && now.getDate() >= 1 ? (
        <JulidikaChristmasLogo />
      ) : (
        <JuridikaLogo color={COLOR_TOKENS.red[600]} />
      )}
    </NavLinkCss>
  );
};

export default NavbarLogo;
