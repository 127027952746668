import { GqlError } from 'util/graphql/GqlError';
import * as GqlResult from 'util/graphql/GqlResult';

export interface GqlSearchSuggestions {
  searchSuggestions: GqlSearchSuggestionsResponse;
}

export interface GqlSearchSuggestionsResponse {
  results: GqlSearchResult[] | null;
  error: GqlError | null;
}

export interface GqlSearchResult {
  header: string;
  list: GqlSearchSuggestion[];
}

export interface GqlSearchSuggestion {
  score: number;
  term: string;
  displayValue: string;
  hits: number;
  type: string;
  boost: number;
  metadata: GqlSearchSuggestionMetadata;
  literaturePathElements: GqlSearchSuggestionLiteraturePathElements | null;
}

export interface GqlSearchSuggestionLiteraturePathElements {
  slug: string;
  editionPath: string[];
  key: string;
}

export interface GqlSearchSuggestionMetadata {
  documentPath: string;
  publicationDate: string | null;
  journalTitle: string[] | null;
  issueNumber: string | null;
  firstEditionYear: string | null;
  lastEditionYear: string | null;
  contributors: GqlSearchSuggestionContributor[] | null;
  accessible: boolean;
  availability: 'ANNOUNCED' | 'PREVIEW' | 'COMPLETE';
  hasComment: boolean | null;
}

export interface GqlSearchSuggestionContributor {
  name: string;
}

export interface GqlSearchSuggestionVariables {
  query: string;
  limit?: number;
}

export const placeholderForSuggestSearch = (
  future: GqlResult.Future<GqlSearchSuggestionsResponse> | null
): GqlSearchSuggestionsResponse => {
  if (future && future.type === GqlResult.Type.Ok) {
    return future.data;
  }

  return {
    error: null,
    results: [],
  };
};
