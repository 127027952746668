import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const ForwardArrow: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.7403 4.93237L10.2402 0.182841C9.75878 -0.232941 9 0.104591 9 0.750466V3.25212C3.98034 3.30959 0 4.31562 0 9.07269C0 10.9927 1.23691 12.8948 2.60416 13.8893C3.03081 14.1997 3.63888 13.8102 3.48156 13.3071C2.06456 8.77544 4.15366 7.57237 9 7.50265V10.25C9 10.8969 9.75938 11.2329 10.2402 10.8176L15.7403 6.06762C16.0863 5.76881 16.0868 5.23159 15.7403 4.93237Z"
      fill={color}
    />
  </Svg>
);

ForwardArrow.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default ForwardArrow;
