import React from 'react';

const NewsPaper: React.FC<{
  width?: number;
  height?: number;
}> = ({ width = 22, height = 18 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0 0 22 18">
    <g fill="none" fillRule="evenodd">
      <path d="M-2 1h26v16H-2z" />
      <g stroke="#e00e37" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M18.5 17a2.5 2.5 0 0 1-2.5-2.5V1" />
        <path d="M1 14.5V1h15.034v3H21v10.5a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 1 14.5z" />
      </g>
      <path fill="#e00e37" d="M3 4h5v5H3zM9 4h5v2H9zM9 7h5v2H9zM3 10h11v2H3zM3 13h11v2H3z" />
    </g>
  </svg>
);

export default NewsPaper;
