import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as ReduxPersist from 'redux-persist';

import { decodeToken } from 'util/jwt/decodeToken';
import { JuridikaIdToken } from '../../models/jwt/JuridikaIdToken';

import * as jwtActions from '../jwt/jwtActions';

import { SessionState } from './types';
import * as sessionActions from './sessionActions';

type SessionAction = ActionType<typeof sessionActions> | ActionType<typeof jwtActions> | { type: typeof ReduxPersist.REHYDRATE };

export const sessionInitialState: SessionState = { tokenSource: undefined };

// sessionReducers is the persisted part of login state. loginReducers is not persisted
export const sessionReducers: Reducer<SessionState, SessionAction> = (state = { tokenSource: undefined }, action) => {
  switch (action.type) {
    case getType(jwtActions.acquireJwtSuccess):
      return {
        ...state,
        idTokenPayload: action.payload.jwt.idToken && decodeToken<JuridikaIdToken>(action.payload.jwt.idToken),
        tokenSource: action.payload.tokenSource,
        ...action.payload.jwt,
      };
    case getType(jwtActions.acquireJwtFailure):
    case getType(sessionActions.resetSession):
      return {
        ...state,
        idToken: '',
        accessToken: '',
        idTokenPayload: {},
        tokenSource: undefined,
      };

    default: {
      return state;
    }
  }
};
