import React from 'react';

import Heading, { HeadingProps } from '../Heading/Heading';
import { fontSize, PromotedContentSize } from './utils/fontSize';

interface PromotedContentHeading extends HeadingProps {
  size?: PromotedContentSize;
}

const PromotedContentHeading: React.FC<PromotedContentHeading> = ({
  size,
  margin: { top = 0.625, bottom = 0.9375 } = {},
  fontConfig,
  ...headingProps
}) => (
  <Heading
    margin={{ top, bottom }}
    {...headingProps}
    fontConfig={{
      size: fontSize(size),
      weight: 500,
      letterSpacing: 0.0125,
      ...fontConfig,
    }}
  />
);

export default PromotedContentHeading;
