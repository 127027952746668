import { Store, RootState } from 'state/types';

export const loadPreloadedState = (): RootState => {
  const preloadedState = (window as any).PRELOADED_STATE;
  delete (window as any).PRELOADED_STATE;
  return preloadedState;
};

export const waitStoreCondition = (store: Store, condition: (state: RootState) => boolean): Promise<void> => {
  if (condition(store.getState())) return Promise.resolve();

  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      if (condition(store.getState())) {
        unsubscribe();
        resolve();
      }
    });
  });
};
