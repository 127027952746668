import {
  FootnoteBox,
  FootnoteBoxSymbol,
  FootnoteToggleButton,
} from 'pages/TextbookReaderPage/bits/components/BitsTypography';
import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';
import createFontStyles from 'util/createFontStyles';
import Svg from 'components/ui/Svg';
import { FootNoteBoxContentCss } from './styles';

export const NIKFootnoteToggleButton = styled(FootnoteToggleButton)`
  background: transparent;
  border: none;
  padding: 0 0.1rem;
  margin-right: 0.25rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  border: 1px solid ${({ theme }) => theme.colorTokens.purpleVibrant[900]};

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
    color: ${({ theme }) => theme.colorTokens.default.white};
    border: 1px solid ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
  }

  :focus-visible {
    border: 1px solid ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    background: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
    color: ${({ theme }) => theme.colorTokens.default.white};
  }

  :active {
    border: 1px solid ${({ theme }) => theme.colorTokens.purpleVibrant[700]};
    background: ${({ theme }) => theme.colorTokens.purpleVibrant[700]};
  }
`;

export const NIKInformationToggleButton = styled.button`
  all: unset;
  margin-left: 0.1rem;
  svg {
    circle:first-of-type {
      stroke: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    }
    circle:last-of-type {
      fill: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    }
    line {
      stroke: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    }
  }

  :hover {
    cursor: pointer;
    svg {
      circle:first-of-type {
        fill: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
        stroke: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
      }
      circle:last-of-type {
        fill: ${({ theme }) => theme.colorTokens.default.white};
      }
      line {
        stroke: ${({ theme }) => theme.colorTokens.default.white};
      }
    }
  }

  :focus-visible {
    box-sizing: border-box;
    border: none;
    outline: 1px solid ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    outline-offset: 1px;
  }
`;

export const NIKFootnoteBox = styled(FootnoteBox)<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'block' : 'none')};
  overflow: hidden;
  margin: 0.75rem 0;
  padding: 1rem;
  background: ${(props) => props.theme.colorTokens.peach[300]};

  ${createFontStyles({
    lineHeight: 1.375,
    size: 0.938,
  })}
`;

export const NIKFootnoteBoxSymbol = styled(FootnoteBoxSymbol)`
  font-size: 0.75rem;
  font-weight: 700;
  vertical-align: top;
  padding-right: 0.3rem;
`;

interface Props {
  type: 'information' | 'reference';
  children: ReactNode | ReactNode[];
}

const InformationIcon = (
  <Svg
    width={13}
    height={13}
    viewBox="-10 -10 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="0" r="9" fill="none" stroke="black" strokeWidth="1" />
    <line x1="0" y1="5" x2="0" y2="-1.85" stroke="black" strokeWidth="1.5" />
    <circle cx="0" r="0.75" cy="-4.25" fill="black" />
  </Svg>
);

const InlineFootnote: FC<Props> = ({ type, children }) => {
  const [visible, setVisible] = useState(false);

  const symbol = type === 'reference' ? 'ref' : 'i';
  const toggleFootnote = () => setVisible(!visible);
  return (
    <>
      {type === 'reference' ? (
        <sup>
          <NIKFootnoteToggleButton onClick={toggleFootnote}>
            {symbol}
          </NIKFootnoteToggleButton>
        </sup>
      ) : (
        <NIKInformationToggleButton onClick={toggleFootnote}>
          {InformationIcon}
        </NIKInformationToggleButton>
      )}
      <NIKFootnoteBox $visible={visible}>
        <NIKFootnoteBoxSymbol>{symbol}</NIKFootnoteBoxSymbol>
        <FootNoteBoxContentCss>{children}</FootNoteBoxContentCss>
      </NIKFootnoteBox>
    </>
  );
};

export default InlineFootnote;
