import React, { FC } from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const DownloadIcon3: FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
    <g clipPath="url(#clip0_4931_42195)">
      <path
        d="M11.75 1.75C11.75 1.05859 11.1914 0.5 10.5 0.5C9.80859 0.5 9.25 1.05859 9.25 1.75V11.2305L6.38281 8.36328C5.89453 7.875 5.10156 7.875 4.61328 8.36328C4.125 8.85156 4.125 9.64453 4.61328 10.1328L9.61328 15.1328C10.1016 15.6211 10.8945 15.6211 11.3828 15.1328L16.3828 10.1328C16.8711 9.64453 16.8711 8.85156 16.3828 8.36328C15.8945 7.875 15.1016 7.875 14.6133 8.36328L11.75 11.2305V1.75ZM3 14.25C1.62109 14.25 0.5 15.3711 0.5 16.75V18C0.5 19.3789 1.62109 20.5 3 20.5H18C19.3789 20.5 20.5 19.3789 20.5 18V16.75C20.5 15.3711 19.3789 14.25 18 14.25H14.0352L12.2656 16.0195C11.2891 16.9961 9.70703 16.9961 8.73047 16.0195L6.96484 14.25H3ZM17.375 18.3125C16.8555 18.3125 16.4375 17.8945 16.4375 17.375C16.4375 16.8555 16.8555 16.4375 17.375 16.4375C17.8945 16.4375 18.3125 16.8555 18.3125 17.375C18.3125 17.8945 17.8945 18.3125 17.375 18.3125Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4931_42195">
        <rect width="20" height="20" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </Svg>
);

export default DownloadIcon3;

DownloadIcon3.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};
