import SuccessFilled from 'components/icons/SuccessFilled';
import Flex from 'components/ui/Flex';
import React, { FC } from 'react';
import Progressbar from '../Progressbar/Progressbar';

export const ProgressbarWithCheckmark: FC<{ progress: number; isCompleted: boolean; className?: string }> = ({
  progress = 0,
  isCompleted,
  className = '',
}) => {
  // make sure progress is a number so we don't get NaN/inifity-values
  const safeProgressValue = !Number.isNaN(Number(progress)) ? progress : 0;

  return (
    <Flex align="center" className={className}>
      <Progressbar progress={safeProgressValue} />
      {isCompleted && <SuccessFilled ml2 size={22} />}
    </Flex>
  );
};

export default ProgressbarWithCheckmark;
