import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BylineWrapperCss = styled.div<{ useFlexLayout: boolean }>`
  margin: 1.5rem 0;
  display: ${({ useFlexLayout }) => (useFlexLayout ? 'flex' : 'block')};
  align-items: center;

  div:first-of-type {
    margin-right: ${({ useFlexLayout }) => (useFlexLayout ? '1.25rem' : '0')};
  }

  div:last-of-type {
    margin-top: ${({ useFlexLayout }) => (useFlexLayout ? '0' : '0.6rem')};
  }
`;

export const ContributorAvatarImageButtonCss = styled.button<{
  imageUrl: string;
  selected: boolean;
  zIndex: number;
  selectedZIndex: number;
  hasMultipleAuthors: boolean;
}>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transform: ${({ selected, hasMultipleAuthors }) =>
    selected && hasMultipleAuthors ? `scale(1.15)` : 'scale(1)'};
  transform-origin: bottom;
  border: 0.5px solid ${({ theme }) => theme.colorTokens.default.black};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  background-size: cover;
  margin-right: -0.4rem;
  z-index: ${({ selected, zIndex, selectedZIndex }) =>
    selected ? selectedZIndex : zIndex};
  pointer-events: ${({ selected }) => (selected ? 'none' : 'initial')};

  :hover {
    opacity: 1;
    cursor: pointer;
  }

  :active {
    border: 0.5px solid ${({ theme }) => theme.colorTokens.default.black};
  }

  :focus-visible {
    border: 0.5px solid ${({ theme }) => theme.colorTokens.default.black};
    outline: 1.5px solid ${({ theme }) => theme.colorTokens.default.black};
  }
`;

export const BylineContentCss = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-start: 0;
  margin-block-end: 0;
  letter-spacing: 0.01875rem;

  h3 {
    all: unset;
    margin-bottom: 0.2rem;
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: normal;
    font-size: 1rem !important;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colorTokens.default.black};
    font-size: 0.875rem;
    font-weight: 400;
    font-style: italic;
    line-height: 130%;
  }
`;

export const ContributorLink = styled(Link)`
  color: ${({ theme }) => theme.colorTokens.default.black};
  width: fit-content;
  text-decoration-thickness: 0.07rem;
  text-underline-offset: 0.125rem;
  :focus-visible {
    text-decoration: none;
    border: none;
    outline: 1px solid ${({ theme }) => theme.colorTokens.default.black};
  }
`;
