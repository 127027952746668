import React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Menu: React.FC<IconPropTypes> = ({ color, width, height, ...props }) => (
  <Svg className="c-icon c-icon__menu" width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...props}>
    <g fill={color} fillRule="evenodd">
      <rect width={height} height="2.5" />
      <rect width={height} height="2.5" y="6" />
      <rect width={height} height="2.5" y="12" />
    </g>
  </Svg>
);

Menu.defaultProps = {
  color: COLOR_TOKENS.purple[800],
  width: 16,
  height: 15,
};

export default Menu;
