import Svg from 'components/ui/Svg';
import * as React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

interface StarIconPropTypes extends IconPropTypes {
  stroke?: string;
}

const Star: React.FC<StarIconPropTypes> = ({ color, size, stroke, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g strokeWidth="0.789473684" fill={color} fillRule="evenodd">
      <path
        stroke={stroke}
        d="M3.44291686,13.7256155 L7.49972685,11.6075071 L7.68241785,11.7028861 L11.5566614,13.7255452 C11.9033168,13.9063682 11.9809877,13.8503063 11.9157722,13.4714625 L11.140026,8.98282372 L11.2899712,8.8376547 L14.4240798,5.80337682 C14.701484,5.53470137 14.6731188,5.44814666 14.287435,5.39223408 L9.75078788,4.73734988 L9.65861799,4.55199594 L7.72063698,0.654709699 C7.54867432,0.308014366 7.45102732,0.308072096 7.27869582,0.654976729 L5.24997826,4.73734254 L5.0450305,4.76692592 L0.713101914,5.39222238 C0.326648159,5.44809039 0.298441553,5.53458994 0.576319445,5.80332193 L3.86041975,8.98281386 L3.82489135,9.1884578 L3.08489951,13.4716466 C3.01919261,13.8507717 3.09650036,13.9065254 3.44291686,13.7256155 Z"
      />
      <path
        stroke={stroke}
        d="M3.44291686,13.7256155 L7.49972685,11.6075071 L7.68241785,11.7028861 L11.5566614,13.7255452 C11.9033168,13.9063682 11.9809877,13.8503063 11.9157722,13.4714625 L11.140026,8.98282372 L11.2899712,8.8376547 L14.4240798,5.80337682 C14.701484,5.53470137 14.6731188,5.44814666 14.287435,5.39223408 L9.75078788,4.73734988 L9.65861799,4.55199594 L7.72063698,0.654709699 C7.54867432,0.308014366 7.45102732,0.308072096 7.27869582,0.654976729 L5.24997826,4.73734254 L5.0450305,4.76692592 L0.713101914,5.39222238 C0.326648159,5.44809039 0.298441553,5.53458994 0.576319445,5.80332193 L3.86041975,8.98281386 L3.82489135,9.1884578 L3.08489951,13.4716466 C3.01919261,13.8507717 3.09650036,13.9065254 3.44291686,13.7256155 Z"
      />
    </g>
  </Svg>
);

Star.defaultProps = {
  color: 'none',
  size: 16,
  stroke: COLOR_TOKENS.grey[700],
};

export default Star;
