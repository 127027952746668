export type PromotedContentSize = 'sm' | 'md' | 'lg';

export interface SizeProps {
  size?: PromotedContentSize;
}

export const fontSize = (size?: PromotedContentSize): number => {
  switch (size) {
    case 'sm':
      return 1.125;
    case 'lg':
      return 1.8;
    default:
      return 1.25;
  }
};
