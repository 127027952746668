import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';

import { ResponsiveFontType, ColorTokenValues } from 'theme/config/types';
import createFontStyles from 'util/createFontStyles';

export type ButtonType = 'primary' | 'secondary' | 'plain';
export type ButtonSizeType = 'lg' | 'md' | 'sm';

interface PaddingConfig {
  topBottom: string;
  sides: string;
}

export interface TransientButtonAndLinkStyleProps {
  $fontConfig?: ResponsiveFontType;
  $variant?: ButtonType;
  $fullWidth?: boolean;
  $muted?: boolean;
  $customWidth?: number;
  $size?: ButtonSizeType;
  $color?: ColorTokenValues;
  $backgroundColor?: ColorTokenValues;
  $rounded?: boolean;
  $hasTextDecoration?: boolean;
  $hasTextDecorationOnHover?: boolean;
  $justifyContent?: 'center' | 'space-between';
  $alignItems?: 'center' | 'baseline';
  $paddingConfig?: PaddingConfig;
}

export interface ButtonAndLinkIconProps {
  leftIcon?: React.FC | JSX.Element;
  rightIcon?: React.FC | JSX.Element;
  children?: React.ReactNode;
}

export interface ButtonAndLinkContentStyleProps {
  $color?: ColorTokenValues;
  $variant?: ButtonType;
}

export type ButtonAndLinkProps = ButtonAndLinkIconProps & TransientButtonAndLinkStyleProps;

const DEFAULT_PADDING_CONFIG: { [key in ButtonSizeType]: PaddingConfig } = {
  sm: {
    topBottom: '0.5rem',
    sides: '1rem',
  },
  md: {
    topBottom: '1rem',
    sides: '1.5rem',
  },
  lg: {
    topBottom: '1.5rem',
    sides: '2rem',
  },
};

const getBackgroundColorFromVariant = (props: ThemedStyledProps<TransientButtonAndLinkStyleProps, DefaultTheme>) => {
  switch (props.$variant) {
    case 'primary':
      return props.theme.colorTokens.red[600];
    case 'secondary':
      return props.theme.colorTokens.purple[600];
    default:
      return 'transparent';
  }
};

const getFontAndIconColorFromVariant = (props: ThemedStyledProps<TransientButtonAndLinkStyleProps, DefaultTheme>) => {
  switch (props.$variant) {
    case 'primary':
    case 'secondary':
      return props.theme.colorTokens.default.white;
    default:
      return props.theme.colorTokens.default.black;
  }
};

const getFontSizeFromSize = (props: ThemedStyledProps<TransientButtonAndLinkStyleProps, DefaultTheme>) => {
  switch (props.$size) {
    case 'md':
    case 'lg':
      return 1.25;
    default:
      return 1;
  }
};

const getFontWeightFromSize = (props: ThemedStyledProps<TransientButtonAndLinkStyleProps, DefaultTheme>) => {
  switch (props.$size) {
    case 'md':
    case 'lg':
      return 600;
    default:
      return 500;
  }
};

const getPadding = (size?: ButtonSizeType, paddingConfig?: PaddingConfig) => {
  const config = paddingConfig || (size ? DEFAULT_PADDING_CONFIG[size] : null);

  if (!config) return;

  return `${config.topBottom} ${config.sides}`;
};

export const buttonAndLinkCss = css<TransientButtonAndLinkStyleProps>`
  padding: ${({ $size, $paddingConfig }) => getPadding($size, $paddingConfig)};
  width: ${(props) => (props.$fullWidth && '100%') || (props.$customWidth && `${props.$customWidth}rem`)};
  display: ${(props) => (props.$fullWidth ? 'flex' : 'inline-flex')};
  justify-content: ${(props) => props.$justifyContent || 'center'};
  align-items: ${(props) => props.$alignItems || 'center'};
  ${({ $muted }) =>
    $muted &&
    css`
      opacity: 0.5;
    `}
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: ${(props) => props.$rounded && '0.25rem'};
  background: ${(props) => (props.$backgroundColor ? props.$backgroundColor : getBackgroundColorFromVariant(props))};
  color: ${(props) => (props.$color ? props.$color : getFontAndIconColorFromVariant(props))};
  text-decoration: ${(props) => (props.$hasTextDecoration ? 'underline' : 'none')};

  ${(props) =>
    createFontStyles({
      family: props.$fontConfig?.family,
      size: props.$fontConfig?.size || getFontSizeFromSize(props),
      weight: props.$fontConfig?.weight || getFontWeightFromSize(props),
      lineHeight: props.$fontConfig?.lineHeight,
      letterSpacing: props.$fontConfig?.letterSpacing,
      mediaQueries: props.$fontConfig?.mediaQueries,
    })};

  &:hover {
    opacity: 0.85;
    text-decoration: ${(props) => (props.$hasTextDecorationOnHover ? 'underline' : 'none')};
  }

  &:disabled {
    opacity: 0.32;
    pointer-events: none;
  }
`;

export const ButtonAndLinkContentWrapper = styled.span<{ margin?: string }>`
  margin: ${(props) => props.margin};
  display: inline-flex;
  align-items: center;
`;

export const ButtonAndLinkIconWrapperCSS = styled(ButtonAndLinkContentWrapper)<
  {
    isFunctionComponent: boolean;
  } & ButtonAndLinkContentStyleProps
>`
  svg * {
    fill: ${({ isFunctionComponent, $color, $variant, theme }) => {
      if (isFunctionComponent) {
        return $color || getFontAndIconColorFromVariant({ $variant, theme });
      }
    }}};
  }
`;
