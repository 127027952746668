import { useSelector } from 'util/hooks/useSelector';
import { RootState } from 'state/types';
import { authenticatedSelector } from 'state/login/loginSelectors';
import { useUserHasAkademiBeta } from '../utils';

export const useIsUserLoggedInAndHasAkademi = () => {
  const hasAkademiBeta = useUserHasAkademiBeta();
  const isAuthenticated = useSelector((state: RootState) => authenticatedSelector(state));

  const isUserAuthenticatedAndHasAkademi = hasAkademiBeta && isAuthenticated;

  return isUserAuthenticatedAndHasAkademi;
};
