import * as React from 'react';

const Download2Icon: React.FC = () => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 16V19H1V16H15ZM9.1943 0V10.3518L14.3276 5.09375L16 6.80555L8 15L0 6.80555L1.6723 5.09375L6.80562 10.3518V0H9.1943Z"
      fill="#5C5B5F"
    />
  </svg>
);

export default Download2Icon;
