import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'state/types';
import { NIK_TOOLBAR_HEIGHT } from '../constants';

const NIKToolbar: FC = () => {
  const nikToolbarTitle = useSelector(
    (state: RootState) => state.ui.nik.nikToolbarTitle
  );

  return (
    <NIKToolbarCss>
      <span>{nikToolbarTitle}</span>
    </NIKToolbarCss>
  );
};

const NIKToolbarCss = styled.nav`
  height: ${NIK_TOOLBAR_HEIGHT}px;

  background-color: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  color: ${({ theme }) => theme.colorTokens.default.white};
  font-weight: 500;
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
  }
`;

export default NIKToolbar;
