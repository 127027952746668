import * as React from 'react';
import styled, { css } from 'styled-components';

import { FONT_FAMILIES } from 'theme/config/defaultTheme';
import PromotedContentLink from '../PromotedContent/PromotedContentLink';
import { ButtonAndLinkProps } from '../Button/ButtonAndLinkCss';
import FavoriteButton, { FavoriteButtonProps } from '../FavoriteButton';

const FavoriteCss = styled.div<{ left: boolean }>`
  display: flex;

  align-items: flex-start;
  line-height: ${(props) => props.theme.spacing.md};

  ${({ left }) => !left && `justify-content: space-between;`}
`;

const TitleCss = styled.h2`
  margin: 0;
`;

const SubtitleCss = styled.div`
  font-style: italic;
`;

const ButtonWrapper = styled.div<{ left: boolean }>`
  margin: ${(props) => props.theme.spacing.xs} 0 0 ${(props) => props.theme.spacing.xs};

  ${({ left }) =>
    left &&
    css`
      margin-left: 0;
      button {
        padding-left: 0;
      }
    `}
`;

const Favorite: React.FC<
  {
    title: string;
    subtitle?: string | JSX.Element;
    titlePath: string;
    contributorsAndEditionInfo?: JSX.Element | string;
    buttonConfig?: ButtonAndLinkProps;
    iconLeft?: boolean;
  } & FavoriteButtonProps
> = ({
  title,
  titlePath,
  subtitle,
  contributorsAndEditionInfo,
  isFavorite,
  collection,
  mutationVariables,
  graphqlResource,
  buttonConfig,
  iconLeft,
}) => {
  const renderFavoriteButton = (
    <ButtonWrapper left={!!iconLeft}>
      <FavoriteButton
        isFavorite={!!isFavorite}
        collection={collection}
        mutationVariables={mutationVariables}
        graphqlResource={graphqlResource}
        {...buttonConfig}
      />
    </ButtonWrapper>
  );

  return (
    <FavoriteCss left={!!iconLeft}>
      {iconLeft && renderFavoriteButton}
      <div>
        <TitleCss>
          <PromotedContentLink
            to={titlePath}
            $fontConfig={{
              family: FONT_FAMILIES.secondary,
              size: 1.125,
              weight: 600,
            }}
          >
            {title}
          </PromotedContentLink>
        </TitleCss>
        <SubtitleCss>{subtitle}</SubtitleCss>
        {contributorsAndEditionInfo && <div>{contributorsAndEditionInfo}</div>}
      </div>
      {!iconLeft && renderFavoriteButton}
    </FavoriteCss>
  );
};

export default Favorite;
