import Flex from 'components/ui/Flex';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export const AccessDeniedCss = styled.div<{ $whiteBackgroundColor?: boolean; $isSmall?: boolean }>`
  background-color: ${({ $whiteBackgroundColor, theme }) =>
    $whiteBackgroundColor ? theme.colorTokens.default.white : theme.colorTokens.peach[300]};
  padding: 2rem;
  max-width: 50rem;
  border-radius: 0.75rem;
  margin: 5rem 1.5625rem;

  ${createMediaQuery(
    850,
    css`
      margin: 5rem auto;
    `
  )}

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      margin: 5rem 1.5625rem;
      > div:first-of-type {
        flex-direction: column;
        align-items: center;

        > svg {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    `}
`;

export const MessageCss = styled.div`
  margin-top: 0.125rem;
  margin-bottom: 2rem;

  em {
    font-weight: 600;
  }
`;

export const TitleCss = styled.h2`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 1.75rem;
`;

export const FooterCss = styled(Flex)<{ $isLarge?: boolean }>`
  flex-direction: column;

  ${({ $isLarge }) =>
    $isLarge &&
    css`
      flex-direction: row;

      div {
        margin: 0;
      }
    `}
`;

export const FooterItemCss = styled(Flex)`
  margin: 0.75rem 0;
`;

export const ChatButtonCss = styled.a`
  padding-left: 0;
  letter-spacing: normal;

  span {
    font-weight: 400;
    text-decoration: underline;
    color: ${({ theme }) => theme.colorTokens.blue[700]};
  }

  svg {
    margin-right: 1rem;
  }
`;

export const LockedSimpleCss = styled.div`
  color: #3b4146;
  font-size: 0.875rem;
  display: flex;
`;

export const MessageSimpleCss = styled.div`
  margin-top: 0.125rem;
`;
