import * as React from 'react';

import {
  ButtonAndLinkContentStyleProps,
  ButtonAndLinkContentWrapper,
  ButtonAndLinkIconProps,
  ButtonAndLinkIconWrapperCSS,
} from './ButtonAndLinkCss';

const getIconWrapperMargin = (position: 'right' | 'left', hasMargin: boolean) => {
  if (!hasMargin) return undefined;

  return position === 'right' ? '0 0 0 0.5rem' : '0 0.5rem 0 0';
};

export const ButtonAndLinkIconWrapper: React.FC<
  {
    icon?: React.FC | JSX.Element;
    position: 'right' | 'left';
    hasMargin: boolean;
  } & ButtonAndLinkContentStyleProps
> = ({ icon: Icon, hasMargin, position, ...styleProps }) => {
  if (!Icon) return null;

  return (
    <ButtonAndLinkIconWrapperCSS
      margin={getIconWrapperMargin(position, hasMargin)}
      isFunctionComponent={typeof Icon === 'function'}
      {...styleProps}
    >
      {typeof Icon === 'function' ? <Icon /> : Icon}
    </ButtonAndLinkIconWrapperCSS>
  );
};

export const ButtonAndLinkContent: React.FC<ButtonAndLinkIconProps & ButtonAndLinkContentStyleProps> = ({
  children,
  leftIcon,
  rightIcon,
  ...styleProps
}) => (
  <>
    <ButtonAndLinkIconWrapper icon={leftIcon} position="left" hasMargin={!!children || !!rightIcon} {...styleProps} />
    {children && <ButtonAndLinkContentWrapper>{children}</ButtonAndLinkContentWrapper>}
    <ButtonAndLinkIconWrapper icon={rightIcon} position="right" hasMargin={!!children} {...styleProps} />
  </>
);
