import { FormState } from '../components/ContactUsForm';

interface HSForm {
  fields: {
    objectTypeId: string;
    name: string;
    value: string;
  }[];
  legalConsentOptions: {
    consent: {
      consentToProcess: boolean;
      text: string;
      communications: {
        value: boolean;
        subscriptionTypeId: number;
        text: string;
      }[];
    };
  };
}

export const mapFormInput = (formState: FormState): HSForm => {
  return {
    fields: [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: formState.email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: formState.firstname,
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: formState.lastname,
      },
      {
        objectTypeId: '0-1',
        name: 'company',
        value: formState.companyName,
      },
      {
        objectTypeId: '0-1',
        name: 'phone',
        value: formState.number,
      },
      {
        objectTypeId: '0-5',
        name: 'content',
        value: formState.description,
      },
      {
        objectTypeId: '0-5',
        name: 'subject',
        value: formState.subject,
      },
    ],
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Juridika to store and process my personal data.',
        communications: [
          {
            value: formState.shouldReceiveNewsletter,
            subscriptionTypeId: 142604203,
            text: 'Yes, send me news and updates.',
          },
        ],
      },
    },
  };
};
