import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import SuccessStatusCircle from 'icons/SuccessStatusCircle';
import WarningStatusCircle from 'icons/WarningStatusCircle';
import { COLORS } from 'theme/config/defaultTheme';
import styled, { css } from 'styled-components';
import { Button } from './Button/Button';

const NewsLetterSignupFormCss = styled.form`
  text-align: right;
`;

const NoticeCss = styled.div<{ $success: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  margin-bottom: 0.625rem;
  padding: 0.4375rem 0.5rem;
  max-height: 62.5rem;

  ${({ $success }) =>
    $success
      ? css`
          color: #055600;
          border: solid 0.125rem #0a9500;
        `
      : css`
          color: ${({ theme }) => theme.colorTokens.red[600]};
          border: solid 0.125rem ${({ theme }) => theme.colorTokens.red[600]};
        `}
`;

const InputCss = styled.input`
  background: transparent;
  border: none;
  outline: none;
  margin-bottom: 0.625rem;
  width: 100%;
  display: block;
  padding: 0.625rem 0.9375rem;
  background: #4a4a4a;
  color: ${({ theme }) => theme.colorTokens.default.white};

  ::placeholder {
    color: ${({ theme }) => theme.colorTokens.grey[600]};
  }
`;

export interface HubspotFormField {
  objectTypeId: string;
  name: string;
  value: string;
}

export interface HubspotForm {
  fields: [
    {
      objectTypeId: string;
      name: string;
      value: string;
    }
  ];
  legalConsentOptions: {
    consent: {
      consentToProcess: boolean;
      text: string;
      communications: [
        {
          value: boolean;
          subscriptionTypeId: number;
          text: string;
        }
      ];
    };
  };
}

const IHubspotFormState: HubspotForm = {
  fields: [
    {
      objectTypeId: '0-1',
      name: 'email',
      value: '',
    },
  ],
  legalConsentOptions: {
    consent: {
      consentToProcess: true,
      text: 'Jeg samtykker til at Juridika kan behandle mine personopplysninger for å sende meg nyhetsbrev.',
      communications: [
        {
          value: true,
          subscriptionTypeId: 999,
          text: 'Jeg samtykker til å motta nyhetsbrev med markedsføring fra Juridika.',
        },
      ],
    },
  },
};

export interface Notice {
  status: 'success' | 'error';
  message: string;
}

const SIGNUP_SUCCESS_MESSAGE = 'Takk! Du er nå påmeldt vårt nyhetsbrev';
const SIGNUP_ERROR_MESSAGE = 'Påmeldingen feilet';

const emailValidationSchema = Yup.object({
  email: Yup.string().email('Ugyldig epost').required('Mangler e-post'),
});

const NewsletterSignupForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [notice, setNotice] = useState<Notice | null>(null);
  const [formState, setFormState] = useState<HubspotForm>(IHubspotFormState);
  const userEmailValue = formState.fields[0].value;

  useEffect(() => {
    if (notice?.status === 'success') {
      setTimeout(() => setNotice(null), 3000);
    }
  }, [notice]);

  const subscribeToNewsLetter = async () => {
    setLoading(true);
    await fetch(`/api/hubspot/form/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formState),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status !== 'error') {
          setNotice({ status: 'success', message: SIGNUP_SUCCESS_MESSAGE });
        } else {
          setNotice({ status: 'error', message: SIGNUP_ERROR_MESSAGE });
        }
      })
      .catch(() => setNotice({ status: 'error', message: SIGNUP_ERROR_MESSAGE }))
      .finally(() => setLoading(false));
  };

  const validateEmail = async (value: string, callback?: () => void) => {
    try {
      await emailValidationSchema.validate({ email: value });

      setNotice(null);
      if (callback) {
        callback();
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorMsg = error.message;

        setFormErrors([errorMsg]);
        setNotice({ status: 'error', message: errorMsg });
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { fields } = formState;
    setFormState({
      ...formState,
      fields: [
        {
          ...fields[0],
          value,
        },
      ],
    });

    if (formErrors.length > 0) {
      validateEmail(value);
    }
  };

  const submitForm = () => {
    subscribeToNewsLetter();
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    validateEmail(userEmailValue, submitForm);
  };

  return (
    <NewsLetterSignupFormCss onSubmit={onSubmit}>
      {notice && (
        <NoticeCss $success={notice.status === 'success'}>
          {notice.status === 'success' ? (
            <SuccessStatusCircle color={COLORS.success} mr2 />
          ) : (
            <WarningStatusCircle color={COLORS.danger} mr2 />
          )}
          <span>{notice.message}</span>
        </NoticeCss>
      )}
      <InputCss
        type="email"
        name="email"
        value={userEmailValue}
        onChange={onChange}
        placeholder="Din e-postadresse"
        aria-label="Din e-postadresse"
      />
      <Button type="submit" $variant="primary" $size="sm" disabled={loading} $fontConfig={{ weight: 600 }} $rounded>
        Send påmelding
      </Button>
    </NewsLetterSignupFormCss>
  );
};

export default NewsletterSignupForm;
