import {
  ThemeType,
  FontFamilyType,
  BreakpointsType,
  ColorTokens,
  SpacingType,
  FontSizeTypes,
  ColorTypes,
  RadiusTypes,
  AcademyThemePart,
} from './types';

export const COLOR_TOKENS: ColorTokens = {
  red: {
    100: '#FEF1F3',
    200: '#FDE8EA',
    300: '#FBD5DA',
    400: '#F17583',
    500: '#EB283F',
    600: '#D6142B',
    700: '#BB1125',
    800: '#910818',
    900: '#600611',
  },
  purple: {
    100: '#F1EEF4',
    200: '#E1DEE5',
    300: '#C6C0CE',
    400: '#9C94AA',
    500: '#67597C',
    600: '#463460',
    700: '#3D265E',
    800: '#291547',
    900: '#1D0A38',
  },
  purpleVibrant: {
    100: '#EAE4F1',
    200: '#D8CCE5',
    300: '#C0B2D1',
    400: '#A191B8',
    500: '#8167A4',
    600: '#6B4B96',
    700: '#5C388C',
    800: '#4E2683',
    900: '#370974',
  },
  aqua: {
    100: '#ECF3F4',
    200: '#EAF9F8',
    300: '#D0F0EE',
    400: '#B7E8E6',
    500: '#ADD6D4',
  },
  blue: {
    100: '#C0D3E4',
    200: '#92B2CE',
    300: '#628EB9',
    400: '#3B69A3',
    500: '#2B5C97',
    600: '#194E8F',
    700: '#00478C',
    800: '#003970',
    900: '#00274D',
  },
  peach: {
    100: '#FFFBFA',
    200: '#FFF8F5',
    300: '#FFF3ED',
    400: '#FFEEE5',
    500: '#FFE6D9',
    600: '#FCDECF',
    700: '#F6D3C1',
    800: '#EEC5B0',
    900: '#E0B69F',
  },
  brown: {
    100: '#F6EBE6',
    200: '#EFE4DE',
    300: '#E8DCD6',
    400: '#E1D5CF',
    500: '#D9CDC7',
    600: '#D2C6BF',
    700: '#CABEB7',
    800: '#C3B7AF',
    900: '#BFAFA6',
  },
  grey: {
    100: '#FAFAFA',
    200: '#F5F5F5',
    300: '#F5F3F3',
    400: '#E1E0E1',
    500: '#D6D5D6',
    600: '#C2C0C2',
    700: '#8F8D90',
    800: '#5C5B5F',
    900: '#29282E',
  },
  blueGrey: {
    100: '#F4F8FC',
    200: '#ECF3F8',
    300: '#DFE7EF',
    400: '#D1DCE5',
    500: '#C3CFDB',
    600: '#B5C3D1',
  },
  default: {
    white: '#FFFFFF',
    black: '#000000',
  },
};

export const COLORS: ColorTypes = {
  primary: COLOR_TOKENS.red[600],
  primaryD1: COLOR_TOKENS.red[700],
  secondary: COLOR_TOKENS.aqua[400],
  backgroundGrey: COLOR_TOKENS.grey[300],
  backgroundPeach: COLOR_TOKENS.peach[300],
  text: COLOR_TOKENS.grey[900],
  base: COLOR_TOKENS.purple[700],
  iconDefault: COLOR_TOKENS.grey[900],
  danger: COLOR_TOKENS.red[600],
  success: '#009993', // TODO: Missing in color tokens
  info: COLOR_TOKENS.blue[600],
  black: COLOR_TOKENS.default.black,
  white: COLOR_TOKENS.default.white,
};

const radiusBase = '2px';

export const RADIUS: RadiusTypes = {
  all: radiusBase,
  none: '0',
  top: `${radiusBase} ${radiusBase} 0 0`,
  right: `0 ${radiusBase} ${radiusBase} 0`,
  bottom: `0 0 ${radiusBase} ${radiusBase}`,
  left: `${radiusBase} 0 0 ${radiusBase}`,
  topLeft: `${radiusBase} 0 0 0`,
  topRight: `0 ${radiusBase} 0 0`,
  bottomLeft: `0 0 0 ${radiusBase}`,
  bottomRight: `0 0 ${radiusBase} 0`,
};

export const BREAKPOINTS: BreakpointsType = {
  xxxs: 320,
  xxs: 376,
  xs: 490,
  sm: 768,
  md: 1024,
  lg: 1280,
  mlg: 1440,
  xl: 1600,
  xxl: 1900,
  xxxl: 2560,
  xxxxl: 3440,
};

export const FONT_FAMILIES: FontFamilyType = {
  primary: 'Tuna',
  secondary: "'Source Sans Pro', sans-serif",
  tertiary: "'Source Serif Pro', serif",
  quaternary: "'Kaushan Script', cursive",
};

export const FONT_SIZE: FontSizeTypes = {
  sm: '0.875rem',
  md: '1rem', // Paragraph
  lg: '1.2rem',
  xl: '1.375rem', // Preamble
  xxl: '1.5rem', // H3
  xxxl: '2.5rem', // H2
  xxxxl: '3.375rem', // H1
  xxxxxl: '5rem', // Hero
};

export const FONT_SIZE_SCALE = Object.values(FONT_SIZE);

export const SPACING: SpacingType = {
  xxs: '0.25rem', // 4px
  xs: '0.5rem', // 8px
  sm: '1rem', // 16px
  md: '1.5rem',
  lg: '2rem',
  xl: '2.5rem',
  xxl: '3rem',
  xxxl: '3.5rem',
  xxxxl: '4rem', // 64px
};

export const SPACING_SCALE = ['0', ...Object.values(SPACING)];

export const BORDER = {
  separator: ' 1px solid #d4d2d4',
};

const ACADEMY_THEME_PART: AcademyThemePart = {
  frontPage: {
    colors: {
      primary: COLOR_TOKENS.purpleVibrant[900],
      secondary: COLOR_TOKENS.purpleVibrant[500],
      backgroundBrown: COLOR_TOKENS.brown[100],
      backgroundPurple: COLOR_TOKENS.purpleVibrant[100],
      textGray: COLOR_TOKENS.grey[800],
      textSelectionBackground: COLOR_TOKENS.purpleVibrant[600],
    },
  },
};

export const defaultTheme: ThemeType = {
  breakpoints: BREAKPOINTS,
  colorTokens: COLOR_TOKENS,
  colors: COLORS,
  fontFamilies: FONT_FAMILIES,
  fontSizeScale: FONT_SIZE_SCALE,
  radius: RADIUS,
  spacing: SPACING,
  spacingScale: SPACING_SCALE,
  border: BORDER,
  academy: ACADEMY_THEME_PART,
};

export default defaultTheme;
