import { RootState } from 'state/types';
import { USER_ID_CLAIM } from 'models/jwt/jwtClaims';
import { CourseContent, getModulesInChapter } from 'pages/AcademyPage/utils/academyCourseContentContext';

const PROGRESS_PERCENTAGE_THRESHOLD_FOR_MODULE_COMPLETED = 90;

export const accessTokenSelector = (state: RootState): string => state.session.accessToken || '';

export const idTokenSelector = (state: RootState): string => state.session.idToken || '';

export const userIdTokenSelector = (state: RootState): string | undefined => {
  if (state.session.idTokenPayload) {
    return state.session.idTokenPayload[USER_ID_CLAIM];
  }
};

export const dropdownSelector = (state: RootState): string => state.ui.dropdown;

export const tagFiltersSelector = (state: RootState): string[] => state.ui.tagFilters;

export const timestampCursorByIdSelector = (state: RootState, moduleId: string): number => {
  const timestamp = state.ui.akademi?.moduleProgress?.find((progress) => progress.moduleId === moduleId)?.timestampCursor || 0;
  return timestamp;
};

export const courseProgressSelector = (state: RootState, courseContent: CourseContent): number => {
  const chaptersThatCountTowardsCourseProgress = courseContent.chapters.filter((chapter) => chapter.countsTowardsCourseProgress);
  const totalChapterProgress = state.ui.akademi.chapterProgress.reduce((acc, item) => acc + (item.totalChapterProgress || 0), 0);
  const progress = Math.floor((totalChapterProgress / (chaptersThatCountTowardsCourseProgress.length * 100)) * 100);
  return progress;
};

export const chapterProgressByIdSelector = (state: RootState, id: string): number =>
  state.ui.akademi.chapterProgress?.find((prog) => prog.chapterId === id)?.totalChapterProgress || 0;

export const moduleProgressByIdSelector = (state: RootState, id: string): number => {
  return state.ui.akademi.moduleProgress?.find((prog) => prog.moduleId === id)?.totalModuleProgress || 0;
};

export const isModuleCompletedSelector = (state: RootState, moduleId: string): boolean => {
  const progress = moduleProgressByIdSelector(state, moduleId);
  return progress >= PROGRESS_PERCENTAGE_THRESHOLD_FOR_MODULE_COMPLETED;
};

export const isChapterModulesCompletedSelector = (state: RootState, chapterId: string, courseContent: CourseContent): boolean => {
  const chapterModules = getModulesInChapter(courseContent, chapterId);
  return chapterModules.every((module) => isModuleCompletedSelector(state, module.id));
};

export const isCourseModulesBeforeFinalTestCompletedSelector = (state: RootState, courseContent: CourseContent): boolean => {
  const chaptersThatCountTowardsCourseCompletion = courseContent.chapters.filter(
    (chapter) => chapter.countsTowardsCourseProgress
  );

  if (chaptersThatCountTowardsCourseCompletion.length > 0) {
    return chaptersThatCountTowardsCourseCompletion.every((chapter) =>
      isChapterModulesCompletedSelector(state, chapter.id, courseContent)
    );
  }
  return false;
};
