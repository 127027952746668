import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import WidthConstraints from 'components/WidthConstraints';
import QuoteMark from 'components/icons/QuoteMark';

const QuoteSectionCss = styled.section`
  padding: 90px 0;
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};

  svg {
    margin-right: 8px;
    min-width: 2.3125rem;
    min-height: 1.75rem;
  }
`;

const WrapperCss = styled(WidthConstraints)`
  display: flex;
  font-family: ${(props) => props.theme.fontFamilies.primary};
  flex-direction: row;
  margin: 0 auto;

  ${createMediaQuery(
    'md',
    css`
      max-width: 800px;
    `
  )}
`;

const BlockquoteCss = styled.blockquote`
  color: ${({ theme }) => theme.colorTokens.default.white};
  font-family: ${(props) => props.theme.fontFamilies.primary};
  margin: 0;

  p {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 1.5rem;
  }

  footer {
    font-size: 1.25rem;
    letter-spacing: 0.04rem;
    font-style: italic;
    padding: 5px 0 0 0;
  }
`;

const QuoteSection: FC<{
  children: ReactNode | ReactNode[];
  cite?: string;
  className?: string;
}> = ({ children, cite = '', className = '' }) => {
  return (
    <QuoteSectionCss className={className}>
      <WrapperCss defaultResponsiveWidth size="large">
        <QuoteMark />
        <BlockquoteCss cite={cite}>{children}</BlockquoteCss>
      </WrapperCss>
    </QuoteSectionCss>
  );
};

export default QuoteSection;
