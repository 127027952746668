import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { saveFinalTestPassed } from 'pages/AcademyPage/utils';
import { setValue } from 'state/ui/uiActions';
import { RootState } from 'state/types';
import { accessTokenSelector } from 'state/selectors';
import { FLYT_BACKEND_BASE_URL, useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { mount as flytMount } from './flyt-dist/flyt';

interface AcademyQuizProps {
  quiz: {
    flytId: string;
  };
  courseId: string;
}

const AcademyQuiz: FC<AcademyQuizProps> = ({ quiz, courseId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const quizRef = useRef<HTMLDivElement>(null);
  const isFinalTestAlreadyCompleted = useSelector((state: RootState) => state.ui.akademi.isFinalTestCompleted);
  const accessToken = useSelector((state: RootState) => accessTokenSelector(state));

  const juridikaConfig = useJuridikaConfig();

  const saveQuizPassedIfFirstTime = useCallback(() => {
    const firstTimeTakingFinalTest = !isFinalTestAlreadyCompleted;

    if (firstTimeTakingFinalTest) {
      saveFinalTestPassed(accessToken, courseId).then((res) => {
        if (res === 'Context saved') {
          dispatch(setValue(true, ['akademi', 'isFinalTestCompleted']));
        }
      });
    }
  }, [accessToken, courseId, dispatch, isFinalTestAlreadyCompleted]);

  useEffect(() => {
    const node = quizRef.current;
    if (!node) {
      return;
    }

    const cleanup = flytMount(node, {
      flytId: quiz.flytId,
      theme: 'akademi',
      getAuthToken: () => accessToken,
      endpoint: juridikaConfig.getString(FLYT_BACKEND_BASE_URL),
      callbacks: {
        onProgress: (_progress: number) => {},
        onCompleted: (simpleReport) => {
          if (simpleReport.numberOfItems === simpleReport.numberOfPassedItems) {
            saveQuizPassedIfFirstTime();
          }
        },
        onRequestCertificate: () => history.push('kursbevis'),
      },
      intro: {
        text: 'Du har nå vært gjennom alle modulene til Kræsjkurs i sakførsel for prosessfullmektiger. Ta quizen for å få kursbeviset ditt.',
      },
      getReportFeedback: (simpleReport) => {
        if (simpleReport.numberOfPassedItems === simpleReport.numberOfItems) {
          return {
            success: true,
            banner: {
              title: 'Fullført kurs',
            },
            body: {
              title: 'Gratulerer!',
              text: `
              <p>
                Du har fullført Kræsjkurs i sakførsel for prosessfullmektiger, med
                følgende innhold:
              </p>
              <ul>
                <li>Trinnene i saksforberedelsen</li>
                <li>Håndtering av vitner</li>
                <li>Vitneutspørring og partsforklaring</li>
                <li>Når du skal ha på deg kappe</li>
                <li>Hvordan en rettsal kan se ut</li>
                <li>Hva dommeren forventer av en fullmektig</li>
                <li>Quiz</li>
              </ul>
              <p>
                Du kan nå laste ned kursbeviset.
              </p>
              `,
            },
          };
        }

        return {
          success: false,
          banner: {
            title: 'Kurs ikke bestått',
          },
          body: {
            title: 'Prøv igjen!',
            text: `
            <p>
              Du må ha 100% korrekt for å få kursbevis. Selv om du ikke fikk alle
              svarene riktige nå er det bare å prøve på nytt. Sett deg gjerne inn i
              de spørsmålene du ikke fikk rett.
            </p>
            `,
          },
        };
      },
    });

    return () => {
      cleanup();
    };
  }, [accessToken, history, juridikaConfig, quiz, saveQuizPassedIfFirstTime]);

  return <div ref={quizRef} />;
};

export default memo(AcademyQuiz);
