import * as React from 'react';
import CustomScroll from 'react-custom-scroll';
import { SUGGESTION_HISTORY_TYPES, SUGGESTIONS_CATEGORIES, parseSearchHistoryMetaText } from 'util/searchSuggestionHelpers';
import { ColorTokenValues } from 'theme/config/types';
import History from 'icons/History';
import MagnifyingGlassSlim from 'icons/MagnifyingGlassSlim';
import styled, { css } from 'styled-components';
import { listWithoutStyle } from 'styleMixins/listMixins';
import createMediaQuery from 'util/createMediaQuery';
import { OldButton } from '../OldButton';
import SuggestionItem, { SuggestionIconCss } from './SuggestionItem';

const SearchSuggestionPopupCss = styled.div`
  position: relative;
`;
const ScrollContentCss = styled.div<{ isScrollable: boolean }>`
  max-height: 28.125rem;

  ${createMediaQuery(
    'sm',
    css`
      max-height: 38.75rem;
    `
  )}

  ${({ isScrollable }) =>
    isScrollable &&
    css`
      :after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        width: 100%;
        height: 6.25rem;
      }
    `}
`;

const SuggestionListCss = styled.ul`
  ${listWithoutStyle}
  padding-bottom: 0.3125rem;
`;

const PopupCss = styled.div`
  min-width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.05), 0 1px 1px rgba(16, 22, 26, 0.1);
  position: absolute;
  z-index: 2;
`;

const SuggestionDeleteHistoryBtnCss = styled(OldButton)`
  float: right;
  margin: 0.3125rem;
`;

const SuggestionsAsideCss = styled.div`
  flex: 1 0 70%;

  > .custom-scroll .custom-scrollbar {
    opacity: 1;
  }
`;

const SuggestionHeaderCss = styled.div`
  background: ${({ theme }) => theme.colorTokens.grey[100]};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 0.875rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.03125rem;
  color: ${({ theme }) => theme.colorTokens.red[600]};
  padding: 0.3125rem;
  text-transform: uppercase;
`;

const MagnifyingGlassSlimCss = styled(MagnifyingGlassSlim)`
  ${SuggestionIconCss}
`;

const HistoryCss = styled(History)`
  ${SuggestionIconCss}
`;

interface Props {
  onSuggestionSelect: (suggestion: SuggestionElement) => void;
  onHoverSuggestion: (index: number, isHovered: boolean) => void;
  onMouseMove: () => void;
  clearHistory: () => void;
  setHovered: (hovered: boolean) => void;
  hideSuggestionCategoryHeaders?: boolean;
  suggestions: Suggestion[];
  searchHistory: SearchHistorySuggestionElement[];
  showSuggestions: boolean;
  showHistory: boolean;
  activeSuggestionIndex: number;
}

export interface Suggestion {
  category: string;
  list: SuggestionElement[];
}

export interface TextWithModifiers {
  text: string;
  color?: ColorTokenValues;
}

export interface SuggestionElement {
  displayText: string;
  documentUrl: string;
  metaText: TextWithModifiers[];
  score?: number;
  type?: string;
  accessible: boolean;
}

// Search history is stored in localstorage, we need to save old types in order to be backwards compatible
// Alternatively, we can just change the localstorage key, but then all users would lose their current search history
export interface SearchHistorySuggestionElement extends Omit<SuggestionElement, 'metaText'> {
  metaText: string | TextWithModifiers[] | null;
}

export const headlines = {
  [SUGGESTIONS_CATEGORIES.CONTRIBUTORS]: 'Forfatter',
  [SUGGESTIONS_CATEGORIES.ACT_FRAGMENT]: 'Lover',
  [SUGGESTIONS_CATEGORIES.ACT]: 'Lover',
  [SUGGESTIONS_CATEGORIES.TEXTBOOK]: 'Fagbøker',
  [SUGGESTIONS_CATEGORIES.JOURNAL]: 'Tidsskrifter',
  [SUGGESTIONS_CATEGORIES.JOURNAL_ARTICLE]: 'Tidsskrifter',
  [SUGGESTIONS_CATEGORIES.NEWS_ARTICLE]: 'Innsikt',
  [SUGGESTIONS_CATEGORIES.CONTRACT_TEMPLATE]: 'Mal',
  [SUGGESTIONS_CATEGORIES.NIK_DOCUMENT]: 'Problemstilling',
};

export const combinedCategories = [
  [SUGGESTIONS_CATEGORIES.ACT_FRAGMENT, SUGGESTIONS_CATEGORIES.ACT],
  [SUGGESTIONS_CATEGORIES.JOURNAL, SUGGESTIONS_CATEGORIES.JOURNAL_ARTICLE, SUGGESTIONS_CATEGORIES.NIK_DOCUMENT],
  [SUGGESTIONS_CATEGORIES.TEXTBOOK],
  [SUGGESTIONS_CATEGORIES.CONTRIBUTORS],
  [SUGGESTIONS_CATEGORIES.NEWS_ARTICLE],
  [SUGGESTIONS_CATEGORIES.CONTRACT_TEMPLATE],
];

const SearchSuggestionDropdown: React.FC<Props> = ({
  hideSuggestionCategoryHeaders = false,
  onSuggestionSelect,
  onHoverSuggestion,
  onMouseMove,
  setHovered,
  clearHistory,
  suggestions,
  searchHistory,
  showHistory,
  showSuggestions,
  activeSuggestionIndex,
}) => {
  const [isScrollable, setIsScrollable] = React.useState(false);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const checkIsScrollable = React.useCallback(() => {
    if (scrollRef.current) {
      const scrollEl = scrollRef.current.querySelector('.inner-container');
      if (scrollEl) {
        const scrollOffset = scrollEl.scrollHeight - scrollEl.clientHeight - scrollEl.scrollTop;
        const scrollable = scrollOffset > 10;
        if (scrollable !== isScrollable) {
          setIsScrollable(scrollable);
        }
      }
    }
  }, [isScrollable]);

  React.useEffect(() => {
    checkIsScrollable();
  }, [suggestions.length, searchHistory.length, scrollRef, activeSuggestionIndex, checkIsScrollable]);

  let index = 0;
  return (
    <SearchSuggestionPopupCss>
      <PopupCss
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseMove={() => onMouseMove()}
        ref={scrollRef}
      >
        {showHistory && !showSuggestions && (
          <CustomScroll onScroll={checkIsScrollable} allowOuterScroll>
            <ScrollContentCss isScrollable={isScrollable}>
              <SuggestionListCss>
                {searchHistory.map((suggestion) => (
                  <SuggestionItem
                    key={index}
                    index={index}
                    onHover={onHoverSuggestion}
                    // eslint-disable-next-line no-plusplus
                    isActive={index++ === activeSuggestionIndex}
                    onClick={() =>
                      onSuggestionSelect({
                        ...suggestion,
                        metaText: parseSearchHistoryMetaText(suggestion.metaText),
                      })
                    }
                    icon={suggestion.type === SUGGESTION_HISTORY_TYPES.SEARCH_QUERY ? <MagnifyingGlassSlimCss /> : <HistoryCss />}
                    title={suggestion.displayText}
                    subTitle={parseSearchHistoryMetaText(suggestion.metaText)}
                    accessible={suggestion.accessible}
                  />
                ))}
              </SuggestionListCss>
              <SuggestionDeleteHistoryBtnCss onClick={() => clearHistory()}>Slett historikken</SuggestionDeleteHistoryBtnCss>
            </ScrollContentCss>
          </CustomScroll>
        )}
        {showSuggestions && (
          <SuggestionsAsideCss>
            <CustomScroll onScroll={checkIsScrollable} allowOuterScroll>
              <ScrollContentCss isScrollable={isScrollable}>
                {suggestions.map((suggestion) => (
                  <React.Fragment key={suggestion.category}>
                    {!hideSuggestionCategoryHeaders && (
                      <SuggestionHeaderCss>{headlines[suggestion.category]}</SuggestionHeaderCss>
                    )}
                    <SuggestionListCss>
                      {suggestion.list.map((hit) => (
                        <SuggestionItem
                          key={index}
                          index={index}
                          onHover={onHoverSuggestion}
                          // eslint-disable-next-line no-plusplus
                          isActive={index++ === activeSuggestionIndex}
                          onClick={() => onSuggestionSelect(hit)}
                          title={hit.displayText}
                          subTitle={hit.metaText}
                          accessible={hit.accessible}
                        />
                      ))}
                    </SuggestionListCss>
                  </React.Fragment>
                ))}
              </ScrollContentCss>
            </CustomScroll>
          </SuggestionsAsideCss>
        )}
      </PopupCss>
    </SearchSuggestionPopupCss>
  );
};

export default SearchSuggestionDropdown;
