import Flex from 'components/ui/Flex';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import Image from 'components/Image';

export const ImageCss = styled(Image)`
  border-radius: 4px;
`;

export const ModuleCss = styled.div<{ $isActive: boolean; $disabled: boolean }>`
  border: 1px solid ${({ theme }) => theme.colorTokens.grey[600]};
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  border-radius: 0.5rem;
  padding: 0.875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;

  :focus,
  :focus-visible {
    outline: ${({ theme }) => theme.colorTokens.purple[700]} auto 1px;
  }

  > div:first-of-type {
    margin-bottom: 0.625rem;

    ${createMediaQuery(
      375,
      css`
        margin-bottom: 0;
      `
    )}
  }

  :hover {
    background-color: ${({ theme }) => theme.colorTokens.purple[100]};
    outline: ${({ theme }) => theme.colorTokens.purple[300]};

    > div:first-of-type {
      svg * {
        fill: ${({ theme }) => theme.colorTokens.purple[500]};
      }
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      outline: ${({ theme }) => theme.colorTokens.purple[500]} auto 1px;
    `}

  :last-of-type {
    margin-bottom: 0;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;

export const TitleCss = styled.h3`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
`;

export const DescriptionContainerCss = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the description to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const DescriptionCss = styled.p`
  margin: 0.75rem 0;
  color: ${({ theme }) => theme.colorTokens.grey[900]};

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the description to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DetailsCss = styled(Flex)`
  color: ${({ theme }) => theme.colorTokens.grey[800]};
`;
