import * as React from 'react';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';

interface PageLoaderProps {
  children?: React.ReactNode | React.ReactNode[];
}

const PageLoaderCss = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

const MessageCss = styled.div`
  color: ${({ theme }) => theme.colorTokens.grey[800]};
`;

const PageLoader: React.FC<PageLoaderProps> = ({ children }) => (
  <PageLoaderCss>
    <LoadingSpinner size="large" />
    <MessageCss>{children}</MessageCss>
  </PageLoaderCss>
);

export default PageLoader;
