import React, { FC, ReactElement, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Location } from 'history';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { setValue } from 'state/ui/uiActions';
import { useDispatch } from 'react-redux';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { setAppContainerHeight } from 'util/domUtils';
import { useDismissLocalStorage } from 'util/hooks/useDismissLocalStorage';
import DismissibleErrorBanner, { APIError } from 'components/DismissibleErrorBanner/DismissibleErrorBanner';
import { NAVBAR_HEIGHT } from 'containers/NavbarAndGlobalSearchAndErrorContainer/utils';
import { NIK_TOOLBAR_HEIGHT } from 'pages/NIKPage/constants';
import { RootError } from '../../components/error/RootError';
import { ROOT_CATCH_ID } from '../../components/error/CatchContext';
import { ToolbarCss, StartToolbarItems, EndToolbarItems } from '../../components/Toolbar/Toolbar';
import { Catch } from '../../components/error/Catch';
import { CustomErrorMessages } from '../../models/CustomErrorMessages';
import NavbarAndGlobalSearchAndErrorContainer from '../NavbarAndGlobalSearchAndErrorContainer/NavbarAndGlobalSearchAndErrorContainer';
import Footer from './components/Footer';

const GLOBAL_SEARCH_HEIGHT = 82;
const DISMISSABLE_ERROR_BANNER_HEIGHT_WITH_ONE_TEXT_LINE = 66;

interface LayoutCssProps {
  hasFullViewportHeight: boolean;
  hasBeigeBackground: boolean;
  errorMessageIsVisible: boolean;
  isNikToolbarVisible: boolean;
  globalSearchIsVisible: boolean;
}

const LayoutCss = styled.div<LayoutCssProps>`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colorTokens.default.white};

  ${({ hasFullViewportHeight }) =>
    hasFullViewportHeight &&
    css`
      height: 100%;
      min-height: 0;
    `}

  ${({ hasBeigeBackground }) =>
    hasBeigeBackground &&
    css`
      background: ${COLOR_TOKENS.peach[300]};
    `}

  ${({ globalSearchIsVisible, errorMessageIsVisible, isNikToolbarVisible }) =>
    css`
      padding-top: ${getApproximateHeaderHeight(globalSearchIsVisible, errorMessageIsVisible, isNikToolbarVisible)}px;
    `}
`;

const MainCss = styled.main<{ hasFullViewportHeight: boolean }>`
  min-height: 100%;
  flex-grow: 1;

  ${({ hasFullViewportHeight }) =>
    hasFullViewportHeight &&
    css`
      height: 100%;
      min-height: 0;
      overflow: hidden;
    `}
`;

export interface LayoutProps {
  location?: Location;
  children?: ReactNode | ReactNode[];
  // Whether this main layout should scroll to top if the path
  // changes, but the page is the same.
  scrollToTopOnPathChange?: boolean;
  // Overrides default scroll to top behaviour with custom array of dependencies to use in useEffect
  customScrollToTopDependencies?: any[];
  hasFullViewportHeight?: boolean;
  hasToolbar?: boolean;
  hasFooter?: boolean;
  hasBeigeBackground?: boolean;
  toolbarStart?: ReactElement;
  toolbarEnd?: ReactElement;
  webinarPromo?: ReactElement;
  customErrorMessages?: CustomErrorMessages;
  livechatVisibility?: 'hidden' | 'maximized' | 'minimized';
  hasNikToolbar?: boolean;
}

export const Layout: FC<LayoutProps> = ({
  scrollToTopOnPathChange,
  customScrollToTopDependencies,
  hasFullViewportHeight = false,
  hasToolbar = true,
  hasFooter = true,
  hasBeigeBackground = false,
  toolbarStart = null,
  toolbarEnd = null,
  webinarPromo = null,
  customErrorMessages,
  livechatVisibility = 'hidden',
  hasNikToolbar = false,
  children,
}) => {
  const location = useLocation();
  const { isClient } = useJuridikaConfig();
  const [displayGlobalSearch, setDisplayGlobalSearch] = useState(false);
  const [error, setError] = useState<APIError | null>(null);
  const { isDismissed: isErrorDismissed } = useDismissLocalStorage(error?.localStorageKey || undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setValue(livechatVisibility, ['chat', 'visibility']));
  }, [dispatch, livechatVisibility]);

  const scrollToTopDependencies: string[] =
    customScrollToTopDependencies || (scrollToTopOnPathChange === true ? [location.pathname, location.search] : []);

  if (isClient) {
    /*
     * Reset Global Scroll Position:
     * I en SPA, når du navigerer mellom sider, blir ikke siden lastet på nytt helt.
     * Dette sørger for at du starter på toppen av siden når du scrollToTopDependencies trigges.
     */
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, scrollToTopDependencies);
  }

  useEffect(() => {
    if (hasFullViewportHeight) {
      setAppContainerHeight('100vh');
    }

    return () => {
      if (hasFullViewportHeight) {
        setAppContainerHeight('auto');
      }
    };
  }, [hasFullViewportHeight]);

  useEffect(() => {
    const fetchErrorMessage = async () => {
      try {
        const response = await fetch(`/api/juridika_global_error_message`);
        const result: APIError = await response.json();
        if (result.message) {
          setError(result);
        }
      } catch (error) {
        setError(null);
      }
    };
    fetchErrorMessage();
  }, []);

  const errorMessageIsVisible = error !== null && !isErrorDismissed;

  useEffect(() => {
    dispatch(
      setValue(getApproximateHeaderHeight(displayGlobalSearch, errorMessageIsVisible, hasNikToolbar), ['topElementsApproxHeight'])
    );
  }, [dispatch, displayGlobalSearch, errorMessageIsVisible, hasNikToolbar]);

  return (
    <LayoutCss
      errorMessageIsVisible={errorMessageIsVisible}
      hasBeigeBackground={hasBeigeBackground}
      hasFullViewportHeight={hasFullViewportHeight}
      isNikToolbarVisible={hasNikToolbar}
      globalSearchIsVisible={displayGlobalSearch}
    >
      <Helmet defaultTitle="Juridika" titleTemplate="%s | Juridika" />

      <NavbarAndGlobalSearchAndErrorContainer
        displayGlobalSearch={displayGlobalSearch}
        setDisplayGlobalSearch={() => setDisplayGlobalSearch((prev) => !prev)}
        hasBeigeBackground={hasBeigeBackground}
        errorBanner={<DismissibleErrorBanner error={error} />}
        isNikToolbarVisible={hasNikToolbar}
      />

      {webinarPromo}

      {hasToolbar && (
        <ToolbarCss>
          <StartToolbarItems>{toolbarStart}</StartToolbarItems>
          <EndToolbarItems>{toolbarEnd}</EndToolbarItems>
        </ToolbarCss>
      )}

      <MainCss hasFullViewportHeight={hasFullViewportHeight}>
        <Catch
          catchId={ROOT_CATCH_ID}
          renderError={(props) => <RootError {...props} customErrorMessages={customErrorMessages} />}
        >
          {children}
        </Catch>
      </MainCss>

      {hasFooter && <Footer />}

      <div id="modals-portal-container" />
    </LayoutCss>
  );
};

export const getApproximateHeaderHeight = (
  isGlobalSearchVisible: boolean,
  isDismissibleErrorBannerVisible: boolean,
  isNikToolbarVisible: boolean
) =>
  NAVBAR_HEIGHT +
  (isGlobalSearchVisible ? GLOBAL_SEARCH_HEIGHT : 0) +
  (isDismissibleErrorBannerVisible ? DISMISSABLE_ERROR_BANNER_HEIGHT_WITH_ONE_TEXT_LINE : 0) +
  (isNikToolbarVisible ? NIK_TOOLBAR_HEIGHT : 0);
