import styled, { css } from 'styled-components';
import { buttonWithoutStyle } from 'styleMixins/listMixins';

export const SourceAccordionButtonCss = styled.button<{ $expanded: boolean }>`
  ${buttonWithoutStyle}

  width: 100%;
  padding: 0.9375rem 1.25rem;

  ${({ $expanded }) =>
    $expanded &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
    `}

  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colorTokens.purple[300]};
    border-radius: 0.5rem;

    ${({ $expanded }) =>
      $expanded &&
      css`
        border-radius: 0.5rem 0.5rem 0 0;
      `}

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }

  :hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.colorTokens.purple[100]};
    color: ${({ theme }) => theme.colorTokens.default.black};
    border-radius: 7px;

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.black};
    }

    ${({ $expanded }) =>
      $expanded &&
      css`
        border-radius: 7px 7px 0 0;
      `}
  }
`;

export const SourceAccordionCss = styled.div<{ $isActive: boolean }>`
  ${buttonWithoutStyle}

  background-color: ${({ theme }) => theme.colorTokens.default.white};
  border: 1px solid ${({ theme }) => theme.colorTokens.grey[500]};
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  width: 100%;

  ${({ $isActive }) =>
    $isActive &&
    css`
      outline: ${({ theme }) => theme.colorTokens.purple[500]} auto 1px;
    `}
`;

export const ModuleTitleCss = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 1.125rem;
  flex: 1;
`;

export const LegalSourcesCss = styled.div`
  margin: 0.9375rem 0 1.25rem;
  padding: 0 1.25rem;
  button {
    display: block;
    margin: 0.625rem 0;
  }

  hr {
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.colorTokens.purple[300]};
    margin-top: 0.9375rem;
  }
`;

export const LinkCss = styled.a`
  display: flex;
  align-items: center;
  margin: 0.625rem 0;
  color: ${({ theme }) => theme.colorTokens.blue[600]};

  :hover,
  :focus-visible {
    color: ${({ theme }) => theme.colorTokens.grey[900]};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.black};
    }
  }

  :hover {
    opacity: 1;
  }

  :focus-visible {
    border: none;
    outline: ${({ theme }) => theme.colorTokens.grey[900]} auto 1px;
  }
`;

export const SourcesWrapperCss = styled.div`
  height: calc(100% - 70px);
  overflow: auto;
  background-color: ${({ theme }) => theme.colorTokens.grey[200]};
  padding: 0.75rem 1.25rem;
`;

export const CourseTitleCss = styled.h2`
  color: ${({ theme }) => theme.colorTokens.grey[900]};
  font-weight: 600;
  font-size: 1.375rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.25rem;
  margin-top: 0;
  border-bottom: ${({ theme }) => css`1px solid ${theme.colorTokens.grey[600]}`};
`;

export const TitleCss = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
`;
