import Flex from 'components/ui/Flex';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';
import Lock from 'components/icons/Lock';

export const SidebarLockCss = styled(Lock)`
  align-self: center;
`;

export const SidebarGoToIntroButtonCss = styled.button<{ $isActive: boolean }>`
  ${buttonWithoutStyle}
  display: flex;
  align-items: center;
  margin: 0.75rem 0;

  span {
    font-size: 1.125rem;
    text-decoration: underline;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      span {
        text-decoration: none;
      }
    `}

  :focus-visible {
    border: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colorTokens.default.white};
  }

  :hover {
    opacity: 1;

    svg {
      margin-left: 10px;
      transition: margin-left 0.2s;
    }
  }

  :focus-visible {
    border: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colorTokens.default.white};
  }

  :hover {
    opacity: 1;

    svg {
      margin-left: 10px;
      transition: margin-left 0.2s;
    }
  }
`;

export const SidebarChapterWrapperCss = styled.div<{ $expanded: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[600]};

  ${({ $expanded }) =>
    $expanded &&
    css`
      &:last-child {
        border-bottom: none;
      }
    `}
`;

export const SidebarCourseHeaderCss = styled.div`
  color: ${({ theme }) => theme.colorTokens.default.white};
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  padding: 0.75rem 1.25rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[600]};

  > div {
    color: ${({ theme }) => theme.colorTokens.purple[300]};
  }
`;

export const SidebarChapterHeaderCss = styled.div<{ $expanded: boolean }>`
  background-color: ${({ theme }) => theme.colorTokens.default.white};
  padding: 0.75rem 1.25rem 1rem;

  ${({ $expanded }) =>
    $expanded &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[600]};
    `}
`;

export const SidebarAccordionCss = styled.button`
  ${buttonWithoutStyle}

  display: flex;
  align-items: flex-start;
  width: 100%;

  > svg:last-of-type {
    margin-top: 0.3rem;
    margin-left: auto;
  }

  :hover {
    opacity: 1;
    h2 {
      text-decoration: underline;
    }
  }

  :focus-visible {
    border: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colorTokens.default.black};
  }
`;

export const SidebarTitleCss = styled.h2`
  margin: 0;
  margin-right: 0.625rem;
  font-size: 1.25rem;
  font-weight: 600;
  flex: 1;
`;

export const SidebarExpandedAreaCss = styled.div`
  margin-top: 0.5rem;
`;

export const SidebarChapterModulesCss = styled.div`
  padding: 1.25rem;
  background-color: ${({ theme }) => theme.colorTokens.grey[200]};
`;

export const SidebarDetailsCss = styled(Flex)`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colorTokens.grey[800]};
  margin-bottom: 0.625rem;
`;
