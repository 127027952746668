import URI from 'urijs';
import { CreativeWork, WithContext } from 'schema-dts';
import { HastFragmentNode } from '@universitetsforlaget/hast';

import { JuridikaConfig } from 'commonUtils/juridikaConfig';

import {
  PublicationLink,
  contributorPath,
  getPublicationLinkUrl,
  LiteraturePublicationId,
  getEditionLinkUrl,
} from 'util/literatureUtils';
import { GqlTextbookEdition, GqlTextbookPublication } from '../pages/TextbookEditionPage/model/Publication';
import { GqlPublication, GqlEditionEdge, GqlDocumentEdge } from '../pages/JournalPage/model/Publication';
import { StaticPage, StaticPageContributionEdge } from '../pages/StaticContentPage/model/StaticPage';
import { DocumentData } from '../pages/JournalArticlePage/hooks/useDocumentQuery';
import { getEditionCoverUrlWithFallback } from '../components/EditionCover';

import { staticPageRelativeUrl, noticeHeadersSelector } from './staticContentHelpers';
import { extractText } from './HastUtils';
import { addLeadingZeros } from './dateHelpers';
import { sanitizeTitle } from './textUtils';
import { findByTagName } from './hypertextUtils';
import { HTML_ATTRIBUTES, HypertextNode } from './hypertextAllocator';
import { EditionData } from '../pages/TextbookReaderPage/hooks/useEditionQuery';

export const PAYWALL_CSS_SELECTOR = 'paywall';
export const NO_PAYWALL_CSS_SELECTOR = 'nopaywall';

export interface Person {
  '@type': 'Person';
  name: string;
  sameAs: string | undefined;
  jobTitle: string | undefined;
}

interface Organization {
  '@type': 'Organization';
  name: string;
  logo: {
    '@type': 'ImageObject';
    url: string;
  };
}

type Publisher = Person | Person[] | Organization | Organization[];
type Author = Publisher;

interface BookOffer {
  '@type': 'Offer';
  category: 'nologinrequired' | 'free' | 'subscription' | 'purchase' | 'rental';
  // Do we accept more regions?
  eligibleRegion: {
    '@type': 'Country';
    name: 'NO';
  };
}

export interface BookWork {
  // Required properties
  '@context': 'https://schema.org';
  '@id': string;
  '@type': 'Book';
  author: Author;
  name: string;
  url: string;
  workExample?: BookEdition[];
}

export interface BookEdition {
  // Required properties
  '@id': string;
  '@type': 'Book';
  bookFormat: 'https://schema.org/EBook';
  inLanguage: 'no'; // This is a required property, but we have no data from the backend saying what language the book is in. Norwegian will almost always be correct
  isbn: string | null;
  potentialAction: {
    '@type': 'ReadAction';
    expectsAcceptanceOf: BookOffer;
    target: {
      '@type': 'EntryPoint';
      urlTemplate: string;
      actionPlatform: ['http://schema.org/DesktopWebPlatform'];
    };
  };
  // Recommended properties
  author: Author;
  bookEdition: string;
  datePublished: string;
  name: string;
  image: string;
  url: string;
}

interface CreativeWorkPart {
  '@type': string; // Subtype of CreativeWork
  isAccessibleForFree?: boolean;
  cssSelector?: string;
}

export interface Article {
  '@context': 'https://schema.org';
  '@type': 'NewsArticle' | 'Article';
  mainEntityOfPage: {
    '@type': 'WebPage';
    '@id': string;
  };
  headline: string;
  image: string[];
  datePublished: string | null;
  dateModified: string | null;
  author: Author;
  publisher: Organization;
  description: string;
  isAccessibleForFree?: boolean;
  hasPart?: CreativeWorkPart;
}

interface PublicationIssue {
  '@id': string;
  '@type': 'PublicationIssue';
  datePublished: string;
  startPageName?: string;
  endPageName?: string;
  issueNumber: string;
}

export interface Periodical {
  '@id': string;
  '@context': 'https://schema.org';
  '@type': 'Periodical';
  issn: string | null;
  name: string;
  abstract: string | null;
  hasPart: PublicationIssue[];
  publisher: Publisher;
  url: string;
}

export const person = (
  contribution: {
    names: string[];
    slug: string;
    isFeatured: boolean;
    occupation?: {
      hast: HastFragmentNode | null;
    } | null;
  },
  juridikaConfig: JuridikaConfig
): Person => {
  return {
    '@type': 'Person',
    name: contribution.names.join(' '),
    sameAs: contribution.isFeatured
      ? juridikaConfig.juridikaAbsoluteBaseUrl().segment(contributorPath(contribution)).toString()
      : undefined,
    jobTitle: contribution.occupation?.hast ? extractText(contribution.occupation.hast) : undefined,
  };
};

const organization = (name: string, imageUrl: string): Organization => ({
  '@type': 'Organization',
  name,
  logo: {
    '@type': 'ImageObject',
    url: imageUrl,
  },
});

const bookWork = (edition: GqlTextbookEdition, publication: GqlTextbookPublication, juridikaConfig: JuridikaConfig): BookWork => {
  const publicationLink = new PublicationLink(publication.slug, publication.category);

  const publucationUrl = juridikaConfig.juridikaAbsoluteBaseUrl().segment(publicationLink.url().toString()).toString();
  const contributions = edition.documents.edges[0]?.node.contributions.edges.map((edge) => edge.node) ?? [];

  return {
    '@id': publucationUrl,
    '@context': 'https://schema.org',
    '@type': 'Book',
    name: sanitizeTitle(edition.titles[0] || ''),
    url: publucationUrl,
    author: contributions.map((contribution) => person(contribution, juridikaConfig)),
  };
};

const bookEdition = (
  edition: GqlTextbookEdition,
  publication: GqlTextbookPublication,
  juridikaConfig: JuridikaConfig,
  isFreeAccess: boolean
): BookEdition => {
  const publicationLink = new PublicationLink(publication.slug, publication.category);
  const editionLink = publicationLink.withEdition(edition.path);
  const editionPath = editionLink.url().toString();
  const editionUrl = juridikaConfig.juridikaAbsoluteBaseUrl().segment(editionPath).toString();
  const contributions = edition.documents.edges[0]?.node.contributions.edges.map((edge) => edge.node) ?? [];

  return {
    '@id': editionUrl,
    '@type': 'Book',
    inLanguage: 'no',
    name: sanitizeTitle(edition.titles[0] || ''),
    image: getEditionCoverUrlWithFallback(juridikaConfig, edition, 100),
    bookEdition: edition.path[0],
    author: contributions.map((contribution) => person(contribution, juridikaConfig)),
    datePublished: edition.originallyPublishedAt,
    url: editionUrl,
    isbn: edition.isbn,
    bookFormat: 'https://schema.org/EBook',
    potentialAction: {
      '@type': 'ReadAction',
      expectsAcceptanceOf: {
        '@type': 'Offer',
        category: isFreeAccess ? 'nologinrequired' : 'subscription',
        eligibleRegion: {
          '@type': 'Country',
          name: 'NO',
        },
      },
      target: {
        '@type': 'EntryPoint',
        urlTemplate: URI(editionUrl).segment('document').toString(),
        actionPlatform: ['http://schema.org/DesktopWebPlatform'],
      },
    },
  };
};

const publicationIssue = ({ node }: GqlEditionEdge, literaturPublicationId: LiteraturePublicationId): PublicationIssue => {
  const { path } = node;
  const documentEdges: GqlDocumentEdge[] | undefined = node.documents?.edges;
  const editionPath = getEditionLinkUrl(literaturPublicationId, { path });
  const [year, month = '1'] = path;
  const datePublished = new Date([year, addLeadingZeros(parseInt(month, 10))].join('-'));

  return {
    '@id': editionPath,
    '@type': 'PublicationIssue',
    datePublished: datePublished.toISOString(),
    issueNumber: [year, month].join('/'),
    ...(documentEdges !== undefined && {
      startPageName: documentEdges[0].node.startPageName,
      endPageName: documentEdges[documentEdges.length - 1].node.endPageName,
    }),
  };
};

export const hasFreeAccessTag = (tags: Array<{ path: Array<string>; name: string }>): boolean => {
  for (const tag of tags) {
    if (tag.path.length > 2 && tag.path[0] === 'lisens' && tag.path[1] === 'openaccess') {
      return true;
    }
    if (tag.path[0] === 'freebie') {
      return true;
    }
  }
  return false;
};

export const includesFreeAccessTag = (tags: string[] | undefined): boolean =>
  tags?.some((tag) => tag.startsWith('lisens.openaccess') || tag.startsWith('freebie')) || false;

export const textbookStructuredData = (
  requestedEdition: GqlTextbookEdition,
  publication: GqlTextbookPublication,
  juridikaConfig: JuridikaConfig,
  rootDocumentTags: Array<{ path: Array<string>; name: string }>
): BookWork => {
  const publicationEditions = publication.editions.edges.map((edge) => edge.node);

  return {
    ...bookWork(requestedEdition, publication, juridikaConfig),
    workExample: publicationEditions.map((edition) =>
      bookEdition(edition, publication, juridikaConfig, hasFreeAccessTag(rootDocumentTags))
    ),
  };
};

export const creativeWorkStructuredData = (
  publicationSlug: string,
  editionPath: string[],
  editionData: EditionData,
  juridikaConfig: JuridikaConfig
): WithContext<CreativeWork> => {
  const { editionOriginallyPublishedAt, rootModule } = editionData;
  const publicationLink = new PublicationLink(publicationSlug, ['fagbok']);
  const editionLink = publicationLink.withEdition(editionPath);
  const documentUrl = editionLink.withDocument(rootModule.key).url().toString();
  const isFreeAccess = hasFreeAccessTag(rootModule.tags);
  return {
    '@context': 'https://schema.org',
    '@type': 'CreativeWork',
    '@id': documentUrl,
    name: rootModule.titles[0],
    author: rootModule.contributions.map((contribution) => person(contribution.contributor, juridikaConfig)),
    datePublished: editionOriginallyPublishedAt,
    url: documentUrl,
    isAccessibleForFree: isFreeAccess,
    ...(!isFreeAccess && {
      hasPart: {
        '@type': 'WebPageElement',
        isAccessibleForFree: isFreeAccess,
        cssSelector: PAYWALL_CSS_SELECTOR,
      },
    }),
  };
};

export const staticPageContributor = (
  contribution: StaticPageContributionEdge,
  juridikaConfig: JuridikaConfig
): Person | null => {
  const { occupation, node } = contribution;

  if (!node) return null;

  const { occupation: contributorOccupation, names, isFeatured, slug } = node;
  const occupationHastNode = occupation?.hast ?? contributorOccupation?.hast;

  return person(
    {
      names,
      isFeatured,
      slug,
      occupation: { hast: occupationHastNode || null },
    },
    juridikaConfig
  );
};

export const newsArticleStructuredData = (
  staticPage: StaticPage,
  juridikaConfig: JuridikaConfig,
  hyperTextNode: HypertextNode
): Article => {
  const authors = staticPage.contributions.edges.reduce((aggr: Person[], edge) => {
    const author = staticPageContributor(edge, juridikaConfig);

    if (!author) return aggr;

    return [...aggr, author];
  }, []);
  const articlePath = staticPageRelativeUrl(staticPage.slug).toString();
  const articleImagesSources = findByTagName('img', [hyperTextNode]).map((node: HypertextNode) => node[HTML_ATTRIBUTES]?.src);
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': juridikaConfig.juridikaAbsoluteBaseUrl().segment(articlePath).toString(),
    },
    headline: staticPage.title,
    image: articleImagesSources,
    datePublished: staticPage.lastPublishedAt,
    dateModified: staticPage.lastPublishedAt,
    author: authors,
    publisher: organization('Juridika', 'https://juridika-images.s3.eu-central-1.amazonaws.com/logo-juridika-red-200x60.png'),
    description:
      staticPage.publishedNotices.edges.length > 0
        ? extractText(noticeHeadersSelector.complement(staticPage.publishedNotices.edges[0].node.content.hast))
        : '',
  };
};

export const journalStructuredData = (publication: GqlPublication, juridikaConfig: JuridikaConfig): Periodical => {
  const {
    review,
    titles,
    editions: { edges: editionEdges },
    slug,
    category,
  } = publication;
  const publicationPath = getPublicationLinkUrl({ slug, category });
  const publicationUrl = juridikaConfig.juridikaAbsoluteBaseUrl().segment(publicationPath).toString();

  return {
    '@id': publicationUrl,
    '@context': 'https://schema.org',
    '@type': 'Periodical',
    issn: null,
    name: titles.join(' '),
    abstract: review?.hast.children ? extractText(review.hast.children[0]) : null,
    hasPart: editionEdges.map((edge) => publicationIssue(edge, { slug, category })),
    publisher: organization('Universitetsforlaget', 'https://juridika-images.s3.eu-central-1.amazonaws.com/UF-logo.png'),
    url: publicationUrl,
  };
};

export const journalArticleStructuredData = (
  documentData: DocumentData,
  documentUrl: string,
  isFreeAccess: boolean,
  juridikaConfig: JuridikaConfig
): Article => {
  const { editionOriginallyPublishedAt, documentTitles, contributions } = documentData;
  const articleTitle: string = sanitizeTitle(documentTitles[0]);

  const authors = contributions.reduce((aggr: Person[], contribution) => {
    const author = person({ ...contribution.contributor, occupation: null }, juridikaConfig);

    if (!author) return aggr;

    return [...aggr, author];
  }, []);
  return {
    '@context': 'https://schema.org',
    '@type': 'Article', // TODO: Should this be ScholarlyArticle ?
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': documentUrl,
    },
    headline: articleTitle,
    image: [],
    datePublished: editionOriginallyPublishedAt,
    dateModified: editionOriginallyPublishedAt,
    author: authors,
    publisher: organization('Juridika', 'https://juridika-images.s3.eu-central-1.amazonaws.com/logo-juridika-red-200x60.png'),
    description: '', // TODO: Should we pick out abstract from text? Add keywords?
    isAccessibleForFree: isFreeAccess,
    ...(!isFreeAccess && {
      hasPart: {
        '@type': 'WebPageElement',
        isAccessibleForFree: isFreeAccess,
        cssSelector: PAYWALL_CSS_SELECTOR,
      },
    }),
  };
};
