import React, { ReactNode, FC } from 'react';
import styled from 'styled-components';
import ArrowUp3 from 'components/icons/ArrowUp3';

const BoxedContentCss = styled.div<{ visible: boolean }>`
  margin: 1.1rem 0;

  div:first-of-type {
    padding: 0 1rem 0.5rem 1rem;
    margin: 1rem 0;
  }

  div > p:first-child {
    margin-block-start: 1.4rem;
  }

  div > p:last-child {
    margin-block-end: 0;
  }
`;

const BoxedContentToggleButtonCss = styled.button`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid
    ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  color: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  width: 100%;
  padding-bottom: 1rem;

  svg path {
    fill: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
  }

  h2:first-child {
    all: unset;
    font-family: ${({ theme }) => theme.fontFamilies.secondary};
    font-size: 1.375rem;
    font-weight: 600;
  }

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
    border-bottom: 2px solid
      ${({ theme }) => theme.colorTokens.purpleVibrant[500]};

    svg path {
      fill: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
    }
  }

  :focus-visible {
    border: none;
    box-sizing: border-box;
    padding: 0.56rem;
    outline: 2px solid ${({ theme }) => theme.colorTokens.purpleVibrant[800]};
    outline-offset: 2px;
    color: ${({ theme }) => theme.colorTokens.purpleVibrant[800]};
    svg path {
      fill: ${({ theme }) => theme.colorTokens.purpleVibrant[800]};
    }
  }
`;

const BoxedContentIconCss = styled(ArrowUp3)<{ visible: boolean }>`
  transform: rotate(${({ visible }) => (visible ? '0' : '180deg')});
  transition: transform 0.3s;
`;

const BoxedContent: FC<{
  label: string;
  children: ReactNode | ReactNode[];
}> = ({ label, children }) => {
  const [visible, setVisible] = React.useState(false);

  const toggleExample = () => setVisible(!visible);
  return (
    <BoxedContentCss visible={visible}>
      <BoxedContentToggleButtonCss onClick={toggleExample}>
        <h2>{label}</h2>
        <BoxedContentIconCss visible={visible} />
      </BoxedContentToggleButtonCss>
      {visible && <div>{children}</div>}
    </BoxedContentCss>
  );
};

export default BoxedContent;
