import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { JuridikaDevOptionsState } from './types';
import * as juridikaDevOptionsCommands from './juridikaDevOptionsCommands';
import * as juridikaDevOptionsActions from './juridikaDevOptionsActions';

type JuridikaDevOptionsAction = ActionType<typeof juridikaDevOptionsActions>;

export const juridikaDevOptionsInitialState: JuridikaDevOptionsState = {};

// Dev options are options that are hidden to regular users, and mutated via executing any of juridikaDevOptionsCommands from the console.
// This reducer handles state that is "dev options" exclusively. There is nothing preventing
// you from handling the JuridikaDevOptionsAction in other reducers though.
export const juridikaDevOptionsReducer: Reducer<JuridikaDevOptionsState, JuridikaDevOptionsAction> = (
  state = juridikaDevOptionsInitialState,
  action
) => {
  switch (action.type) {
    case getType(juridikaDevOptionsActions.executeJuridikaDevOptionsCommand):
      switch (action.payload) {
        // Example: can be replaced
        case juridikaDevOptionsCommands.activateWebinarPromo: {
          return { ...state, webinarPromoActive: true };
        }
        default: {
          return state;
        }
      }
    default: {
      return state;
    }
  }
};
