import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { AcademyIconButton4 } from '../IconButtons/IconButtons';

export const SidebarCss = styled.aside<{ $show: boolean; $topElementsApproxHeight: number }>`
  position: sticky;
  ${({ $topElementsApproxHeight }) =>
    css`
      top: ${$topElementsApproxHeight}px;
      height: calc(100vh - ${$topElementsApproxHeight}px);
    `};

  ${({ $show }) =>
    $show
      ? css`
          width: 420px;

          ${createMediaQuery(
            2000,
            css`
              width: 600px;
            `
          )}
        `
      : css`
          width: 0;
        `}

  transition: width 0.5s;
  right: 0;

  .sidebar-content-sticky {
    /* This has to do with the behavior when clicking dock button, without this the navbar width
       misbehaves when clicking dock button */
    position: relative;
    top: 0;
    overflow-y: auto;
  }
`;

export const SidebarModalCss = styled.aside<{ $topElementsApproxHeight: number }>`
  ${({ $topElementsApproxHeight }) =>
    css`
      height: calc(100vh - ${$topElementsApproxHeight}px);
    `}
`;

export const DockButtonCss = styled(AcademyIconButton4)<{ $open: boolean; $topElementsApproxHeight: number }>`
  ${({ $open, $topElementsApproxHeight }) =>
    $open
      ? css`
          top: 0;
          left: -2.7rem;
          position: absolute;
        `
      : css`
          top: ${$topElementsApproxHeight + 1}px;
          right: 0;
          position: fixed;
        `}

  transition: left 0.5s, right 0.5s;
  z-index: 98;
`;

export const SidebarContentCss = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  box-shadow: -3px 2px 5px 0 #00000040;
`;

export const CourseCss = styled.div`
  flex-grow: 1; /* Fill rest of vertical space after HeaderActions container */
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colorTokens.grey[200]};
`;
