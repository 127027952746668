import React from 'react';
import Bug from 'components/icons/Bug';
import { useDismissLocalStorage } from 'util/hooks/useDismissLocalStorage';
import { ButtonCss, DismissibleErrorBannerCss, HeaderCss, MessageCss, TitleCss } from './Styles';

export interface APIError {
  message: string | null;
  localStorageKey: string | null;
}

// prop 'overrideDismissed' is only used for showcasing component in storybook
const DismissibleErrorBanner: React.FC<{ error: APIError | null; overrideDismissed?: boolean }> = ({
  error,
  overrideDismissed = false,
}) => {
  const { isDismissed, dismissHandler } = useDismissLocalStorage(error?.localStorageKey || undefined);

  const handleDismissed = () => {
    dismissHandler();
    window.location.reload();
  };

  const dismissed = isDismissed && !overrideDismissed;

  if (!error || dismissed) return null;

  return (
    <DismissibleErrorBannerCss>
      <HeaderCss>
        <Bug mr2 />
        <TitleCss>Feil på tjenesten</TitleCss>
      </HeaderCss>
      <MessageCss>{error.message}</MessageCss>
      <ButtonCss label="Lukk" iconLeft={{ name: 'Close', size: 12 }} onClick={handleDismissed} my2 />
    </DismissibleErrorBannerCss>
  );
};

export default DismissibleErrorBanner;
