import { FC } from 'react';
import IconPropTypes from 'components/icons/IconPropTypes';

import SuccessStatusCircle from './SuccessStatusCircle';
import WarningStatusCircle from './WarningStatusCircle';
import Info from './Info';
import Cross from './Cross';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import ChatBubble from './ChatBubble';
import Gears from './Gears';
import Close from './Close';
import Save from './Save';
import Filter from './Filter';
import ArrowDown2 from './ArrowDown2';
import ArrowUp2 from './ArrowUp2';
import Chat from './Chat';
import Plus from './Plus';
import Minus from './Minus';
import ForwardArrow from 'icons/ForwardArrow';
import DockOn from 'icons/DockOn';
import DockOff from 'icons/DockOff';
import Menu from './Menu';
import MagnifyingGlass from './MagnifyingGlass';

const icons: { [key: string]: FC<IconPropTypes> } = {
  SuccessStatusCircle,
  WarningStatusCircle,
  Info,
  Cross,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  ChatBubble,
  Close,
  Gears,
  Save,
  Filter,
  ArrowDown2,
  ArrowUp2,
  Chat,
  Plus,
  Minus,
  ForwardArrow,
  DockOn,
  DockOff,
  Menu,
  MagnifyingGlass,
};

export default icons;
