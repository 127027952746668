export enum Format {
  VIDEO = 'video',
  AUDIO = 'audio',
  TEXT = 'text',
  QUIZ = 'quiz',
  DIPLOMA = 'diploma',
  INTRO = 'intro',
}

export enum SourceItemType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface ModuleProgress {
  moduleId: string;
  totalModuleProgress: number;
  chapterId: string;
  timestampCursor: number;
  timestampTokensSeen: number[];
  totalTimestampTokens: number;
}

export interface ChapterProgress {
  chapterId: string;
  totalChapterProgress: number;
}

interface SourceItem {
  label: string;
  src: string;
  _type: SourceItemType;
}

export interface HotspotLink {
  src: string;
  label: string;
  timestamp: number;
}
export interface Sources {
  id: string;
  moduleId: string;
  chapterId: string;
  items?: SourceItem[];
}

export interface ModuleMedia {
  coverimage?: string;
  transcriptFile?: string;
  transcript?: string;
  video?: string;
}

export interface QuizOptions {
  flytId: string;
}

export interface ModuleCore {
  id: string;
  inChapter: string;
  inCourse: string;
  title: string;
  slug: string;
  prevModule: string | null;
  nextModule: string | null;
  format: Format;
  desc: string;
  durationInSeconds: number;
  // TODO: thumbnail should probably only be nullable for last "end of course" module
  // Maybe we should have a separate type for that special "module".
  thumbnail?: string; // 118 x 66
  media?: ModuleMedia;
  quiz?: QuizOptions;
}
export interface Module extends ModuleCore {
  html: string;
  hotspotLinks?: HotspotLink[];
}

export interface SidebarModule extends ModuleCore {
  part?: number;
  chapter: string;
}

export interface Chapter {
  id: string;
  slug: string;
  title: string;
  inCourse: string;
  time: string;
  parts: number;
  // At this time, all modules within these chapters count towards course progress and completion
  countsTowardsCourseProgress?: boolean;
}

export interface Course {
  id: string;
  slug: string;
  title: string;
  numberOfChapters: number;
}

export interface AcademyFrontPageData {
  frontPage: {
    heroSection: {
      tagLine: string;
    };
    topVideoAndTextSection: {
      title: string;
      textOneColumn: string;
      textsTwoColumns: {
        mainText: string;
        textUnderVideo: string;
      };
      video: AcademyFrontPageVideoPlayerUrls;
    };
    allCoursesSection: {
      title: string;
      courseCards: {
        title: string;
        category: string;
        description: string;
      }[];
    };
    aboutAkademiSection: {
      title: string;
      text: string;
      image: {
        imageUrls: {
          oneColumn: string;
          twoColumns: string;
        };
        altText?: string;
      };
    };
    testimonialsSection: {
      title: string;
      testimonials: AcademyFrontPageTestimonial[];
    };
    sneakPeakSection: {
      title: string;
      text: string;
      video: AcademyFrontPageVideoPlayerUrls;
    };
    featureBoxes: {
      deepDive: AcademyFrontPageFeatureBoxContent;
      videos: AcademyFrontPageFeatureBoxContent;
      quizAndCertificate: AcademyFrontPageFeatureBoxContent;
    };
  };
}

interface AcademyFrontPageVideoPlayerUrls {
  videoFileUrl: string;
  posterImageUrl: string;
  videoCaptions?: string;
}

export interface AcademyFrontPageTestimonial {
  text: string;
  signature: string;
}

interface AcademyFrontPageFeatureBoxContent {
  title: string;
  text: string;
}
