import React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Time: React.FC<IconPropTypes> = ({ color, size, width, height, ...props }) => (
  <Svg
    className="icon icon-clock"
    xmlns="http://www.w3.org/2000/svg"
    width={size || width}
    height={size || height}
    viewBox="0 0 11 12"
    {...props}
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M5.5.556a5.506 5.506 0 0 0-5.5 5.5c0 3.032 2.467 5.5 5.5 5.5s5.5-2.468 5.5-5.5c0-3.033-2.467-5.5-5.5-5.5zm0 .868a4.625 4.625 0 0 1 4.632 4.632A4.625 4.625 0 0 1 5.5 10.687 4.625 4.625 0 0 1 .868 6.056 4.625 4.625 0 0 1 5.5 1.424zm0 .724a.434.434 0 0 0-.434.434v3.474c0 .16.087.3.217.375l2.759 1.592a.434.434 0 1 0 .434-.75L5.934 5.806V2.582a.434.434 0 0 0-.434-.434z"
    />
  </Svg>
);

Time.defaultProps = {
  color: COLOR_TOKENS.grey[800],
  width: 11,
  height: 12,
};

export default Time;
