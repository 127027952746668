import React, { Dispatch, FC, RefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useViewportSize } from 'util/hooks/useViewportSize';
import ThreeDots from 'components/icons/ThreeDots';
import { capitalizeFirstLetter } from 'util/textUtils';
import { CategoryPage } from './categoryPages';
import { NAVBAR_VIEWPORT_SIZE, NavigationType, isNavLinkActive } from '../../../../utils';
import {
  CategoryPageNavigationCss,
  MoreButtonCss,
  MoreButtonListItemCss,
  NavLinkCss,
  NavLinkListItemCss,
  NavLinksCss,
} from './Styles';

const CategoryPageNavigation: FC<{
  navType: NavigationType;
  listOfHiddenMenuItems?: CategoryPage[];
  setListOfHiddenMenuItems?: Dispatch<SetStateAction<CategoryPage[]>>;
  showSecondNavbar?: boolean;
  toggleSecondNavbar?: Dispatch<SetStateAction<boolean>>;
  moreButtonRef?: RefObject<HTMLButtonElement>;
  menuItems?: CategoryPage[];
}> = ({
  navType,
  listOfHiddenMenuItems = [],
  setListOfHiddenMenuItems = () => {},
  showSecondNavbar = false,
  toggleSecondNavbar = () => {},
  moreButtonRef = null,
  menuItems = [],
}) => {
  const viewportSize = useViewportSize(NAVBAR_VIEWPORT_SIZE, 'small');

  const [visibleItems, setVisibleItems] = useState<CategoryPage[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const currentlyHiddenItems = menuItems.slice(visibleItems.length);
    setListOfHiddenMenuItems(currentlyHiddenItems);
  }, [menuItems, setListOfHiddenMenuItems, visibleItems.length]);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (viewportSize !== 'small') {
      const breakpoints = [985, 1085, 1220, 1295]; // Brekkpunkt for når menypunktene skal dukke opp
      const visibleItemCount = breakpoints.reduce((count, breakpoint) => {
        if (windowWidth >= breakpoint) {
          return count + 1;
        }
        return count;
      }, 3); // Start med 3 menypunkter

      setVisibleItems(menuItems.slice(0, visibleItemCount));
    } else setVisibleItems(menuItems);
  }, [listOfHiddenMenuItems.length, menuItems, viewportSize, windowWidth]);

  const onlyOneItemLeftInHiddenItems = listOfHiddenMenuItems.length === 1;

  useEffect(() => {
    setVisibleItems(menuItems);
  }, [onlyOneItemLeftInHiddenItems, menuItems]);

  const handleToggleMoreButton = () => toggleSecondNavbar((prev) => !prev);
  const namesOfMenuItems = useMemo(() => menuItems.map((item) => item.name.toLowerCase()), [menuItems]);

  return (
    <CategoryPageNavigationCss navType={navType}>
      <NavLinksCss $navType={navType}>
        {visibleItems.map(({ name, path }: CategoryPage) => {
          return (
            <NavLinkListItemCss key={name}>
              <NavLinkCss
                $navType={navType}
                activeClassName="isActive"
                to={path}
                isActive={(a, b) => isNavLinkActive(a, b, namesOfMenuItems)}
              >
                {capitalizeFirstLetter(name)}
              </NavLinkCss>
            </NavLinkListItemCss>
          );
        })}

        {listOfHiddenMenuItems.length > 0 && (
          <MoreButtonListItemCss>
            <MoreButtonCss onClick={handleToggleMoreButton} $isActive={showSecondNavbar} ref={moreButtonRef}>
              <span>Mer</span>
              <ThreeDots ml2 />
            </MoreButtonCss>
          </MoreButtonListItemCss>
        )}
      </NavLinksCss>
    </CategoryPageNavigationCss>
  );
};

export default CategoryPageNavigation;
