import URI from 'urijs';

import { JURIDIKA_LITTERATUR_BACKEND } from 'commonUtils/serviceConstants';
import { CLOUDFRONT_LITERATURE_FILES_PUBLIC_URL, JuridikaConfig } from 'commonUtils/juridikaConfig';

import { getPublicApiUrl } from './apiUrls';

export type LiteratureCollection = 'fagbøker' | 'tidsskrifter';
export type LiteratureEditionAvailability = 'ANNOUNCED' | 'PREVIEW' | 'COMPLETE';

interface UrlOptions {
  /**
   * If you pass along this value, you will get an _absolute URL_, not a relative one.
   */
  absoluteUrlConfig?: JuridikaConfig;
}

// Constants
export const portraitPlaceholder = (width: number): string =>
  `https://res.cloudinary.com/dylsvi3ok/image/upload/w_${width},c_scale/v1604262159/place-holder/portrait_placeholder-500px-24ab9393e5333ef6db041154260559b0-removebg-preview_egqrjh.png`;

export class PublicationLink {
  readonly slug: string;

  readonly category: string[];

  constructor(slug: string, category: string[]) {
    this.slug = slug;
    this.category = category;
  }

  isJournal(): boolean {
    return this.category[0] === 'tidsskrift';
  }

  isTextbook(): boolean {
    return this.category[0] === 'fagbok';
  }

  withEdition(path: string[], availability?: LiteratureEditionAvailability): EditionLink {
    return new EditionLink(this, path, availability);
  }

  url(options?: UrlOptions): URI {
    const base = options?.absoluteUrlConfig ? options.absoluteUrlConfig.juridikaAbsoluteBaseUrl() : URI('/');

    return base.segment(this.isJournal() ? 'tidsskrifter' : 'fagbok').segment(this.slug);
  }
}

export class EditionLink {
  readonly publicationLink: PublicationLink;

  readonly path: string[];

  readonly isAvailable: boolean;

  constructor(publicationLink: PublicationLink, path: string[], availability?: LiteratureEditionAvailability) {
    this.publicationLink = publicationLink;
    this.path = path;
    this.isAvailable = availability ? availability !== 'ANNOUNCED' : true;
  }

  url(options?: UrlOptions): URI {
    const uri = this.publicationLink.url(options);
    this.path.map((segment) => uri.segment(segment));
    return uri;
  }

  withDocument(key: string): DocumentLink {
    return new DocumentLink(this, key);
  }
}

export class DocumentLink {
  readonly editionLink: EditionLink;

  readonly key: string;

  private isAccessible: boolean;

  private q: string | null;

  constructor(editionLink: EditionLink, key: string) {
    this.editionLink = editionLink;
    this.key = key;
    this.isAccessible = true;
    this.q = null;
  }

  accessible(value: boolean): DocumentLink {
    this.isAccessible = value;
    return this;
  }

  searchQuery(q: string | null): DocumentLink {
    this.q = q;
    return this;
  }

  url(options?: UrlOptions): URI {
    const uri = this.editionLink.url(options);
    if (!this.isAccessible || !this.editionLink.isAvailable) return uri;

    if (this.editionLink.publicationLink.isJournal()) {
      uri.segment('artikkel').segment(this.key);
    } else {
      if (this.key === 'default') {
        uri.segment('dokument');
      } else {
        this.key.split('__').forEach((keySegment) => {
          uri.segment(textbookKeySegmentToUriSegment(keySegment));
        });
      }

      if (this.q) {
        uri.setQuery({ q: this.q });
      }
    }

    return uri;
  }
}

export interface LiteraturePublicationId {
  slug: string;
  category: string[];
}

interface LiteratureEditionId {
  path: string[];
}

interface LiteratureDocumentId {
  key: string;
  category: string[];
}

interface LiteratureObjectId {
  publication: LiteraturePublicationId;
  edition?: Partial<LiteratureEditionId>;
  document?: Partial<LiteratureDocumentId>;
  accessible?: boolean;
  term?: string;
}

// Get internal link/route url for any literature resource
export const getLiteratureLinkURI = ({
  publication: { slug: publicationSlug, category: publicationCategory },
  edition: { path: editionPath } = { path: undefined },
  document: { key: documentKey, category: documentCategory } = {
    key: undefined,
    category: undefined,
  },
  accessible,
  term,
}: LiteratureObjectId): URI => {
  const publicationLink = new PublicationLink(publicationSlug, publicationCategory);

  if (editionPath) {
    const editionLink = publicationLink.withEdition(editionPath);

    if (documentKey && documentCategory) {
      const documentLink = editionLink.withDocument(documentKey);
      if (accessible !== undefined) {
        documentLink.accessible(accessible);
      }
      if (term !== undefined) {
        documentLink.searchQuery(term);
      }

      return documentLink.url();
    }

    return editionLink.url();
  }

  return publicationLink.url();
};

export const getPublicationLinkUrl = (publication: LiteraturePublicationId): string =>
  getLiteratureLinkURI({ publication }).toString();

export const getEditionLinkUrl = (publication: LiteraturePublicationId, edition: LiteratureEditionId): string =>
  getLiteratureLinkURI({ publication, edition }).toString();

// Get URL for document contents
export const getDocumentFormatUrl = (config: JuridikaConfig, documentId: string): string => {
  return getPublicApiUrl(config, JURIDIKA_LITTERATUR_BACKEND)
    .segment('rest')
    .segment('v1')
    .segment('documents')
    .segment(documentId)
    .segment('format')
    .toString();
};

export const editionCoverWidths = [500, 200, 100];
export const closestWidth = (goal: number): number =>
  editionCoverWidths.reduce((prev, curr) => {
    if (editionCoverWidths.includes(goal)) return goal;
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });

interface ContributorOrContribution {
  id: string;
  hasPortrait: boolean;
  customPortrait?: string;
}

export const getPortraitUrl = (
  juridikaConfig: JuridikaConfig,
  contributorOrContribution: ContributorOrContribution,
  width: number
): string => {
  const { id, hasPortrait, customPortrait } = contributorOrContribution;
  const closestWidthFound = closestWidth(width);

  if (customPortrait) {
    return customPortrait;
  }

  if (hasPortrait) {
    const cloudFrontUrl = juridikaConfig.getString(CLOUDFRONT_LITERATURE_FILES_PUBLIC_URL);
    return `${cloudFrontUrl}/contributors/${id}/portrait-${closestWidthFound}px`;
  }

  return portraitPlaceholder(width);
};

export const contributorPath = (contributor: { slug: string }): string =>
  `/${URI('').segment('forfattere').segment(contributor.slug)}`;

const textbookKeySegmentToUriSegment = (keySegment: string): string => {
  if (keySegment.startsWith('part_')) {
    const items = keySegment.split('_', 2);
    return `del_${items[1]}`;
  }

  if (keySegment.startsWith('chapter_')) {
    const items = keySegment.split('_', 2);
    return `kapittel_${items[1]}`;
  }

  if (keySegment.startsWith('section_')) {
    const items = keySegment.split('_', 2);
    return items[1];
  }

  if (keySegment.startsWith('subsection_')) {
    const items = keySegment.split('_', 2);
    return items[1];
  }

  if (keySegment.startsWith('paragraph_')) {
    const items = keySegment.split('_', 2);
    return `¶${items[1]}`;
  }

  return keySegment;
};

/**
 * Full path for editions used in GraphQL queries
 */
export interface LiteratureEditionFullPath {
  publicationSlug: string;
  editionPath: Array<string>;
}

/**
 * Full path for documents used in GraphQL queries
 */
export interface LiteratureDocumentFullPath extends LiteratureEditionFullPath {
  key: string;
}
