import { ChapterProgress, ModuleProgress } from 'pages/AcademyPage/types';
import { calculateTotalProgressInChapter } from 'pages/AcademyPage/utils';

export const calculateModuleProgressPercent = (currentProgressTokens: number[], totalProgressTokens: number): number => {
  return Math.floor((currentProgressTokens.length / totalProgressTokens) * 100);
};

export const updateModuleProgress = (
  moduleId: string,
  chapterId: string,
  newTimestampTokensSeen: number[],
  newTimestampCursor: number,
  totalTimestampTokens: number,
  existingModulesProgress: ModuleProgress[]
): ModuleProgress[] => {
  const newOrUpdatedModuleProgress = (existingTimestampTokens: number[]): ModuleProgress => {
    const newFlooredTimetampTokensSeen = newTimestampTokensSeen.map((token) => Math.floor(token));
    const updatedTimestampTokensSeen = [...new Set(existingTimestampTokens.concat(newFlooredTimetampTokensSeen || []))];
    return {
      moduleId,
      chapterId,
      totalModuleProgress: calculateModuleProgressPercent(updatedTimestampTokensSeen, totalTimestampTokens),
      timestampCursor: newTimestampCursor,
      timestampTokensSeen: updatedTimestampTokensSeen,
      totalTimestampTokens,
    };
  };

  let hasUpdatedAnExistingModuleProgress = false;
  const updatedExistingModulesProgress = existingModulesProgress.map((existingModuleProgress) => {
    if (existingModuleProgress.moduleId === moduleId) {
      hasUpdatedAnExistingModuleProgress = true;
      return newOrUpdatedModuleProgress(existingModuleProgress.timestampTokensSeen);
    }
    return existingModuleProgress;
  });

  if (hasUpdatedAnExistingModuleProgress) return updatedExistingModulesProgress;
  return [...existingModulesProgress, newOrUpdatedModuleProgress([])];
};

export const updateChapterProgress = (
  chapterId: string,
  existingModulesProgress: ModuleProgress[],
  existingChaptersProgress: ChapterProgress[],
  totalDurationInChapter: number
): ChapterProgress[] => {
  let hasUpdatedAnExistingChapterProgress = false;

  const updatedChaptersProgress = existingChaptersProgress.map((existingChapterProgress) => {
    if (existingChapterProgress.chapterId === chapterId) {
      hasUpdatedAnExistingChapterProgress = true;
      return {
        chapterId,
        totalChapterProgress: calculateTotalProgressInChapter(existingModulesProgress, chapterId, totalDurationInChapter),
      };
    }
    return existingChapterProgress;
  });

  if (hasUpdatedAnExistingChapterProgress) return updatedChaptersProgress;

  return [
    ...existingChaptersProgress,
    {
      chapterId,
      totalChapterProgress: calculateTotalProgressInChapter(existingModulesProgress, chapterId, totalDurationInChapter),
    },
  ];
};
