import SpacingPropTypes from 'models/SpacingPropTypes';
import React from 'react';
import styled, { css } from 'styled-components';
import createSpacing from 'util/createSpacing';
import CheckboxLabel from './components/CheckboxLabel';
import CheckboxSvg from './components/CheckboxSvg';

export interface CheckboxProps extends SpacingPropTypes {
  id?: string;
  isChecked?: boolean;
  isIndeterminate?: boolean;
  label?: string | JSX.Element;
  color?: string;
  isDisabled?: boolean;
  type?: 'button' | 'submit';
  onClick?<T>(...args: T[]): void;
  labelFor?: string;
}

export const CheckboxCss = styled.button<CheckboxProps & { disabled?: boolean }>`
  padding: 0.125rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  outline: none;
  text-align: left;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${(props) => createSpacing(props)}

  ${({ disabled }) =>
    disabled &&
    css`
      :hover {
        opacity: 0.5 !important;
      }
    `}

  svg {
    display: block;
  }
`;

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  isChecked,
  color,
  isIndeterminate,
  label,
  isDisabled = false,
  type = 'button',
  onClick,
  labelFor,
  ...props
}) => {
  return (
    <CheckboxCss
      type={type}
      disabled={isDisabled}
      onClick={onClick}
      id={id}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      color={color}
      {...props}
    >
      <span>
        <CheckboxSvg isChecked={isChecked} color={color} isIndeterminate={isIndeterminate} />
      </span>
      <CheckboxLabel isDisabled={isDisabled} label={label} labelFor={labelFor || ''} />
    </CheckboxCss>
  );
};

export default Checkbox;
