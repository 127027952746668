import React, { FC, ReactNode, ElementType } from 'react';
import styled, { css } from 'styled-components';

import createSpacing from 'util/createSpacing';
import SpacingPropTypes from 'models/SpacingPropTypes';

export interface FlexPropTypes extends SpacingPropTypes {
  as?: ElementType;
  align?: string;
  justify?: string;
  column?: boolean;
  wrap?: boolean;
  grow?: number;
  relative?: boolean;
  flex?: string;
  children?: ReactNode;
  className?: string;
}

const FlexCss = styled.div<FlexPropTypes>`
  display: flex;
  ${(props) => createSpacing(props)}
  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}
  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `}
  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}
  ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: wrap;
    `}

    ${({ grow }) =>
    grow &&
    css`
      flex-grow: ${grow};
    `}

    ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

const Flex: FC<FlexPropTypes> = (props) => <FlexCss {...props} />;

export default Flex;
