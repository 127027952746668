import { createAction } from 'typesafe-actions';
import { Auth0DecodedHash } from 'auth0-js';

export const completeSignupRequest = createAction('COMPANY_COMPLETE_SIGNUP_REQUEST');
export const completeSignupSuccess = createAction(
  'COMPANY_COMPLETE_SIGNUP_SUCCESS',
  (resolve) => (authResult: Auth0DecodedHash) => resolve(authResult)
);

export const completeSignupFailure = createAction('COMPANY_COMPLETE_SIGNUP_FAILURE', (resolve) => (err: any) => resolve(err));
