import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { Auth0DecodedHash } from 'auth0-js';

import { decodeToken } from 'util/jwt/decodeToken';

import { CurrentUserState } from './types';
import * as currentUserActions from './currentUserActions';
import * as sessionActions from '../session/sessionActions';
import * as companySignupActions from '../companySignup/companySignupActions';

import * as jwtActions from '../jwt/jwtActions';
import { JuridikaIdToken } from '../../models/jwt/JuridikaIdToken';
import { EMAIL_CLAIM } from '../../models/jwt/jwtClaims';

type SessionAction =
  | ActionType<typeof currentUserActions>
  | ActionType<typeof sessionActions>
  | ActionType<typeof companySignupActions>
  | ActionType<typeof jwtActions>;

const getEmailAndNameFromToken = (tokens: Auth0DecodedHash) => {
  if (!tokens.idToken) return { email: null, name: null };
  const decodedToken = decodeToken<JuridikaIdToken>(tokens.idToken)!;
  return { email: decodedToken[EMAIL_CLAIM] || null, name: decodedToken.name || null };
};

export const currentUserInitialState: CurrentUserState = {
  email: null,
  name: null,
};

export const currentUserReducers: Reducer<CurrentUserState, SessionAction> = (state = currentUserInitialState, action) => {
  switch (action.type) {
    case getType(currentUserActions.resetCurrentUser):
    case getType(jwtActions.acquireJwtFailure):
    case getType(sessionActions.resetSession):
      return currentUserInitialState;
    case getType(sessionActions.login.success):
    case getType(sessionActions.loginWithIp.success):
    case getType(sessionActions.refreshIpToken.success):
    case getType(companySignupActions.completeSignupSuccess):
      return {
        ...state,
        ...getEmailAndNameFromToken(action.payload),
      };
    case getType(sessionActions.ssoLogin.success):
      return {
        ...state,
        ...getEmailAndNameFromToken(action.payload.jwt),
      };
    default:
      return state;
  }
};
