import React, { Attributes, Fragment, ReactElement, ReactNode, createElement } from 'react';
import { Link } from 'react-router-dom';

import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { CHILD, CHILDREN, ELEMENT, KEY, TYPE } from '../models/dom/domNode';

import { HTML_ATTRIBUTES, HypertextNode, TAGNAME } from './hypertextAllocator';
import { textGenerator } from './dom/domGenerators';

export interface RenderContext {
  level: number;
  currentPartId: string;
  paragraphId?: string;
}

export interface RenderNode {
  node: HypertextNode;
  renderNode: RenderFn;
  overrides: (node: HypertextNode) => {
    [key: string]: string;
  } | null;
  renderContext?: RenderContext;
}

type RenderFn = (
  node: HypertextNode,
  props: Attributes | null,
  overrides: (node: HypertextNode) => { [key: string]: string } | null,
  renderContext?: RenderContext
) => ReactElement | null;

export const renderNode = (
  node: HypertextNode,
  props: Attributes | null = null,
  overrides: (node: HypertextNode) => { [key: string]: string } | null = () => null
): ReactElement | null => {
  switch (node[TYPE]) {
    case ELEMENT: {
      switch (node[TAGNAME]) {
        case 'math': {
          return (
            <MathJaxContext version={3} config={{ loader: { load: ['input/mml', 'output/chtml'] }, mml: {} }}>
              <MathJax inline hideUntilTypeset="first">
                {createElement(
                  node[TAGNAME],
                  {
                    ...node[HTML_ATTRIBUTES],
                    ...props,
                    ...overrides(node),
                  },
                  ...renderChildrenOf(node, renderNode, overrides)
                )}
              </MathJax>
            </MathJaxContext>
          );
        }
        case 'script': {
          // Disable XSS
          return null;
        }
        case 'a': {
          // relative/internal URLs should render with Link
          const { [HTML_ATTRIBUTES]: htmlAttrs } = node;
          const { href = '/' } = htmlAttrs || {};
          if (href.startsWith('/')) {
            return createElement(
              Link,
              {
                ...node[HTML_ATTRIBUTES],
                ...props,
                ...overrides(node),
                to: href,
              },
              ...renderChildrenOf(node, renderNode, overrides)
            );
          }
        }

        default: {
          return createElement(
            node[TAGNAME],
            {
              ...node[HTML_ATTRIBUTES],
              ...props,
              ...overrides(node),
            },
            ...renderChildrenOf(node, renderNode, overrides)
          );
        }
      }
    }
    default: {
      return renderText(node, props);
    }
  }
};

export const renderText = (node: HypertextNode, props: Attributes | null = null): Readonly<ReactElement> => {
  const textNodes: Array<string | undefined> = [];

  for (const text of textGenerator(node)) {
    textNodes.push(text);
  }

  return createElement(Fragment, props, ...textNodes);
};

export const renderNodes = (
  nodes: HypertextNode[],
  childFn: RenderFn,
  overrides: (node: HypertextNode) => { [key: string]: string } | null = () => null,
  renderContext: RenderContext = { level: 1, currentPartId: '' }
): Readonly<ReactNode[]> => {
  return nodes.map((node) => childFn(node, { key: node[KEY] }, overrides, renderContext));
};

export const renderChildrenOf = (
  node: HypertextNode,
  childFn: RenderFn,
  overrides: (node: HypertextNode) => { [key: string]: string } | null = () => null,
  renderContext: RenderContext = { level: 1, currentPartId: '' }
): Readonly<ReactNode[]> => {
  const child = node[CHILD];
  if (child) {
    return [childFn(child, null, overrides, renderContext)];
  }

  const children = node[CHILDREN];
  if (children) {
    return renderNodes(children, childFn, overrides, renderContext);
  }

  return [];
};

const isBlank = (str: string | undefined): boolean => {
  return !str || /^\s*$/.test(str);
};

export const hasNonBlankTextContent = (node: HypertextNode): boolean => {
  for (const text of textGenerator(node)) {
    if (!isBlank(text)) {
      return true;
    }
  }
  return false;
};
