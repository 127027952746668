import React, { FC, ForwardedRef, ReactNode, forwardRef } from 'react';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';

interface AcademyButtonProps {
  label: string;
  onClick: () => void;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  className?: string;
  ref?: ForwardedRef<HTMLButtonElement>;
}

export const commonButtonStyles = css`
  ${buttonWithoutStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
  border-radius: 4px;

  :hover {
    opacity: 1;
  }

  :focus-visible {
    outline: none;
  }
`;

export const PrimaryButtonPurpleCss = styled.button`
  ${commonButtonStyles}

  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  color: ${({ theme }) => theme.colorTokens.default.white};

  :hover {
    background-color: ${({ theme }) => theme.colorTokens.purple[300]};
    color: ${({ theme }) => theme.colorTokens.purple[700]};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.purple[700]};
    }
  }

  :focus-visible {
    border: none;
    background-color: ${({ theme }) => theme.colorTokens.default.white};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.purple[700]};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.purple[700]};
    }
  }
`;

const SecondaryButtonPurpleCss = styled.button`
  ${commonButtonStyles}

  color: ${({ theme }) => theme.colorTokens.purple[700]};
  background-color: ${({ theme }) => theme.colorTokens.default.white};

  border: 1px solid ${({ theme }) => theme.colorTokens.default.black};
  padding: 0.75rem 1.25rem;
  border-radius: 4px;

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    color: ${({ theme }) => theme.colorTokens.default.white};

    svg * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

export const PrimaryButtonPurple: FC<AcademyButtonProps> = forwardRef(
  ({ label, onClick, iconLeft, iconRight, className = '' }, ref) => (
    <PrimaryButtonPurpleCss onClick={onClick} className={className} ref={ref}>
      {iconLeft && iconLeft}
      <span>{label}</span>
      {iconRight && iconRight}
    </PrimaryButtonPurpleCss>
  )
);

export const SecondaryButtonPurple: FC<AcademyButtonProps> = forwardRef(
  ({ label, onClick, iconLeft, iconRight, className = '' }, ref) => (
    <SecondaryButtonPurpleCss onClick={onClick} className={className} ref={ref}>
      {iconLeft && iconLeft}
      <span>{label}</span>
      {iconRight && iconRight}
    </SecondaryButtonPurpleCss>
  )
);
