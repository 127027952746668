import * as React from 'react';
import styled from 'styled-components';

import { ColorTokenValues } from 'theme/config/types';

const FooterLinkCSS = styled.div<{ color?: ColorTokenValues }>`
  font-size: 0.875rem;
  color: ${(props) => props.color};

  a,
  a:active,
  a:hover,
  a:visited {
    color: ${(props) => props.color || 'inherit'};
  }
`;

const FooterLinkToHelpPages: React.FC<{ color?: ColorTokenValues }> = (props) => (
  <FooterLinkCSS {...props}>
    Besøk våre <a href="https://innhold.juridika.no/hjelpesider">hjelpesider</a>
  </FooterLinkCSS>
);

export default FooterLinkToHelpPages;
