import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Chat: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.9988 9.99999C19.9988 15.5125 15.5129 19.9984 10.0004 19.9984C8.40664 19.9984 6.83164 19.6141 5.41602 18.8875L1.58633 19.9562C1.47852 19.9844 1.36602 19.9984 1.25352 19.9984C0.92539 19.9984 0.60664 19.8719 0.367578 19.6328C0.0488276 19.3141 -0.0730468 18.85 0.0488276 18.4141L1.11758 14.5844C0.386328 13.1687 0.00195312 11.5937 0.00195312 9.99999C0.00195312 4.48749 4.48789 0.0015564 10.0004 0.0015564C15.5129 0.0015564 19.9988 4.48749 19.9988 9.99999ZM10.0004 9.00156C9.44727 9.00156 9.00195 9.44687 9.00195 9.99999C9.00195 10.5531 9.44727 10.9984 10.0004 10.9984C10.5535 10.9984 10.9988 10.5531 10.9988 9.99999C10.9988 9.44687 10.5535 9.00156 10.0004 9.00156ZM13.502 9.00156C12.9488 9.00156 12.4988 9.44687 12.4988 9.99999C12.4988 10.5531 12.9488 10.9984 13.502 10.9984C14.0551 10.9984 14.5004 10.5531 14.5004 9.99999C14.5004 9.44687 14.0551 9.00156 13.502 9.00156ZM6.49883 9.00156C5.9457 9.00156 5.50039 9.44687 5.50039 9.99999C5.50039 10.5531 5.9457 10.9984 6.49883 10.9984C7.05195 10.9984 7.50195 10.5531 7.50195 9.99999C7.50195 9.44687 7.05195 9.00156 6.49883 9.00156Z"
      fill={color}
    />
  </Svg>
);

Chat.defaultProps = {
  color: COLOR_TOKENS.default.black,
  size: 20,
};

export default Chat;
