import { createStandardAction } from 'typesafe-actions';

export const setValue = createStandardAction('ui/SET_VALUE')<unknown, Array<string> | string>();
export const hideAudioPlayer = createStandardAction('ui/HIDE_AUDIO_PLAYER')();
export const setDropdown = createStandardAction('ui/SET_DROPDOWN')<string>();
export const hideDropdown = createStandardAction('ui/HIDE_DROPDOWN')();
export const updateModuleProgressAction = createStandardAction('ui/ADD_OR_UPDATE_PROGRESS')<{
  timestampCursor: number;
  timestampTokensSeen: number[];
  totalTimestampTokens: number;
  totalTimestampTokensInChapter: number;
}>();
