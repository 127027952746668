import React, { FC } from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const SuccessFilled: FC<IconPropTypes> = ({ size, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="#009993"
          d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0z"
          transform="translate(-374 -668) translate(0 70) translate(0 40) translate(0 170) translate(0 338) translate(142 50) translate(232)"
        />
        <path
          fill="#FFF"
          d="M12.8 24.662L5.269 17.131 7.531 14.869 12.8 20.138 24.469 8.469 26.731 10.731z"
          transform="translate(-374 -668) translate(0 70) translate(0 40) translate(0 170) translate(0 338) translate(142 50) translate(232)"
        />
      </g>
    </g>
  </Svg>
);

SuccessFilled.defaultProps = {
  size: 32,
};

export default SuccessFilled;
