import { JsonMLElement } from 'models/JsonML';
import { useResourceStickers } from 'pages/NIKPage/hooks/useResourceStickers';
import React, { FC, useEffect } from 'react';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { setValue } from 'state/ui/uiActions';
import { useDispatch } from 'react-redux';
import { GqlContributorDetails } from 'pages/NIKPage/types';
import PageviewStatisticsLogger from 'containers/StatisticsLogger/PageviewStatisticsLogger';
import { useLocation } from 'react-router-dom';
import { useTextSelection } from 'util/hooks/useTextSelection';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import MarkedTextTooltip from 'components/MarkedTextTooltip';
import { ResourceStickersView } from '../ResourceStickers/ResourceStickersView';
import Byline from './Byline/Byline';
import DateLastUpdated from './DateLastUpdated';
import collectTitles from './TableOfContents/collectTitles';
import TableOfContents from './TableOfContents/TableOfContents';
import Body from './Body';
import {
  HeaderCss,
  TitleCss,
  DraftTextCss,
  NeverBeforePublishedTextCss,
  DocumentContainerCss,
  NIKContentContainerCss,
} from './styles';

export interface Contributors {
  name: string;
  title: string;
  shortBio: string;
  slug: string;
  id: string;
  hasPortrait: boolean;
}

interface DocumentProps {
  publicationTitle: string;
  publishedAt: string;
  jsonmlDocument: JsonMLElement;
  primaryTag: { name: string };
  contributorDetails: GqlContributorDetails[];
  isDraft?: boolean;
}

export const NIK_VIEWPORT_SIZE = {
  small: 576,
  medium: 767,
  large: 992,
};

const flattenContributorDetailsObject = (
  gqlContributorDetails: GqlContributorDetails[]
): Contributors[] =>
  gqlContributorDetails.map((contributor) => {
    const { names, hasPortrait, slug, id } =
      contributor.contributorConnection.node;

    const { title, shortBio } = contributor;

    return {
      name: names.join(' '),
      title,
      shortBio,
      slug,
      id,
      hasPortrait,
    };
  });

const Document: FC<DocumentProps> = ({
  publicationTitle,
  publishedAt,
  jsonmlDocument,
  primaryTag,
  contributorDetails,
  isDraft,
}) => {
  const viewportSize = useViewportSize(NIK_VIEWPORT_SIZE, 'small');
  const dispatch = useDispatch();
  const location = useLocation();

  const juridikaConfig = useJuridikaConfig();

  const {
    stickers,
    preloadLawFragment,
    addOrScrollToLegalFragmentSticker,
    closeResourceSticker,
    scrollResourceBoxesViewToTopCounter,
  } = useResourceStickers();

  const {
    showToolTip,
    setShowToolTip,
    toolTipCoords,
    selectedText,
    handleTextSelection,
  } = useTextSelection();

  const handleCloseResourceSticker = (stickerId: string) => {
    closeResourceSticker(stickerId);
  };

  const { titles, ids } = collectTitles(jsonmlDocument);
  const hasTOC = titles.length > 0 && ids.length > 0;

  useEffect(() => {
    dispatch(setValue(primaryTag.name, ['nik', 'nikToolbarTitle']));
  }, [dispatch, primaryTag.name]);

  const markedTextTooltipDocument = [publicationTitle, publishedAt];
  return (
    <NIKContentContainerCss margin="25px 15px">
      <PageviewStatisticsLogger
        title={isDraft ? 'Problemstilling:utkast' : 'Problemstilling'}
      />
      {hasTOC && (
        <TableOfContents
          publicationTitle={publicationTitle}
          titles={titles}
          ids={ids}
        />
      )}
      <DocumentContainerCss
        id="nikDocument"
        onMouseUp={() =>
          handleTextSelection({
            source: 'nik',
            publicationTitle,
            publishedAt,
            contributions: flattenContributorDetailsObject(
              contributorDetails
            ).map((contributor) => contributor.name),
            location,
          })
        }
      >
        <HeaderCss>
          {isDraft && (
            <DraftTextCss>Du ser på et utkast av dokumentet</DraftTextCss>
          )}
          <TitleCss>{publicationTitle}</TitleCss>
          <Byline
            contributors={flattenContributorDetailsObject(contributorDetails)}
          />
          {publishedAt ? (
            <DateLastUpdated ISO8601Date={publishedAt} />
          ) : (
            <NeverBeforePublishedTextCss>
              Aldri tidligere publisert
            </NeverBeforePublishedTextCss>
          )}
        </HeaderCss>
        <Body
          jsonmlDocument={jsonmlDocument}
          onHoverResourceLink={preloadLawFragment}
          onClickResourceLink={addOrScrollToLegalFragmentSticker}
        />
        {selectedText && toolTipCoords.x && toolTipCoords.y && (
          <MarkedTextTooltip
            juridikaConfig={juridikaConfig}
            markedTextTooltipDocument={markedTextTooltipDocument}
            visible={showToolTip}
            hideToolTip={() => setShowToolTip(false)}
            posX={toolTipCoords.x}
            posY={toolTipCoords.y}
            selectedText={selectedText}
          />
        )}
      </DocumentContainerCss>
      {viewportSize === 'large' && (
        <ResourceStickersView
          resourceStickers={stickers}
          scrollToTopEffectCounter={scrollResourceBoxesViewToTopCounter}
          onCloseSticker={handleCloseResourceSticker}
        />
      )}
    </NIKContentContainerCss>
  );
};

export default Document;
