import React, { FC, useCallback, useState } from 'react';
import { HotspotLink, Module } from 'pages/AcademyPage/types';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';
import { timestampCursorByIdSelector } from 'state/selectors';
import ClickableHotspot from '../ClickableHotspot/ClickableHotspot';
import Video from '../Video/Video';

declare const window: Window &
  typeof globalThis & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jwplayer: any;
  };

interface Track {
  file: string;
  kind: string;
  label: string;
}

interface AcademyVideoProps {
  hotspot: {
    show: boolean;
    links?: HotspotLink[];
  };
  video: {
    id: string;
    playlist: {
      file: string;
      image: string;
      tracks: Track[];
    };
    isLastModule: boolean;
  };
  moduleMetadata: Module;
}

const AcademyVideo: FC<AcademyVideoProps> = ({ hotspot, video, moduleMetadata }) => {
  const lastSeenTimestamp = useSelector((state: RootState) => timestampCursorByIdSelector(state, moduleMetadata.id));

  const [hotspotLink, setHotspotLink] = useState<{ src: string; label: string } | null>(null);
  const [videoLoading, setVideoLoading] = useState<boolean>(true);
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const onReady = useCallback(() => {
    const player = window.jwplayer(`jwplayer-${video.id}`);
    player.seek(lastSeenTimestamp).stop();
  }, [video.id, lastSeenTimestamp]);

  const handleDisplayOrHideHotpotLink = (currentTimestamp: number) => {
    if (hotspot.links) {
      for (const link of hotspot.links) {
        if (currentTimestamp >= link.timestamp && link.timestamp + 7 > currentTimestamp) {
          setHotspotLink({ src: link.src, label: link.label });
          return; // Exit the loop after finding the first matching link
        }
      }
    }

    // If no links match the current timestamp, hide the overlay
    setHotspotLink(null);
  };

  const handleHotspotLinkDisplay = (currentTime: number) => {
    handleDisplayOrHideHotpotLink(currentTime);
  };

  return (
    <ClickableHotspot
      show={hotspot.show && !videoLoading && hotspotLink !== null}
      link={hotspotLink?.src || ''}
      label={hotspotLink?.label || ''}
    >
      <Video
        playlist={video.playlist}
        id={`jwplayer-${video.id}`}
        loading={videoLoading}
        setLoading={setVideoLoading}
        duration={videoDuration}
        setDuration={setVideoDuration}
        onReady={onReady}
        onTime={handleHotspotLinkDisplay}
        moduleMetadata={moduleMetadata}
      />
    </ClickableHotspot>
  );
};

export default AcademyVideo;
