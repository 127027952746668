import { useRouteMatch } from 'react-router';

const useRouteMatchUrlWithoutSlash = () => {
  const { url } = useRouteMatch();
  const urlWithoutTrailingSlash = removeTrailingSlash(url);

  return urlWithoutTrailingSlash;
};

const removeTrailingSlash = (str: string) => str.replace(/\/$/, '');

export default useRouteMatchUrlWithoutSlash;
