import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Close: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg
    className="c-icon c-icon-close"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 21 16"
    {...props}
  >
    <defs>
      <filter width="102.3%" height="100.5%" x="-1.1%" y="-.2%" filterUnits="objectBoundingBox">
        <feMerge>
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <path
      fill={color}
      fillRule="evenodd"
      d="M692 16.25L686.75 11 685 12.75l5.25 5.25-5.25 5.25 1.75 1.75 5.25-5.25 5.25 5.25 1.75-1.75-5.25-5.25 5.25-5.25-1.75-1.75-5.25 5.25z"
      transform="translate(-682 -9)"
    />
  </Svg>
);

Close.defaultProps = {
  color: COLOR_TOKENS.grey[800],
  size: 16,
};

export default Close;
