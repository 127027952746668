import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import Close from 'components/icons/Close';
import { buttonWithoutStyle } from 'styleMixins/listMixins';
import Modal from 'components/ui/Modal/Modal';
import useFocusTrap from 'util/hooks/useFocusTrap';
import CategoryPageNavigation from '../CategoryPageNavigation/CategoryPageNavigation';
import JuridikaLogo from '../../../../../../components/icons/Logos/JuridikaLogo';
import CurrentUserDropdownOptions from '../NavbarDropdown/CurrentUserDropdownOptions';
import { NavigationType } from '../../../../utils';
import { CategoryPage } from '../CategoryPageNavigation/categoryPages';

const SmallViewportMenuCss = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #c53034;
  overflow-y: scroll;
  padding: 1.875rem 0;

  hr {
    margin: 0;
  }
`;

const LogoWrapperCss = styled.div`
  text-align: center;

  svg {
    width: 9.5rem;
    height: 1.875rem;
  }
`;

const CurrentUserDropdownOptionsWrapperCss = styled.div`
  padding: 1.5625rem 0;
  text-align: center;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

const CloseButtonCss = styled.button`
  ${buttonWithoutStyle}
  position: absolute;
  top: 0.6875rem;
  right: 0.6875rem;
  display: flex;
  transition: transform 1.25s ease;
  transform-origin: center;

  svg {
    transform: translate(0.6px, -0.6px);
  }

  :hover {
    opacity: 1;
    transform: rotate(360deg);
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.default.white};
    transform: none;
    border: none;
  }
`;

interface SmallViewportMenuProps {
  open: boolean;
  setOpen: () => void;
  menuItems: CategoryPage[];
}

const SmallViewportMenu: FC<SmallViewportMenuProps> = ({ open, setOpen, menuItems }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useFocusTrap(modalRef);

  return (
    <Modal ariaLabelledbyId="mobile-menu" isOpen={open} fullscreen>
      <SmallViewportMenuCss ref={modalRef} tabIndex={-1}>
        <LogoWrapperCss>
          <JuridikaLogo color={COLOR_TOKENS.default.white} />
        </LogoWrapperCss>
        <CloseButtonCss onClick={setOpen} aria-label="Lukk meny">
          <Close color={COLOR_TOKENS.default.white} size={24} />
        </CloseButtonCss>
        <CategoryPageNavigation navType={NavigationType.mobileMenu} menuItems={menuItems} />
        <hr />
        <CurrentUserDropdownOptionsWrapperCss>
          <CurrentUserDropdownOptions />
        </CurrentUserDropdownOptionsWrapperCss>
      </SmallViewportMenuCss>
    </Modal>
  );
};

export default SmallViewportMenu;
