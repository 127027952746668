import * as React from 'react';
import styled from 'styled-components';

import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { constibutorsStr } from 'util/contributorsStr';
import { FavoriteCollection } from 'util/hooks/useFavoriteMutation';
import {
  GqlFavoriteLegalDocument,
  GqlAnyFavoritesEdge,
  GqlContribution,
  GqlLiteratureDocument,
  GqlLiteratureDocumentContributor,
  GqlPublishedPage,
} from 'util/hooks/useFavoritesQuery';
import { PublicationLink } from 'util/literatureUtils';
import { staticPageRelativeUrl } from 'util/staticContentHelpers';
import { legalDocumentLatestExpressionLink } from 'util/urlHelpers';
import DividerDot from 'icons/DividerDot';
import { listWithoutStyle } from '../../styleMixins/listMixins';
import ContributionLink from '../ContributionLink';
import { ButtonAndLinkProps } from '../Button/ButtonAndLinkCss';
import Favorite from './Favorite';
import { getTemplateRelativeUrl } from '../Template';

export const FavoriteListCss = styled.ul`
  ${listWithoutStyle}
`;

export const FavoriteListItemCss = styled.li`
  padding: ${(props) => props.theme.spacing.sm} 0;
  border-bottom: 1px solid ${(props) => props.theme.colorTokens.brown[300]};
`;

const DividerDotWrapperCss = styled.span`
  margin: ${(props) => props.theme.spacing.xs};
`;

const Contributors: React.FC<{
  contributions: GqlContribution<GqlLiteratureDocumentContributor>[];
}> = ({ contributions }) => {
  return (
    <span>
      Av&nbsp;
      {contributions.map((contribution, index) => (
        <React.Fragment key={contribution.node.slug}>
          <ContributionLink
            contribution={{
              role: contribution.role,
              contributor: contribution.node,
            }}
            $hasTextDecoration
            $hasTextDecorationOnHover
            $color={COLOR_TOKENS.blue[700]}
          />
          {index < contributions.length - 1 && ', '}
        </React.Fragment>
      ))}
    </span>
  );
};

const publicationDate = (dateAsString: string): string => {
  const date = new Date(dateAsString);

  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  const yy = date.getFullYear();

  return `${dd}.${mm}.${yy}`;
};

const getFavoriteProps = (
  node: GqlFavoriteLegalDocument | GqlLiteratureDocument | GqlPublishedPage
): {
  title: string;
  subtitle?: string;
  contributorsAndEditionInfo?: string | JSX.Element;
  titlePath: string;
  collection: FavoriteCollection;
} => {
  // eslint-disable-next-line no-underscore-dangle
  switch (node.__typename) {
    case 'LiteratureDocument': {
      const {
        titles: documentTitles,
        key,
        edition: {
          path,
          originallyPublishedAt,
          availability,
          name,
          titles: editionTitles,
          publication: { slug, category },
        },
        contributions,
      } = node;

      const [documentTitle, documentSubtitle] = documentTitles;
      const [editionTitle, editionSubtitle] = editionTitles;

      const isTextbook = category.includes('fagbok');
      const isRevised = path.length === 1 && path[0] !== '1';

      const title = isTextbook ? editionTitle : documentTitle;
      const subtitle = isTextbook ? editionSubtitle : documentSubtitle;

      const editionInfo = isTextbook
        ? [isRevised && name.toLowerCase(), new Date(originallyPublishedAt).getFullYear()].filter((item) => item).join(', ')
        : [...path].reverse().join('/');
      return {
        title,
        subtitle,
        contributorsAndEditionInfo: (
          <>
            {[
              ...(contributions && [<Contributors key="contributions" contributions={contributions.edges} />]),
              ...(contributions && [
                <DividerDotWrapperCss key="dividerdot">
                  <DividerDot />
                </DividerDotWrapperCss>,
              ]),
              editionInfo,
            ]}
          </>
        ),
        titlePath: new PublicationLink(slug, category).withEdition(path, availability).withDocument(key).url().toString(),
        collection: 'FavoriteLiteratureDocument',
      };
    }
    case 'PublishedPage': {
      const { title, contributions, firstPublishedAt, slug, contentCategory } = node;

      const titlePath =
        contentCategory?.semanticKey === 'kontrakt' ? getTemplateRelativeUrl(slug) : staticPageRelativeUrl(slug).toString();

      return {
        title,
        contributorsAndEditionInfo: (
          <>
            {[
              constibutorsStr(contributions.edges.map((contribution) => contribution.node)),
              <DividerDotWrapperCss key="dividerdot">
                <DividerDot />
              </DividerDotWrapperCss>,
              publicationDate(firstPublishedAt),
            ]}
          </>
        ),
        titlePath,
        collection: 'FavoritePublishedPage',
      };
    }
    case 'LegalDocument': {
      return {
        title: node.shortTitle,
        titlePath: legalDocumentLatestExpressionLink({
          countryCode: node.countryCode,
          subtype: node.subtype,
          date: node.date,
          seq: node.seq,
          shouldDisplayComment: false,
        }),
        collection: 'FavoriteActWork',
      };
    }
  }
};

const FavoritesList: React.FC<{
  edges?: GqlAnyFavoritesEdge[];
  buttonConfig?: ButtonAndLinkProps;
  iconLeft?: boolean;
}> = ({ edges, buttonConfig, iconLeft }) => {
  if (!edges) return null;

  return (
    <FavoriteListCss>
      {edges.map((edge: GqlAnyFavoritesEdge) => {
        const {
          node,
          node: { id, userMetadata },
        } = edge;

        return (
          <FavoriteListItemCss key={id}>
            <Favorite
              buttonConfig={buttonConfig}
              isFavorite={!!userMetadata?.favoriteCreatedAt}
              mutationVariables={{ id }}
              graphqlResource={node}
              iconLeft={!!iconLeft}
              {...getFavoriteProps(edge.node)}
            />
          </FavoriteListItemCss>
        );
      })}
    </FavoriteListCss>
  );
};

export default FavoritesList;
