import styled from 'styled-components';
import createFontStyles from 'util/createFontStyles';

export const FormWrapperCss = styled.div`
  margin: 0 auto;
  padding: 1.25rem;
  max-width: 37.5rem;
  background: ${(props) => props.theme.colorTokens.peach[400]};
`;

export const FormSectionCss = styled.div`
  font-size: 1.125rem;
  margin: 1.5625rem 0;

  :first-child {
    margin-top: 0;
  }
`;

export const FormHeadingCss = styled.h2`
  margin: 0.625rem 0;

  ${(props) =>
    createFontStyles({
      family: props.theme.fontFamilies.secondary,
      size: 1.125,
      weight: 600,
    })};
`;

export const FrontlistInfoCss = styled.p`
  margin: 0;
  font-size: 1rem;
`;
