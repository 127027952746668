import URI from 'urijs';
import { Auth0DecodedHash } from 'auth0-js';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { JURIDIKA_SUBSCRIPTIONS } from 'commonUtils/serviceConstants';
import { PAGES_WE_DO_NOT_WANT_TO_RETURN_TO } from 'commonUtils/constants';
import { Auth0 } from 'util/auth0';
import { getIsomorphicApiUrl } from 'util/apiUrls';
import { withTimeout } from 'util/promiseUtils';
import { resetCurrentUser } from '../currentUser/currentUserActions';
import * as referrerActions from '../referrer/referrerActions';
import { ThunkResult, ThunkDispatch } from '../types';
import * as sessionActions from './sessionActions';
import { ActiveLoginMethod } from '../login/types';
import { TokenSource } from './types';

const _ipCheckUri = (config: JuridikaConfig) =>
  getIsomorphicApiUrl(config, JURIDIKA_SUBSCRIPTIONS).segment('auth').segment('check');

const ipLoginUri = (config: JuridikaConfig) =>
  getIsomorphicApiUrl(config, JURIDIKA_SUBSCRIPTIONS).segment('auth').segment('login');

interface TimeoutOptions {
  timeoutMs: number;
}

const fetchIpToken = async (config: JuridikaConfig, options?: TimeoutOptions): Promise<Auth0DecodedHash> => {
  const fetchPromise = fetch(ipLoginUri(config).toString(), { method: 'POST' });

  const response = options && options.timeoutMs ? await withTimeout(fetchPromise, options.timeoutMs) : await fetchPromise;
  if (!response.ok) {
    throw new Error(`${response.status}`);
  }

  const ipAuthResult = await response.json();
  return {
    accessToken: ipAuthResult.access_token,
    idToken: ipAuthResult.id_token,
    tokenType: ipAuthResult.tokenType,
    expiresIn: ipAuthResult.expires_in,
  };
};

export const checkIp =
  (_config: JuridikaConfig): ThunkResult<void> =>
  async (_dispatch) => {
    /*
  dispatch(sessionActions.checkIp.request());
  try {
    const response = await fetch(ipCheckUri(config).toString());
    if (!response.ok) {
      throw new Error(`${response.status}`);
    }

    const text = await response.text();
    dispatch(sessionActions.checkIp.success({ canDoIpLogin: text === 'true' }));
  } catch (error) {
    dispatch(sessionActions.checkIp.failure({ error }));
  } */
  };

export const loginWithIp =
  (config: JuridikaConfig, options?: { timeoutMs: number }): ThunkResult<void> =>
  async (dispatch, getState) => {
    if (config.isServer) return;

    // Prevent multiple login calls if we are already logging in
    if (getState().login.activeLoginMethod === ActiveLoginMethod.IP) return;

    // TODO: Parameterize path
    dispatch(sessionActions.loginWithIp.request());
    try {
      const authResult = await fetchIpToken(config, options);

      dispatch(sessionActions.loginWithIp.success(authResult));
    } catch (error) {
      dispatch(sessionActions.loginWithIp.failure({ error }));
    }
  };

// Only use from jwt middleware!
export const refreshIpToken =
  (config: JuridikaConfig, options?: { timeoutMs: number }): ThunkResult<void> =>
  async (dispatch, getState) => {
    if (config.isServer) return;

    // Prevent multiple login calls if we are already logging in
    if (getState().login.activeLoginMethod === ActiveLoginMethod.IP) return;

    // TODO: Parameterize path
    dispatch(sessionActions.refreshIpToken.request());
    try {
      const authResult = await fetchIpToken(config, options);
      dispatch(sessionActions.refreshIpToken.success(authResult));
    } catch (error) {
      dispatch(sessionActions.refreshIpToken.failure({ error }));
    }
  };

export const setReferrer = (dispatch: ThunkDispatch): void => {
  // TODO: Parameterize path
  const referrer = new URI(window.location.pathname + window.location.search + window.location.hash);
  if (!PAGES_WE_DO_NOT_WANT_TO_RETURN_TO.has(window.location.pathname)) {
    dispatch(referrerActions.setReferrer(referrer.toString()));
  }
};

export const login =
  (config: JuridikaConfig, auth0: Auth0): ThunkResult<void> =>
  async (dispatch, getState) => {
    if (config.isServer) return;

    // Prevent multiple login calls if we are already logging in
    if (getState().login.activeLoginMethod === ActiveLoginMethod.USER) return;

    setReferrer(dispatch);
    dispatch(sessionActions.login.request());
    auth0.changeLocationToAuth0Lock({});
  };

// Only use from jwt middleware!
export const ssoLogin =
  (currentTokenSource: TokenSource, config: JuridikaConfig, auth0: Auth0): ThunkResult<void> =>
  async (dispatch, getState) => {
    if (config.isServer) return;

    const state = getState();
    // Prevent multiple login calls if we are already logging in
    if (state.login.activeLoginMethod === ActiveLoginMethod.SSO) return;
    dispatch(sessionActions.ssoLogin.request());
    try {
      const authResult = await auth0.refreshAccessToken();
      dispatch(sessionActions.ssoLogin.success({ jwt: authResult, tokenSource: currentTokenSource }));
    } catch (error) {
      dispatch(sessionActions.ssoLogin.failure({ error }));
    }
  };

export const logout = (): ThunkResult<void> => (dispatch) => {
  dispatch(sessionActions.resetSession());
  dispatch(resetCurrentUser());
  // We need to log out via the middleware if using server side auth
  window.location.replace('/logout');
};
