import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import Checked from './Checked';
import IconPropTypes from './IconPropTypes';

const CheckboxOn: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} {...props}>
    <>
      <Svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.499939" width="20" height="20" rx="4" fill={color} />
      </Svg>
      <Checked color={COLOR_TOKENS.default.white} width={17} height={12} y={4} x={1} />
    </>
  </Svg>
);

CheckboxOn.defaultProps = {
  size: 18,
  color: COLOR_TOKENS.purple[800],
};

export default CheckboxOn;
