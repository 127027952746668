import * as React from 'react';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const DoubleArrowsRight: React.FC<IconPropTypes> = ({ color, title, ...props }) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.35786 7.36173L1.58849 0.284662C1.2381 -0.0830379 0.653859 -0.0964997 0.285006 0.255815C0.0950019 0.437357 0 0.680054 0 0.922751C0 1.15233 0.0853479 1.38199 0.256005 1.56084L6.41551 8.0002L0.257698 14.4388C-0.0932711 14.8078 -0.0824632 15.3919 0.286544 15.7442C0.655552 16.0964 1.23964 16.0832 1.59196 15.7154L8.36132 8.63829C8.70017 8.28098 8.70018 7.71943 8.35786 7.36173ZM15.7426 7.36173L8.97326 0.284662C8.62325 -0.0830379 8.03862 -0.0964997 7.66938 0.255815C7.48092 0.437357 7.38476 0.680054 7.38476 0.922751C7.38476 1.15233 7.47011 1.38199 7.64077 1.56084L13.8003 8.0002L7.64246 14.4388C7.29149 14.8078 7.3023 15.3919 7.67131 15.7442C8.04032 16.0964 8.6244 16.0832 8.97672 15.7154L15.7461 8.63829C16.0849 8.28098 16.0849 7.71943 15.7426 7.36173Z"
      fill={color}
    />
  </Svg>
);

DoubleArrowsRight.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default DoubleArrowsRight;
