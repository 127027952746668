interface ParsedQuery {
  [key: string]: string;
}

export const parseQuery = (search: string): ParsedQuery => {
  const query = search.replace(/\?/g, '');
  let parsedQuery = {};

  if (query) {
    parsedQuery = query.split('&').reduce((result, q) => {
      const [key, value] = q.split('=');

      return {
        ...result,
        [key]: value,
      };
    }, {});
  }

  return parsedQuery;
};

export const sanitizeSearchTerm = (term: string) => {
  // Strip away soft hyphens (sometimes included when copy/pasting titles)
  return term.replace(/\u00AD/g, '');
};
