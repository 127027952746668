import { takeWhile } from './collectionUtils';
import { selfAndAncestorsOf, isHtmlElement, hasClassAnyOf, firstFollowingNode } from './domUtils';

export const extractCommentaryParagraphNumbers = (selection: Selection): [string, string] | null => {
  if (selection && !selection.isCollapsed && selection.rangeCount > 0) {
    /* Most browsers support only 1 range in a selection, and it is unclear how
     * the logic here would work for several ranges anyway. */
    const range: Range = selection.getRangeAt(0);

    const startParagraphNumber: string | null = getAssociatedParagraphNumber(range, 'start');
    const endParagraphNumber: string | null = getAssociatedParagraphNumber(range, 'end');

    if (startParagraphNumber !== null && endParagraphNumber !== null) {
      return [startParagraphNumber, endParagraphNumber];
    }
  }
  return null;
};

const getAssociatedParagraphNumber = (range: Range, nodeRole: 'start' | 'end'): string | null => {
  const numberedParagraph: HTMLElement | null = getAssociatedNumberedParagraph(range, nodeRole);
  if (numberedParagraph && numberedParagraph.dataset) {
    const { paragraphNumber } = numberedParagraph.dataset;
    if (paragraphNumber) {
      return paragraphNumber;
    }
  }
  return null;
};

const getAssociatedNumberedParagraph = (range: Range, nodeRole: 'start' | 'end'): HTMLElement | null => {
  const node: Node = nodeRole === 'start' ? range.startContainer : range.endContainer;
  const otherEnd: Node = nodeRole === 'start' ? range.endContainer : range.startContainer;

  if (!isUnderCommentaryBody(node)) {
    /* Commentary paragraph numbers can only be extracted when selecting within
     * the Commentary Body react element.
     * Could have restricted it to the commentary root as returned from the backend,
     * but that would have excluded the fragment title and the commentary metadata,
     * which we would rather support. */
    return null;
  }

  /* Strategy for identifying the related paragraph number:
   * 1. Search upwards.
   *    Trivial case: the selection boundary is directly in a numbered paragraph. */
  const paragraphAncestor: HTMLElement | null = getTopmostNumberedParagraphAncestor(node);
  if (paragraphAncestor) {
    return paragraphAncestor;
  }

  /* 2. Search backwards.
   *    The selection boundary is in a node "belonging" to a preceding node.
   *    E.g. a quote or a list immediately after a numbered paragraph.
   *    To identify this case, search backwards until a numbered paragraph is found.
   *    Terminate the search as unsuccessful if:
   *    2a. (for the selection start) a section boundary is met, or
   *    2b. (for the selection end) both the selection start _and_ a section boundary
   *        have been encountered.
   *        We need to wait until having encountered both, because it should work in
   *        both the following example scenarios:
   *        A. the selection starts and ends within the same quote element, but that
   *           quote belongs to the preceding numbered paragraph.
   *        B. the selection crosses a section boundary, and ends at an unnumbered
   *           element immediately after this boundary, so you want to return the
   *           last numbered paragraph _before_ the section boundary.
   */
  if (nodeRole === 'end') {
    /* Case 2b above */
    const precedingNumberedParagraph: HTMLElement | undefined =
      getClosestPrecedingNumberedParagraphUntilSelectionStartAndSectionBoundary(node, otherEnd);
    if (precedingNumberedParagraph) {
      return precedingNumberedParagraph;
    }
  } else {
    // nodeRole === 'start'
    if (isLikelyOwnedByPrecedingNumberedParagraph(node)) {
      /* Case 2a above */
      const precedingNumberedParagraph: HTMLElement | undefined = getClosestRelatedPrecedingNumberedParagraph(node);
      if (precedingNumberedParagraph) {
        return precedingNumberedParagraph;
      }
    }

    /* 3. Search forwards (not for the selection end).
     *    The selection start is not in a numbered paragraph or belonging to a
     *    preceding numbered paragraph.
     *    E.g. the selection starts at a heading.
     *    Search forward until a numbered paragraph is found. */
    const closestParagraph: HTMLElement | undefined = findClosestNumberedParagraph(node, 'next', otherEnd);
    if (closestParagraph) {
      return closestParagraph;
    }
  }

  /* 4. No numbered paragraph can be found. */
  return null;
};

const isUnderCommentaryBody = (node: Node): boolean => {
  const ancestors: Node[] = selfAndAncestorsOf(node);
  const commentaryBodyAncestor: HTMLElement | undefined = ancestors.filter(isHtmlElement).find(isCommentaryBody);
  return !!commentaryBodyAncestor;
};

const isCommentaryBody = (element: HTMLElement): boolean =>
  element.tagName.toLowerCase() === 'div' && element.classList.contains('c-commentary__body');

const getTopmostNumberedParagraphAncestor = (node: Node): HTMLElement | null => {
  const ancestors: Node[] = selfAndAncestorsCommentaryNodes(node);
  const numberedParagraphs: HTMLElement[] = ancestors.filter(isNumberedParagraph) as HTMLElement[];
  if (numberedParagraphs.length > 0) {
    return numberedParagraphs[numberedParagraphs.length - 1];
  }
  return null;
};

const selfAndAncestorsCommentaryNodes = (node: Node): Node[] => {
  const isNotCommentaryBody = (ancestor: Node) => !(isHtmlElement(ancestor) && isCommentaryBody(ancestor));
  return takeWhile(selfAndAncestorsOf(node), isNotCommentaryBody);
};

const isNumberedParagraph = (node: Node): node is HTMLElement => isNormalParagraph(node) && !!node?.dataset?.paragraphNumber;

const isNormalParagraph = (node: Node): node is HTMLElement =>
  /* In the commentary XML schema, the <Avsnitt> element is the canonical element
   * for containing text (i.e. for changing from a hierarchical context to an inline
   * text context).
   * It is used in to represent normal paragraphs, but also quotes and margin-text
   * (via boolean attributes). If it does not represent a quote or margin-text,
   * it is given the class 'Normal'. */
  isHtmlElement(node) && node.nodeName.toLowerCase() === 'p' && node.classList.contains('Normal');

const isLikelyOwnedByPrecedingNumberedParagraph = (node: Node): boolean =>
  selfAndAncestorsCommentaryNodes(node).filter(isNormalParagraph).length === 0;

const getClosestRelatedPrecedingNumberedParagraph = (origin: Node): HTMLElement | undefined =>
  firstFollowingNode(origin, 'previous', isNumberedParagraph, isSectionBoundary);

const isSectionBoundary = (node: Node): node is HTMLElement =>
  /* The commentary body, at least, should always exist, so long as the selection
   * was made from the commentary panel (which should have been asserted higher
   * up in the call stack, in getAssociatedNumberedParagraph). */
  isHtmlElement(node) && (isSection(node) || isIntermediateHeading(node) || isCommentaryBody(node));

/* Section as in a (typically titled) part of a larger professional text,
 * not as in a clause in an act of law. */
const isSection = (element: HTMLElement): boolean =>
  element.tagName.toLowerCase() === 'div' && hasClassAnyOf(element, ['Seksjon', 'Subsek1', 'Subsek2', 'Subsek3']);

const isIntermediateHeading = (element: HTMLElement): boolean =>
  // mtit = mellomtittel
  element.classList.contains('mtit');

const getClosestPrecedingNumberedParagraphUntilSelectionStartAndSectionBoundary = (
  origin: Node,
  otherEnd: Node
): HTMLElement | undefined => {
  /* Necessary e.g. when ending the selection in a list item of a list which belongs
   * to a numbered paragraph, but which is not a descendant of said paragraph. */
  const reachedSelectionStartAndSectionStart = ((): ((node: Node) => boolean) => {
    let haveReachedSelectionStart = false;
    let haveReachedSectionStart = false;

    return (node: Node): boolean => {
      if (!haveReachedSelectionStart) {
        haveReachedSelectionStart = node === otherEnd;
      }
      if (!haveReachedSectionStart) {
        haveReachedSectionStart = isSectionBoundary(node);
      }
      return haveReachedSelectionStart && haveReachedSectionStart;
    };
  })();

  return firstFollowingNode(origin, 'previous', isNumberedParagraph, reachedSelectionStartAndSectionStart);
};

const findClosestNumberedParagraph = (origin: Node, direction: 'next' | 'previous', otherEnd: Node): HTMLElement | undefined => {
  const reachedSelectionEnd = (node: Node): boolean => node === otherEnd;

  return firstFollowingNode(origin, direction, isNumberedParagraph, reachedSelectionEnd);
};
