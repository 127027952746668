import React from 'react';
import SpinnerIcon from 'icons/SpinnerIcon';

const sizeValues = {
  small: '16px',
  low_medium: '19px',
  medium: '22px',
  large: '32px',
  big: '64px',
};

const speedValues = {
  slow: '1s',
  medium: '0.6s',
  fast: '0.3s',
};

interface Props {
  size?: keyof typeof sizeValues;
  speed?: keyof typeof speedValues;
  animation?: string;
}

const processProps = ({ speed, size, ...props }: Props): { style: React.CSSProperties } => ({
  ...props,
  style: {
    animation: `spinner ${speedValues[speed || 'medium']} linear infinite`,
    width: sizeValues[size || 'medium'],
    height: sizeValues[size || 'medium'],
    objectFit: 'contain',
  },
});

const LoadingSpinner: React.FC<Props> = (props) => <SpinnerIcon {...processProps(props)} />;

export default LoadingSpinner;
