import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Gavel: React.FC<IconPropTypes> = ({ color, ariaLabel, ...props }) => (
  <Svg width={20} height={20} ariaLabel={ariaLabel} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.4446 0.365234C11.9565 -0.123047 11.1637 -0.123047 10.6756 0.365234L5.98946 5.05273C5.50132 5.54102 5.50132 6.33398 5.98946 6.82227L6.61427 7.44727C7.10241 7.93555 7.89515 7.93555 8.38329 7.44727L8.53949 7.29102L12.7101 11.459L12.5539 11.6152C12.0658 12.1035 12.0658 12.8965 12.5539 13.3848L13.1788 14.0098C13.6669 14.498 14.4596 14.498 14.9478 14.0098L19.6339 9.32227C20.122 8.83398 20.122 8.04102 19.6339 7.55273L19.0091 6.92773C18.5209 6.43945 17.7282 6.43945 17.2401 6.92773L17.0839 7.08398L12.9132 2.91211L13.0694 2.75586C13.5576 2.26758 13.5576 1.47461 13.0694 0.986328L12.4446 0.361328V0.365234ZM6.50883 11.6152C6.0207 11.127 5.22796 11.127 4.73982 11.6152L0.366104 15.9902C-0.122035 16.4785 -0.122035 17.2715 0.366104 17.7598L2.24055 19.6348C2.72869 20.123 3.52143 20.123 4.00957 19.6348L8.38329 15.2598C8.87142 14.7715 8.87142 13.9785 8.38329 13.4902L8.32861 13.4355L10.6248 11.1426L8.8558 9.37305L6.56351 11.666L6.50883 11.6113V11.6152Z"
      fill={color}
    />
  </Svg>
);

Gavel.defaultProps = {
  ariaLabel: 'Gavel',
  color: COLOR_TOKENS.grey[900],
};

export default Gavel;
