import * as React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Book2: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={42} height={40} viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.4247 3.72568H38.8495V2.15044C38.8495 1.28406 38.1504 0.575195 37.2742 0.575195H27.3009C24.7215 0.575195 22.4374 1.82555 21 3.74538C19.5626 1.82555 17.2785 0.575195 14.699 0.575195H4.72573C3.8495 0.575195 3.15049 1.28406 3.15049 2.15044V3.72568H1.57524C0.699013 3.72568 0 4.43454 0 5.30093V37.8593C0 38.7256 0.699013 39.4345 1.57524 39.4345H40.4247C41.3009 39.4345 41.9999 38.7256 41.9999 37.8593V5.30093C41.9999 4.43454 41.3009 3.72568 40.4247 3.72568ZM6.30098 3.72568H14.699C17.308 3.72568 19.4247 5.85226 19.4247 8.45142V31.5583C19.4247 32.4247 20.1336 33.1335 21 33.1335C21.8664 33.1335 22.5752 32.4247 22.5752 31.5583V8.45142C22.5752 5.85226 24.692 3.72568 27.3009 3.72568H35.699V33.1335H27.3009C24.751 33.1335 22.4571 34.3642 21 36.3136C19.5429 34.3642 17.2489 33.1335 14.699 33.1335H6.30098V3.72568Z"
      fill={color}
    />
  </Svg>
);

Book2.defaultProps = {
  color: COLOR_TOKENS.purple[400],
};

export default Book2;
