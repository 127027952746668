import { createRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  convertFragmentIdToUrlPath,
  countryCodeToFragmentProfile,
} from 'util/fragmentUrlPathConversion';
import {
  GqlLegalFragmentNode,
  GqlLegalFragmentQueryVariables,
  LEGAL_FRAGMENT_QUERY,
} from './useLegalFragmentQuery';
import { ResourceLink } from '../types';

export enum ResourceStickerType {
  LawFragment,
  Judgment,
  Regulation,
  Directive,
}

export interface ResourceStickerData {
  id: string;
  type: ResourceStickerType;
  isLoading: boolean;
  stickerHeading?: string;
  content?: {
    heading?: string;
    contentHtml: string;
  };
  stickerDivRef: React.RefObject<HTMLDivElement>;
  legalFragmentStickerData?: LegalFragmentStickerData;
}

interface LegalFragmentStickerData {
  documentShortTitle: string;
  legalResourceRouteLocation: string;
}

export const useResourceStickers = () => {
  const [stickers, setStickers] = useState<ResourceStickerData[]>([]);

  const [
    scrollResourceBoxesViewToTopCounter,
    setScrollResourceBoxesViewToTopCounter,
  ] = useState(0);

  const apolloClient = useApolloClient();

  function queryLawFragment(uri: string, fragmentId: string) {
    return apolloClient.query<
      GqlLegalFragmentNode,
      GqlLegalFragmentQueryVariables
    >({
      query: LEGAL_FRAGMENT_QUERY,
      fetchPolicy: 'cache-first',
      variables: { uri, fragmentId },
    });
  }

  function preloadLawFragment(legalUri: string, fragmentId: string) {
    queryLawFragment(legalUri, fragmentId);
  }

  function getLegalResourceRouteLocation(
    countryCode: string,
    subType: string,
    date: string,
    seq: string,
    fragmentId: string
  ) {
    const fragmentUrlParam = convertFragmentIdToUrlPath(
      fragmentId,
      countryCodeToFragmentProfile(countryCode)
    );

    const legalResourceRouteLocation = `/${countryCode}/${subType}/${date}-${seq}${fragmentUrlParam}`;

    return legalResourceRouteLocation;
  }

  async function addOrScrollToLegalFragmentSticker(resourceLink: ResourceLink) {
    const { id, legalUri, fragmentId, type } = resourceLink;
    const existingSticker = stickers.find((sticker) => sticker.id === id);

    if (existingSticker) {
      scrollToAndAnimateSticker(existingSticker);
    } else {
      const loadingSticker: ResourceStickerData = {
        id,
        type,
        isLoading: true,
        stickerHeading: undefined,
        stickerDivRef: createRef<HTMLDivElement>(),
      };

      setStickers((stickers) => [loadingSticker, ...stickers]);

      setScrollResourceBoxesViewToTopCounter((counter) => counter + 1);

      const result = await queryLawFragment(legalUri, fragmentId);

      if (!result.data.legalFragment) {
        closeResourceSticker(id);
        return;
      }

      const legalFragmentNode = result.data.legalFragment.node;
      const {
        countryCode,
        subtype,
        date,
        seq,
        shortTitle,
        abbrevation,
        fragment,
      } = legalFragmentNode;

      const legalResourceRouteLocation = getLegalResourceRouteLocation(
        countryCode,
        subtype,
        date,
        seq,
        fragmentId
      );

      const contentHeading = `${abbrevation || ''} ${
        fragment.edges[0].node.numText?.html || ''
      } ${fragment.edges[0].node.heading?.html || ''}`;

      const newLegalFragmentSticker: ResourceStickerData = {
        ...loadingSticker,
        isLoading: false,
        type,
        content: {
          contentHtml: fragment.edges[0].node.content?.html,
          heading: contentHeading,
        },
        legalFragmentStickerData: {
          documentShortTitle: shortTitle,
          legalResourceRouteLocation,
        },
      };

      // switch out the loading sticker with the one with data at the posotion of the loading sticker
      setStickers((stickers) =>
        stickers.map((existingSticker) =>
          existingSticker.id === id ? newLegalFragmentSticker : existingSticker
        )
      );
    }
  }

  function scrollToAndAnimateSticker(sticker: ResourceStickerData) {
    scrollToSticker(sticker);

    sticker.stickerDivRef.current?.classList.add('highlight-animation');
    setTimeout(() => {
      sticker.stickerDivRef.current?.classList.remove('highlight-animation');
    }, 1000);
  }

  function scrollToSticker(sticker: ResourceStickerData) {
    sticker.stickerDivRef.current?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
      behavior: 'smooth',
    });
  }

  function closeResourceSticker(id: string) {
    setStickers((stickers) => stickers.filter((sticker) => sticker.id !== id));
  }

  return {
    stickers,
    preloadLawFragment,
    addOrScrollToLegalFragmentSticker,
    closeResourceSticker,
    scrollResourceBoxesViewToTopCounter,
  };
};
