import * as React from 'react';
import * as hast from '@universitetsforlaget/hast';

import { Hast } from '../components/Hast';

import { HastConfig } from './hastConfig';

/** Render a hast fragment with react, with ability to customize the rendering for each fragment element. */
export const renderFragmentElements = (
  fragment: hast.HastFragmentNode,
  config: HastConfig,
  renderElement: (node: hast.HastElementNode, index: number) => React.ReactNode
): React.ReactElement | null => {
  if (!fragment.children) return null;

  return React.createElement(
    React.Fragment,
    {},
    fragment.children.map((node, index) => {
      if (hast.isText(node)) {
        return React.createElement(Hast, { node, config });
      }

      return renderElement(node, index);
    })
  );
};

export const hasAnyContent = (node: hast.HastNode): boolean => {
  if (hast.isText(node)) return true;

  if (node.tagName === 'fragment') {
    return node.children ? node.children.length > 0 : false;
  }

  return true;
};

export const containsDirectTextChildren = (node: hast.HastElementNode): boolean =>
  node.children ? node.children.some(hast.isText) : false;

export const extractText = (node: hast.HastNode): string => {
  if (hast.isText(node)) return node.value;
  if (!node.children) return '';

  const texts = node.children.map(extractText);

  if (containsDirectTextChildren(node)) {
    // Assumes non-text children are inlines, and therefore can
    // be safely joined without whitespace
    return texts.join('');
  }

  // Assumes there are blocks in the child list,
  // and therefore need to insert whitespace between texts.
  return texts.join(' ');
};

export const findFirstImageSrc = (node: hast.HastNode): string | null => {
  if (hast.isText(node)) return null;

  if (node.tagName === 'img' && node.properties && node.properties.src) {
    return node.properties.src;
  }

  if (!node.children) return null;

  for (const child of node.children) {
    const result = findFirstImageSrc(child);
    if (result) return result;
  }

  return null;
};

export const findByTagName = (tagName: string, nodes?: hast.HastNode[]): hast.HastElementNode[] => {
  if (!nodes) return [];

  return nodes.reduce((aggr: hast.HastElementNode[], node) => {
    if (hast.isText(node)) return aggr;

    let result = [...aggr];

    if (node.tagName === tagName) {
      result = [...result, node];
    }

    if (node.children) {
      result = [...result, ...findByTagName(tagName, node.children)];
    }

    return result;
  }, []);
};
