import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { ChapterProgress, ModuleProgress } from 'pages/AcademyPage/types';
import { ConsentCookies } from 'components/Cookiebanner/model';
import { mergeStateValue } from './utils';
import * as uiActions from './uiActions';
import { UiState } from './types';
import { updateChapterProgress, updateModuleProgress } from './academyUiProgressUtils';

type UiAction = ActionType<typeof uiActions>;

export const initialConsentCookies: ConsentCookies = {
  necessary: true,
  functional: false,
  analysis: false,
  marketing: false,
};

export const uiInitialState: UiState = {
  topElementsApproxHeight: 0,
  akademi: {
    showTranscript: false,
    hotspots: true,
    moduleProgress: [],
    chapterProgress: [],
    currentCourseId: '',
    currentChapterId: '',
    currentModuleId: '',
    returnToCourseBanner: { show: false, path: '' },
    sidebarIsOpen: false,
    isFinalTestCompleted: false,
  },
  nik: {
    nikToolbarTitle: '',
  },
  dropdown: '__none__',
  tagFilters: [],
  chat: {
    visibility: 'hidden',
  },
};

export const uiReducers: Reducer<UiState, UiAction> = (state = uiInitialState, action): UiState => {
  switch (action.type) {
    case getType(uiActions.setValue):
      return mergeStateValue(state, action.payload, action.meta);
    case getType(uiActions.setDropdown):
      return {
        ...state,
        dropdown: action.payload,
      };
    case getType(uiActions.hideDropdown):
      return {
        ...state,
        dropdown: uiInitialState.dropdown,
      };

    case getType(uiActions.updateModuleProgressAction): {
      const { timestampCursor, timestampTokensSeen, totalTimestampTokens, totalTimestampTokensInChapter } = action.payload;
      const storedModuleId = state.akademi.currentModuleId;
      const storedChapterId = state.akademi.currentChapterId;
      const storedModulesProgress = state.akademi.moduleProgress;

      const updatedModulesProgress: ModuleProgress[] = updateModuleProgress(
        storedModuleId,
        storedChapterId,
        timestampTokensSeen,
        timestampCursor,
        totalTimestampTokens,
        storedModulesProgress
      );

      const updatedChapterProgress: ChapterProgress[] = updateChapterProgress(
        storedChapterId,
        updatedModulesProgress,
        Array.isArray(state.akademi.chapterProgress) ? state.akademi.chapterProgress : [],
        totalTimestampTokensInChapter
      );

      return {
        ...state,
        akademi: {
          ...state.akademi,
          moduleProgress: updatedModulesProgress,
          chapterProgress: updatedChapterProgress,
        },
      };
    }

    default:
      return state;
  }
};
