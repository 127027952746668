import React, { FC, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { InputErrorMessage } from 'components/Input/Input';
import * as formValidator from 'util/formHelpers';
import { FormErrors } from 'util/formHelpers';
import { createLogger } from 'commonUtils/log';
import Checkbox from '../../../components/Input/Checkbox';
import Textarea from '../../../components/Input/Textarea';
import { COMPANY_INFO_FIELDS, CONTACT_INFO_FIELDS } from '../utils/formFields';
import { mapFormInput } from '../utils/mapFormInput';
import { FormSectionCss, FormWrapperCss } from '../../../components/Form/FormComponentsCSS';
import FieldGroup, { FieldGroupFormState } from '../../../components/Form/FieldGroup';
import Confirmation from './Confirmation';
import FormSubmitionErrorMessage from './FormSubmitionErrorMessage';
import { Button } from '../../../components/Button/Button';
import LoadingSpinner from '../../../components/LoadingSpinner';

const ERROR_LOGGER_ID = 'trialPeriodForm';

export interface ContactInfoState {
  firstname: string;
  lastname: string;
  email: string;
  number: string;
}

export interface CompanyInfoState {
  companyName: string;
}

export interface CheckboxInfoState {
  hasAcceptedTerms: boolean;
  shouldReceiveNewsletter: boolean;
}

export interface TicketState {
  description: string;
  subject: string;
}

export type FormState = ContactInfoState & CompanyInfoState & CheckboxInfoState & TicketState;

const initialFormState: FormState = {
  firstname: '',
  lastname: '',
  email: '',
  number: '',
  companyName: '',
  description: '',
  subject: 'Juridika prøvetilgang',
  hasAcceptedTerms: false,
  shouldReceiveNewsletter: false,
};

const ContactUsForm: FC<{
  formCommentaryText?: string;
}> = ({ formCommentaryText = '' }) => {
  const [formState, setFormState] = useState({
    ...initialFormState,
    commentary: formCommentaryText,
  });
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [submitStatusCodeResult, setSubmitStatusCodeResult] = useState<number>();
  const [errors, setFormErrors] = useState<FormErrors>({});

  const updateFormState = (fieldName: keyof FormState, value: string | boolean) => {
    resetError(fieldName);
    setFormState({
      ...formState,
      [fieldName]: value,
    });
  };

  const submitForm = async () => {
    setIsSubmitting(true);
    const log = createLogger(ERROR_LOGGER_ID);
    const config = {
      apiUrl: '/api/hubspot/form/trial',
      data: mapFormInput(formState),
      errorLoggerName: ERROR_LOGGER_ID,
    };
    fetch('/api/hubspot/form/trial', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config.data),
    })
      .then((response) => {
        setSubmitStatusCodeResult(response.status);
        return response.json();
      })
      .catch((error) => {
        log.error({ config, error });
        return error;
      });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const formErrors = formValidator.validateForm(
      [...CONTACT_INFO_FIELDS, ...COMPANY_INFO_FIELDS].map((field) => ({
        ...field,
        value: formState[field.name],
      }))
    );

    const formHasErrors = formValidator.formHasErrors(formErrors) || !formState.hasAcceptedTerms;
    if (!formHasErrors) {
      submitForm();
    } else {
      setFormErrors({
        ...formErrors,
        hasAcceptedTerms: !formState.hasAcceptedTerms ? ['Du må godta brukervilkårene'] : [],
      });
    }
  };

  const resetError = (field: string) => {
    setFormErrors(formValidator.resetError(field, errors));
  };

  return (
    <FormWrapperCss id="companySignupForm">
      {submitStatusCodeResult === 200 ? (
        <Confirmation />
      ) : (
        <form onSubmit={onSubmit} noValidate>
          {submitStatusCodeResult === 400 && <FormSubmitionErrorMessage />}
          <FormSectionCss>
            <FieldGroup
              fields={CONTACT_INFO_FIELDS}
              formState={formState as unknown as FieldGroupFormState}
              updateFormState={updateFormState}
              formErrors={errors}
              resetError={resetError}
            />
          </FormSectionCss>
          <FormSectionCss>
            <FieldGroup
              fields={COMPANY_INFO_FIELDS}
              formState={formState as unknown as FieldGroupFormState}
              updateFormState={updateFormState}
              formErrors={errors}
              resetError={resetError}
            />
          </FormSectionCss>
          <FormSectionCss>
            <Textarea
              label="Beskriv henvendelsen din"
              id="description"
              name="description"
              onChange={(e) => updateFormState('description', e.target.value)}
              maxHeight={7}
              hasBorderRadius
              value={formState.description}
            />
          </FormSectionCss>
          <FormSectionCss>
            <Checkbox
              id="hasAcceptedTerms"
              name="hasAcceptedTerms"
              checked={formState.hasAcceptedTerms}
              required
              label={
                <>
                  Jeg godtar{' '}
                  <Link to="/brukeravtale" target="_blank" rel="noopener noreferrer">
                    brukervilkårene
                  </Link>
                </>
              }
              onChange={() => updateFormState('hasAcceptedTerms', !formState.hasAcceptedTerms)}
            />
            {errors.hasAcceptedTerms &&
              errors.hasAcceptedTerms.map((error) => <InputErrorMessage label="Brukervilkår" inputErrorMessage={error} />)}
          </FormSectionCss>
          <FormSectionCss>
            <Checkbox
              id="shouldReceiveNewsletter"
              name="shouldReceiveNewsletter"
              checked={formState.shouldReceiveNewsletter}
              label="Ja, send meg nyheter og oppdateringer"
              onChange={() => updateFormState('shouldReceiveNewsletter', !formState.shouldReceiveNewsletter)}
            />
          </FormSectionCss>
          <FormSectionCss>
            <Button type="submit" $variant="primary" $size="sm" $fontConfig={{ weight: 600 }} $rounded>
              {isSubmiting ? <LoadingSpinner /> : 'Send inn'}
            </Button>
          </FormSectionCss>
        </form>
      )}
    </FormWrapperCss>
  );
};

export default ContactUsForm;
