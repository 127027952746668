import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';
import React, { FC } from 'react';
import Svg from 'components/ui/Svg';

const ExternalLink: FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M8.3125 0.65625C8.3125 1.01992 8.60508 1.3125 8.96875 1.3125H11.7605L5.66016 7.41016C5.40312 7.66719 5.40312 8.08281 5.66016 8.33711C5.91719 8.59141 6.33281 8.59414 6.58711 8.33711L12.6848 2.23945V5.03125C12.6848 5.39492 12.9773 5.6875 13.341 5.6875C13.7047 5.6875 13.9973 5.39492 13.9973 5.03125V0.65625C13.9973 0.292578 13.7047 0 13.341 0H8.96875C8.60508 0 8.3125 0.292578 8.3125 0.65625ZM1.96875 0.875C0.880469 0.875 0 1.75547 0 2.84375V12.0312C0 13.1195 0.880469 14 1.96875 14H11.1562C12.2445 14 13.125 13.1195 13.125 12.0312V8.53125C13.125 8.16758 12.8324 7.875 12.4688 7.875C12.1051 7.875 11.8125 8.16758 11.8125 8.53125V12.0312C11.8125 12.3949 11.5199 12.6875 11.1562 12.6875H1.96875C1.60508 12.6875 1.3125 12.3949 1.3125 12.0312V2.84375C1.3125 2.48008 1.60508 2.1875 1.96875 2.1875H5.46875C5.83242 2.1875 6.125 1.89492 6.125 1.53125C6.125 1.16758 5.83242 0.875 5.46875 0.875H1.96875Z"
      fill={color}
    />
  </Svg>
);

ExternalLink.defaultProps = {
  color: COLOR_TOKENS.blue[600],
};

export default ExternalLink;
