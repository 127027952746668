import Button from 'components/ui/Button';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export const ButtonCss = styled(Button)`
  border: 1px solid black;
  border-radius: 0.3125rem;

  ${createMediaQuery(
    'md',
    css`
      margin-left: auto;
    `
  )}
`;

export const DismissibleErrorBannerCss = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fbd5da;
  align-items: center;
  padding: 0.9375rem 1rem;

  ${createMediaQuery(
    'md',
    css`
      flex-direction: row;
    `
  )}
`;

export const HeaderCss = styled.div`
  display: flex;
  margin-bottom: 0.3125rem;

  ${createMediaQuery(
    'md',
    css`
      margin-bottom: 0;
    `
  )}
`;

export const TitleCss = styled.strong`
  margin-right: 0.3125rem;
`;

export const MessageCss = styled.p`
  margin: 0;
`;
