import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Save: React.FC<IconPropTypes> = ({ size, color, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.4979 3.49789L12.5021 0.502107C12.1806 0.180616 11.7446 2.37742e-06 11.2899 0H1.71429C0.7675 0 0 0.7675 0 1.71429V14.2857C0 15.2325 0.7675 16 1.71429 16H14.2857C15.2325 16 16 15.2325 16 14.2857V4.71007C16 4.25542 15.8194 3.81938 15.4979 3.49789ZM8 13.7143C6.73764 13.7143 5.71429 12.6909 5.71429 11.4286C5.71429 10.1662 6.73764 9.14286 8 9.14286C9.26236 9.14286 10.2857 10.1662 10.2857 11.4286C10.2857 12.6909 9.26236 13.7143 8 13.7143ZM11.4286 2.83857V6.42857C11.4286 6.66525 11.2367 6.85714 11 6.85714H2.71429C2.47761 6.85714 2.28571 6.66525 2.28571 6.42857V2.71429C2.28571 2.47761 2.47761 2.28571 2.71429 2.28571H10.8757C10.9894 2.28571 11.0984 2.33086 11.1788 2.41125L11.303 2.53554C11.3428 2.57533 11.3744 2.62257 11.396 2.67456C11.4175 2.72656 11.4286 2.78229 11.4286 2.83857Z"
      fill={color}
    />
  </Svg>
);

Save.defaultProps = {
  color: COLOR_TOKENS.default.white,
  size: 16,
};

export default Save;
