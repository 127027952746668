import * as fieldValidators from './fieldValidators';

export interface Field {
  name: string;
  value: string;
  displayName: string;
  validations: fieldValidators.FieldValidator[];
}

class FieldValidator {
  private name: string;

  private value: string;

  private errors: string[] = [];

  constructor(field: Field) {
    this.name = field.displayName;
    this.value = field.value;
  }

  private handleValidation = (result: string | undefined) => {
    if (result) {
      this.errors = [...this.errors, result];
    }
  };

  public required = (): void => {
    this.handleValidation(fieldValidators.required(this.name, this.value));
  };

  public email = (): void => {
    this.handleValidation(fieldValidators.email(this.value));
  };

  public phone = (): void => {
    this.handleValidation(fieldValidators.phoneNumber(this.value));
  };

  public regExp = (regExp: RegExp): void => {
    this.handleValidation(fieldValidators.regExp(regExp, this.name, this.value));
  };

  public minLength = (length: number): void => {
    this.handleValidation(fieldValidators.minLength(this.name, this.value, length));
  };

  public getErrors = (): string[] => this.errors;
}

export default FieldValidator;
