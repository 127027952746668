import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import Button from '../Button';
import ModalOverlay from './ModalOverlay';

interface ModalProps {
  children: React.ReactNode | React.ReactNode[];
  isOpen?: boolean;
  ariaLabelledbyId: string;
  fullscreen?: boolean;
  className?: string;
}

const ContainerCss = styled.div<{ fullscreen: boolean }>`
  width: 90%;
  height: 90%;

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      width: 100%;
      height: 100%;
    `}

  border-radius: 0.125rem;
  box-shadow: 0 0 0 0.0625rem rgba(16, 22, 26, 0.1), 0 0.25rem 0.5rem rgba(16, 22, 26, 0.2),
    0 1.125rem 2.875rem 0.375rem rgba(16, 22, 26, 0.2);
  display: flex;
  flex-direction: column;
  user-select: text;
  background: ${({ theme }) => theme.colorTokens.default.white};
  overflow: hidden;
`;

const HeaderCss = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 0.375rem 0.375rem 0 0;
  box-shadow: 0 0.0625rem 0 rgba(16, 22, 26, 0.075);
  min-height: 2.5rem;
  padding-left: 0.3125rem;
  margin-bottom: 0.3125rem;

  ${createMediaQuery(
    'md',
    css`
      margin-left: 0.625rem;
    `
  )}
`;
const TitleCss = styled.div`
  flex: 1 1 auto;
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 1.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #29282d;
`;
export const ContentCss = styled.div<{ isScrollable?: boolean }>`
  padding: 0.475rem;
  height: 100%;
  position: relative;
  overflow: hidden;

  ${({ isScrollable }) =>
    isScrollable &&
    css`
      overflow-y: auto;
      overscroll-behavior: contain;
    `}

  ${createMediaQuery(
    'md',
    css`
      margin-left: 0.625rem;
    `
  )}
`;
export const FooterCss = styled.div`
  padding: 0.625rem;
  box-shadow: 0 -0.0625rem 0 rgba(16, 22, 26, 0.075);
`;

export const ModalHeader: React.FC<{
  onClose?: () => void;
  className?: string;
  children: React.ReactNode;
}> = ({ onClose, children, className = '' }) => (
  <HeaderCss className={className}>
    <TitleCss>{children}</TitleCss>
    <Button onClick={onClose} iconLeft={{ name: 'Close' }} ariaLabel="lukk dialog" />
  </HeaderCss>
);

export const ModalContent: React.FC<{
  className?: string;
  hasScrollableContent?: boolean;
  children: React.ReactNode;
}> = ({ className = '', hasScrollableContent, children }) => (
  <ContentCss isScrollable={hasScrollableContent} className={className}>
    {children}
  </ContentCss>
);

export const ModalFooter: React.FC<{ className?: string; children: React.ReactNode }> = ({ className = '', children }) => (
  <FooterCss className={className}>{children}</FooterCss>
);

const ModalPortal: React.FC<ModalProps> = ({ isOpen = false, ariaLabelledbyId, fullscreen, className = '', children }) => {
  const [rootSelector, setRootSelector] = React.useState<HTMLElement | null>(null);
  const [container] = React.useState(document.createElement('div'));
  const { isClient } = useJuridikaConfig();

  if (isClient) {
    React.useLayoutEffect(() => {
      // We set the state here to make sure the DOM has been rendered before it is set to state
      setRootSelector(document.getElementById('modals-portal-container'));
    }, []);
  }

  if (isClient) {
    React.useLayoutEffect(() => {
      if (rootSelector) {
        rootSelector.appendChild(container);
      }

      return () => {
        if (rootSelector) {
          rootSelector.removeChild(container);
        }
      };
    }, [container, rootSelector]);
  }

  return ReactDOM.createPortal(
    <ModalOverlay isOpen={isOpen} ariaLabelledbyId={ariaLabelledbyId} className={className}>
      <ContainerCss fullscreen={!!fullscreen}>{children}</ContainerCss>
    </ModalOverlay>,
    container
  );
};

const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen } = props;
  return isOpen ? <ModalPortal {...props} /> : null;
};

export default Modal;
