import { PdfViewer, PdfPageView } from '../models/pdfTypes';
import { GqlLiteratureEditionSearchResult } from '../../literatureEditionSearchTypes';

export default class PDFCustomFindController {
  private pdfViewer: PdfViewer;

  private pageMatches: Array<Array<number>>;

  private pageMatchesLength: Array<Array<number>>;

  private currentTerm: string;

  private highlightWords: { [word: string]: null };

  private highlightRegExp: RegExp;

  private state: {
    query: string;
    highlightAll: boolean;
  };

  private selected: {
    pageIdx: number;
    matchIdx: number;
  };

  private pageCount: number;

  private active: boolean;

  constructor(pdfViewer: PdfViewer) {
    this.pdfViewer = pdfViewer;
    this.pageMatches = [];
    this.pageMatchesLength = [];
    this.currentTerm = '';
    this.highlightWords = {};
    this.highlightRegExp = PDFCustomFindController.compileHighlightRegExp(Object.keys(this.highlightWords));
    this.state = {
      query: '',
      highlightAll: true,
    };
    this.selected = {
      pageIdx: -1,
      matchIdx: -1,
    };
    this.pageCount = 0;
    this.active = true;
  }

  getPageMatches(): number[][] {
    return this.pageMatches;
  }

  getPageMatchesLength(): number[][] {
    return this.pageMatchesLength;
  }

  applySearchResults(results: GqlLiteratureEditionSearchResult, query: string): void {
    let modified = false;
    let specificSearch = false;
    if (this.currentTerm !== query) {
      this.highlightWords = {};
      modified = true;
    }

    if (query && query.startsWith('"') && query.endsWith('"')) {
      this.highlightWords[query.substring(1, query.length - 1)] = null;
      specificSearch = true;
    }

    this.currentTerm = query;

    if (!specificSearch) {
      for (let i = 0; i < results.edges.length; i += 1) {
        const item = results.edges[i].node;

        for (let j = 0; j < item.highlightedWords.length; j += 1) {
          const word = item.highlightedWords[j];
          if (!(word in this.highlightWords)) {
            modified = true;
            this.highlightWords[word] = null;
          }
        }
      }
    }

    if (modified) {
      // console.log('highlight words: ', this.highlightWords);
      this.highlightRegExp = PDFCustomFindController.compileHighlightRegExp(Object.keys(this.highlightWords));
      const numPages = this.pdfViewer.pagesCount;
      for (let i = 0; i < numPages; i += 1) {
        this.updatePage(i);
      }
    }
  }

  static regexEscape(word: string): string {
    return word.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
  }

  static compileHighlightRegExp(words: Array<string>): RegExp {
    if (words.length) {
      return new RegExp(
        words
          .sort((w) => -w.length)
          .map(PDFCustomFindController.regexEscape)
          .join('|')
      );
    }

    // regexp that matches nothing
    return new RegExp(/a^/);
  }

  collectMatches(pageIndex: number, pageView: PdfPageView): void {
    const texts: Array<string> = pageView.textLayer.textContentItemsStr;

    let charIndex = 0;
    const matches = [];
    const matchesLength = [];

    for (let i = 0; i < texts.length; i += 1) {
      let text = texts[i];

      let rMatch = text.match(this.highlightRegExp);
      while (rMatch) {
        const start = rMatch.index || 0;
        const len = rMatch[0].length;
        const end = start + len;

        matches.push(charIndex + start);
        matchesLength.push(len);

        text = text.substring(end);
        charIndex += end;

        rMatch = text.match(this.highlightRegExp);
      }

      charIndex += text.length;
    }

    this.pageMatches[pageIndex] = matches;
    this.pageMatchesLength[pageIndex] = matchesLength;
  }

  // This method is part of the pdf.js find controller interface
  // eslint-disable-next-line class-methods-use-this
  resolveFirstPage(): void {
    // console.log('CUSTOM: resolveFirstPage pageCount=', this.pdfViewer.pagesCount);
  }

  updatePageLazy(pageIndex: number): void {
    // console.log('CUSTOM: updatePage', pageIndex);
    const pageView = this.pdfViewer.getPageView(pageIndex);
    if (pageView.textLayer) {
      this.collectMatches(pageIndex, pageView);
    }
  }

  // This method is part of the pdf.js find controller interface
  updatePage(pageIndex: number): void {
    // console.log('CUSTOM: updatePage', pageIndex);
    const pageView = this.pdfViewer.getPageView(pageIndex);
    if (pageView.textLayer) {
      this.collectMatches(pageIndex, pageView);
      pageView.textLayer.updateMatches();
    }
  }

  // This method is part of the pdf.js find controller interface
  // eslint-disable-next-line
  updateMatchPosition(pageIndex: number, matchIndex: number, elements: any, beginIdx: any) {
    // console.log('CUSTOM: updateMatchPosition', pageIndex, matchIndex, elements, beginIdx);
    /*
    if (this.selected.matchIdx === matchIndex && this.selected.pageIdx === pageIndex) {
      var spot = {
        top: FIND_SCROLL_OFFSET_TOP,
        left: FIND_SCROLL_OFFSET_LEFT
      };
      (0, _ui_utils.scrollIntoView)(elements[beginIdx], spot, true);
    }
    */
  }
}
