// Actions that can be dispatched by the jwt middleware

import * as TypesafeActions from 'typesafe-actions';
import { Auth0DecodedHash, Auth0Result } from 'auth0-js';

import { TokenSource } from '../session/types';

export interface Tokens {
  jwt: Auth0DecodedHash | Auth0Result;
  tokenSource: TokenSource;
}

export const jwtMiddlewareInitialized = TypesafeActions.createAction('JWT_MIDDLEWARE_INITIALIZED');

export const acquireJwtSuccess = TypesafeActions.createAction(
  'ACQUIRE_JWT_SUCCESS',
  (resolve) => (tokens: Tokens) => resolve(tokens)
);

export const acquireJwtFailure = TypesafeActions.createAction('ACQUIRE_JWT_FAILURE');

export const checkAccessTokenValidity = TypesafeActions.createAction(
  'CHECK_ACCESS_TOKEN_VALIDITY',
  (resolve) => (accessToken: string | undefined) => resolve({ accessToken })
);
