import AngleLeft from 'components/icons/AngleLeft';
import AngleRight from 'components/icons/AngleRight';
import Flex from 'components/ui/Flex';
import ModalOverlay from 'components/ui/Modal/ModalOverlay';
import TextBlock from 'pages/AcademyPage/components/TextBlock/TextBlock';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

const TOP_MARGIN_COURSE_CONTENT_ON_LARGE = 46;
const HEIGHT_OF_TEXTBLOCK_HEADING_ELEMENT = 103;
const DESIRED_MINIMUM_HEIGHT_UNDER_VIDEO = 20;
const LEFT_RIGHT_MARGIN_ON_VIDEO_ELEMENT_REM = 1.88;
const MAX_WIDTH_TEXT_BLOCK_CONTENT_REM = 40;
const LEFT_RIGHT_MARGIN_ON_TEXTBLOCK_ELEMENT_REM = 1.875;
const MIN_WIDTH_VIDEO_SIZE_CONTAINER_REM = MAX_WIDTH_TEXT_BLOCK_CONTENT_REM + LEFT_RIGHT_MARGIN_ON_TEXTBLOCK_ELEMENT_REM;

export const IconLeftCss = styled(AngleLeft)`
  margin-right: 0.6875rem;
`;

export const IconRightCss = styled(AngleRight)`
  margin-left: 0.6875rem;
`;

export const DiplomaWrapperCss = styled.div`
  margin-top: 2rem;

  .pdfViewer .page .canvasWrapper {
    border: none !important;
  }

  .rcs-outer-container {
    border-radius: 0.25rem;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 35%);
  }
`;

export const TextBlockCss = styled(TextBlock)`
  margin-bottom: 2.5rem;
  margin-top: 1.875rem;
`;

export const CurrentCourseViewCss = styled.div<{ $large: boolean }>`
  display: block;

  ${({ $large }) =>
    $large &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `}
`;

export const CurrentCourseContentCss = styled.div<{ $display: boolean; $marginTop: boolean }>`
  max-width: 80rem;
  min-width: 0;
  margin: 0 auto;

  display: ${({ $display }) => ($display ? 'block' : 'none')};
  flex: 1;

  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: 2.875rem;
    `}
`;

export const AdjustVideoHeightToViewportContainerCss = styled.div<{
  $isVideoModule: boolean;
  $isSidebarOpen: boolean;
  $topElementsApproxHeight: number;
}>`
  margin: 0 auto;

  ${function ({ $isVideoModule, $topElementsApproxHeight }) {
    const heightOfElementsOverVideo =
      $topElementsApproxHeight + TOP_MARGIN_COURSE_CONTENT_ON_LARGE + HEIGHT_OF_TEXTBLOCK_HEADING_ELEMENT;

    const heightToLeaveOverAndUnderVideo = heightOfElementsOverVideo + DESIRED_MINIMUM_HEIGHT_UNDER_VIDEO;

    if ($isVideoModule) {
      return css`
        ${createMediaQuery(
          ACADEMY_VIEWPORT_SIZE.medium,
          css`
            width: calc(((100vh - ${heightToLeaveOverAndUnderVideo}px) / 9 * 16) + ${LEFT_RIGHT_MARGIN_ON_VIDEO_ELEMENT_REM}rem);
            max-width: 100%;
            min-width: ${MIN_WIDTH_VIDEO_SIZE_CONTAINER_REM}rem;
          `
        )}
      `;
    }
  }}

  ${({ $isVideoModule, $isSidebarOpen }) =>
    $isVideoModule &&
    $isSidebarOpen &&
    css`
      ${createMediaQuery(
        ACADEMY_VIEWPORT_SIZE.large,
        css`
          min-width: auto;
          width: auto;
        `
      )}
    `}
`;

export const ButtonWrapperLeftCss = styled(Flex)`
  margin-right: auto;

  > button:last-child {
    margin: 0 0.75rem;
  }
`;

export const ButtonWrapperRightCss = styled(Flex)<{ $isOnIntroPage: boolean }>`
  gap: 1rem;

  ${createMediaQuery(
    375,
    css`
      gap: 0.75rem;
    `
  )}

  ${({ $isOnIntroPage }) =>
    $isOnIntroPage &&
    css`
      /* For screens smaller than 375px wide, this container is wrapped to it's own line */
      flex-basis: 100%;

      /* Make the child buttons stretch out and fill width. */
      > button {
        flex-grow: 1;
      }

      ${createMediaQuery(
        375,
        css`
          flex-basis: auto;
        `
      )}
    `}
`;

export const CourseControlsCss = styled(Flex)<{ $isOnIntroPage: boolean }>`
  margin: 0 0.9375rem;

  ${createMediaQuery(
    767,
    css`
      margin: 0;
    `
  )}

  > div:first-child {
    margin-right: auto;
  }

  ${({ $isOnIntroPage }) =>
    $isOnIntroPage &&
    css`
      flex-wrap: wrap;
      row-gap: 1rem;

      ${createMediaQuery(
        375,
        css`
          flex-wrap: nowrap;
        `
      )}
    `}
`;

export const CourseControlsWrapperCss = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[500]};
  padding: 0.9375rem 0;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.medium,
    css`
      margin: 0 0.9375rem;
    `
  )}
`;

export const LoadingSpinnerWrapperCss = styled.div`
  width: 100%;
  text-align: center;
`;

export const ModalOverlayCss = styled(ModalOverlay)`
  background-color: ${({ theme }) => theme.colorTokens.grey[200]};
`;
