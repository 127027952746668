import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const Headphones: React.FC<IconPropTypes> = ({ width, height, color, ...props }) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 4.5625C12.2965 4.5625 5.11875 11.0758 4.06875 19.4922C4.83984 19.1805 5.67656 19 6.5625 19C8.73633 19 10.5 20.7637 10.5 22.9375V33.4375C10.5 35.6113 8.73633 37.375 6.5625 37.375C2.93672 37.375 0 34.4383 0 30.8125V29.5V25.5625V21.625C0 10.0258 9.40078 0.625 21 0.625C32.5992 0.625 42 10.0258 42 21.625V25.5625V29.5V30.8125C42 34.4383 39.0633 37.375 35.4375 37.375C33.2637 37.375 31.5 35.6113 31.5 33.4375V22.9375C31.5 20.7637 33.2637 19 35.4375 19C36.3234 19 37.1602 19.1723 37.9312 19.4922C36.8812 11.0758 29.7035 4.5625 21 4.5625Z"
      fill={color}
    />
  </Svg>
);

Headphones.defaultProps = {
  color: COLOR_TOKENS.purple[400],
  width: 42,
  height: 38,
};

export default Headphones;
