import React from 'react';
import styled from 'styled-components';

import { FormErrors } from 'util/formHelpers';
import { Input } from '../Input/Input';
import { FieldInput } from '../../pages/ContactUsFormPage/utils/formFields';

export interface FieldGroupFormState {
  [key: string]: string;
}

const InputContainerCss = styled.div`
  margin: 1rem 0;
`;

const FieldGroup: React.FC<{
  fields: FieldInput[];
  formState: FieldGroupFormState;
  formErrors: FormErrors;
  resetError(field: string): void;
  updateFormState(fieldName: string, value: string): void;
}> = ({ fields, formState, updateFormState, formErrors, resetError }) => (
  <>
    {fields.map(({ type, displayName, name, ...htmlAttrs }) => {
      const errors = formErrors[name] || [];
      const error = errors[errors.length - 1];

      return (
        <InputContainerCss key={name}>
          <Input
            type={type}
            label={displayName}
            required={htmlAttrs?.required}
            onChange={(e) => {
              if (error) resetError(name);
              updateFormState(name, e.target.value);
            }}
            inputErrorMessage={error}
            value={formState[name]}
            {...htmlAttrs}
          />
        </InputContainerCss>
      );
    })}
  </>
);

export default FieldGroup;
