import { JuridikaError, JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import { CustomErrorMessages } from '../models/CustomErrorMessages';

export enum ErrorClassification {
  BAD_REQUEST,
  USER_UNAUTHENTICATED,
  USER_UNAUTHORIZED,
  RESOURCE_NOT_FOUND,
  API_NOT_WORKING,
}

export const errorClassification = (error: JuridikaError): ErrorClassification => {
  switch (error.type) {
    case JuridikaErrorType.GRAPHQL_BAD_REQUEST:
      return ErrorClassification.BAD_REQUEST;
    case JuridikaErrorType.REST_UNAUTHENTICATED:
    case JuridikaErrorType.GRAPHQL_UNAUTHENTICATED:
      return ErrorClassification.USER_UNAUTHENTICATED;
    case JuridikaErrorType.REST_UNAUTHORIZED:
    case JuridikaErrorType.GRAPHQL_UNAUTHORIZED:
      return ErrorClassification.USER_UNAUTHORIZED;
    case JuridikaErrorType.REST_NOT_FOUND:
    case JuridikaErrorType.GRAPHQL_NOT_FOUND:
    case JuridikaErrorType.ROUTE_NOT_FOUND:
    case JuridikaErrorType.MISSING_PDF_EXCEPTION:
      return ErrorClassification.RESOURCE_NOT_FOUND;
    case JuridikaErrorType.LOGIN:
    case JuridikaErrorType.REST:
    case JuridikaErrorType.GRAPHQL_SERVER:
    case JuridikaErrorType.GRAPHQL_LOADING:
    case JuridikaErrorType.GRAPHQL_NOT_CALLED:
    case JuridikaErrorType.GRAPHQL_UNKNOWN:
    case JuridikaErrorType.APOLLO:
    case JuridikaErrorType.EMAIL_VALIDATION_FAILED:
      return ErrorClassification.API_NOT_WORKING;
  }
};

export const frontendHttpStatusCodeForError = (error: JuridikaError): number => {
  switch (errorClassification(error)) {
    case ErrorClassification.BAD_REQUEST:
      return 400;
    case ErrorClassification.USER_UNAUTHENTICATED:
      return 401;
    case ErrorClassification.USER_UNAUTHORIZED:
      return 403;
    case ErrorClassification.RESOURCE_NOT_FOUND:
      return 404;
    case ErrorClassification.API_NOT_WORKING:
      return 500;
  }
};

export const formatError = (error: JuridikaError, customErrorMessages?: CustomErrorMessages): string => {
  switch (error.type) {
    case JuridikaErrorType.REST_UNAUTHENTICATED:
    case JuridikaErrorType.GRAPHQL_UNAUTHENTICATED:
      return 'Tilgang til innhold krever innlogging';
    case JuridikaErrorType.REST_UNAUTHORIZED:
    case JuridikaErrorType.GRAPHQL_UNAUTHORIZED:
      return 'Ingen tilgang til innhold';
    case JuridikaErrorType.REST_NOT_FOUND:
    case JuridikaErrorType.GRAPHQL_NOT_FOUND: {
      return customErrorMessages?.notFound ?? 'Vi fant ikke innholdet';
    }
    case JuridikaErrorType.LOGIN:
      return 'Feil ved innlogging';
    case JuridikaErrorType.GRAPHQL_BAD_REQUEST:
      return 'Feilaktig forespørsel';
    case JuridikaErrorType.REST:
    case JuridikaErrorType.GRAPHQL_SERVER:
    case JuridikaErrorType.GRAPHQL_LOADING:
    case JuridikaErrorType.GRAPHQL_NOT_CALLED:
    case JuridikaErrorType.GRAPHQL_UNKNOWN:
    case JuridikaErrorType.APOLLO:
      return 'Feil ved henting av innhold';
    case JuridikaErrorType.ROUTE_NOT_FOUND:
      return `Vi fant ikke siden (${error.pathname})`;
    case JuridikaErrorType.EMAIL_VALIDATION_FAILED:
      return 'Validering av e-postadresse feilet';
    case JuridikaErrorType.MISSING_PDF_EXCEPTION:
      return 'Ups, vi finner ikke dokumentet';
  }
};
