import React, { Dispatch, FC, Ref, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import WidthConstraints from 'components/WidthConstraints';
import AcademyVideo from 'pages/AcademyPage/components/AcademyVideo/AcademyVideo';
import TextBlock from 'pages/AcademyPage/components/TextBlock/TextBlock';
import { Chapter, Course, Format, Module } from 'pages/AcademyPage/types';
import { useDispatch } from 'react-redux';
import { setValue } from 'state/ui/uiActions';
import { RootState } from 'state/types';
import { useSelector } from 'util/hooks/useSelector';
import { useHistory, useLocation } from 'react-router';
import { ACADEMY_VIEWPORT_SIZE, convertSrtToJson, getFinalTestPassed, useUserHasAkademiBeta } from 'pages/AcademyPage/utils';
import { useViewportSize } from 'util/hooks/useViewportSize';
import Settings from 'pages/AcademyPage/components/Settings/Settings';
import Transcript from 'pages/AcademyPage/components/Transcript/Transcript';
import {
  DesktopTranscriptToggleButton,
  MobileTranscriptToggleButton,
} from 'pages/AcademyPage/components/Transcript/TranscriptButton/TranscriptToggleButtons';
import { accessTokenSelector, idTokenSelector, isCourseModulesBeforeFinalTestCompletedSelector } from 'state/selectors';
import { CourseContentContext } from 'pages/AcademyPage/utils/academyCourseContentContext';
import AcademyQuiz from 'pages/AcademyPage/components/AcademyQuiz/AcademyQuiz';
import Notice from 'components/ui/Notice';
import { COLORS, COLOR_TOKENS } from 'theme/config/defaultTheme';
import DownloadPDFButton from 'pages/AcademyPage/components/DownloadPDFButton/DownloadPDFButton';
import { LocationStateProps } from 'pages/TextbookReaderPage/pdf/hooks/usePDFReader';
import DocumentPreview from 'pages/TemplatePage/components/DocumentPreview';
import { saveAs } from 'file-saver';
import DoubleArrowsLeft from 'components/icons/DoubleArrowsLeft';
import AngleLeft from 'components/icons/AngleLeft';
import { AcademyIconButton5 } from 'pages/AcademyPage/components/IconButtons/IconButtons';
import AngleRight from 'components/icons/AngleRight';
import { PrimaryButtonPurple, SecondaryButtonPurple } from 'components/AcademyAndNIK/Buttons/ButtonsPurple';
import { DeniedPackageAccess } from 'components/AccessDenied/AccessDenied';
import {
  CourseControlsWrapperCss,
  CourseControlsCss,
  ButtonWrapperLeftCss,
  ButtonWrapperRightCss,
  CurrentCourseContentCss,
  TextBlockCss,
  DiplomaWrapperCss,
  IconRightCss,
  IconLeftCss,
  AdjustVideoHeightToViewportContainerCss,
} from './Styles';

interface CurrentCourseContentProps {
  exitFocusRef: Ref<HTMLButtonElement>;
  setCurrentChapter: Dispatch<SetStateAction<Chapter | undefined>>;
  setAllModules: Dispatch<SetStateAction<Module[] | undefined>>;
  currentChapter: Chapter | undefined;
  currentCourse: Course | undefined;
  setOpenShareCourseModal: Dispatch<SetStateAction<boolean>>;
}

const CurrentCourseContent: FC<CurrentCourseContentProps> = ({
  exitFocusRef,
  setCurrentChapter,
  setAllModules,
  currentChapter,
  setOpenShareCourseModal,
  currentCourse,
}) => {
  const hotspots = useSelector((state: RootState) => state.ui.akademi.hotspots);
  const isSidebarOpen = useSelector((state: RootState) => state.ui.akademi.sidebarIsOpen);
  const isTranscriptEnabledInSettings = useSelector((state: RootState) => state.ui.akademi.showTranscript);

  const courseContent = useContext(CourseContentContext);
  const hasAkademiBeta = useUserHasAkademiBeta();

  const accessToken = useSelector(accessTokenSelector);
  const idToken = useSelector(idTokenSelector);
  const [moduleMetadata, setModuleMetadata] = useState<Module | undefined>();

  const VIEWPORT_SIZES = { ...ACADEMY_VIEWPORT_SIZE, mediumSmall: 575 };

  const dispatch = useDispatch();
  const location = useLocation<LocationStateProps>();
  const history = useHistory();
  const viewportSize = useViewportSize(VIEWPORT_SIZES, 'small');

  const handleShowClickableHotspots = () => dispatch(setValue(!hotspots, ['akademi', 'hotspots']));
  const isCourseModulesBeforeTestCompleted = useSelector((state: RootState) =>
    isCourseModulesBeforeFinalTestCompletedSelector(state, courseContent)
  );

  const moduleFromUrl = location.pathname.split('/').at(-1).toLowerCase();
  const chapterFromUrl = location.pathname.split('/').at(-2).toLowerCase();

  const isVideoModule = moduleMetadata?.format === Format.VIDEO;
  const moduleHasTranscript = typeof moduleMetadata?.media?.transcript === 'string';
  const transcript = useMemo(
    () => (moduleMetadata?.media?.transcript ? convertSrtToJson(JSON.parse(moduleMetadata?.media?.transcript)) : null),
    [moduleMetadata]
  );
  const showTranscriptToggleButtonAndPossiblyTranscript = isTranscriptEnabledInSettings && isVideoModule && moduleHasTranscript;
  const showMobileTranscriptToggleButton = showTranscriptToggleButtonAndPossiblyTranscript && viewportSize !== 'large';
  const showDesktopTranscriptToggleButton = showTranscriptToggleButtonAndPossiblyTranscript && viewportSize === 'large';

  const [isTranscriptToggleButtonActive, setIsTranscriptToggleButtonActive] = useState(true);
  const showTranscript = showTranscriptToggleButtonAndPossiblyTranscript && isTranscriptToggleButtonActive;

  // Set currentModuleId and currentChapterId in redux
  useEffect(() => {
    if (moduleMetadata) {
      dispatch(setValue(moduleMetadata?.id, ['akademi', 'currentModuleId']));
      dispatch(setValue(moduleMetadata?.inChapter, ['akademi', 'currentChapterId']));
      dispatch(setValue(moduleMetadata?.inCourse, ['akademi', 'currentCourseId']));
    }
  }, [moduleMetadata, dispatch]);

  // Set current chapter in parent state
  useEffect(() => {
    if (moduleMetadata) {
      fetch(`/api/academy/chapter/${moduleMetadata.inChapter}`).then((res) =>
        res.json().then((res) => {
          setCurrentChapter(res);
        })
      );
    }
  }, [moduleMetadata, setCurrentChapter]);

  // Set chapter modules in parent state
  const getSlug = useCallback((): string => {
    switch (moduleFromUrl) {
      case 'intro':
        return `intro/intro`;
      default:
        return `${chapterFromUrl}/${moduleFromUrl}`;
    }
  }, [chapterFromUrl, moduleFromUrl]);

  useEffect(() => {
    const slug = getSlug();

    if (currentCourse) {
      fetch(`/api/academy/course/slug/${currentCourse.slug}/${slug}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      }).then((res) => res.json().then((res) => setModuleMetadata(res)));
    }
  }, [accessToken, currentCourse, getSlug]);

  // Set allModules in parent state
  useEffect(() => {
    fetch('/api/academy/modules').then((res) =>
      res.json().then((res) => {
        setAllModules(res);
      })
    );
  }, [setAllModules]);

  // check if user has passed quiz
  useEffect(() => {
    if (currentCourse) {
      getFinalTestPassed(accessToken, currentCourse?.id).then((res) =>
        dispatch(setValue(res, ['akademi', 'isFinalTestCompleted']))
      );
    }
  }, [accessToken, currentCourse, dispatch]);

  const isLastModule = moduleMetadata?.nextModule === null;
  const isOnIntroPage = moduleMetadata?.format === Format.INTRO;
  const isFirstModule = moduleMetadata?.prevModule === null;
  const renderHtml = <div dangerouslySetInnerHTML={{ __html: moduleMetadata?.html || '' }} />;
  const isFinalTestCompleted = useSelector((state: RootState) => state.ui.akademi.isFinalTestCompleted);

  const goToPrevModule = () => {
    if (moduleMetadata && !isFirstModule) {
      history.push(`/akademi/kurs/${currentCourse?.slug}/${moduleMetadata.prevModule}`);
    }
  };

  const goToNextModule = () => {
    if (moduleMetadata && !isLastModule) {
      history.push(`/akademi/kurs/${currentCourse?.slug}/${moduleMetadata.nextModule}`);
    }
  };

  const quiz = useMemo(() => moduleMetadata?.quiz, [moduleMetadata]);
  const handleOnClick = () => dispatch(setValue(true, ['akademi', 'sidebarIsOpen']));

  const pdfUrl = `/api/academy/diploma/${currentCourse?.id}`;
  const onDownloadPdf = () => {
    fetch(pdfUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'id-token': idToken,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, `kursbevis.pdf`);
      });
  };

  const topElementsApproxHeight = useSelector((state: RootState) => state.ui.topElementsApproxHeight);

  return (
    <CurrentCourseContentCss $marginTop={viewportSize === 'large'} $display={viewportSize !== 'large' ? !isSidebarOpen : true}>
      <AdjustVideoHeightToViewportContainerCss
        $isSidebarOpen={isSidebarOpen}
        $isVideoModule={isVideoModule}
        $topElementsApproxHeight={topElementsApproxHeight}
      >
        {isVideoModule && (
          <>
            <WidthConstraints margin="0.9375rem 0.9375rem">
              <TextBlock>
                <p className="subheading over-video">{currentChapter?.title}</p>
                <h1 className="over-video">{moduleMetadata?.title}</h1>
              </TextBlock>
            </WidthConstraints>

            <AcademyVideo
              hotspot={{
                show: hotspots,
                links: moduleMetadata?.hotspotLinks,
              }}
              video={{
                id: moduleMetadata.id || '',
                playlist: {
                  file: moduleMetadata.media?.video || '',
                  image: moduleMetadata.media?.coverimage || '',
                  tracks: [{ file: moduleMetadata.media?.transcriptFile || '', kind: 'captions', label: 'Norwegian' }],
                },
                isLastModule,
              }}
              moduleMetadata={moduleMetadata}
            />
          </>
        )}

        <CourseControlsWrapperCss>
          <CourseControlsCss justify="space-between" $isOnIntroPage={isOnIntroPage}>
            <ButtonWrapperLeftCss>
              {moduleMetadata?.format !== Format.DIPLOMA && (
                <Settings
                  format={moduleMetadata?.format}
                  handleShareCourseLink={() => setOpenShareCourseModal(true)}
                  showClickableHotspots={hotspots}
                  handleClickableHotspots={handleShowClickableHotspots}
                  showTranscriptOption={moduleHasTranscript}
                  isOnTopOfPage={moduleMetadata?.format !== Format.VIDEO}
                />
              )}
              {showDesktopTranscriptToggleButton && (
                <DesktopTranscriptToggleButton
                  isInOpenState={isTranscriptToggleButtonActive}
                  onClick={() => setIsTranscriptToggleButtonActive((value) => !value)}
                  ref={exitFocusRef}
                />
              )}
            </ButtonWrapperLeftCss>

            <ButtonWrapperRightCss $isOnIntroPage={isOnIntroPage}>
              {isOnIntroPage ? (
                <>
                  {hasAkademiBeta && (
                    <SecondaryButtonPurple
                      iconRight={<IconRightCss />}
                      label="Start kurs"
                      onClick={goToNextModule}
                      ref={viewportSize !== 'large' && isFirstModule ? exitFocusRef : null}
                    />
                  )}
                </>
              ) : (
                <>
                  {!isFirstModule &&
                    (viewportSize === 'small' ? (
                      <AcademyIconButton5 onClick={goToPrevModule} icon={<AngleLeft size={16} />} ref={exitFocusRef} />
                    ) : (
                      <SecondaryButtonPurple
                        onClick={goToPrevModule}
                        ref={viewportSize !== 'large' ? exitFocusRef : null}
                        iconLeft={<IconLeftCss />}
                        label="Forrige"
                      />
                    ))}
                  {!isLastModule &&
                    (viewportSize === 'small' ? (
                      <AcademyIconButton5
                        onClick={goToNextModule}
                        icon={<AngleRight size={16} />}
                        ref={isFirstModule ? exitFocusRef : null}
                      />
                    ) : (
                      <SecondaryButtonPurple
                        iconRight={<IconRightCss />}
                        label="Neste"
                        onClick={goToNextModule}
                        ref={viewportSize !== 'large' && isFirstModule ? exitFocusRef : null}
                      />
                    ))}
                </>
              )}
              {viewportSize !== 'large' && (
                <PrimaryButtonPurple
                  onClick={handleOnClick}
                  label="Kursdeler"
                  iconRight={<DoubleArrowsLeft color={COLOR_TOKENS.default.white} ml2 />}
                />
              )}
            </ButtonWrapperRightCss>
          </CourseControlsCss>
        </CourseControlsWrapperCss>

        {showMobileTranscriptToggleButton && (
          <MobileTranscriptToggleButton
            isInOpenState={isTranscriptToggleButtonActive}
            onClick={() => setIsTranscriptToggleButtonActive((prev) => !prev)}
          />
        )}
        {showTranscript && transcript && <Transcript transcript={transcript} />}
      </AdjustVideoHeightToViewportContainerCss>

      <WidthConstraints margin="0.9375rem 0.9375rem">
        {moduleMetadata?.format === Format.DIPLOMA ? (
          <TextBlockCss>
            <p className="subheading">{currentCourse?.title}</p>
            <h1>{moduleMetadata?.title}</h1>
            {isFinalTestCompleted ? (
              <>
                {moduleMetadata?.desc && <p className="preamble">{moduleMetadata?.desc}</p>}
                {renderHtml}
                <DownloadPDFButton onClick={onDownloadPdf} text="last ned kursbevis" />
                <DiplomaWrapperCss>
                  <DocumentPreview documentUrl={pdfUrl} accessToken={accessToken} idToken={idToken} />
                </DiplomaWrapperCss>
              </>
            ) : (
              <Notice
                mb2
                danger
                small
                align="center"
                borderColor={COLORS.danger}
                content="Du må fullføre kurset for å få tilgang til kursbevis."
              />
            )}
          </TextBlockCss>
        ) : (
          <TextBlockCss>
            {moduleMetadata?.format !== Format.VIDEO && (
              <>
                <p className="subheading">{currentCourse?.title}</p>
                <h1 className={moduleMetadata?.format === Format.QUIZ ? 'quiz' : ''}>{moduleMetadata?.title}</h1>
              </>
            )}
            {moduleMetadata?.desc && <p className="preamble">{moduleMetadata?.desc}</p>}
            {renderHtml}

            {quiz &&
              currentCourse &&
              (isCourseModulesBeforeTestCompleted ? (
                <AcademyQuiz quiz={quiz} courseId={currentCourse?.id} />
              ) : (
                <Notice
                  mb2
                  danger
                  small
                  align="center"
                  borderColor={COLORS.danger}
                  content="Du må fullføre kursinnholdet for å få tilgang til quiz."
                />
              ))}
          </TextBlockCss>
        )}
        {isOnIntroPage && !hasAkademiBeta && <DeniedPackageAccess />}
      </WidthConstraints>
    </CurrentCourseContentCss>
  );
};

export default CurrentCourseContent;
