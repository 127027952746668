import * as React from 'react';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const DoubleArrowsLeft: React.FC<IconPropTypes> = ({ color, title, ...props }) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.64214 8.63827L14.4115 15.7153C14.7619 16.083 15.3461 16.0965 15.715 15.7442C15.905 15.5626 16 15.3199 16 15.0772C16 14.8477 15.9147 14.618 15.744 14.4392L9.58449 7.9998L15.7423 1.56121C16.0933 1.1922 16.0825 0.608113 15.7135 0.255798C15.3444 -0.096363 14.7604 -0.0831698 14.408 0.284645L7.63868 7.36171C7.29982 7.71903 7.29982 8.28058 7.64214 8.63827ZM0.257376 8.63828L7.02674 15.7153C7.37675 16.083 7.96138 16.0965 8.33062 15.7442C8.51908 15.5626 8.61524 15.3199 8.61524 15.0772C8.61524 14.8477 8.52989 14.618 8.35923 14.4392L2.19972 7.9998L8.35754 1.56121C8.70851 1.1922 8.6977 0.608114 8.32869 0.255799C7.95968 -0.0963624 7.37559 -0.0831692 7.02328 0.284646L0.253913 7.36171C-0.0849407 7.71903 -0.0849387 8.28058 0.257376 8.63828Z"
      fill={color}
    />
  </Svg>
);

DoubleArrowsLeft.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default DoubleArrowsLeft;
