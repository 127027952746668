import React, { FC } from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const Login: FC<IconPropTypes> = ({ width, height, color, ...props }) => (
  <Svg
    className="c-icon c-icon__login"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 20"
    {...props}
  >
    <g fill={color} fillRule="nonzero">
      <path d="M16.274.196h-7.28v1.936h5.344v15.736H8.993v1.935h7.28z" />
      <path d="M5.761 13.78l1.42 1.317L11.906 10 7.181 4.903 5.761 6.22l2.61 2.812H.178v1.936H8.37z" />
    </g>
  </Svg>
);

export default Login;

Login.defaultProps = {
  color: '#e00e37',
  width: 17,
  height: 20,
};
