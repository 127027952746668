import React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const Refresh: React.FC<IconPropTypes> = ({ color, size, width, height, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={width || size} height={height || size} viewBox="0 0 24 20" {...props}>
    <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M23 2v6h-6M1 18v-6h6" />
      <path d="M3.51 7a9 9 0 0 1 14.85-3.36L23 8M1 12l4.64 4.36A9 9 0 0 0 20.49 13" />
    </g>
  </Svg>
);

Refresh.defaultProps = {
  width: 24,
  height: 20,
  color: '#e00e37',
};

export default Refresh;
