import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';

const FRONT_PAGE_MAX_WIDTH = '1200px';

export enum AkademiFrontPageSectionBackgroundColor {
  WHITE = 'WHITE',
  PURPLE_VIBRANT = 'PURPLE_VIBRANT',
  BROWN = 'BROWN',
}

export const AcademyFrontPageSectionCss = styled.section<{
  backgroundColor?: AkademiFrontPageSectionBackgroundColor;
}>`
  ${({ backgroundColor = AkademiFrontPageSectionBackgroundColor.WHITE }) => {
    switch (backgroundColor) {
      case AkademiFrontPageSectionBackgroundColor.WHITE:
        return css`
          h1,
          h2,
          h3 {
            color: ${({ theme }) => theme.academy.frontPage.colors.primary};
          }
        `;
      case AkademiFrontPageSectionBackgroundColor.PURPLE_VIBRANT:
        return css`
          background-color: ${({ theme }) => theme.academy.frontPage.colors.primary};
          color: ${({ theme }) => theme.colors.white};
        `;
      case AkademiFrontPageSectionBackgroundColor.BROWN:
        return css`
          background-color: ${({ theme }) => theme.academy.frontPage.colors.backgroundBrown};

          h1,
          h2,
          h3 {
            color: ${({ theme }) => theme.academy.frontPage.colors.primary};
          }
        `;
    }
  }}
`;

export const AcademyFrontPageSectionContentCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  max-width: ${FRONT_PAGE_MAX_WIDTH};
  padding-top: 35px;
  padding-bottom: 45px;
  padding-left: 15px;
  padding-right: 15px;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.medium,
    css`
      padding-top: 40px;
      padding-bottom: 50px;
    `
  )}

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.large,
    css`
      padding-left: 30px;
      padding-right: 30px;
    `
  )}
`;

export const AcademyFrontPageSectionContentTwoColumnsCss = styled(AcademyFrontPageSectionContentCss)`
  gap: 30px;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.large,
    css`
      flex-direction: row;
      align-items: flex-start;

      > * {
        flex: 1 1 0;
      }
    `
  )}
`;
