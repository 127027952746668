import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import createMediaQuery from 'util/createMediaQuery';
import { ButtonLinkVibrantCss } from 'components/AcademyAndNIK/Buttons/ButtonsVibrantPurple';
import { useIsUserLoggedInAndHasAkademi } from 'pages/AcademyPage/hooks/useIsUserLoggedInAndHasAkademi';
import useRouteMatchUrlWithoutSlash from 'pages/AcademyPage/hooks/useRouteMatchUrlWithoutSlash';
import { academyButtonGoToCourseButtonArrowRightIconSvg } from './FrontPageSvgs';

const HAS_ACCESS_BUTTON_LABEL = 'Start kurs';
const GET_ACCESS_BUTTON_LABEL = 'Få tilgang til kurs';

interface Props {
  isPrimary?: boolean;
}

const GetAccessOrStartCourseButton: FC<Props> = ({ isPrimary }) => {
  const isUserAuthenticatedAndHasAkademi = useIsUserLoggedInAndHasAkademi();
  const url = useRouteMatchUrlWithoutSlash();

  const buttonLabel = isUserAuthenticatedAndHasAkademi ? HAS_ACCESS_BUTTON_LABEL : GET_ACCESS_BUTTON_LABEL;
  const buttonLinkLocation = isUserAuthenticatedAndHasAkademi
    ? `${url}/kurs/krasjkurs-i-sakforsel-for-prosessfullmektiger`
    : '/prøv-akademi';

  return (
    <GetAccessOrStartCourseButtonLinkCss to={buttonLinkLocation} $isPrimary={isPrimary}>
      <span>{buttonLabel}</span>
      {academyButtonGoToCourseButtonArrowRightIconSvg}
    </GetAccessOrStartCourseButtonLinkCss>
  );
};

export default GetAccessOrStartCourseButton;

const GetAccessOrStartCourseButtonLinkCss = styled(ButtonLinkVibrantCss)`
  display: inline-flex;
  gap: 8px;

  width: 100%;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.mediumSmall,
    css`
      width: auto;
    `
  )}
`;
