import React, { FC } from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import Svg from 'components/ui/Svg';
import IconPropTypes from './IconPropTypes';

const MagnifyingGlass: FC<IconPropTypes> = ({ color, width, height, ...props }) => (
  <Svg
    className="c-icon icon-search"
    width={width}
    height={height}
    viewBox={`0 0 ${height} ${width}`}
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    {...props}
  >
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g>
        <path d="M12.4317707,10.821616 L16.006073,14.3819427 L14.3808289,16.0038452 L10.8097877,12.4401833 C10.8086012,12.4410253 10.8074144,12.4418669 10.8062273,12.4427081 C9.68883009,13.234582 8.32376707,13.6999998 6.8499999,13.6999998 C3.06684942,13.6999998 0,10.6331504 0,6.8499999 C0,3.06684942 3.06684942,0 6.8499999,0 C10.6331504,0 13.6999998,3.06684942 13.6999998,6.8499999 C13.6999998,8.32951715 13.2309432,9.69948034 12.4334244,10.819295 C12.4328733,10.8200688 12.4323221,10.8208425 12.4317707,10.821616 Z M6.8499999,11.4499998 C9.3905097,11.4499998 11.4499998,9.3905097 11.4499998,6.8499999 C11.4499998,4.30949011 9.3905097,2.25 6.8499999,2.25 C4.30949011,2.25 2.25,4.30949011 2.25,6.8499999 C2.25,9.3905097 4.30949011,11.4499998 6.8499999,11.4499998 Z"></path>
      </g>
    </g>
  </Svg>
);

MagnifyingGlass.defaultProps = {
  color: COLOR_TOKENS.default.black,
  width: 16,
  height: 16,
};

export default MagnifyingGlass;
