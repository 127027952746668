const scrollToElement = (id: string, offset: number, scrollOptions?: ScrollToOptions) => {
  if (window) {
    const element = document.getElementById(id);
    window.history.pushState(null, '', `#${id}`);
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element?.getBoundingClientRect().top || 0;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      ...scrollOptions,
      top: offsetPosition,
    });
  }
};

export default scrollToElement;
