import React, { FC, ReactNode, MouseEvent } from 'react';
import { useLocation } from 'react-router';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { LinkCss, OverlayCss, WrapperCss } from './Styles';

interface ClickableHotspotProps {
  show: boolean;
  children?: ReactNode;
  link: string;
  label: string;
}

const ClickableHotspot: FC<ClickableHotspotProps> = ({ show, children, link, label }) => {
  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');
  const location = useLocation();

  const handlePersistStoreOnRedirect = (event: MouseEvent): void => {
    /*
     * store returnToCourseBanner in localStorage so
     * that the store persists through page refresh
     * caused by following anchor-tags
     */
    // Do not persist store if user is holding down ctrl, shift or meta key (opening link in new tab)
    if (event.ctrlKey || event.shiftKey || event.metaKey || (event.button && event.button === 1)) {
      return;
    }

    localStorage.setItem('returnToCourseBanner', JSON.stringify({ show: true, path: location.pathname }));
  };

  return (
    <OverlayCss $large={viewportSize !== 'small'}>
      <WrapperCss $show={show}>
        <LinkCss href={link} onClick={handlePersistStoreOnRedirect}>
          {label}
        </LinkCss>
      </WrapperCss>
      {children}
    </OverlayCss>
  );
};

export default ClickableHotspot;
