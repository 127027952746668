import { loadableReady } from '@loadable/component';
import { ErrorContextHydratedProvider } from 'components/error/ErrorContextHydratedProvider';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import BackToCourseBanner from 'pages/AcademyPage/components/BackToCourseBanner/BackToCourseBanner';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { Routes } from 'routes/routes';
import { ScriptWrapper } from 'state/contexts/ScriptsContext/ScriptsWrapper';
import { createClientReduxStore, runPersistStore } from 'state/store';
import ThemeWrapper from 'theme/ThemeWrapper';
import { createApolloClient } from 'util/apolloClient';
import { Auth0, Auth0Context } from 'util/auth0';
import { loadPreloadedState } from 'util/reduxUtils';
import { JuridikaConfig, JuridikaConfigContext } from 'commonUtils/juridikaConfig';
import * as ReactRedux from 'react-redux';
import { reloadPageWhenBundleChanges } from 'util/pageReload';
import { setHasBeenHydrated } from 'state/viewport/viewportActions';
import JuridikaLivechatWidget from 'components/JuridikaLivechatWidget/JuridikaLivechatWidget';
import { installJuridikaDevOptions } from './juridikaDevOptions';

const JURIDIKA_CONFIG = JuridikaConfig.realClient();

require('../images/favicon.png');
// Avoid code-splitting the following css file:
// Code-splitting css does not yet work.
require('pdfjs-dist/web/pdf_viewer.css');
// Required for PackageCard default-scrolling:
require('simplebar-react/dist/simplebar.min.css');
// See -> components/VideoJS
require('video.js/dist/video-js.css');

reloadPageWhenBundleChanges(JURIDIKA_CONFIG);

loadableReady(() => {
  const history = createBrowserHistory();
  const auth0 = Auth0.real(JURIDIKA_CONFIG);
  const preloadedState = loadPreloadedState();

  const store = createClientReduxStore(JURIDIKA_CONFIG, auth0, history, preloadedState);
  const apolloClient = createApolloClient(JURIDIKA_CONFIG, store);
  installJuridikaDevOptions(store);

  const domNode = document.querySelector('#app-container') as Element;

  const reactNode = (
    <ErrorContextHydratedProvider>
      <JuridikaConfigContext.Provider value={JURIDIKA_CONFIG}>
        <Auth0Context.Provider value={auth0}>
          <ReactRedux.Provider store={store}>
            <ApolloProvider client={apolloClient}>
              <HelmetProvider>
                <ScriptWrapper>
                  <ConnectedRouter history={history}>
                    <ThemeWrapper>
                      <Routes />
                      <BackToCourseBanner />
                      <JuridikaLivechatWidget />
                    </ThemeWrapper>
                  </ConnectedRouter>
                </ScriptWrapper>
              </HelmetProvider>
            </ApolloProvider>
          </ReactRedux.Provider>
        </Auth0Context.Provider>
      </JuridikaConfigContext.Provider>
    </ErrorContextHydratedProvider>
  );

  if (module.hot) {
    createRoot(domNode).render(reactNode);
  } else hydrateRoot(domNode, reactNode);

  store.dispatch(setHasBeenHydrated());

  // Persisting store here to prevent race-condition
  runPersistStore(store);
});
