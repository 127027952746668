import * as React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { createLogger } from 'commonUtils/log';
import { JuridikaError } from 'commonUtils/models/JuridikaError';
import { PartialSearchQuery } from 'util/searchQuery';
import { useLazyApolloQuery } from 'util/hooks/useApolloQuery';
import {
  clearSuggestionHistory,
  combineSuggestions,
  fetchSuggestionHistory,
  parseSearchHistoryMetaText,
  processGraphqlSuggestions,
  SEARCH_SUGGESTIONS_QUERY,
  storeSearchQuery,
  storeSuggestion,
} from 'util/searchSuggestionHelpers';
import * as GqlResult from 'util/graphql/GqlResult';
import { concatArrays } from 'util/arrayUtils';
import MagnifyingGlass from 'icons/MagnifyingGlass';
import { ColorTokenValues } from 'theme/config/types';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { useDebounce } from 'util/hooks/useDebounce';
import Input from '../Input/Input';
import SearchSuggestionDropdown, {
  combinedCategories,
  SearchHistorySuggestionElement,
  Suggestion,
  SuggestionElement,
} from './SearchSuggestionDropdown';
import {
  GqlSearchSuggestions,
  GqlSearchSuggestionsResponse,
  GqlSearchSuggestionVariables,
  placeholderForSuggestSearch,
} from '../../models/graphql/SearchSuggestions';
import SearchTips from '../../pages/SearchPage/components/SearchTips';

const log = createLogger('Searchbar');

export interface SearchBarProps {
  onSubmit: (query: PartialSearchQuery) => void;
  onClose?: () => void;
  onChange?: (query: PartialSearchQuery) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  inputClassModifiers?: {
    hasColoredMagnifyingGlass: boolean;
    isLarge: boolean;
  };
  placeholder?: string;
  query?: PartialSearchQuery;
  enableSuggestion?: boolean;
  autoFocus?: boolean;
  hideSuggestionHistory?: boolean;
  hideSuggestionCategoryHeaders?: boolean;
  displayedSuggestionCategories?: string[];
  darkBackground?: boolean;
  iconButtonSvgConfig?: {
    width?: number;
    height?: number;
    fillColor?: ColorTokenValues;
  };
  paddingConfig?: {
    topBottom?: number;
    leftRight?: number;
  };
  borderColorVariant?: 'primary' | 'secondary' | 'tertiary';
  isLargeInputField?: boolean;
  showTips?: boolean;
  className?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSubmit,
  onClose = () => {},
  onChange,
  onKeyDown = () => {},
  onBlur = () => {},
  placeholder = 'Søk i hele Juridika',
  query = { term: '' },
  enableSuggestion = true,
  autoFocus = false,
  hideSuggestionHistory = false,
  hideSuggestionCategoryHeaders = false,
  displayedSuggestionCategories,
  darkBackground,
  iconButtonSvgConfig,
  paddingConfig,
  borderColorVariant,
  isLargeInputField,
  showTips = false,
  className = '',
}) => {
  const [storedQuery, setStoredQuery] = React.useState(query);
  const [inputFieldFocused, setInputFieldFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [suggestions, setSuggestions] = React.useState<Suggestion[]>([]);
  const [error, setError] = React.useState<JuridikaError | null>(null);
  const [suggestionsCount, setSuggestionCount] = React.useState<number | null>(null);
  const [searchHistory, setSearchHistory] = React.useState<SearchHistorySuggestionElement[]>([]);
  const [hovered, setHovered] = React.useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState(-1);
  const hoverSuggestionDisabled = React.useRef(false);

  const juridikaConfig = useJuridikaConfig();
  const dispatch = useDispatch();

  const [execute, results] = useLazyApolloQuery<GqlSearchSuggestions, GqlSearchSuggestionVariables>(SEARCH_SUGGESTIONS_QUERY);

  const delayedExecuteQuery = React.useRef(
    useDebounce((searchQuery: string) => {
      if (enableSuggestion && searchQuery !== '') {
        execute({
          variables: {
            query: searchQuery,
            limit: 5,
          },
        });
      }
    }, juridikaConfig.searchTypeDelay)
  ).current;

  React.useEffect(() => {
    return () => {
      delayedExecuteQuery.cancel();
    };
  }, [delayedExecuteQuery]);

  React.useEffect(() => {
    delayedExecuteQuery(storedQuery.term);
  }, [storedQuery.term, inputFieldFocused, execute, delayedExecuteQuery]);

  React.useEffect(() => {
    GqlResult.of(results)
      .flatMapOk<GqlSearchSuggestionsResponse>((data) => {
        if (!data || !data.searchSuggestions) {
          return GqlResult.unknownError();
        }
        if (data.searchSuggestions.error) {
          return GqlResult.gqlError(data.searchSuggestions.error);
        }
        return GqlResult.ok(data.searchSuggestions);
      })
      .mapFuture((future) => {
        const response = placeholderForSuggestSearch(future);

        const processedSuggestions = response.results
          ? processGraphqlSuggestions(response.results, displayedSuggestionCategories)
          : [];

        const combinedSuggestions = combineSuggestions(processedSuggestions, combinedCategories, 5);
        const updatedSuggestionsCount = combinedSuggestions
          .map((suggestion) => suggestion.list.length)
          .reduce((a, b) => a + b, 0);
        setSuggestions(combinedSuggestions);
        setSuggestionCount(updatedSuggestionsCount);
        setActiveSuggestionIndex(-1);
        setError(null);
      })
      .getOrElseGet((notOk) => {
        log.warn({ msg: 'Error fetching suggest results.', error: GqlResult.notOkToJuridikaError(notOk) });
        setError(GqlResult.notOkToJuridikaError(notOk));
      });
  }, [results, displayedSuggestionCategories]);

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (enableSuggestion) {
      switch (event.key) {
        case 'Enter':
          if (activeSuggestionIndex > -1) {
            event.preventDefault();
            const flatSuggestions =
              storedQuery.term.length > 0
                ? suggestions.map((suggestion) => suggestion.list).reduce(concatArrays, [])
                : searchHistory;

            const selectedSuggestion = flatSuggestions[activeSuggestionIndex];
            handleSuggestionSelect({
              ...selectedSuggestion,
              metaText: parseSearchHistoryMetaText(selectedSuggestion.metaText),
            });
          } else if (storedQuery.term.length > 0) {
            storeSearchQuery(storedQuery.term);
          }
          break;
        case 'ArrowUp':
          hoverSuggestionDisabled.current = true;
          if (activeSuggestionIndex > -1) {
            setActiveSuggestionIndex(activeSuggestionIndex - 1);
          }
          break;
        case 'ArrowDown':
          {
            hoverSuggestionDisabled.current = true;
            const maxIndex = storedQuery.term.length && suggestionsCount ? suggestionsCount : searchHistory.length;
            if (activeSuggestionIndex < maxIndex - 1) {
              setActiveSuggestionIndex(activeSuggestionIndex + 1);
            }
          }
          break;
        default:
          break;
      }
    }
    onKeyDown(event);
  };

  React.useEffect(() => {
    if (storedQuery.term.length === 0) {
      const currentSearchHistory = fetchSuggestionHistory();
      if (JSON.stringify(currentSearchHistory) !== JSON.stringify(searchHistory)) {
        setSearchHistory(currentSearchHistory);
      }
    }
  }, [storedQuery.term, searchHistory]);

  const handleQueryChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = {
      ...query,
      term: ev.target.value,
    };
    setStoredQuery(newQuery);
    if (onChange) {
      onChange(newQuery);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (storedQuery.term) {
      onSubmit(storedQuery);
      if (inputRef.current && !autoFocus) {
        inputRef.current.blur();
      }
    }
    setInputFieldFocused(false);
  };

  const handleSuggestionSelect = (suggestion: SuggestionElement) => {
    const updatedSearchHistory = storeSuggestion(suggestion);
    setSearchHistory(updatedSearchHistory);
    setHovered(false);
    setActiveSuggestionIndex(-1);
    dispatch(push(suggestion.documentUrl));
    onClose();
  };

  const onHoverSuggestion = (index: number, isHovered: boolean) => {
    if (!hoverSuggestionDisabled.current) {
      setActiveSuggestionIndex(isHovered ? index : -1);
    }
  };

  const onMouseMove = () => {
    hoverSuggestionDisabled.current = false;
  };

  const clearHistory = () => {
    clearSuggestionHistory();
    setSearchHistory([]);
    setHovered(false);
  };

  React.useEffect(() => {
    setStoredQuery(query);
  }, [query.term]);

  const showHistory = searchHistory.length > 0 && storedQuery.term.length === 0 && !hideSuggestionHistory;
  const showSuggestions = storedQuery.term.length > 0 && suggestions.length > 0 && !error;
  const renderDropDown = (hovered || inputFieldFocused) && (showHistory || showSuggestions);

  return (
    <>
      {showTips && <SearchTips />}
      <form role="search" onSubmit={handleSubmit} className={className}>
        <Input
          ref={inputRef}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          placeholder={placeholder}
          aria-label={placeholder}
          value={storedQuery.term}
          onChange={handleQueryChanged}
          onFocus={() => setInputFieldFocused(true)}
          onBlur={(e) => {
            setInputFieldFocused(false);
            onBlur(e);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => onInputKeyDown(event)}
          rightIcon={MagnifyingGlass}
          darkBackground={darkBackground}
          paddingConfig={paddingConfig}
          iconButtonSvgConfig={iconButtonSvgConfig}
          iconButtonHandler={onSubmit}
          borderColorVariant={borderColorVariant}
          isLargeInputField={isLargeInputField}
        />
        {enableSuggestion && renderDropDown && (
          <SearchSuggestionDropdown
            hideSuggestionCategoryHeaders={hideSuggestionCategoryHeaders}
            onSuggestionSelect={handleSuggestionSelect}
            suggestions={suggestions}
            searchHistory={searchHistory}
            showHistory={showHistory}
            showSuggestions={showSuggestions}
            activeSuggestionIndex={activeSuggestionIndex}
            onHoverSuggestion={onHoverSuggestion}
            clearHistory={clearHistory}
            setHovered={setHovered}
            onMouseMove={onMouseMove}
          />
        )}
      </form>
    </>
  );
};

export default SearchBar;
