import * as React from 'react';
import styled from 'styled-components';
import ContactUsButtons from '../../../components/ContactUsButtons';

const FormSubmitionErrorMessageCss = styled.div`
  margin: 0 auto 0.625rem;
  padding: 1.25rem;
  background: ${(props) => props.theme.colorTokens.default.white};
  border: solid 0.125rem ${(props) => props.theme.colorTokens.red[600]};
  color: ${(props) => props.theme.colorTokens.grey[900]};
  text-align: center;

  h3 {
    margin: 0 0 1rem;
  }
`;

const FormSubmitionErrorMessage: React.FC = () => (
  <FormSubmitionErrorMessageCss>
    <h3>Noe gikk galt ved innsendingen av skjemaet</h3>
    <p>Prøv igjen senere eller kontakt kundeservice.</p>
    <ContactUsButtons subject="Feil ved innsending av kontaktskjema" />
  </FormSubmitionErrorMessageCss>
);

export default FormSubmitionErrorMessage;
