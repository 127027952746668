import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { buttonWithoutStyle, listWithoutStyle } from 'styleMixins/listMixins';
import createMediaQuery from 'util/createMediaQuery';
import { NAVBAR_VIEWPORT_SIZE, NavigationType } from '../../../../utils';

export const CategoryPageNavigationCss = styled.nav<{ navType: NavigationType }>`
  margin-right: auto;
  background: inherit;
  ${({ navType }) =>
    navType === NavigationType.mobileMenu &&
    css`
      padding: 1.5625rem 0;
    `}

  ${({ navType }) =>
    navType === NavigationType.desktopNavbar &&
    css`
      margin-right: auto;
      background: inherit;
    `}
`;

export const NavLinksCss = styled.ul<{ $navType: NavigationType }>`
  ${listWithoutStyle}

  ${({ $navType }) =>
    $navType === NavigationType.mobileMenu &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;
    `}

  ${({ $navType }) =>
    $navType === NavigationType.desktopNavbar &&
    css`
      display: flex;
      align-items: baseline;
    `}
`;

export const NavLinkListItemCss = styled.li`
  ${createMediaQuery(
    NAVBAR_VIEWPORT_SIZE.medium,
    css`
      background: inherit;
      margin: 0 1.25rem 0 0;
    `
  )}
`;

export const NavLinkCss = styled(NavLink)<{ $navType: NavigationType }>`
  border-radius: 0;

  ${({ $navType, theme }) =>
    $navType === NavigationType.mobileMenu &&
    css`
      display: inline-flex;
      padding: 0.625rem;
      border-bottom: 1px solid transparent;

      color: ${theme.colorTokens.default.white};
      font-size: 1.125rem;
      font-weight: 600;
      letter-spacing: 0.0313rem;
      text-decoration: none;

      :focus-visible {
        box-shadow: 0 0 1px 1px ${theme.colorTokens.default.white};
        border: none;
        border-bottom: 1px solid transparent;
      }

      :hover {
        border-bottom: 1px solid ${theme.colorTokens.default.white};
      }

      &.isActive {
        border-bottom: solid ${theme.colorTokens.default.white} 3px;
        padding-bottom: 0.8125rem;

        :hover {
          border-bottom: solid ${theme.colorTokens.default.white} 3px;
        }

        :focus-visible {
          border-bottom: solid ${theme.colorTokens.default.white} 3px;
        }
      }
    `}

  ${({ $navType, theme }) =>
    $navType === NavigationType.desktopNavbar &&
    css`
      font-size: 1.125rem;
      font-weight: 500;
      color: ${theme.colorTokens.purple[800]};
      text-decoration: none;
      border-bottom: 5px solid transparent;
      padding-bottom: 0.9375rem;

      &.isActive {
        border-bottom: 5px solid ${theme.colorTokens.red[600]};

        :focus-visible {
          padding-bottom: 0.875rem;
          margin: 0;
          border-bottom: 6px solid ${theme.colorTokens.red[600]};
        }
      }

      :hover {
        color: ${theme.colorTokens.red[600]};
      }

      :focus-visible {
        border: none;
        padding-bottom: 0;
        margin-bottom: 1rem;
        border-bottom: 2px solid ${theme.colorTokens.red[600]};
      }
    `}

  :hover {
    opacity: 1;
  }
`;

export const MoreButtonCss = styled.button<{ $isActive: boolean }>`
  ${buttonWithoutStyle}
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colorTokens.purple[800]};
  border-bottom: 5px solid transparent;
  display: inline-flex;
  padding-bottom: 0.9375rem;

  :hover {
    color: ${({ theme }) => theme.colorTokens.red[600]};
    opacity: 1;
    svg * {
      fill: ${({ theme }) => theme.colorTokens.red[600]};
    }
  }

  :focus-visible {
    border: none;
    padding-bottom: 0;
    margin-bottom: 1rem;
    border-bottom: 2px solid ${({ theme }) => theme.colorTokens.red[600]};
  }

  svg {
    align-self: center;
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      border-bottom: 5px solid ${theme.colorTokens.red[600]};

      :focus-visible {
        padding-bottom: 0.875rem;
        margin: 0;
        border-bottom: 6px solid ${theme.colorTokens.red[600]};
      }
    `}
`;

export const MoreButtonListItemCss = styled.li`
  // bruk en fiksert høyde for å kunne plassere stilene for aktivt-menypunkt på knapp
  height: 1.375rem;
`;
