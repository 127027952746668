import React, { FC } from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const Avatar: FC<IconPropTypes> = ({ ...props }) => (
  <Svg width={16} height={16} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C5.247 0 3 2.247 3 5s2.247 5 5 5c2.752 0 5-2.247 5-5s-2.248-5-5-5zM2.25 12A2.261 2.261 0 0 0 0 14.25v.6c0 1.47.932 2.789 2.354 3.696C3.777 19.453 5.722 20 8 20s4.223-.547 5.646-1.454C15.068 17.639 16 16.32 16 14.85v-.6A2.261 2.261 0 0 0 13.75 12H2.25z"
      fill={COLOR_TOKENS.red[600]}
    />
  </Svg>
);

export default Avatar;

Avatar.defaultProps = {
  color: COLOR_TOKENS.red[600],
};
