import React, { FC } from 'react';
import { academyButtonGoToCourseButtonArrowRightIconSvg } from 'pages/AcademyPage/pages/FrontPage/components/FrontPageSvgs';
import { ResourceStickerData } from '../../hooks/useResourceStickers';
import {
  HTMLContentCss,
  LegalFragmentStickerContentCss,
  GoToResourceButtonLinkCss,
} from './styles';

interface ResourceStickerContentProps {
  isExpanded: boolean;
  stickerData: ResourceStickerData;
}

interface LegalFragmentStickerContentProps {
  isExpanded: boolean;
  content: {
    heading?: string;
    contentHtml: string;
  };
  legalFragmentStickerData: {
    documentShortTitle: string;
    legalResourceRouteLocation: string;
  };
}

interface HTMLStickerContentProps {
  isExpanded: boolean;
  content: {
    heading?: string;
    contentHtml: string;
  };
}

const HTMLStickerContent: FC<HTMLStickerContentProps> = ({
  content,
  isExpanded,
}) => {
  return (
    <HTMLContentCss isExpanded={isExpanded}>
      {content.heading && (
        <h2 dangerouslySetInnerHTML={{ __html: content.heading }} />
      )}
      {isExpanded && (
        <p dangerouslySetInnerHTML={{ __html: content.contentHtml }} />
      )}
    </HTMLContentCss>
  );
};

const LegalFragmentStickerContent: FC<LegalFragmentStickerContentProps> = ({
  isExpanded,
  content,
  legalFragmentStickerData: { documentShortTitle, legalResourceRouteLocation },
}) => {
  return (
    <LegalFragmentStickerContentCss isExpanded={isExpanded}>
      {content && (
        <HTMLStickerContent isExpanded={isExpanded} content={content} />
      )}
      {isExpanded && (
        <GoToResourceButtonLinkCss
          $isPrimary
          to={legalResourceRouteLocation}
          target="_blank"
          aria-label="Gå til ressurs"
        >
          <span>Gå til {documentShortTitle}</span>
          {academyButtonGoToCourseButtonArrowRightIconSvg}
        </GoToResourceButtonLinkCss>
      )}
    </LegalFragmentStickerContentCss>
  );
};

const ResourceStickerContent: FC<ResourceStickerContentProps> = ({
  stickerData,
  isExpanded,
}) => {
  const { content, legalFragmentStickerData } = stickerData;

  return (
    content &&
    legalFragmentStickerData && (
      <LegalFragmentStickerContent
        content={content}
        isExpanded={isExpanded}
        legalFragmentStickerData={legalFragmentStickerData}
      />
    )
  );
};

export default ResourceStickerContent;
