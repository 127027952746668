import React, { FC } from 'react';
import { OpenResourceStickerButtonCss } from './styles';

interface Props {
  onClick: () => void;
  onMouseOver?: () => void;
  label: string;
}

const OpenResourceStickerButton: FC<Props> = ({
  onClick,
  onMouseOver,
  label,
}) => {
  return (
    <OpenResourceStickerButtonCss onClick={onClick} onMouseOver={onMouseOver}>
      <span>{label}</span>
      {LinkSvgIcon}
    </OpenResourceStickerButtonCss>
  );
};

export default OpenResourceStickerButton;

const LinkSvgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
  >
    <path
      d="M0.0820312 1H9.99939M9.99939 1V11M9.99939 1L1.07377 9.92562"
      stroke="currentcolor"
      strokeWidth="2"
    />
  </svg>
);
