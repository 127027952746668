import React, { FC, Ref, forwardRef } from 'react';

import ArrowDown3 from 'components/icons/ArrowDown3';
import ArrowUp3 from 'components/icons/ArrowUp3';
import BulletPointsSquare from 'components/icons/BulletpointsSquare';
import { MobileTranscriptToggleButtonCss, FlexCss, MobileTranscriptToggleButtonContainerCss } from './Styles';
import { SecondaryButtonPurple } from '../../../../../components/AcademyAndNIK/Buttons/ButtonsPurple';

export const DesktopTranscriptToggleButton: FC<{
  isInOpenState: boolean;
  onClick: () => void;
  ref?: Ref<HTMLButtonElement>;
}> = forwardRef(({ isInOpenState, onClick }, ref) => {
  return (
    <SecondaryButtonPurple
      aria-expanded={isInOpenState}
      ref={ref}
      iconRight={isInOpenState ? <ArrowUp3 height={8} width={13} ml2 /> : <ArrowDown3 height={8} width={13} ml2 />}
      label="Transkribering"
      onClick={onClick}
    />
  );
});

export const MobileTranscriptToggleButton: FC<{ isInOpenState: boolean; onClick: () => void }> = ({ isInOpenState, onClick }) => (
  <MobileTranscriptToggleButtonContainerCss>
    <MobileTranscriptToggleButtonCss onClick={onClick} tabIndex={-1} aria-expanded={isInOpenState}>
      <FlexCss tabIndex={0}>
        <BulletPointsSquare mr2 />
        <span>Transkribering</span>
        {isInOpenState ? <ArrowUp3 height={8} width={13} ml2 /> : <ArrowDown3 height={8} width={13} ml2 />}
      </FlexCss>
    </MobileTranscriptToggleButtonCss>
  </MobileTranscriptToggleButtonContainerCss>
);
