import React from 'react';
import styled, { css } from 'styled-components';

import createMediaQuery from 'util/createMediaQuery';

import { mailToLink } from 'util/mailToLink';

const BriefNoAccessCss = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 35rem;
  padding: 1rem;
  text-align: center;
`;

const TitleCss = styled.h2`
  margin: 0 0 1.5rem;
  color: ${({ theme }) => theme.colorTokens.default.black};

  ${createMediaQuery(
    'md',
    css`
      font-size: 2.25rem;
    `
  )}
`;

const IllustrationCss = styled.img`
  width: 80%;
`;

const InfoTextCss = styled.div`
  margin-top: 1.5rem;
`;

const BriefNoAccess: React.FC = (): JSX.Element => (
  <BriefNoAccessCss>
    <TitleCss>Ikke tilgjengelig i ditt abonnement</TitleCss>
    <IllustrationCss
      alt=""
      src="https://res.cloudinary.com/dylsvi3ok/image/upload/v1623316947/illustrations/hoppetau-trans_nbhdvb.png"
      loading="lazy"
    />
    <InfoTextCss>
      <p>Du har ikke tilgang til dette innholdet. Dette kan for eksempel skyldes at abonnementet ditt har utløpt.</p>
      <p>
        Vet du at du skal ha tilgang til dette? Bare <a href={mailToLink()}>ta kontakt</a>, så ordner vi det for deg.
      </p>
    </InfoTextCss>
  </BriefNoAccessCss>
);

export default BriefNoAccess;
