import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from './IconPropTypes';

const Bug: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.0039 17.082C9.91016 17.082 9.81641 17.082 9.72266 17.0742C7.54297 16.9336 5.83203 15.0547 5.83203 12.7969V7.70703C5.83203 5.98438 7.23438 4.58203 8.95703 4.58203H11.043C12.7656 4.58203 14.168 5.98438 14.168 7.70703V12.918C14.168 14.0625 13.6875 15.1719 12.8477 15.957C12.0664 16.6875 11.0664 17.082 10.0039 17.082Z"
      fill="#910818"
    />
    <path d="M2.29297 10.832H6.45703" stroke="#910818" strokeWidth="1.66667" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M13.543 10.832H17.707" stroke="#910818" strokeWidth="1.66667" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M11.875 2.70703V3.33203C11.875 4.36719 11.0352 5.20703 10 5.20703C8.96484 5.20703 8.125 4.36719 8.125 3.33203V2.70703"
      stroke="#910818"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.543 13.543H14.375C15.7539 13.543 16.875 14.6602 16.875 16.043V17.293"
      stroke="#910818"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.125 17.293V16.043C3.125 14.6602 4.24609 13.543 5.625 13.543H6.45703"
      stroke="#910818"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.543 8.125H14.375C15.7539 8.125 16.875 7.00391 16.875 5.625V4.375"
      stroke="#910818"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.125 4.375V5.625C3.125 7.00391 4.24609 8.125 5.625 8.125H6.45703"
      stroke="#910818"
      strokeWidth="1.66667"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Svg>
);

export default Bug;
