import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { decodeToken } from 'util/jwt/decodeToken';
import { isTimestampExpired } from 'util/jwt/isTimestampExpired';

import { JuridikaAccessToken } from '../../models/jwt/JuridikaAccessToken';
import { PERMISSION_CLAIM } from '../../models/jwt/jwtClaims';

import * as sessionActions from '../session/sessionActions';
import * as jwtActions from '../jwt/jwtActions';
import * as companySignupActions from '../companySignup/companySignupActions';

import { ActiveLoginMethod, AuthenticationStatus, LoginState, TokenValidationResult } from './types';

type LoginAction = ActionType<typeof sessionActions> | ActionType<typeof companySignupActions> | ActionType<typeof jwtActions>;

const TOKEN_INVALID: TokenValidationResult = {
  authStatus: AuthenticationStatus.NOT_AUTHENTICATED,
  permissions: [],
  accessTokenExpiryTimestamp: undefined,
};

export const loginInitialState: LoginState = {
  activeLoginMethod: null,
  isCheckingIp: false,
  haveCheckedIp: false,
  canDoIpLogin: false,
  authStatus: AuthenticationStatus.UNKNOWN,
  prevAuthStatus: AuthenticationStatus.UNKNOWN,
  permissions: [],
  accessTokenExpiryTimestamp: undefined,

  jwtMiddlewareInitialized: false,
  jwtMiddlewareLastValidationResult: TOKEN_INVALID,
};

const validateAccessToken = (accessToken: string | undefined): TokenValidationResult => {
  if (!accessToken) return TOKEN_INVALID;

  const decodedToken = decodeToken<JuridikaAccessToken>(accessToken);

  if (isTimestampExpired(decodedToken.exp)) return TOKEN_INVALID;

  return {
    authStatus: AuthenticationStatus.AUTHENTICATED,
    permissions: decodedToken[PERMISSION_CLAIM] || [],
    accessTokenExpiryTimestamp: decodedToken.exp,
  };
};

export const loginReducers: Reducer<LoginState, LoginAction> = (state = loginInitialState, action): LoginState => {
  switch (action.type) {
    case getType(jwtActions.jwtMiddlewareInitialized):
      return {
        ...state,
        prevAuthStatus: state.authStatus,
        ...state.jwtMiddlewareLastValidationResult,
        jwtMiddlewareInitialized: true,
      };

    case getType(jwtActions.acquireJwtSuccess): {
      const validationResult = validateAccessToken(action.payload.jwt.accessToken);

      // Only expose the validation result immediately if the middleware is initialized
      const mergeValidationResult = state.jwtMiddlewareInitialized && validationResult;

      return {
        ...state,
        prevAuthStatus: state.authStatus,
        ...mergeValidationResult,
        jwtMiddlewareLastValidationResult: validationResult,
      };
    }
    case getType(jwtActions.checkAccessTokenValidity): {
      const validationResult = validateAccessToken(action.payload.accessToken);

      // Only expose the validation result immediately if the middleware is initialized
      const mergeValidationResult = state.jwtMiddlewareInitialized && validationResult;

      return {
        ...state,
        prevAuthStatus: state.authStatus,
        ...mergeValidationResult,
        jwtMiddlewareLastValidationResult: validationResult,
      };
    }
    case getType(jwtActions.acquireJwtFailure):
      return {
        ...state,
        prevAuthStatus: state.authStatus,
        authStatus: state.jwtMiddlewareInitialized ? AuthenticationStatus.NOT_AUTHENTICATED : state.authStatus,
        jwtMiddlewareLastValidationResult: TOKEN_INVALID,
      };

    case getType(sessionActions.ssoLogin.success):
    case getType(sessionActions.login.success):
    case getType(sessionActions.loginWithFeide.success):
    case getType(companySignupActions.completeSignupSuccess): {
      return {
        ...state,
        activeLoginMethod: null,
        isCheckingIp: false,
      };
    }

    case getType(sessionActions.checkIp.request): {
      return {
        ...state,
        isCheckingIp: true,
      };
    }

    case getType(sessionActions.checkIp.success): {
      return {
        ...state,
        ...action.payload,
        isCheckingIp: false,
        haveCheckedIp: true,
      };
    }

    case getType(sessionActions.checkIp.failure): {
      return {
        ...state,
        activeLoginMethod: null,
        isCheckingIp: false,
        haveCheckedIp: false,
      };
    }

    case getType(sessionActions.ssoLogin.request): {
      return {
        ...state,
        activeLoginMethod: ActiveLoginMethod.SSO,
      };
    }

    case getType(sessionActions.ssoLogin.failure):
    case getType(companySignupActions.completeSignupFailure): {
      return {
        ...state,
        activeLoginMethod: null,
      };
    }

    case getType(sessionActions.login.request): {
      return {
        ...state,
        activeLoginMethod: ActiveLoginMethod.USER,
      };
    }

    case getType(sessionActions.refreshIpToken.request):
    case getType(sessionActions.loginWithIp.request): {
      return {
        ...state,
        activeLoginMethod: ActiveLoginMethod.IP,
      };
    }

    case getType(sessionActions.loginWithFeide.request): {
      return {
        ...state,
        activeLoginMethod: ActiveLoginMethod.FEIDE,
      };
    }

    case getType(sessionActions.refreshIpToken.success):
    case getType(sessionActions.loginWithIp.success): {
      return {
        ...state,
        activeLoginMethod: null,
      };
    }

    case getType(sessionActions.login.failure):
    case getType(sessionActions.refreshIpToken.failure):
    case getType(sessionActions.loginWithIp.failure): {
      return {
        ...state,
        activeLoginMethod: null,
      };
    }

    case getType(sessionActions.resetSession): {
      return {
        ...state,
        prevAuthStatus: state.authStatus,
        authStatus: AuthenticationStatus.NOT_AUTHENTICATED,
      };
    }

    default: {
      return state;
    }
  }
};
