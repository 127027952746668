import * as React from 'react';
import { detect } from 'detect-browser';
import styled, { css } from 'styled-components';
import Lock from 'icons/Lock';
import { ColorTokenValues } from 'theme/config/types';
import { TextWithModifiers } from './SearchSuggestionDropdown';

interface Props {
  index: number;
  title: string;
  subTitle: TextWithModifiers[];
  onClick: () => void;
  onHover: (index: number, isHovering: boolean) => void;
  isActive: boolean;
  icon?: JSX.Element;
  accessible: boolean;
}

const SubtitleWrapperCss = styled.span<{ color?: ColorTokenValues }>`
  color: ${({ color }) => color};
`;

export const SuggestionIconCss = styled.span`
  top: 0.115rem;
  position: relative;
  margin-right: 0.1875rem;
`;

const SuggestionSubtitleCss = styled.span`
  font-size: 0.8125rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.03125rem;
  color: ${({ theme }) => theme.colorTokens.grey[800]};
`;

const SuggestionItemCss = styled.li<{ isActive: boolean }>`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: 1rem;
  letter-spacing: 0.05rem;
  min-height: 1.05rem;
  line-height: 1.05rem;

  margin-bottom: 0.3125rem;
  padding: 0.265rem 0.625rem 0.265rem 0.3125rem;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.colorTokens.grey[800]};
      color: ${({ theme }) => theme.colorTokens.grey[100]};
      cursor: pointer;
      span {
        color: ${({ theme }) => theme.colorTokens.default.white};
      }
      path {
        fill: ${({ theme }) => theme.colorTokens.default.white};
      }
    `}
`;

const SuggestionItem: React.FC<Props> = ({ title, subTitle, onHover, isActive, icon = null, onClick, accessible, index }) => {
  const ref = React.createRef<HTMLLIElement>();

  React.useEffect(() => {
    const browser = detect()?.name;
    const supportsScroll = browser === 'chrome' || browser === 'firefox';

    if (isActive && supportsScroll) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [isActive, ref]);

  return (
    <SuggestionItemCss
      ref={ref}
      onClick={onClick}
      isActive={isActive}
      onMouseEnter={() => onHover(index, true)}
      onMouseLeave={() => onHover(index, false)}
    >
      {!accessible && (
        <SuggestionIconCss>
          <Lock title="Dette innholdet er ikke i ditt abonnement" />
        </SuggestionIconCss>
      )}
      {icon && <SuggestionIconCss>{icon}</SuggestionIconCss>}
      <span>
        <span>{title}</span>
        <SuggestionSubtitleCss>
          {subTitle.map((textWithModifiers: TextWithModifiers) => {
            return (
              <SubtitleWrapperCss color={textWithModifiers.color} key={textWithModifiers.text}>
                {`, ${textWithModifiers.text}`}
              </SubtitleWrapperCss>
            );
          })}
        </SuggestionSubtitleCss>
      </span>
    </SuggestionItemCss>
  );
};

export default SuggestionItem;
