import * as React from 'react';
import styled from 'styled-components';

import { authenticatedSelector } from 'state/login/loginSelectors';
import Star from 'icons/Star';
import { ColorTokenValues } from 'theme/config/types';
import { useSelector } from 'util/hooks/useSelector';
import { FavoriteCollection, FavoriteMutation, useFavoriteMutation } from 'util/hooks/useFavoriteMutation';
import { GqlLegalDocumentUserdata } from 'pages/LegalDocumentPage/hooks/useLegalDocumentUserdataQuery';
import { DocumentData } from 'pages/JournalArticlePage/hooks/useDocumentQuery';
import { StaticPage } from 'pages/StaticContentPage/model/StaticPage';
import { GqlFavoriteMutationVariables } from 'models/GqlFavorite';
import { GqlLiteratureDocument } from 'pages/CommentariesPage/hooks/useExtraLiteratureLegalCommentaryQuery';
import { GqlFavoriteLegalDocument, GqlPublishedPage } from 'util/hooks/useFavoritesQuery';
import { GqlLegalDocumentSummary } from 'models/legalDocument/model';
import { GqlLiteratureDocument as EditionGqlLiteratureDocument } from '../pages/TextbookReaderPage/hooks/useEditionQuery';
import { ButtonAndLinkProps } from './Button/ButtonAndLinkCss';
import { Button } from './Button/Button';

const ERROR_MSG = 'Noe gikk galt! Prøv igjen senere';

export const FavoriteButtonWrapperCss = styled.span<{
  isLarge?: boolean;
  isFavorite: boolean;
  color?: ColorTokenValues;
}>`
  svg {
    width: ${(props) => (props.isLarge ? '1.4375rem' : '1.0625rem')};
    height: ${(props) => (props.isLarge ? '1.4375rem' : '1.0625rem')};
  }

  svg path {
    stroke: ${({ isFavorite, color, theme }) => (isFavorite ? color ?? theme.colorTokens.red[600] : theme.colorTokens.grey[700])};
    fill: ${({ isFavorite, color, theme }) => (isFavorite ? color ?? theme.colorTokens.red[600] : 'none')};
  }
`;

const ErrorWrapperCss = styled.span`
  color: ${(props) => props.theme.colorTokens.red[600]};
`;

export type FavoriteGqlResource =
  | GqlFavoriteLegalDocument
  | GqlLegalDocumentSummary
  | GqlLegalDocumentUserdata
  | GqlLiteratureDocument
  | EditionGqlLiteratureDocument
  | GqlPublishedPage
  | DocumentData
  | StaticPage;

export interface FavoriteButtonProps extends ButtonAndLinkProps {
  isLarge?: boolean;
  isFavorite: boolean;
  mutationVariables: GqlFavoriteMutationVariables;
  graphqlResource: FavoriteGqlResource;
  collection: FavoriteCollection;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  collection,
  isFavorite,
  isLarge,
  mutationVariables,
  graphqlResource,
  ...buttonProps
}) => {
  const isAuthenticated = useSelector((state) => authenticatedSelector(state));
  const isIpLogin = useSelector((state) => state.session.tokenSource) === 'IP';

  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

  const mutation = (isFavorite ? `remove${collection}` : `add${collection}`) as FavoriteMutation;

  const [toggleFavorite, { loading }] = useFavoriteMutation(mutation, {
    variables: mutationVariables,
    onError: (_e) => {
      setErrorMsg(ERROR_MSG);
    },
    update: (cache, { data }) => {
      const { success, error } = (data && data[mutation]) || {};

      if (error) setErrorMsg(ERROR_MSG);

      if (success) {
        cache.modify({
          id: cache.identify(graphqlResource),
          fields: {
            userMetadata: (cachedUserMetadata) => ({
              favoriteCreatedAt: cachedUserMetadata?.favoriteCreatedAt ? null : new Date().toISOString(),
            }),
          },
        });
      }
    },
  });

  const handleFavoriteToggle = () => {
    if (errorMsg) setErrorMsg(null);
    if (toggleFavorite) toggleFavorite();
  };

  if (!isAuthenticated || isIpLogin) return null;

  return (
    <>
      <FavoriteButtonWrapperCss isFavorite={isFavorite} isLarge={isLarge} color={buttonProps.$color}>
        <Button
          onClick={handleFavoriteToggle}
          leftIcon={Star}
          $variant="plain"
          disabled={loading}
          aria-label={isFavorite ? 'slett favoritt' : 'legg til favoritt'}
          {...buttonProps}
        />
      </FavoriteButtonWrapperCss>
      {errorMsg && <ErrorWrapperCss>{errorMsg}</ErrorWrapperCss>}
    </>
  );
};

export default FavoriteButton;
