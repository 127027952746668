import { CHILD, CHILDREN } from '../models/dom/domNode';
import { HypertextNode, TAGNAME, HTML_ATTRIBUTES } from './hypertextAllocator';
import { textGenerator } from './dom/domGenerators';

export const findByTagName = (tagName: string, nodes: HypertextNode[], excludeClassNames?: string[]): HypertextNode[] => {
  return nodes.reduce<HypertextNode[]>((aggr, node) => {
    const { [HTML_ATTRIBUTES]: htmlAttrs } = node;
    const { className } = htmlAttrs || {};
    if ((node[TAGNAME] === tagName || node[CHILDREN]) && !excludeClassNames?.includes(className)) {
      return [
        ...aggr,
        ...(node[TAGNAME] === tagName ? [node] : []),
        ...findByTagName(tagName, node[CHILDREN] || [], excludeClassNames),
      ];
    }
    return aggr;
  }, []);
};

export const getTextContent = (node: HypertextNode): string => {
  return [...textGenerator(node)].join(' ').trim();
};

export const findFirstImageSrc = (node: HypertextNode): string | null => {
  if (node[TAGNAME] === 'img') {
    const { [HTML_ATTRIBUTES]: htmlAttrs } = node;
    const { src } = htmlAttrs || {};
    if (src) return src;
  }

  const child = node[CHILD];
  if (child) return findFirstImageSrc(child);

  const children = node[CHILDREN];
  if (!children) return null;

  for (const c of children) {
    const result = findFirstImageSrc(c);
    if (result) return result;
  }
  return null;
};
