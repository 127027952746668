import React from 'react';

const MagnifyingGlassSlim: React.FC<{
  className?: string;
}> = (props) => (
  <svg width="17" height="17" viewBox="0 0 17 17">
    <path
      fillRule="evenodd"
      d="M6.8499999,0 C10.6331504,0 13.6999998,3.06684942 13.6999998,6.8499999 C13.6999998,8.32951715 13.2309432,9.69948034 12.4334244,10.819295 L12.4319755,10.8213287 L16.006073,14.3819427 L14.3808289,16.0038452 L10.809,12.44 C9.76864418,13.1780196 8.51751248,13.631846 7.16409267,13.6929267 L6.8499999,13.6999998 C3.06684942,13.6999998 0,10.6331504 0,6.8499999 C0,3.06684942 3.06684942,0 6.8499999,0 Z M6.8499999,1.25 C3.75720536,1.25 1.25,3.75720536 1.25,6.8499999 C1.25,9.94279445 3.75720536,12.4499998 6.8499999,12.4499998 C9.94279445,12.4499998 12.4499998,9.94279445 12.4499998,6.8499999 C12.4499998,3.75720536 9.94279445,1.25 6.8499999,1.25 Z"
    />
  </svg>
);

export default MagnifyingGlassSlim;
