import React from 'react';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';

import URI from 'urijs';
import { JuridikaErrorType } from 'commonUtils/models/JuridikaError';

import { AuthenticationStatus } from 'state/login/types';
import { useSelector } from 'util/hooks/useSelector';
import { Throw } from '../../components/error/Throw';
import PageLoader from '../../components/PageLoader';

import { Layout } from '../Layouts/Layout';

const AuthCallback: React.FC = () => {
  const { hash } = useLocation();
  const authStatus = useSelector((state) => state.login.authStatus);
  const referrerPath = useSelector((state) => state.referrer.path);

  const renderPageContent = () => {
    switch (authStatus) {
      case AuthenticationStatus.NOT_AUTHENTICATED: {
        const queries = URI.parseQuery(hash.replace(/#/g, '')) as any;
        if (queries && queries.error === 'unauthorized' && queries.error_description.startsWith('login.fail.email.verify')) {
          return <Redirect to="/validateEmail" />;
        }
        // BUG: we should throw something more "semantic" - like extract auth0 error code in some way?
        return <Throw error={{ type: JuridikaErrorType.LOGIN }} />;
      }
      case AuthenticationStatus.UNKNOWN:
        return <PageLoader>Logger inn</PageLoader>;

      case AuthenticationStatus.AUTHENTICATED:
        return <Redirect to={referrerPath} />;
    }
  };

  // Waiting for jwt middleware to do its thing.
  // (hint: it has its own separate check for location being AUTH_CALLBACK_PATHNAME)
  return <Layout hasBeigeBackground>{renderPageContent()}</Layout>;
};

export default AuthCallback;
