import React, { FC } from 'react';
import IconPropTypes from '../IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const JuridikaLogo: FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg className="juridika-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1999.000000 408.000000" {...props}>
    <g transform="translate(0.000000,408.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
      <path
        d="M8150 3935 c-369 -74 -678 -361 -869 -807 -43 -100 -135 -368 -129
    -373 21 -14 212 -75 217 -68 5 4 23 51 40 103 45 131 120 286 185 384 95 142
    247 269 380 316 l50 18 22 -56 c13 -30 36 -71 52 -91 65 -81 218 -124 355
    -102 90 15 143 40 196 93 62 63 85 131 85 253 1 90 -2 107 -28 163 -38 83 -98
    136 -184 162 -76 24 -266 26 -372 5z"
      />
      <path
        d="M11155 3782 c-220 -41 -403 -80 -407 -86 -4 -6 -21 -51 -39 -101
    l-31 -89 30 -27 c39 -35 167 -83 328 -122 l134 -32 0 -387 0 -387 -57 24
    c-111 44 -214 60 -403 60 -190 0 -259 -10 -401 -56 -178 -57 -324 -145 -460
    -276 -190 -183 -318 -422 -381 -710 -30 -141 -37 -475 -13 -629 43 -274 164
    -510 334 -651 81 -66 219 -136 326 -164 111 -29 316 -36 453 -15 174 26 391
    112 556 219 l69 45 22 -63 c40 -119 115 -185 234 -205 165 -28 428 50 604 180
    l67 50 0 115 0 115 -149 0 -149 0 -31 47 c-65 97 -61 -5 -61 1685 l0 1538 -87
    -1 c-63 -1 -201 -23 -488 -77z m-330 -1587 c113 -19 170 -36 268 -82 l77 -36
    0 -699 0 -699 -76 -36 c-286 -132 -623 -107 -837 62 -92 73 -181 207 -228 343
    -68 198 -84 529 -34 724 58 225 210 371 435 419 106 22 275 24 395 4z"
      />
      <path
        d="M14885 3840 c-38 -10 -206 -51 -372 -91 l-303 -73 0 -101 c0 -92 2
    -103 23 -122 44 -42 142 -92 239 -124 l98 -32 0 -1378 0 -1378 -37 -6 c-64
    -11 -215 -52 -268 -73 l-50 -20 -3 -131 -3 -131 641 0 641 0 -3 132 -3 131
    -60 22 c-33 13 -111 34 -174 48 -62 14 -115 28 -117 30 -2 2 -3 185 -2 406 l3
    402 430 -423 c261 -257 470 -453 530 -499 140 -107 284 -200 405 -261 l104
    -52 100 17 c55 10 124 25 153 34 l53 17 0 110 0 111 -603 564 c-331 311 -603
    568 -605 571 -1 4 177 155 396 335 l398 328 113 28 c63 15 147 44 187 63 l74
    35 0 126 0 125 -580 0 -580 0 0 -126 0 -125 53 -24 c28 -13 89 -34 134 -47 46
    -13 82 -26 80 -30 -1 -4 -191 -172 -422 -374 l-420 -366 -3 1186 -2 1186 -88
    -1 c-48 0 -118 -9 -157 -19z"
      />
      <path
        d="M1200 3435 l0 -146 46 -19 c64 -28 142 -49 269 -75 l110 -22 3 -1152
    c2 -1262 3 -1246 -56 -1341 -37 -60 -108 -96 -222 -111 -134 -17 -349 7 -501
    57 -92 31 -80 -4 -110 304 -11 118 -22 216 -24 218 -3 4 -216 -15 -276 -24
    l-37 -5 -31 -147 c-51 -237 -110 -654 -98 -686 12 -32 334 -119 547 -147 133
    -18 392 -15 515 5 446 74 717 262 819 567 61 182 59 143 65 1353 l6 1109 90
    18 c113 22 187 45 248 75 l47 24 0 145 0 145 -705 0 -705 0 0 -145z"
      />
      <path
        d="M18370 2634 c-397 -51 -705 -189 -879 -396 -108 -128 -151 -248 -151
    -425 0 -92 2 -103 18 -103 9 1 138 27 287 59 l269 58 24 174 c12 96 24 179 26
    185 5 13 118 49 201 65 78 15 285 5 349 -15 69 -22 127 -58 169 -105 84 -94
    107 -183 107 -431 l0 -176 -237 -17 c-362 -26 -572 -64 -774 -137 -250 -90
    -419 -259 -474 -475 -19 -76 -19 -250 0 -333 36 -154 139 -279 287 -351 105
    -51 184 -70 323 -77 296 -17 585 84 828 291 l65 55 16 -73 c19 -85 63 -183 97
    -214 88 -80 282 -81 507 -1 110 38 171 69 250 125 l62 44 0 114 0 114 -157 3
    -156 3 -33 47 c-18 26 -37 70 -43 98 -7 33 -11 248 -11 618 0 312 -5 605 -10
    652 -30 261 -139 445 -319 539 -123 64 -214 82 -426 86 -99 2 -196 1 -215 -1z
    m420 -1648 l0 -234 -67 -45 c-253 -166 -550 -219 -743 -131 -111 51 -154 117
    -154 239 -1 95 18 138 87 202 109 101 299 156 648 187 79 8 163 14 187 15 l42
    1 0 -234z"
      />
      <path
        d="M3075 2545 c-192 -47 -360 -87 -372 -90 -22 -5 -23 -10 -23 -109 l0
    -104 49 -36 c56 -42 136 -79 236 -111 l70 -23 5 -644 6 -643 27 -100 c40 -149
    106 -275 192 -361 115 -116 244 -173 437 -194 281 -30 568 50 864 241 50 33
    93 59 96 59 3 0 8 -17 12 -38 9 -58 63 -158 104 -192 79 -65 205 -90 337 -67
    151 27 322 111 434 212 l41 37 0 114 0 113 -151 3 -152 3 -28 35 c-59 74 -59
    66 -59 1067 l0 913 -77 0 c-64 0 -150 -17 -470 -92 l-393 -93 0 -102 0 -102
    33 -24 c63 -48 277 -137 328 -137 19 0 19 -16 19 -679 l0 -679 -85 -36 c-192
    -80 -329 -104 -489 -87 -208 24 -333 102 -406 256 -59 125 -60 138 -60 995 l0
    780 -87 -1 c-74 0 -143 -14 -438 -84z"
      />
      <path
        d="M6305 2536 l-370 -92 -3 -100 -3 -101 36 -28 c54 -40 138 -81 237
    -112 l88 -28 0 -767 c0 -423 -2 -768 -4 -768 -50 0 -256 -57 -328 -90 l-28
    -13 0 -129 0 -128 640 0 640 0 0 130 0 131 -47 20 c-46 20 -195 60 -275 74
    l-38 6 0 1044 0 1045 -87 -1 c-75 -1 -143 -15 -458 -93z"
      />
      <path
        d="M8130 2536 l-375 -93 -3 -95 -3 -95 36 -32 c48 -41 120 -77 235 -117
    l95 -32 3 -765 2 -765 -42 -7 c-76 -12 -199 -45 -264 -72 l-64 -25 0 -129 0
    -129 645 0 645 0 0 129 0 129 -52 22 c-50 21 -201 62 -280 75 l-38 6 0 1044 0
    1045 -82 -1 c-70 0 -144 -15 -458 -93z"
      />
      <path
        d="M12920 2536 l-375 -93 -3 -95 -3 -95 36 -32 c48 -41 120 -77 235
    -117 l95 -32 3 -765 2 -765 -42 -7 c-76 -12 -199 -45 -264 -72 l-64 -25 0
    -129 0 -129 645 0 645 0 0 129 0 129 -52 22 c-50 21 -201 62 -280 75 l-38 6 0
    1044 0 1045 -82 -1 c-70 0 -144 -15 -458 -93z"
      />
    </g>
  </Svg>
);

export default JuridikaLogo;

JuridikaLogo.defaultProps = {
  color: COLOR_TOKENS.default.black,
};
