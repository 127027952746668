import * as React from 'react';

import { JuridikaError } from 'commonUtils/models/JuridikaError';

export const ROOT_CATCH_ID = 'root';

interface CatchContextValue {
  readonly catchError: (error: JuridikaError) => void;
}

const swallowError = () => {};

export const CatchContext = React.createContext<CatchContextValue>({
  catchError: swallowError,
});
