import React, { FC } from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';

const QuoteMark: FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={37} height={28} viewBox="0 0 37 28" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M13.043 25.488c-.944.554-1.97.977-3.076 1.27-1.107.293-2.214.44-3.32.44-1.856 0-3.386-.627-4.59-1.88C.852 24.063.25 22.265.25 19.921c0-1.79.301-3.589.903-5.396.603-1.806 1.49-3.564 2.661-5.273 1.172-1.71 2.62-3.345 4.346-4.907 1.725-1.563 3.695-2.979 5.908-4.248l2.002 2.978c-2.636 2.051-4.646 4.094-6.03 6.128-1.383 2.035-2.075 4.028-2.075 5.982 0 .716.13 1.302.39 1.757.26.456.627.83 1.1 1.123.471.293 1.016.53 1.635.708.618.18 1.27.35 1.953.513v6.201zm20.703 0c-.944.554-1.97.977-3.076 1.27-1.107.293-2.214.44-3.32.44-1.856 0-3.386-.627-4.59-1.88-1.205-1.254-1.807-3.052-1.807-5.396 0-1.79.301-3.589.903-5.396.603-1.806 1.49-3.564 2.662-5.273 1.171-1.71 2.62-3.345 4.345-4.907 1.726-1.563 3.695-2.979 5.908-4.248l2.002 2.978c-2.636 2.051-4.646 4.094-6.03 6.128-1.383 2.035-2.075 4.028-2.075 5.982 0 .716.13 1.302.39 1.757.261.456.627.83 1.1 1.123.471.293 1.016.53 1.635.708.618.18 1.27.35 1.953.513v6.201z"
    />
  </Svg>
);

QuoteMark.defaultProps = {
  color: '#F16876',
};
export default QuoteMark;
