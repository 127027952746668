import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import * as ViewportActions from './viewportActions';
import { ViewportState } from './types';

type ViewportAction = ActionType<typeof ViewportActions>;

export const viewportInitialState: ViewportState = {
  hasBeenHydrated: false,
};

export const viewportReducers: Reducer<ViewportState, ViewportAction> = (state = viewportInitialState, action) => {
  switch (action.type) {
    case getType(ViewportActions.setHasBeenHydrated):
      return {
        hasBeenHydrated: true,
      };
    default:
      return state;
  }
};
