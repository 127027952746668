import React from 'react';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

import StatisticsLogger from './StatisticsLogger';
import { StatisticsLoggerContext } from './StatisticsLoggerContext';
import ErrorBoundary from '../../components/error/ErrorBoundary';
import { EventMetadata, ResourceMetadata } from './types';

interface PageviewStatisticsLoggerProps {
  title: string;
  resourceMetadata?: ResourceMetadata;
  disabled?: boolean;
  setMetadataCallback?: (data: EventMetadata) => void;
}

export const PageviewStatisticsLogger: React.FC<PageviewStatisticsLoggerProps> = (props) => {
  return (
    <ErrorBoundary>
      <PageviewStatisticsLoggerContent {...props} />
    </ErrorBoundary>
  );
};

const PageviewStatisticsLoggerContent: React.FC<PageviewStatisticsLoggerProps> = ({
  title,
  resourceMetadata,
  disabled,
  setMetadataCallback,
}) => {
  const loggerContext = React.useContext(StatisticsLoggerContext);
  const { isServer } = useJuridikaConfig();
  loggerContext.pageviewLoggers += 1;
  // if disabled, the StatisticsLogger is rendered in client instead of server.
  // This is required when the title is loaded dynamically, as the server
  // does not yet have access to the title at render-time.
  if (disabled) return null;
  if (isServer) {
    return (
      <StatisticsLogger
        kind="pageviewEvent"
        title={`${title} - Juridika`}
        hostname={loggerContext.hostname}
        page={loggerContext.pathname + loggerContext.search}
        resourceMetadata={resourceMetadata || {}}
        setMetadataCallback={setMetadataCallback}
      />
    );
  }

  return (
    <StatisticsLogger
      kind="pageviewEvent"
      title={`${title} - Juridika`}
      hostname={document.location.hostname}
      page={document.location.pathname + document.location.hash + document.location.search}
      resourceMetadata={resourceMetadata || {}}
      setMetadataCallback={setMetadataCallback}
    />
  );
};

export default PageviewStatisticsLogger;
