import Flex from 'components/ui/Flex';
import SimpleBar from 'simplebar-react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export const TranscriptCss = styled(SimpleBar)`
  background-color: ${({ theme }) => theme.colorTokens.purple[100]};
  padding: 0.625rem 0.75rem;
  height: 12.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[500]};
  margin-right: 3px;

  .simplebar-placeholder {
    // glitch resizing a div caused by simplebar
    width: auto !important; // bugfix from https://github.com/Grsmto/simplebar/issues/253#issuecomment-454345362
  }

  ${createMediaQuery(
    767,
    css`
      margin: 0 0.94rem;
    `
  )}

  ${createMediaQuery(
    991,
    css`
      padding-bottom: 0.94rem;
      border-bottom: 1px solid ${({ theme }) => theme.colorTokens.grey[500]};
    `
  )}
`;

export const TextCss = styled.p`
  margin: 0;
`;

export const TimestampCss = styled.span`
  margin-right: 0.75rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorTokens.grey[800]};
`;

export const FlexCss = styled(Flex)`
  margin-bottom: 0.9375rem;
`;
