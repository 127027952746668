import Svg from 'components/ui/Svg';
import * as React from 'react';
import IconPropTypes from './IconPropTypes';

const ArrowDown2: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.75 2.33203V12.918"
      stroke={color}
      strokeWidth="1.77778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.418 8.99999L8.75 13.668L4.08203 8.99999"
      stroke={color}
      strokeWidth="1.77778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

ArrowDown2.defaultProps = {
  color: '#171718',
};

export default ArrowDown2;
