import { CHILD, CHILDREN, DomNode, TEXT, TYPE, VALUE } from '../../models/dom/domNode';

/**
 * A generator for all the direct children of a node
 * @param node
 */
export function* directChildrenGenerator<P>(node: DomNode<P>): IterableIterator<DomNode<P>> {
  const child = node[CHILD];
  if (child) {
    yield child;
  }

  const children = node[CHILDREN];
  if (children) {
    for (const child of children) {
      yield child;
    }
  }
}

/**
 * A generator for all text nodes contained within a node
 * @param node
 */
export function* textGenerator<P>(node: DomNode<P>): IterableIterator<string | undefined> {
  if (node[TYPE] === TEXT) {
    yield node[VALUE];
  }

  const child = node[CHILD];
  if (child) {
    yield* textGenerator(child);
  }

  const children = node[CHILDREN];
  if (children) {
    for (const child of children) {
      yield* textGenerator(child);
    }
  }
}
