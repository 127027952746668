export const possibleTypes = {
  DocumentSearchResult: [
    'TextbookSearchResult',
    'ActExpressionSearchResult',
    'ActFragmentSearchResult',
    'JournalSearchResult',
    'JournalArticleSearchResult',
    'PublishedPageSearchResult',
    'PublishedPageContractResult',
    'ContributorSearchResult',
    'TextbookFragmentSearchResult',
    'UnknownTypeSearchResult',
  ],
  FavoriteDocument: ['ActWork', 'PublishedPage', 'LiteratureDocument'],
};
