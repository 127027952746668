import React, { FC, useEffect, useState, useRef } from 'react';
import isElementVisibleInContainer from 'util/isElementVisibleInContainer';
import { TitleHierarchy } from './TableOfContents';
import { TOCHeaderTitleCss, TOCPartsCss, TOCListItemButtonCss } from './styles';

type TOCFoldableSectionProps = {
  titles: TitleHierarchy[];
  ids: string[];
  urlHash: string;
  publicationTitle: string;
  handleScrollToPart: (id: string) => void;
};

interface TitleRef {
  id: string;
  element: HTMLButtonElement;
}

const performCallbackOnHTMLElements = (
  ids: string[],
  callback: (element: HTMLElement) => void
) => {
  ids.forEach((id) => {
    const element = document.getElementById(id);
    if (element) {
      callback(element);
    }
  });
};

const TOCFoldableSection: FC<TOCFoldableSectionProps> = ({
  titles,
  ids,
  urlHash,
  publicationTitle,
  handleScrollToPart,
}) => {
  const [activeTitleId, setActiveTitleId] = useState(urlHash);
  const tocRef = useRef<HTMLDivElement>(null);
  const titleRefs = useRef<TitleRef[]>([]);

  const refCallback = (element: HTMLButtonElement, id: string) => {
    const existingRefIndex = titleRefs.current.findIndex(
      (ref) => ref.id === id
    );
    if (existingRefIndex === -1) {
      titleRefs.current.push({ id, element });
    } else {
      titleRefs.current[existingRefIndex].element = element;
    }
  };

  const renderTitles = (titles: TitleHierarchy[]) => {
    return (
      <ol>
        {titles.map(({ title, id, children }) => {
          return (
            <li key={id}>
              <TOCListItemButtonCss
                aria-label={`Scroll til ${title}`}
                ref={(el) => el && refCallback(el, id)}
                highlighted={activeTitleId === id}
                onClick={() => handleScrollToPart(id)}
              >
                {title.trim()}
              </TOCListItemButtonCss>
              {children.length > 0 && renderTitles(children)}
            </li>
          );
        })}
      </ol>
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setActiveTitleId(entry.target.id);
        }
      },
      { rootMargin: `0% 0% -80% 0%` }
    );

    performCallbackOnHTMLElements(ids, (element) => {
      observer.observe(element);
    });

    return () => {
      performCallbackOnHTMLElements(ids, (element) => {
        observer.unobserve(element);
      });
    };
  }, [ids]);

  useEffect(() => {
    const activeElement = titleRefs.current.find(
      (ref) => ref.id === activeTitleId
    )?.element;
    if (window) {
      window.history.pushState(null, '', `#${activeTitleId}`);
    }
    if (tocRef.current && activeElement) {
      if (!isElementVisibleInContainer(activeElement, tocRef.current)) {
        const scrollToTocElementOptions: ScrollIntoViewOptions = {
          block: 'start',
        };
        activeElement.scrollIntoView({ ...scrollToTocElementOptions });
      }
    }
  }, [activeTitleId]);

  useEffect(() => {
    if (urlHash) {
      setActiveTitleId(urlHash);
    }
  }, [urlHash]);

  return (
    <div>
      <TOCHeaderTitleCss>{publicationTitle}</TOCHeaderTitleCss>
      <div>
        <TOCPartsCss ref={tocRef}>{renderTitles(titles)}</TOCPartsCss>
      </div>
    </div>
  );
};

export default TOCFoldableSection;
