import React from 'react';

import { contributorPath } from 'util/literatureUtils';
import { StyledInternalLink } from './StyledLink/StyledLink';
import { ButtonAndLinkProps } from './Button/ButtonAndLinkCss';

const contributionText = (names: string[], role: string) => {
  const name = names.join(' ');
  return role === 'editor' ? `${name} (red.)` : name;
};

const ContributionLink: React.FC<
  {
    contribution: {
      role: string;
      contributor: {
        slug: string;
        names: string[];
        isFeatured: boolean;
      };
    };
  } & ButtonAndLinkProps
> = ({ contribution, contribution: { contributor }, ...linkProps }) =>
  contribution.contributor.isFeatured ? (
    <StyledInternalLink to={contributorPath(contributor)} {...linkProps}>
      {contributionText(contributor.names, contribution.role)}
    </StyledInternalLink>
  ) : (
    <>{contributionText(contributor.names, contribution.role)}</>
  );

export default ContributionLink;
