import * as React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import createFontStyles from 'util/createFontStyles';
import { defaultTheme } from './config/defaultTheme';
import { ThemeType } from './config/types';

interface ThemeWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

export const GlobalStyleCss = createGlobalStyle<{ theme: ThemeType }>`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  .app-container {
    min-height: 100vh;
    margin: 0;
  }

  body {
    padding: 0;
    -webkit-font-smoothing: antialiased;
    background-color: ${(props) => props.theme.colorTokens.default.white};
    color: ${(props) => props.theme.colorTokens.grey[900]};

    ${(props) =>
      createFontStyles({
        family: props.theme.fontFamilies.secondary,
        weight: 400,
        size: 1,
        lineHeight: 1.25,
      })}


    /* Removing spinner on input */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Remove iOS input shadow */
    input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    a {
      border-radius: 2px;
      color: ${(props) => props.theme.colorTokens.blue[700]};
    }

    a, button {
      outline: none;

      &:hover {
        opacity: 0.85;
      }

      &:focus-visible {
        outline: none;
        border: 1px solid ${(props) => props.theme.colorTokens.grey[800]};
      }

      // The order is important. The active selector must come last.
      &:active {
        border-color: transparent;
      }
    }
}
`;

const ThemeWrapper: React.FunctionComponent<ThemeWrapperProps> = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyleCss />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
