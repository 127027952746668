import Svg from 'components/ui/Svg';
import React, { FC } from 'react';
import IconPropTypes from 'icons/IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const AngleRight: FC<IconPropTypes> = ({ color, size, ...props }) => {
  return (
    <Svg width={size || 10} height={size || 16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8C7.51704 5.33333 5.03481 2.66667 2.55185 0L0 1.93467L5.66 8.01533L0 14.0807L2.55185 16C5.02889 13.328 7.54074 10.6847 10 8Z"
        fill={color}
      />
    </Svg>
  );
};

AngleRight.defaultProps = {
  color: COLOR_TOKENS.purple[700],
};

export default AngleRight;
