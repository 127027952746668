interface GqlContribution {
  names: string[];
  occupation: {
    plainText: string | null;
  } | null;
}

export const constibutorsStr = (contributions: GqlContribution[] | null): string => {
  if (!contributions || contributions.length === 0) return '';

  const names = contributions.map((contributor) =>
    contributor.names.join(' ').concat(contributor.occupation ? ` (${contributor.occupation?.plainText})` : '')
  );

  if (names.length > 1) {
    return `${names.slice(0, names.length - 1).join(', ')} og ${names[names.length - 1]}`;
  }

  return names[0];
};
