import { camelize } from 'humps';

export const cssStringToObject = (cssString?: string): { [key: string]: string } | null => {
  if (!cssString && typeof cssString !== 'string') return null;

  return cssString.split(';').reduce((aggr, styleRule) => {
    if (!styleRule) return aggr;

    const [attr, value] = styleRule.split(':');

    if (!value) return aggr;

    return {
      ...aggr,
      [camelize(attr)]: value.trim(),
    };
  }, {});
};
