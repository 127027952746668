import { DocumentNode, gql, StoreObject } from '@apollo/client';
import * as apolloClient from '@apollo/client';

import { AuthenticationStatus } from 'state/login/types';

import { GqlConnection } from '../graphql/GqlConnection';
import { GqlError } from '../graphql/GqlError';

import { useApolloQuery } from './useApolloQuery';
import { LiteratureEditionAvailability } from '../literatureUtils';

const COMMON_QUERY_FIELDS = `
  pageInfo {
    hasNextPage
    endCursor
  }
  error {
    type
  }
`;

const FAVORITE_PUBLISHED_PAGE_QUERY_FIELDS = `
  id
  userMetadata {
    favoriteCreatedAt
  }
  slug
  title
  firstPublishedAt
  contentCategory {
    semanticKey
    name
  }
  contributions {
    edges {
      node {
        names
        occupation {
          plainText
        }
      }
    }
  }
`;

const FAVORITE_LITERATURE_DOCUMENT_QUERY_FIELDS = `
  id
  key
  userMetadata {
    favoriteCreatedAt
  }
  titles
  edition {
    id
    path
    titles
    name
    originallyPublishedAt
    availability
    publication {
      slug
      category
    }
  }
  contributions {
    edges {
      role
      node {
        slug
        names
        isFeatured
      }
    }
  }
`;

const FAVORITE_LEGAL_DOCUMENT_QUERY_FIELDS = `
  id
  userMetadata {
    favoriteCreatedAt
  }
  countryCode
  subtype
  date
  seq
  shortTitle
`;

const FAVORITE_ACTS_QUERY = gql`
  query FavoriteActs($cursor: String, $first: Int, $offset: Int) {
    legalDocuments(cursor: $cursor, first: $first, offset: $offset, onlyFavorites: true) {
      edges {
        cursor
        node {
          ${FAVORITE_LEGAL_DOCUMENT_QUERY_FIELDS}
        }
      }
      ${COMMON_QUERY_FIELDS}
    }
  }
`;

const FAVORITE_LITERATURE_DOCUMENTS_QUERY = gql`
  query FavoriteLiteratureDocuments($cursor: String, $first: Int, $offset: Int) {
    favoriteTextbookDocuments: favoriteLiteratureDocuments(
      cursor: $cursor
      first: $first
      offset: $offset
      collection: "fagbøker"
    ) {
      edges {
        cursor
        node {
          ${FAVORITE_LITERATURE_DOCUMENT_QUERY_FIELDS}
        }
      }
      ${COMMON_QUERY_FIELDS}
    }
  }
`;

const FAVORITE_JOURNAL_ARTICLE_DOCUMENTS_QUERY = gql`
  query FavoriteJournalArticleDocuments($cursor: String, $first: Int, $offset: Int) {
    favoriteJournalArticleDocuments: favoriteLiteratureDocuments(
      cursor: $cursor
      first: $first
      offset: $offset
      collection: "tidsskrifter"
    ) {
      edges {
        cursor
        node {
          ${FAVORITE_LITERATURE_DOCUMENT_QUERY_FIELDS}
        }
      }
      ${COMMON_QUERY_FIELDS}
    }
  }
`;

const FAVORITE_PUBLISHED_PAGES_QUERY = gql`
  query FavoritePublishedPages($cursor: String, $first: Int, $offset: Int) {
    favoritePublishedPages(cursor: $cursor, type: "page", first: $first, offset: $offset) {
      edges {
        cursor
        node {
          ${FAVORITE_PUBLISHED_PAGE_QUERY_FIELDS}
        }
      }
     ${COMMON_QUERY_FIELDS}
    }
  }
`;

const FAVORITE_PUBLISHED_CONTRACT_QUERY = gql`
  query FavoritePublishedContracts($cursor: String, $first: Int, $offset: Int) {
    favoritePublishedContracts: favoritePublishedPages(
        cursor: $cursor, 
        type: "contract", 
        first: $first, 
        offset: $offset
    ) {
      edges {
        cursor
        node {
          ${FAVORITE_PUBLISHED_PAGE_QUERY_FIELDS}
        }
      }
     ${COMMON_QUERY_FIELDS}
    }
  }
`;

export const FAVORITE_ALL_QUERY = gql`
  query AllFavoriteDocuments($cursor: String, $first: Int) {
    allFavorites(cursor: $cursor, first: $first) {
      edges {
        cursor
        node {
          __typename
          ...on LegalDocument {
            ${FAVORITE_LEGAL_DOCUMENT_QUERY_FIELDS}
          }
          ...on LiteratureDocument {
            ${FAVORITE_LITERATURE_DOCUMENT_QUERY_FIELDS}
          }
          ...on PublishedPage {
            ${FAVORITE_PUBLISHED_PAGE_QUERY_FIELDS}
          }
        }
      }
      ${COMMON_QUERY_FIELDS}
    }
  }
`;

interface GqlVariables {
  cursor?: string;
  first?: number;
  offset?: number;
}

export interface GqlFavoriteEdge<T> {
  cursor: string;
  node: T;
}

export interface GqlFavoriteConnection<EdgeT> {
  pageInfo: {
    hasNextPage: boolean;
    endCursor: string;
  };
  edges: Array<EdgeT>;
  error: GqlError;
}

interface FavoriteDocumentUserMetadata {
  favoriteCreatedAt: string | null;
}

export interface GqlFavoriteLegalDocument extends StoreObject {
  __typename: 'LegalDocument';
  id: string;
  countryCode: string;
  subtype: string;
  date: string;
  seq: number;
  userMetadata: FavoriteDocumentUserMetadata | null;
  shortTitle: string;
}

export interface GqlLiteratureDocumentContributor {
  slug: string;
  names: string[];
  isFeatured: boolean;
}

export interface GqlContribution<T> {
  role: string;
  node: T;
}

export interface GqlEdition {
  path: string[];
  name: string;
  originallyPublishedAt: string;
  availability: LiteratureEditionAvailability;
  titles: string[];
  publication: {
    slug: string;
    category: string[];
  };
}

export interface GqlLiteratureDocument extends StoreObject {
  __typename: 'LiteratureDocument';
  id: string;
  key: string;
  userMetadata: FavoriteDocumentUserMetadata | null;
  titles: string[];
  edition: GqlEdition;
  contributions: GqlConnection<GqlContribution<GqlLiteratureDocumentContributor>>;
}

export interface GqlPublishedPageContributor {
  names: string[];
  occupation: {
    plainText: string;
  };
}

export interface GqlPublishedPage extends StoreObject {
  __typename: 'PublishedPage';
  id: string;
  slug: string;
  userMetadata: FavoriteDocumentUserMetadata | null;
  title: string;
  contributions: GqlConnection<GqlContribution<GqlPublishedPageContributor>>;
  contentCategory: { semanticKey: string };
  firstPublishedAt: string;
}

export type GqlActWorkEdge = GqlFavoriteEdge<GqlFavoriteLegalDocument>;
export type GqlLiteratureDocumentEdge = GqlFavoriteEdge<GqlLiteratureDocument>;
export type GqlPublishedPageEdge = GqlFavoriteEdge<GqlPublishedPage>;
export type GqlAnyFavoritesEdge = GqlFavoriteEdge<GqlPublishedPage | GqlLiteratureDocument | GqlFavoriteLegalDocument>;

export interface GqlFavoritesConnection {
  legalDocuments?: GqlFavoriteConnection<GqlActWorkEdge>;
  favoriteTextbookDocuments?: GqlFavoriteConnection<GqlLiteratureDocumentEdge>;
  favoriteJournalArticleDocuments?: GqlFavoriteConnection<GqlLiteratureDocumentEdge>;
  favoritePublishedPages?: GqlFavoriteConnection<GqlPublishedPageEdge>;
  favoritePublishedContracts?: GqlFavoriteConnection<GqlPublishedPageEdge>;
  allFavorites?: GqlFavoriteConnection<GqlAnyFavoritesEdge>;
}

export type GqlFavoritesConnectionKey = keyof GqlFavoritesConnection;

export const FAVORITE_QUERY_MAPPER: {
  [key in GqlFavoritesConnectionKey]: DocumentNode;
} = {
  legalDocuments: FAVORITE_ACTS_QUERY,
  favoriteTextbookDocuments: FAVORITE_LITERATURE_DOCUMENTS_QUERY,
  favoriteJournalArticleDocuments: FAVORITE_JOURNAL_ARTICLE_DOCUMENTS_QUERY,
  favoritePublishedPages: FAVORITE_PUBLISHED_PAGES_QUERY,
  favoritePublishedContracts: FAVORITE_PUBLISHED_CONTRACT_QUERY,
  allFavorites: FAVORITE_ALL_QUERY,
};

const concatGqlConnections = <EdgeT>(
  head: GqlFavoriteConnection<EdgeT>,
  tail: GqlFavoriteConnection<EdgeT>
): GqlFavoriteConnection<EdgeT> => ({
  ...head,
  ...tail,
  pageInfo: tail.pageInfo,
  edges: [...head.edges, ...tail.edges],
  error: tail.error,
});

export const useFavoriteQuery = (
  favoriteQuery: GqlFavoritesConnectionKey,
  initialLoadCount: number,
  displayMoreInterval?: number
): {
  result: apolloClient.QueryResult<GqlFavoritesConnection, GqlVariables>;
  authStatus: AuthenticationStatus;
  fetchMore(cursor: string): void;
} => {
  const [queryResult, { authStatus }] = useApolloQuery<GqlFavoritesConnection, GqlVariables>(
    FAVORITE_QUERY_MAPPER[favoriteQuery],
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        cursor: undefined,
        first: initialLoadCount,
      },
      context: {
        // TODO: Remove when `FavoritesPage` have been reorganized to not have conditional rendering of child components using this query
        queryDeduplication: false,
      },
    }
  );

  const fetchMore = (cursor: string) =>
    queryResult.fetchMore({
      variables: {
        cursor,
        first: displayMoreInterval || initialLoadCount,
      },
      updateQuery: (prev: GqlFavoritesConnection, { fetchMoreResult }): GqlFavoritesConnection => {
        if (!fetchMoreResult) return prev;

        switch (favoriteQuery) {
          case 'legalDocuments': {
            return {
              ...prev,
              ...(prev.legalDocuments &&
                fetchMoreResult.legalDocuments && {
                  legalDocuments: concatGqlConnections(prev.legalDocuments, fetchMoreResult.legalDocuments),
                }),
            };
          }
          case 'favoriteTextbookDocuments': {
            return {
              ...prev,
              ...(prev.favoriteTextbookDocuments &&
                fetchMoreResult.favoriteTextbookDocuments && {
                  favoriteTextbookDocuments: concatGqlConnections(
                    prev.favoriteTextbookDocuments,
                    fetchMoreResult.favoriteTextbookDocuments
                  ),
                }),
            };
          }
          case 'favoriteJournalArticleDocuments': {
            return {
              ...prev,
              ...(prev.favoriteJournalArticleDocuments &&
                fetchMoreResult.favoriteJournalArticleDocuments && {
                  favoriteJournalArticleDocuments: concatGqlConnections(
                    prev.favoriteJournalArticleDocuments,
                    fetchMoreResult.favoriteJournalArticleDocuments
                  ),
                }),
            };
          }
          case 'favoritePublishedPages': {
            return {
              ...prev,
              ...(prev.favoritePublishedPages &&
                fetchMoreResult.favoritePublishedPages && {
                  favoritePublishedPages: concatGqlConnections(
                    prev.favoritePublishedPages,
                    fetchMoreResult.favoritePublishedPages
                  ),
                }),
            };
          }
          case 'favoritePublishedContracts': {
            return {
              ...prev,
              ...(prev.favoritePublishedContracts &&
                fetchMoreResult.favoritePublishedContracts && {
                  favoritePublishedPages: concatGqlConnections(
                    prev.favoritePublishedContracts,
                    fetchMoreResult.favoritePublishedContracts
                  ),
                }),
            };
          }
          case 'allFavorites': {
            return {
              ...prev,
              ...(prev.allFavorites &&
                fetchMoreResult.allFavorites && {
                  allFavorites: concatGqlConnections(prev.allFavorites, fetchMoreResult.allFavorites),
                }),
            };
          }
        }
      },
    });

  return { result: queryResult, authStatus, fetchMore };
};
