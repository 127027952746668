import * as React from 'react';
import { useDispatch } from 'react-redux';
import ThreeDots from 'components/icons/ThreeDots';
import ForwardButton from 'components/ForwardButton/ForwardButton';
import { Format } from 'pages/AcademyPage/types';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { setValue } from 'state/ui/uiActions';
import { useSelector } from 'util/hooks/useSelector';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { RootState } from 'state/types';
import { AcademyToggleSwitchCss, SettingsCss, SettingsOptionsCss } from './Styles';
import { AcademyIconButton1 } from '../IconButtons/IconButtons';

const Settings: React.FC<{
  format?: Format;
  handleShareCourseLink: () => void;
  showClickableHotspots: boolean;
  handleClickableHotspots: () => void;
  isOnTopOfPage: boolean;
  showTranscriptOption?: boolean;
}> = ({ format, handleShareCourseLink, showClickableHotspots, handleClickableHotspots, showTranscriptOption, isOnTopOfPage }) => {
  const isTranscriptEnabledInSettings = useSelector((state: RootState) => state.ui.akademi.showTranscript);

  const [isComponentVisible, setIsComponentVisible] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');
  const shouldDisplaySettingsDropdownBelowButton = isOnTopOfPage && viewportSize !== 'large';

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) setIsComponentVisible(false);
  };

  const handleToggleTranscriptEnabled = () => dispatch(setValue(!isTranscriptEnabledInSettings, ['akademi', 'showTranscript']));

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <SettingsCss ref={ref}>
      <AcademyIconButton1
        icon={<ThreeDots />}
        onClick={() => setIsComponentVisible((prev) => !prev)}
        aria-expanded={isComponentVisible}
        aria-label="Innstillinger"
      />
      <SettingsOptionsCss column $isActive={isComponentVisible} $top={!shouldDisplaySettingsDropdownBelowButton}>
        {isComponentVisible && (
          <>
            {format === Format.VIDEO && (
              <AcademyToggleSwitchCss
                labelRight="Lenker i video"
                isChecked={showClickableHotspots}
                onToggleSwitch={handleClickableHotspots}
              />
            )}

            {showTranscriptOption && (
              <AcademyToggleSwitchCss
                labelRight="Transkribering"
                isChecked={isTranscriptEnabledInSettings}
                onToggleSwitch={handleToggleTranscriptEnabled}
              />
            )}

            <ForwardButton handleShareCourseLink={handleShareCourseLink} />
          </>
        )}
      </SettingsOptionsCss>
    </SettingsCss>
  );
};

export default Settings;
