import { ButtonAndLinkIconWrapperCSS } from 'components/Button/ButtonAndLinkCss';
import Input from 'components/Input/Input';
import { LinkCss } from 'components/StyledLink/StyledLink';
import Flex from 'components/ui/Flex';
import styled, { css } from 'styled-components';
import { listWithoutStyle } from 'styleMixins/listMixins';
import createMediaQuery from 'util/createMediaQuery';

export const TransparentCss = styled.div<{ transparency?: boolean }>`
  ${({ transparency }) => transparency && `opacity: 0.5;`}
`;

export const IconLabelCss = styled.label`
  margin-right: 0.25rem;
`;

export const InputCss = styled(Input)`
  height: 1.5rem;
  width: 2.5rem;
`;

export const WrapperCss = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 4rem;
`;

export const ColumnCss = styled.div`
  width: 100%;
  margin: 0 0.9375rem;

  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }
`;

export const ColItemCss = styled.div<{ hide?: boolean; backgroundColor?: string; promo?: boolean }>`
  padding: ${({ promo }) => (promo ? `0` : `1.25rem`)};
  margin: 2rem 0;
  border: ${({ theme }) => `0.0625rem solid ${theme.colorTokens.brown[200]}`};
  border-radius: 0.75rem;

  :first-of-type {
    margin-top: 0;
  }

  :last-of-type {
    margin-bottom: 0;
  }

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

export const EditBoxCss = styled.div`
  padding: 1.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  margin: auto;
  margin-bottom: 1.25rem;
  border: 0.0625rem solid ${({ theme }) => theme.colorTokens.brown[500]};
  border-radius: 4px;
  line-height: 1.75rem;

  ${createMediaQuery(
    'sm',
    css`
      width: 36rem;
    `
  )}
`;

export const EditBoxTitleCss = styled(Flex)`
  margin-bottom: 0.75rem;
`;

export const HeadingCss = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
`;

export const FilterListCss = styled.ul`
  ${listWithoutStyle};
`;

export const FilterListItemCss = styled.li`
  display: inline-block;
  margin: 1rem 0.5rem 0.5rem 0;
`;

export const ReadMoreLinkWrapperCss = styled.div`
  margin-left: 2rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  ${createMediaQuery(
    'sm',
    css`
      margin-left: 3rem;
    `
  )}
`;

export const LinkWrapperCss = styled.div`
  ${LinkCss} {
    padding: 0.5rem 0;

    :hover {
      svg * {
        fill: ${({ theme }) => theme.colorTokens.red[500]};
      }
    }
  }
`;

export const PromoContainerCss = styled.div`
  padding: 3rem 0 2rem;
  text-align: center;

  h2 {
    margin: 0 0 1.5rem;
    color: ${({ theme }) => theme.colorTokens.red[600]};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 2.25rem;
  }

  p {
    margin: 0 auto 2.5rem;
    max-width: 29.3125rem;
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  div {
    margin-top: 2.6875rem;
  }

  ${ButtonAndLinkIconWrapperCSS} svg {
    width: 0.625rem;
    height: 1rem;

    * {
      fill: ${({ theme }) => theme.colorTokens.default.white};
    }
  }
`;

export const EditBoxListCss = styled.ul`
  margin: 0;
  padding-left: 1.5rem;
`;
