import React from 'react';
import IconPropTypes from './IconPropTypes';
import Svg from 'components/ui/Svg';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const BulletPointsSquare: React.FC<IconPropTypes> = ({ color, ...props }) => (
  <Svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.32258 0.290283C2.75161 0.290283 3.09677 0.637057 3.09677 1.06448V2.61286C3.09677 3.0419 2.75161 3.38706 2.32258 3.38706H0.774194C0.346774 3.38706 0 3.0419 0 2.61286V1.06448C0 0.637057 0.346774 0.290283 0.774194 0.290283H2.32258ZM15.2258 1.06448C15.6548 1.06448 16 1.41125 16 1.83867C16 2.2677 15.6548 2.61286 15.2258 2.61286H5.41935C4.99032 2.61286 4.64516 2.2677 4.64516 1.83867C4.64516 1.41125 4.99032 1.06448 5.41935 1.06448H15.2258ZM15.2258 6.22577C15.6548 6.22577 16 6.57093 16 6.99996C16 7.42899 15.6548 7.77415 15.2258 7.77415H5.41935C4.99032 7.77415 4.64516 7.42899 4.64516 6.99996C4.64516 6.57093 4.99032 6.22577 5.41935 6.22577H15.2258ZM15.2258 11.3871C15.6548 11.3871 16 11.7322 16 12.1613C16 12.5903 15.6548 12.9354 15.2258 12.9354H5.41935C4.99032 12.9354 4.64516 12.5903 4.64516 12.1613C4.64516 11.7322 4.99032 11.3871 5.41935 11.3871H15.2258ZM0 6.22577C0 5.79673 0.346774 5.45157 0.774194 5.45157H2.32258C2.75161 5.45157 3.09677 5.79673 3.09677 6.22577V7.77415C3.09677 8.20319 2.75161 8.54835 2.32258 8.54835H0.774194C0.346774 8.54835 0 8.20319 0 7.77415V6.22577ZM2.32258 10.6129C2.75161 10.6129 3.09677 10.958 3.09677 11.3871V12.9354C3.09677 13.3645 2.75161 13.7096 2.32258 13.7096H0.774194C0.346774 13.7096 0 13.3645 0 12.9354V11.3871C0 10.958 0.346774 10.6129 0.774194 10.6129H2.32258Z"
      fill={color}
    />
  </Svg>
);

BulletPointsSquare.defaultProps = {
  color: COLOR_TOKENS.grey[900],
};

export default BulletPointsSquare;
