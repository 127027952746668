import { css, CSSProp } from 'styled-components';

import { FONT_SIZE_SCALE } from 'theme/config/defaultTheme';
import FontSizePropTypes from 'models/FontSizePropTypes';

const createFontSize = (props: FontSizePropTypes & { [key: string]: any }): CSSProp => css`
  ${Object.keys(props).reduce((a, c) => {
    if (/^f-?[0-9]?$/.test(c)) {
      if (props[c] === true) {
        // Is boolean (like f3) -> extract value
        const value = parseInt(c.replace('f', ''), 10);
        return `${a}font-size: ${FONT_SIZE_SCALE[value]};`;
      }

      if (typeof props[c] === 'number') {
        return `${a}font-size: ${FONT_SIZE_SCALE[props[c]]};`;
      }

      if (typeof props[c] === 'string') {
        return `${a}font-size: ${props[c]};`;
      }

      return a;
    }

    return a;
  }, '')}
`;

export default createFontSize;
