import * as React from 'react';
import styled, { css } from 'styled-components';

import createMediaQuery from 'util/createMediaQuery';
import { GridProps } from './Grid';

import { GridMargin } from './gridConfig';

const gridItemCss = (numberOfColumns: number, margin: GridMargin) => {
  const { topBottom, sides } = margin;
  const widthRule = `calc(${100.0 / numberOfColumns}% - ${sides * 2}rem)`;

  return css`
    margin: 0 ${sides}rem ${topBottom}rem;
    flex-basis: ${widthRule};
    max-width: ${widthRule};
  `;
};

const GridItemCSS = styled.li<GridProps>`
  flex-grow: 0;
  flex-shrink: 1;

  ${({ config: { margin: initialMargin, numberOfColumns: initialNumberOfColumns, mediaQueries = [] } }) => css`
    ${gridItemCss(initialNumberOfColumns, initialMargin)}

    ${mediaQueries.map(({ breakpoint, numberOfColumns, margin }) =>
      createMediaQuery(breakpoint, gridItemCss(numberOfColumns, margin))
    )}
  `}
`;

const GridItem: React.FC<GridProps> = ({ config, children }) => {
  return (
    <GridItemCSS as={!config.isListGrid ? 'div' : 'li'} config={config}>
      {children}
    </GridItemCSS>
  );
};

export default GridItem;
