import * as React from 'react';
import ArrowUp3 from 'components/icons/ArrowUp3';
import Time from 'components/icons/Time';
import Flex from 'components/ui/Flex';
import ArrowDown3 from 'components/icons/ArrowDown3';
import type { Chapter as ChapterType } from 'pages/AcademyPage/types';
import { Course, Module as ModuleType } from 'pages/AcademyPage/types';
import { FC, memo, useEffect, useState } from 'react';
import { RootState } from 'state/types';
import { useSelector } from 'util/hooks/useSelector';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { useHistory } from 'react-router';
import ArrowRight from 'components/icons/ArrowRight';
import { isCourseModulesBeforeFinalTestCompletedSelector } from 'state/selectors';
import { CourseContentContext } from 'pages/AcademyPage/utils/academyCourseContentContext';
import { secondsToHHMMSS, useUserHasAkademiBeta } from 'pages/AcademyPage/utils';
import {
  SidebarAccordionCss,
  SidebarChapterHeaderCss,
  SidebarChapterModulesCss,
  SidebarExpandedAreaCss,
  SidebarTitleCss,
  SidebarDetailsCss,
  SidebarChapterWrapperCss,
  SidebarCourseHeaderCss,
  SidebarGoToIntroButtonCss,
  SidebarLockCss,
} from './Styles';
import Module from '../Module/Module';
import { ChapterProgressbar, CourseProgressbar } from '../../AcademyProgressbar/AcademyProgressbar';

interface ChapterHeaderProps {
  chapterData: ChapterType;
  expanded: boolean;
  handleExpanded: () => void;
}

interface ChapterProps {
  chapterData: ChapterType;
  onModuleClick: () => void;
  sidebarWidth: number;
  courseSlug?: string;
}

interface CourseHeaderProps {
  isSelected: boolean;
  courseMetadata?: Course | null;
}

export const CourseHeader: FC<CourseHeaderProps> = ({ isSelected, courseMetadata }) => {
  const history = useHistory();
  const hasAkademiBeta = useUserHasAkademiBeta();

  const { modules } = React.useContext(CourseContentContext);
  const totalDuration = modules?.reduce((acc, curr) => acc + curr.durationInSeconds, 0);

  const handleOnclick = () => {
    history.push(`/akademi/kurs/${courseMetadata?.slug}/intro`);
  };

  return (
    <SidebarCourseHeaderCss>
      <SidebarTitleCss>{courseMetadata?.title}</SidebarTitleCss>
      <SidebarGoToIntroButtonCss onClick={handleOnclick} $isActive={isSelected}>
        <span>Introduksjon til kurs</span>
        <ArrowRight ml1 color={COLOR_TOKENS.default.white} />
      </SidebarGoToIntroButtonCss>

      <SidebarDetailsCss justify="space-between" mt1>
        <span>
          {courseMetadata?.numberOfChapters && courseMetadata?.numberOfChapters > 1
            ? `${courseMetadata?.numberOfChapters} deler`
            : `${courseMetadata?.numberOfChapters} del`}
        </span>
        <Flex align="center">
          <Time mr1 size={14} color={COLOR_TOKENS.purple[300]} />
          <span>{secondsToHHMMSS(totalDuration)}</span>
        </Flex>
      </SidebarDetailsCss>
      {hasAkademiBeta && <CourseProgressbar />}
    </SidebarCourseHeaderCss>
  );
};

const ChapterHeader: FC<ChapterHeaderProps> = ({ chapterData: data, expanded, handleExpanded }) => {
  const courseContent = React.useContext(CourseContentContext);
  const hasAkademiBeta = useUserHasAkademiBeta();

  const isCourseModulesBeforeTestCompleted = useSelector((state: RootState) =>
    isCourseModulesBeforeFinalTestCompletedSelector(state, courseContent)
  );

  const { modules } = React.useContext(CourseContentContext);
  const [chapterDuration, setChapterDuration] = useState<number>(0);

  useEffect(() => {
    const chapterDuration = modules
      ?.filter((mod) => mod.inChapter === data.id)
      .reduce((acc, curr) => acc + curr.durationInSeconds, 0);
    setChapterDuration(chapterDuration);
  }, [modules, data.id]);

  return (
    <SidebarChapterHeaderCss $expanded={expanded}>
      <SidebarAccordionCss onClick={handleExpanded} aria-expanded={expanded}>
        {data.slug === 'quiz-og-kursbevis' && !isCourseModulesBeforeTestCompleted && (
          <SidebarLockCss mr2 color={COLOR_TOKENS.grey[700]} />
        )}
        <SidebarTitleCss>{data.title}</SidebarTitleCss>
        {expanded ? <ArrowUp3 /> : <ArrowDown3 />}
      </SidebarAccordionCss>
      {expanded && data.slug !== 'quiz-og-kursbevis' && (
        <SidebarExpandedAreaCss>
          <SidebarDetailsCss justify="space-between" mt1>
            <span>{data.parts && data.parts > 1 ? `${data.parts} moduler` : `${data.parts} modul`}</span>
            <Flex align="center">
              <Time mr1 size={14} />
              <span>{secondsToHHMMSS(chapterDuration)}</span>
            </Flex>
          </SidebarDetailsCss>
          {hasAkademiBeta && <ChapterProgressbar chapterId={data.id} />}
        </SidebarExpandedAreaCss>
      )}
    </SidebarChapterHeaderCss>
  );
};

const Chapter: FC<ChapterProps> = memo(({ chapterData: data, onModuleClick, sidebarWidth, courseSlug }) => {
  const currentChapterId = useSelector((state: RootState) => state.ui.akademi.currentChapterId);
  const currentModuleId = useSelector((state: RootState) => state.ui.akademi.currentModuleId);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [modules, setModules] = useState<ModuleType[]>([]);

  useEffect(() => {
    if (data) {
      fetch('/api/academy/modules').then((res) =>
        res.json().then((res) => {
          const courseModules = res.filter((mod: ModuleType) => mod.inChapter === data.id);
          setModules(courseModules);
        })
      );
      setExpanded(data.id === currentChapterId);
    }
  }, [currentChapterId, data]);

  return (
    <SidebarChapterWrapperCss $expanded={expanded}>
      <ChapterHeader chapterData={data} expanded={expanded} handleExpanded={() => setExpanded((prev) => !prev)} />
      {expanded && (
        <SidebarChapterModulesCss>
          {modules.map((module, index) => (
            <Module
              sidebarWidth={sidebarWidth}
              key={module.id}
              moduleData={{ ...module, part: index + 1, chapter: data.slug, course: courseSlug || '' }}
              close={onModuleClick}
              isActive={currentModuleId === module.id}
            />
          ))}
        </SidebarChapterModulesCss>
      )}
    </SidebarChapterWrapperCss>
  );
});

export default Chapter;
