import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { PrimaryButtonPurpleCss, commonButtonStyles } from './ButtonsPurple';

const commonPrimaryButtonVibrantStyles = css`
  ${commonButtonStyles}

  background-color: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  color: ${({ theme }) => theme.colorTokens.default.white};

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
    color: ${({ theme }) => theme.colorTokens.default.white};
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    border: none;
  }
`;

const commonSecondaryButtonVibrantStyles = css`
  ${commonButtonStyles}

  background-color: ${({ theme }) => theme.colorTokens.default.white};
  color: ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  border: 1px solid ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
  padding: 11px 19px;

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purpleVibrant[500]};
    color: ${({ theme }) => theme.colorTokens.default.white};
    border: none;
    padding: 12px 20px;
  }

  :focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorTokens.purpleVibrant[900]};
    border: none;
  }
`;

export const ButtonLinkVibrantCss = styled(Link)<{ $isPrimary?: boolean }>`
  ${({ $isPrimary }) => ($isPrimary ? commonPrimaryButtonVibrantStyles : commonSecondaryButtonVibrantStyles)}

  text-decoration: none;
  display: inline-flex;
`;

export const PrimaryButtonVibrantCss = styled(PrimaryButtonPurpleCss)`
  ${commonPrimaryButtonVibrantStyles}
`;
