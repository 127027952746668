import * as Redux from 'redux';
import { History } from 'history';
import { RouterActionType, connectRouter } from 'connected-react-router';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { RootReducer, RootState } from './types';
import { currentUserReducers, currentUserInitialState } from './currentUser/currentUserReducers';
import { sessionReducers, sessionInitialState } from './session/sessionReducers';
import { referrerReducers, referrerInitialState } from './referrer/referrerReducers';
import { loginReducers, loginInitialState } from './login/loginReducers';
import { viewportReducers, viewportInitialState } from './viewport/viewportReducers';
import { uiReducers, uiInitialState } from './ui/uiReducers';
import { juridikaDevOptionsReducer, juridikaDevOptionsInitialState } from './juridikaDevOptions/juridikaDevOptionsReducers';

/**
 * had to type state as any. For some reason doesn´t work with its original imported 'unknown' type.
 * The interface is normally pretyped and imported from a package associated to the router
 * (in this case it was the interface RouterState from connected-react-router).
 * TODO: revisit router interface after having a more up to date codebase
 */

export interface RouterState {
  location: {
    state: any;
    query: Record<string, string>;
    pathname: string;
    search: string;
    hash: string;
    key?: string;
  };
  action: RouterActionType;
}

const routerInitialState: RouterState = {
  location: {
    state: '',
    pathname: '/',
    search: '',
    hash: '',
    query: {},
  },
  action: 'POP',
};

export const initialState: RootState = {
  currentUser: currentUserInitialState,
  router: routerInitialState,
  session: sessionInitialState,
  referrer: referrerInitialState,
  login: loginInitialState,
  viewport: viewportInitialState,
  ui: uiInitialState,
  juridikaDevOptions: juridikaDevOptionsInitialState,
};

export const createRootReducer = (juridikaConfig: JuridikaConfig, history: History): RootReducer =>
  Redux.combineReducers({
    currentUser: currentUserReducers,
    router: connectRouter(history),
    session: sessionReducers,
    referrer: referrerReducers,
    login: loginReducers,
    viewport: viewportReducers,
    ui: uiReducers,
    juridikaDevOptions: juridikaDevOptionsReducer,
  });
