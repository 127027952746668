import React from 'react';

export interface StatisticsLoggerContextValue {
  hostname: string;
  pathname: string;
  search: string;
  pageviewLoggers: number;
}

export const StatisticsLoggerContext = React.createContext<StatisticsLoggerContextValue>({
  hostname: '',
  pathname: '',
  search: '',
  pageviewLoggers: 0,
});
