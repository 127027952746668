import * as React from 'react';
import styled, { css } from 'styled-components';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import createMediaQuery from 'util/createMediaQuery';
import Button from 'components/ui/Button';
import { ButtonAndLinkContent } from 'components/Button/ButtonAndLinkContent';

interface SectionProps {
  symbol: string;
  description: string;
  usage: React.ReactElement;
}

const SearchTipsCss = styled.div`
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: flex-end;
`;

const WrapperCss = styled.div`
  margin: 1.875rem 0;
  padding: 1rem;
  font-family: ${(props) => props.theme.fontFamilies.secondary};
  background-color: ${COLOR_TOKENS.grey[100]};
  width: 100%;
`;

const TitleCss = styled.div`
  font-weight: 600;
  letter-spacing: 0.0437rem;
  display: flex;
`;

const SectionHeaderCss = styled.div<{ isPointingLeft: boolean }>`
  padding: 0.3125rem 0;
  display: flex;
  align-items: center;

  .c-icon-arrowDown,
  .c-icon-arrowUp {
    transition: transform 0.15s ease;
    ${({ isPointingLeft }) => isPointingLeft && `transform: rotate(180deg)`}
  }
`;

const SectionSymbolCss = styled.div`
  min-width: 3.5625rem;
  min-height: 3.125rem;
  background-color: #eeeeee;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${COLOR_TOKENS.grey[900]};
  padding-top: 0.325rem;
  padding: 0.3125rem;
`;

const SectionSymbolDescriptionCss = styled.div`
  padding-right: 0.3125rem;
  margin-left: 1rem;
  align-self: center;
  letter-spacing: 0.0563rem;
  color: ${COLOR_TOKENS.grey[900]};
`;

const SectionsCss = styled.div`
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 0.0625rem solid ${COLOR_TOKENS.grey[500]};

  ${createMediaQuery(
    'md',
    css`
      border-top: none;
      flex-direction: row;
    `
  )}
`;

const SectionSymbolUsageCss = styled.div`
  padding: 0.625rem 1rem 0.625rem 0.3125rem;
  border-top: 0.0625rem solid ${COLOR_TOKENS.grey[500]};
  letter-spacing: 0.0563rem;
`;

const SectionCss = styled.div`
  border-bottom: 0.0625rem solid ${COLOR_TOKENS.grey[500]};

  ${createMediaQuery(
    'md',
    css`
      :first-child {
        border-top: 0.0625rem solid ${COLOR_TOKENS.grey[500]};
      }
    `
  )}
}`;

const ContainerCss = styled.div`
  ${createMediaQuery(
    'md',
    css`
      flex-basis: calc(50% - 10px);
    `
  )}
`;

export const ButtonTextCss = styled.p`
  text-decoration: underline;
  color: ${COLOR_TOKENS.blue[700]};
  font-weight: 600;
  margin: 0.5rem 0;
  padding: 1px 0 2px 7px;
  font-size: 12px;
  letter-spacing: 0.43px;
  text-transform: uppercase;

  :hover {
    text-decoration: none;
  }
`;

const Section: React.FC<SectionProps> = ({ symbol, description, usage }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <SectionCss>
      <SectionHeaderCss isPointingLeft={open}>
        <SectionSymbolCss>{symbol}</SectionSymbolCss>
        <SectionSymbolDescriptionCss>{description}</SectionSymbolDescriptionCss>
        <Button onClick={() => setOpen(!open)} iconLeft={{ name: 'ArrowDown' }} mlAuto />
      </SectionHeaderCss>
      {open && <SectionSymbolUsageCss>{usage}</SectionSymbolUsageCss>}
    </SectionCss>
  );
};

const SearchTips: React.FC = () => {
  const leftSection = [
    {
      symbol: '""',
      description: 'Ord mellom hermetegn skal komme etter hverandre i angitt rekkefølge (frase).',
      usage: (
        <>
          Nyttig for å søke etter sammensatte begrep. F.eks.: <strong>&quot;barnets beste&quot;</strong> gir treff med disse to
          ordene rett etter hverandre. Det er viktig å bruke riktig type hermetegn. Det vil ikke fungere med “” eller «».
        </>
      ),
    },
    {
      symbol: 'OR',
      description: 'Gir treff når enten uttrykket til venstre eller til høyre for OR finnes, eller begge.',
      usage: (
        <>
          Standard er at når du skriver flere ord i søkefeltet så vil du bare få treff hvis alle ordene er med. Med OR kan du
          overstyre dette. F.eks.: <strong>kontrakt OR avtale</strong> gir treff der <i>kontrakt</i> og/eller <i>avtale</i>{' '}
          finnes.
        </>
      ),
    },
    {
      symbol: '-',
      description: 'Eliminerer et søkeord eller en frase fra treffene.',
      usage: (
        <>
          Nyttig for å luke ut irrelevante treff. F.eks.: <strong>akkordforslag -dødsbo</strong> gir treff på <i>akkordforslag</i>{' '}
          der <i>dødsbo</i> ikke er nevnt.
        </>
      ),
    },
  ];

  const rightSection = [
    {
      symbol: '*',
      description: 'Erstatter * med 0 eller flere mulige tegn.',
      usage: (
        <>
          F.eks.: <strong>familierel*</strong> gir treff med alle mulige endelser av ordet, som <i>familierelasjon</i>,{' '}
          <i>familierelaterte</i>, osv. Jo tidligere du setter stjernen, jo flere mulige treff. Dette kan gi mange irrelevante
          treff.
        </>
      ),
    },
    {
      symbol: '( )',
      description: 'Brukes til å gruppere søkeord eller fraser.',
      usage: (
        <>
          F.eks.: <strong>(fusjon OR fisjon) selskap utland</strong> gir treff der begge søkeordene
          <i>selskap</i> og <i>utland</i> finnes, i kombinasjon med enten <i>fusjon</i>, <i>fisjon</i> eller begge.
        </>
      ),
    },
  ];

  const [open, setOpen] = React.useState(false);

  return (
    <SearchTipsCss>
      {!open && (
        <Button onClick={() => setOpen(!open)}>
          <ButtonAndLinkContent $color={COLOR_TOKENS.blue[700]}>
            <ButtonTextCss>Vis Søketips</ButtonTextCss>
          </ButtonAndLinkContent>
        </Button>
      )}
      {open && (
        <WrapperCss>
          <TitleCss>
            Tegn du kan bruke til å finjustere søket ditt
            <Button onClick={() => setOpen(!open)} iconRight={{ name: 'Close' }} mlAuto />
          </TitleCss>
          <SectionsCss>
            <ContainerCss>
              {leftSection.map((section) => (
                <Section {...section} key={section.symbol} />
              ))}
            </ContainerCss>
            <ContainerCss>
              {rightSection.map((section) => (
                <Section {...section} key={section.symbol} />
              ))}
            </ContainerCss>
          </SectionsCss>
        </WrapperCss>
      )}
    </SearchTipsCss>
  );
};

export default SearchTips;
