import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from 'containers/Layouts/Layout';
import { Throw } from 'components/error/Throw';
import { JuridikaErrorType } from 'commonUtils/models/JuridikaError';
import { authenticatedSelector } from 'state/login/loginSelectors';
import { useSelector } from 'util/hooks/useSelector';
import NIKReadingPage from './pages/NIKReadingPage';

export const NEW_CONTENT_CONCEPT_PATH = 'problemstilling';

const NIKPage: FC = () => {
  const isAuthenticated = useSelector(authenticatedSelector);

  return (
    // TODO: Accomodate top error message on NIK page (CU-8693axeg8)
    <Layout hasToolbar={false} hasNikToolbar={isAuthenticated}>
      <Switch>
        <Route path={`/${NEW_CONTENT_CONCEPT_PATH}/draft/:document`}>
          <NIKReadingPage draft />
        </Route>
        <Route path={`/${NEW_CONTENT_CONCEPT_PATH}/:document`}>
          <NIKReadingPage />
        </Route>
      </Switch>
      {!isAuthenticated && (
        <Throw error={{ type: JuridikaErrorType.GRAPHQL_UNAUTHENTICATED }} />
      )}
    </Layout>
  );
};

export default NIKPage;
