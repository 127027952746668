import { match } from 'react-router-dom';
import { Location } from 'history';

export const NAVBAR_HEIGHT = 62;

export const NAVBAR_VIEWPORT_SIZE = {
  small: 0,
  medium: 875,
  large: 1600,
  xlarge: 1800,
};

/*
 *  TODO: usikker på hvor mye vi vinner på å bruke denne enumtypen
 * fremfor å bruke mediaqQueries. Per nå brukes denne kun i styles
 */

export enum NavigationType {
  desktopNavbar,
  mobileMenu,
}

export const isNavLinkActive = (match: match | null, location: Location<unknown>, nameOfMenuItems: string[]) => {
  if (!match) return false;

  // sett aktiv hvis URL inkluderer navnet på menypunktet (og ikke er rot-url)
  if (match.url !== '' && nameOfMenuItems.some((name) => location.pathname.includes(name))) {
    return true;
  }

  /*
   * TODO: lage funksjon som returnerer true om URL møter spesifikke mønster.
   * For eksempel burde lovkommentarer bli aktiv når URL matcher \/no\/.*\/\d{4}-\d+-\d+-\d+
   * */

  return match.isExact;
};
