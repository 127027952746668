import { css, CSSProp } from 'styled-components';

import { SPACING_SCALE } from 'theme/config/defaultTheme';
import SpacingPropTypes from 'models/SpacingPropTypes';

function createSpacing(props: SpacingPropTypes & { [key: string]: any }): CSSProp {
  const propRegex = /^[whmp][trblxy]?-?([0-9]|Auto)?$/;
  const replaceRegex = /^[whmp][trblxy]?/;

  const shorthands: { [key: string]: string } = {
    w: 'width',
    h: 'height',
    m: 'margin',
    p: 'padding',
  };

  const directions: { [key: string]: Array<string> } = {
    t: ['-top'],
    r: ['-right'],
    b: ['-bottom'],
    l: ['-left'],
    x: ['-left', '-right'],
    y: ['-top', '-bottom'],
  };

  return css`
    ${Object.keys(props).reduce((a, c) => {
      if (propRegex.test(c)) {
        const direction = directions[c.charAt(1)] || [''];

        let value = props[c];

        if (value === true) {
          if (c.includes('Auto')) {
            value = 'auto';
          } else {
            // Is boolean (like p3) -> extract value
            const spacingValue = parseInt(c.replace(replaceRegex, ''), 10);
            value = SPACING_SCALE[spacingValue];
          }
        } else if (typeof value === 'number') {
          value = SPACING_SCALE[value];
        }

        const addStyles = direction.reduce((a2, dir) => `${a2}${shorthands[c.charAt(0)]}${dir}: ${value};`, '');

        return `${a}${addStyles}`;
      }

      return a;
    }, '')}
  `;
}

export default createSpacing;
