import * as React from 'react';
import URI from 'urijs';

export enum Target {
  WEB,
  NODE,
}

export enum Build {
  RELEASE,
  DEBUG,
}

export interface ConfigVariables {
  [key: string]: string | undefined;
}

export const HOST = 'HOST';
export const PORT = 'PORT';
export const EXTERNAL_PORT = 'EXTERNAL_PORT';
export const SCHEME = 'SCHEME';
export const JURIDIKA_LISTINGS_BASE_URL = 'JURIDIKA_LISTINGS_BASE_URL';
export const CLOUDFRONT_STATIC_CONTENT_FILES_URL = 'CLOUDFRONT_STATIC_CONTENT_FILES_URL';
export const STATIC_CONTENT_FILES_S3_BUCKET = 'STATIC_CONTENT_FILES_S3_BUCKET';
export const CLOUDFRONT_LITERATURE_FILES_PUBLIC_URL = 'CLOUDFRONT_LITERATURE_FILES_PUBLIC_URL';
export const LITERATURE_FILES_PUBLIC_S3_BUCKET = 'LITERATURE_FILES_PUBLIC_S3_BUCKET';
export const AUTH0_CLIENT_ID = 'AUTH0_CLIENT_ID';
export const AUTH0_CLIENT_SECRET = 'AUTH0_CLIENT_SECRET';
export const AUTH0_DOMAIN = 'AUTH0_DOMAIN';
export const AUTH0_SESSION_SECRET = 'SESSION_SECRET';
export const FACEBOOK_APP_ID = 'FACEBOOK_APP_ID';
export const GA_TRACKING_ID = 'GA_TRACKING_ID';
export const HUBSPOT_API_KEY = 'HUBSPOT_API_KEY';
export const ROBOTS_PREVENT_INDEXING = 'ROBOTS_PREVENT_INDEXING';
export const GOOGLE_SITE_VERIFICATION_FILE = 'GOOGLE_SITE_VERIFICATION_FILE';
export const SUPPORT_EMAIL = 'SUPPORT_EMAIL';
export const CONTACT_FORM_API_KEY = 'CONTACT_FORM_API_KEY';
export const CONTACT_FORM_API_GATEWAY_BASE_URL = 'CONTACT_FORM_API_GATEWAY_BASE_URL';
export const MAIL_FWD_API_KEY = 'MAIL_FWD_API_KEY';
export const MAIL_FWD_API_GATEWAY_BASE_URL = 'MAIL_FWD_API_GATEWAY_BASE_URL';
export const SEND_TEMPLATED_EMAIL_API_KEY = 'SEND_TEMPLATED_EMAIL_API_KEY';
export const SEND_TEMPLATED_EMAIL_API_GATEWAY_BASE_URL = 'SEND_TEMPLATED_EMAIL_API_GATEWAY_BASE_URL';
export const UTDRAG_LAMBDA_FUNCTION_STAGE = 'UTDRAG_LAMBDA_FUNCTION_STAGE';
export const AWS_ACCESS_KEY_ID = 'AWS_ACCESS_KEY_ID';
export const AWS_SECRET_ACCESS_KEY = 'AWS_SECRET_ACCESS_KEY';
export const JURIDIKA_SUBSCRIPTIONS_BASE_URL = 'JURIDIKA_SUBSCRIPTIONS_BASE_URL';
export const JURIDIKA_AKADEMI_BACKEND_BASE_URL = 'JURIDIKA_AKADEMI_BACKEND_BASE_URL';
export const FLYT_BACKEND_BASE_URL = 'FLYT_BACKEND_BASE_URL';
export const JURIDIKA_PERSONALIZATION_BACKEND_URL = 'JURIDIKA_PERSONALIZATION_BACKEND_URL';
export const RUDDERSTACK_JURIDIKA_ROYALTYSTATISTIKK_BASIC_AUTH = 'RUDDERSTACK_JURIDIKA_ROYALTYSTATISTIKK_BASIC_AUTH';
export const RUDDERSTACK_JURIDIKA_BRUKERSTATISTIKK_BASIC_AUTH = 'RUDDERSTACK_JURIDIKA_BRUKERSTATISTIKK_BASIC_AUTH';
export const CMS_LIVE_PREVIEW_BASE_URL = 'CMS_LIVE_PREVIEW_BASE_URL';
export const DATADOG_APP_ID = 'DATADOG_APP_ID';
export const DATADOG_CLIENT_TOKEN = 'DATADOG_CLIENT_TOKEN';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore __CLIENT__ defined by webpack
const realTarget = typeof __CLIENT__ !== 'undefined' && !!__CLIENT__ ? Target.WEB : Target.NODE;

// NODE_ENV should always be set in process.env (see webpack.config.js)
const realBuild = process.env.NODE_ENV === 'development' ? Build.DEBUG : Build.RELEASE;

export class JuridikaConfig {
  readonly target: Target;

  readonly build: Build;

  private readonly variables: ConfigVariables;

  constructor(target: Target, build: Build, variables: ConfigVariables) {
    this.target = target;
    this.build = build;
    this.variables = variables;
  }

  static real(variables: ConfigVariables): JuridikaConfig {
    return new JuridikaConfig(realTarget, realBuild, variables);
  }

  static realClient(): JuridikaConfig {
    // take the object registered in <script> inside the Html component
    const variables = (window as any).CONFIG;
    delete (window as any).CONFIG;
    return JuridikaConfig.real(variables);
  }

  static testServerDebug(variables: ConfigVariables): JuridikaConfig {
    return new JuridikaConfig(Target.NODE, Build.DEBUG, variables);
  }

  static testServerRelease(variables: ConfigVariables): JuridikaConfig {
    return new JuridikaConfig(Target.NODE, Build.RELEASE, variables);
  }

  static testClientDebug(variables: ConfigVariables): JuridikaConfig {
    return new JuridikaConfig(Target.WEB, Build.DEBUG, variables);
  }

  get isServer(): boolean {
    return this.target === Target.NODE;
  }

  get isClient(): boolean {
    return this.target === Target.WEB;
  }

  get isRelease(): boolean {
    return this.build === Build.RELEASE;
  }

  get isDebug(): boolean {
    return this.build === Build.DEBUG;
  }

  // eslint-disable-next-line class-methods-use-this
  get searchTypeDelay(): number {
    return 300;
  }

  /** The public, configured absolute base URL for juridika frontend. */
  juridikaAbsoluteBaseUrl(): URI {
    return URI('')
      .scheme(this.get(SCHEME) || 'https')
      .host(this.get(HOST) || 'localhost')
      .port(this.get(EXTERNAL_PORT) || '');
  }

  auth0DomainBaseUrl(): URI {
    return URI('').scheme('https').host(this.AUTH0_DOMAIN);
  }

  get AUTH0_DOMAIN(): string {
    return this.getString(AUTH0_DOMAIN);
  }

  get AUTH0_CLIENT_ID(): string {
    return this.getString(AUTH0_CLIENT_ID);
  }

  get FACEBOOK_APP_ID(): string | undefined {
    return this.get(FACEBOOK_APP_ID);
  }

  get SUPPORT_EMAIL(): string {
    return this.getString(SUPPORT_EMAIL);
  }

  get JURIDIKA_LISTINGS_BASE_URL(): string {
    return this.getString(JURIDIKA_LISTINGS_BASE_URL);
  }

  get(key: string): string | undefined {
    return this.variables[key];
  }

  getAllVariables(): ConfigVariables {
    return this.variables;
  }

  getString(key: string): string {
    const value = this.variables[key];
    if (!value) {
      throw new Error(`ConfigVariable ${key} is not defined`);
    }
    return value;
  }

  getBoolean(key: string): boolean {
    const value = this.variables[key];
    return value === 'true';
  }

  isLocalEnv(): boolean {
    const host = this.variables.HOST;
    return host === 'localhost';
  }
}

export const JuridikaConfigContext = React.createContext<JuridikaConfig>(new JuridikaConfig(Target.NODE, Build.DEBUG, {}));

export const useJuridikaConfig = (): JuridikaConfig => {
  return React.useContext(JuridikaConfigContext);
};
