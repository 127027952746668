import * as React from 'react';
import styled from 'styled-components';

const BarCss = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.purple[300]};
  height: 0.25rem;
  border-radius: 1rem;
  flex: 2;
  width: 100%;

  position: relative;

  :hover span {
    visibility: visible;
  }
`;

const ColoredAreaCss = styled.div<{ percentComplete?: number }>`
  background-color: ${({ theme }) => theme.colorTokens.purple[700]};
  border-radius: inherit;
  width: ${({ percentComplete }) => percentComplete}%;
  height: 100%;
`;

const TooltipCss = styled.span<{ $widthOfElement: number; $percentComplete: number }>`
  visibility: hidden;

  background-color: ${({ theme }) => theme.colorTokens.purple[600]};
  color: ${({ theme }) => theme.colorTokens.default.white};
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 0.875rem;

  position: absolute;
  z-index: 1;
  bottom: 0.4375rem;
  left: ${({ $percentComplete }) => `${$percentComplete}%`};
  margin-left: ${({ $widthOfElement }) => `-${$widthOfElement}px`};
`;

// Also need to show part tooltip for chapters and course instead of percent
const Progressbar: React.FC<{ progress: number; className?: string }> = ({ progress, className }) => {
  const myref = React.useRef<HTMLSpanElement>(null);
  const percentComplete = progress;
  const widthOfElement = myref?.current?.offsetWidth || 0;

  return (
    <BarCss
      role="progressbar"
      className={className || ''}
      aria-label="Progresjonsbar"
      aria-valuetext={`${percentComplete}% fullført.`}
    >
      <ColoredAreaCss percentComplete={percentComplete}>
        <TooltipCss $widthOfElement={widthOfElement} $percentComplete={percentComplete} ref={myref}>
          {percentComplete}%
        </TooltipCss>
      </ColoredAreaCss>
    </BarCss>
  );
};

export default Progressbar;
