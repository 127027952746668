import Flex from 'components/ui/Flex';
import Modal from 'components/ui/Modal/Modal';
import { inputWithoutStyle } from 'styleMixins/listMixins';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { AcademyIconButton3 } from '../IconButtons/IconButtons';
import { PrimaryButtonPurple } from '../../../../components/AcademyAndNIK/Buttons/ButtonsPurple';

export const AcademyIconButton3Css = styled(AcademyIconButton3)`
  margin-left: auto;
`;

export const ModalContentCss = styled.div`
  :focus-visible {
    outline: none;
  }
`;

export const ModalCss = styled(Modal)`
  > div {
    height: auto;
    padding: 1.25rem;
    box-shadow: 0 2px 4px 0 #00000040;
    border-radius: 0.5rem;
    max-width: 37.5rem;
  }
`;

export const TitleCss = styled.h2`
  font-weight: 600;
  margin: 0;
`;

export const InputCss = styled.input<{ $selected: boolean }>`
  ${inputWithoutStyle}

  width: 100%;
  border: 0.5px solid grey;
  border-radius: 0.25rem;
  padding: 0.25rem 0.625rem;
  font-size: 1.125rem;

  ::first-line {
    background-color: ${({ $selected, theme }) => ($selected ? theme.colorTokens.blue[100] : theme.colorTokens.default.white)};
  }
`;

export const ButtonCss = styled(PrimaryButtonPurple)`
  margin-top: 0.625rem;

  ${createMediaQuery(
    575,
    css`
      flex-direction: row;
      margin-top: 0;
      margin-left: 0.5rem;
    `
  )}
`;

export const HeaderCss = styled(Flex)`
  margin-bottom: 1.25rem;
`;

export const ContentCss = styled(Flex)`
  flex-direction: column;

  ${createMediaQuery(
    575,
    css`
      flex-direction: row;
    `
  )}
`;
