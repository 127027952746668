import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

interface Props {
  showHeadingLine?: boolean;
}

const ResourceStickerContentLoader: FC<Props> = ({
  showHeadingLine = true,
}) => {
  const headingVerticalOffset = showHeadingLine ? 64 : 0;
  const theme = useTheme();

  return (
    <ContentLoader
      viewBox={`0 0 380 ${208 + headingVerticalOffset}`}
      height={272}
      width={380}
      primaryColor={theme.colorTokens.grey[600]}
      secondaryColor="#CBCBCB"
      speed={1}
    >
      {/* Resembling a heading */}
      {showHeadingLine && (
        <rect x="0" y="0" rx="3" ry="3" width="240" height="16" />
      )}

      {/* Resembling 7 text lines */}
      <rect
        x="0"
        y={0 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="380"
        height="16"
      />
      <rect
        x="0"
        y={32 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="340"
        height="16"
      />
      <rect
        x="0"
        y={64 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="380"
        height="16"
      />
      <rect
        x="0"
        y={96 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="340"
        height="16"
      />
      <rect
        x="0"
        y={128 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="380"
        height="16"
      />
      <rect
        x="0"
        y={160 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="340"
        height="16"
      />
      <rect
        x="0"
        y={192 + headingVerticalOffset}
        rx="3"
        ry="3"
        width="380"
        height="16"
      />
    </ContentLoader>
  );
};

export default ResourceStickerContentLoader;
