import React, { FC, useEffect, useRef } from 'react';
import Cross2 from 'components/icons/Cross2';
import styled from 'styled-components';
import useFocusTrap from 'util/hooks/useFocusTrap';
import { useHistory, useLocation } from 'react-router';
import Flex from 'components/ui/Flex';
import { ButtonCss, HeaderCss, ModalCss, TitleCss } from '../ShareCourseModal/Styles';
import { AcademyIconButton3 } from '../IconButtons/IconButtons';

const BodyTextCss = styled.p`
  margin: 0;
  margin-top: 0.25rem;
  font-size: 1.125rem;
`;

const GoToQuizButtonCss = styled(ButtonCss)`
  margin: 0;
  margin-left: 0 !important;
  margin-right: 0.69rem;
`;

const ModalContentCss = styled.div`
  :focus-visible {
    outline: none;
  }
`;

interface FinishedCourseModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  course: { title: string; slug: string };
}

const FinishedCourseModal: FC<FinishedCourseModalProps> = ({ open, setOpen, course }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();
  useFocusTrap(modalRef);

  const dismissModal = () => {
    setOpen(false);
    localStorage.setItem('finishedCourseModalIsDismissed', 'true');
  };

  const goToQuiz = () => {
    dismissModal();
    history.push(`/akademi/kurs/${course.slug}/quiz-og-kursbevis/quiz`);
  };

  useEffect(() => {
    // modal should not be displayed on the page it links to
    if (location.pathname === `/akademi/kurs/${course.slug}/quiz-og-kursbevis/quiz`) {
      setOpen(false);
    }
  }, [course.slug, location.pathname, setOpen]);

  return (
    <ModalCss ariaLabelledbyId="finished-course-alert-module" isOpen={open}>
      <ModalContentCss ref={modalRef} tabIndex={-1}>
        <HeaderCss align="flex-start" justify="space-between">
          <div>
            <TitleCss>Alle kursdeler fullført</TitleCss>
            <BodyTextCss>
              Du har fullført alle kursdelene for {course.title}. For å kunne få kursbevis må du ta en avsluttende quiz.
            </BodyTextCss>
          </div>
          <AcademyIconButton3 icon={<Cross2 />} onClick={dismissModal} />
        </HeaderCss>
        <Flex>
          <GoToQuizButtonCss onClick={goToQuiz} label="Ta quiz" />
        </Flex>
      </ModalContentCss>
    </ModalCss>
  );
};

export default FinishedCourseModal;
