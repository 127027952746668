import * as React from 'react';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

import { SelectedTextInfoObjectType } from '../../models/SelectedTextInfoObject';
import { SelectedText } from '../../components/MarkedTextTooltip';
import { generateCitation, getTooltipCords } from '../citationHelpers';

interface ToolTipCoords {
  x: number | null;
  y: number | null;
}

interface TextSelectionProps {
  showToolTip: boolean;
  setShowToolTip: (value: boolean) => void;
  toolTipCoords: ToolTipCoords;
  selectedText: SelectedText | null;
  handleTextSelection: (selectedTextInfoObject: SelectedTextInfoObjectType) => void;
}

export const useTextSelection = (): TextSelectionProps => {
  const juridikaConfig = useJuridikaConfig();
  const [showToolTip, setShowToolTip] = React.useState(false);
  const [toolTipCoords, setToolTipCoords] = React.useState<ToolTipCoords>({
    x: null,
    y: null,
  });
  const [selectedText, setSelectedText] = React.useState<SelectedText | null>(null);
  let containerElId: string | null;

  const handleTextSelection = (selectedTextInfoObject: SelectedTextInfoObjectType) => {
    const selection: Selection | null = window.getSelection();
    const parentElement = selection?.anchorNode?.parentElement;
    const text = selection ? selection.toString() : '';

    // FIXME: Why does this code belong here? This is quite non-cohesive...
    switch (selectedTextInfoObject.source) {
      case 'article':
      case 'pdf':
        containerElId = '.pdfViewer';
        break;
      case 'commentary':
        containerElId = '#commentaryPanel .inner-container';
        break;
      case 'legalDocument':
        containerElId = '#legalDocumentPanel .inner-container';
        break;
      case 'bits':
        containerElId = null;
        break;
    }

    if (!selection || text === '') return;

    const containerEl = containerElId ? document.querySelector(containerElId) : null;
    const citation = generateCitation(selectedTextInfoObject, selection, juridikaConfig, parentElement);
    const { toolTipX, toolTipY, range } = getTooltipCords(containerEl);

    const htmlElement = document.createElement('div');
    htmlElement.appendChild(range.cloneContents());

    setShowToolTip(true);
    setToolTipCoords({ x: toolTipX, y: toolTipY });
    setSelectedText({
      text,
      html: htmlElement,
      citation,
    });
  };

  return {
    showToolTip,
    setShowToolTip,
    toolTipCoords,
    selectedText,
    handleTextSelection,
  };
};
