const MONTHS: { [month: number]: string } = {
  0: 'januar',
  1: 'februar',
  2: 'mars',
  3: 'april',
  4: 'mai',
  5: 'juni',
  6: 'juli',
  7: 'august',
  8: 'september',
  9: 'oktober',
  10: 'november',
  11: 'desember',
};

export const addLeadingZeros = (n: number): string => String(`00${n}`).slice(-2);

const getDay = (date: Date): string => addLeadingZeros(date.getDate());

export const formatDate = (date: Date | string, dayToNotStartWithZero?: boolean): string => {
  const typecheckedDate = typeof date === 'string' ? new Date(date) : date;

  let day = getDay(typecheckedDate);

  if (dayToNotStartWithZero) {
    day = String(typecheckedDate.getDate());
  }

  const month = MONTHS[typecheckedDate.getMonth()];
  const year = typecheckedDate.getFullYear();

  return `${day}. ${month} ${year}`;
};

export class DateFormatter extends Date {
  hhmm = (): string => {
    return this.toLocaleTimeString('no-NO', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  dayAndDate = (): string => {
    return this.toLocaleDateString('no-NO', { weekday: 'long', day: 'numeric', month: 'long' });
  };
}
// Migrated these unchanged from DateLastUpdated.tsx
export const stripTimeFromISO8601Date = (fullISO8601Date: string): string => {
  const date = fullISO8601Date.split('T')[0];
  return date;
};

export const convertISO8601DateToDDMMYYYY = (ISO8601Date: string): string => {
  const parts = ISO8601Date.split('-');

  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  return `${day}.${month}.${year}`;
};
