import React, { FC } from 'react';
import styled from 'styled-components';
import Checked from 'icons/Checked';
import { listWithoutStyle } from '../../../styleMixins/listMixins';

const productsWithSalesPitch = new Map();

productsWithSalesPitch
  .set('comments', 'Mer enn 75 ajourførte blå lovkommentarer fra Universitetsforlaget, med flere på vei')
  .set('comments_digital_only', 'Tilgang til smalere lovkommentarer som kun publiseres digitalt')
  .set('textbooks', 'Over 300 juridiske fagbøker fra eksperter du stoler på')
  .set(
    'journals',
    'Tilgang til nesten samtlige norske juridiske tidsskrifter (15 stk.), pluss 4 tidsskrifter innen beslektede fagområder'
  )
  .set('excerpt', 'Utdrag til PDF og utklipp med automatiske kildehenvisninger fra alle lovkommentarer, fagbøker og tidsskrifter')
  .set('insight', 'Juridika Innsikt – vår juridiske nyhetstjeneste')
  .set('lovdataLink', 'Toveis direktelenking mellom innhold på Juridika og Lovdata Pro')
  .set(
    'frontlist',
    'Mulig å bestille tilleggspakken "Nye bøker". Les de nyeste jussbøkene på nett allerede samme dag som papirutgaven kommer ut!'
  );

const ListOfProductsWithSalesPitch: FC = () => {
  return (
    <WrapperCss>
      {Array.from(productsWithSalesPitch.entries()).map(([key, value]) => (
        <li key={key}>
          <span>
            <Checked />
          </span>
          <span>{value}</span>
        </li>
      ))}
    </WrapperCss>
  );
};

export const WrapperCss = styled.ul`
  ${listWithoutStyle}

  margin: 1.5rem 0;

  li {
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;
  }

  span:first-child {
    margin: 0.1875rem 0.75rem 0 0;
  }

  svg {
    width: 1.125rem;
    height: 0.875rem;
  }
`;

export default ListOfProductsWithSalesPitch;
