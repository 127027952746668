import styled, { css } from 'styled-components';
import { FONT_FAMILIES } from 'theme/config/defaultTheme';
import createFontStyles from 'util/createFontStyles';
import createMediaQuery from 'util/createMediaQuery';

export interface ParagraphType {
  isDispQuote?: boolean;
  isPetit?: boolean;
  isMarginalia?: boolean;
  isList?: boolean;
  isFigure?: boolean;
}

export interface Config {
  // Wether `x` elements (generated content) should be rendered
  displayX?: boolean;
  listType?: string;
  paragraphType?: ParagraphType;
  ignoreTarget?: boolean;
  // Indent left and right of text
  indentLevels: [number, number];
}

const STANDARD_FONT_SIZE = 1.125;

/* Font styles */
export const BitsHeadingOne = styled.h1<{ isPartType?: boolean }>`
  text-transform: ${(props) => props.isPartType && 'uppercase'};
  ${(props) =>
    createFontStyles({
      family: FONT_FAMILIES.primary,
      size: 1.75,
      weight: 400,
      mediaQueries: [{ query: 'sm', size: props.isPartType ? 2.625 : 2.375 }],
    })}
`;

export const BitsHeadingTwo = styled.h2`
  ${createFontStyles({
    family: FONT_FAMILIES.primary,
    size: 1.5625,
    weight: 500,
    mediaQueries: [{ query: 'sm', size: 1.65 }],
  })}
`;

export const BitsHeadingThree = styled.h3`
  ${createFontStyles({
    family: FONT_FAMILIES.primary,
    size: 1.25,
    weight: 500,
    mediaQueries: [{ query: 'sm', size: 1.25 }],
  })}
`;

export const BitsHeadingFour = styled.h4`
  ${createFontStyles({
    family: FONT_FAMILIES.primary,
    size: 1.25,
    weight: 500,
    lineHeight: 1.25,
  })}
`;

export const dispQuoteStyle = css`
  font-style: italic;
  margin-top: 0;
  ${createFontStyles({
    family: FONT_FAMILIES.tertiary,
    size: 1,
    weight: 600,
    lineHeight: 1.375,
  })}
`;

const secondaryFontStyle = (lineHight: number, size: number) => {
  return css`
    ${createFontStyles({
      family: FONT_FAMILIES.secondary,
      lineHeight: lineHight,
      size,
      weight: 400,
    })}
  `;
};

export const primaryFontStyle = (size: number) => {
  return css`
    ${createFontStyles({
      family: FONT_FAMILIES.primary,
      size,
      weight: 400,
    })}
  `;
};

export const captionStyle = css`
  font-style: italic;
  ${secondaryFontStyle(1.25, 0.875)};
`;

export const petitFontStyle = secondaryFontStyle(1.125, 0.875);

/* Disp Quote */
export const BitsDispQuote = styled.div<{ quoteWithinQuote?: boolean }>`
  margin-top: 0.275rem;
  margin-left: ${(props) => (props.quoteWithinQuote ? '2rem' : ' 3.5rem;')};

  display: block;
  margin-right: 0;

  li span,
  li ::marker {
    ${dispQuoteStyle}
  }
`;

/* Graphic */
export const GraphicWrapper = styled.div`
  margin-left: 1.5rem;
`;

export const BitsFigCaption = styled.figcaption`
  margin: 0.3125rem 0 1rem 1.5rem;
  ${captionStyle}
`;

/* Caption */
export const BitsCaption = styled.caption`
  font-style: italic;
  ${captionStyle}
`;

/* Table */
export const BitsTableHorizontalScroll = styled.div`
  overflow-x: auto;
  margin-left: 1.65rem;
  ${createMediaQuery(
    890,
    css`
      overflow-x: initial;
    `
  )}
`;

export const BitsTable = styled.table<{ paragraphType?: ParagraphType }>`
  border-collapse: collapse;
  margin-bottom: 1rem;
  ${(props) => {
    if (props.paragraphType?.isPetit) return petitFontStyle;
    else return secondaryFontStyle(1.375, STANDARD_FONT_SIZE);
  }}
  caption {
    text-align: left;
    margin: 1rem 0;
  }
`;

export const BitsTableHead = styled.thead`
  tr {
    background-color: ${(props) => props.theme.colorTokens.default.white} !important;
  }

  td {
    font-weight: 600;
  }
`;

export const BitsTableBody = styled.tbody`
  th {
    padding-right: 1rem;
  }
`;

export const BitsTableRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colorTokens.grey[300]};

    td:not(:last-child) {
      border-right: 2px solid white;
    }
  }
`;

export const BitsTableDataCell = styled.td<{ paragraphType?: ParagraphType }>`
  padding: ${(props) => (props.paragraphType?.isPetit ? '0.4rem' : '0.625rem')};
  font-weight: 400;
`;

export const BitsTableHeading = styled.th`
  background-color: ${(props) => props.theme.colorTokens.default.white};
  padding: 0.625rem;
  font-weight: 600;
  td {
    font-weight: 600;
  }
`;

/* Footnote */
export const FootnoteToggleButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

export const FootnoteBox = styled.span<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'block' : 'none')};
  overflow: hidden;
  margin: 0.75rem 0;
  padding: 1rem;
  background: ${(props) => props.theme.colorTokens.peach[300]};

  ${createFontStyles({
    lineHeight: 1.375,
    size: 0.938,
  })}
`;

export const FootnoteBoxSymbol = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  vertical-align: top;
  padding-right: 0.3rem;
`;

/* List */
export const RefList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-left: 1.5;
`;

// An item in a ref-list
export const Ref = styled.li`
  margin: 2rem 0;
`;

export const ListWrapper = styled.div<{ isTopLevel: boolean }>`
  margin-left: ${(props) => (props.isTopLevel ? '1.5rem;' : '0')};
`;

export const OrderedList = styled.ol`
  margin-top: 0;
`;

export const OrderedListItem = styled.li<{ paragraphType?: ParagraphType }>`
  ${(props) => {
    if (props.paragraphType?.isPetit) {
      return css`
        ::marker {
          ${petitFontStyle}
        }
      `;
    }
  }}
`;

export const SimpleList = styled.ul`
  list-style-type: none;
  position: relative;
  margin-top: 0;
`;

export const SimpleListLabel = styled.span<{ paragraphType?: ParagraphType }>`
  position: absolute;
  text-align: right;
  left: 0.1rem;
  width: 2.2rem;
  height: 100%;
  ${(props) => {
    if (props.paragraphType?.isPetit) {
      return css`
        ${petitFontStyle}
      `;
    } else {
      return css`
        font-size: ${STANDARD_FONT_SIZE}rem;
      `;
    }
  }}
`;

/* Paragraph */
export const BitsParagraph = styled.p<{ paragraphType?: ParagraphType }>`
  position: relative;
  ${(props) => {
    if (props.paragraphType?.isPetit) {
      return css`
        max-width: 38.6875rem;
        margin-top: 0.375rem;
        padding-top: 0;
        margin-left: ${props.paragraphType?.isList ? '0' : '1.65rem'};
        ${petitFontStyle}
      `;
    } else if (props.paragraphType?.isMarginalia) {
      return css`
        ${secondaryFontStyle(1.125, 0.875)}
      `;
    } else if (props.paragraphType?.isDispQuote && !props.paragraphType?.isList) {
      return dispQuoteStyle;
    } else if (props.paragraphType?.isList) {
      return css`
        max-width: 38.6875rem;
        margin-bottom: 0.625rem;
        margin-top: 0;
        margin-left: 0;
        ${secondaryFontStyle(1.125, STANDARD_FONT_SIZE)}
      `;
    } else if (props.paragraphType?.isFigure) {
      return css`
        display: inline;
        ${captionStyle};
      `;
    } else {
      return css`
        max-width: 38.6875rem;
        margin-top: 0;
        padding-top: 0.0625rem;
        margin-left: 1.65rem;
        ${secondaryFontStyle(1.5, STANDARD_FONT_SIZE)}
      `;
    }
  }}
`;
