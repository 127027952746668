import React, { FC } from 'react';
import IconPropTypes from './IconPropTypes';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import Svg from 'components/ui/Svg';

const Exit: FC<IconPropTypes> = ({ color, size, ...props }) => (
  <Svg
    className="c-icon c-icon__exit"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    fill={color}
    {...props}
  >
    <g id="icomoon-ignore" />
    <path
      d={`
        M384 320v-64h-160v-64h160v-64l96 96zM352
        288v128h-160v96l-192-96v-416h352v160h-32v-128h-256l128
        64v288h128v-96z
      `}
    />
  </Svg>
);

Exit.defaultProps = {
  color: COLOR_TOKENS.default.black,
  size: 24,
};
export default Exit;
