import URI from 'urijs';
import { AMAZON_S3_DOMAIN } from 'commonUtils/constants';
import {
  CLOUDFRONT_STATIC_CONTENT_FILES_URL,
  STATIC_CONTENT_FILES_S3_BUCKET,
  useJuridikaConfig,
} from 'commonUtils/juridikaConfig';

import { convertFragmentIdToUrlPath, countryCodeToFragmentProfile } from 'util/fragmentUrlPathConversion';
import { PathParams, RequestedContent, SanitizedPathParams } from 'pages/LegalDocumentPage/types';

export const AUTH_CALLBACK_PATHNAME = '/auth/callback';
export const IP_CALLBACK_PATHNAME = '/ip/callback';
export const LOGOUT_PATHNAME = '/logout';
export const COMPLETE_SIGNUP_PATHNAME = '/completeSignup';

export const getReloadUrlPath = (): string => URI('/').segment('redirect').addQuery('ref', window.location.href).toString();

const augmentUriQuery = (path: URI, additionalQueryArgs: { [key: string]: string }): URI =>
  path.setQuery({ ...path.query(true), ...additionalQueryArgs });

export const legalDocumentLinkURI = (
  { countryCode, subtype, date, seq, inForceAt, languageCode, fragmentId, shouldDisplayComment = false }: RequestedContent,
  query: Object | null = null,
  urlHash: string | null = null
): URI => {
  let path = URI('/');

  if (countryCode) {
    path.segment(countryCode);
  }

  path.segment(subtype).segment(seq === 0 ? date : [date, seq].join('-'));

  if (languageCode) {
    path.segment(languageCode);
  }
  if (inForceAt) {
    path.segment(inForceAt);
  }

  path
    .setQuery(query as Object) // it actually accepts null, but type definitions are incorrect
    .hash(urlHash as string); // it actually accepts null, but type definitions are incorrect

  if (fragmentId) {
    const fragmentPath = convertFragmentIdToUrlPath(fragmentId, countryCodeToFragmentProfile(countryCode));
    path = fragmentPath !== undefined ? path.segment(fragmentPath) : augmentUriQuery(path, { fragment: fragmentId });
  }

  if (shouldDisplayComment) {
    path = path.segment('kommentar');
  }

  return path;
};

export const legalDocumentPath = (
  requestedContent: RequestedContent,
  query: Object | null = null,
  urlHash: string | null = null
): string => legalDocumentLinkURI(requestedContent, query, urlHash).toString();

export const legalDocumentLatestExpressionLink = (
  requestedContent: RequestedContent,
  query: Object | null = null,
  urlHash: string | null = null
) => {
  return legalDocumentPath({ ...requestedContent, inForceAt: undefined }, query, urlHash);
};

export const sanitizeLegalDocumentPathParams = (unsanitizedParams: PathParams): SanitizedPathParams => {
  /**
   * Normally docId is of the form YYYY-MM-DD-S (where S can be several digits).
   * But we also need to support the case YYYY-MM-DD.
   * This is actually a valid ID, but these cases are currently not directly
   * supported by the rest of Juridika, but are instead represented by a
   * sequence number of 0. So we need to translate IDs with no seq to have a
   * seq of 0.
   * Example: lov/1968-11-29 (Servituttlova) has no sequence number.
   */
  const sanitizeDocId = (docId: string): string => {
    return docId.split('-').length === 3 ? `${docId}-0` : docId;
  };

  const unsanitizedDocId = unsanitizedParams.docId;
  const params: SanitizedPathParams = {
    ...unsanitizedParams,
    unsanitizedDocId,
    docId: sanitizeDocId(unsanitizedDocId),
  };
  return params;
};

/**
 * In order to use the cloudfront distribution for static content images typically used in published notices and pages, we override the img src prop.
 * There is no guarantee that an image in a published notice or page resides in our own s3 bucket, or even in the right "environment".
 * If the image src matches this environment's s3 static content bucket, we will use the cloudfront distribution
 */
export const staticContentImageUrlToCloudfrontUrl = (staticContentImageUrl: string): string => {
  const juridikaConfig = useJuridikaConfig();
  const staticContentFilesBucket = juridikaConfig.get(STATIC_CONTENT_FILES_S3_BUCKET);
  const s3StaticContentImagePath = `https://${staticContentFilesBucket}.${AMAZON_S3_DOMAIN}/image/`;

  if (staticContentImageUrl.indexOf(s3StaticContentImagePath) > -1) {
    const cloudFrontUrl = juridikaConfig.get(CLOUDFRONT_STATIC_CONTENT_FILES_URL);
    return staticContentImageUrl.replace(s3StaticContentImagePath, `${cloudFrontUrl}/image/`);
  }
  // The image url does not match the aws static content bucket in this environment. Use original url.
  return staticContentImageUrl;
};
