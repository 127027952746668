import * as React from 'react';

import { JuridikaError } from 'commonUtils/models/JuridikaError';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { ErrorContext, ErrorContextValue } from './ErrorContext';
import { CatchContext } from './CatchContext';

/**
 * Interface used for error handling - drawing "error components".
 */

interface CatchProps {
  children?: React.ReactNode | React.ReactNode[];
  // a globally unique but typically semantic id of this catch
  catchId: string;
  // a function that renders an error.
  // NOTE: This function should _not_ be able to return null.
  // Don't be tempted to do <Catch renderError={MyErrorComponent} />.
  // Hooks will be broken.
  // Instead do:
  // <Catch renderError={props => <MyErrorComponent {...props} />} />.
  renderError: (props: ErrorHandlerProps) => React.ReactElement;
}
export interface ErrorHandlerProps {
  // what error occurred?
  error: JuridikaError;

  // callback to be invoked when retrying the throwing operation.
  // E.g. call this function as the click action of a retry button:
  // <button onClick={onRetry}>Try again</button>
  // The error view will then disappear, and the original crashing
  // component will try to redraw.
  onRetry: () => void;
}

const ensureServerCatchIdUnique = (catchId: string, serverErrorContext: ErrorContextValue) => {
  if (catchId in serverErrorContext.catchRegistry) {
    throw new Error(`${catchId} already defined in catchRegistry!`);
  }

  // Save the Catch in the registry, so we're able to report the error above.
  // eslint-disable-next-line no-param-reassign
  serverErrorContext.catchRegistry[catchId] = null;
};

const findFirstServerError = (errorContextValue: ErrorContextValue, catchId: string): JuridikaError | null => {
  const { serverErrorState } = errorContextValue;

  if (!serverErrorState || !(catchId in serverErrorState)) {
    return null;
  }

  // Return the first error reported.
  return serverErrorState[catchId][0];
};

const useErrorState = (
  errorContextValue: ErrorContextValue,
  catchId: string
): [JuridikaError | null, (error: JuridikaError | null) => void] => {
  if (useJuridikaConfig().isClient) {
    // Client mode: Just use state initialized with the server error.
    // The initialization is done because of initial hydration.
    return React.useState<JuridikaError | null>(() => findFirstServerError(errorContextValue, catchId));
  }

  // Server mode: We cannot use component state, because it's not persisted across several
  // SSR passes. So instead use ServerErrorContext and modify it directly.

  ensureServerCatchIdUnique(catchId, errorContextValue);

  const registerError = (error: JuridikaError | null) => {
    if (error !== null) {
      // eslint-disable-next-line no-param-reassign
      errorContextValue.serverErrorState[catchId] = [...(errorContextValue.serverErrorState[catchId] || []), error];
    }
  };

  return [findFirstServerError(errorContextValue, catchId), registerError];
};

/**
 * Catch any JuridikaError occurring in any sub-branch of the vDOM tree below.
 * If something <Throw/>s, the return value of renderError is drawn instead.
 */
export const Catch: React.FC<CatchProps> = ({ catchId, renderError, children }) => {
  const errorContextValue = React.useContext(ErrorContext);
  const [currentError, setCurrentError] = useErrorState(errorContextValue, catchId);
  if (currentError) {
    // Render ErrorComponent outside ErrorContext.Provider.
    // This is cool because then the ErrorComponent may re-Throw the error,
    // and it will be Catch-ed further up the tree :)
    return renderError({
      error: currentError,
      onRetry: () => setCurrentError(null),
    });
  }

  const catchError = (error: JuridikaError) => {
    errorContextValue.errorLogger(catchId, error);
    setCurrentError(error);
  };

  return <CatchContext.Provider value={{ catchError }}>{children}</CatchContext.Provider>;
};
