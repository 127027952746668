/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as ApolloClient from '@apollo/client';

import { JuridikaError, JuridikaErrorType } from 'commonUtils/models/JuridikaError';

import * as GqlError from './GqlError';

export enum Type {
  Loading,
  Ok,
  OkLoadingMore,
  ApolloError,
  GqlError,
  UnknownError,
  NotCalled,
}

interface Loading {
  readonly type: Type.Loading;
}

interface NotCalled {
  readonly type: Type.NotCalled;
}

export interface Ok<T> {
  readonly type: Type.Ok | Type.OkLoadingMore;
  readonly data: T;
}

interface ApolloErrorFailure {
  readonly type: Type.ApolloError;
  readonly error: ApolloClient.ApolloError;
}

interface GqlErrorFailure {
  readonly type: Type.GqlError;
  readonly error: GqlError.GqlError;
}

interface UnknownFailure {
  readonly type: Type.UnknownError;
}

/**
 * A "future": the result is either loading or ok.
 */
export type Future<T> = Loading | Ok<T> | NotCalled;

/**
 * A failure: the result failed to load.
 */
export type AnyFailure = ApolloErrorFailure | GqlErrorFailure | UnknownFailure;

/**
 * Data "not ok": either loading or error.
 */
export type NotOk = AnyFailure | Loading | NotCalled;

/**
 * Utility for functional programming on an apollo graphql result.
 */
export class Result<T> {
  readonly value: Future<T> | AnyFailure;

  constructor(value: Future<T> | AnyFailure) {
    this.value = value;
  }

  /**
   * Map the ok value into another type.
   */
  map = <U>(func: (arg: T, loadingMore: boolean) => U): Result<U> => {
    switch (this.value.type) {
      case Type.Ok:
      case Type.OkLoadingMore:
        return new Result<U>({
          type: Type.Ok,
          data: func(this.value.data, this.value.type === Type.OkLoadingMore),
        });
      default:
        return new Result(this.value);
    }
  };

  /**
   * Map the "Future" type (loading | ok) into ok.
   */
  mapFuture = <U>(func: (arg: Future<T>) => U): Result<U> => {
    switch (this.value.type) {
      case Type.Loading:
      case Type.NotCalled:
      case Type.Ok:
      case Type.OkLoadingMore:
        return new Result<U>({
          type: Type.Ok,
          data: func(this.value),
        });
      default:
        return new Result(this.value);
    }
  };

  mapOkLoadingMoreToLoading = (): Result<T> => {
    switch (this.value.type) {
      case Type.OkLoadingMore:
        return new Result({
          type: Type.Loading,
        });
      default:
        return this;
    }
  };

  /**
   * Map the "loading" type into ok.
   */
  mapLoading = (func: () => T): Result<T> => {
    switch (this.value.type) {
      case Type.Loading:
        return new Result({
          type: Type.Ok,
          data: func(),
        });
      default:
        return this;
    }
  };

  /**
   * Flat map the ok value into another Result.
   */
  flatMapOk = <U>(func: (arg: T) => Result<U>): Result<U> => {
    switch (this.value.type) {
      case Type.Ok:
      case Type.OkLoadingMore:
        return func(this.value.data);
      default:
        return new Result(this.value);
    }
  };

  /**
   * Map the "Future" type (loading | ok) into another Result.
   */
  flatMapFuture = <U>(func: (arg: Future<T>) => Result<U>): Result<U> => {
    switch (this.value.type) {
      case Type.Loading:
      case Type.NotCalled:
      case Type.Ok:
      case Type.OkLoadingMore:
        return func(this.value);
      default:
        return new Result(this.value);
    }
  };

  /**
   * Get the ok value, or else compute a fallback value.
   */
  getOrElseGet = (func: (arg: NotOk) => T): T => {
    switch (this.value.type) {
      case Type.Ok:
      case Type.OkLoadingMore:
        return this.value.data;
      default:
        return func(this.value);
    }
  };
}

export const gqlError = <T>(error: GqlError.GqlError | null): Result<T> => {
  const data: AnyFailure = error ? { type: Type.GqlError, error } : { type: Type.UnknownError };
  return new Result<T>(data);
};

export const unknownError = <T>(): Result<T> => {
  return new Result<T>({ type: Type.UnknownError });
};

export const ok = <T>(data: T): Result<T> => {
  return new Result<T>({ type: Type.Ok, data });
};

export const loading = <T>(): Result<T> => new Result<T>({ type: Type.Loading });

export const valueOfFuture = <T>(future: Future<T>): T | undefined => {
  if (future.type === Type.Ok) {
    return future.data;
  }

  return undefined;
};

export const isOk = <T>(data: Future<T> | AnyFailure): data is Ok<T> => data.type === Type.Ok || data.type === Type.OkLoadingMore;

export const notOkToJuridikaError = (notOk: NotOk): JuridikaError => {
  switch (notOk.type) {
    case Type.Loading:
      // This is normally not an error condition, but included for completeness.
      return { type: JuridikaErrorType.GRAPHQL_LOADING };
    // This is normally not an error condition, but included for completeness.
    case Type.NotCalled:
      return { type: JuridikaErrorType.GRAPHQL_NOT_CALLED };
    case Type.ApolloError:
      return {
        type: JuridikaErrorType.APOLLO,
        apolloError: notOk.error,
      };
    case Type.GqlError:
      return GqlError.gqlErrorToJuridikaError(notOk.error);
    case Type.UnknownError:
      return { type: JuridikaErrorType.GRAPHQL_UNKNOWN };
  }
};

export const join = <T>(r1: Result<Array<T>>, r2: Result<Array<T>>): Result<Array<T>> => {
  switch (r1.value.type) {
    case Type.Ok:
    case Type.OkLoadingMore:
      switch (r2.value.type) {
        case Type.Ok:
        case Type.OkLoadingMore:
          return new Result<Array<T>>({
            type: Type.Ok,
            data: r1.value.data.concat(r2.value.data),
          });
        default:
          return new Result(r2.value);
      }
    default:
      return new Result<Array<T>>(r1.value);
  }
};

/**
 * Interpret an apollo response as a GqlResult.
 */
export const of = <T>({
  loading,
  data,
  error,
  called,
}: {
  loading: boolean;
  data?: T;
  error?: ApolloClient.ApolloError;
  called?: boolean;
}): Result<T> => {
  if (error) {
    return new Result({
      type: Type.ApolloError,
      error,
    });
  }

  if (!data || Object.keys(data).length === 0) {
    if (loading) {
      return new Result({
        type: Type.Loading,
      });
    }

    if (called === false) {
      return new Result({
        type: Type.NotCalled,
      });
    }

    return new Result({
      type: Type.UnknownError,
    });
  }
  // @ts-ignore
  if (loading) {
    // @ts-ignore
    return new Result({
      type: Type.OkLoadingMore,
      data,
    });
  }
  // @ts-ignore
  return new Result({
    type: Type.Ok,
    data,
  });
};
