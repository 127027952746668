import { SwitchBaseCss, ThumbCss, TrackCss, TitleCss } from 'components/ToggleSwitch/Styles';
import { CheckboxCss as ButtonCss } from 'components/Checkbox/Checkbox';
import styled, { css } from 'styled-components';
import Cross from '../../../../../images/icon-cross-grey.svg';
import Checked from '../../../../../images/icon-checked-purple.svg';

export const SwitchBaseOverrideCss = styled(SwitchBaseCss)`
  top: 1px;
  left: 1px;
`;

export const TrackOverrideCss = styled(TrackCss)<{ $isChecked: boolean }>`
  border: none;
  background-color: ${({ theme }) => theme.colorTokens.grey[700]};

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: ${({ theme }) => theme.colorTokens.purple[700]};
    `}
`;

export const ThumbOverrideCss = styled(ThumbCss)`
  background-color: ${({ theme }) => theme.colorTokens.default.white};

  width: 1.3125rem;
  height: 1.3125rem;

  ::before {
    content: '';

    background-image: url(${Cross});

    ${({ $isChecked }) =>
      $isChecked &&
      css`
        background-image: url(${Checked});
      `}
  }
`;

export const ButtonOverrideCss = styled(ButtonCss)`
  width: 100%;

  :hover {
    opacity: 1;
  }

  :focus-visible {
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
  }

  :hover,
  :focus-visible {
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colorTokens.purple[200]};
  }
`;

export const TitleOverrideCss = styled(TitleCss)`
  font-weight: 400;
  font-size: 1rem;
`;
