import * as Redux from 'redux';
import { getType } from 'typesafe-actions';
import * as ReduxPersist from 'redux-persist';
import * as H from 'history';

import { createLogger } from 'commonUtils/log';
import { JuridikaConfig } from 'commonUtils/juridikaConfig';

import { RootState, ThunkDispatch } from 'state/types';
import * as jwtActions from 'state/jwt/jwtActions';
import { Tokens } from 'state/jwt/jwtActions';
import * as sessionActions from 'state/session/sessionActions';
import * as sessionThunkActions from 'state/session/sessionThunkActions';
import * as companySignupActions from 'state/companySignup/companySignupActions';
import { AuthenticationStatus } from 'state/login/types';
import { SessionState, TokenSource } from 'state/session/types';

import { decodeToken } from 'util/jwt/decodeToken';
import { getTokenExpirationDate } from 'util/jwt/getTokenExpirationDate';
import { AsyncFifoQueue } from 'util/asyncFifoQueue';
import { Auth0 } from 'util/auth0';
import * as urlHelpers from 'util/urlHelpers';

const log = createLogger('jwt');

type TimeoutHandle = any;

const awaitAll = async (...values: any[]) => {
  for (const value of values) {
    // eslint-disable-next-line no-await-in-loop
    await value;
  }
};

interface JwtMiddlewareOptions {
  tokenExpiryBufferMs: number;
}

// 15 mins "buffer" time. We will try to renew the token if time until expiry is less than this.
export const DEFAULT_TOKEN_EXPIRY_BUFFER_MS = 15 * 60 * 1000;

const _auth0ErrorCodes: { [key: string]: string } = {
  invalid_grant: 'Feil brukernavn eller passord',
};

interface TokenExpiry {
  timeUntilExpiry: number;
  timeUntilBufferExpiry: number;
}

const computeTokenExpiry = (accessToken: string, options: JwtMiddlewareOptions): TokenExpiry | null => {
  const decodedToken = decodeToken(accessToken);
  const expiryDate = getTokenExpirationDate(decodedToken);

  if (!expiryDate) return null;

  const timeUntilExpiry = expiryDate.valueOf() - new Date().valueOf();

  return {
    timeUntilExpiry,
    timeUntilBufferExpiry: timeUntilExpiry - options.tokenExpiryBufferMs,
  };
};

/**
 * Redux middleware for managing jwt state.
 *
 */
export const jwtMiddleware =
  (
    juridikaConfig: JuridikaConfig,
    auth0: Auth0,
    options: JwtMiddlewareOptions = {
      tokenExpiryBufferMs: DEFAULT_TOKEN_EXPIRY_BUFFER_MS,
    }
  ): Redux.Middleware<Record<string, unknown>, RootState, ThunkDispatch> =>
  (store) => {
    let scheduleHandle: TimeoutHandle;
    const refreshResultQueue = new AsyncFifoQueue<boolean>();

    return (next) => {
      const currentAccessToken = (): string | undefined => {
        return store.getState().session.accessToken;
      };

      const tryRefreshTokenSilently = async (previousTokenSource: TokenSource): Promise<boolean> => {
        log.info({ msg: 'Trying to start silent token renewal for token source', previousTokenSource });
        switch (previousTokenSource) {
          case TokenSource.USER:
          case TokenSource.SERVER:
          case TokenSource.FEIDE: {
            const resultPromise = refreshResultQueue.recv();
            store.dispatch(sessionThunkActions.ssoLogin(previousTokenSource, juridikaConfig, auth0));
            await resultPromise;
            return true;
          }
          case TokenSource.IP: {
            const resultPromise = refreshResultQueue.recv();
            store.dispatch(sessionThunkActions.refreshIpToken(juridikaConfig));
            await resultPromise;
            return true;
          }
        }
      };

      // Code that runs when we believe the token may be close to expiry date
      const performTokenExpiryCheck = async () => {
        const accessToken = currentAccessToken();
        if (!accessToken) {
          log.info(`perform token expiry check: found no token.`);
          const previousTokenSource = store.getState().session.tokenSource;
          if (previousTokenSource === undefined) {
            next(sessionActions.resetSession());
          } else {
            await tryRefreshTokenSilently(previousTokenSource);
          }

          return;
        }

        // The reducer performs the actual token check and reflects the result
        // to React
        next(jwtActions.checkAccessTokenValidity(accessToken));

        const { login, session } = store.getState();
        const isStillAuthenticated = login.jwtMiddlewareLastValidationResult.authStatus === AuthenticationStatus.AUTHENTICATED;

        const expiry = computeTokenExpiry(accessToken, options);

        if (session.tokenSource !== undefined && ((expiry && expiry.timeUntilBufferExpiry <= 1000) || !isStillAuthenticated)) {
          await tryRefreshTokenSilently(session.tokenSource);
        } else {
          scheduleExpiryCheck(accessToken);
        }
      };

      // Schedule an access token expiry check, a little while before we think that the token
      // will expire (the expiry buffer)
      const scheduleExpiryCheck = (accessToken: string) => {
        unscheduleExpiryCheck();

        const expiry = computeTokenExpiry(accessToken, options);
        if (!expiry) return;

        log.info(`Will check token expiry in ${expiry.timeUntilBufferExpiry / 1000}s`);

        if (expiry.timeUntilBufferExpiry <= 0) {
          performTokenExpiryCheck();
        } else {
          scheduleHandle = setTimeout(async () => {
            unscheduleExpiryCheck();
            await performTokenExpiryCheck();
          }, expiry.timeUntilBufferExpiry);
        }
      };

      const unscheduleExpiryCheck = () => {
        if (scheduleHandle) {
          clearTimeout(scheduleHandle);
        }
        scheduleHandle = null;
      };

      const registerTokens = async (tokens: Tokens) => {
        let result;

        if (!tokens.jwt.accessToken) {
          log.info({ tokens }, `registered new tokens without any access token :(`);
          result = next(jwtActions.acquireJwtFailure());
        } else {
          result = next(jwtActions.acquireJwtSuccess(tokens));
        }

        const { login } = store.getState();

        unscheduleExpiryCheck();
        if (tokens.jwt.accessToken && login.jwtMiddlewareLastValidationResult.authStatus === AuthenticationStatus.AUTHENTICATED) {
          scheduleExpiryCheck(tokens.jwt.accessToken);
        }

        await result;
      };

      const initUsingIpCallback = async (location: H.Location) => {
        log.info(location, 'initUsingIpCallback');
        await store.dispatch(sessionThunkActions.loginWithIp(juridikaConfig));
      };

      // The app is started normally, try to reuse persisted tokens, or refresh them if expired.
      const initUsingRehydratedSessionState = async (rehydratedSessionState: SessionState) => {
        log.info({ rehydratedSessionState }, 'initUsingRehydratedSessionState');

        const { tokenSource, ...auth0DecodedHash } = rehydratedSessionState;
        if (tokenSource === undefined) {
          // If there is no persisted tokenSource, there is no way we can
          // even use this middleware's logic to renew it.
          // So just reject it, and the user will have to log in again.
          await store.dispatch(jwtActions.acquireJwtFailure());
        } else {
          await registerTokens({ jwt: auth0DecodedHash, tokenSource });

          const { login } = store.getState();

          // if not authenticated yet, try to renew
          if (login.jwtMiddlewareLastValidationResult.authStatus !== AuthenticationStatus.AUTHENTICATED) {
            await tryRefreshTokenSilently(tokenSource);
          }
        }
      };

      // At this point, early on in the app lifecycle, we can start looking at our options
      // for logging in users.
      const init = async (rehydratedSessionState: SessionState) => {
        log.info(store.getState().router.location, 'jwt init start');
        const { location } = store.getState().router;

        if (location.pathname === urlHelpers.IP_CALLBACK_PATHNAME) {
          await initUsingIpCallback(location);
        } else {
          await initUsingRehydratedSessionState(rehydratedSessionState);
        }

        await store.dispatch(jwtActions.jwtMiddlewareInitialized());
      };

      // In case our timers are ruined while the browser tab is in the background,
      // Check and/or reschedule each time the tab reveals itself.
      // It should not be that expensive.
      document.addEventListener(
        'visibilitychange',
        () => {
          if (!document.hidden) {
            performTokenExpiryCheck();
          }
        },
        false
      );

      return (action) => {
        // Reduce first
        const result = next(action);

        // Then our own logic
        switch (action.type) {
          // The following event starts the show!
          case ReduxPersist.REHYDRATE: {
            // The rehydration event starts the show!
            return awaitAll(result, init(store.getState().session));
          }

          // Intercept JWTs from various sources:
          case getType(sessionActions.ssoLogin.success):
            refreshResultQueue.send(true);
            return awaitAll(result, registerTokens(action.payload));
          case getType(sessionActions.login.success):
            return awaitAll(result, registerTokens({ jwt: action.payload, tokenSource: TokenSource.USER }));
          case getType(sessionActions.loginWithFeide.success):
            return awaitAll(result, registerTokens({ jwt: action.payload, tokenSource: TokenSource.FEIDE }));
          case getType(sessionActions.refreshIpToken.success): {
            refreshResultQueue.send(true);
            return awaitAll(result, registerTokens({ jwt: action.payload, tokenSource: TokenSource.IP }));
          }
          case getType(sessionActions.loginWithIp.success):
            return awaitAll(result, registerTokens({ jwt: action.payload, tokenSource: TokenSource.IP }));
          case getType(sessionActions.ssoLogin.failure):
          case getType(sessionActions.refreshIpToken.failure): {
            log.info(`refresh token acquisition failed: ${action.type}`);
            refreshResultQueue.send(false);
            unscheduleExpiryCheck();
            return awaitAll(result, next(jwtActions.acquireJwtFailure()));
          }
          case getType(sessionActions.login.failure):
          case getType(sessionActions.loginWithFeide.failure):
          case getType(sessionActions.loginWithIp.failure):
          case getType(companySignupActions.completeSignupFailure):
            log.info(`token acquisition failed: ${action.type}`);
            unscheduleExpiryCheck();
            return awaitAll(result, next(jwtActions.acquireJwtFailure()));
          default:
            return result;
        }
      };
    };
  };
