import { useSelector } from 'react-redux';
import { RootState } from 'state/types';
import { Chapter, ChapterProgress, Module, ModuleProgress } from './types';
import { TranscriptEntry } from './components/Transcript/Transcript';
import { CourseContent, getModulesInChapter } from './utils/academyCourseContentContext';

// TODO: AKADEMI remove when ts target lib is >= es2022
declare global {
  interface Array<T> {
    at(index: number): T;
  }
}
export const ACADEMY_VIEWPORT_SIZE = {
  small: 0,
  mediumSmall: 576,
  medium: 767,
  large: 992,
};

export const useUserHasAkademiBeta = (): boolean => {
  const userPermissions = useSelector((state: RootState) => state.login.permissions);
  const ACADEMY_BETA_PERMISSIONS: string[] = ['read:akademi', 'read:beta_features'];
  return ACADEMY_BETA_PERMISSIONS.every((permission) => userPermissions.includes(permission));
};

export const convertSrtToJson = (srtContent: string): TranscriptEntry[] => {
  const subtitleBlocks = srtContent.trim().split('\n\n');
  const subtitleEntries: TranscriptEntry[] = [];

  for (const block of subtitleBlocks) {
    const lines = block.trim().split('\n');

    if (lines.length >= 3) {
      const idNumber = parseInt(lines[0], 10);
      const timestamps = lines[1].split(' --> ');
      const text = lines.slice(2).join('\n');

      // Extract and format the timestamps to display minutes and seconds with zero padding: MM:SS
      const formatTimestamp = (timestamp: string) => {
        const timeParts = timestamp.split(':');
        const minutes = String(parseInt(timeParts[1], 10)).padStart(2, '0');
        const seconds = String(parseInt(timeParts[2].split(',')[0], 10)).padStart(2, '0');
        return `${minutes}:${seconds}`;
      };

      const formattedTimestampFrom = formatTimestamp(timestamps[0]);
      const formattedTimestampTo = formatTimestamp(timestamps[1]);

      const subtitleEntry: TranscriptEntry = {
        id: idNumber,
        timestamp: {
          from: formattedTimestampFrom,
          to: formattedTimestampTo,
        },
        text,
      };

      subtitleEntries.push(subtitleEntry);
    }
  }

  return subtitleEntries;
};

export const getPrevModuleUrl = (currentModuleId: string, currentChapter: Chapter, courseModules: Module[]): string | null => {
  const currentIndex = courseModules.findIndex((module) => module.id === currentModuleId);

  if (currentIndex > 0) {
    const prevItem = courseModules.at(currentIndex - 1);
    return `/akademi/kurs/krasjkurs-i-sakforsel-for-advokatfullmektiger/${currentChapter.slug}/${prevItem.slug}`;
  }
  return null;
};

export const getTotalInChapter = (
  context: CourseContent,
  chapterId: string,
  property: 'duration' | 'timestampTokens'
): number => {
  // Since we are using floored timestamps, we need to add 1 second to the total duration
  const shouldAddOne = property === 'timestampTokens';
  const modules = getModulesInChapter(context, chapterId);
  return modules.reduce((total, module) => total + Number(module.durationInSeconds) + (shouldAddOne ? 1 : 0), 0);
};

export const calculateTotalProgressInChapter = (
  moduleProgress: ModuleProgress[],
  chapterId: string,
  totalTokensInChapter: number
): number => {
  const calculatedChapterProgress = moduleProgress
    .filter((item) => item.chapterId === chapterId)
    .reduce((acc, item) => {
      return acc + item.timestampTokensSeen.length || 0;
    }, 0);
  const res = Math.min(Math.floor((calculatedChapterProgress / totalTokensInChapter) * 100), 100);
  return res;
};

export const calculateTotalProgressInAllChapters = (
  moduleProgress: ModuleProgress[],
  courseData: CourseContent
): ChapterProgress[] => {
  const allChapterIds = courseData.chapters.map((chapter) => chapter.id);

  const chapterProgress: ChapterProgress[] = [];

  allChapterIds.forEach((chapterId) => {
    const totalTokensInChapter = getTotalInChapter(courseData, chapterId, 'timestampTokens');
    const totalProgressInChapter = calculateTotalProgressInChapter(moduleProgress, chapterId, totalTokensInChapter);

    chapterProgress.push({
      chapterId,
      totalChapterProgress: totalProgressInChapter,
    });
  });

  return chapterProgress;
};

export const getModulesProgress = (accessToken: string, moduleIds: string[]): Promise<ModuleProgress[]> => {
  const joinedModuleIds = moduleIds.join(',');
  const response = fetch(`/api/academy/progress/${joinedModuleIds}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
  return response;
};

export const putModuleProgress = (accessToken: string, progress: ModuleProgress, moduleId: string): Promise<string> => {
  if (progress.timestampTokensSeen.length <= 1) return Promise.resolve('No progress to save');

  const response = fetch(`/api/academy/progress/put/${moduleId}`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: progress }),
  })
    .then((res) => res.text())
    .then((res) => res);

  return response;
};

export const deleteModuleProgress = (accessToken: string, moduleId: string): Promise<void> => {
  const response = fetch(`/api/academy/progress/delete/${moduleId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.json())
    .then((res) => res);

  return response;
};

export const secondsToMMSS = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsStr = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${minutesStr}:${secondsStr}`;
};

export const secondsToHHMMSS = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  let result = '';
  if (hours > 0) {
    result += `${hours}:`;
  }
  if (hours > 0 || minutes > 0) {
    result += `${(minutes < 10 ? '0' : '') + minutes}:`;
  }
  result += (seconds < 10 ? '0' : '') + seconds;

  return result;
};

export const saveFinalTestPassed = (accessToken: string, courseId: string) => {
  const response = fetch(`/api/academy/quiz/put/${courseId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.text())
    .then((res) => res);

  return response;
};

export const getFinalTestPassed = (accessToken: string, courseId: string) => {
  const response = fetch(`/api/academy/quiz/${courseId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.json())
    .then((res) => res);

  return response;
};
