import {
  DomNode,
  BaseDomNode,
  CHILD,
  CHILDREN,
  TEXT,
  ELEMENT,
  FRAGMENT,
  KEY,
  TYPE,
  VALUE,
  CODEPOINT_INDEX_START,
  CODEPOINT_INDEX_END,
} from '../../models/dom/domNode';
import * as jsonml from '../../models/JsonML';

type AttributeValue = string | number | boolean | null;

export interface AllocatorConfig<P, S> {
  initialState: S;
  enrichNode: (baseNode: BaseDomNode, state: S) => DomNode<P>;
  createElement: (
    baseNode: BaseDomNode,
    tagName: string,
    attributes: Record<string, AttributeValue> | null,
    state: S
  ) => DomNode<P>;
}

/**
 * DomAllocator.
 *
 * type parameters:
 * P: Domain-specific DomNode properties.
 * S: Domain-specific allocator state.
 */
export class DomAllocator<P, S> {
  /**
   * Key is unique number allocated to each node. It increases with reading order.
   * We should be able to efficiently locate a node by its key, by bsearching ourselves
   * down to the pinpoint location.
   */
  private key: number;

  private codepointIndex: number;

  private state: S;

  private enrichNode: (baseNode: BaseDomNode, state: S) => DomNode<P>;

  private createElement: (
    baseNode: BaseDomNode,
    tagName: string,
    attributes: Record<string, AttributeValue> | null,
    state: S
  ) => DomNode<P>;

  constructor({ initialState, enrichNode, createElement }: AllocatorConfig<P, S>) {
    this.key = 1;
    this.codepointIndex = 0;
    this.state = initialState;
    this.enrichNode = enrichNode;
    this.createElement = createElement;
  }

  private allocKey(): number {
    const { key } = this;
    this.key += 1;
    return key;
  }

  getCodepointIndex(): number {
    return this.codepointIndex;
  }

  jsonmlFragmentToNode = (input: jsonml.JsonMLNode | null): DomNode<P> => {
    const key = this.allocKey();

    if (!input) return this.emptyFragmentWithKey(key);
    if (typeof input === 'string') {
      return this.textToDomNode(key, input);
    }

    const { codepointIndex } = this;

    const { tagName, attributes, children } = jsonml.unpackElement(input, (child) => this.jsonmlNodeToDomNode(child));

    if (tagName === 'fragment') {
      if (children.length === 0) return this.emptyFragmentWithKey(key);
      if (children.length === 1) return children[0];

      const fragment = this.enrichNode(
        {
          [TYPE]: FRAGMENT,
          [KEY]: key,
          [CODEPOINT_INDEX_START]: codepointIndex,
          [CODEPOINT_INDEX_END]: this.codepointIndex,
        },
        this.state
      );

      fragment[CHILDREN] = children;

      return fragment;
    }

    return this.jsonmlElementToDomNode(key, tagName, attributes, children, codepointIndex);
  };

  emptyFragment = (): DomNode<P> => {
    return this.emptyFragmentWithKey(this.allocKey());
  };

  private emptyFragmentWithKey = (key: number): DomNode<P> => {
    return this.enrichNode(
      {
        [TYPE]: FRAGMENT,
        [KEY]: key,
        [CODEPOINT_INDEX_START]: this.codepointIndex,
        [CODEPOINT_INDEX_END]: this.codepointIndex,
      },
      this.state
    );
  };

  private textToDomNode = (key: number, input: string): DomNode<P> => {
    const { codepointIndex } = this;
    this.codepointIndex += input.length;
    return this.enrichNode(
      {
        [TYPE]: TEXT,
        [VALUE]: input,
        [KEY]: key,
        [CODEPOINT_INDEX_START]: codepointIndex,
        [CODEPOINT_INDEX_END]: this.codepointIndex,
      },
      this.state
    );
  };

  private jsonmlNodeToDomNode = (input: jsonml.JsonMLNode): DomNode<P> => {
    const key = this.allocKey();

    if (typeof input === 'string') {
      return this.textToDomNode(key, input);
    }

    const { codepointIndex } = this;

    const { tagName, attributes, children } = jsonml.unpackElement(input, (child) => this.jsonmlNodeToDomNode(child));

    return this.jsonmlElementToDomNode(key, tagName, attributes, children, codepointIndex);
  };

  private jsonmlElementToDomNode = (
    key: number,
    tagName: string,
    attrs: jsonml.JsonMLAttributes | null,
    children: DomNode<P>[],
    codepointIndexStart: number
  ): DomNode<P> => {
    const baseNode: BaseDomNode = {
      [TYPE]: ELEMENT,
      [KEY]: key,
      [CODEPOINT_INDEX_START]: codepointIndexStart,
      [CODEPOINT_INDEX_END]: this.codepointIndex,
    };

    const element = this.createElement(baseNode, tagName, attrs, this.state);

    switch (children.length) {
      case 0:
        break;
      case 1:
        // eslint-disable-next-line prefer-destructuring
        element[CHILD] = children[0];
        break;
      default:
        element[CHILDREN] = children;
        break;
    }

    return element;
  };
}
