import { css, CSSProp } from 'styled-components';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

export const createInputFieldBorder = (
  rightIcon?: boolean,
  leftIcon?: boolean,
  hasError?: boolean,
  borderColorVariant?: 'primary' | 'secondary' | 'tertiary'
): CSSProp => {
  return css`
    border: ${hasError ? '0.125rem' : '0.0625rem'} solid
      ${(hasError && COLOR_TOKENS.red[600]) ||
      (borderColorVariant === 'primary' && COLOR_TOKENS.grey[500]) ||
      (borderColorVariant === 'secondary' && COLOR_TOKENS.purple[100]) ||
      (borderColorVariant === 'tertiary' && COLOR_TOKENS.purple[600]) ||
      COLOR_TOKENS.brown[400]};
    border-right: ${!!rightIcon && 'none'};
    border-left: ${!!leftIcon && 'none'};
  `;
};

export const createInputFieldBorderRadius = (rightIcon?: boolean, leftIcon?: boolean): CSSProp => {
  return css`
    border-radius: 0.25rem;
    border-top-left-radius: ${leftIcon && 0};
    border-bottom-left-radius: ${leftIcon && 0};
    border-top-right-radius: ${rightIcon && 0};
    border-bottom-right-radius: ${rightIcon && 0};
  `;
};
