import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import createMediaQuery from 'util/createMediaQuery';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { AcademyFrontPageData } from 'pages/AcademyPage/types';
import { AcademyFrontPageTextContentCss } from './AcademyFrontPageContentStyles';
import {
  academyFrontPageFeatureBoxDeepDiveIconSvg,
  academyFrontPageFeatureBoxQuizAndCetificateIconSvg,
  academyFrontPageFeatureBoxVideosIconSvg,
} from './FrontPageSvgs';

interface Props {
  featureBoxesContent: AcademyFrontPageData['frontPage']['featureBoxes'];
}

const AcademyFeatureBoxes: FC<Props> = ({ featureBoxesContent }) => {
  return (
    <FeatureBoxesContainerCss>
      <FeatureBoxCss>
        <div className="icon-and-heading-container">
          <div className="icon-container">{academyFrontPageFeatureBoxDeepDiveIconSvg}</div>
          <h3>{featureBoxesContent.deepDive.title}</h3>
        </div>
        <AcademyFrontPageTextContentCss dangerouslySetInnerHTML={{ __html: featureBoxesContent.deepDive.text }} />
      </FeatureBoxCss>

      <FeatureBoxCss>
        <div className="icon-and-heading-container">
          <div className="icon-container">{academyFrontPageFeatureBoxVideosIconSvg}</div>
          <h3>{featureBoxesContent.videos.title}</h3>
        </div>
        <AcademyFrontPageTextContentCss dangerouslySetInnerHTML={{ __html: featureBoxesContent.videos.text }} />
      </FeatureBoxCss>

      <FeatureBoxCss>
        <div className="icon-and-heading-container">
          <div className="icon-container">{academyFrontPageFeatureBoxQuizAndCetificateIconSvg}</div>
          <h3>{featureBoxesContent.quizAndCertificate.title}</h3>
        </div>
        <AcademyFrontPageTextContentCss dangerouslySetInnerHTML={{ __html: featureBoxesContent.quizAndCertificate.text }} />
      </FeatureBoxCss>
    </FeatureBoxesContainerCss>
  );
};

export default AcademyFeatureBoxes;

const FeatureBoxesContainerCss = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: stretch;
  padding-top: 0;
  max-width: 640px;

  ${createMediaQuery(
    ACADEMY_VIEWPORT_SIZE.large,
    css`
      flex-direction: row;
      gap: 30px;
      max-width: 100%;
    `
  )}

  > div {
    flex: 1 0 0;
  }
`;

const FeatureBoxCss = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;
  background-color: ${({ theme }) => theme.academy.frontPage.colors.backgroundPurple};
  color: ${({ theme }) => theme.academy.frontPage.colors.primary};

  > .icon-and-heading-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    > .icon-container {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > h3 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
  }

  > p {
  }
`;
