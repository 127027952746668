import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';

import NIKToolbar from 'pages/NIKPage/components/NIKToolbar';
import GlobalSearch from './components/GlobalSearch/GlobalSearch';
import Navbar from './components/Navbar/Navbar';

const NavbarAndGlobalSearchAndErrorContainerCss = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background: inherit;
  z-index: 99;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  @media print {
    display: none;
  }
`;

interface NavbarAndGlobalSearchAndErrorContainerProps {
  displayGlobalSearch: boolean;
  setDisplayGlobalSearch: Dispatch<SetStateAction<boolean>>;
  hasBeigeBackground: boolean;
  errorBanner?: ReactNode;
  isNikToolbarVisible?: boolean;
}

const NavbarAndGlobalSearchAndErrorContainer: FC<NavbarAndGlobalSearchAndErrorContainerProps> = ({
  displayGlobalSearch,
  setDisplayGlobalSearch,
  hasBeigeBackground,
  errorBanner = null,
  isNikToolbarVisible = false,
}) => {
  return (
    <NavbarAndGlobalSearchAndErrorContainerCss role="banner">
      <GlobalSearch displayGlobalSearch={displayGlobalSearch} setDisplayGlobalSearch={setDisplayGlobalSearch} />
      <Navbar
        setDisplayGlobalSearch={setDisplayGlobalSearch}
        displayGlobalSearch={displayGlobalSearch}
        hasBeigeBackground={hasBeigeBackground}
      />
      {errorBanner}
      {isNikToolbarVisible && <NIKToolbar />}
    </NavbarAndGlobalSearchAndErrorContainerCss>
  );
};

export default NavbarAndGlobalSearchAndErrorContainer;
