import Svg from 'components/ui/Svg';
import React from 'react';
import IconPropTypes from './IconPropTypes';

const DividerDot: React.FC<IconPropTypes> = ({ ...props }) => (
  <Svg className="icon icon-dividerDot" width={6} height={6} viewBox="0 0 6 6" {...props}>
    <circle cx="164.75" cy="778.75" r="2.75" fill="#C2C0C2" fillRule="evenodd" transform="translate(-162 -776)" />
  </Svg>
);

export default DividerDot;
