import Svg from 'components/ui/Svg';
import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import IconPropTypes from './IconPropTypes';

const ArrowUp: React.FC<IconPropTypes> = ({ size, color, width, height, ...props }) => {
  return (
    <Svg width={width || size} height={height || size} className="c-icon c-icon-arrowUp" viewBox="0 0 16 9" {...props}>
      <polygon
        fill={color}
        fillRule="evenodd"
        points="61 8.688 53 16 45 8.688 46.714 7 53 12.906 59.286 7"
        transform="matrix(1 0 0 -1 -45 16)"
      />
    </Svg>
  );
};

ArrowUp.defaultProps = {
  color: COLOR_TOKENS.purple[800],
  size: 16,
};
export default ArrowUp;
