import * as React from 'react';
import Content from 'components/icons/Content';
import Cross2 from 'components/icons/Cross2';
import Paragraph from 'components/icons/Paragraph';
import { useViewportSize } from 'util/hooks/useViewportSize';
import { ACADEMY_VIEWPORT_SIZE } from 'pages/AcademyPage/utils';
import { HeaderActionsCss, ButtonCss } from './Styles';
import { Display } from '../Sidebar';
import { AcademyIconButton3Css } from '../../ShareCourseModal/Styles';

interface HeaderActionsProps {
  handleViewCourses: () => void;
  handleViewSources: () => void;
  display: Display;
  onClose: () => void;
  showSources: boolean;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({ handleViewCourses, handleViewSources, display, onClose, showSources }) => {
  const viewportSize = useViewportSize(ACADEMY_VIEWPORT_SIZE, 'small');

  return (
    <HeaderActionsCss>
      <ButtonCss $isActive={display === Display.CHAPTERS} onClick={handleViewCourses} tabIndex={0}>
        <Content mr2 /> Innhold
      </ButtonCss>
      {showSources && (
        <ButtonCss $isActive={display === Display.SOURCES} onClick={handleViewSources}>
          <Paragraph mr2 />
          Dypdykk
        </ButtonCss>
      )}

      {viewportSize !== 'large' && <AcademyIconButton3Css icon={<Cross2 />} onClick={onClose} aria-label="Lukk sidepanel" />}
    </HeaderActionsCss>
  );
};

export default HeaderActions;
