import * as React from 'react';
import { reloadJuridika } from 'util/pageReload';

export class LoadableWrapper extends React.PureComponent {
  componentDidCatch(error: Error) {
    if (error.message.includes('Loading chunk')) {
      reloadJuridika();
    }
  }

  public render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { children } = this.props;
    return <>{children}</>;
  }
}
