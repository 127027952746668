import React, { FC } from 'react';
import styled from 'styled-components';

const ConfirmationCSS = styled.section`
  text-align: center;
  font-size: 1.375rem;
  > p:first-child {
    font-weight: 600;
  }

  span {
    text-align: left;
    font-size: 1.1rem;

    p {
      line-height: 1.5rem;
      a:first-of-type {
        color: ${({ theme }) => theme.colorTokens.default.black};
      }
    }
  }
`;

const Confirmation: FC = () => (
  <ConfirmationCSS>
    <p>Tusen takk for henvendelsen din!</p>
    <p>Vi kontakter deg så fort vi kan, vanligvis innen en arbeidsdag.</p>
    <span>
      <p>
        Haster det, eller har du noen spørsmål i mellomtiden?
        <br />
        Ring oss på <a href="tel:+4724147500">24 14 75 00</a>, eller send en e-post til{' '}
        <a href="mailto: post@juridika.no">post@juridika.no</a>.
      </p>
    </span>
  </ConfirmationCSS>
);

export default Confirmation;
