import { AppliedAnnotation } from './domAnnotations';

// Keys:
export const TYPE = Symbol('type');
export const VALUE = Symbol('value');
export const CHILD = Symbol('child');
export const CHILDREN = Symbol('children');
export const KEY = Symbol('key');
export const CODEPOINT_INDEX_START = Symbol('codepointIndexStart');
export const CODEPOINT_INDEX_END = Symbol('codepointIndexEnd');
export const ANNOTATIONS = Symbol('annotations');

/**
 * The TYPE of a node that is a text node
 */
export const TEXT = Symbol('text');

/**
 * The TYPE of a node that is an element node.
 * Schema-based nodes may use its own set of symbols
 * to tag domain-specific elements, instead of the generic ELEMENT.
 */
export const ELEMENT = Symbol('element');

/**
 * Special kind of "invisible" node that never has any semantic significance,
 * its children are simply merged into its parent.
 */
export const FRAGMENT = Symbol('fragment');

/**
 * Type for representing abstract, indexed, virtual DOM.
 *
 * It's abstract, because the type can be extended to include extra metadata.
 * It can represent HTML or XML-based schemas.
 *
 * It's indexed, because each node in the tree knows about the text that it spans.
 *
 * This type is created using the DomAllocator, and can be used as input to
 * a react function outputting real HTML.
 *
 * It also supports text annotations (highlights of text).
 */
export interface DomBaseProps<P> {
  /**
   * The type of node (TEXT, ELEMENT, FRAGMENT or domain specific element)
   */
  [TYPE]: symbol;

  /**
   * Unique key allocated by allocator: (only unique if the same allocator instance has been used)
   */
  [KEY]: number | string;

  /**
   * The first codepoint index of this node, relative to all other nodes
   * allocated with the same allocator.
   */
  [CODEPOINT_INDEX_START]: number;

  /**
   * The first codepoint index of this node, relative to all other nodes
   * allocated with the same allocator.
   */
  [CODEPOINT_INDEX_END]: number;

  /**
   * For text nodes, this is the text value:
   */
  [VALUE]?: string;

  /**
   * Annotations on a text node
   */
  [ANNOTATIONS]?: Set<AppliedAnnotation>;

  /**
   * For element nodes having a single child
   */
  [CHILD]?: DomBaseProps<P> & P;

  /**
   * For element nodes having multiple children
   */
  [CHILDREN]?: Array<DomBaseProps<P> & P>;
}

export type DomNode<P> = DomBaseProps<P> & P;

export type BaseDomNode = DomNode<Record<string, never>>;

export type DomPath<P> = Array<DomNode<P>>;

export const mapChildren = <P, T>(node: DomNode<P>, callbackFn: (child: DomNode<P>, index: number) => T): Array<T> => {
  const child = node[CHILD];
  if (child) {
    return [callbackFn(child, 0)];
  }

  const children = node[CHILDREN];
  if (!children) return [];

  return children.map(callbackFn);
};

export const domChildrenOf = <P>(node: DomNode<P>): DomNode<P>[] => {
  const child = node[CHILD];
  if (child) {
    return [child];
  }
  const children = node[CHILDREN];
  if (children) {
    return children;
  }
  return [];
};

export const extractText = <P>(node: DomNode<P>): string => {
  if (node[TYPE] === TEXT) {
    return node[VALUE] || '';
  }

  return mapChildren(node, extractText).join('');
};
