import React, { FC } from 'react';

import { COLORS } from 'theme/config/defaultTheme';
import IconPropTypes from 'components/icons/IconPropTypes';
import Svg from 'components/ui/Svg';

const Info: FC<IconPropTypes> = ({ size, color, ...props }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 1.833A6.674 6.674 0 0 0 1.333 8.5 6.674 6.674 0 0 0 8 15.167 6.674 6.674 0 0 0 14.667 8.5 6.674 6.674 0 0 0 8 1.833zm0 1A5.66 5.66 0 0 1 13.667 8.5 5.66 5.66 0 0 1 8 14.167 5.66 5.66 0 0 1 2.333 8.5 5.66 5.66 0 0 1 8 2.833zm0 2.334A.667.667 0 1 0 8 6.5a.667.667 0 0 0 0-1.333zm-.008 2.326A.5.5 0 0 0 7.5 8v3.667a.5.5 0 1 0 1 0V8a.5.5 0 0 0-.508-.507z"
        fill={color}
      />
    </Svg>
  );
};

Info.defaultProps = {
  size: 20,
  color: COLORS.iconDefault,
};

export default Info;
