import React, { FC } from 'react';
import styled from 'styled-components';

import { AcademyFrontPageData } from 'pages/AcademyPage/types';
import AcademyTestimonials from './components/AcademyTestimonials';
import {
  AcademyFrontPageSectionContentCss,
  AcademyFrontPageSectionCss,
  AkademiFrontPageSectionBackgroundColor,
} from './components/AcademyFrontPageSectionStyles';
import { AcademyFrontPageSectionHeadingCss } from './components/AcademyFrontPageContentStyles';

interface Props {
  testimonialsSectionData: AcademyFrontPageData['frontPage']['testimonialsSection'];
}

const AcademyFrontPageTestimonialsSection: FC<Props> = ({ testimonialsSectionData }) => {
  return (
    <AcademyFrontPageSectionCss backgroundColor={AkademiFrontPageSectionBackgroundColor.BROWN}>
      <TestimonialsSectionContentCss>
        <AcademyFrontPageSectionHeadingCss
          isTextAlignCenter
          dangerouslySetInnerHTML={{ __html: testimonialsSectionData.title }}
        />

        <AcademyTestimonials testimonials={testimonialsSectionData.testimonials} />
      </TestimonialsSectionContentCss>
    </AcademyFrontPageSectionCss>
  );
};

export default AcademyFrontPageTestimonialsSection;

const TestimonialsSectionContentCss = styled(AcademyFrontPageSectionContentCss)``;
