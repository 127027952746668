import URI from 'urijs';

import { JuridikaConfig } from 'commonUtils/juridikaConfig';
import { formatDate, stripTimeFromISO8601Date, convertISO8601DateToDDMMYYYY } from './dateHelpers';
import { legalDocumentPath } from './urlHelpers';
import { normalizeWhitespace } from './textUtils';
import { norwegianListify } from './languageUtils';
import { extractCommentaryParagraphNumbers } from './commentaryParagraphNumberingUtils';

import {
  SelectedArticleTextInfoObject,
  SelectedPdfTextInfoObject,
  SelectedCommentaryTextInfoObject,
  SelectedLegalDocumentTextInfoObject,
  SelectedTextInfoObjectType,
  SelectedBitsTextInfoObject,
  SelectedNIKTextInfoObject,
} from '../models/SelectedTextInfoObject';

interface Citation {
  htmlText: string;
  plainText: string;
  parentElement?: HTMLElement | null;
}

interface TooltipCoords {
  toolTipX: number;
  toolTipY: number;
  range: Range;
}

export const getTooltipCords = (scrollContainer: Element | null): TooltipCoords => {
  const selection = window.getSelection();

  if (!selection) {
    throw new Error('selection is not defined');
  }

  const range = selection.getRangeAt(0);
  const rangeClientRects = range.getClientRects();
  const toolTipElement = document.getElementById('selectedTextToolTip');
  // Alter Y and X position to make sure tooltip is in view and that bottom arrow appears at start of text
  let toolTipY;
  let toolTipX = rangeClientRects[0].left;

  if (scrollContainer) {
    // If starting position of selected text is out of view, place it at top of scrollcontainer
    toolTipY =
      scrollContainer.getBoundingClientRect().top > rangeClientRects[0].top
        ? scrollContainer.getBoundingClientRect().top
        : rangeClientRects[0].top;
  } else {
    // No scrollcontainer to calculate from on articles
    toolTipY = window.pageYOffset + rangeClientRects[0].top;
  }

  if (toolTipElement) {
    toolTipY -= toolTipElement.clientHeight;
    toolTipX -= toolTipElement.clientWidth / 2;
  }

  return { toolTipY, toolTipX, range };
};

const generateSearchedDateString = () => {
  const date = new Date();
  return `(kopiert ${formatDate(date)})`;
};

const commaSeparate = (text: (string | undefined)[]) =>
  text
    .filter(Boolean)
    .map((s) => (s as string).trim())
    .join(', ');

const normalizeTitles = (titles: string[]): string[] => titles.map(normalizeWhitespace);

const splitEditionNumberFromTitles = (titles: string[]): [string[], string | undefined] => {
  const lastTitle: string = titles[titles.length - 1];

  return titles.length > 1 && lastTitle.match(/\d+\. utgave/)
    ? [titles.slice(0, titles.length - 1), lastTitle]
    : [titles, undefined];
};

const convertNIKPartIdToReadableFormat = (partID: string) => {
  const replacedString = partID
    .replace(/subpart_(\d+)/g, (match, p1) => `Underdel ${p1}`)
    .replace(/part_(\d+)/g, (match, p1) => `Del ${p1}`)
    .replace(/para_(\d+)/g, (match, p1) => `Avsnitt ${p1}`);

  const readableFormat = replacedString.replace(/__/g, ', ');
  return readableFormat;
};

const joinTitles = (titles: string[]): string => normalizeTitles(titles).join('. ');

/** This method exists only to ensure we use N-dash (tankestrek) as opposed to a
 *  hyphen (bindestrek) when citing ranges (e.g. page range, paragraph range, etc). */
const citeRange = (range: [string, string]): string => range.join('–');

const ensureDoesNotEndInPeriod = (text: string): string =>
  text.substring(text.length - 1, text.length) === '.' ? text.substring(0, text.length - 1) : text;

export const generateCitation = (
  selectedTextInfoObject: SelectedTextInfoObjectType,
  selection: Selection,
  juridikaConfig: JuridikaConfig,
  parentElement?: HTMLElement | null
): Citation => {
  switch (selectedTextInfoObject.source) {
    case 'commentary': {
      return generateCommentaryCitation(selectedTextInfoObject, selection, juridikaConfig);
    }
    case 'legalDocument': {
      return generateLawCitation(selectedTextInfoObject, juridikaConfig);
    }
    case 'article': {
      return generateArticleCitation(selectedTextInfoObject, juridikaConfig);
    }
    case 'nik': {
      return generateNIKCitation(selectedTextInfoObject, juridikaConfig, parentElement);
    }
    case 'pdf': {
      return generatePdfCitation(selectedTextInfoObject, juridikaConfig);
    }
    case 'bits': {
      return generateBitsCitation(selectedTextInfoObject, juridikaConfig);
    }
    default: {
      return {
        htmlText: '',
        plainText: '',
      };
    }
  }
};

const generatePlainTextCitation = (content: string[], url: string) => {
  const citation = commaSeparate([...content, `Juridika ${generateSearchedDateString()}\n${url}`]);

  return `\n\n${citation}`;
};

const generateHtmlCitation = (content: string[], juridikaConfig: JuridikaConfig) => {
  const citation = commaSeparate([
    ...content,
    `<a href='${juridikaConfig.juridikaAbsoluteBaseUrl().toString()}'>Juridika</a> ${generateSearchedDateString()}`,
  ]);

  return `<p>${citation}</p>`;
};

export const commentaryCitation = (
  { authors = [], legalDocumentShortTitle, requestedContent, fragmentTitle }: SelectedCommentaryTextInfoObject,
  paragraphNumbers: [string, string] | null,
  juridikaConfig: JuridikaConfig
): Citation => {
  const authorsString = generateContributionStringFromAuthorList(authors);
  const title = joinTitles([legalDocumentShortTitle, 'Lovkommentar']);
  const fragmentTitleWithoutPunctuationEnding =
    fragmentTitle !== null ? ensureDoesNotEndInPeriod(normalizeWhitespace(fragmentTitle)) : '';
  const urlParagraphSubPath = paragraphNumbers ? `/avsnitt_${paragraphNumbers[0]}` : '';
  const url = juridikaConfig
    .juridikaAbsoluteBaseUrl()
    .segment([legalDocumentPath(requestedContent), urlParagraphSubPath])
    .toString();
  const paragraphNumberText: string =
    paragraphNumbers === null
      ? ''
      : `avsnitt ${paragraphNumbers[0] === paragraphNumbers[1] ? paragraphNumbers[0] : citeRange(paragraphNumbers)}`;
  const htmlCitation = generateHtmlCitation(
    [
      authorsString,
      `<cite>${title}</cite>`,
      `<a href='${url}'>${commaSeparate([fragmentTitleWithoutPunctuationEnding, paragraphNumberText])}</a>`,
    ],
    juridikaConfig
  );
  const plainTextCitation = generatePlainTextCitation(
    [authorsString, title, fragmentTitleWithoutPunctuationEnding, paragraphNumberText],
    url
  );

  return {
    htmlText: htmlCitation,
    plainText: plainTextCitation,
  };
};

export const generateContributionString = (contributions: Array<{ role: string; contributor: { names: string[] } }>): string => {
  const list = contributions.map((c) => `${c.contributor.names.join(' ')}${c.role === 'editor' ? ' (red.)' : ''}`);
  return generateContributionStringFromAuthorList(list);
};

export const generateContributionStringFromAuthorList = (authors: string[]): string => {
  /* Gunhild Eide has confirmed that the rule is:
   * - for more than 3 contributions cite only the first one (typically an editor).
   * - for fewer contributions, cite them all;
   * */
  return authors.length > 3 ? `${authors[0]} mfl.` : norwegianListify(authors);
};

export const generateCommentaryCitation = (
  selectedCommentaryTextInfoObject: SelectedCommentaryTextInfoObject,
  selection: Selection,
  juridikaConfig: JuridikaConfig
): Citation => commentaryCitation(selectedCommentaryTextInfoObject, extractCommentaryParagraphNumbers(selection), juridikaConfig);

export const generateLawCitation = (
  { fullTitle, numValue, requestedContent }: SelectedLegalDocumentTextInfoObject,
  juridikaConfig: JuridikaConfig
): Citation => {
  const url = juridikaConfig
    .juridikaAbsoluteBaseUrl()
    .segment(
      legalDocumentPath({
        ...requestedContent,
        shouldDisplayComment: false,
      })
    )
    .toString();
  const citation = numValue ? `${fullTitle} § ${numValue}` : fullTitle;
  const htmlText = generateHtmlCitation([`<a href='${url}'>${citation}</a>`], juridikaConfig);

  return {
    htmlText,
    plainText: generatePlainTextCitation([citation], url),
  };
};

export const generateNIKCitation = (
  selectedNIKTextInfoObject: SelectedNIKTextInfoObject,
  juridikaConfig: JuridikaConfig,
  parentElement?: HTMLElement | null
): Citation => {
  const url = juridikaConfig
    .juridikaAbsoluteBaseUrl()
    .segment(selectedNIKTextInfoObject.location.pathname)
    .hash(selectedNIKTextInfoObject.location.hash)
    .toString();
  const lastUpdatedAt = convertISO8601DateToDDMMYYYY(stripTimeFromISO8601Date(selectedNIKTextInfoObject.publishedAt));
  const htmlText = generateHtmlCitation(
    [
      `${selectedNIKTextInfoObject.contributions.join(', ')}`,
      `<a href='${url}'>${selectedNIKTextInfoObject.publicationTitle}</a>`,
      `Ajourført: ${lastUpdatedAt}`,
      parentElement?.id ? `Utdrag fra: ${convertNIKPartIdToReadableFormat(parentElement.id)}` : '',
    ],
    juridikaConfig
  );
  return {
    htmlText,
    plainText: generatePlainTextCitation([selectedNIKTextInfoObject.publicationTitle], url),
  };
};

export const generatePdfCitation = (
  {
    publisherName,
    contributions,
    editionOriginallyPublishedAt,
    documentTitles,
    location,
    pdfPageNumber,
  }: SelectedPdfTextInfoObject,
  juridikaConfig: JuridikaConfig
): Citation => {
  const year = editionOriginallyPublishedAt.split('-')[0];
  const contributionsString = generateContributionString(contributions);
  const [nonNumTitles, editionNumSubtitle]: [string[], string | undefined] = splitEditionNumberFromTitles(
    normalizeTitles(documentTitles)
  );
  const title = joinTitles(nonNumTitles);
  const url = juridikaConfig.juridikaAbsoluteBaseUrl().segment(location.pathname).hash(location.hash);

  return generateSimpleBookCitation({
    contributionsString,
    title,
    editionNumSubtitle,
    publisherName,
    year,
    url,
    pageName: pdfPageNumber,
    juridikaConfig,
  });
};

export const generateBitsCitation = (
  {
    deepDocumentLink,
    publisherName,
    editionOriginallyPublishedAt,
    rootDocumentTitles,
    rootContributions,
    deepDocumentTitles,
    deepContributions,
    pageName,
  }: SelectedBitsTextInfoObject,
  juridikaConfig: JuridikaConfig
): Citation => {
  const year = editionOriginallyPublishedAt.split('-')[0];
  const rootContributionsString = generateContributionString(rootContributions);
  const [nonNumTitles, editionNumSubtitle]: [string[], string | undefined] = splitEditionNumberFromTitles(
    normalizeTitles(rootDocumentTitles)
  );
  const rootTitle = joinTitles(nonNumTitles);

  const url = deepDocumentLink.url({ absoluteUrlConfig: juridikaConfig });

  if (deepContributions && deepDocumentTitles) {
    return generateComplexBookCitation({
      deepContributionsString: generateContributionString(deepContributions),
      deepTitle: joinTitles(deepDocumentTitles),
      rootContributionsString,
      rootTitle,
      editionNumSubtitle,
      publisherName,
      year,
      url,
      pageName: pageName ?? '?',
      juridikaConfig,
    });
  }

  return generateSimpleBookCitation({
    contributionsString: rootContributionsString,
    title: rootTitle,
    editionNumSubtitle,
    publisherName,
    year,
    url,
    pageName: pageName ?? '?',
    juridikaConfig,
  });
};

/**
 * Generate a 1-level book citation
 */
const generateSimpleBookCitation = (params: {
  contributionsString: string;
  title: string;
  editionNumSubtitle: string | undefined;
  publisherName: string;
  year: string;
  url: URI;
  pageName: string;
  juridikaConfig: JuridikaConfig;
}): Citation => {
  return {
    htmlText: generateHtmlCitation(
      [
        params.contributionsString,
        `<cite>${params.title}</cite>`,
        `${commaSeparate([params.editionNumSubtitle, params.publisherName, params.year])} <a href='${params.url.toString()}'>s. ${
          params.pageName
        }</a>`,
      ],
      params.juridikaConfig
    ),
    plainText: generatePlainTextCitation(
      [
        params.contributionsString,
        params.title,
        `${commaSeparate([params.editionNumSubtitle, params.publisherName, params.year])} s. ${params.pageName}`,
      ],
      params.url.toString()
    ),
  };
};

/**
 * Generate a 2-level book citation
 */
const generateComplexBookCitation = (params: {
  deepContributionsString: string;
  deepTitle: string;
  rootContributionsString: string;
  rootTitle: string;
  editionNumSubtitle: string | undefined;
  publisherName: string;
  year: string;
  url: URI;
  pageName: string;
  juridikaConfig: JuridikaConfig;
}): Citation => {
  return {
    htmlText: generateHtmlCitation(
      [
        params.deepContributionsString,
        `«${params.deepTitle}» i ${params.rootContributionsString}`,
        `<cite>${params.rootTitle}</cite>`,
        `${commaSeparate([params.editionNumSubtitle, params.publisherName, params.year])} <a href='${params.url.toString()}'>s. ${
          params.pageName
        }</a>`,
      ],
      params.juridikaConfig
    ),
    plainText: generatePlainTextCitation(
      [
        params.deepContributionsString,
        `«${params.deepTitle}» i ${params.rootContributionsString}`,
        params.rootTitle,
        `${commaSeparate([params.editionNumSubtitle, params.publisherName, params.year])} s. ${params.pageName}`,
      ],
      params.url.toString()
    ),
  };
};

export const generateArticleCitation = (
  {
    publicationTitles,
    editionPath,
    documentTitles,
    documentStartPageName,
    documentEndPageName,
    contributions,
    location,
  }: SelectedArticleTextInfoObject,
  juridikaConfig: JuridikaConfig
): Citation => {
  const pageStart = documentStartPageName;
  const pageEnd = documentEndPageName;
  const yearAndEdition = editionPath.join('/');
  const pageRange = pageStart && pageEnd ? citeRange([pageStart, pageEnd]) : pageStart || pageEnd;
  const pages = pageRange ? ` s. ${pageRange}` : '';
  const contributionsString = generateContributionString(contributions);
  const url = juridikaConfig.juridikaAbsoluteBaseUrl().segment(location.pathname).hash(location.hash).toString();
  const articleTitle = joinTitles(documentTitles);
  const articleTitleSubstituted = articleTitle.replace(/«/g, '‹').replace(/»/g, '›');
  const articleCollectionName = publicationTitles.join(' ');

  const htmlCitation = generateHtmlCitation(
    [
      contributionsString,
      `<a href='${url}'>«${articleTitleSubstituted}»</a>`,
      `<cite>${articleCollectionName}</cite> ${yearAndEdition}${pages}`,
    ],
    juridikaConfig
  );
  const plainTextCitation = generatePlainTextCitation(
    [contributionsString, `«${articleTitleSubstituted}»`, `${articleCollectionName} ${yearAndEdition}${pages}`],
    url
  );

  return {
    htmlText: htmlCitation,
    plainText: plainTextCitation,
  };
};
