import JsCookies from 'js-cookie';
import { Cookie, SetCookie } from './model';

interface CookieHandlerClient {
  getCookie: (cookie: Cookie) => string | undefined;
  setCookie: (cookie: SetCookie) => void;
}

export const cookieHandlerClient: CookieHandlerClient = {
  getCookie(cookie: Cookie): string | undefined {
    return JsCookies.get(cookie.name);
  },

  setCookie(cookie: SetCookie) {
    JsCookies.set(cookie.name, cookie.value, cookie.options);
  },
};
