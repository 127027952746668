import React from 'react';

const CheckboxIntermediate: React.FC<{}> = () => (
  <svg width={18} height={19}>
    <path fill="none" d="M-1-1h582v402H-1z" />
    <g>
      <path d="M.531.904h17v17h-17v-17z" stroke="#979797" fillRule="evenodd" fill="#FFF" />
      <path stroke="#979797" strokeWidth={1.5} fill="#FFF" d="M4.949 8.917h7.694" />
    </g>
  </svg>
);

export default CheckboxIntermediate;
