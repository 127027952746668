import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';

export type maxWidthSize = 'small' | 'medium' | 'large' | 'auto' | 'default';

export interface WidthConstraintsProps {
  height?: string;
  margin?: string;
  size?: maxWidthSize;
  customWidth?: string;
  customMaxWidth?: string;
  fullWidth?: boolean;
  defaultResponsiveWidth?: boolean;
  defaultResponsiveMaxWidth?: boolean;
}

const createMaxWidth = (props: maxWidthSize) => {
  switch (props) {
    case 'small': {
      return '23.4375rem';
    }
    case 'medium': {
      return '50rem';
    }
    case 'large': {
      return '80rem';
    }
    case 'auto': {
      return '140.625rem';
    }
    default: {
      return '64rem';
    }
  }
};

const WidthConstraints = styled.div<WidthConstraintsProps>`
  margin: ${(props) => props.margin || '0 auto'};
  width: ${(props) =>
    (props.defaultResponsiveWidth && `calc(100% - 2.5rem)`) ||
    (props.fullWidth && `100%`) ||
    (props.customWidth && props.customWidth)};
  max-width: ${(props) => (props.size && createMaxWidth(props.size)) || (props.customMaxWidth && props.customMaxWidth)};
  height: ${(props) => props.height};

  ${(props) =>
    props.defaultResponsiveWidth &&
    css`
      ${createMediaQuery(
        'xs',
        css`
          width: calc(100% - 3.75rem);
        `
      )}
    `}

  ${(props) =>
    props.defaultResponsiveMaxWidth &&
    css`
      ${createMediaQuery(
        'mlg',
        css`
          max-width: 71.25rem;
        `
      )}
    `}

  @media print {
    max-width: initial;
  }
`;

export default WidthConstraints;
