import { Store } from 'state/types';

import { executeJuridikaDevOptionsCommand } from 'state/juridikaDevOptions/juridikaDevOptionsActions';
import * as juridikaDevOptionsCommands from 'state/juridikaDevOptions/juridikaDevOptionsCommands';

const juridikaDevOptions = (store: Store): any => {
  return (command: any = undefined): any => {
    if (!command) {
      console.log('Available commands:');
      for (command of Object.keys(juridikaDevOptionsCommands)) {
        console.log(`window.juridikaDevOptions("${command}");`);
      }
    } else if (command in juridikaDevOptionsCommands) {
      store.dispatch(executeJuridikaDevOptionsCommand(command));
    } else {
      console.log(`No such command`);
    }

    console.log('Current values: ', store.getState().juridikaDevOptions);
  };
};

export const installJuridikaDevOptions = (store: Store) => {
  (window as any).juridikaDevOptions = juridikaDevOptions(store);
};
