import * as React from 'react';
import Button from 'components/ui/Button';
import styled from 'styled-components';

const StyledButtonCss = styled(Button)`
  padding: 0.625rem 0.5rem;
  transition: none;
  border: 1px solid ${({ theme }) => theme.colorTokens.default.white};

  :focus-visible {
    border: 1px solid ${({ theme }) => theme.colorTokens.purple[700]};
  }

  :hover,
  :focus-visible {
    background-color: ${({ theme }) => theme.colorTokens.purple[200]};
    border-radius: 0.5rem;
    opacity: 1;
  }

  svg {
    margin-left: 0.375rem;
  }

  span {
    font-weight: 400;
    font-size: 1rem;
  }
`;

const ForwardButton: React.FC<{ handleShareCourseLink: () => void }> = ({ handleShareCourseLink }) => (
  <StyledButtonCss onClick={handleShareCourseLink} label="Del kurs" iconLeft={{ name: 'ForwardArrow' }} />
);
export default ForwardButton;
