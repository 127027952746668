import gql from 'graphql-tag';
import { JsonMLElement } from 'models/JsonML';
import { useApolloQuery } from 'util/hooks/useApolloQuery';
import * as GqlResult from 'util/graphql/GqlResult';

export const useLegalFragmentQuery = (uri: string, fragmentId: string) => {
  const [result] = useApolloQuery<
    GqlLegalFragmentNode,
    GqlLegalFragmentQueryVariables
  >(LEGAL_FRAGMENT_QUERY, {
    variables: { uri, fragmentId },
  });

  return GqlResult.of(result)
    .flatMapOk<GqlLegalFragmentNode>((data) => {
      if (!data.legalFragment) {
        return GqlResult.unknownError();
      }

      return GqlResult.ok(data);
    })
    .map(({ legalFragment }): string | null => {
      const myhtml =
        legalFragment?.node?.fragment?.edges[0]?.node?.content?.html;

      return myhtml;
    });
};

export interface GqlLegalFragmentQueryVariables {
  fragmentId: string;
  uri: string;
}

export interface GqlLegalFragmentNode {
  legalFragment: {
    node: {
      id: string;
      countryCode: string;
      subtype: string;
      date: string;
      seq: string;
      shortTitle: string;
      abbrevation?: string;
      fragment: {
        edges: [
          {
            node: {
              type: string;
              heading: {
                jsonml: JsonMLElement;
                html: string;
              };
              numText: {
                html: string;
              };
              content: {
                jsonml: JsonMLElement;
                html: string;
              };
              lastChangeDate: string;
              curatedSources: {
                type: string;
                content: {
                  jsonml: JsonMLElement;
                };
              }[];
            };
          }
        ];
      };
    };
  };
}

export const LEGAL_FRAGMENT_QUERY = gql`
  query PargraphStickerQuery($uri: String!, $fragmentId: String!) {
    legalFragment(uri: $uri, fragmentId: $fragmentId) {
      node {
        id
        countryCode
        subtype
        date
        seq
        shortTitle
        abbreviation
        fragment {
          edges {
            node {
              fragmentId
              type
              heading {
                jsonml
                html
              }
              numText {
                html
              }
              content {
                jsonml
                html
              }
              lastChangeDate
              curatedSources {
                type
                content {
                  jsonml
                }
              }
            }
          }
        }
      }
      error {
        type
      }
    }
  }
`;
