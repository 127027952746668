import { css, CSSProp } from 'styled-components';

import { FontType, ResponsiveFontType } from 'theme/config/types';
import createMediaQuery from './createMediaQuery';

const fontStyles = ({ family, weight, size, lineHeight, letterSpacing, style }: FontType) => css`
  font-family: ${family && family};
  font-weight: ${weight && weight};
  font-size: ${size && `${size}rem`};
  line-height: ${lineHeight && lineHeight};
  letter-spacing: ${letterSpacing && `${letterSpacing}rem`};
  font-style: ${style && style};
`;

function createFontStyles(font: ResponsiveFontType): CSSProp {
  const { mediaQueries, ...rest } = font;

  return css`
    ${fontStyles(rest)}

    ${(mediaQueries || []).map((screen) => {
      const { query, ...rest } = screen;

      return createMediaQuery(query, fontStyles(rest));
    })};
  `;
}

export default createFontStyles;
